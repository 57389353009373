import { Injectable } from '@angular/core';

@Injectable()
export class AppGlobals {

   // readonly weburl: string = "https://blockchainmatrimony.com/DigitalLendingapi";
    readonly weburl: string = "https://myservices-highway.com/MyServiceHighwayAPI";
    
   // readonly weburl : string ="https://myservices-highway.com/MyServiceHighwayAPI";
    
}