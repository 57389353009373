<app-header></app-header>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>

<div class="row inbox">
    <div class="col-md-1"></div>
    <div class="col-md-2">
        <div class="panel panel-default">
            <div class="panel-body inbox-menu">
                <a class="btn btn-danger btn-block" data-toggle="modal" mdbWavesEffect data-target="#Compose" style="color: white;background-color: #212529;">Compose</a>

                <ul>


                    <li>
                        <a (click)="gotoinbox()"><i class="fa fa-inbox"></i> Inbox</a>
                    </li>

                    <li>
                        <a (click)="gotosentitems()"><i class="fa fa-rocket"></i> Sent</a>
                    </li>
                    <li>
                        <a (click)="gotodeleteitems()"><i class="fa fa-trash-o"></i> Trash</a>
                    </li>



                </ul>

            </div>

        </div>
        <div>&nbsp;</div>

        <div class="card">
            <div class="card-body">
                <div>
                    <div class="col-md-1"></div>

                    <div>
                        <label>From Date</label>
                        <input type="date" class="form-control" id="fromdate" name="fromdate" placeholder="Any Day" [(ngModel)]="fromdate" (change)="onChangefromdate(fromdate)" />
                    </div>
                    <div class="col-md-1"></div>

                    <div>
                        <label>To Date</label>
                        <input type="date" class="form-control" id="todate" name="todate" placeholder="Any Day" [(ngModel)]="todate" (change)="onChangeTodate(todate)" />
                    </div>
                    <div class="col-md-1"></div>

                    <div>
                        <div class="col-md-12">&nbsp;</div>
                        <button type="button" class="btn btn-warning waves-light" style="background-color: gray;color: white;border: black" (click)="cancel()">Cancel</button>

                    </div>
                </div>
            </div>
        </div>





    </div>


    <div class="col-md-8 ">



        <aside class="lg-side">
            <div class="inbox-head">
                <h3>Sent Items</h3>


                <form class="pull-right position" action="#">
                    <div class="input-append">
                        <!-- <button type="button" class="btn sr-btn" data-original-title="" title=""><i class="fa fa-search"></i></button> -->
                        <input class="form-control" type="text" name="SearchSentItmes" [(ngModel)]="SearchSentItmes" autocomplete="off" placeholder="&#61442; Search">

                    </div>
                </form>
            </div>

        </aside>





        <table class="table table-striped table-hover" style="    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);">

            <tr class="thead-dark" style="font-size:17px;cursor: pointer;">
                <th>Sl No</th>
                <!-- <th>Ticket Id</th> -->
                <th>From User </th>
                <th>Mail Id</th>
                <th>Comments</th>
                <th>Service Finished</th>
                <th style="cursor: pointer;" (click)="sortbydate()">Last Updated</th>
                <th></th>
            </tr>
            <tr *ngFor="let aa of SentMails | LockFilter: SearchSentItmes  | paginate: { itemsPerPage: 5, currentPage: p };let i = index" style="font-size:16px;font-family:DINNextRoundedLTPro ">
                <td><a>{{ 5 * (p - 1) + (i+1) }}</a> </td>
                <td><a>{{aa.fromUser}}</a></td>
                <td><a>{{aa.mailId}}</a></td>
                <td><a>{{aa.comments}}</a></td>
                <td><a>{{aa.serviceStatus}}</a></td>
                <td><a>{{aa.createdOn|date:'short'}}</a></td>
                <td> <a (click)="deletemail(aa)" style="color: red;cursor: pointer;"><i class=" fa fa-trash"></i> </a>
                </td>

            </tr>
        </table>


        <br />
        <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
        <br /><br />
        <div>&nbsp;</div>
        <div>&nbsp;</div>
    </div>
</div>