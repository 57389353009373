<app-header></app-header>
<br/>
<br/>
<br>

<div id="signin"  align="center"  
    style="background: linear-gradient(0deg, rgb(67, 67, 72) -8.79%, rgb(215, 212, 222) 36.31%, rgba(153, 119, 234, 0) 95.86%); margin-left: 0px;margin-right: 0px;height: 100%;margin-top: 6%" >
        <fieldset  id="container">
          <form  [formGroup]="signupForm" >

            <br />
            <!-- <legend align="center" style="width: 50%;"><img src="./assets/img/HASPIT_Logo.png"  id="logo"></legend> -->

                  <div id="forgotpass">Forgot your password?</div>  <br><br>
                  <div id="emaillabel" style="width: auto;color: rgb(66, 79, 91);text-align: left;">Enter a new password for your {{email}} account.</div>  

                  <br/>
                  <br>
                
                  <label for="Email" id="emaillabel" >New Password</label>
                    <input  (keyup.enter)="setpassword()"
                    (change)="updatepassword($event.currentTarget.value)" formControlName="password" class="form-control" type="password" id="inputbutton" style="background-color:#eaf1f7;color: #c60c0c;"  #password>
                    <br />
                    <label for="Email" id="emaillabel" >Re-type Password</label>
                    <input  (keyup.enter)="setpassword()"
                    (change)="updateconfirm($event.currentTarget.value)" formControlName="confirmpassword"  class="form-control" type="password" id="inputbutton" style="background-color:#eaf1f7;color: #c60c0c;" [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }" #confirmpassword/>
                    
                    
                    <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                      <div *ngIf="f.confirmpassword.errors.pattern">Password must include</div>
                      <div *ngIf="f.confirmpassword.errors.pattern">An uppercase character</div>
                      <div *ngIf="f.confirmpassword.errors.pattern">An lowercase character</div>
                      <div *ngIf="f.confirmpassword.errors.pattern">An alphabetic character</div>
                      <div *ngIf="f.confirmpassword.errors.pattern">A numeric character</div>
                      <div *ngIf="f.confirmpassword.errors.pattern">A special character</div>
                      <div *ngIf="f.confirmpassword.errors.pattern">A minimum of 8 characters</div>
                    </div>

                    <br />
                  <br/>
                  <button id="forgotbutton" (click)="setpassword()">SUBMIT</button>
                  <br/>
                  <br/>
                  <br/>
                </form>
        </fieldset>
    
 
        <br/>
        <br/>
        <br/>

</div>


<b #openpopup style="display: none;" data-toggle="modal" data-target="#MessagePopup">Edit</b>
<!--  modal -->
<div class="modal modal-angular fade" id="MessagePopup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style="margin-top: 100px;">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
        
       
              <div class="modal-body text-center">
                  <div class="col-md-12">&nbsp;</div>
              <h4 style="font-size: 24px; font-family: auto; color: darkblue;">{{showmessage}}</h4>
              </div>
        
          <div class="modal-footer">
            
              <button *ngIf="idenable=='1'" type="button" class="btn btn-primary" data-dismiss="modal"
                  mdbWavesEffect>OK</button>
                  <button *ngIf="idenable=='2'" (click)="Routetosignin()" type="button" class="btn btn-primary" data-dismiss="modal"
                  mdbWavesEffect>OK</button>
          </div>
      </div>
  </div>
</div>