import { Component, OnInit } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { BusinessLoanServiceService } from '../../Shared/BusinessLoan/business-loan-service.service';
import { CustomerApprovalVM } from '../../ViewModels/CustomerApprovalVM';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'src/app/MessageService/meaagse.service';

@Component({
  selector: 'app-healthinsurancedetails',
  templateUrl: './healthinsurancedetails.component.html',
  styleUrls: ['./healthinsurancedetails.component.css']
})
export class HealthinsurancedetailsComponent implements OnInit {
  submitted: boolean;
  alldetails: any;
  familyDetails: any;
  fixedassetDetails: any;
  vehicleLoanDetails: any;

  userBasicDetailsDetails: any;
  userBankDetails: any;
  //personal details
  fname;
  mname;
  lname;
  placebirth;
  gender;
  marital;
  mobile;
  dob;
  religion;
  caste;
  qualification;
  occupation;
  email;
  designation;
  //Residential Address
  rPostal;
  rCountry;
  rState;
  rDistrict;
  rAddress1;
  rAddress2;
  //permanent Address
  pPostal;
  pCountry;
  pState;
  pDistrict;
  pAddress1;
  pAddress2;
  userBusinessDetails: any;
  //business
  businessaddtel;
  GSTNo;
  scst;
  Factoryaddress;
  partnername;
  agepartner;
  experiencepast;
  resiaddr;
  partnershare;
  techqualified;
  loaandyearofest;
  loanamt;
  TermLoanpurpose;
  WorkingcapitalPurpose;
  TermLoanAmount;
  WorkingcapitalAmount;
  TermLoanRepaymentprogramme;
  businessPremisesOwn;
  routerThroughBank;
  booksmaintained;
  workingrepaymentprogramme;
  nameandAddressofAssociateConcerns;
  difficulties;
  commoditiesTraded;
  majorSuppliers;
  numberOfFirms;
  competationSuccessfull;
  year1;
  year2;
  sales1;
  sales2;
  netProfit1;
  netProfit2;
  remarks1;
  remarks2;
  anticipatedYear;
  anticipatedSales;
  anticipatedProfit;
  anticipatedRemarks;
  feasibleYear;
  feasibleSales;
  feasibleNetProfit;
  feasibleRemarks;
  itAssessmentYear;
  itAmounPaid;
  stAssessmentYear;
  stAmountPaid;
  avgLvlValue;
  avgLvlMargin;
  avgLvlPermissibleLimit;
  avgLvlRemarks;
  outSatndingValue;
  outSatndingMargin;
  outStandingPermissibleLimit;
  outStandingRemarks;
  creditAvailableSuppliers;
  repaymentProgramme;
  item1;
  item2;
  item3;
  supplier1;
  suppier2;
  supplier3;
  cost;
  cost1;
  cost2;
  lessapplicantscontribution;
  loanEquipmentRupees;
  reapymentProgamme;
  security;
  timeToErect;
  startUpPeriod;
  detailsofImmovableProperty;
  workingCapitalRequired;
  firmName;
  guarantorValue;
  guarantor;
  desc1;
  desc1Rs;
  desc2;
  desc2Rs;
  desc3;
  desc3Rs;
  SmallScaleDetails: any;
  workflwid: string;
  customerreqdata: any;
  uid: string;
  healthinsurancedetails: any;
  baid: any;
  detailss: string;
  customerid: string;
  transid: string;
  mailid: string;
  mailticketid: string;
  useremail: string;
  username: string;
  roleid: string;
  groupId: string;
  showapprove: boolean;
  CertificateList: any;
  enablecertificates: boolean=false;
  WFStatusList: any;
  rationCardNum:any;
  files: any;
  ApproveFiles: string;
  selectedstatusid: any;
  comments: any;
  sourcefund:any;
  operation: any;
  workflowidfrommail: string;
  userdetailsfor: string;
  bankdetails: any;
  bankdocs: any;
  bankdocsexist: boolean;
  certificates: any;
  certificatesexist: boolean;
  CertificateDisplay: any=[];
  role: string;
  allcertificates: any;
  enableothercertificates: boolean;

aadharNum: any;
  voterId: any;
      panNum: any;
      serviceTaxNum: any;
    passportNum: any;
     lifeInsuranceValue: any;
     isConfirm: any;


     aadharFile: any;

     bankStatements: any;

     gstCert: any;

     itReturnsFile: any;

     passportFile: any;

     voterIdFile: any;



   monthincome: any;
    detailsfirs: any;
     detailsthrd: any;
     detailsfour: any;
   dateofbirthFirst: any;
    dateofbirthsec: any;
    dateofbirthtrd: any;
    dateofbirthfourth: any;
    genderfirst: any;
    gendersec: any;
    genderthrd: any;
    genderfour: any;
    nationalityfirst: any;
    nationalitysec: any;
    nationalitytrd: any;
    nationality: any;
    proposertfirst: any;
    proposersec: any;
    proposerthrd: any;
    proposertforth: any;
    statusfirst: any;
    statussecond: any;
    statusthird: any;
    statusfourth: any;
    insuranceone: any;
    insurancetwo: any;

    insurancethrd: any;

    insurancefour: any;

    othersfirst: any;
    otherstwo: any;

    othersthree: any;

    othersfour: any;

    insuredone: any;

    insuredsec: any;
    detailsthird:any;

    insuredthrd: any;
    insuredfour: any;
    diabetesfirst: any;
    diabetessecond: any;
    diabetesthird: any;
    diabetesfourth: any;
    nomineefirst: any;
    nomineesecond: any;
    nomineethird: any;
    nomineefourth: any;
    relationfirst: any;
    relationsecond: any;
    relationthird: any;
    relationfourth: any;
    medicalfirst: any;
    medicalsecond: any;
    medicalthird: any;
    medicalfourth: any;
    liverfirst: any;
    liversecond: any;
    liverthird: any;
    liverfourth: any;
    cancerfirst: any;
    cancersecond: any;
    cancerthird: any;
    cancerfourth: any;
    heartfirst: any;
    heartsecond: any;
    heartthird: any;
    heartfourth: any;
    arthritisfirst: any;
    arthritissecond: any;
    arthritisthird: any;
    arthritisfourth: any;
    kidneyfirst: any;
    kidneysecond: any;
    kidneythird: any;
    kidneyfourth: any;
    paralysisfirst: any;
    paralysissecond: any;
    paralysisthird: any;
    paralysisfourth: any;
    congenitalfirst: any;
    congenitalsecond: any;
    congenitalthird: any;
    congenitalfourth: any;
    injurtfirst: any;
    injurtsecond: any;
    injurtthird: any;
    injurtfourth: any;
    specifyfirst: any;
    specifysecond: any;
    specifythird: any;
    specifyfourth: any;
    drugsfirst: any;
    drugssecond: any;
    drugsthird: any;
    drugsfourth: any;
    tobaccofirst: any;
    tobaccosecond: any;
    tobaccothird: any;
    tobaccofourth: any;
    detailsfirst:any;



    
    personone:any;
    personsec:any;
    personsthrd:any;
    personsfourth:any;
    policyfirst:any;
    policysec:any;
    policythrd:any;
    policyfour:any;
    periodone:any;
    periodsecond:any;
    periodthrd:any;
    periodfourth:any;
    sumfirst:any;
    sumsecond:any;
    sumthird:any;
    sumfourth:any;
    filedfirst:any;
    filedsecond:any;
    filedthird:any;
    filedfourth:any;
    healthfirst:any;
    healthsecond:any;
    healththird:any;
    healthfourth:any;
  
    coveredfirst:any;
    coveredsecond:any;
  
    coveredthird:any;
  
    coveredfourth:any;
  
    companyfirst:any;
  
    companysecond:any;
  
    companythird:any;
  
    companyfourth:any;
  
    infirmityfirst:any;
  
    infirmitysecond:any;
  
    infirmitythird:any;
    infirmityfourth:any;
    contactfirst:any;
    contactsecond:any;
    contactthird:any;
    contactfourth:any;
    Emailfirst:any;
    Emailsecond:any;
    Emailthird:any;
    Emailfourth:any;
  


debitcard:any;
  chequeamt:any;
chequedate:any;
nmpayer:any;
bnkname:any;




  constructor(private messageService: MessageService,private businessService: BusinessLoanServiceService, private spinner: NgxSpinnerService, 
    private router: Router,private httpservice:HttpClient) {
      this.messageService.sendSession('true');
      localStorage.setItem("Loder", "0");
    this.uid = localStorage.getItem("userId");
    if (localStorage.getItem("IsLoggedIn") == "true") {
      this.uid = localStorage.getItem("userId");
      this.useremail = localStorage.getItem("Email");
      this.username = localStorage.getItem("fullname");
      this.roleid = localStorage.getItem("role");
      this.role=localStorage.getItem("role");
      this.groupId = localStorage.getItem("groupId");
      this.detailss = localStorage.getItem("custidwithmailid");
    }

    this.uid = localStorage.getItem("userId");
    if (this.uid.includes("USR")) {
      this.showapprove = true;
    }
    else {
      this.showapprove = false;
    }
  }

  ngOnInit() {
    this.workflwid = localStorage.getItem("workflowid");
    this.workflowidfrommail=this.workflwid
    this.baid = localStorage.getItem("baid");
    this.detailss = localStorage.getItem("custidwithmailid");
    var aa = this.detailss.split('-');
    // if (!aa[0].includes("USR") == undefined) {
      this.customerid = aa[0];
    // }
    // else {
    //   this.customerid = this.uid;
    // }
    this.transid = aa[1];
    this.mailid = aa[2];
    this.mailticketid = aa[3];
    if(this.workflowidfrommail=="1008"){
      this.mailticketid = localStorage.getItem("ProspectIdtoDisplydata");
    }
    this.backendServices();
    this.gethealthinsdetails();
  }
  backendServices() {
    this.userdetailsfor = this.customerid + ',' + this.mailticketid;
   // this.spinner.show();
    this.businessService.getBusinessLoanDetailsUser(this.userdetailsfor).subscribe((response: any) => {
      this.alldetails = response;
     // this.spinner.hide();

      this.userBasicDetailsDetails = this.alldetails.userBasicDetailsDetails;
      this.userBankDetails=this.alldetails.userBankandIdentityDetails;

      this.rationCardNum=this.userBankDetails.rationCardNum;

      this.aadharNum=this.userBankDetails.aadharNum;
      this.voterId=this.userBankDetails.voterId;
      this.panNum=this.userBankDetails.panNum;
      this.serviceTaxNum=this.userBankDetails.serviceTaxNum;
      this.passportNum=this.userBankDetails.passportNum;
      this.lifeInsuranceValue=this.userBankDetails.lifeInsuranceValue;
      this.isConfirm=this.userBankDetails.isConfirm;



      this.aadharFile=this.userBankDetails.aadharFile;
      this.bankStatements=this.userBankDetails.bankStatements;
      this.gstCert=this.userBankDetails.gstCert;
      this.itReturnsFile=this.userBankDetails.itReturnsFile;
      this.passportFile=this.userBankDetails.passportFile;
      this.voterIdFile=this.userBankDetails.voterIdFile;
  

      if (this.alldetails.userBankandIdentityDetails != null || this.alldetails.userBankandIdentityDetails != undefined) {
        this.bankdetails = this.alldetails.userBankandIdentityDetails.bankdetails;
        this.bankdocs = this.alldetails.userBankandIdentityDetails.alldocuments
        if (this.bankdocs.length != 0) {
          this.bankdocsexist = true
        }
      }
      else if (this.alldetails.count != null || this.alldetails.certificateDetails != undefined) {
        this.certificates = this.alldetails.certificateDetails
        if (this.certificates.length != 0) {
          this.certificatesexist = true
      
        }
      }
      if (this.userBasicDetailsDetails != null || this.userBasicDetailsDetails != undefined) {
        this.fname = this.userBasicDetailsDetails.firstName;
        this.mname = this.userBasicDetailsDetails.middleName;
        this.lname = this.userBasicDetailsDetails.lastName;
        this.placebirth = this.userBasicDetailsDetails.placeOfBirth;
        this.gender = this.userBasicDetailsDetails.genderstring;
        this.marital = this.userBasicDetailsDetails.maritalstring;
        this.mobile = this.userBasicDetailsDetails.mobileNum;
        this.dob = this.userBasicDetailsDetails.dateOfBirth;
        this.religion = this.userBasicDetailsDetails.religionstring;
        this.caste = "",
          this.qualification = this.userBasicDetailsDetails.qualificationstring;
        this.occupation = this.userBasicDetailsDetails.occupationstring;
        this.email = this.userBasicDetailsDetails.email;
        this.designation = this.userBasicDetailsDetails.designationstring;
        //address

        this.rPostal = this.userBasicDetailsDetails.residentialPincode;
        // this.rCountry=this.userBasicDetailsDetails.occupationstring;
        this.rState = this.userBasicDetailsDetails.residentialState;
        this.rDistrict = this.userBasicDetailsDetails.residentialDistrict;
        this.rAddress1 = this.userBasicDetailsDetails.residentialLineOne;
        this.rAddress2 = this.userBasicDetailsDetails.residentialLineTwo;

        this.pPostal = this.userBasicDetailsDetails.permanentPincode;
        // this.pCountry=this.userBasicDetailsDetails.designationstring;
        this.pState = this.userBasicDetailsDetails.permanentState;
        this.pDistrict = this.userBasicDetailsDetails.permanentDistrict;
        this.pAddress1 = this.userBasicDetailsDetails.permanentLineOne;
        this.pAddress2 = this.userBasicDetailsDetails.permanentLineTwo;

      }

      if(this.alldetails.allCertificateDetails.length!=0){
        this.allcertificates=this.alldetails.allCertificateDetails
        this.certificatesexist = true

        for (let index = 0; index < this.allcertificates.length; index++) {
          if (this.allcertificates[index].actualCertificateName == null) {
            this.CertificateDisplay.push(this.allcertificates[index]);
          }
        }
        for (let index = 0; index < this.allcertificates.length; index++) {
          if (this.allcertificates[index].actualCertificateName != null) {
            this.enableothercertificates=true
          }
          break
        }
  
      }
      if (this.alldetails.customerRequestdata != null || this.alldetails.customerRequestdata != undefined) {
        this.customerreqdata = this.alldetails.customerRequestdata;
      }

      console.log(response);
    })
  }
 
  bankIn(stepper: MatStepper) {
    this.submitted = true;
    stepper.next();
  }
  signIn(stepper: MatStepper) {
    this.submitted = true;
    stepper.next();
  }
  details(stepper: MatStepper) {
    this.submitted = true;
    stepper.next();
  }
  homeIn(stepper: MatStepper) {
    this.submitted = true;
    stepper.next();
  }
  healthinsurance(stepper: MatStepper) {
    this.submitted = true;
     stepper.next();
  }

  gethealthinsdetails() {
   // this.spinner.show();    
    this.businessService.gethealthinsurancedetails(this.mailticketid).subscribe((respose: any) => {
      this.healthinsurancedetails = respose.healthInsuranceDetails;
      this.sourcefund=this.healthinsurancedetails.sourcefund;


      this.monthincome=this.healthinsurancedetails.monthincome;
      this.detailsfirst=this.healthinsurancedetails.detailsfirst;
      this.detailsthrd=this.healthinsurancedetails.detailsthrd;
      this.detailsfour=this.healthinsurancedetails.detailsfour;
      this.dateofbirthFirst=this.healthinsurancedetails.dateofbirthFirst;
      this.dateofbirthsec=this.healthinsurancedetails.dateofbirthsec;
      this.dateofbirthtrd=this.healthinsurancedetails.dateofbirthtrd;
      this.dateofbirthfourth=this.healthinsurancedetails.dateofbirthfourth;
      this.genderfirst=this.healthinsurancedetails.genderfirst;
      this.gendersec=this.healthinsurancedetails.gendersec;
      this.genderthrd=this.healthinsurancedetails.genderthrd;
      this.genderfour=this.healthinsurancedetails.genderfour;
      this.nationalityfirst=this.healthinsurancedetails.nationalityfirst;
      this.nationalitysec=this.healthinsurancedetails.nationalitysec;
      this.nationalitytrd=this.healthinsurancedetails.nationalitytrd;
      this.nationality=this.healthinsurancedetails.nationality;
      this.proposertfirst=this.healthinsurancedetails.proposertfirst;
      this.proposersec=this.healthinsurancedetails.proposersec;
      this.proposerthrd=this.healthinsurancedetails.proposerthrd;
      this.proposertforth=this.healthinsurancedetails.proposertforth;
      this.statusfirst=this.healthinsurancedetails.statusfirst;
      this.statussecond=this.healthinsurancedetails.statussecond;
      this.statusthird=this.healthinsurancedetails.statusthird;
      this.statusfourth=this.healthinsurancedetails.statusfourth;
      this.insuranceone=this.healthinsurancedetails.insuranceone;
      this.insurancetwo=this.healthinsurancedetails.insurancetwo;

      this.insurancethrd=this.healthinsurancedetails.insurancethrd;

      this.insurancefour=this.healthinsurancedetails.insurancefour;

      this.othersfirst=this.healthinsurancedetails.othersfirst;

      this.otherstwo=this.healthinsurancedetails.otherstwo;

      this.othersthree=this.healthinsurancedetails.othersthree;

      this.othersfour=this.healthinsurancedetails.othersfour;

      this.insuredone=this.healthinsurancedetails.insuredone;

      this.insuredsec=this.healthinsurancedetails.insuredsec;

      this.insuredthrd=this.healthinsurancedetails.insuredthrd;
      this.insuredfour=this.healthinsurancedetails.insuredfour;
      this.diabetesfirst=this.healthinsurancedetails.diabetesfirst;
      this.diabetessecond=this.healthinsurancedetails.diabetessecond;
      this.diabetesthird=this.healthinsurancedetails.diabetesthird;
      this.diabetesfourth=this.healthinsurancedetails.diabetesfourth;
      this.nomineefirst=this.healthinsurancedetails.nomineefirst;
      this.nomineesecond=this.healthinsurancedetails.nomineesecond;
      this.nomineethird=this.healthinsurancedetails.nomineethird;
      this.nomineefourth=this.healthinsurancedetails.nomineefourth;
      this.relationfirst=this.healthinsurancedetails.relationfirst;
      this.relationsecond=this.healthinsurancedetails.relationsecond;
      this.relationthird=this.healthinsurancedetails.relationthird;
      this.relationfourth=this.healthinsurancedetails.relationfourth;
      this.medicalfirst=this.healthinsurancedetails.medicalfirst;
      this.medicalsecond=this.healthinsurancedetails.medicalsecond;
      this.medicalthird=this.healthinsurancedetails.medicalthird;
      this.medicalfourth=this.healthinsurancedetails.medicalfourth;
      this.liverfirst=this.healthinsurancedetails.liverfirst;
      this.liversecond=this.healthinsurancedetails.liversecond;
      this.liverthird=this.healthinsurancedetails.liverthird;
      this.liverfourth=this.healthinsurancedetails.liverfourth;
      this.cancerfirst=this.healthinsurancedetails.cancerfirst;
      this.cancersecond=this.healthinsurancedetails.cancersecond;
      this.cancerthird=this.healthinsurancedetails.cancerthird;
      this.cancerfourth=this.healthinsurancedetails.cancerfourth;
      this.heartfirst=this.healthinsurancedetails.heartfirst;
      this.heartsecond=this.healthinsurancedetails.heartsecond;
      this.heartthird=this.healthinsurancedetails.heartthird;
      this.heartfourth=this.healthinsurancedetails.heartfourth;
      this.arthritisfirst=this.healthinsurancedetails.arthritisfirst;
      this.arthritissecond=this.healthinsurancedetails.arthritissecond;
      this.arthritisthird=this.healthinsurancedetails.arthritisthird;
      this.arthritisfourth=this.healthinsurancedetails.arthritisfourth;
      this.kidneyfirst=this.healthinsurancedetails.kidneyfirst;
      this.kidneysecond=this.healthinsurancedetails.kidneysecond;
      this.kidneythird=this.healthinsurancedetails.kidneythird;
      this.kidneyfourth=this.healthinsurancedetails.kidneyfourth;
      this.paralysisfirst=this.healthinsurancedetails.paralysisfirst;
      this.paralysissecond=this.healthinsurancedetails.paralysissecond;
      this.paralysisthird=this.healthinsurancedetails.paralysisthird;
      this.paralysisfourth=this.healthinsurancedetails.paralysisfourth;
      this.congenitalfirst=this.healthinsurancedetails.congenitalfirst;
      this.congenitalsecond=this.healthinsurancedetails.congenitalsecond;
      this.congenitalthird=this.healthinsurancedetails.congenitalthird;
      this.congenitalfourth=this.healthinsurancedetails.congenitalfourth;
      this.injurtfirst=this.healthinsurancedetails.injurtfirst;
      this.injurtsecond=this.healthinsurancedetails.injurtsecond;
      this.injurtthird=this.healthinsurancedetails.injurtthird;
      this.injurtfourth=this.healthinsurancedetails.injurtfourth;
      this.specifyfirst=this.healthinsurancedetails.specifyfirst;
      this.specifysecond=this.healthinsurancedetails.specifysecond;
      this.specifythird=this.healthinsurancedetails.specifythird;
      this.specifyfourth=this.healthinsurancedetails.specifyfourth;
      this.drugsfirst=this.healthinsurancedetails.drugsfirst;
      this.drugssecond=this.healthinsurancedetails.drugssecond;
      this.drugsthird=this.healthinsurancedetails.drugsthird;
      this.drugsfourth=this.healthinsurancedetails.drugsfourth;
      this.tobaccofirst=this.healthinsurancedetails.tobaccofirst;
      this.tobaccosecond=this.healthinsurancedetails.tobaccosecond;
      this.tobaccothird=this.healthinsurancedetails.tobaccothird;
      this.tobaccofourth=this.healthinsurancedetails.tobaccofourth;


      this.personone=this.healthinsurancedetails.personone;
      this.personsec=this.healthinsurancedetails.personsec;
      this.personsthrd=this.healthinsurancedetails.personsthrd;
      this.personsfourth=this.healthinsurancedetails.personsfourth;
      this.policyfirst=this.healthinsurancedetails.policyfirst;
      this.policysec=this.healthinsurancedetails.policysec;
      this.policythrd=this.healthinsurancedetails.policythrd;
      this.policyfour=this.healthinsurancedetails.policyfour;
      this.periodone=this.healthinsurancedetails.periodone;
      this.periodsecond=this.healthinsurancedetails.periodsecond;
      this.periodthrd=this.healthinsurancedetails.periodthrd;
      this.periodfourth=this.healthinsurancedetails.periodfourth;
      this.sumfirst=this.healthinsurancedetails.sumfirst;
      this.sumsecond=this.healthinsurancedetails.sumsecond;
      this.sumthird=this.healthinsurancedetails.sumthird;
      this.sumfourth=this.healthinsurancedetails.sumfourth;
      this.filedfirst=this.healthinsurancedetails.filedfirst;
   
      this.filedsecond=this.healthinsurancedetails.filedsecond;
      this.filedthird=this.healthinsurancedetails.filedthird;
      this.filedfourth=this.healthinsurancedetails.filedfourth;
      this.healthfirst=this.healthinsurancedetails.healthfirst;
      this.healthsecond=this.healthinsurancedetails.healthsecond;
      this.healththird=this.healthinsurancedetails.healththird;
      this.healthfourth=this.healthinsurancedetails.healthfourth;
    
      this.coveredfirst=this.healthinsurancedetails.coveredfirst;
      this.coveredsecond=this.healthinsurancedetails.coveredsecond;
    
      this.coveredthird=this.healthinsurancedetails.coveredthird;
    
      this.coveredfourth=this.healthinsurancedetails.coveredfourth;
    
      this.companyfirst=this.healthinsurancedetails.companyfirst;
    
      this.companysecond=this.healthinsurancedetails.companysecond;
    
      this.companythird=this.healthinsurancedetails.companythird;
    
      this.companyfourth=this.healthinsurancedetails.companyfourth;
    
      this.infirmityfirst=this.healthinsurancedetails.infirmityfirst;
    
      this.infirmitysecond=this.healthinsurancedetails.infirmitysecond;
    
      this.infirmitythird=this.healthinsurancedetails.infirmitythird;
      this.infirmityfourth=this.healthinsurancedetails.infirmityfourth;
      this.contactfirst=this.healthinsurancedetails.contactfirst;
      this.contactsecond=this.healthinsurancedetails.contactsecond;
      this.contactthird=this.healthinsurancedetails.contactthird;
      this.contactfourth=this.healthinsurancedetails.contactfourth;
      this.Emailfirst=this.healthinsurancedetails.Emailfirst;
      this.Emailsecond=this.healthinsurancedetails.Emailsecond;
      this.Emailthird=this.healthinsurancedetails.Emailthird;
      this.Emailfourth=this.healthinsurancedetails.Emailfourth;
    
      
    
      this.debitcard=this.healthinsurancedetails.debitcard;
      this.chequeamt=this.healthinsurancedetails.chequeamt;
      this.chequedate=this.healthinsurancedetails.chequedate;
      this.nmpayer=this.healthinsurancedetails.nmpayer;
     
    
this.bnkname=this.healthinsurancedetails.this.bnkname;
;
     



     // this.spinner.hide();

    });
  }

  approve() {
    if (this.workflwid == '1008') {
      const inputRequest: CustomerApprovalVM = {
        CustomerId: localStorage.userId,
        TicketId:this.customerreqdata.complaintId,
        WorkflowId: this.workflwid,
        ComplaintId: this.customerreqdata.complaintId,
        BusinessAssociateId: this.baid
      }
     // this.spinner.show();
      this.businessService.customerapprovalandreqticket(inputRequest).subscribe(
        (data: any) => {
         // this.spinner.hide();
          this.router.navigate(['/customerdashboard']);
        });
    }
    else {
      const inputRequest: CustomerApprovalVM = {
        CustomerId: localStorage.userId,
        TicketId: null,
        WorkflowId: null,
        ComplaintId: null,
        BusinessAssociateId: null
      }
     // this.spinner.show();
      this.businessService.customerapproval(inputRequest).subscribe(
        (data: any) => {
         // this.spinner.hide();
          this.router.navigate(['/customerdashboard']);
        });
    }
  }
  SendVerificationtoCustomer() {
    var mailtocustomer = {
      Id: 0,
      CustomerId: this.customerid,
      MailId: this.mailid,
      TransId: this.transid,
      FromUser: this.uid,
      ToUser: this.customerid,
      WorkflowState: this.selectedstatusid,
      Comments: this.comments,
      TickedId: this.mailticketid 

    };
    const frmData = new FormData();
    frmData.append("CustomerData", JSON.stringify(mailtocustomer));
    
    frmData.append("ApproveFiles", this.ApproveFiles);
   // this.spinner.show();
    this.httpservice.post('https://myservices-highway.com/MyServiceHighwayAPI/api/WorkFlow/PostUpdateWorkflowMail/', frmData).subscribe(
      data => {
        if (data != null) {
         // this.spinner.hide();
          alert('Mail Sent Successfully');
          this.router.navigate(['/inbox']);
        }
        else {
         // this.spinner.hide();
          alert("Something went wrong!!")
        }
      });
    
  }
  GetGroupId(grpid) {
    var groupid = "";
    if (grpid === "1003") {
      groupid = "1004";
    }
    else if (grpid === "1004") {
      groupid = "1005";
    }
    else if (grpid === "1005") {
      groupid = "1006";
    }
    else if (grpid === "1006") {
      groupid = "1007";
    }
    else if (grpid === "1007") {
      groupid = "1008";
    }
    else if (grpid === "1007") {
      groupid = "1008";
    }
    else if (grpid === "1008") {
      groupid = "1009";
    }
    else if (grpid === "1009") {
      groupid = "1010";
    }
    else if (grpid === "1010") {
      groupid = "1014";
    }
    else if (grpid === "1014") {
      groupid = "1015";
    }
    else if (grpid === "1015") {
      groupid = "1016";
    }
    else if (grpid === "1016") {
      groupid = "1017";
    }
    else if (grpid === "1017") {
      groupid = "1018";
    }
    else if (grpid === "1018") {
      groupid = "1019";
    }
    else if (grpid === "1019") {
      groupid = "1018";
    }
    else if (grpid === "1020") {
      groupid = "1021";
    }
    else if (grpid === "1021") {
      groupid = "1020";
    }
    else if (grpid === "1022") {
      groupid = "1023";
    }
    else if (grpid === "1023") {
      groupid = "1024";
    }
    else if (grpid === "1024") {
      groupid = "1024";
    }
    return groupid;
  }
  onselecttouser(id) {
    this.operation = id;
  }
  onSelectStatus(id) {
    this.selectedstatusid = id;
  }
  onChangeApproveFiles(event){
    var fileslist = "";
    this.files = [].slice.call(event.target.files);
    console.log(this.files);
    fileslist = this.files[0];
    this.ApproveFiles = fileslist;
  }
  SendVerificationtoApprover() {
    
    if (this.roleid == "1009" || this.roleid == "1010") {
      var mailtocustomer = {
        Id: 0,
        CustomerId: this.customerid,
        MailId: this.mailid,
        TransId: this.transid,
        FromUser: this.uid,
        ToUser: this.GetGroupId(this.groupId),
        GroupId: this.GetGroupId(this.groupId),
        WorkflowState: this.selectedstatusid,
        Comments: this.comments,
        TickedId: this.mailticketid 
      };
      console.log(mailtocustomer);
      const frmData = new FormData();
    frmData.append("CustomerData", JSON.stringify(mailtocustomer));
    
    frmData.append("ApproveFiles", this.ApproveFiles);
   // this.spinner.show();

    this.httpservice.post('https://myservices-highway.com/MyServiceHighwayAPI/api/WorkFlow/PostPPUUpdateWorkflowOfcMail/', frmData).subscribe(
      data => {
        if (data != null) {
          this.WFStatusList = data;
         // this.spinner.hide();
          alert('Mail Sent Successfully');
          this.router.navigate(['/inbox']);
        }
        else {
         // this.spinner.hide();
          alert("Something went wrong!!")
        }
      });
 
    }
    else{

    var mailtocustomer = {
      Id: 0,
      CustomerId: this.customerid,
      MailId: this.mailid,
      TransId: this.transid,
      FromUser: this.uid,
      ToUser: this.GetGroupId(this.groupId),
      GroupId: this.GetGroupId(this.groupId),
      WorkflowState: this.selectedstatusid,
      Comments: this.comments,
      TickedId: this.mailticketid 
    };
    console.log(mailtocustomer);
    const frmData = new FormData();
    frmData.append("CustomerData", JSON.stringify(mailtocustomer));
    
    frmData.append("ApproveFiles", this.ApproveFiles);
   // this.spinner.show();

    this.httpservice.post('https://myservices-highway.com/MyServiceHighwayAPI/api/WorkFlow/PostUpdateWorkflowOfcMail/', frmData).subscribe(
      data => {
        if (data != null) {
          this.WFStatusList = data;
         // this.spinner.hide();
          alert('Mail Sent Successfully');
          this.router.navigate(['/inbox']);
        }
        else {
         // this.spinner.hide();
          alert("Something went wrong!!")
        }
      });
    
  }
     
  }
  EditPersonalDetails(){
    this.router.navigate(['HealthInsurance/'])
    localStorage.setItem("editinsuranceform", "personal")
  }
 
  EditBankDetails(){
    this.router.navigate(['HealthInsurance/'])
    localStorage.setItem("editinsuranceform", "bank")
  }

  EditHealthInsuranceDetails(){
    this.router.navigate(['HealthInsurance/'])
    localStorage.setItem("editinsuranceform", "health")
  }
  EditCertificatesDetails(){
    this.router.navigate(['HealthInsurance/'])
    localStorage.setItem("editinsuranceform", "certificates")
  }
}
