

<app-header></app-header>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>
<br>
<br>
<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">

        <div class="col-md-12 row" *ngIf="roleid=='2'">
            <div class="col-md-9"></div>
            <div class="col-md-3">
                <button class="btn btn-primary" (click)="DownloadForm()">Download</button>
            </div>
        </div>
        <div class="homecard" id="print-section" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%">

            <div class="col-md-12 row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Personal Details</h2>
                </div>
                <div class="col-md-2">
                    <button *ngIf="roleid!='2'" class="btn btn-primary" (click)="personaldetailsclick()">Edit</button>

                </div>
            </div>
            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-12">
                <div class="col-md-12 row">
                    <div class="col-md-2 row">&nbsp;</div>
                    <div class="col-md-3 row" style="display: flex;">
                        <label id="emaillabel">First Name : &nbsp;</label>&nbsp;{{fname}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Middle Name : &nbsp;</label>&nbsp;{{mname}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Last Name : &nbsp;</label>&nbsp;{{lname}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Place of Birth : &nbsp;</label>&nbsp;{{placebirth}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Gender : &nbsp;</label>&nbsp;{{gender}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Marital Status : &nbsp;</label>&nbsp;{{marital}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Mobile Number : &nbsp;</label>&nbsp;{{mobile}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Date of Birth :
                            &nbsp;</label>&nbsp;{{dob | date : 'shortDate'}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Religion : &nbsp;</label>&nbsp;{{religion}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Caste : &nbsp;</label>&nbsp;{{caste}} Brahmin

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Qualification :
                            &nbsp;</label>&nbsp;{{qualification}}


                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Occupation : &nbsp;</label>&nbsp;{{occupation}}


                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> Email : &nbsp;</label>&nbsp;{{email}}

                    </div>

                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Designation : &nbsp;</label>&nbsp;{{designation}}


                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Postal Code : &nbsp;</label>&nbsp;{{rPostal}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Country : &nbsp;</label>&nbsp;{{rCountry}} India
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> State : &nbsp;</label>&nbsp;{{rState}}
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">District : &nbsp;</label>&nbsp;{{rDistrict}}
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Address one : &nbsp;</label>&nbsp;{{rAddress1}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address two : &nbsp;</label>&nbsp;{{rAddress2}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-7 row ">
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Permanent Address</h3>
                    </div>
                    <div class="col-md-1 row ">
                    </div>
                    <div class="col-md-2 row ">&nbsp;</div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Postal Code : &nbsp;</label>&nbsp;{{pPostal}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Country : &nbsp;</label>&nbsp;{{pCountry}} India
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> State : &nbsp;</label>&nbsp;{{pState}}
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">District : &nbsp;</label>&nbsp;{{pDistrict}}
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address one : &nbsp;</label>&nbsp;{{pAddress1}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address two : &nbsp;</label>&nbsp;{{pAddress2}}

                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 ">&nbsp;</div>                                       
                        <div class="col-md-12 " *ngIf="familyDetails">
                            <div class="col-md-12 row">
                                <div class="col-md-4"></div>
                                <div class="col-md-4">
                                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Family Details</h2>
                                </div>
                                <div class="col-md-2">
                                    <button *ngIf="roleid!='2'" class="btn btn-primary" (click)="familydetailsclick()">Edit</button>
                                </div>
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12 ">
                                <div class="col-md-12 ">&nbsp;</div>
                                <div class="col-md-12 row ">
                                    <div class="col-md-2 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label id="emaillabel ">Family Type:</label> &nbsp;
                                        <!-- <span *ngIf="familyDetails?.familyTypeString == null">Extended Family</span> -->
                                        <span *ngIf="familyDetails?.familyTypeString != null">{{familyDetails?.familyTypeString}}</span>
                                    </div>
            
                                    <div class="col-md-1 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label id="emaillabel ">Number of Dependents:</label>&nbsp;
                                        <!-- <span
                                        *ngIf="familyDetails?.numberofDependents == null || familyDetails?.numberofDependents == undefined">2</span> -->
                                        <span *ngIf="familyDetails?.numberofDependents != null">{{familyDetails.numberofDependents}}</span>
                                    </div>
                                    <div class="col-md-1 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label id="emaillabel ">Number of Children:</label>&nbsp;
                                        <!-- <span
                                        *ngIf="familyDetails.numberofChildren == null || familyDetails.numberofChildren == undefined">3</span> -->
                                        <span *ngIf="familyDetails.numberofChildren != null">{{familyDetails.numberofChildren}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 row ">&nbsp;</div>
            
                                <div class="col-md-12 row ">
                                    <div class="col-md-2 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label id="emaillabel ">Annual Income in Rs. :</label>&nbsp;
                                        <!-- <span *ngIf="familyDetails.annualIncomeAmount == null">25000</span> -->
                                        <span *ngIf="familyDetails.annualIncomeAmount != null">{{familyDetails.annualIncomeAmount}}</span>
                                    </div>
            
                                    <div class="col-md-1 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label for="Email " id="emaillabel ">Income from Other sources in Rs. :</label>&nbsp;
                                        <!-- <span *ngIf="familyDetails.otherSourceAmount == null">1000</span> -->
                                        <span *ngIf="familyDetails.otherSourceAmount != null">{{familyDetails.otherSourceAmount}}</span>
                                    </div>
                                    <div class="col-md-1 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label for="Email " id="emaillabel "> Total Annual Income:</label>&nbsp;
                                        <!-- <span *ngIf="familyDetails.totalAmount == null">270000</span> -->
                                        <span *ngIf="familyDetails.totalAmount != null">{{familyDetails.totalAmount}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 row ">&nbsp;</div>
                                <div class="col-md-12 row ">&nbsp;</div>
            
                                <div class="col-md-12 row ">&nbsp;</div>
                                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Minor Nominee</h3>
                                <div class="col-md-12 row ">&nbsp;</div>
                                <div class="col-md-12 row ">
                                    <div class="col-md-2 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label id="emaillabel ">First Name:</label>&nbsp;
                                        <!-- <span *ngIf="familyDetails.minorNomineeName == null">Raj</span> -->
                                        <span *ngIf="familyDetails.minorNomineeName != null">{{familyDetails.minorNomineeName}}</span>
                                    </div>
                                    <div class="col-md-1 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label for="Email " id="emaillabel ">Middle Name:</label>&nbsp;
                                        <!-- <span *ngIf="familyDetails.minorNomineeDOB == null">Raj</span> -->
                                        <span *ngIf="familyDetails.minorNomineeDOB != null">{{familyDetails.minorNomineeDOB|date}}</span>
            
                                    </div>
                                    <div class="col-md-1 row ">&nbsp;</div>
            
                                    <div class="col-md-3 row ">
                                        <label id="emaillabel ">Last Name:</label>&nbsp;
                                        <!-- <span *ngIf="familyDetails.minorNomineeName == null">Raj</span> -->
                                        <span *ngIf="familyDetails.minorNomineeName != null">{{familyDetails.minorNomineeName}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 row ">&nbsp;</div>
                                <div class="col-md-12 row ">
                                    <div class="col-md-2 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label for="Email " id="emaillabel ">Postal Code:</label>&nbsp;{{familyDetails.minorPostalCode}}
                                    </div>
                                    <div class="col-md-1 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label id="emaillabel ">Country:</label>&nbsp;{{familyDetails.minorCountry}}
                                    </div>
                                    <div class="col-md-1 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label for="Email " id="emaillabel "> State:</label>&nbsp;{{familyDetails.minorState}}
                                    </div>
                                </div>
                                <div class="col-md-12 row ">&nbsp;</div>
                                <div class="col-md-12 row ">
                                    <div class="col-md-2 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label for="Email " id="emaillabel ">District:</label>&nbsp;{{familyDetails.minorDistrict}}
            
                                    </div>
                                    <div class="col-md-1 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label for="Email " id="emaillabel "> Address Line One:</label>&nbsp;
                                        <!-- <span *ngIf="familyDetails.minorNomineeAddress == null">DIVYANKA, 2nd cross</span> -->
                                        <span *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.minorAddress1}}</span>
            
                                    </div>
                                    <div class="col-md-1 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label id="emaillabel ">Address Line Two:</label>&nbsp;
                                        <!-- <span *ngIf="familyDetails.minorNomineeAddress == null">3rd main</span> -->
                                        <span *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.minorAddress2}}</span>
                                    </div>
            
                                    <div class="col-md-1 row ">&nbsp;</div>
                                </div>
                                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Guardian Details</h3>
                                <div class="col-md-12 row ">
                                    <div class="col-md-2 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label for="Email " id="emaillabel ">Guardian Name:</label>&nbsp;
                                        <!-- <span *ngIf="familyDetails.minorNomineeAddress == null">DIVYANKA, 2nd cross</span> -->
                                        <span *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.guardianName}}</span>
            
                                    </div>
                                    <div class="col-md-1 row ">&nbsp;</div>
                                    <div class="col-md-3 row ">
                                        <label id="emaillabel ">Relationship:</label>&nbsp;
                                        <!-- <span *ngIf="familyDetails.minorNomineeAddress == null">3rd main</span> -->
                                        <span *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.guardianReationship}}</span>
                                    </div>
            
                                    <div class="col-md-1 row ">&nbsp;</div>
                                </div>
                                <div id="familydocsid" *ngIf="familydocsexist">
                                    <div class="col-md-12 row">&nbsp;</div>
                                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>
            
                                    <div class="col-md-12 row">&nbsp;</div>
                                    <div class="col-md-12 row">
                                        <div class="col-md-1 row">&nbsp;</div>
            
                                        <div class="col-md-2 row" *ngFor="let item of familydocs">
                                            <div *ngIf="item.documentType == 'image/jpeg' ||
                                        item.documentType == 'image/png'" >
                                                <img [src]="item.file" height="100" width="100" />
                                                <div class="col-md-12 row">
                                                    <div class="col-md-2 row">&nbsp;</div>
                                                    <div class="col-md-10 row">
                                                        <p style="text-align: center;">{{item.documentName}}</p>
            
                                                    </div>
            
                                                </div>
                                            </div>
                                            <div *ngIf=" (item.documentType == 'application/pdf' ||
                                        item.documentType == 'pdf')" >
                                                <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                                <div class="col-md-12 row">
                                                    <div class="col-md-2 row">&nbsp;</div>
                                                    <div class="col-md-10 row">
                                                        <p style="text-align: center;">{{item.documentName}}</p>
            
                                                    </div>
            
                                                </div>
                                            </div>
                                            <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                        item.documentType == 'xlsx')" >
                                                <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                                <div class="col-md-12 row">
                                                    <div class="col-md-2 row">&nbsp;</div>
                                                    <div class="col-md-10 row">
                                                        <p style="text-align: center;">{{item.documentName}}</p>
            
                                                    </div>
            
                                                </div>
                                            </div>
                                            <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                                        item.documentType == 'doc' || item.documentType == 'docx')" >
                                                <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                                <div class="col-md-12 row">
                                                    <div class="col-md-2 row">&nbsp;</div>
                                                    <div class="col-md-10 row">
                                                        <p style="text-align: center;">{{item.documentName}}</p>
            
                                                    </div>
            
                                                </div>
                                            </div>
            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 row ">&nbsp;</div>
                            </div>
                        </div>
            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-12 row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Bank Details</h2>
                </div>
                <div class="col-md-2">
                    <button *ngIf="roleid!='2'" class="btn btn-primary"  (click)="bankdetailsclick()">Edit</button>
                </div>
            </div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">
                <div class="container" style="margin-top: 5%">

                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Bank Account Type</th>
                                <th>Bank Name</th>
                                <th>Branch</th>
                                <th>IFSC Code</th>
                                <th>Cheque Facility</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of bankdetails">
                                <td>{{data.banktypeid}}</td>
                                <td>{{data.bankname}} </td>
                                <td>{{data.branch}} </td>
                                <td>{{data.ifscecode}} </td>
                                <td>{{data.chequefacility}}</td>
                                <!-- <td>{{data.loanfacility}}</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12 row ">


                    <div class="col-md-12 ">&nbsp;</div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;text-align: center;    margin-left: 42%; ">Identity Details</h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Ration Card Number:</label> &nbsp;{{userBankDetails.rationCardNum}}

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Aadhar Number:</label>&nbsp;{{userBankDetails.aadharNum}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Voter ID Number:</label>&nbsp;{{userBankDetails.voterId}}

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">PAN Number:</label>&nbsp;{{userBankDetails.panNum}}

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-4 row ">
                            <label for="Email " id="emaillabel ">Service Tax Number:</label>&nbsp;{{userBankDetails.serviceTaxNum}}

                        </div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Passport Number:</label>&nbsp;{{userBankDetails.passportNum}}

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Life Insurance Value:</label>&nbsp;{{userBankDetails.lifeInsuranceValue}}

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Confirm:</label>&nbsp;{{userBankDetails.isConfirm}}

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>



                    <BR/>
                    <div class="col-md-12 row">&nbsp;</div>
                    <div class="col-md-12 row" id="bankdocsid">
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;margin-left: 15%; ">Uploaded Documents</h3> <br>
                    <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;margin-left: 15%; ">Download Documents</h5> <br>

                    <ol>
                        <li *ngIf="userBankDetails.aadharFile"><a href="{{userBankDetails.aadharFile}}">Aadhar</a></li>
                        <li *ngIf="userBankDetails.bankStatements"><a href="{{userBankDetails.bankStatements}}">Bank Statement</a></li>
                        <li *ngIf="userBankDetails.gstCert"><a href="{{userBankDetails.gstCert}}">GST</a></li>
                        <li *ngIf="userBankDetails.itReturnsFile"><a href="{{userBankDetails.itReturnsFile}}">ITR</a></li>
                        <li *ngIf="userBankDetails.passportFile"><a href="{{userBankDetails.passportFile}}">Passport</a></li>
                        <li *ngIf="userBankDetails.voterIdFile"><a href="{{userBankDetails.voterIdFile}}">Voter Id</a></li>
                    </ol>
                    <div class="col-md-12 row">&nbsp;</div>
                    <div class="col-md-12 row">&nbsp;</div>

                    <div class="col-md-2 row" *ngFor="let item of bankdocs" style="    margin-left: 8%;">
                        <div class="col-md-1 row">&nbsp;</div>

                        <div *ngIf="item.documentType == 'image/jpeg' ||
                        item.documentType == 'image/png'">
                            <img [src]="item.file" height="100" width="100" />
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-10 row">
                                    <p style="text-align: center;">{{item.documentName}}</p>
                                </div>
                            </div>
                        </div>
                        <div *ngIf=" (item.documentType == 'application/pdf' ||
                        item.documentType == 'pdf')" >
                            <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-10 row">
                                    <p style="text-align: center;">{{item.documentName}}</p>

                                </div>

                            </div>
                        </div>
                        <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        item.documentType == 'xlsx')" >
                            <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-10 row">
                                    <p style="text-align: center;">{{item.documentName}}</p>

                                </div>

                            </div>
                        </div>
                        <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        item.documentType == 'doc' || item.documentType == 'docx')" >
                            <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-10 row">
                                    <p style="text-align: center;">{{item.documentName}}</p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                    <br>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
            </div>
                        
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 ">&nbsp;</div>
                      
                        <div class="col-md-12" *ngIf="accidentinsurance" >
                            <div class="col-md-12 row">
                                <div class="col-md-4"></div>
                                <div class="col-md-4">
                                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Accident Insurance</h2>
                                </div>
                                <div class="col-md-2">
                                    <button *ngIf="roleid!='2'" class="btn btn-primary"  (click)="accidentinsuranceclick()">Edit</button>
                                </div>
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-5 row ">
                                    <label id="emaillabel ">Sources of funds: &nbsp;</label>
                                    <span *ngIf="accidentInsurancedata.sourcefund!=null">{{accidentInsurancedata.sourcefund}}</span>
                                </div>
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel ">Monthly Income: &nbsp;</label>
                                    <span>{{accidentInsurancedata.monthlyIncome}}</span>
                                </div>
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Existing Personal Accident Insurance Policy</h4>
                            <div class="col-md-12 ">&nbsp;</div>
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th style=" width: 22%;">Details</th>
                                        <th>Member 1</th>
                                        <th>Member 2</th>
                                        <th>Member 3</th>
                                        <th>Member 4</th>
                                    </tr>
                                </thead>
                        
                                <tbody>
                                    <tr>
                                        <td>Name</td>
                                        <td>
                                            {{accidentInsurancedata.personone}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.personsec}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.personsthrd}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.personsfourth}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>DOB</td>
                                        <td>
                                            {{accidentInsurancedata.dateofbirthFirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.dateofbirthsec}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.dateofbirthtrd}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.dateofbirthfourth}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Gender</td>
                                        <td>
                                            {{accidentInsurancedata.genderfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.gendersec}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.genderthrd}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.genderfour}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Nationality</td>
                                        <td>
                                            {{accidentInsurancedata.nationalityfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.nationalitysec}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.nationalitytrd}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.nationality}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Relationship with proposer</td>
                                        <td>
                                            {{accidentInsurancedata.proposertfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.proposersec}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.proposerthrd}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.proposertforth}}
                                        </td>
                                    </tr>
                        
                                    <tr>
                                        <td>Occupation</td>
                                        <td>
                                            {{accidentInsurancedata.insuranceone}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.insurancetwo}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.insurancethrd}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.insurancefour}}
                                        </td>
                        
                                    </tr>
                        
                                    <tr>
                                        <td>Annual Income</td>
                                        <td>
                                            {{accidentInsurancedata.othersfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.otherstwo}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.othersthree}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.othersfour}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Capital Sum Insured</td>
                                        <td>
                                            {{accidentInsurancedata.insuredone}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.insuredsec}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.insuredthrd}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.insuredfour}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Diabetes</td>
                                        <td>
                                            {{accidentInsurancedata.diabetesfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.diabetessecond}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.diabetesthird}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.diabetesfourth}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Marital Status</td>
                                        <td>
                                            {{accidentInsurancedata.statusfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.statussecond}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.statusthird}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.statusfourth}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Nominee</td>
                                        <td>
                                            {{accidentInsurancedata.nomineefirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.nomineesecond}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.nomineethird}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.nomineefourth}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Relationship with nominee</td>
                                        <td>
                                            {{accidentInsurancedata.relationfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.relationsecond}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.relationthird}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.relationfourth}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Medical expenses extention (Due to Accident)</td>
                                        <td>
                                            {{accidentInsurancedata.medicalfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.medicalsecond}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.medicalthird}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.medicalfourth}}
                                        </td>
                                </tbody>
                        
                            </table>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Existing Personal Accident Insurance Policy</h4>
                            <div class="col-md-12 ">&nbsp;</div>
                        
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th style=" width: 22%;">Details</th>
                                        <th>Member 1</th>
                                        <th>Member 2</th>
                                        <th>Member 3</th>
                                        <th>Member 4</th>
                                    </tr>
                                </thead>
                        
                                <tbody>
                                    <tr>
                                        <td>Name of insurer</td>
                                        <td>
                                            {{accidentInsurancedata.personone}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.personsec}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.personsthrd}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.personsfourth}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Policy no.</td>
                                        <td>
                                            {{accidentInsurancedata.policyfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.policysec}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.policythrd}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.policyfour}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Policy Period(from date)</td>
                                        <td>
                                            {{accidentInsurancedata.periodone}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.periodsecond}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.periodthrd}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.periodfourth}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Sum Insured</td>
                                        <td>
                                            {{accidentInsurancedata.sumfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.sumsecond}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.sumthird}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.sumfourth}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Claim Filed before?</td>
                                        <td>
                                            {{accidentInsurancedata.filedfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.filedsecond}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.filedthird}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.filedfourth}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Any proposal of health,life,PA or critical illness declined, rejected or charged a higher premium?</td>
                                        <td>
                                            {{accidentInsurancedata.healthfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.healthsecond}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.healththird}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.healthfourth}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Covered under any other health insurance policy?</td>
                                        <td>
                                            {{accidentInsurancedata.coveredfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.coveredsecond}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.coveredthird}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.coveredfourth}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Are any of the persons proposed for insurance covered under any other health insurance policy with the Company? (Including Critical Illness</td>
                                        <td>
                                            {{accidentInsurancedata.companyfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.companysecond}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.companythird}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.companyfourth}}
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Details of existing physical defect or infirmity</td>
                                        <td>
                                            {{accidentInsurancedata.infirmityfirst}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.infirmitysecond}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.infirmitythird}}
                                        </td>
                                        <td>
                                            {{accidentInsurancedata.infirmityfourth}}
                                        </td>
                        
                                    </tr>
                                </tbody>
                            </table>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Nominee Details(incase of death)
                            </h3>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Nominee Name: &nbsp;</label>
                                    <span>{{accidentInsurancedata.nomineename}}</span>
                                </div>
                                <div class="col-md-1">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Relationship: &nbsp;</label>
                                    <span>{{accidentInsurancedata.nomineerelationship}}</span>
                                </div>
                                <div class="col-md-1">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Address of Nominee: &nbsp;</label>
                                    <span>{{accidentInsurancedata.addressofnominee}}</span>
                                </div>
                            </div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Premium Payment Details</h4>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Cheque/DD/Credit Card/Debit Card: &nbsp;</label>
                                    <span>{{accidentInsurancedata.creditcard}}</span>
                                </div>
                                <div class="col-md-1">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Cheque/DD no./Amount: &nbsp;</label>
                                    <span>{{accidentInsurancedata.chequeamt}}</span>
                                </div>
                                <div class="col-md-1">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Bank Name: &nbsp;</label>
                                    <span>{{accidentInsurancedata.inbankname}}</span>
                                </div>
                            </div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Cheque Date: &nbsp;</label>
                                    <span>{{accidentInsurancedata.chequedate}}</span>
                                </div>
                                <div class="col-md-1">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Name of the payer: &nbsp;</label>
                                    <span>{{accidentInsurancedata.nameofpayer}}</span>
                                </div>
                                <div class="col-md-1">&nbsp;</div>
                            </div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Proposer's Bank Details</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Name of A/C holder: &nbsp;</label>
                                    <span>{{accidentInsurancedata.acholder}}</span>
                                </div>
                                <div class="col-md-1">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Bank Account no.: &nbsp;</label>
                                    <span>{{accidentInsurancedata.chequeamt}}</span>
                                </div>
                                <div class="col-md-1">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Bank Name: &nbsp;</label>
                                    <span>{{accidentInsurancedata.inbankname}}</span>
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 ">&nbsp;</div>
                        
                
                        
                        <div class="col-md-12 " *ngIf="lifeinsurance">
                            <!-- <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Life Insurance</h2>   
                                                 -->
                                                 <div class="col-md-12 row">
                                                    <div class="col-md-4"></div>
                                                    <div class="col-md-4">
                                                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Life Insurance</h2>   
                                       
                                                    </div>
                                                    <div class="col-md-2">
                                                        <button class="btn btn-primary"  (click)="lifeinsuranceclick()">Edit</button>
                                                    </div>
                                                </div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 ">&nbsp;</div>                    
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Employment Details</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Occupation:</label>
                                    <span *ngIf="userLifeInsuranceDetails.empOccupation == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.empOccupation != null">{{userLifeInsuranceDetails.empOccupation}}</span>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Nature of Work:</label>
                                    <span *ngIf="userLifeInsuranceDetails.empNatureofWork == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.empNatureofWork != null">{{userLifeInsuranceDetails.empNatureofWork}}</span>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Length of Service:</label>
                                    <span *ngIf="userLifeInsuranceDetails.emplengthofService == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.emplengthofService != null">{{userLifeInsuranceDetails.emplengthofService}}</span>
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Annual Income(In Rs.):</label>
                                    <span *ngIf="userLifeInsuranceDetails.empAnnualIncome == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.empAnnualIncome != null">{{userLifeInsuranceDetails.empAnnualIncome}}</span>
                        
                                </div>
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Details of Income proof submitted:</label>
                                    <span *ngIf="userLifeInsuranceDetails.empIncomeProof == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.empIncomeProof != null">{{userLifeInsuranceDetails.empIncomeProof}}</span>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Whether covered under pension
                                    scheme:
                                    </label>
                                    <span *ngIf="userLifeInsuranceDetails.empunderPension == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.empunderPension != null">{{userLifeInsuranceDetails.empunderPension}}</span>
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Normal retirement age:</label>
                                    <span *ngIf="userLifeInsuranceDetails.empnormalRetirement == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.empnormalRetirement != null">{{userLifeInsuranceDetails.empnormalRetirement}}</span>
                                </div>
                            </div>
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">
                                Pension Details</h4>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Employer’s Scheme/Insurance:</label>
                                    <span *ngIf="userLifeInsuranceDetails.empschemeInsurance == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.empschemeInsurance != null">{{userLifeInsuranceDetails.empschemeInsurance}}</span>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                        
                                    <label for="Email " id="emaillabel ">Personal contribution/Premium:</label>
                                    <span *ngIf="userLifeInsuranceDetails.emppersonalPremium == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.emppersonalPremium != null">{{userLifeInsuranceDetails.emppersonalPremium}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                        
                                    <label for="Email " id="emaillabel ">Retirement age:</label>
                                    <span *ngIf="userLifeInsuranceDetails.empretirementage == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.empretirementage != null">{{userLifeInsuranceDetails.empretirementage}}</span>
                        
                                </div>
                            </div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Anticipated value</label>
                                    <span *ngIf="userLifeInsuranceDetails.empanticipatedValue == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.empanticipatedValue != null">{{userLifeInsuranceDetails.empanticipatedValue}}</span>
                                </div>
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-8 row ">
                                    <label for="Email " id="emaillabel ">How do you project your work span?</label>
                                    <span *ngIf="userLifeInsuranceDetails.projectWorkSpanNameString == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.projectWorkSpanNameString != null">{{userLifeInsuranceDetails.projectWorkSpanNameString}}</span>
                                </div>
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">
                                Income/Expenditure --Current and projected</h4>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-12 row">
                        
                        
                        
                                    <table>
                                        <tr>
                                            <th>YEAR</th>
                                            <th>Income (In Rs.)</th>
                                            <th>Expenditure (In Rs.)</th>
                        
                                        </tr>
                                        <tr>
                                            <td>Last Year</td>
                                            <td>{{userLifeInsuranceDetails.incomelastyear}}</td>
                                            <td>{{userLifeInsuranceDetails.explastyear}}</td>
                        
                                        </tr>
                                        <tr>
                                            <td>Current Year</td>
                                            <td>{{userLifeInsuranceDetails.incomecuryear}}</td>
                                            <td>{{userLifeInsuranceDetails.expcuryear}}</td>
                        
                                        </tr>
                                        <tr>
                                            <td>5-10 Years</td>
                                            <td>{{userLifeInsuranceDetails.income5to10}}</td>
                                            <td>{{userLifeInsuranceDetails.exp5to10}}</td>
                        
                                        </tr>
                                        <tr>
                                            <td>10-15 Years</td>
                                            <td>{{userLifeInsuranceDetails.income10to15}}</td>
                                            <td>{{userLifeInsuranceDetails.exp10to15}}</td>
                        
                                        </tr>
                                        <tr>
                                            <td>15-20 Years</td>
                                            <td>{{userLifeInsuranceDetails.income15to20}}</td>
                                            <td>{{userLifeInsuranceDetails.exp15to20}}</td>
                        
                                        </tr>
                                        <tr>
                                            <td>20-25 Years</td>
                                            <td>{{userLifeInsuranceDetails.income20to25}}</td>
                                            <td>{{userLifeInsuranceDetails.exp20to25}}</td>
                        
                                        </tr>
                                        <tr>
                                            <td>25-30 Years</td>
                                            <td>{{userLifeInsuranceDetails.income25to30}}</td>
                                            <td>{{userLifeInsuranceDetails.exp25to30}}</td>
                        
                                        </tr>
                                    </table>
                        
                        
                        
                        
                        
                        
                                    <br/>
                        
                        
                                </div>
                            </div>
                        
                        
                            <div class="col-md-12">&nbsp;</div>
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">
                                Financial Details</h4>
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Value of savings and assets</label>
                                    <span *ngIf="userLifeInsuranceDetails.finsavings == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.finsavings != null">{{userLifeInsuranceDetails.finsavings}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Details of liabilities</label>
                                    <span *ngIf="userLifeInsuranceDetails.finliabilities == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.finliabilities != null">{{userLifeInsuranceDetails.finliabilities}}</span>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Expected inheritance</label>
                                    <span *ngIf="userLifeInsuranceDetails.fininheritance == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.fininheritance != null">{{userLifeInsuranceDetails.fininheritance}}</span>
                                </div>
                            </div>
                        
                            <div class="col-md-12 row">&nbsp;</div>
                        
                            <!-- LIFE HEALTH -->
                        
                            <table>
                                <tr>
                                    <th></th>
                                    <th>Name of Member (whether proposer or covered person)</th>
                                    <th>Details of premium being paid</th>
                                    <th>Name of Member (whether proposer or covered person)</th>
                                    <th>Details of premium being paid</th>
                        
                                </tr>
                                <tr>
                                    <td>Life</td>
                                    <td>{{userLifeInsuranceDetails.lifeNameMemberOne}}</td>
                                    <td>{{userLifeInsuranceDetails.lifeDetailsone}}</td>
                                    <td>{{userLifeInsuranceDetails.lifeNameMemberTwo}}</td>
                                    <td>{{userLifeInsuranceDetails.lifeDetailsTwo}}</td>
                        
                                </tr>
                                <tr>
                                    <td>Health</td>
                                    <td>{{userLifeInsuranceDetails.healthNameMemberOne}}</td>
                                    <td>{{userLifeInsuranceDetails.healthDetailsone}}</td>
                                    <td>{{userLifeInsuranceDetails.healthNameMemberTwo}}</td>
                                    <td>{{userLifeInsuranceDetails.healthDetailsTwo}}</td>
                        
                                </tr>
                                <tr>
                                    <td>Savings and Investment</td>
                                    <td>{{userLifeInsuranceDetails.savingsNameMemberOne}}</td>
                                    <td>{{userLifeInsuranceDetails.savingsDetailsone}}</td>
                                    <td>{{userLifeInsuranceDetails.savingsNameMemberTwo}}</td>
                                    <td>{{userLifeInsuranceDetails.savingsDetailsTwo}}</td>
                                </tr>
                                <tr>
                                    <td>Pension</td>
                                    <td>{{userLifeInsuranceDetails.pensionNameMember}}</td>
                                    <td>{{userLifeInsuranceDetails.pensionDetailsone}}</td>
                                    <td>{{userLifeInsuranceDetails.pensionNameMemberTwo}}</td>
                                    <td>{{userLifeInsuranceDetails.pensionDetailsTwo}}</td>
                                </tr>
                                <tr>
                                    <td>Other ( to specify)</td>
                                    <td>{{userLifeInsuranceDetails.otheNameofMember}}</td>
                                    <td>{{userLifeInsuranceDetails.otherDetailsone}}</td>
                                    <td>{{userLifeInsuranceDetails.otherNameMemberTwo}}</td>
                                    <td>{{userLifeInsuranceDetails.otherDetailsTwo}}</td>
                        
                                </tr>
                        
                            </table>
                        
                        
                        
                            <br/>
                        
                            <div class="col-md-12">&nbsp;</div>
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">
                                B. SPECIALISED/ADDITIONAL INFORMATION</h4>
                            <div class="col-md-12">&nbsp;</div>
                            <label>Physical/Medical Information (Questionnaire)—for each covered person</label>
                            <div class="col-md-12">&nbsp;</div>
                        
                        
                        
                            <div class="col-md-12 row">
                                <div class="col-md-6">
                                    <label>Height</label>
                        
                                    <span *ngIf="userLifeInsuranceDetails.phyHeight == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.phyHeight != null">{{userLifeInsuranceDetails.phyHeight}}</span>
                        
                                </div>
                                <div class="col-md-6">
                                    <label>Weight</label>
                                    <span *ngIf="userLifeInsuranceDetails.phyWeight == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.phyWeight != null">{{userLifeInsuranceDetails.phyWeight}}</span>
                        
                                </div>
                            </div>
                        
                            <div class="col-md-12">&nbsp;</div>
                            <div>
                                <div class="col-md-12" style="text-align: left;">
                                    <span id="showpinsText">
                                    Do you have any physical deformity/handicap/congenital 
                                    defect/abnormality?
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.physicaltg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                                    <span id="showpinsText">
                                    Are you currently undergoing/have undergone any tests, investigations,
                                     awaiting results of any tests, investigations or have you ever 
                                     been advised to undergo any tests, investigations or
                                    surgery or been hospitalized for general checkup, observations, 
                                    treatment or surgery?
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.surgerytg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                                    <span id="showpinsText">
                                    Have you ever been referred to an Oncologist or Cancer hospital for any investigation or treatment?                       
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Oncologisttg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                                    <span id="showpinsText">
                                    Did you have any ailment/injury/accident requiring treatment/medication 
                                    for more than a	week?         
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.ailmenttg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                                    <span id="showpinsText">
                                    Have you ever been absent from work for more than a week in last 2 years due to 
                                    any illness?
                                    </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.absentfromworktg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                                    <span id="showpinsText">
                                    Were you or your spouse ever tested for Hepatitis B or C, HIV/AIDS or any other sexually
                                    transmitted disease?                 
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Hepatitistg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                                    <span id="showpinsText">
                                    Have you ever suffered Chest pain, Palpitation, Rheumatic fever, Heart Murmur, Heart attack,
                                    shortness of Breath or any other Heart related disorder?                  
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Rheumatictg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                                    <span id="showpinsText">
                                    Have you ever suffered symptoms/ailment relating to Kidney, Prostate,
                                    Hydrocele, And Urinary System?                  
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Kidneytg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                                    <span id="showpinsText">
                                    Have you ever suffered Gastritis, Stomach or Duodenal Ulcer, Hernia, Jaundice, Fistula Piles or
                                     any other disease or disorders of the Gastrointestinal System?                 
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Gastritistg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                        
                                    <span id="showpinsText">
                                    Have you ever suffered Thyroid disorder or any other disease or disorder of the 
                                    Endocrine system?                 
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Thyroidtg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                        
                                    <span id="showpinsText">
                                    Have you undergone/have been recommended to undergo Angioplasty, Bypass 
                                    Surgery, Brain
                                    Surgery, Heart Valve Surgery, Aorta Surgery or Organ Transplant?             
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Angioplastytg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                        
                                    <span id="showpinsText">
                                    Have you ever suffered Diabetes/ High Blood Sugar?            
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Diabetestg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                        
                                    <span id="showpinsText">
                                    Have you ever suffered High/Low Blood Pressure?             
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Bloodpressuretg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                        
                                    <span id="showpinsText">
                                    Have you ever suffered Disorders of Eye, Ear, Nose,
                                     Throat including defective sight, speech or hearing & discharge from ears?        
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Throattg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                        
                                    <span id="showpinsText">
                                    Have you ever suffered ailments relating to Liver or reproductive System?       
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Livertg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                        
                                    <span id="showpinsText">
                                    Have you ever suffered Symptoms/ailments relating to Brian, Depression? Mental/Psychiatric	
                                     ailment, Multiple Sclerosis, Nervous System, Stroke, Paralysis, Parkinsonism or Epilepsy?       
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Sclerosistg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                        
                                    <span id="showpinsText">
                                    Have you ever suffered Asthma, Bronchitis, Blood spitting, 
                                    Tuberculosis or other Respiratory disorders?    
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Bronchitistg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                        
                                    <span id="showpinsText">
                                    Have you ever suffered Anaemia, Blood or Blood related disorders?     
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Anaemiatg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                        
                                    <span id="showpinsText">
                                    Have you ever suffered Musculoskeletal disorders such as Arthritis,
                                     Recurrent Back Pain, Slipped disc or any other disorder of Spine, Joints, Limbs or Leprosy?   
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.Musculoskeletaltg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                        
                                    <span id="showpinsText">
                                    Have you ever suffered any other illness or impairment not mentioned above?
                                  </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.impairmenttg">
                                    </mat-slide-toggle>
                                </div>
                        
                        
                        
                                <div class="col-md-12">&nbsp;</div>
                                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">
                                    Details of Female Prospects /covered person</h4>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                                    <span id="showpinsText">
                                      Are you Currently Pregnant?
                                    </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.curpregnanttg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12 row" style="text-align: left;">
                        
                                    <div class="col-md-6">
                                        <span id="showpinsText">&nbsp;&nbsp;
                                        If yes, current months of pregnancy 
                                      </span>
                                        <span *ngIf="userLifeInsuranceDetails.pregmonths == null">Single</span>
                                        <span *ngIf="userLifeInsuranceDetails.pregmonths != null">{{userLifeInsuranceDetails.pregmonths}}</span>
                        
                                    </div>
                                    <div class="col-md-6">
                                        <span id="showpinsText">
                                        State age of first pregnancy
                                      </span>
                                        <span *ngIf="userLifeInsuranceDetails.stageage == null">Single</span>
                                        <span *ngIf="userLifeInsuranceDetails.stageage != null">{{userLifeInsuranceDetails.stageage}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                        
                                <div class="col-md-12" style="text-align: left;">
                                    <span id="showpinsText">
                                      Have you ever had any abortion, miscarriage or ectopic pregnancy?
                                    </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.miscariagetg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                                    <span id="showpinsText">
                                      Have you undergone any gynecological investigations, 
                                      internal checkups, breast checks such as mammogram or biopsy?                            </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.gynecologicaltg">
                                    </mat-slide-toggle>
                                </div>
                                <div class="col-md-12">&nbsp;</div>
                        
                                <div class="col-md-12" style="text-align: left;">
                                    <span id="showpinsText">
                                      Have you ever consulted a doctor because of an 
                                      irregularity at the breast, vagina, uterus,
                                      ovary, fallopian tubes, menstruation, birth delivery, 
                                       complications during pregnancy or
                                      child delivery or a sexually transmitted disease?
                                   </span>
                                    <mat-slide-toggle [(ngModel)]="userLifeInsuranceDetails.transmittedtg">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                            <!-- MINI -->
                            <div class="col-md-12 row">
                                <div class="col-md-4">
                                </div>
                                <div class="col-md-4">
                                    <!-- Uploaded image put here -->
                                    <img [src]="dispProposerSignFile" *ngIf="dispProposerSignFile" height="100" width="100" />
                        
                                </div>
                                <div class="col-md-4">
                                </div>
                        
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12">&nbsp;</div>
                        
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">
                                C. NEEDS OF PROSPECT</h4>
                            <div class="col-md-12">&nbsp;</div>
                        
                            <h5>1. Affordable contribution—Current and projected</h5>
                        
                            <table>
                                <tr>
                                    <th>YEAR</th>
                                    <th>Yearly</th>
                                    <th>Monthly</th>
                        
                                </tr>
                                <tr>
                                    <td>Last Year</td>
                                    <td>{{userLifeInsuranceDetails.prospectlastyear}}</td>
                                    <td>{{userLifeInsuranceDetails.prospectMonthlylastyear}}</td>
                        
                                </tr>
                                <tr>
                                    <td>Current Year</td>
                                    <td>{{userLifeInsuranceDetails.prospectcuryear}}</td>
                                    <td>{{userLifeInsuranceDetails.prospectMonthlycuryear}}</td>
                        
                                </tr>
                                <tr>
                                    <td>5-10 Years</td>
                                    <td>{{userLifeInsuranceDetails.prospect5to10}}</td>
                                    <td>{{userLifeInsuranceDetails.prospectMonthlylastyear}}</td>
                        
                                </tr>
                                <tr>
                                    <td>10-15 Years</td>
                                    <td>{{userLifeInsuranceDetails.prospect10to15}}</td>
                                    <td>{{userLifeInsuranceDetails.prospectMonthlylastyear}}</td>
                        
                                </tr>
                                <tr>
                                    <td>15-20 Years</td>
                                    <td>{{userLifeInsuranceDetails.prospect15to20}}</td>
                                    <td>{{userLifeInsuranceDetails.prospectMonthly15to20}}</td>
                        
                                </tr>
                                <tr>
                                    <td>20-25 Years</td>
                                    <td>{{userLifeInsuranceDetails.prospect20to25}}</td>
                                    <td>{{userLifeInsuranceDetails.prospectMonthly20to25}}</td>
                        
                                </tr>
                                <tr>
                                    <td>25-30 Years</td>
                                    <td>{{userLifeInsuranceDetails.prospect25to30}}</td>
                                    <td>{{userLifeInsuranceDetails.prospectMonthly25to30}}</td>
                        
                                </tr>
                            </table>
                        
                        
                        
                        
                            <div class="col-md-12">&nbsp;</div>
                        
                            <div class="col-md-12">&nbsp;</div>
                            <h5>2. Identified insurance needs
                            </h5>
                            <div class="col-md-12 row">
                                <div class="col-md-6">
                                    <label>Life Insurance ( Death/Maturity) </label>
                                    <span *ngIf="userLifeInsuranceDetails.lifeincDeathMaturity == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.lifeincDeathMaturity != null">{{userLifeInsuranceDetails.lifeincDeathMaturity}}</span>
                        
                                </div>
                                <div class="col-md-6">
                                    <label>Desirable Sum Assured</label>
                                    <span *ngIf="userLifeInsuranceDetails.desirableSumAssured == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.desirableSumAssured != null">{{userLifeInsuranceDetails.desirableSumAssured}}</span>
                        
                                </div>
                            </div>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-6">
                                    <label>Health Insurance</label>
                                    <span *ngIf="userLifeInsuranceDetails.healthInsurance == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.healthInsurance != null">{{userLifeInsuranceDetails.healthInsurance}}</span>
                        
                                </div>
                                <div class="col-md-6">
                                    <label>Desirable limit of coverage per annum</label>
                                    <span *ngIf="userLifeInsuranceDetails.desirablecoverageperAnnum == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.desirablecoverageperAnnum != null">{{userLifeInsuranceDetails.desirablecoverageperAnnum}}</span>
                                </div>
                            </div>
                            <div class="col-md-12 row">
                                <div class="col-md-6">
                                    <label>Savings and Investment Planning </label>
                                    <span *ngIf="userLifeInsuranceDetails.savingandinvestplan == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.savingandinvestplan != null">{{userLifeInsuranceDetails.savingandinvestplan}}</span>
                                </div>
                                <div class="col-md-6">
                                    <label>Desirable returns per annum </label>
                                    <span *ngIf="userLifeInsuranceDetails.desirablereturns == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.desirablereturns != null">{{userLifeInsuranceDetails.desirablereturns}}</span>
                                </div>
                            </div>
                            <div class="col-md-12 row">
                                <div class="col-md-6">
                                    <label>Pension planning </label>
                                    <span *ngIf="userLifeInsuranceDetails.pensionPlanning == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.pensionPlanning != null">{{userLifeInsuranceDetails.pensionPlanning}}</span>
                        
                                </div>
                                <div class="col-md-6">
                                    <label>Desirable pension per annum</label>
                                    <span *ngIf="userLifeInsuranceDetails.desirablepensionperAnnum == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.desirablepensionperAnnum != null">{{userLifeInsuranceDetails.desirablepensionperAnnum}}</span>
                                </div>
                            </div>
                        
                        
                        
                        
                        
                            <div class="col-md-12">&nbsp;</div>
                        
                            <h5>3. Insurance Plan Details </h5>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-6">
                                    <label>Plan Name </label>
                                    <span *ngIf="userLifeInsuranceDetails.incPlanName == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.incPlanName != null">{{userLifeInsuranceDetails.incPlanName}}</span>
                                </div>
                                <div class="col-md-6">
                                    <label>Premium Type </label>
                                    <span *ngIf="userLifeInsuranceDetails.incpremiumType == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.incpremiumType != null">{{userLifeInsuranceDetails.incpremiumType}}</span>
                                </div>
                            </div>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-6">
                                    <label>Payment Mode </label>
                                    <span *ngIf="userLifeInsuranceDetails.incPaymentMode == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.incPaymentMode != null">{{userLifeInsuranceDetails.incPaymentMode}}</span>
                                </div>
                                <div class="col-md-6">
                                    <label>Payment Method  </label>
                                    <span *ngIf="userLifeInsuranceDetails.incpaymentmethod == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.incpaymentmethod != null">{{userLifeInsuranceDetails.incpaymentmethod}}</span>
                                </div>
                            </div>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-6">
                                    <label>Premium Term </label>
                                    <span *ngIf="userLifeInsuranceDetails.incPremiumTerm == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.incPremiumTerm != null">{{userLifeInsuranceDetails.incPremiumTerm}}</span>
                                </div>
                                <div class="col-md-6">
                                    <label>Coverage Term  </label>
                                    <span *ngIf="userLifeInsuranceDetails.inccoverageaTerm == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.inccoverageaTerm != null">{{userLifeInsuranceDetails.inccoverageaTerm}}</span>
                                </div>
                            </div>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-6">
                                    <label>Sum Assured </label>
                                    <span *ngIf="userLifeInsuranceDetails.incSumAssured == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.incSumAssured != null">{{userLifeInsuranceDetails.incSumAssured}}</span>
                                </div>
                                <div class="col-md-6">
                                    <label>Benefits/Riders/Fund Allocation</label>
                                    <span *ngIf="userLifeInsuranceDetails.incBenefits == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.incBenefits != null">{{userLifeInsuranceDetails.incBenefits}}</span>
                                </div>
                            </div>
                        
                        
                            <div class="col-md-12">&nbsp;</div>
                        
                        
                            <h5>4. Identified Life needs</h5>
                        
                        
                        
                        
                            <table>
                                <tr>
                                    <th>ITEM/YEAR</th>
                                    <th>Food, shelter, clothing and other living expenses such as transportation expenses, utilities etc</th>
                                    <th>Education Expenses</th>
                                    <th>Marriage Expenses</th>
                        
                                </tr>
                                <tr>
                                    <td>Last Year</td>
                                    <td>{{userLifeInsuranceDetails.foodlastyear}}</td>
                                    <td>{{userLifeInsuranceDetails.edulastyear}}</td>
                                    <td>{{userLifeInsuranceDetails.marlastyear}}</td>
                        
                                </tr>
                                <tr>
                                    <td>Current Year</td>
                                    <td>{{userLifeInsuranceDetails.foodcuryear}}</td>
                                    <td>{{userLifeInsuranceDetails.educuryear}}</td>
                                    <td>{{userLifeInsuranceDetails.marcuryear}}</td>
                        
                                </tr>
                                <tr>
                                    <td>5-10 Years</td>
                                    <td>{{userLifeInsuranceDetails.food5to10}}</td>
                                    <td>{{userLifeInsuranceDetails.edu5to10}}</td>
                                    <td>{{userLifeInsuranceDetails.mar5to10}}</td>
                        
                                </tr>
                                <tr>
                                    <td>10-15 Years</td>
                                    <td>{{userLifeInsuranceDetails.food10to15}}</td>
                                    <td>{{userLifeInsuranceDetails.edu10to15}}</td>
                                    <td>{{userLifeInsuranceDetails.mar10to15}}</td>
                        
                                </tr>
                                <tr>
                                    <td>15-20 Years</td>
                                    <td>{{userLifeInsuranceDetails.food15to20}}</td>
                                    <td>{{userLifeInsuranceDetails.edu15to20}}</td>
                                    <td>{{userLifeInsuranceDetails.mar15to20}}</td>
                        
                                </tr>
                                <tr>
                                    <td>20-25 Years</td>
                                    <td>{{userLifeInsuranceDetails.food20to25}}</td>
                                    <td>{{userLifeInsuranceDetails.edu20to25}}</td>
                                    <td>{{userLifeInsuranceDetails.mar20to25}}</td>
                        
                                </tr>
                                <tr>
                                    <td>25-30 Years</td>
                                    <td>{{userLifeInsuranceDetails.food25to30}}</td>
                                    <td>{{userLifeInsuranceDetails.edu25to30}}</td>
                                    <td>{{userLifeInsuranceDetails.mar25to30}}</td>
                        
                                </tr>
                            </table>
                        
                            <table>
                                <tr>
                                    <th>ITEM/YEAR</th>
                                    <th>Vacations and other travel expenses</th>
                                    <th>Other commitments such as insurance premium, various contributions etc</th>
                                    <th>TOTAL</th>
                        
                                </tr>
                                <tr>
                                    <td>Last Year</td>
                                    <td>{{userLifeInsuranceDetails.vacationlastyear}}</td>
                                    <td>{{userLifeInsuranceDetails.otherlastyear}}</td>
                                    <td>{{userLifeInsuranceDetails.totallastyear}}</td>
                        
                                </tr>
                                <tr>
                                    <td>Current Year</td>
                                    <td>{{userLifeInsuranceDetails.vacationcuryear}}</td>
                                    <td>{{userLifeInsuranceDetails.othercuryear}}</td>
                                    <td>{{userLifeInsuranceDetails.totalcuryear}}</td>
                        
                                </tr>
                                <tr>
                                    <td>5-10 Years</td>
                                    <td>{{userLifeInsuranceDetails.vacation5to10}}</td>
                                    <td>{{userLifeInsuranceDetails.other5to10}}</td>
                                    <td>{{userLifeInsuranceDetails.total5to10}}</td>
                        
                                </tr>
                                <tr>
                                    <td>10-15 Years</td>
                                    <td>{{userLifeInsuranceDetails.vacation10to15}}</td>
                                    <td>{{userLifeInsuranceDetails.other10to15}}</td>
                                    <td>{{userLifeInsuranceDetails.total10to15}}</td>
                        
                                </tr>
                                <tr>
                                    <td>15-20 Years</td>
                                    <td>{{userLifeInsuranceDetails.vacation15to20}}</td>
                                    <td>{{userLifeInsuranceDetails.other15to20}}</td>
                                    <td>{{userLifeInsuranceDetails.total15to20}}</td>
                        
                                </tr>
                                <tr>
                                    <td>20-25 Years</td>
                                    <td>{{userLifeInsuranceDetails.vacation20to25}}</td>
                                    <td>{{userLifeInsuranceDetails.other20to25}}</td>
                                    <td>{{userLifeInsuranceDetails.total20to25}}</td>
                        
                                </tr>
                                <tr>
                                    <td>25-30 Years</td>
                                    <td>{{userLifeInsuranceDetails.vacation25to30}}</td>
                                    <td>{{userLifeInsuranceDetails.other25to30}}</td>
                                    <td>{{userLifeInsuranceDetails.total25to30}}</td>
                        
                                </tr>
                            </table>
                        
                        
                        
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">
                                D. RECOMMENDATION</h4>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">1.	Life stage</label>
                                    <span *ngIf="userLifeInsuranceDetails.lifeStageNameString == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.lifeStageNameString != null">{{userLifeInsuranceDetails.lifeStageNameString}}</span>
                        
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">2.	Protection needs</label>
                                    <span *ngIf="userLifeInsuranceDetails.protectionNeedNameString == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.protectionNeedNameString != null">{{userLifeInsuranceDetails.protectionNeedNameString}}</span>
                        
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel "> 3.	Appetite for risk</label>
                                    <span *ngIf="userLifeInsuranceDetails.appetiteNameString == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.appetiteNameString != null">{{userLifeInsuranceDetails.appetiteNameString}}</span>
                        
                                </div>
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12 row">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-5">
                                    <label>4.	Policy recommended, including name of insurer</label>
                                    <span *ngIf="userLifeInsuranceDetails.recommendedpolicy == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.recommendedpolicy != null">{{userLifeInsuranceDetails.recommendedpolicy}}</span>
                        
                                </div>
                                <div class="col-md-5">
                                    <label>5.	Details of commitment for the
                                        current and future years
                                        </label>
                                    <span *ngIf="userLifeInsuranceDetails.recommendedcommit == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.recommendedcommit != null">{{userLifeInsuranceDetails.recommendedcommit}}</span>
                        
                                </div>
                        
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12 row">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-5">
                                    <label>6. Whether all risk elements and
                                        details of charges to be incurred and 
                                        all other obligations have been explained
                                        </label>
                                    <span *ngIf="userLifeInsuranceDetails.recommendedRisk == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.recommendedRisk != null">{{userLifeInsuranceDetails.recommendedRisk}}</span>
                        
                                </div>
                                <div class="col-md-5">
                                    <label>7.	Why you think this policy is most
                                        suited for the prospect
                                        </label>
                                    <span *ngIf="userLifeInsuranceDetails.recommendedsuitedPolicy == null">Single</span>
                                    <span *ngIf="userLifeInsuranceDetails.recommendedsuitedPolicy != null">{{userLifeInsuranceDetails.recommendedsuitedPolicy}}</span>
                        
                                </div>
                            </div>
                        
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12">&nbsp;</div>
                        
                            <p style="float: left;">
                                <u>Agent/Broker’s Certification:</u></p>
                        
                            <p> &nbsp;I /We hereby certify that I/we believe that the product/s recommended me/us above is suitable for the prospect, bas on the information submitted by him/her, as recorded above.</p>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-6" style="float:left;text-align: left;">
                                    <p>Dated: {{userLifeInsuranceDetails.todaysdateAgent}}</p>
                                </div>
                                <div class="col-md-6" style="float: right;">
                        
                                    <img [src]="dispAgentSignFile" *ngIf="dispAgentSignFile" height="100" width="100" /> (Signature of Agent/Broker)
                                </div>
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                            <p style="float: left;">
                                <u>Prospect’s Acknowledgement:</u></p>
                            <p> &nbsp;The above recommendation is based on the information provided by me. I have been explained about the features of the product and believe it would be suitable for me based on my insurance needs and financial objectives.</p>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-6" style="float:left;text-align: left;">
                                    <p>Dated: {{userLifeInsuranceDetails.todaysdateprospect}}</p>
                                </div>
                                <div class="col-md-6" style="float: right;">
                        
                        
                                    <img [src]="dispProspectsSignFile" *ngIf="dispProspectsSignFile" height="100" width="100" /> (Signature of Prospect)
                                </div>
                            </div>
                        
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                        </div>
                        
                        
                        
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 ">&nbsp;</div>
                  
                        <div class="col-md-12 " *ngIf="healthinsurance">
                            <div class="col-md-12 row">
                                <div class="col-md-4"></div>
                                <div class="col-md-4">
                                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Health Insurance</h2>
                                </div>
                                <div class="col-md-2">
                                    <button *ngIf="roleid!='2'" class="btn btn-primary"  (click)="healthinsuranceclick()">Edit</button>
                                </div>
                            </div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of person(s) proposed to be insured</h4>
                            <div class="col-md-12 ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Sources of funds:</label>
                                    <span *ngIf="healthinsurancedetails.sourcefund != null">{{healthinsurancedetails.sourcefund}}</span>
                        
                                </div>
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Monthly Income:</label>
                                    <span *ngIf="healthinsurancedetails.monthincome != null">{{healthinsurancedetails.monthincome}}</span>
                        
                                </div>
                            </div>
                            <div class="col-md-12 ">&nbsp;</div>
                        
                        
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th style=" width: 22%;">Details</th>
                                        <th>Member 1</th>
                                        <th>Member 2</th>
                                        <th>Member 3</th>
                                        <th>Member 4</th>
                                    </tr>
                                </thead>
                        
                                <tbody>
                                    <tr>
                                        <td>Name</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.detailsfirst != null">{{healthinsurancedetails.detailsfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.detailsthrd != null">{{healthinsurancedetails.detailsthrd}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.detailsthird != null">{{healthinsurancedetails.detailsthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.detailsfour != null">{{healthinsurancedetails.detailsfour}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>DOB</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.dateofbirthFirst != null">{{healthinsurancedetails.dateofbirthFirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.dateofbirthsec != null">{{healthinsurancedetails.dateofbirthsec}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.dateofbirthtrd != null">{{healthinsurancedetails.dateofbirthtrd}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.dateofbirthfourth != null">{{healthinsurancedetails.dateofbirthfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Gender</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.genderfirst != null">{{healthinsurancedetails.genderfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.gendersec != null">{{healthinsurancedetails.gendersec}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.genderthrd != null">{{healthinsurancedetails.genderthrd}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.genderfour != null">{{healthinsurancedetails.genderfour}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Nationality</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.nationalityfirst != null">{{healthinsurancedetails.nationalityfirst}}</span>  </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.nationalitysec != null">{{healthinsurancedetails.nationalitysec}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.nationalitytrd != null">{{healthinsurancedetails.nationalitytrd}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.nationality != null">{{healthinsurancedetails.nationality}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Relationship with proposer</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.proposertfirst != null">{{healthinsurancedetails.proposertfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.proposersec != null">{{healthinsurancedetails.proposersec}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.proposerthrd != null">{{healthinsurancedetails.proposerthrd}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.proposertforth != null">{{healthinsurancedetails.proposertforth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Marital Status</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.statusfirst != null">{{healthinsurancedetails.statusfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.statussecond != null">{{healthinsurancedetails.statussecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.statusthird != null">{{healthinsurancedetails.statusthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.statusfourth != null">{{healthinsurancedetails.statusfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Occupation</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.insuranceone != null">{{healthinsurancedetails.insuranceone}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.insurancetwo != null">{{healthinsurancedetails.insurancetwo}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.insurancethrd != null">{{healthinsurancedetails.insurancethrd}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.insurancefour != null">{{healthinsurancedetails.insurancefour}}</span>
                                        </td>
                        
                                    </tr>
                        
                                    <tr>
                                        <td>Height(in cm)</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.othersfirst != null">{{healthinsurancedetails.Oothersfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.otherstwo != null">{{healthinsurancedetails.otherstwo}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.othersthree != null">{{healthinsurancedetails.othersthree}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.othersfour != null">{{healthinsurancedetails.othersfour}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Weight (in kg)</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.insuredone != null">{{healthinsurancedetails.insuredone}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.insuredsec != null">{{healthinsurancedetails.insuredsec}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.insuredthrd != null">{{healthinsurancedetails.insuredthrd}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.insuredfour != null">{{healthinsurancedetails.insuredfour}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Diabetes</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.diabetesfirst != null">{{healthinsurancedetails.diabetesfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.diabetessecond != null">{{healthinsurancedetails.diabetessecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.diabetesthird != null">{{healthinsurancedetails.diabetesthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.diabetesfourth != null">{{healthinsurancedetails.diabetesfourth}}</span>
                                        </td>
                        
                                    </tr>
                        
                                    <tr>
                                        <td>Hypertension</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.nomineefirst != null">{{healthinsurancedetails.nomineefirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.nomineesecond != null">{{healthinsurancedetails.nomineesecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.nomineethird != null">{{healthinsurancedetails.nomineethird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.nomineefourth != null">{{healthinsurancedetails.nomineefourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Respiratory disorder</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.relationfirst != null">{{healthinsurancedetails.relationfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.relationsecond != null">{{healthinsurancedetails.relationsecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.relationthird != null">{{healthinsurancedetails.relationthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.relationfourth != null">{{healthinsurancedetails.relationfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>HIV/AIDS/STD</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.medicalfirst != null">{{healthinsurancedetails.medicalfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.medicalsecond != null">{{healthinsurancedetails.medicalsecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.medicalthird != null">{{healthinsurancedetails.medicalthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.medicalfourth != null">{{healthinsurancedetails.medicalfourth}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Liver disease </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.liverfirst != null">{{healthinsurancedetails.liverfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.liversecond != null">{{healthinsurancedetails.liversecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.liverthird != null">{{healthinsurancedetails.liverthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.liverfourth != null">{{healthinsurancedetails.liverfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Cancer/Tumor</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.cancerfirst != null">{{healthinsurancedetails.cancerfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.cancersecond != null">{{healthinsurancedetails.cancersecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.cancerthird != null">{{healthinsurancedetails.cancerthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.cancerfourth != null">{{healthinsurancedetails.cancerfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Heart disease</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.heartfirst != null">{{healthinsurancedetails.heartfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.heartsecond != null">{{healthinsurancedetails.heartsecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.heartthird != null">{{healthinsurancedetails.heartthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.heartfourth != null">{{healthinsurancedetails.heartfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Arthritis/Joint pain</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.arthritisfirst != null">{{healthinsurancedetails.arthritisfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.arthritissecond != null">{{healthinsurancedetails.arthritissecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.arthritisthird != null">{{healthinsurancedetails.arthritisthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.arthritisfourth != null">{{healthinsurancedetails.arthritisfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Kidney Disease</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.kidneyfirst != null">{{healthinsurancedetails.kidneyfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.kidneysecond != null">{{healthinsurancedetails.kidneysecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.kidneythird != null">{{healthinsurancedetails.kidneythird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.kidneyfourth != null">{{healthinsurancedetails.kidneyfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Paralysis/Stroke</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.paralysisfirst != null">{{healthinsurancedetails.paralysisfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.paralysissecond != null">{{healthinsurancedetails.paralysissecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.paralysisthird != null">{{healthinsurancedetails.paralysisthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.paralysisfourth != null">{{healthinsurancedetails.paralysisfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Congenital Disease</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.congenitalfirst != null">{{healthinsurancedetails.congenitalfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.congenitalsecond != null">{{healthinsurancedetails.congenitalsecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.congenitalthird != null">{{healthinsurancedetails.congenitalthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.congenitalfourth != null">{{healthinsurancedetails.congenitalfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Injurt</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.injurtfirst != null">{{healthinsurancedetails.injurtfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.injurtsecond != null">{{healthinsurancedetails.injurtsecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.injurtthird != null">{{healthinsurancedetails.injurtthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.injurtfourth != null">{{healthinsurancedetails.injurtfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Other(Specify)</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.specifyfirst != null">{{healthinsurancedetails.specifyfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.specifysecond != null">{{healthinsurancedetails.specifysecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.specifythird != null">{{healthinsurancedetails.specifythird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.specifyfourth != null">{{healthinsurancedetails.specifyfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Details of Drugs/Medicine prescribed</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.drugsfirst != null">{{healthinsurancedetails.drugsfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.drugssecond != null">{{healthinsurancedetails.drugssecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.drugsthird != null">{{healthinsurancedetails.drugsthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.drugsfourth != null">{{healthinsurancedetails.drugsfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Consume tobacco or alchohol?</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.tobaccofirst != null">{{healthinsurancedetails.tobaccofirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.tobaccosecond != null">{{healthinsurancedetails.tobaccosecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.tobaccothird != null">{{healthinsurancedetails.tobaccothird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.tobaccofourth != null">{{healthinsurancedetails.tobaccofourth}}</span>
                                        </td>
                        
                                    </tr>
                                </tbody>
                        
                            </table>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Previous/Current health Insurance Details</h4>
                            <div class="col-md-12 ">&nbsp;</div>
                        
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th style=" width: 22%;">Details</th>
                                        <th>Member 1</th>
                                        <th>Member 2</th>
                                        <th>Member 3</th>
                                        <th>Member 4</th>
                                    </tr>
                                </thead>
                        
                                <tbody>
                                    <tr>
                                        <td>Name of insurer</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.personone != null">{{healthinsurancedetails.personone}}</span>  </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.personsec != null">{{healthinsurancedetails.personsec}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.personsthrd != null">{{healthinsurancedetails.personsthrd}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.personsfourth != null">{{healthinsurancedetails.personsfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Policy no.</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.policyfirst != null">{{healthinsurancedetails.policyfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.policysec != null">{{healthinsurancedetails.policysec}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.policythrd != null">{{healthinsurancedetails.policythrd}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.policyfour != null">{{healthinsurancedetails.policyfour}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Policy period(from&to)</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.periodone != null">{{healthinsurancedetails.periodone}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.periodsecond != null">{{healthinsurancedetails.periodsecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.periodthrd != null">{{healthinsurancedetails.periodthrd}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.periodfourth != null">{{healthinsurancedetails.periodfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Sum Insured</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.sumfirst != null">{{healthinsurancedetails.sumfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.sumsecond != null">{{healthinsurancedetails.sumsecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.sumthird != null">{{healthinsurancedetails.sumthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.sumfourth != null">{{healthinsurancedetails.sumfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Previous Claim?</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.filedfirst != null">{{healthinsurancedetails.filedfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.filedsecond != null">{{healthinsurancedetails.filedsecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.filedthird != null">{{healthinsurancedetails.filedthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.filedfourth != null">{{healthinsurancedetails.filedfourth}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Has any proposal of life, criticalillness or health insurance beendeclined, cancelled or charged ahigher premium?</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.healthfirst != null">{{healthinsurancedetails.healthfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.healthsecond != null">{{healthinsurancedetails.healthsecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.healththird != null">{{healthinsurancedetails.healththird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.healthfourth != null">{{healthinsurancedetails.healthfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Are any of the persons proposed for insurance covered under any other health insurance policy with the Company? (Including Critical Illness</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.coveredfirst != null">{{healthinsurancedetails.coveredfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.coveredsecond != null">{{healthinsurancedetails.coveredsecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.coveredthird != null">{{healthinsurancedetails.coveredthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.coveredfourth != null">{{healthinsurancedetails.coveredfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Applying for portability</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.companyfirst != null">{{healthinsurancedetails.companyfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.companysecond != null">{{healthinsurancedetails.companysecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.companythird != null">{{healthinsurancedetails.companythird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.companyfourth != null">{{healthinsurancedetails.companyfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Name of the family Physician</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.infirmityfirst != null">{{healthinsurancedetails.infirmityfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.infirmitysecond != null">{{healthinsurancedetails.infirmitysecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.infirmitythird != null">{{healthinsurancedetails.infirmitythird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.infirmityfourth != null">{{healthinsurancedetails.infirmityfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Contact</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.contactfirst != null">{{healthinsurancedetails.contactfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.contactsecond != null">{{healthinsurancedetails.contactsecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.contactthird != null">{{healthinsurancedetails.contactthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.contactfourth != null">{{healthinsurancedetails.contactfourth}}</span>
                                        </td>
                        
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.Emailfirst != null">{{healthinsurancedetails.Emailfirst}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.Emailsecond != null">{{healthinsurancedetails.Emailsecond}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.Emailthird != null">{{healthinsurancedetails.Emailthird}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="healthinsurancedetails.Emailfourth != null">{{healthinsurancedetails.Emailfourth}}</span>
                                        </td>
                        
                                    </tr>
                                </tbody>
                            </table>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Premium Payment Details:</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Cheque/DD/Credit Card/Debit Card:</label>
                                    <span *ngIf="healthinsurancedetails.debitcard != null">{{healthinsurancedetails.debitcard}}</span>
                        
                                </div>
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Cheque/DD no.:/Amount</label>
                                    <span *ngIf="healthinsurancedetails.chequeamt != null">{{healthinsurancedetails.chequeamt}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Bank Name:</label>
                                    <span *ngIf="healthinsurancedetails.bnkname != null">{{healthinsurancedetails.bnkname}}</span>
                        
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Cheque date:</label>
                                    <span *ngIf="healthinsurancedetails.chequedate != null">{{healthinsurancedetails.chequedate}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Name of the payer:</label>
                                    <span *ngIf="healthinsurancedetails.nmpayer != null">{{healthinsurancedetails.nmpayer}}</span>
                        
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12">&nbsp;</div>
                        
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 ">&nbsp;</div>
                        
                  
                        <div class="col-md-12 " *ngIf="homeinsurance">
                            <!-- <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Home Insurance</h2> -->
                            <div class="col-md-12 row">
                                <div class="col-md-4"></div>
                                <div class="col-md-4">
                                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Home Insurance</h2>
                                </div>
                                <div class="col-md-2">
                                    <button class="btn btn-primary"  (click)="homeinsuranceclick()">Edit</button>
                                </div>
                            </div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">DETAILS OF THE PREMISES TO BE INSURED
                            </h4>
                            <div class="col-md-12 ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel ">Address of property proposed for insurance:</label> &nbsp;{{propaddress}}
                        
                                </div>
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Year of Construction:</label>&nbsp;{{yearofconstr}}
                        
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Built up area :</label>&nbsp;{{builtuparea}}
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel ">Class of Construction:</label>&nbsp;{{classofConst}}
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Sum Insured of
                                          Building:</label>&nbsp;{{suminsuredbuildingval}}
                        
                                </div>
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Name of Financier, if
                                          any:</label>&nbsp;{{nameoffinancier}}
                        
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel ">Sum Insured of Contents:</label>&nbsp;{{sumcontentinsured}}
                        
                                </div>
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Distance from water
                                          front:</label>&nbsp;{{distfromwater}}
                        
                                </div>
                        
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-10 row ">
                                    <label for="Email " id="emaillabel ">Location of property(Basement/Ground floor & above/
                                          others_____):</label>&nbsp;{{locationofprop}}
                        
                                </div>
                        
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Is property located is</h4>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <label for="Email " id="emaillabel ">Low lying area:</label>&nbsp;{{lowlyingarea}}
                        
                        
                            </div>
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <label id="emaillabel ">Flood prone area?:</label>&nbsp;{{floddprone}}
                        
                            </div>
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <label for="Email " id="emaillabel ">Area prone to Natural
                                      Catastrophes?:</label>&nbsp;{{areaprone}}
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Section 1- Building</h4>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel "> Structure(Sum Insured):</label>&nbsp;{{buildingstructure}}
                        
                                </div>
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Underground services(Sum
                                          Insured):</label>&nbsp;{{undergroundservices}}
                        
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Total(Sum Insured):</label>&nbsp;{{buildingtotal}}
                        
                                </div>
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-10 row ">
                                    <label id="emaillabel ">Do you want coverage for Tenant Fixtures?:</label> &nbsp;{{coveragefortenant}}
                                </div>
                        
                        
                            </div>
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                                <label for="Email " id="emaillabel ">Do you want to opt for lock & key
                                      coverage?:</label>&nbsp;{{lockkeyopt}}
                        
                            </div>
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                                <label for="Email " id="emaillabel ">Do you want coverage for Fire extinguishing expenses?
                                      :</label>&nbsp;{{coverageextinguish}}
                            </div>
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                                <label id="emaillabel ">Do you wish to opt for terrorism
                                      extension?:</label>&nbsp;{{terrorismopt}}
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Section 2- Home contents</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Valueables proposed for insurance
                            </h5>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Description:</label>&nbsp;{{valueablesdesc}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Quantity:</label>&nbsp;{{valueablesquantity}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Weight:</label>&nbsp;{{valueablesweight}}
                        
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel ">Sum Insured:</label>&nbsp;{{valueablessuminsured}}
                        
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Appliances</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Description:</label>&nbsp;{{appliancesdesc}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Make&Model:</label>&nbsp;{{appliancesmodel}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Year of Mfg:</label>&nbsp;{{appliancesyear}}
                        
                                </div>
                        
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel "> ID no.:</label>&nbsp;{{appliancesIDNo}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Sum Insured:</label>&nbsp;{{appliancesSumInsured}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                        
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Fixed Plate Glass and Sanitary ware
                            </h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <label id="emaillabel ">Location within premises:</label>&nbsp;{{locationwithinpremises}}
                        
                            </div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Dimensions:</label>&nbsp;{{dimensions}}
                        
                                </div>
                        
                        
                            </div>
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Sum Insured:</label>&nbsp;{{fixedplatessum}}
                        
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Additional Benefits</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Do you want to cover Loss of
                                          Documents?:</label>&nbsp;{{coverlossofdocs}}
                        
                                </div>
                        
                            </div>
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <label id="emaillabel ">Do you want to cover expenses for household
                                      removal?:</label>&nbsp;{{coverexpensesforhousehold}}
                        
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel ">Loss of rent:</label>&nbsp;{{lossofrent}}
                        
                                </div>
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Additional rent for alternate
                                          accomodation:</label>&nbsp;{{additionalrent}}
                        
                                </div>
                        
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Pedigree Pet
                                          Insurance:</label>&nbsp;{{pedigreeinsu}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel ">Baggage Insurance:</label>&nbsp;{{baggageinsu}}
                        
                                </div>
                            </div>
                            <div class="col-md-12 ">&nbsp;</div>
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Domestic Servants</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel ">No. of servants:</label>&nbsp;{{noofservents}}
                        
                                </div>
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">nature of work/ duties
                                          :</label>&nbsp;{{natureofwork}}
                        
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Estimated wages:</label>&nbsp;{{estimatedwages}}
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                            </div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of expirying Policy</h4>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel "> Name & address of insurance
                                          company:</label>&nbsp;{{addressofinsurancecom}}
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Security Arrangements</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <label for="Email " id="emaillabel ">In case of independent house 24 hours dedicated
                                      watchman:</label>&nbsp;{{independenthouse}}
                        
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <label id="emaillabel "> In case of apartment Are all entry/exit gates manned around the
                                      clock:</label>&nbsp;{{apartment}}
                        
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <label id="emaillabel "> Are the flats connected with each other/Security office with
                                      interom:</label>&nbsp;{{flatsconnected}}
                        
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Policy number:</label>&nbsp;{{policynumber}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel "> Name of the Insurance
                                          Company:</label>&nbsp;{{insurancecompname}}
                        
                                </div>
                            </div>
                        
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel "> Period of insurance:</label>&nbsp;{{periodofinsurance}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Item already
                                      insured:</label>&nbsp;{{itemalreadyinsured}}
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Source of fund</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-6 row ">
                                    <label id="emaillabel "> Salary/Business/Other, Specify:</label>&nbsp;{{sourceoffund}}
                        
                                </div>
                        
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Payment Details</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel "> Cheque/DD/PO/Cash/Other________:</label>&nbsp;{{paymenttype}}
                        
                                </div>
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Cheque/DD/PO no.:</label>&nbsp;{{chequeno}}
                        
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Dated:</label>&nbsp;{{paymentdated}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel "> Bank Name:</label>&nbsp;{{bankName}}
                        
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                        
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Amount:</label>&nbsp;{{paymentamount}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Amount in words:</label>&nbsp;{{paymentamtinwords}}
                        
                                </div>
                            </div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 ">&nbsp;</div>
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                  
                        <div class="col-md-12 " *ngIf="motorinsurance">
                              
                            <!-- <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Motor Insurance Details</h2> -->
                            <div class="col-md-12 row">
                                <div class="col-md-4"></div>
                                <div class="col-md-4">
                                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Motor Insurance</h2>
                                </div>
                                <div class="col-md-2">
                                    <button class="btn btn-primary"  (click)="motorinsuranceclick()">Edit</button>
                                </div>
                            </div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Payment Details</h4>
                            <div class="col-md-12 ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel ">Cheque/Instrument no.:</label> &nbsp;{{chequeno}}
                        
                                </div>
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Date of instrument:</label>&nbsp;{{dateofinst}}
                        
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Bank Name :</label>&nbsp;{{bankname}}
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel ">Branch Location:</label>&nbsp;{{branchloc}}
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Amount:</label>&nbsp;{{paymentamount}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Source of fund</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel ">Salary/Business/Other,
                                          Specify:</label>&nbsp;{{sourceoffund}}
                                </div>
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Risk Information</h4>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Vehicle
                                          Manufacturer:</label>&nbsp;{{vehiclemanufacturer}}
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Vehicle model:</label>&nbsp;{{vehiclemodel}}
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">Registration
                                          location:</label>&nbsp;{{registrationlocation}}
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">Year of Manufacturer:</label>&nbsp;{{yearofmanufacture}}
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">Engine no.:</label>&nbsp;{{engineno}}
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">Chassis no.:</label>&nbsp;{{chassisno}}
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">Colour of Vehicle:</label>&nbsp;{{colourofvehicle}}
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">Fuel type:</label>&nbsp;{{fueltype}}
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label id="emaillabel "> Age of Insured:</label>&nbsp;{{ageofinsured}}
                        
                                </div>
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Insured Declared Value of the
                                          Vehicle:</label>&nbsp;{{insureddeclaredvalue}}
                        
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-10 row ">
                                    <label for="Email " id="emaillabel ">Non-Electrical Accessories fitted to vehicle Side
                                          car(Two-Wheeler):</label>&nbsp;{{accessoriesfitted}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-10 row ">
                                    <label for="Email " id="emaillabel ">Value of CNG/LPG kit Bi
                                          fuel:</label>&nbsp;{{valueofkit}}
                        
                                </div>
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Total Value:</label> &nbsp;{{totalvalue}}
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row">
                                    <label for="Email " id="emaillabel ">Package policy:</label>&nbsp;{{packagepolicy}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row">
                                    <label for="Email " id="emaillabel ">PUC:</label>&nbsp;{{puc}}
                        
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Additional Info</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                        
                        
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">Registration
                                          no.:</label>&nbsp;{{registrationno}}
                                </div>
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">Date of
                                          Registration:</label>&nbsp;{{dateofregistration}}
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">Previous
                                          Insurer:</label>&nbsp;{{previousinsurer}}
                                </div>
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">Previous policy
                                          number:</label>&nbsp;{{previouspolicynumber}}
                                </div>
                            </div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Previous period of insurance</h4>
                        
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">From:</label>&nbsp;{{previousperiodfrom}}
                                </div>
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">To:</label>&nbsp;{{previousperiodto}}
                                </div>
                            </div>
                        
                            <div class="col-md-12 ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Current period of insurance</h4>
                        
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">From:</label>&nbsp;{{currentperiodfrom}}
                                </div>
                                <div class="col-md-5 row">
                                    <label for="Email " id="emaillabel ">To:</label>&nbsp;{{currentperiodto}}
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                        
                                    <label id="emaillabel ">Claims lodged during preceding
                                          year:</label>&nbsp;{{claimslodged}}
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                        
                                    <label id="emaillabel ">Claims lodged during preceding year
                                          Amount:</label>&nbsp;{{claimslodgedamount}}
                                </div>
                            </div>
                        
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                        
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Entitled to NCB(no claim
                                          bonus):</label>&nbsp;{{entitledtoncb}}&nbsp;&nbsp;{{entitledtoncbyespercent}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">use of vehicle is limited to own
                                          permises?:</label>&nbsp;{{limitedtoownpermises}}
                                </div>
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <label id="emaillabel ">Vehicle is designed for the Handicapped/Mentally challenged
                                      persons and duly endorsed by RTA?:</label>&nbsp;{{vehicledesignedhandicapped}}
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <label id="emaillabel ">Is the Vehicle proposed for insurance under:Hire-Purchase/Lease
                                      Agreement:</label>&nbsp;{{vehicleproposedfor}}
                                <br/>
                        
                            </div>
                            <div class="col-md-12 row " *ngIf="nameofconcernedenable">&nbsp;</div>
                        
                            <div class="col-md-12 row " *ngIf="nameofconcernedenable">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <label id="emaillabel ">Name of concerned parties:</label>&nbsp;{{nameofconcerned}}
                            </div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Coverage Info</h3>
                            <div class="col-md-12 ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Please select the higher deductible if you wish to opt for over and above the compulsory deductible </h4>
                            <div class="col-md-12 ">&nbsp;</div>
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Do you wish to include the following PA coverages</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Pillion Passengers:</label>&nbsp;{{pillionpassengers}}
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">No. of Persons:</label>&nbsp;{{nofpersons}}
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">CSI opted for: Rs:</label>&nbsp;{{csiopted}}
                                </div>
                        
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <label id="emaillabel ">Do you wish to opt for statutory TPPD liability coverage of
                                      Rs.6000 only?:</label>&nbsp;{{optforstatutory}}
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel "> Legal Liability:</label>&nbsp;{{legalliability}}
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">No. of
                                          Persons:</label>&nbsp;{{legalliabilitypersons}}
                        
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Driver/Conductor/Cleaner:</label>&nbsp;{{driver}}
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-5 row ">
                                    <label for="Email " id="emaillabel ">Other
                                          Employee</label>&nbsp;{{otheremployee}}
                                </div>
                        
                            </div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Hire Purchase,Hypothecation,Lease, if applicable</h4>
                            <div class="col-md-12 ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <label id="emaillabel ">Full Name of the concerned party and
                                      address:</label>&nbsp;{{fullnameofconcerned}}
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-8 row ">
                                    <label for="Email " id="emaillabel ">Moton Add-on Covers Zero
                                          Depriciation:</label>&nbsp;{{motonaddon}}
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                      
                        
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 " *ngIf="termloaninsurance">
                          
                            <div class="col-md-12">&nbsp;</div>
                            <!-- <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Term Loan Insurance</h2> -->
                            <div class="col-md-12 row">
                                <div class="col-md-4"></div>
                                <div class="col-md-4">
                                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Term Loan Insurance</h2>  
                   
                                </div>
                                <div class="col-md-2">
                                    <button class="btn btn-primary"  (click)="terminsuranceclick()">Edit</button>
                                </div>
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12 ">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Plan Details</h4>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">Product name :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.proname != null">{{terminsurancedetails.proname}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">Policy term(yrs) :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.plcterms != null">{{terminsurancedetails.plcterms}}</span>
                        
                                </div>
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12">&nbsp;</div>
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-4 row">
                                    <label id="emaillabel">Sum Insured :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.sumins != null">{{terminsurancedetails.sumins}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label for="Email " id="emaillabel ">DETAILS OF CURRENT LOAN AND OVERDRAFTS :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.overdrafts != null">{{terminsurancedetails.overdrafts}}</span>
                        
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-4 row">
                                    <label id="emaillabel">Premium payment :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.prmapy != null">{{terminsurancedetails.prmapy}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label for="Email " id="emaillabel ">Installment of premium :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.instofprm != null">{{terminsurancedetails.instofprm}}</span>
                        
                                </div>
                        
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label for="Email " id="emaillabel ">GST :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.gst != null">{{terminsurancedetails.gst}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row">
                                    <label id="emaillabel">Total Premium :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.ttlprm != null">{{terminsurancedetails.ttlprm}}</span>
                        
                                </div>
                        
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label for="Email " id="emaillabel ">Sum Assured based on :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.sumassured == '1'">Multipele of gross salary</span>
                                    <span *ngIf="terminsurancedetails.sumassured == '2'">Flat Cover</span>
                                    <span *ngIf="terminsurancedetails.sumassured == '3'">Grade Wise Cover</span>
                                    <span *ngIf="terminsurancedetails.sumassured == '4'">Outstanding loan/liability,bank
                                        deposits</span>
                                    <span *ngIf="terminsurancedetails.sumassured == '5'">Life cover</span>
                        
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                        
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Life to be insured-Family history</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">Life to be insured/proposer :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.lifeinsorpro != null">{{terminsurancedetails.lifeinsorpro}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">Family member :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.fammemb != null">{{terminsurancedetails.fammemb}}</span>
                        
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">Exact cause of death :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.causedeath != null">{{terminsurancedetails.causedeath}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">If alive, current age :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.curage != null">{{terminsurancedetails.curage}}</span>
                        
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">If deceased, age of death :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.ageofdeath != null">{{terminsurancedetails.ageofdeath}}</span>
                        
                                </div>
                        
                            </div>
                        
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel " style="text-align: left;">Are all employees/members (In case of association groups) covered?
                                        :&nbsp;</label>
                        
                                    <span *ngIf="terminsurancedetails.allempcov">Yes</span>
                                    <span *ngIf="!terminsurancedetails.allempcov">No</span>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">If NOT, then % of members covered? :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.perofmem != null">{{terminsurancedetails.perofmem}}</span>
                        
                                </div>
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">Desired date of commencement of coverage :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.dateofcomm != null">{{terminsurancedetails.dateofcomm}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">Premium Frequency :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.prmfrq == '1'">Half yearly</span>
                                    <span *ngIf="terminsurancedetails.prmfrq == '2'">Annual</span>
                                    <span *ngIf="terminsurancedetails.prmfrq == '3'">Monthly</span>
                                    <span *ngIf="terminsurancedetails.prmfrq == '4'">Quaterly</span>
                        
                        
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">Mode of payment :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.modeofpay == '1'">Cheque</span>
                                    <span *ngIf="terminsurancedetails.modeofpay == '2'">Demand Draft</span>
                                    <span *ngIf="terminsurancedetails.modeofpay == '3'">Direct Debit</span>
                                    <span *ngIf="terminsurancedetails.modeofpay == '4'">Other</span>
                        
                                </div>
                        
                            </div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Bank details</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">Bank & Branch :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.bnknbrn != null">{{terminsurancedetails.bnknbrn}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">Bank A/C no. :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.accno != null">{{terminsurancedetails.accno}}</span>
                        
                                </div>
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">Cheque/DD no. :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.chkrdd != null">{{terminsurancedetails.chkrdd}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">MICR Code :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.micrcode != null">{{terminsurancedetails.micrcode}}</span>
                        
                                </div>
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">Amount :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.amount != null">{{terminsurancedetails.amount}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">Bank Account proof :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.bnkaccprf != null">{{terminsurancedetails.bnkaccprf}}</span>
                        
                                </div>
                            </div>
                        
                            <div class="col-md-1 row ">&nbsp;</div>
                        
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Group Demographics</h4>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">Group size :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.grpsize != null">{{terminsurancedetails.grpsize}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">Retirement age :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.rtrage != null">{{terminsurancedetails.rtrage}}</span>
                        
                                </div>
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">Minimum age at entry :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.minage != null">{{terminsurancedetails.minage}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">Max age at entry :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.maxage != null">{{terminsurancedetails.maxage}}</span>
                        
                                </div>
                            </div>
                        
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">Cover of member(s) effective from :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.covefffrm != null">{{terminsurancedetails.covefffrm}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">Date of Appointment :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.dateofapp != null">{{terminsurancedetails.dateofapp}}</span>
                        
                                </div>
                            </div>
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">Date of Confirmation :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.dateofcomf != null">{{terminsurancedetails.dateofcomf}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">Next Renewal Date :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.nxtren != null">{{terminsurancedetails.nxtren}}</span>
                        
                                </div>
                            </div>
                        
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">Other :&nbsp;</label>
                                    <span>{{terminsurancedetails.others}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                            </div>
                        
                        
                        
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel " style="text-align: left;">Member(s) covered by any othe Life insurance company?
                                        :&nbsp;</label>
                        
                                    <span *ngIf="terminsurancedetails.memothrins">Yes</span>
                                    <span *ngIf="!terminsurancedetails.memothrins">No</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel">state the name of insurer for past 2 years :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.nmofins != null">{{terminsurancedetails.nmofins}}</span>
                        
                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                        
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label id="emaillabel ">Date of cover ceased :&nbsp;</label>
                                    <span *ngIf="terminsurancedetails.dateofcovcsd != null">{{terminsurancedetails.dateofcovcsd}}</span>
                        
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                        
                            </div>
                        
                            <div class="col-md-1 row ">&nbsp;</div>
                        
                        </div>
                        
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">
                <div id="downloadid">
                <div class="col-md-12 ">
                    <div *ngIf="certificatesexist">
                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="col-md-12 row">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Uploaded Certificates</h2>
                            </div>
                            <div class="col-md-2">
                                <button *ngIf="roleid!='2'" class="btn btn-primary" (click)="certificatesclick()">Edit</button>
                            </div>
                        </div>
            

                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="col-md-12 row">
                            <div class="col-md-1 row">&nbsp;</div>

                            <div class="col-md-2 row" *ngFor="let item of CertificateDisplay">
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'image/jpeg' ||
                         item.documentType == 'image/png')" >
                                    <img [src]="item.certificate" height="100" width="100" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/pdf' ||
                         item.documentType == 'pdf')" >
                                    <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                         item.documentType == 'xlsx')" >
                                    <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                         item.documentType == 'doc' || item.documentType == 'docx')" >
                                    <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                        <div>
                            <div class="col-md-12 row">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Uploaded Documents</h4>

                            <div class="col-md-12 row">
                                <div class="col-md-1 row">&nbsp;</div>

                                <div class="col-md-2 row" *ngFor="let item of certificates">
                                    <div *ngIf="item.actualCertificateName!=null  && (item.documentType == 'image/jpeg' ||
                         item.documentType == 'image/png')" >
                                        <img [src]="item.certificate" height="100" width="100" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/pdf' ||
                         item.documentType == 'pdf')" >
                                        <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                         item.documentType == 'xlsx')" >
                                        <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                         item.documentType == 'doc' || item.documentType == 'docx')" >
                                        <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                <div class="col-md-12 ">&nbsp;</div>
            </div>
                        
                        </div>
                        </div>
                        </div> 
