<app-header></app-header>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div class="col-md-12 row bodycolor">
    <div class="col-md-1"></div>
    <div class="col-md-10">
        <div class="card">
            <form id="regform" [formGroup]="SendRequestForm">
                <div>&nbsp;</div>
                <div class="col-md-12 row">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                        <div>&nbsp;</div>
                        <div style="color: darkslateblue">
                            <h4>To: </h4>
                            <br />
                        </div>
                        <br />

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label for="placeofbith">Full Name</label>
                                    <input type="text" class="form-control" name="fullName">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label for="contactnumber">Mobile Number</label>
                                    <input  type="tel" class="form-control" name="contactnumber">
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                            <div class="row">

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label for="email">Email</label>
                                    <input type="text" class="form-control" name="email">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label for="placeofbith">Address</label>
                                    <input type="text" class="form-control" name="address">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label for="placeofbith">Information</label>
                                    <input  type="text" class="form-control" name="info">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label for="date">Service Date</label>
                                    <input type="text" class="form-control" name="date"
                                         readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <label for="placeofbith">Service Time</label>
                                    <input type="text" class="form-control" name="servicetime">
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                            <div class="row">
                                <div class="col-md-4"></div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <button type="submit" class="btn btnprop">Cancel</button>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                    <button type="submit" class="btn btnprop"
                                        >Submit</button><br /><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
