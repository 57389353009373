import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { SignatureService } from 'src/app/Shared/Esign/signature.service';
import { ChooseESign, DrawnESign } from 'src/app/ViewModels/Esign';

@Component({
  selector: 'app-esignregistration',
  templateUrl: './esignregistration.component.html',
  styleUrls: ['./esignregistration.component.css']
})
export class EsignregistrationComponent implements OnInit {
  isChoose: boolean;
  isDraw: boolean;
  isUpload: boolean;
  userId: any;
  fileupload: any;
  initialImage: any;
  signatureImage: any;
  selectedesign: any;
  uploadedinitial: any;
  uploadedsign: any;
  esign:any;
  esinitials:any;
  esfullname:any;
  constructor(private signatureService:SignatureService, private router:Router) { 
    this.userId = localStorage.getItem("userId");
  }

  ngOnInit(): void {
  }


  ActiveMenuEsign(data: any) {
    if (data == 1) {

      document.getElementById("choose").style.color = "#4F44C5";
      document.getElementById("draw").style.color = "#9BAAB6";
      document.getElementById("upload").style.color = "#9BAAB6";

      document.getElementById("choose").style.borderBottom = "3px solid rgb(79, 68, 197)";
      document.getElementById("draw").style.borderBottom = "none";
      document.getElementById("upload").style.borderBottom = "none";

      
      
      

      document.getElementById("choose").style.fontStyle = "normal";
      
      

      document.getElementById("choose").style.fontSize = "13px";
      document.getElementById("draw").style.fontSize = "13px";
      document.getElementById("upload").style.fontSize = "13px";

      document.getElementById("choose").style.lineHeight = "12px";
      document.getElementById("draw").style.lineHeight = "12px";
      document.getElementById("upload").style.lineHeight = "12px";

      document.getElementById("choose").style.letterSpacing = "0.1em";
      document.getElementById("draw").style.lineHeight = "0.1em";
      document.getElementById("upload").style.lineHeight = "0.1em";

      this.isChoose = true;
      this.isDraw = false;
      this.isUpload = false;
      // this.Getpinslist()
    }
    else if (data == 2) {

      document.getElementById("choose").style.color = "#9BAAB6";
      document.getElementById("draw").style.color = "#4F44C5";
      document.getElementById("upload").style.color = "#9BAAB6";

      document.getElementById("choose").style.borderBottom = "none";
      document.getElementById("draw").style.borderBottom = "3px solid rgb(79, 68, 197)";
      document.getElementById("upload").style.borderBottom = "none";

      
      
      

      document.getElementById("choose").style.fontStyle = "normal";
      
      

      document.getElementById("choose").style.fontSize = "13px";
      document.getElementById("draw").style.fontSize = "13px";
      document.getElementById("upload").style.fontSize = "13px";

      document.getElementById("choose").style.lineHeight = "12px";
      document.getElementById("draw").style.lineHeight = "12px";
      document.getElementById("upload").style.lineHeight = "12px";

      document.getElementById("choose").style.letterSpacing = "0.1em";
      document.getElementById("draw").style.lineHeight = "0.1em";
      document.getElementById("upload").style.lineHeight = "0.1em";

      this.isChoose = false;
      this.isDraw = true;
      this.isUpload = false;
      // this.Getpinslist()

      
      if (window.innerWidth >= 780 && window.innerWidth <= 1025) {
        //Draw signatures
        setTimeout(() => {
          var b = document.getElementsByClassName("btn btn-primary ng-star-inserted");
          for (let i = 0; i < b.length; i++) {
            (<HTMLElement>b[i]).style.backgroundColor = 'none';
            (<HTMLElement>b[i]).style.borderColor = 'none';
            (<HTMLElement>b[i]).style.boxShadow = 'none';
            (<HTMLElement>b[i]).style.color = '#4F44C5';
            (<HTMLElement>b[i]).style.textTransform = 'capitalize';
            (<HTMLElement>b[i]).style.marginTop = '-22%';
            (<HTMLElement>b[i]).style.marginLeft = '98%';
            (<HTMLElement>b[i]).style.background = 'none';
            (<HTMLElement>b[i]).style.border = 'none';
            
            (<HTMLElement>b[i]).style.fontSize = '13px';
          }
        }, 1);


        setTimeout(() => {
          var b = document.getElementsByClassName("btn btn-default ng-star-inserted");
          for (let i = 0; i < b.length; i++) {
            (<HTMLElement>b[i]).style.backgroundColor = 'none';
            (<HTMLElement>b[i]).style.borderColor = 'none';
            (<HTMLElement>b[i]).style.boxShadow = 'none';
            (<HTMLElement>b[i]).style.color = '#4F44C5';
            (<HTMLElement>b[i]).style.textTransform = 'capitalize';
            (<HTMLElement>b[i]).style.marginTop = '-77%';
            (<HTMLElement>b[i]).style.marginLeft = '98%';
            (<HTMLElement>b[i]).style.background = 'none';
            (<HTMLElement>b[i]).style.border = 'none';
            
            (<HTMLElement>b[i]).style.fontSize = '13px';
          }
          var element = document.getElementById("canv");
          var sign = element.getElementsByTagName("canvas")[0].style.backgroundColor = "#EAF1F7";
          var sign1 = element.getElementsByTagName("canvas")[0].style.borderRadius = "10px";
          var sign2 = element.getElementsByTagName("canvas")[0].style.paddingRight = "10%";
          var sign3 = element.getElementsByTagName("canvas")[0].style.height = "85px";
          var sign4 = element.getElementsByTagName("canvas")[0].style.width = "333px";//469
          var sign5 = element.getElementsByTagName("canvas")[0].style.marginTop = "-2%";

          var ele = document.getElementById("canvone");
          var initial = ele.getElementsByTagName("canvas")[0].style.backgroundColor = "#EAF1F7";
          var initial1 = ele.getElementsByTagName("canvas")[0].style.borderRadius = "10px";
          var initial2 = ele.getElementsByTagName("canvas")[0].style.paddingLeft = "2%";
          var initial3 = ele.getElementsByTagName("canvas")[0].style.marginLeft = "9%";
          var initial4 = ele.getElementsByTagName("canvas")[0].style.height = "85px";
          var initial5 = ele.getElementsByTagName("canvas")[0].style.width = "306px";//438px
          var initial6 = ele.getElementsByTagName("canvas")[0].style.marginTop = "-2%";
        }, 1);
      }
      else if ((window.innerWidth <= 2000 && window.innerWidth > 1024)) {
        
        setTimeout(() => {
          var b = document.getElementsByClassName("btn btn-primary ng-star-inserted");
          for (let i = 0; i < b.length; i++) {
            (<HTMLElement>b[i]).style.backgroundColor = 'none';
            (<HTMLElement>b[i]).style.borderColor = 'none';
            (<HTMLElement>b[i]).style.boxShadow = 'none';
            (<HTMLElement>b[i]).style.color = '#4F44C5';
            (<HTMLElement>b[i]).style.textTransform = 'capitalize';
            (<HTMLElement>b[i]).style.marginTop = '-16%';
            (<HTMLElement>b[i]).style.marginLeft = '104%';
            (<HTMLElement>b[i]).style.background = 'none';
            (<HTMLElement>b[i]).style.border = 'none';
            
            (<HTMLElement>b[i]).style.fontSize = '13px';
          }
        }, 1);


        setTimeout(() => {
          var b = document.getElementsByClassName("btn btn-default ng-star-inserted");
          for (let i = 0; i < b.length; i++) {
            (<HTMLElement>b[i]).style.backgroundColor = 'none';
            (<HTMLElement>b[i]).style.borderColor = 'none';
            (<HTMLElement>b[i]).style.boxShadow = 'none';
            (<HTMLElement>b[i]).style.color = '#4F44C5';
            (<HTMLElement>b[i]).style.textTransform = 'capitalize';
            (<HTMLElement>b[i]).style.marginTop = '-57%';
            (<HTMLElement>b[i]).style.marginLeft = '104%';
            (<HTMLElement>b[i]).style.background = 'none';
            (<HTMLElement>b[i]).style.border = 'none';
            
            (<HTMLElement>b[i]).style.fontSize = '13px';
          }
          var element = document.getElementById("canv");
          var sign = element.getElementsByTagName("canvas")[0].style.backgroundColor = "#EAF1F7";
          var sign1 = element.getElementsByTagName("canvas")[0].style.borderRadius = "10px";
          var sign2 = element.getElementsByTagName("canvas")[0].style.paddingRight = "10%";
          var sign3 = element.getElementsByTagName("canvas")[0].style.height = "85px";
          var sign4 = element.getElementsByTagName("canvas")[0].style.width = "439px";//469
          var sign5 = element.getElementsByTagName("canvas")[0].style.marginTop = "-2%";

          var ele = document.getElementById("canvone");
          var initial = ele.getElementsByTagName("canvas")[0].style.backgroundColor = "#EAF1F7";
          var initial1 = ele.getElementsByTagName("canvas")[0].style.borderRadius = "10px";
          var initial2 = ele.getElementsByTagName("canvas")[0].style.paddingLeft = "2%";
          var initial3 = ele.getElementsByTagName("canvas")[0].style.marginLeft = "9%";
          var initial4 = ele.getElementsByTagName("canvas")[0].style.height = "85px";
          var initial5 = ele.getElementsByTagName("canvas")[0].style.width = "413px";//438
          var initial6 = ele.getElementsByTagName("canvas")[0].style.marginTop = "-2%";
        }, 1);

      }
      
      else if (window.innerWidth <= 780 && window.innerWidth >= 500) {

        setTimeout(() => {
          var b = document.getElementsByClassName("btn btn-primary ng-star-inserted");
          for (let i = 0; i < b.length; i++) {
            (<HTMLElement>b[i]).style.backgroundColor = 'none';
            (<HTMLElement>b[i]).style.borderColor = 'none';
            (<HTMLElement>b[i]).style.boxShadow = 'none';
            (<HTMLElement>b[i]).style.color = '#4F44C5';
            (<HTMLElement>b[i]).style.textTransform = 'capitalize';
            (<HTMLElement>b[i]).style.marginTop = '-29%';
            (<HTMLElement>b[i]).style.marginLeft = '103%';
            (<HTMLElement>b[i]).style.background = 'none';
            (<HTMLElement>b[i]).style.border = 'none';
            
            (<HTMLElement>b[i]).style.fontSize = '13px';
          }
        }, 1);


        setTimeout(() => {
          var b = document.getElementsByClassName("btn btn-default ng-star-inserted");
          for (let i = 0; i < b.length; i++) {
            (<HTMLElement>b[i]).style.backgroundColor = 'none';
            (<HTMLElement>b[i]).style.borderColor = 'none';
            (<HTMLElement>b[i]).style.boxShadow = 'none';
            (<HTMLElement>b[i]).style.color = '#4F44C5';
            (<HTMLElement>b[i]).style.textTransform = 'capitalize';
            (<HTMLElement>b[i]).style.marginTop = '-110%';
            (<HTMLElement>b[i]).style.marginLeft = '103%';
            (<HTMLElement>b[i]).style.background = 'none';
            (<HTMLElement>b[i]).style.border = 'none';
            
            (<HTMLElement>b[i]).style.fontSize = '13px';
          }
          var element = document.getElementById("canv");
          var sign = element.getElementsByTagName("canvas")[0].style.backgroundColor = "#EAF1F7";
          var sign1 = element.getElementsByTagName("canvas")[0].style.borderRadius = "10px";
          var sign2 = element.getElementsByTagName("canvas")[0].style.paddingRight = "10%";
          var sign3 = element.getElementsByTagName("canvas")[0].style.height = "85px";
          var sign4 = element.getElementsByTagName("canvas")[0].style.width = "245px";//469
          var sign5 = element.getElementsByTagName("canvas")[0].style.marginTop = "-2%";

          var ele = document.getElementById("canvone");
          var initial = ele.getElementsByTagName("canvas")[0].style.backgroundColor = "#EAF1F7";
          var initial1 = ele.getElementsByTagName("canvas")[0].style.borderRadius = "10px";
          var initial2 = ele.getElementsByTagName("canvas")[0].style.paddingLeft = "2%";
          var initial3 = ele.getElementsByTagName("canvas")[0].style.marginLeft = "13%";
          var initial4 = ele.getElementsByTagName("canvas")[0].style.height = "85px";
          var initial5 = ele.getElementsByTagName("canvas")[0].style.width = "246px";//438
          var initial6 = ele.getElementsByTagName("canvas")[0].style.marginTop = "-2%";
        }, 1);

      }
      else if (window.innerWidth <= 500 && window.innerWidth >= 335) {

        setTimeout(() => {
          var b = document.getElementsByClassName("btn btn-primary ng-star-inserted");
          for (let i = 0; i < b.length; i++) {
            (<HTMLElement>b[i]).style.backgroundColor = 'none';
            (<HTMLElement>b[i]).style.borderColor = 'none';
            (<HTMLElement>b[i]).style.boxShadow = 'none';
            (<HTMLElement>b[i]).style.color = '#4F44C5';
            (<HTMLElement>b[i]).style.textTransform = 'capitalize';
            (<HTMLElement>b[i]).style.marginTop = '-21%';
            (<HTMLElement>b[i]).style.marginLeft = '82%';
            (<HTMLElement>b[i]).style.background = 'none';
            (<HTMLElement>b[i]).style.border = 'none';
            (<HTMLElement>b[i]).style.fontSize = '9px';
          }
        }, 1);


        setTimeout(() => {
          var b = document.getElementsByClassName("btn btn-default ng-star-inserted");
          for (let i = 0; i < b.length; i++) {
            (<HTMLElement>b[i]).style.backgroundColor = 'none';
            (<HTMLElement>b[i]).style.borderColor = 'none';
            (<HTMLElement>b[i]).style.boxShadow = 'none';
            (<HTMLElement>b[i]).style.color = '#4F44C5';
            (<HTMLElement>b[i]).style.textTransform = 'capitalize';
            (<HTMLElement>b[i]).style.marginTop = '-75%';
            (<HTMLElement>b[i]).style.marginLeft = '82%';
            (<HTMLElement>b[i]).style.background = 'none';
            (<HTMLElement>b[i]).style.border = 'none';
            (<HTMLElement>b[i]).style.fontSize = '9px';
          }
          var element = document.getElementById("canv");
          var sign = element.getElementsByTagName("canvas")[0].style.backgroundColor = "#EAF1F7";
          var sign1 = element.getElementsByTagName("canvas")[0].style.borderRadius = "10px";
          var sign2 = element.getElementsByTagName("canvas")[0].style.paddingRight = "10%";
          var sign3 = element.getElementsByTagName("canvas")[0].style.height = "85px";
          var sign4 = element.getElementsByTagName("canvas")[0].style.width = "469px";
          var sign5 = element.getElementsByTagName("canvas")[0].style.marginTop = "-2%";


          var ele = document.getElementById("canvone");
          var initial = ele.getElementsByTagName("canvas")[0].style.backgroundColor = "#EAF1F7";
          var initial1 = ele.getElementsByTagName("canvas")[0].style.borderRadius = "10px";
          var initial2 = ele.getElementsByTagName("canvas")[0].style.paddingLeft = "2%";
          var initial3 = ele.getElementsByTagName("canvas")[0].style.marginLeft = "9%";
          var initial4 = ele.getElementsByTagName("canvas")[0].style.height = "85px";
          var initial5 = ele.getElementsByTagName("canvas")[0].style.width = "438px";
          var initial6 = ele.getElementsByTagName("canvas")[0].style.marginTop = "-2%";

        }, 1);

      }
      else if (window.innerWidth <= 335) {

        setTimeout(() => {
          var b = document.getElementsByClassName("btn btn-primary ng-star-inserted");
          for (let i = 0; i < b.length; i++) {
            (<HTMLElement>b[i]).style.backgroundColor = 'none';
            (<HTMLElement>b[i]).style.borderColor = 'none';
            (<HTMLElement>b[i]).style.boxShadow = 'none';
            (<HTMLElement>b[i]).style.color = '#4F44C5';
            (<HTMLElement>b[i]).style.textTransform = 'capitalize';
            (<HTMLElement>b[i]).style.marginTop = '-21%';
            (<HTMLElement>b[i]).style.marginLeft = '82%';
            (<HTMLElement>b[i]).style.background = 'none';
            (<HTMLElement>b[i]).style.border = 'none';
            (<HTMLElement>b[i]).style.fontSize = '9px';
          }
        }, 1);


        setTimeout(() => {
          var b = document.getElementsByClassName("btn btn-default ng-star-inserted");
          for (let i = 0; i < b.length; i++) {
            (<HTMLElement>b[i]).style.backgroundColor = 'none';
            (<HTMLElement>b[i]).style.borderColor = 'none';
            (<HTMLElement>b[i]).style.boxShadow = 'none';
            (<HTMLElement>b[i]).style.color = '#4F44C5';
            (<HTMLElement>b[i]).style.textTransform = 'capitalize';
            (<HTMLElement>b[i]).style.marginTop = '-75%';
            (<HTMLElement>b[i]).style.marginLeft = '82%';
            (<HTMLElement>b[i]).style.background = 'none';
            (<HTMLElement>b[i]).style.border = 'none';
            (<HTMLElement>b[i]).style.fontSize = '9px';
          }
          var element = document.getElementById("canv");
          var sign = element.getElementsByTagName("canvas")[0].style.backgroundColor = "#EAF1F7";
          var sign1 = element.getElementsByTagName("canvas")[0].style.borderRadius = "10px";
          var sign2 = element.getElementsByTagName("canvas")[0].style.paddingRight = "10%";
          var sign3 = element.getElementsByTagName("canvas")[0].style.height = "85px";
          var sign4 = element.getElementsByTagName("canvas")[0].style.width = "469px";
          var sign5 = element.getElementsByTagName("canvas")[0].style.marginTop = "-2%";

          var ele = document.getElementById("canvone");
          var initial = ele.getElementsByTagName("canvas")[0].style.backgroundColor = "#EAF1F7";
          var initial1 = ele.getElementsByTagName("canvas")[0].style.borderRadius = "10px";
          var initial2 = ele.getElementsByTagName("canvas")[0].style.paddingLeft = "2%";
          var initial3 = ele.getElementsByTagName("canvas")[0].style.marginLeft = "9%";
          var initial4 = ele.getElementsByTagName("canvas")[0].style.height = "85px";
          var initial5 = ele.getElementsByTagName("canvas")[0].style.width = "438px";
          var initial6 = ele.getElementsByTagName("canvas")[0].style.marginTop = "-2%";
        }, 1);

      }

    }
    else if (data == 3) {


      document.getElementById("choose").style.color = "#9BAAB6";
      document.getElementById("draw").style.color = "#9BAAB6";
      document.getElementById("upload").style.color = "#4F44C5";

      document.getElementById("choose").style.borderBottom = "none";
      document.getElementById("draw").style.borderBottom = "none";
      document.getElementById("upload").style.borderBottom = "3px solid rgb(79, 68, 197)";

      document.getElementById("choose").style.fontStyle = "normal";

      document.getElementById("choose").style.fontSize = "13px";
      document.getElementById("draw").style.fontSize = "13px";
      document.getElementById("upload").style.fontSize = "13px";

      document.getElementById("choose").style.lineHeight = "12px";
      document.getElementById("draw").style.lineHeight = "12px";
      document.getElementById("upload").style.lineHeight = "12px";

      document.getElementById("choose").style.letterSpacing = "0.1em";
      document.getElementById("draw").style.lineHeight = "0.1em";
      document.getElementById("upload").style.lineHeight = "0.1em";

      this.isChoose = false;
      this.isDraw = false;
      this.isUpload = true;

    }
  }


  UploadESignature(event: any) {

    this.fileupload = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.fileupload);
    reader.onload = () => {
      this.uploadedsign = reader.result; 
    };
  }


  GetUploadedInitial() {
    this.signatureService.getUploadedInitial(this.userId).subscribe(
     data => {
       this.uploadedinitial = data;
     });
 }
 GetUploadedSigns() {
    this.signatureService.getUploadedSign(this.userId).subscribe(
     data => {
       this.uploadedsign = data;
     });
 }

  SaveChoosenESigns(form: NgForm) {
    const inputRequest: ChooseESign = {
      CustomerId: this.userId,
      CustomerFullName: form.controls["esfullname"].value,
      CustomerInitials: "",
      SelectedESign: this.selectedesign,
      ipaddress: "",
      dnsip: "",
      browser: this.getBrowserName(),
      transaction: "Edited E-Signature using Choose Option"
    }
      this.signatureService.PostCustomerSelectedSign(inputRequest).subscribe(
      data => {
        if (data == "1") {
          alert("E Signature Created Successfully!!");
          
          this.router.navigate(["/docs"]);
        }
        else {
          alert("E Signature Creation Failed");
        }
      });
  }
  SaveDrawESigns(form: NgForm) {
    const inputRequest: DrawnESign = {
      CustomerId: this.userId,
      CustomerFullName: "",
      CustomerInitials: "",
      DrawnESign: this.signatureImage,
      DrawnEInitial: "",
      ipaddress: "",
      dnsip: "",
      browser: this.getBrowserName(),
      transaction: "Edited E-Signature using Draw Option"
    }
      this.signatureService.PostCustomerDrawSign(inputRequest).subscribe(
      data => {
        if (data == "1") {
          alert("E Signature Created Successfully!!");
    
          this.router.navigate(["/docs"]);
        }
        else {
          alert("E Signature Creation Failed");
        }
      });
  }

  saveUploadedEsign()
  {
    this.signatureService.postESignature(this.userId, this.fileupload).subscribe(
      data => {
      
        if (data == "1") {
          alert("Signature Uploaded Successfully");
  

          this.router.navigate(["/docs"]);
          }
          else {
            alert("Image is present");
          }
      });
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1:
        return "Edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "Opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "Chrome";
      case agent.indexOf("trident") > -1:
        return "IE";
      case agent.indexOf("firefox") > -1:
        return "Firefox";
      case agent.indexOf("safari") > -1:
        return "Safari";
      default:
        return "Other";
    }
  }

  onSignFontChange(event) {
    var data = (<HTMLInputElement>event.target).value;
    if (data == "1") {
      this.selectedesign = "Bimbo Regular";
    }
    else if (data == "2") {
      this.selectedesign = "Holligate Signature Demo";
    }
    else if (data == "3") {
      this.selectedesign = "Creattion Demo";
    }
  }

  showInitial(data) {
    this.initialImage = data;
  }

  showImage(data) {
    this.signatureImage = data;
  }

}