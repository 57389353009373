<app-header></app-header>


<body class="" id="menuslist">
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>


    <p class="headtext" style="color: #c60c0c;font-size: 26px;">Dashboard</p><br />
    <p class="headtext">Customer ID: {{uid}}</p><br /><br />
    <div>&nbsp;</div>
    <div class="col-md-12 row">
        <div class="column">
            <div class="card cardcolor">
                <br />
                <p class="complaintext">Request New Service</p>
                <br /> <br /> <br />
                <div class="col-md-12 row">
                    <div class="col-md-1"></div>
                    <div class="col-md-6" style="text-align: center;">
                        <button class="btn btnprop" (click)="GotoNewService()">View Details</button>
                    </div>
                </div>
                <br /> <br />
            </div>
        </div>
        <div class="column">
            <div class="card cardcolor">
                <br />
                <p class="complaintext">Requested Services</p><br />
                <p class="complaintext">{{RequestedServicesList.length}}</p><br />
                <div class="col-md-12 row">
                    <div class="col-md-1"></div>
                    <div class="col-md-6" style="text-align: center;">
                        <a pageScroll href="#ShowRequestedDetails"> <button class="btn btnprop" (click)="GotoRequestedService()">View Details</button></a>
                    </div>
                </div>
                <br /> <br />
            </div>
        </div>
        <div class="column">
            <div class="card cardcolor">
                <br />
                <p class="complaintext">Completed Services</p><br />
                <p class="complaintext">{{CompletedServiceList.length}}</p><br />
                <div class="col-md-12 row">
                    <div class="col-md-1"></div>
                    <div class="col-md-6" style="text-align: center;">
                        <a pageScroll href="#ShowCompletedDetails"> <button class="btn btnprop" (click)="GotoCompletedService()">View Details</button></a>
                    </div>
                </div>
                <br /> <br />
            </div>
        </div>
        <div class="column">
            <div class="card cardcolor">
                <br />
                <p class="complaintext">Pending Applications</p><br />
                <p class="complaintext">{{PendingApplicationsList.length}}</p><br />
                <div class="col-md-12 row">
                    <div class="col-md-1"></div>
                    <div class="col-md-6" style="text-align: center;">
                        <a pageScroll href="#ShowPendingApplications"> <button class="btn btnprop" (click)="GotoPendingApplications()">View Details</button></a>
                    </div>
                </div>
                <br /> <br />
            </div>
        </div>
    </div>
    <div>&nbsp;</div>

    <div>&nbsp;</div>
</body>





<style>
    .modalsize {
        width: 950px;
        max-width: 950px;
        overflow-y: 50vh;
    }
    
    @media (max-width: 960px) {
        .modalsize {
            width: 450px;
            max-width: 650px;
            overflow-y: 50vh;
        }
    }
    
    @media (max-width: 575px) {
        .modalsize {
            width: 480px;
            max-width: 550px;
            overflow-y: 50vh;
        }
    }
    
    @media (max-width: 460px) {
        .modalsize {
            width: 380px;
            max-width: 450px;
            overflow-y: 50vh;
        }
    }
    
    @media (max-width: 390px) {
        .modalsize {
            width: 350px;
            max-width: 450px;
            overflow-y: 50vh;
        }
    }
    
    @media (max-width: 320px) {
        .modalsize {
            width: 310px;
            max-width: 450px;
            overflow-y: 50vh;
        }
    }
</style>