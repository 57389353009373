<app-header></app-header>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>

<div class="container">
    <h2 id="heading">EMPLOYEES</h2><br />
    <div class="col-md-12">
        <div class="col-md-11" style="text-align:right">
            <input class="btn btn-info btn-fill btn-wd" type="button" data-toggle="modal" data-target="#NewEmployee"
                type="button" id="addnew" value="Add New Employee" (click)="addnewemployee()"/><br />
        </div>
    </div>
    <div class="col-lg-12">&nbsp;</div>
    <div>
        <table class="table table-bordered">
            <thead>
                <tr style="color:black; text-align: center ">
                    <th>Sl No</th>
                    <th>Employee Id</th>
                    <th>Company Employee Id</th>
                    <th>Employee Name</th>
                    <th>Designation</th>
                    <th>Location</th>
                    <th>Contact Number</th>
                    <th>Email</th>
                    <th>Service Type</th>
                    <!-- <th>Service Request Type</th> -->
                    <!-- <th>Amount(Rs.)</th> -->
                    <th>Profile Pic</th>
                    <th>Edit</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                <tr style="color:black" *ngFor="let emp of SPEmployees;let i = index">
                    <td>{{i+1}}</td>
                    <td>{{emp.uniqueEmpId}} </td>
                    <td>{{emp.companyEmployeeId}} </td>
                    <td>{{emp.employeeName}} </td>
                    <td>{{emp.designation}} </td>
                    <td>{{emp.location}}</td>
                    <td>{{emp.contactNumber}}</td>
                    <td>{{emp.email}}</td>
                    <td>{{emp.serviceType}}</td>
                    <td><img src="{{emp.profileImage}}" style="height: 70px;width: 70px;border-radius: 20px;" />
                    </td>
                    <td (click)="editEmployee(emp)"><i class="fa fa-2x fa-edit" style="cursor: pointer;"  data-toggle="modal" data-target="#NewEmployee" ></i></td>
                    <td (click)="deleteEmployee(emp)"><i class="fa fa-2x fa-trash-o" style="cursor: pointer;" ></i></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="modal modal-angular fade" id="NewEmployee" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document" id="popupone" >
            <div class="modal-content" style="margin-top: 100px;">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
                            id="closeinto">&times;</span></button>
                </div>
                <div class="modal-body" >
                    <div>
                        <p class="modal-title w-100" id="folderText" style="text-align: center;">
                            Add New Employee</p>
                    </div>
                    <br />
                    <form #NewEmployeeForm="ngForm">
                        <!-- (ngSubmit)="SaveNewFolder(AssignTaskForm)" -->
                        <div class="col-md-12 form-group">
                            <label id="labelname">Employee Unique Id: &nbsp; {{EmployeeUniqueId}}</label>
                            <br>
                            <br>
                            <label id="labelname">Company Employee Id</label>
                            <input type="text" class="form-control" id="inputbox" name="companyemployeeid"
                                [(ngModel)]="companyemployeeid" style="background-image: none;" />
                            <br>
                            <label id="labelname">Employee Name</label>
                            <input type="text" class="form-control" id="inputbox" name="EmployeeName"
                                [(ngModel)]="EmployeeName" style="background-image: none;" />
                            <br>
                            <label id="labelname">Contact Number</label>
                            <input *ngIf="enablesave" type="text" class="form-control" id="inputbox" name="ContactNumber"
                                [(ngModel)]="ContactNumber" style="background-image: none;" />
                                <input *ngIf="enableupdate" type="text" class="form-control" id="inputbox" name="ContactNumber"
                                [(ngModel)]="ContactNumber" style="background-image: none;" readonly/>
                            <br>
                            <label id="labelname">Email</label>
                            <input *ngIf="enablesave" type="text" class="form-control" id="inputbox" name="Email" [(ngModel)]="Email"
                                style="background-image: none;" />
                                <input *ngIf="enableupdate" type="text" class="form-control" id="inputbox" name="Email" [(ngModel)]="Email"
                                style="background-image: none;" readonly/>
                            <br>
                            <label *ngIf="enablesave" id="labelname">Password</label>
                            <input *ngIf="enablesave" type="password" class="form-control" id="inputbox" name="Password" [(ngModel)]="Password"
                                style="background-image: none;" />
                            <br>
                           
                            <label for="Email " id="emaillabel ">Designation</label>
                            <select (change)="onSelectdesignation($event.target.value)" class="form-control">
                                <option value="">Select Designations</option>
                                <option *ngFor="let do of designations" value={{do.id}} [selected]="do.id==desid">
                                    {{do.designationName}}
                                </option>
                            </select>
                            <br>
                            <div *ngIf="otherdesignation">
                                <label for="Email " id="emaillabel ">Designation</label>
                                <input  formControlName="designationd" class="form-control " type="text " id="inputbutton " [(ngModel)]="designationd">
                            </div>
                            <br>
                            <label for="Email " id="emaillabel ">Location</label>
                            <select (change)="onSelectLocation($event.target.value)" class="form-control">
                                <option value="">Select Location</option>
                                <option *ngFor="let do of Locations" value={{do.id}} [selected]="do.id==lcdid">
                                    {{do.pincode}}
                                </option>
                            </select><br/>
                            <label id="labelname">Role</label>
                            <select (change)="onSelectRole($event.target.value)" class="form-control">
                                <option value="" id="selectdropdown">Select Role</option>
                                <option *ngFor="let do of RolesList" value={{do.id}} [selected]="do.id==selectedRole">
                                    {{do.roleName}}
                                </option>
                            </select>
                            <br>
                            <label id="labelname">Group</label>
                            <select (change)="onSelectGroup($event.target.value)" class="form-control">
                                <option value="" id="selectdropdown">Select Group</option>
                                <option *ngFor="let do of GroupsList" value={{do.groupId}} [selected]="do.groupId==selectedGroup">
                                    {{do.groupName}}
                                </option>
                            </select>
                            <br>
                            <label id="labelname">Service Type</label>
                            <select (change)="onSelectServiceType($event.target.value)" class="form-control">
                                <option value="" id="selectdropdown">Select Service</option>
                                <option *ngFor="let do of ServiceTypeList" value={{do.servieTypeId}} [selected]="do.servieTypeId==selectedservicetype">
                                    {{do.serviceName}}
                                </option>
                            </select>
                            <br>
                            <label id="labelname">Employee Image</label>
                            <input #fileInput type="file" class="form-control" (change)="onEmployeeImage($event)" />
                            <br>
                            <!-- <label id="labelname">Service Company Id</label>
                            <input type="text" class="form-control" id="inputbox" name="SPId" [(ngModel)]="SPId"
                                style="background-image: none;" /> -->
                        </div>

                        <div>
                            <button *ngIf="enablesave" type="submit" id="SendBtn" class="btn btn-success btn-sm waves-light" mdbWavesEffect
                                data-dismiss="modal"
                                (click)="SaveServiceProviderEmployee(NewEmployeeForm)">SAVE</button>
                                <button *ngIf="enableupdate" type="submit" id="SendBtn" class="btn btn-success btn-sm waves-light" mdbWavesEffect
                                data-dismiss="modal"
                                (click)="UpdateServiceProviderEmployee()">UPDATE</button>
                            <button type="button" class="btn btn-sm waves-light" id="CancelBtn"  data-dismiss="modal"
                                mdbWavesEffect>CANCEL</button>
                        </div>
                    </form>
                </div>


                <br>
                <br>
            </div>
        </div>
    </div>
</div>
<div>&nbsp;</div>
<div>&nbsp;</div>