<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>

<div id="test">
<h2>Hi</h2>
<h5 style="color: greenyellow;">How are u</h5>
<h5 *ngIf="!expression" class="name">{{name}}</h5>
<h5 *ngIf="expression" class="name">{{name1}}</h5>

<div class="col-md-12 row">
    <div class="col-md-6">&nbsp;</div>
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4">
        <button class="btn btn-primary" id="submitbutton"  (click)="submit()">SUBMIT</button>
    </div>
</div>
</div>




