<br>
<div class="col-md-12 row">

    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-8">
        <!-- <span class="col-md-12 text-center"><h6>Finspace</h6></span>
        <br> -->
        <p>Finspace advisors private limited (Finspace) is owner of the website <a [routerLink]="['/']">https://myfinspace.com/</a> (together called the "Company") is not a lender and does not provide loans on its own. Information carried at website is not
            and should not be construed as an offer or solicitation or invitation to borrow or lend. By submitting your query, you authorize Finspace to share your information with lender(s) and consent for such lender(s) to pull your credit information
            report and call/ message you regarding your query. The outcome of your query or application is solely a matter between you and the lender(s).

        </p>
        <p>The Company is, by no means, assuring as to the correctness of information, FAQ’s, graphics, images, text, and/ or various tools and calculators, including their result, displayed at this website or sent to the user by email or otherwise (together
            called “Information”). These are purely for indicative purposes and cannot be relied upon, either individually or together with any other information, by the user in any manner whatsoever. </p>
        <p>The Company is an independent service provider and is not related to the government or any regulator or any credit information bureau in any way; it does not have the ability to influence the outcome of a case or query or loan application in any
            manner.</p>
        <p>The Company is not guaranteeing or assuring a particular or favorable outcome. </p>
        <br>
        <p>
            The Company is not providing any legal or accounting or tax advice.
        </p>
        <br>
        <p>The Company shall have no liability whatsoever to the user for any direct, indirect or consequential losses, damages, costs, charges, expenses or otherwise, which the user or any third party may incur arising out of the use of the Information.
            </p>
        <br>
        <p>
            Any personal information, documents and credit data and/or technical and/or business and/ or commercial and/ or financial data provided by the user can be used by the Company and its agents without any obligation to the user and be shared with any regulatory
            or statutory body on their request. The Company is not liable to delete any such information from its servers or records. The Company gives the option to opt-out from receiving emails or messages at any time. Once you choose to opt-out, you
            won't receive any further communication from Finspace.
        </p>



        <br><br><br>



    </div>
    <br><br><br><br>
</div>
<br><br><br>