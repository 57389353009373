<app-header></app-header>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<br /><br />
<div class="">
  <div class="col-md-12 row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <h2 style="text-align: center">Districts List</h2>
      <div>&nbsp;</div>
    </div>
  </div>
  <div class="row col-sm-12">
    <div class="col-sm-9"></div>
    <div class="col-sm-1">
      <button style="background-color: green" class="btn btn-success" data-toggle="modal" data-target="#basicExample"
        mdbWavesEffect (click)="addClick()">
        Add
      </button><br /><br />
    </div>
  </div>
  <div class="col-md-12 row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <div class="table-responsive">
        <div style="overflow-x:visible;overflow-y: visible">
          <table class="table table-bordered" style="border-collapse: collapse;">
            <tr style="font-size:17px">
              <th>Sl No</th>
              <th>Country</th>
              <th>State</th>
              <th>Region</th>
              <th>District </th>
              <th>District Description</th>
              <th>Created By</th>
              <th>Created On</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
            <tr *ngFor="let aa of Districts| paginate: { itemsPerPage: 5, currentPage: p };let i = index"
              style="font-size:16px;font-family: DINNextRoundedLTPro">
              <td>{{i+1}}</td>
              <td>{{aa.CountryName}}</td>
              <td>{{aa.StateName}}</td>
              <td>{{aa.RegionName}}</td>
              <td>{{aa.DistrictName}}</td>
              <td>{{aa.DistrictDesc}}</td>
              <td>{{aa.CreatedBy}}</td>
              <td>{{aa.CreatedOn|date}}</td>
              <td>
                <button style="background-color:#ff8000 " class="btn btn-success" data-toggle="modal"
                  data-target="#basicExample" mdbWavesEffect (click)="editDistrict(aa)">
                  Edit
                </button>
              </td>
              <td>
                <button class="btn btn-danger" data-toggle="modal" data-target="#deleteExample" mdbWavesEffect
                  (click)="deleteDistrict(aa)">Delete</button>
              </td>
            </tr>
          </table>
        </div>
      </div>
          <br />
          <pagination-controls (pageChange)="p = $event" style="float: right" ></pagination-controls><br />
    </div>
  </div>

<br /><br />

<!-- add pop up -->
<div class="modal modal-angular fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center"> District</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body text-center">
        <form #DistrictForm="ngForm">
          <div class="col-md-12 row">
            <div class="form-horizontal container">
              <div class="col-md-12 row">
                <label class="col-md-4" style="color:black;padding-top: 13px">Country :</label>
                <div class="col-md-8">
                  <select (change)="onSelectCountry($event.target.value)" class="form-control">
                    <option value="">Select Country</option>
                    <option *ngFor="let do of Countries" value={{do.countryId}} [selected]="do.countryId == cid">
                      {{do.countryName}}
                    </option>
                  </select>
                </div>
              </div><br />
              <div class="col-md-12 row">
                <label class="col-md-4" style="color:black;padding-top: 13px">State :</label>
                <div class="col-md-8">
                  <select (change)="onSelectState($event.target.value)" class="form-control">
                    <option value="">Select State</option>
                    <option *ngFor="let do of StatesFromCountry" value={{do.StateId}}
                      [selected]="do.StateId == stateid">
                      {{do.StateName}}
                    </option>
                  </select>
                </div>
              </div><br />
              <div class="col-md-12 row">
                <label class="col-md-4" style="color:black;padding-top: 13px">Region :</label>
                <div class="col-md-8">
                  <select (change)="onSelectRegion($event.target.value)" class="form-control">
                    <option value="">Select Region</option>
                    <option *ngFor="let do of RegionsFromCountry" value={{do.RegionId}}
                      [selected]="do.RegionId == regionid">
                      {{do.RegionName}}
                    </option>
                  </select>
                </div>
              </div><br />
              <div class="col-md-12 row">
                <label class="col-md-4" style="color:black;padding-top: 13px">District Name:</label>
                <div class="col-md-8">
                  <input type="text" class="form-control" id="districtName" name="districtName"
                    placeholder="Enter District Name" [(ngModel)]="districtName" />
                </div>
              </div><br />
              <div class="col-md-12 row">
                <label class="col-md-4" style="color:black;padding-top: 13px">District Description:</label>
                <div class="col-md-8">
                  <input type="text" class="form-control" id="districtDesc" name="districtDesc"
                    placeholder="Enter District Description" [(ngModel)]="districtDesc" />
                </div>
              </div><br />
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning btn-sm waves-light" data-dismiss="modal"
           mdbWavesEffect>Close</button>
        <button *ngIf="showSave"  data-dismiss="modal" type="submit" class="btn btn-success btn-sm waves-light" mdbWavesEffect
          (click)="SaveDistrict(DistrictForm)">Save</button>
        <button *ngIf="showUpdate" data-dismiss="modal" type="submit" class="btn btn-success btn-sm waves-light"
          (click)="UpdateDistrict(DistrictForm)">Update </button>
      </div>
    </div>
  </div>
</div>
<!-- delete pop up -->
<div class="modal modal-angular fade" id="deleteExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Delete District</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <h3 style="text-align: center">Are you sure to delete..??</h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-dismiss="modal" 
          mdbWavesEffect>Close</button>
        <button data-dismiss="modal" type="submit" style="background-color: red" class="btn btn-danger" (click)="deletedistrictRow()"
          mdbWavesEffect>Delete</button>
      </div>
    </div>
  </div>
</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
  <!-- notification alert -->
  <div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
      <div class="alert alert-info"
        style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 550px;width: 350px;">
        <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <i class="material-icons" style="color: white">close</i>
        </button>
        <span style="color: white;margin-top: 6px;">
          <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
      </div>
    </div>
  </div>