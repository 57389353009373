<app-header></app-header><br><br><br><br><br><br><br><br><br>
<div class="col-md-12 row">

    <div class="col-md-1">&nbsp;</div>
    <div class="col-md-10" style="max-width: 109%;
    position: relative;">
        <!-- <span class="col-md-12 text-center"><h6>Finspace</h6></span>
        <br> -->
        <span class="col-md-12 text-center"><h5>PRIVACY POLICY</h5></span>
        <br>
        <p>This document (“Privacy Policy”) is published by virtue of the legal requirement under the provisions of the Information Technology Act, 2000 that require intermediaries to publish the rules and regulations, privacy policy and terms for access
            or usage of their website/mobile app and is primarily governed by Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 and the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal
            Data or Information) Rules, 2011.</p>
        <p><strong>Commitment</strong></p>
        <p>SRICHID intends to protect the privacy of its users and members on its Website and the privacy of the data provided by them to FINSPACE from time to time. For the purpose of this Privacy Policy, Website means and includes the website (<a [routerLink]="['/']"> www.Myfinspace.com</a>)
            and mobile application of FINSPACE.</p>
        <p>The privacy of our Website users, whether you are our registered user or merely a visitor to our Website, is important to us and we are strongly committed to your right to privacy and to keeping your personal and other information secure. We encourage
            you to read our privacy statement to understand what types of personally identifiable information we collect and how we use this information.</p>
        <p><strong>What type of information does Myfinspace.com collect from you?</strong></p>
        <p><strong>Personal Information</strong></p>
        <p>For the purpose of providing you services, we require you to enrol yourself on our Website and get yourself registered.</p>
        <ol>
            <li>If you choose to become a registered member of our Website, you are required to share your name, e-mail address, gender, date of birth, postal address, phone number, a unique login name, password, password validation, income tax details, marital
                status, family details, business information and other details shared via application form or via email or via any other medium <b>(Personally Identifiable Information).</b></li>
            <li>SRICHID may also collect other information specific to provide you service each time such as name, bank statements, KYC documents, personal details, business details, income tax details, marital status, family details and other information
                via application form or via email or via any other electronic medium or via printed form <b>(Service Information)</b>. All Personally Identifiable Information and Service Information shall be collectively known as <b>“Information”</b>.</li>
            <li>Additionally, FINSPACE collects your other details including but not limited to your feedback, suggestions, views, opinions, etc. shared on our Website <b>(User Information)</b> that may be shared/volunteered by you on our discussion forums
                or other pages of our Website. Such information being in the public domain is available for access to all our Website’s users and visitors. You are advised to exercise discretion before you make such details public, as such information
                is vulnerable to misuse and can be easily misused. FINSPACE in this regard fully disclaims any liability (ies) or claim(s), which may arise by use/misuse of User Information shared by you, by any third party or any party not known to FINSPACE.</li>
            <li>Information exchanged by you with FINSPACE in the form of written communication, responses to emails, feedback required from you, participation in discussions, etc. is handled with confidentiality.</li>
        </ol>
        <p><strong>INFORMATION COLLECTED THROUGH MOBILE APPLICATIONS</strong></p>
        <p>SRICHID services are also provided through the Mobile Application. FINSPACE may collect and use technical data and related information, including but not limited to, technical information about your device, system and application software, and
            peripherals, that is gathered periodically to facilitate the provision of software updates, product support and other services to you (if any) related to such Mobile Applications</p>
        <p>When you use our Mobile Application, the Mobile Application may automatically collect and store some or all of the following information from your mobile device (“Mobile Device Information”), in addition to the Device Information, including without
            limitation:
        </p>
        <ol>
            <li>Your preferred language and country site</li>
            <li>The manufacturer and model of your mobile device (including storage, installed apps, )</li>
            <li>Your mobile operating system</li>
            <li>The type of mobile internet browsers you are using</li>
            <li>Your geolocation</li>
            <li>Information about how you interact with the Mobile Application and our web site to which the Mobile Application links</li>
            <li>Financial Transactional SMS</li>
        </ol>
        <p><strong>Non-Personal Information</strong></p>
        <p>SRICHID also collects certain other information from you on your visit to our Website such as information about your operating system, browser type, the URL of the previous website you visited, list of third-party applications being used by you,
            your internet service provider and your IP Address <b>(Non-personal Information)</b>. This information cannot be easily used to personally identify you. We use Non-personal Information for the purposes including but not limited to troubleshoot
            connection problems, administer the Website, analyze trends in the market, gather demographic information, to ascertain how our visitors use our Website, including information relating to the frequency of visits to our Website, average length
            of visits, pages viewed during a visit, ensuring compliance with the applicable law, co-operating with law enforcement activities, etc. This information is used to improve the Website content and performance. You also agree that FINSPACE may
            gather usage statistics and usage data from your use of our Website to evaluate your use of our products/services, to improve our products/services, Website content and to ensure that you are complying with the terms of the applicable agreements
            between you and FINSPACE. The statistics and data collected may or may not be aggregated. These statistics contain no information that can distinctly identify you.</p>
        <p>We may in future also share this information with third party service providers or third party advertisers to measure the overall effectiveness of our online advertising, content, programming and for other bonafide purpose, as we may desire. By
            usage of our Website you expressly permit FINSPACE to access such information for one or more purposes deemed fit by FINSPACE.</p>
        <p><strong>Cookies</strong></p>
        <p>Similar to other commercial web sites, our Website utilizes standard technology called “Cookies” and Web server logs to collect information about how our Website is used. Cookies are small pieces of information that are stored by your browser
            on your device’s hard drive. Cookies are only read by the server that places them, and are unable to inter alia run programs on your device, plant viruses or harvest your personal information. Cookies allow Web servers to recognize your device
            each time you return to our Website including the date and time of visits, the pages viewed, time spent at our Website, and the websites visited just before and just after our Website, verify your registration or password information (in case
            of registered users) during a particular session so that you do not have to input your password multiple times as you navigate the site and suggest products or services that may interest you. This information is collected on an aggregate basis.
            None of this information is associated with you as an individual.</p>
        <p>Most web browsers automatically accept cookies. By changing the options on your web browser or using certain software programs, you can control how and whether cookies will be accepted by your browser. FINSPACE understands and supports your right
            to block any unwanted Internet activity, especially that of unscrupulous websites. However, blocking FINSPACE cookies may disable certain features on our Website and may make it impossible for you to use certain services available on our Website.</p>
        <p><strong>Session Data</strong></p>
        <p>We automatically log generic information about your device’s connection to the Internet, which we call “session data”, that is anonymous and not linked to any personal information. Session data consists of information such as the IP address, operating
            system, type of browser software being used by you and the activities conducted by you on our Website. An IP address is a number that lets device attached to the Internet, such as our web servers, know where to send data back to the user,
            such as the pages of the site the user wishes to view. We collect session data because it helps us analyze things such as the items visitors are likely to click on most, the manner in which visitors click preferences on our Website, number
            of visitors surfing to various pages on the Website, time spent by the visitors on our Website and frequency of their visit. It also helps us diagnose problems with our servers and lets us better administer our systems. Although such information
            does not identify any visitor personally, it is possible to determine from an IP address a visitor’s Internet Service Provider (ISP), and the approximate geographic location of his or her point of connectivity.</p>
        <p><strong>Sharing of Information</strong></p>
        <p>SRICHID may share your Information, including the bureau reports, with its service providers, affiliate(s), agents, partners and such other third parties for the purposes of (i) providing the services and to perform the functions on your behalf
            or on behalf of FINSPACE; (ii) marketing new products and services by FINSPACE or by any of its affiliate(s), service partners and third parties; or (iii) marketing of services provided by such third parties, affiliates and service partners
            of FINSPACE. It is hereby expressly declared that FINSPACE shall not be responsible for use of your Information by such third parties and service partners and their privacy policies.</p>
        <p><strong>Third Party Products and Services</strong></p>
        <p>There are services and/or products offered by third parties on the Website. If you choose to apply for these third-party products and/or services, then their use of your Information shall be governed by their privacy policies and FINSPACE shall
            not be responsible for their use of your Information and their privacy policies.</p>
        <p>SRICHID shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site
            or resource.</p>
        <p><strong>New Products and Services</strong></p>
        <p>SRICHID may use your Information to intimate you for any new products, services, offers and/or promotions being offered by FINSPACE or any of its affiliate(s), third partners or service partners.</p>
        <p><strong>Other</strong></p>
        <p>We may from time to time add or enhance products/services available on our Website. To the extent these products/services are provided to and used by you, we will use the information you provide in this regard to facilitate the products/service
            requested. For example, if you email us with a question, we will use your email address, name, nature of the question, etc. to respond to your question. We may also store and publish such information to assist us in making the Website better
            and easier to use.</p>
        <p><strong>Promotional Initiatives</strong></p>
        <p>SRICHID may have presence on social networking websites including but not limited to LinkedIn, Facebook, Twitter, YouTube and blogs which are promotional and business initiatives to connect to a larger group of people. The domain links contained
            therein may either direct you to our Website or request your participation by way of feedback, suggestions. FINSPACE in this regard fully disclaims any liability (ies) or claim(s), which may arise by use/misuse of your feedback, suggestions,
            views, etc. on any of the aforementioned networking websites or blogs, by any third party whether or not known to FINSPACE.</p>
        <p><strong>Links to Other Sites</strong></p>
        <p>Our Website may contain links to other websites. If you click on a third-party link, you will be directed to that website. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the privacy policy of
            these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
        <p><strong>Does FINSPACE protect my Personal Information?</strong></p>
        <p>SRICHID is committed to protecting the privacy and the confidentiality of your Personal Information. Whenever FINSPACE obtains Information from you, our Website uses commercially reasonable efforts and general industry standards to protect it
            from any unauthorized access or disclosure. FINSPACE uses its best endeavours to maintain physical, electronic and procedural safeguards that aim to protect your information against loss, misuse, damage, modification, and unauthorized access
            or disclosure. However, FINSPACE assumes no liability whatsoever for any disclosure of Information to any third party(ies) and/ or service partners for providing any services to you by such third parties and/ or service partners. FINSPACE
            disclaims any liability for the use of your Information by such third party(ies) and/or service partners and their privacy policies.</p>
        <p><strong>Does FINSPACE secure the content its users post from other users?</strong></p>
        <p>The content that you store, post, or transmit on or through our Website, may be accessed by other users (registered or unregistered), including people that you do not know. The content/images that you post/share on our Website may be prone to
            misuse by a party not known to you. FINSPACE in this regard fully disclaims any liability (ies) or claim(s), which may arise by use/misuse of such content, by any third party or any party not known to FINSPACE. We suggest that you exercise
            care and caution in communicating with other users and only disclose your information to other users that you know to be trustworthy. You should not assume that your content will be kept private.</p>
        <p><strong>How does FINSPACE use the information that it collects?</strong></p>
        <p>Information shared by you is used by us and/or authorized third parties/service partners/affiliate(s) to support your interaction with us, to offer you services in the best possible manner and to contact you again about other services and products
            that we and/or authorized third parties/service partners/affiliate(s) offer. By submitting your Information to FINSPACE, you expressly acknowledge and consent to FINSPACE to use such Information and to process the same in a manner deemed fit
            by FINSPACE. This may involve conducting data analysis, research based on reviews about deals, transfer of your Information to third party experts for the purpose of outsourcing services offered to you by FINSPACE, transfer your Information
            to third parties/ service partners for providing you services offered by such third parties and service partners etc.</p>
        <p><strong>What security precautions are in place to protect against the loss, misuse, or alteration of your information?</strong></p>
        <p>SRICHID makes every reasonable effort to preserve the privacy and confidentiality of your information shared with us. We implement standard measures to protect against unauthorized access to and unlawful interception of Personal Information.
            However, no Internet website can fully eliminate security risks.</p>
        <p>We reserve the right to disclose information shared by you without your consent (express or implied) and without any liabilities to you when required or permitted by law and we have a good-faith belief that (i) such action is necessary to comply
            with an appropriate law enforcement investigation, current judicial proceeding, a court order or legal process served on us or (ii) to conform to the legal requirements, compliance/reporting to administrative and judicial authorities, as may
            be required (iii) protect and defend the rights or property of the website owners of FINSPACE, or the users of FINSPACE, (iv) person(s) or company (ies) who provide certain support services to us, or (v) person(s) or company (ies) who share
            certain responsibilities/obligations of FINSPACE and (vi) to third parties/ service partners for providing you services offered by such third parties and service partners. Further, we reserve the right to disclose Information shared by you
            without your consent with entities engaged in providing the credit scores or credit information related services like CIBIL, etc.</p>
        <p><strong>I have more questions about privacy policy</strong></p>
        <p>If you have any questions about this Privacy Policy, the practices of this Website, or your dealings with this Website, please contact us at <a href="mailto:grievance@Myfinspace.com">grievance@Myfinspace.com</a> .</p>
        <p><strong>Will FINSPACE change privacy policy in the future?</strong></p>
        <p>This policy provides a general statement of the ways in which FINSPACE aims to protect your information. You may, however, in connection with specific products or services offered by FINSPACE, be provided with privacy policies or statements that
            substitute or supplement this policy. The terms and conditions of this Privacy Policy may be changed from time to time, without your consent (express or implied) to reflect changes in our practices concerning the collection and use of your
            information. The revised Privacy Policy will be effective immediately upon posting to our Website unless otherwise mentioned. This version of the policy is effective from 28th June, 2019. The revised Privacy Policy shall bind you and it is
            your sole responsibility to check the same from time to time. You acknowledge that no knowledge of the revised Privacy Policy shall be a defence in any proceedings with regard to the Privacy Policy.</p>
        <p><strong>Your Consent</strong></p>
        <p>By accessing our Website and using it on a continued basis, you expressly consent and confirm to FINSPACE collecting, maintaining, using, processing and disclosing your Personal Information and other information in accordance with this Privacy
            Policy.
        </p>
        <p><strong>I have a complaint/concern</strong></p>
        <p>In accordance with the relevant provisions of the Information Technology Act, 2000 and Rules made thereunder, the name and contact details of the Grievance Officer who can be contacted with respect to any complaints or concerns including those
            pertaining to breach of FINSPACE’s Terms of Use, Privacy Policy and other polices or questions are published as under:</p>
        <p><strong>Grievance Officer Name:  </strong></p>
        <p><strong>Email address: </strong> <a href="mailto:grievance@Myfinspace.com">grievance@Myfinspace.com</a></p>
        <p>The Grievance Officer can be contacted between 10:30 a.m. to 6:00 p.m. from Monday to Friday except on public holidays.</p>
        <br><br><br><br><br>

    </div>
    <div class="col-md-1">&nbsp;</div>

</div>