


<app-header></app-header>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>

<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
        
        
        <div class="col-md-12 row" *ngIf="roleid=='2'">
            <div class="col-md-9"></div>
            <div class="col-md-3">
                <button class="btn btn-primary" (click)="DownloadForm()">Download</button>
            </div>
        </div>
        <div class="col-md-12 row">&nbsp;</div>
        <div class="col-md-12 row">&nbsp;</div>
        <div class="homecard" #content id="print-section" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%">
        
            <div class="col-md-12 row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Personal Details</h2>
                </div>
                <div class="col-md-2">
                    <button *ngIf="roleid!='2'"  class="btn btn-primary" (click)="personaldetailsclick()">Edit</button>
                </div>
            </div>

            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">
                <div class="col-md-12 row">
                    <div class="col-md-2 row">&nbsp;</div>
                    <div class="col-md-3 row" style="display: flex;">
                        <label id="emaillabel">First Name:</label>&nbsp;{{fname}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email" id="emaillabel ">Middle Name:</label>&nbsp;{{mname}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel">Last Name:</label>&nbsp;{{lname}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel">Place of Birth:</label>&nbsp;{{placebirth}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email" id="emaillabel">Gender:</label>&nbsp;{{gender}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel"> Marital Status:</label>&nbsp;{{marital}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Mobile Number:</label>&nbsp;{{mobile}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Date of Birth:</label>&nbsp;{{dob | date : 'shortDate'}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Religion:</label>&nbsp;{{religion}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Caste:</label>&nbsp;{{caste}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Qualification:</label>&nbsp;{{qualification}}


                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Occupation:</label>&nbsp;{{occupation}}


                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> Email:</label>&nbsp;{{email}}

                    </div>

                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Designation:</label>&nbsp;{{designation}}


                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Postal Code:</label>&nbsp;{{rPostal}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Country:</label>&nbsp;{{rCountry}} India
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> State:</label>&nbsp;{{rState}}
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">District:</label>&nbsp;{{rDistrict}}
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Address one:</label>&nbsp;{{rAddress1}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address two:</label>&nbsp;{{rAddress2}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Permanent Address</h4>

             
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Postal Code:</label>&nbsp;{{pPostal}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Country:</label>&nbsp;{{pCountry}} India
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> State:</label>&nbsp;{{pState}}
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">District:</label>&nbsp;{{pDistrict}}
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address one:</label>&nbsp;{{pAddress1}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address two:</label>&nbsp;{{pAddress2}}

                        </div>
                    </div>
                </div>

            </div>
            <br>
            <br>
             <!-- FAMILY DETAILS -->
            <div class="col-md-12 " *ngIf="familyDetails">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Family Details</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'" class="btn btn-primary" (click)="familydetailsclick()">Edit</button>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 ">
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Family Type:</label> &nbsp;
                            <!-- <span *ngIf="familyDetails?.familyTypeString == null">Extended Family</span> -->
                            <span *ngIf="familyDetails?.familyTypeString != null">{{familyDetails?.familyTypeString}}</span>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Number of Dependents:</label>&nbsp;
                            <!-- <span
                            *ngIf="familyDetails?.numberofDependents == null || familyDetails?.numberofDependents == undefined">2</span> -->
                            <span *ngIf="familyDetails?.numberofDependents != null">{{familyDetails.numberofDependents}}</span>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Number of Children:</label>&nbsp;
                            <!-- <span
                            *ngIf="familyDetails.numberofChildren == null || familyDetails.numberofChildren == undefined">3</span> -->
                            <span *ngIf="familyDetails.numberofChildren != null">{{familyDetails.numberofChildren}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Annual Income in Rs. :</label>&nbsp;
                            <!-- <span *ngIf="familyDetails.annualIncomeAmount == null">25000</span> -->
                            <span *ngIf="familyDetails.annualIncomeAmount != null">{{familyDetails.annualIncomeAmount}}</span>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Income from Other sources in Rs. :</label>&nbsp;
                            <!-- <span *ngIf="familyDetails.otherSourceAmount == null">1000</span> -->
                            <span *ngIf="familyDetails.otherSourceAmount != null">{{familyDetails.otherSourceAmount}}</span>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Total Annual Income:</label>&nbsp;
                            <!-- <span *ngIf="familyDetails.totalAmount == null">270000</span> -->
                            <span *ngIf="familyDetails.totalAmount != null">{{familyDetails.totalAmount}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Minor Nominee</h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">First Name:</label>&nbsp;
                            <!-- <span *ngIf="familyDetails.minorNomineeName == null">Raj</span> -->
                            <span *ngIf="familyDetails.minorNomineeName != null">{{familyDetails.minorNomineeName}}</span>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Middle Name:</label>&nbsp;
                            <!-- <span *ngIf="familyDetails.minorNomineeDOB == null">Raj</span> -->
                            <span *ngIf="familyDetails.minorNomineeDOB != null">{{familyDetails.minorNomineeDOB|date}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>

                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Last Name:</label>&nbsp;
                            <!-- <span *ngIf="familyDetails.minorNomineeName == null">Raj</span> -->
                            <span *ngIf="familyDetails.minorNomineeName != null">{{familyDetails.minorNomineeName}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Postal Code:</label>&nbsp;{{familyDetails.minorPostalCode}}
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Country:</label>&nbsp;{{familyDetails.minorCountry}}
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> State:</label>&nbsp;{{familyDetails.minorState}}
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">District:</label>&nbsp;{{familyDetails.minorDistrict}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Address Line One:</label>&nbsp;
                            <!-- <span *ngIf="familyDetails.minorNomineeAddress == null">DIVYANKA, 2nd cross</span> -->
                            <span *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.minorAddress1}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address Line Two:</label>&nbsp;
                            <!-- <span *ngIf="familyDetails.minorNomineeAddress == null">3rd main</span> -->
                            <span *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.minorAddress2}}</span>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                    </div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Guardian Details</h3><br/>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Guardian Name:</label>&nbsp;
                            <!-- <span *ngIf="familyDetails.minorNomineeAddress == null">DIVYANKA, 2nd cross</span> -->
                            <span *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.guardianName}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Relationship:</label>&nbsp;
                            <!-- <span *ngIf="familyDetails.minorNomineeAddress == null">3rd main</span> -->
                            <span *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.guardianReationship}}</span>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                    </div>
                    <div id="familydocsid" *ngIf="familydocsexist">
                        <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="col-md-12 row">
                            <div class="col-md-1 row">&nbsp;</div>

                            <div class="col-md-2 row" *ngFor="let item of familydocs">
                                <div *ngIf="item.documentType == 'image/jpeg' ||
                            item.documentType == 'image/png'" (click)="ViewBankFamDoc(item)">
                                    <img [src]="item.file" height="100" width="100" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf=" (item.documentType == 'application/pdf' ||
                            item.documentType == 'pdf')" (click)="ViewBankFamDoc(item)">
                                    <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                            item.documentType == 'xlsx')" (click)="ViewBankFamDoc(item)">
                                    <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                            item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewBankFamDoc(item)">
                                    <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                </div>
            </div>
            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">&nbsp;</div>

             <!-- BANK DETAILS -->
            <div class="col-md-12 row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Bank Details</h2>
                </div>
                <div class="col-md-2">
                    <button *ngIf="roleid!='2'" class="btn btn-primary"  (click)="bankdetailsclick()">Edit</button>
                </div>
            </div>
            <div class="col-md-12 ">&nbsp;</div>

            <div class="col-md-12 ">
                <div class="container" style="margin-top: 5%">

                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Bank Account Type</th>
                                <th>Bank Name</th>
                                <th>Branch</th>
                                <th>IFSC Code</th>
                                <th>Cheque Facility</th>
                                <!-- <th>Loan Facility</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of bankdetails">
                                <td>{{data.banktypeid}}</td>
                                <td>{{data.bankname}} </td>
                                <td>{{data.branch}} </td>
                                <td>{{data.ifscecode}} </td>
                                <td>{{data.chequefacility}}</td>
                                <!-- <td>{{data.loanfacility}}</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12 row ">


                    <div class="col-md-12 ">&nbsp;</div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;text-align: center;    margin-left: 42%; ">Identity Details</h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Ration Card Number:</label> &nbsp;{{userBankDetails.rationCardNum}}

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Aadhar Number:</label>&nbsp;{{userBankDetails.aadharNum}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Voter ID Number:</label>&nbsp;{{userBankDetails.voterId}}

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">PAN Number:</label>&nbsp;{{userBankDetails.panNum}}

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-4 row ">
                            <label for="Email " id="emaillabel ">Service Tax Number:</label>&nbsp;{{userBankDetails.serviceTaxNum}}

                        </div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Passport Number:</label>&nbsp;{{userBankDetails.passportNum}}

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Life Insurance Value:</label>&nbsp;{{userBankDetails.lifeInsuranceValue}}

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Confirm:</label>&nbsp;{{userBankDetails.isConfirm}}

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>



                    <BR/>
                    <div class="col-md-12 row" id="bankdocsid">
                    <div class="col-md-12 row">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;margin-left: 15%; ">Uploaded Documents</h3> <br>
                    <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;margin-left: 15%; ">Download Documents</h5> <br>

                    <ol>
                        <li *ngIf="userBankDetails.aadharFile"><a href="{{userBankDetails.aadharFile}}">Aadhar</a></li>
                        <li *ngIf="userBankDetails.bankStatements"><a href="{{userBankDetails.bankStatements}}">Bank Statement</a></li>
                        <li *ngIf="userBankDetails.gstCert"><a href="{{userBankDetails.gstCert}}">GST</a></li>
                        <li *ngIf="userBankDetails.itReturnsFile"><a href="{{userBankDetails.itReturnsFile}}">ITR</a></li>
                        <li *ngIf="userBankDetails.passportFile"><a href="{{userBankDetails.passportFile}}">Passport</a></li>
                        <li *ngIf="userBankDetails.voterIdFile"><a href="{{userBankDetails.voterIdFile}}">Voter ID</a></li>

                    </ol>
                    <div class="col-md-12 row">&nbsp;</div>
                    <div class="col-md-12 row">&nbsp;</div>

                    <div class="col-md-2 row" *ngFor="let item of bankdocs" style="    margin-left: 8%;">
                        <div class="col-md-1 row">&nbsp;</div>

                        <div *ngIf="item.documentType == 'image/jpeg' ||
                        item.documentType == 'image/png'" (click)="ViewBankFamDoc(item)">
                            <img [src]="item.file" height="100" width="100" />
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-10 row">
                                    <p style="text-align: center;">{{item.documentName}}</p>

                                </div>

                            </div>
                        </div>
                        <div *ngIf=" (item.documentType == 'application/pdf' ||
                        item.documentType == 'pdf')" (click)="ViewBankFamDoc(item)">
                            <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-10 row">
                                    <p style="text-align: center;">{{item.documentName}}</p>

                                </div>

                            </div>
                        </div>
                        <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        item.documentType == 'xlsx')" (click)="ViewBankFamDoc(item)">
                            <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-10 row">
                                    <p style="text-align: center;">{{item.documentName}}</p>

                                </div>

                            </div>
                        </div>
                        <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewBankFamDoc(item)">
                            <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-10 row">
                                    <p style="text-align: center;">{{item.documentName}}</p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                    <br>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
            </div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">&nbsp;</div>

            <!-- ASSESTS DETAILS -->
            <div class="col-md-12 row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Asset Details</h2>
                </div>
                <div class="col-md-2">
                    <button  *ngIf="roleid!='2'" class="btn btn-primary"  (click)="assetdetailsclick()">Edit</button>
                </div>
            </div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 " *ngIf="moveableexist">
                <div class="container" style="margin-top :  5%">

                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Fixed Asset</th>
                                <th>Location with Address</th>
                                <th>Measurement</th>
                                <th>Guidance Value</th>
                                <th>Present Value</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of fixedassetDetails.fixedAssets;let i = index" style="font-size : 16px;font-family : DINNextRoundedLTPro ">                              
                                <td>
                                    {{data.fixedAssetId}}
                                </td>
                                <td>{{data.placeallocation}}</td>

                                <td>{{data.measurement}}</td>
                                <td>{{data.giidancevalue}}</td>
                                <td>{{data.presentvalue}}</td>
                                <td>{{data.total}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="container" style="margin-top :  5%">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Movable Asset</th>
                                <th>Model</th>
                                <th>Date Of Purchase</th>
                                <th>Quantity</th>
                                <th>Present Value</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let dat of fixedassetDetails.movableAssets;let i = index" style="font-size : 16px;font-family : DINNextRoundedLTPro ">
                                <td>
                                    {{dat.movableAssetId}}
                                </td>
                                <td>{{dat.model}}</td>
                                <td>{{dat.dateofpurchase|date}}</td>
                                <td>{{dat.quantity}}</td>
                                <td>{{dat.prevalues}}</td>
                                <td>
                                    {{dat.cartotal}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">&nbsp;</div>

             <!-- LOANS FORMS -- STEP5 -->

             <div class="col-md-12 " *ngIf="businessloan">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Business Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'"  class="btn btn-primary" (click)="businessdetailsclick()">Edit</button>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 ">&nbsp;</div>

                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">GST No:</label> &nbsp;{{GSTNo}} 

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Business Address & Tel No:</label>&nbsp;{{businessAddTelNo}}

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Whether the Applicant belongs to : SC / ST /
                            OTHERS:</label>&nbsp;{{scst}} 
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Factory / Godown Address & Tel No:</label>&nbsp;{{Factoryaddress}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Name(s) of Partners / Proprietor /
                            Directors:</label>&nbsp;{{partnername}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Partner Age / Proprietor / Directors:</label>&nbsp;{{agepartner}} 45

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Past Experience:</label>&nbsp;{{experiencepast}} Srichid

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Residential Address of Partners / Proprietor /
                            Directors:</label>&nbsp;{{resiaddr}}

                    </div>


                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Partner / Proprietor / Directors Amount Share in
                            amt:</label>&nbsp;{{partnershare}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Whether the applicant is technically qualified /experienced /
                            educated unemployed? if so furnish details:</label>&nbsp;{{techqualified}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel "> Line of activity and year of Establishment:</label>&nbsp;{{loaandyearofest}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Loan Amount:</label>&nbsp;{{loanamt}}

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Credit facilities required</h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Term Loan Purpose:</label>&nbsp;{{TermLoanpurpose}}

                    </div>


                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Working capital Purpose:</label>&nbsp;{{WorkingcapitalPurpose}}

                    </div>

                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Term Loan Amount:</label>&nbsp;{{TermLoanAmount}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Working capital Amount:</label>&nbsp;{{WorkingcapitalAmount}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Term Loan Repayment programme:</label>&nbsp;{{TermLoanRepaymentprogramme}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div>
                    <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;font-size: 20px; ">Whether the Business Premises are own / rented / leased:
                    </h5>&nbsp;{{businessPremisesOwn}}
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div>
                    <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;font-size: 20px; ">Will applicant be able to route his trade proceeds through the bank:</h5>&nbsp;{{routerThroughBank}}
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div>
                    <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;font-size: 20px; ">Are basic books of accounts like Stock book, sales / Purchase books, Day Book,etc. being maintained:</h5>&nbsp;{{booksmaintained}}
                </div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Working capital Repayment programme:</label> &nbsp;{{workingrepaymentprogramme}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Names and addresses of Associate concerns (if any)
                            Ph No:</label>&nbsp;{{nameandAddressofAssociateConcerns}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">If not able to route his, what difficulties prevent
                            him from doing so
                        :</label>&nbsp;{{difficulties}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Commodities traded in or services rendered (Marketing):</label>&nbsp;{{commoditiesTraded}}
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Sources, Major Suppliers of the commodities
                            (Marketing):</label>&nbsp;{{majorSuppliers}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">(iii) No. of firms (with names) engaged in similar
                            activity in the same locality (Marketing):</label>&nbsp;{{numberOfFirms}} 1

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">(iv) How will the competition be met successfully?
                            (Marketing):</label>&nbsp;{{competationSuccessfull}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Anticipated performance in the case of Existing units</h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">(i) Performance in the past two years
                </h3>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Year1:</label>&nbsp;{{year1}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Sales1:</label>&nbsp;{{sales1}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Net Profit1:</label>&nbsp;{{netProfit1}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Remarks1:</label>&nbsp;{{remarks1}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Year2:</label>&nbsp;{{year2}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Sales2:</label>&nbsp;{{sales2}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Remarks2:</label>&nbsp;{{remarks2}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Net Profit2:</label>&nbsp;{{netProfit2}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">(ii) Anticipated turn over</h3>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Year:</label>&nbsp;{{anticipatedYear}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Sales:</label>&nbsp;{{anticipatedSales}}

                    </div>

                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Net Profit:</label>&nbsp;{{anticipatedProfit}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Remarks:</label>&nbsp;{{anticipatedRemarks}}

                    </div>
                </div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">(iii) How is the anticipated turnover considered feasible of achievement?</h3>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Year:</label>&nbsp;{{feasibleYear}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Sales:</label>&nbsp;{{feasibleSales}}

                    </div>

                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Net Profit:</label>&nbsp;{{feasibleNetProfit}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Remarks:</label>&nbsp;{{feasibleRemarks}}

                    </div>
                </div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">(iv) Particulars of Sales Tax and Income Tax Assessment.(copies to the enclosed)</h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">I.T. Assessment Year:</label>&nbsp;{{itAssessmentYear}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Amount Paid:</label>&nbsp;{{itAmounPaid}}

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">S.T. Assessment Year:</label>&nbsp;{{stAssessmentYear}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Amount Paid:</label>&nbsp;{{stAmountPaid}}

                    </div>
                </div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Working capital requirement for anticipated turn over</h3>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Value:</label>&nbsp;{{AnticipatedValue}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Margin:{{AnticipatedMargin}}</label>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Permissible Limit:{{AnticipatedPermissibleLimit}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Remarks:{{AntiRemarks}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Average level of stocks required at any one time</h3>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Value:</label>&nbsp;{{avgLvlValue}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Margin:</label>&nbsp;{{avgLvlMargin}}

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Permissible Limit:</label>&nbsp;{{avgLvlPermissibleLimit}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Remarks:</label>&nbsp;{{avgLvlRemarks}}

                    </div>
                </div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Average level of outstanding DEBTORS at any one time</h3>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Value:</label>&nbsp;{{outSatndingValue}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Margin:</label>&nbsp;{{outSatndingMargin}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Permissible Limit:</label>&nbsp;{{outStandingPermissibleLimit}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Remarks:</label>&nbsp;{{outStandingRemarks}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Working Capital required</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Rs: </label>&nbsp;{{workingCapitalRequired}}

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Credit available from suppliers</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Rs:</label>&nbsp;{{creditAvailableSuppliers}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Repayment Programme if any, for the Loan.
                            (Applicable to Term Loan):</label>&nbsp;{{repaymentProgramme}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Finance for Equipment (proforma Invoice / to be attached)</h3>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <h4> SL. No</h4>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <h4>(i)</h4>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <h4>(ii)</h4>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <h4> (iii)</h4>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Item:</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{item1}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{item2}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{item3}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Supplier:</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{supplier1}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{suppier2}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{supplier3}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Cost:</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{cost}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{cost1}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{cost2}}</label>

                    </div>
                </div>





                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Less applicant's contribution Rs:</label>&nbsp;{{lessapplicantscontribution}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Loan required for equipment Rs:</label>&nbsp;{{loanEquipmentRupees}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">


                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Repayment Program :</label>&nbsp;{{reapymentProgamme}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel "> Security : Primary<br />(for Wc & TL to be
                            indicated separately):</label>&nbsp;{{security}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> How long it will take for the equipment to be erected /
                            anticipated sales to be reached:</label>&nbsp;{{timeToErect}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Start up period require:</label>&nbsp;{{startUpPeriod}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <!-- <div class="col-md-12 row ">


                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Repayment Programme:</label>
                    </div>
                </div> -->
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Details relating to collateral security
                </h3>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">In case of immovable property:</label> &nbsp;{{detailsofImmovableProperty}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Convert as LSR/Valuation:</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Co-obligant/Guarantor (His Direct & Indirect Liability to be mentioned)
                </h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Guarantor Property:</label>&nbsp;{{guarantor}} Nil


                    </div>

                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Guarantor Convert:</label>&nbsp;{{guarantorValue}}


                    </div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Firm name:</label>&nbsp;{{firmName}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Brief description of goods:</label>&nbsp;{{desc1}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Rs:</label>&nbsp;{{desc1Rs}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Brief description of goods:</label>&nbsp;{{desc2}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Rs:</label>&nbsp;{{desc2Rs}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Brief description of goods:</label>&nbsp;{{desc3}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Rs:</label>&nbsp;{{desc3Rs}}

                    </div>
                </div>
                <!-- <BR />
                <button (click)="businessIn(stepper1)" id="signinbutton">SAVE</button> -->
            </div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">&nbsp;</div>

            <div class="col-md-12 " *ngIf="educationloan">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Education Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'"  (click)="educationloandetailsclick()" class="btn btn-primary">Edit</button>
                    </div>
                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">FINANCIAL DETAILS</h3>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Income : </label>
                        <span *ngIf="educationLoanDetails.facilities != null">{{educationLoanDetails.facilities}}</span>


                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Assets : </label>
                        <span *ngIf="educationLoanDetails.finance != null">{{educationLoanDetails.finance}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Liabilities : </label>
                        <span *ngIf="educationLoanDetails.worth != null">{{educationLoanDetails.worth}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">ACADEMIC BACKGROUND</h3>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Examination from SSC : </label>
                        <span *ngIf="educationLoanDetails.examination != null">{{educationLoanDetails.examination}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Name of board,University : </label>
                        <span *ngIf="educationLoanDetails.university != null">{{educationLoanDetails.university}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Year of Passing : </label>
                        <span *ngIf="educationLoanDetails.register != null">{{educationLoanDetails.register}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Attempts Made : </label>
                        <span *ngIf="educationLoanDetails.authority != null">{{educationLoanDetails.authority}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">% of marks obtained : </label>
                        <span *ngIf="educationLoanDetails.activity != null">{{educationLoanDetails.activity}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Class Divison : </label>
                        <span *ngIf="educationLoanDetails.establishment != null">{{educationLoanDetails.establishment}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Particular of Scholarship/Prize won : </label>
                        <span *ngIf="educationLoanDetails.proprietary != null">{{educationLoanDetails.proprietary}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Extra Curricular activities : </label>
                        <span *ngIf="educationLoanDetails.maintained != null">{{educationLoanDetails.maintained}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Other relevant information : </label>
                        <span *ngIf="educationLoanDetails.relevant != null">{{educationLoanDetails.relevant}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Remarks : </label>
                        <span *ngIf="educationLoanDetails.eduremrks != null">{{educationLoanDetails.eduremrks}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">LOAN REQUIREMENT</h3>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Course applied : </label>
                        <span *ngIf="educationLoanDetails.course != null">{{educationLoanDetails.course}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Fulltime/Parttime : </label>
                        <span *ngIf="educationLoanDetails.parttime != null">{{educationLoanDetails.parttime}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Duration of course : </label>
                        <span *ngIf="educationLoanDetails.competent != null">{{educationLoanDetails.competent}}</span>
                    </div>
                </div>

                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Date of Admission : </label>
                        <span *ngIf="educationLoanDetails.admission != null">{{educationLoanDetails.admission}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Name of institution/university : </label>
                        <span *ngIf="educationLoanDetails.institution != null">{{educationLoanDetails.institution}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Other Relevant inform : </label>
                        <span *ngIf="educationLoanDetails.present != null">{{educationLoanDetails.present}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">EXPENDITURE OF COURSE</h3>
                <div class="col-md-12 ">&nbsp;</div>


                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th style=" width: 22%;"></th>
                            <th>1st Year</th>
                            <th>2nd Year</th>
                            <th>3rd Year</th>
                            <th>4th Year</th>
                            <th>5th Year</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Tuition Fees</td>
                            <td>
                                <span *ngIf="educationLoanDetails.tuition != null">{{educationLoanDetails.tuition}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.tuitionsec != null">{{educationLoanDetails.tuitionsec}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.tuitionthr != null">{{educationLoanDetails.tuitionthr}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.tuitionfour != null">{{educationLoanDetails.tuitionfour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.tuitionfive != null">{{educationLoanDetails.tuitionfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Examination Fees</td>
                            <td>
                                <span *ngIf="educationLoanDetails.feesfirst != null">{{educationLoanDetails.feesfirst}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.feessec != null">{{educationLoanDetails.feessec}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.feesthree != null">{{educationLoanDetails.feesthree}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.feesfour != null">{{educationLoanDetails.feesfour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.feesfive != null">{{educationLoanDetails.feesfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Books, Stationary</td>
                            <td>
                                <span *ngIf="educationLoanDetails.stationary != null">{{educationLoanDetails.stationary}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.books != null">{{educationLoanDetails.books}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.bookssta != null">{{educationLoanDetails.bookssta}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.booksstafour != null">{{educationLoanDetails.booksstafour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.booksfive != null">{{educationLoanDetails.booksfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Equipment</td>
                            <td>
                                <span *ngIf="educationLoanDetails.equipmentone != null">{{educationLoanDetails.equipmentone}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.equipmenttwo != null">{{educationLoanDetails.equipmenttwo}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.equipmentthree != null">{{educationLoanDetails.equipmentthree}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.equipmentfour != null">{{educationLoanDetails.equipmentfour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.equipmentfive != null">{{educationLoanDetails.equipmentfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Hostel Board</td>
                            <td>
                                <span *ngIf="educationLoanDetails.hostel != null">{{educationLoanDetails.hostel}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.hostelboard != null">{{educationLoanDetails.hostelboard}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.boardHostel != null">{{educationLoanDetails.boardHostel}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.boardHotfour != null">{{educationLoanDetails.boardHotfour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.boardHfive != null">{{educationLoanDetails.boardHfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Sundries</td>
                            <td>
                                <span *ngIf="educationLoanDetails.sundriesone != null">{{educationLoanDetails.sundriesone}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.sundriessec != null">{{educationLoanDetails.sundriessec}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.sundriesthrd != null">{{educationLoanDetails.sundriesthrd}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.sundriesfour != null">{{educationLoanDetails.sundriesfour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.sundriesfive != null">{{educationLoanDetails.sundriesfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Insurance</td>
                            <td>
                                <span *ngIf="educationLoanDetails.insuranceone != null">{{educationLoanDetails.insuranceone}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.insurancetwo != null">{{educationLoanDetails.insurancetwo}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.insurancethrd != null">{{educationLoanDetails.insurancethrd}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.insurancefour != null">{{educationLoanDetails.insurancefour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.insurancefive != null">{{educationLoanDetails.insurancefive}}</span>
                            </td>
                        </tr>

                        <tr>
                            <td>Others</td>
                            <td>
                                <span *ngIf="educationLoanDetails.othersfirst != null">{{educationLoanDetails.othersfirst}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.otherstwo != null">{{educationLoanDetails.otherstwo}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.othersthree != null">{{educationLoanDetails.othersthree}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.othersfour != null">{{educationLoanDetails.othersfour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.othersfive != null">{{educationLoanDetails.othersfive}}</span>
                            </td>
                        </tr>
                    </tbody>

                </table>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th style=" width: 22%;">Sources of Finance</th>
                            <th>1st Year</th>
                            <th>2nd Year</th>
                            <th>3rd Year</th>
                            <th>4th Year</th>
                            <th>5th Year</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Non repayable scholarship/Fellowship/Stipend or any other financial assistance</td>
                            <td>
                                <span *ngIf="educationLoanDetails.scholarship != null">{{educationLoanDetails.scholarship}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.stipend != null">{{educationLoanDetails.stipend}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.fellowship != null">{{educationLoanDetails.fellowship}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.repayable != null">{{educationLoanDetails.repayable}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.assistance != null">{{educationLoanDetails.assistance}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Funds available from family sources</td>
                            <td>
                                <span *ngIf="educationLoanDetails.fundsfirst != null">{{educationLoanDetails.fundsfirst}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.fundssec != null">{{educationLoanDetails.fundssec}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.availablethrd != null">{{educationLoanDetails.availablethrd}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.availablefour != null">{{educationLoanDetails.availablefour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.sourcesfive != null">{{educationLoanDetails.sourcesfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Loan from Bank</td>
                            <td>
                                <span *ngIf="educationLoanDetails.bankfirst != null">{{educationLoanDetails.bankfirst}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.banksecond != null">{{educationLoanDetails.banksecond}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.bankthird != null">{{educationLoanDetails.bankthird}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.bankfourth != null">{{educationLoanDetails.bankfourth}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.bankfifth != null">{{educationLoanDetails.bankfifth}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>
                                <span *ngIf="educationLoanDetails.first != null">{{educationLoanDetails.first}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.second != null">{{educationLoanDetails.second}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.third != null">{{educationLoanDetails.third}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.fourth != null">{{educationLoanDetails.fourth}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.fifth != null">{{educationLoanDetails.fifth}}</span>

                            </td>
                        </tr>

                    </tbody>
                </table>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Explain Utility / scope future prospects of the course
                        : </label>
                        <span *ngIf="educationLoanDetails.credit != null">{{educationLoanDetails.credit}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Expected Total Loan Amount : </label>
                        <span *ngIf="educationLoanDetails.type != null">{{educationLoanDetails.type}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Expected Loan Tenor : </label>
                        <span *ngIf="educationLoanDetails.relationship != null">{{educationLoanDetails.relationship}}</span>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Expected income by completion of course : </label>
                        <span *ngIf="educationLoanDetails.amtadv != null">{{educationLoanDetails.amtadv}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Expected Monthly Expensive : </label>
                        <span *ngIf="educationLoanDetails.facilities != null">{{educationLoanDetails.balance}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Expected EMI : </label>
                        <span *ngIf="educationLoanDetails.managed != null">{{educationLoanDetails.managed}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">REFERENCE</h3>
                <div class="col-md-12 ">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float:left;margin-left: 8%;">REFERENCE 1
                </h5>
                <div class="col-md-12 ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name : </label>
                        <span *ngIf="educationLoanDetails.facilities != null">{{educationLoanDetails.reasons}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address : </label>
                        <span *ngIf="educationLoanDetails.owned != null">{{educationLoanDetails.owned}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Pin Code : </label>
                        <span *ngIf="educationLoanDetails.primary != null">{{educationLoanDetails.primary}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float:left;margin-left: 8%;">REFERENCE 2
                </h5>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name : </label>
                        <span *ngIf="educationLoanDetails.adults != null">{{educationLoanDetails.adults}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Address : </label>
                        <span *ngIf="educationLoanDetails.children != null">{{educationLoanDetails.children}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Pin Code : </label>
                        <span *ngIf="educationLoanDetails.pintotal != null">{{educationLoanDetails.pintotal}}</span>
                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Applicant Name : </label>
                        <span *ngIf="educationLoanDetails.member != null">{{educationLoanDetails.member}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Application Date : </label>
                        <span *ngIf="educationLoanDetails.obligation != null">{{educationLoanDetails.obligation}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Place : </label>
                        <span *ngIf="educationLoanDetails.place != null">{{educationLoanDetails.place}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
            </div>

            <div class="col-md-12 row ">&nbsp;</div>

            <div class="col-md-12">&nbsp;</div>

            <div  class="col-md-12" *ngIf="goldloan">

                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Gold Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'"  class="btn btn-primary" (click)="goldloandetailsclick()">Edit</button>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-3 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel ">TYPE OF LOAN : &nbsp;</label>
                        <span>{{goldType}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row">
                    <div class="col-md-2 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <label id="emaillabel">REQUIRED AMOUNT : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.requiredamount != null">{{goldLoanDetails.requiredamount}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">DETAILS OF CURRENT LOAN AND OVERDRAFTS : &nbsp;                                 <span *ngIf="goldLoanDetails.overdrafts != null">{{goldLoanDetails.overdrafts}}</span>
                        </label>


                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">INTEREST PAYMENT MODE</h4>
                    </div>
                    <label for="Email " id="emaillabel ">Monthly : &nbsp;</label>
                    <div class="col-md-1 row ">
                        <span *ngIf="goldLoanDetails.mon != null">{{goldLoanDetails.mon}}</span>

                    </div>
                    <label for="Email " id="emaillabel ">Quarterly : &nbsp;</label>
                    <div class="col-md-1 row ">
                        <span *ngIf="goldLoanDetails.quar != null">{{goldLoanDetails.quar}}</span>

                    </div>
                    <div class="col-md-2 row ">&nbsp;</div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>

                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">REASON FOR LOAN : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.reasonId != null">{{goldLoanDetails.reasonId}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">MODE OF DISBURSEMENT : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.disbursement != null">{{goldLoanDetails.disbursement}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> NET ANNUAL INCOME : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.goldamt != null">{{goldLoanDetails.goldamt}}</span>


                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">DETAILS OF GOLD ORNAMENTS : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.oranaments != null">{{goldLoanDetails.oranaments}}</span>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> Total : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.goldtotal != null">{{goldLoanDetails.goldtotal}}</span>


                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> Value : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.goldvalue != null">{{goldLoanDetails.goldvalue}}</span>
                    </div>
                </div>

            </div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12  " *ngIf="fixedassetloan">

                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Fixed Asset Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'"  class="btn btn-primary" (click)="fixedassetloandetailsclick()">Edit</button>
                    </div>
                </div>


                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Measurement</h4>


                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">East to West(in ft/Mts): {{fixedAssetLoanDetails.easttowest}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">North to south (in ft/Mts): {{fixedAssetLoanDetails.northtosouth}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">North: {{fixedAssetLoanDetails.north}}</label>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">South: {{fixedAssetLoanDetails.south}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">East: {{fixedAssetLoanDetails.east}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">West: {{fixedAssetLoanDetails.west}}</label>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-9 row ">
                        <label id="emaillabel ">Present value of property as estimated by the
                            applicant(valuation should be realistic): {{fixedAssetLoanDetails.repaying}}</label>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Is the property self-acquired or ancestral: {{fixedAssetLoanDetails.ancestral}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Is the property encumbered; if so, to whom and to what
                            extent: {{fixedAssetLoanDetails.encumbered}}</label>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-9 row ">
                        <label id="emaillabel ">Had the property been mortgaged on previous occasion, if so
                            furnish details: {{fixedAssetLoanDetails.bank}}</label>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Since how long the applicant is in enjoyment of the
                            property: {{fixedAssetLoanDetails.outstanding}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Name(s) of tenants (if any) and monthly
                            rent: {{fixedAssetLoanDetails.value}}</label>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Source of Income (monthly)(for salary earners)
                </h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Applicant: </h4>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Gross salary Rs: {{fixedAssetLoanDetails.purchased}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Deductions Rs: {{fixedAssetLoanDetails.deductions}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Net Salary Rs: {{fixedAssetLoanDetails.salary}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Applicant's Spouse:</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Gross salary Rs: {{fixedAssetLoanDetails.purchased}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Deductions Rs: {{fixedAssetLoanDetails.inclusive}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Net Salary Rs: {{fixedAssetLoanDetails.satory}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">(Indicate only if documentary evidence is produced)</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Income from other Source: {{fixedAssetLoanDetails.incomesource}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Loan Amount: {{fixedAssetLoanDetails.allowance}}</label>
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-9 row ">
                        <label id="emaillabel ">Source of Income (for self employed and others)[furnish full
                            particulars with documentary evidence]: {{fixedAssetLoanDetails.allowance}}</label>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Is the applicant Income Tax / Sales Tax assessee: {{fixedAssetLoanDetails.payment}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Details of other immovable properties: {{fixedAssetLoanDetails.properties}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-9 row ">
                        <label for="Email " id="emaillabel ">Average Monthly expenses of Applicant and his
                            dependents: {{fixedAssetLoanDetails.delivery}}</label>
                    </div>

                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Name and age of near relatives living
                </h4>
                <div class="col-md-12 ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Father Name: {{fixedAssetLoanDetails.applicant}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Father Age: {{fixedAssetLoanDetails.fatherage}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Mother Name: {{fixedAssetLoanDetails.mother}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Mother Age: {{fixedAssetLoanDetails.motherage}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Husband/Wife Name: {{fixedAssetLoanDetails.husband}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Husband/Wife Age: {{fixedAssetLoanDetails.wife}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Sons Name: {{fixedAssetLoanDetails.sons}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Sons Age: {{fixedAssetLoanDetails.sonsage}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Daughters Name: {{fixedAssetLoanDetails.daughters}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Daughters Age: {{fixedAssetLoanDetails.daughterage}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Brothers Name: {{fixedAssetLoanDetails.brothers}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Brothers Age: {{fixedAssetLoanDetails.brotherage}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Sisters Name: {{fixedAssetLoanDetails.sisters}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Sisters Age: {{fixedAssetLoanDetails.sisterage}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Grandsons Name: {{fixedAssetLoanDetails.grandsons}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Grandsons Age: {{fixedAssetLoanDetails.grandsonage}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Brother's son Name: {{fixedAssetLoanDetails.brotherson}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Brother's son Age: {{fixedAssetLoanDetails.brothersonage}}</label>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">If applicant is female Her husband's coparceners &
                            heirs her father's coparceners & heirs: {{fixedAssetLoanDetails.balance}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Relatives who have an interest in the property (in
                            case of H.U.F. property): {{fixedAssetLoanDetails.assessee}}</label>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Name of widows, minors and othe entitled to maintenance from the
                            property: {{fixedAssetLoanDetails.earning}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Details of family members who can supplement the
                            applicant's income for repayment of loan: {{fixedAssetLoanDetails.modelname}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Information Regarding Repayment Of Debts - I</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Debts incurred in connection with
                </h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">a) Construction/Additional loan/Repairs of house Rs: {{fixedAssetLoanDetails.employed}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">House Purchase/Site Purchase Rs.: {{fixedAssetLoanDetails.finishloan}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">1)Date of completion of construction: {{fixedAssetLoanDetails.concern}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">2)Total construction area: {{fixedAssetLoanDetails.located}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">3)Total amount spent on construction Rs.: {{fixedAssetLoanDetails.activity}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b) Ceremonial Expenses such as Marriages, etc. Rs: {{fixedAssetLoanDetails.under}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Medical (Furnish details) Rs: {{fixedAssetLoanDetails.annualincome}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">d) Educational (Furnish details) Rs: {{fixedAssetLoanDetails.borrower}}</label>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Parties to whom debt to be repayed in full and final settlement</h4>
                <div class="col-md-12 ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Name of the party: {{fixedAssetLoanDetails.registration}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Amount: {{fixedAssetLoanDetails.authorised}}</label>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Whether documentary evidence is available to
                            establish borrowings? If so, furnish details: {{fixedAssetLoanDetails.towards}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Whether direct payment can be made to parties concerned: {{fixedAssetLoanDetails.property}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Information Regarding Details of Business - II</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name of the business concern: {{fixedAssetLoanDetails.benefit}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Nature of business: {{fixedAssetLoanDetails.relationship}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Year of establishment: {{fixedAssetLoanDetails.course}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Licenses/registration certificate obtained
                            in: {{fixedAssetLoanDetails.obtained}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Connection with business furnish details: {{fixedAssetLoanDetails.evidence}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Are you an IT Assessee if so: {{fixedAssetLoanDetails.deposite}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Pan No. & Return submitted upto: {{fixedAssetLoanDetails.additional}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Annual turnover for the year: {{fixedAssetLoanDetails.account}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Enclose the B/s - P & I: {{fixedAssetLoanDetails.membership}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Present Banker: {{fixedAssetLoanDetails.numbersb}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">How the loan is proposed to be utilised in: {{fixedAssetLoanDetails.proposed}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Business activity: {{fixedAssetLoanDetails.officedata}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">No. of Months required for repayment of
                            loan: {{fixedAssetLoanDetails.Department}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Take Over Of Liability - III</h4>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name of the Institution where the loan is: {{fixedAssetLoanDetails.income}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Outstanding and amount O/S, EMI Amount: {{fixedAssetLoanDetails.operative}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Mode of payment of loan EMI: {{fixedAssetLoanDetails.board}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Whether Paid up-to date: {{fixedAssetLoanDetails.salary}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Whether the application has any other liability
                            with the present situation: {{fixedAssetLoanDetails.domestic}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Whether NOC is obtained from the present
                            Institution: {{fixedAssetLoanDetails.institution}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Whether authenticated copies of the documents are obtained for
                            submission to legal scrutiny: {{fixedAssetLoanDetails.copies}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Whether direct payment to be made to the: {{fixedAssetLoanDetails.made}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Present Mortgagor: {{fixedAssetLoanDetails.mortgagor}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Higher Education - IV</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">Name of the person and relation for whom the loan is proposed to
                            be utilised and the details of the course of the education: {{fixedAssetLoanDetails.whom}}</label>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-9 row ">
                        <label id="emaillabel ">Total period of education and details of the course Fees, Hostel
                            charges, Capitation Fees etc: {{fixedAssetLoanDetails.fees}}</label>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-9 row ">
                        <label id="emaillabel ">Whether undertaking letter from the student about completion of
                            course and employment particulars is obtained: {{fixedAssetLoanDetails.letter}}</label>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">Whether undertaking letters from the parent about the place of
                            employment of the candidate from time to time is obtained: {{fixedAssetLoanDetails.candidate}}</label>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Information Regarding Details of Construction- V</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel ">Address of the property: {{fixedAssetLoanDetails.proaddress}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Measurement of site</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">East to West: {{fixedAssetLoanDetails.etow}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> North to South: {{fixedAssetLoanDetails.ntos}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Total area(in Sqft.): {{fixedAssetLoanDetails.area}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Existing Construction in Squares: {{fixedAssetLoanDetails.squares}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Proposed Construction: {{fixedAssetLoanDetails.construction}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Total area of construction (Sqt.): {{fixedAssetLoanDetails.totalarea}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Class of construction: {{fixedAssetLoanDetails.classfield}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Cost of construction per square: {{fixedAssetLoanDetails.persquare}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Total estimate of construction: {{fixedAssetLoanDetails.estimate}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Cost of construction in stages</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a. Foundation Rs: {{fixedAssetLoanDetails.foundation}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b. Basement Lintel & Roofing Rs: {{fixedAssetLoanDetails.roofing}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">c. Electrical, sanitary, water supply & plasting: {{fixedAssetLoanDetails.sanitary}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">d. Flooring and final finish: {{fixedAssetLoanDetails.flooring}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Present state of constructions: {{fixedAssetLoanDetails.presentstate}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Amount spent so for on construction:
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">a. On building Rs: {{fixedAssetLoanDetails.building}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">b. Value of stock Rs: {{fixedAssetLoanDetails.stock}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Source of Funds</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a. By savings Rs: {{fixedAssetLoanDetails.savings}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b. By hand loans Rs: {{fixedAssetLoanDetails.hand}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">c. From other financial Institutions Rs: {{fixedAssetLoanDetails.financial}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">d. Refundable provident fund loan Rs: {{fixedAssetLoanDetails.refundable}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">e. Non-refundable provident fund loan Rs: {{fixedAssetLoanDetails.fundpro}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">f. Others if any Rs: {{fixedAssetLoanDetails.others}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">g. By loan from this bank Rs: {{fixedAssetLoanDetails.bankfrom}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">h. Other loan like Credit Card, Loans for consumer durable etc.
                            Rs: {{fixedAssetLoanDetails.durable}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Loans for consumer durable etc Rs: {{fixedAssetLoanDetails.consumer}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Whether you are residing in a rental house, if so, what is the
                            monthly rent paid?: {{fixedAssetLoanDetails.rental}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a. Is the house for self-occupation or for letting out?
                            Rs: {{fixedAssetLoanDetails.house}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b. If for letting out, what is the rent expected? Area of
                            letting out in Sft. Rs: {{fixedAssetLoanDetails.expected}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">c. What is the house advance expected Rs: {{fixedAssetLoanDetails.advance}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel ">If you are unable to complete construction within the estimated
                            value due to rise in cost of building materials, how you propose to complete
                            construction ? Rs: {{fixedAssetLoanDetails.unable}}</label>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">What is the escalation in estimated value of construction is
                            expected ? Rs: {{fixedAssetLoanDetails.escalation}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Accumulation of Provident fund amount & the amount of
                            non-refundable fund, you are entitled for Rs.: {{fixedAssetLoanDetails.accumulation}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Purchasing House/Site </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name of the Present Owner: {{fixedAssetLoanDetails.preowner}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Full address of Property under purchase: {{fixedAssetLoanDetails.purchase}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Area coming under: {{fixedAssetLoanDetails.coming}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Area</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Land: {{fixedAssetLoanDetails.land}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Building: {{fixedAssetLoanDetails.builds}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Squares: {{fixedAssetLoanDetails.squaresed}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Agreed to purchase price Rs: {{fixedAssetLoanDetails.agreed}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Advance paid Rs: {{fixedAssetLoanDetails.paid}}</label>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Whether sale agreement entered into: {{fixedAssetLoanDetails.entered}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Balance proposed to be paid by: {{fixedAssetLoanDetails.proposedata}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Probable date of registration and amount Rs.</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Date: {{fixedAssetLoanDetails.probable}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Rs: {{fixedAssetLoanDetails.amtrs}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Class/School/College/in which children are studying</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name: {{fixedAssetLoanDetails.children}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Class: {{fixedAssetLoanDetails.classdata}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">School/College: {{fixedAssetLoanDetails.schcolg}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Whether owning moped/motorcycle/scooter/car, if yes, Reg.
                            No: {{fixedAssetLoanDetails.moped}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel ">monthly reimbursement of conveyance expenses rent / provision by
                            the employer, availed by the applicant, if any, furnish details: {{fixedAssetLoanDetails.conveyance}}</label>

                    </div>
                </div>

                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Other Income</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a. Rent: {{fixedAssetLoanDetails.rent}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Annual Income Rs: {{fixedAssetLoanDetails.annualin}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Monthly Income: {{fixedAssetLoanDetails.monthly}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b.Agriculture: {{fixedAssetLoanDetails.agriculture}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Annual Income Rs: {{fixedAssetLoanDetails.incant}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Monthly Income: {{fixedAssetLoanDetails.monthinc}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">c. Interest on Investment: {{fixedAssetLoanDetails.investment}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Annual Income Rs: {{fixedAssetLoanDetails.annualcant}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Monthly Income: {{fixedAssetLoanDetails.monthdata}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">d. Any other source of income: {{fixedAssetLoanDetails.ontherdata}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Annual Income Rs: {{fixedAssetLoanDetails.amout}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Monthly Income: {{fixedAssetLoanDetails.incomedata}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Income of other members of the family
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 5%;"> Person 1
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.person}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.personone}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.persondata}}
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 5%;"> Person 2
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.persontwo}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.persontwodta}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.pertwodta}}
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 5%;"> Person 3
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.personthree}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.personthredta}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.personval}}
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Monthly Family maintences Expenses
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a) Rent in respect of the residential premises: {{fixedAssetLoanDetails.respect}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b) Provisions, milk, electricity, water,gas,vegetables and
                            household expenses: {{fixedAssetLoanDetails.household}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">c) Education expenses: {{fixedAssetLoanDetails.education}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">d) Conveyance expenses petrol, insurance, tax: {{fixedAssetLoanDetails.petrol}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">e) Telephone expenses: {{fixedAssetLoanDetails.telephone}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">f) Entertainment expenses: {{fixedAssetLoanDetails.entertainment}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">g) Repayment of loan(E.M.I. for existing Loan): {{fixedAssetLoanDetails.repayment}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Monthly income: {{fixedAssetLoanDetails.monthcome}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Monthly Total Expenditure: {{fixedAssetLoanDetails.expenditure}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Excess of income over Expenditure: {{fixedAssetLoanDetails.over}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Amount of loan applied for: {{fixedAssetLoanDetails.applied}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> E.M.I for the recommended loan: {{fixedAssetLoanDetails.recommended}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Scrutiny Sheet </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Present Repayment commitment p.m: {{fixedAssetLoanDetails.commitment}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Comment about repayment or existing Loan/Previous Loan: {{fixedAssetLoanDetails.about}}</label>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel "> Comments regarding method of Creation of charge like mortgage,
                            lein, Assignment, etc. on the security(for existing loan): {{fixedAssetLoanDetails.regarding}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Applicant employed at: {{fixedAssetLoanDetails.employeee}}</label>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel ">Comments about SB / C A/c operation Transaction, Minimum
                            balance, Return of cheques: {{fixedAssetLoanDetails.cheques}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> Period of service left over: {{fixedAssetLoanDetails.left}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Whether applicant is drawing salary through the bank/arrangement
                            for salary remittance is made: {{fixedAssetLoanDetails.arrangement}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Business particulars in the case of Applicant being a
                            Businessmen: {{fixedAssetLoanDetails.casefield}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">i) Details of financial accounts submitted: {{fixedAssetLoanDetails.details}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">ii) Details of Income proof submitted Income per annum: {{fixedAssetLoanDetails.annum}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Name of Co-obligant(s) & M.No: {{fixedAssetLoanDetails.obligant}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Co-obligant(s) employed at: {{fixedAssetLoanDetails.employedat}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Co-obligant Net Salary: {{fixedAssetLoanDetails.net}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> comment about Co-obligant if any: {{fixedAssetLoanDetails.comment}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-8 row ">
                        <label id="emaillabel ">Whether Co-obligant is drawing salary through the
                            bank/arrangement for salary remittance is made: {{fixedAssetLoanDetails.remittance}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Credit Appraisal Report </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Legal Opinion: {{fixedAssetLoanDetails.legal}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Legal Opinion obtained from: {{fixedAssetLoanDetails.opinion}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Valuation-Report obtained from: {{fixedAssetLoanDetails.report}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Value of site/Value of existing house: {{fixedAssetLoanDetails.existing}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Estimation for Construction / Renovation: {{fixedAssetLoanDetails.renovation}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Eligibility of loan as per loan Rule No: {{fixedAssetLoanDetails.eligibility}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> A.O. / Branch Copy</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Committee Resolution Dated: {{fixedAssetLoanDetails.resolution}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Loan Sanctioned Rs: {{fixedAssetLoanDetails.sanctioned}}</label>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Conditions of sanction: {{fixedAssetLoanDetails.conditions}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Compliance to post sanction / disbursement formalities: {{fixedAssetLoanDetails.formalities}}</label>

                    </div>
                </div>


            </div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">&nbsp;</div>

            <div class="col-md-12 " *ngIf="homeloan">
                <!-- <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Home loan</h2> -->
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Home loan</h2>                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-primary" (click)="homeloandetailsclick()">Edit</button>
                    </div>
                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">FINANCIAL DETAILS</h3>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Applicant : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.applicant != 'null'">{{homeLoanDetails.applicant}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Income : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.income != null">{{homeLoanDetails.income}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Assets : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.assets != null">{{homeLoanDetails.assets}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Liabilities : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.liabilities != null">{{homeLoanDetails.liabilities}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Co-Applicant : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coApplicant != null">{{homeLoanDetails.coApplicant}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Co-Applicant Income : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coIncome != null">{{homeLoanDetails.coIncome}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Co-Applicant Assets : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coAssets != null">{{homeLoanDetails.coAssets}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Co-Applicant Liabilities : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coLiabilities != null">{{homeLoanDetails.coLiabilities}}</span>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">PERSONAL REFERENCE</h3>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name : &nbsp;</label>
                        <span>{{homeLoanDetails.coName}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Relation with applicant : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.corelationApplicant != null">{{homeLoanDetails.corelationApplicant}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Residential Address : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coresidentialadd != null">{{homeLoanDetails.coresidentialadd}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">City : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coCity != null">{{homeLoanDetails.coCity}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">PIN : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coPIN != null">{{homeLoanDetails.coPIN}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Mobile : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coMobile != null">{{homeLoanDetails.coMobile}}</span>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Phone : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coPhone != null">{{homeLoanDetails.coPhone}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Email ID : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coEmail != null">{{homeLoanDetails.coEmail}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <!-- <div class="col-md-3 row ">
                                        <label for="Email " id="emaillabel ">Expected EMI : &nbsp;</label>
                                        <span *ngIf="homeLoanDetails.srcinc != null">{{homeLoanDetails.srcinc}}</span> <input [ngClass]="{ 'is-invalid': submitted && h.managed.errors }" (keyup.enter)="homeloanIn(stepper) " formControlName="managed" class="form-control " type="text " id="inputbutton " style="background-color:#f4f4f452; border: solid 2px #c60c0c; " [(ngModel)]="managed">
                                        <div *ngIf="submitted && h.managed.errors" class="invalid-feedback">
                                            <div style="color: red; " *ngIf="h.managed.errors.required "> Required Field</div>
                                        </div>
                                    </div> -->
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">LOAN REQUIREMENT</h3>
                <div class="col-md-12 ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Home Loan Amount : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.homeloanamount != null">{{homeLoanDetails.homeloanamount}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Tenor of Loan : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.tenor != null">{{homeLoanDetails.tenor}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Purpose : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.purposeloan != null">{{homeLoanDetails.purposeloan}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">DETAILS OF PROPERTY</h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.propertyaddress != null">{{homeLoanDetails.propertyaddress}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Area of Land/Flat : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.propertyarea != null">{{homeLoanDetails.propertyarea}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Present Owner : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.presentowner != null">{{homeLoanDetails.presentowner}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Property Type : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.presentowner == '1'">Freehold</span>
                        <span *ngIf="homeLoanDetails.presentowner == '2'">Lease</span>


                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Ownership Type : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.ownershiptype == '1'">Sole</span>
                        <span *ngIf="homeLoanDetails.ownershiptype == '2'">Joint</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Expected Date of Possession : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.expected != null">{{homeLoanDetails.expected}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Percentage of Work Complete : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.percentage != null">{{homeLoanDetails.percentage}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Proposed Owner's Name : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.proposeowner != null">{{homeLoanDetails.proposeowner}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">REPAYMENT OPTIONS : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.presentowner == '1'">ECS</span>
                        <span *ngIf="homeLoanDetails.presentowner == '2'">Auto Debit</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Applicant Name : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.applicantname != null">{{homeLoanDetails.applicantname}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Application Date : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.appdate != null">{{homeLoanDetails.appdate}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Place : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.applicationplace != null">{{homeLoanDetails.applicationplace}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

            </div>

            
            <div class="col-md-12 " *ngIf="projectloan">

                <!-- <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Project Loan</h2> -->
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Project Loan</h2>                    
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-primary" (click)="projectloandetailsclick()">Edit</button>
                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name Of the Company / Firm:</label>
                        <span>{{projectLoanDetails.companyfirm}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Ploat/Building/Plat Name and Number:</label>
                        <span>{{projectLoanDetails.ploat}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Street Name:</label>
                        <span *ngIf="projectLoanDetails.streetname != null">{{projectLoanDetails.streetname}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">City:</label>
                        <span *ngIf="projectLoanDetails.towname != null">{{projectLoanDetails.towname}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Telephone No:</label>
                        <span *ngIf="projectLoanDetails.telephoneno != null">{{projectLoanDetails.telephoneno}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Mobile No:</label>
                        <span *ngIf="projectLoanDetails.mobile != null">{{projectLoanDetails.mobile}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Fax:</label>
                        <span *ngIf="projectLoanDetails.fax != null">{{projectLoanDetails.fax}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Loan Applied for:</label>
                        <span *ngIf="projectLoanDetails.ancestral != null">{{projectLoanDetails.ancestral}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Purpose of Loan:</label>
                        <span *ngIf="projectLoanDetails.encumbered != null">{{projectLoanDetails.encumbered}}</span>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">When and how the loan will be repaid?:</label>
                        <span *ngIf="projectLoanDetails.loanac  != null">{{projectLoanDetails.loanac }}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Name of the Chief/ Promoter/ Chief Executive:
                        </label>
                        <span *ngIf="projectLoanDetails.outstanding != null">{{projectLoanDetails.outstanding}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Your House:</label>
                        <span *ngIf="projectLoanDetails.bank == 'true'">Owned</span>
                        <span *ngIf="projectLoanDetails.bank == null">Rented</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                    <!-- <div class="col-md-5 row ">
                        <label id="emaillabel ">What is your academic qualification? Is related to your line of
                            trade?:
                        </label>                          
                        <span *ngIf="projectLoanDetails.academicqualification != null">{{projectLoanDetails.academicqualification}}</span>
                    </div> -->
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">What is your academic qualification? Is related to your
                            line of trade?:
                        </label>
                        <span *ngIf="projectLoanDetails.academicqualification != null">{{projectLoanDetails.academicqualification}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Your Account Number? What would be the minimum balance
                            in the account?:</label>
                        <span *ngIf="projectLoanDetails.deductions != null">{{projectLoanDetails.deductions}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Are you assessed the Income-Tax:</label>
                        <!-- <span *ngIf="projectLoanDetails.intax == null">Yes</span> -->
                        <span *ngIf="projectLoanDetails.salary == 'true'">   Yes</span>
                        <span *ngIf="projectLoanDetails.intax == 'true'">   No</span>
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Do you have a life insurance Policy?:</label>
                        <span *ngIf="projectLoanDetails.insur == 'true'">   Yes</span>
                        <span *ngIf="projectLoanDetails.insurpol == 'true'">   No</span>
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Year of commencement of business:</label>
                        <span *ngIf="projectLoanDetails.purchased != null">{{projectLoanDetails.purchased}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Loan Amount:</label>
                        <span *ngIf="projectLoanDetails.inclusive != null">{{projectLoanDetails.inclusive}}</span>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">State the profit / loss and sales turnover for the last 3 financial year</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">First Year:</label>
                        <span *ngIf="projectLoanDetails.incomesource != null">{{projectLoanDetails.incomesource}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Second Year:</label>
                        <span *ngIf="projectLoanDetails.allowance != null">{{projectLoanDetails.allowance}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Three Year:</label>
                        <span *ngIf="projectLoanDetails.thrdyr != null">{{projectLoanDetails.thrdyr}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Tell us about your factory Premises:</label>
                        <span *ngIf="projectLoanDetails.premises == null">Yes</span>
                        <span *ngIf="projectLoanDetails.premises != null">No</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Tell us about your product:</label>
                        <span *ngIf="projectLoanDetails.applicant != null">{{projectLoanDetails.applicant}}</span>

                    </div>
                </div>

                <div class="col-md-12 ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>

                    <div class="col-md-4 row ">
                        <label id="emaillabel ">How do you plan to produce it?:</label>
                        <span *ngIf="projectLoanDetails.produce != null">{{projectLoanDetails.produce}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Does the product require special know how? If so, are you in
                            possession of the know:</label>
                        <span *ngIf="projectLoanDetails.prospecial != null">{{projectLoanDetails.prospecial}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">What are raw materials required?:</label>
                        <span *ngIf="projectLoanDetails.materials != null">{{projectLoanDetails.materials}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Whether your line of activity falls under priority sector?:
                        </label>
                        <span *ngIf="projectLoanDetails.sector == null">{{projectLoanDetails.sector}}</span> &nbsp;&nbsp;&nbsp;
                        <span *ngIf="projectLoanDetails.board == 'true'">No</span>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">How do you plan to produces them? Are they available on credit? If
                            so what are the terms of credit?:</label>
                        <span *ngIf="projectLoanDetails.terms != null">{{projectLoanDetails.terms}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">How do you plan to ensure the quality of your product? Any special
                            steps being taken?:</label>
                        <span *ngIf="projectLoanDetails.special != null">{{projectLoanDetails.special}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">Whether you need skilled labour? Please describe your plants for
                            skilled labour?:</label>
                        <span *ngIf="projectLoanDetails.skilled != null">{{projectLoanDetails.skilled}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">Any special reason to locate your business at the present location?
                            Is there any advantage in
                            procuring the raw materials locally? Is the local market big enough to subscribe the
                            finished
                            product of your firm? Any other advantage?:</label>
                        <span *ngIf="projectLoanDetails.advantage != null">{{projectLoanDetails.advantage}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Manufacturing process in brief:</label>
                        <span *ngIf="projectLoanDetails.manufacturing != null">{{projectLoanDetails.manufacturing}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">What the level of competition for the product? How do
                            you plan to meet the competition?:</label>
                        <span *ngIf="projectLoanDetails.competition != null">{{projectLoanDetails.competition}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Give the total outside liabilities for the last 3 years and your tangible network:</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">PARAMETER:</label>
                        <span *ngIf="projectLoanDetails.parameter != null">{{projectLoanDetails.parameter}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Total outside liabilities:</label>
                        <span *ngIf="projectLoanDetails.outside != null">{{projectLoanDetails.outside}}</span>
                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Tangible net worth(including loans from friends and relatives):
                        </label>
                        <span *ngIf="projectLoanDetails.tangible != null">{{projectLoanDetails.tangible}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">TOL / TNW:</label>
                        <span *ngIf="projectLoanDetails.balance != null">{{projectLoanDetails.balance}}</span>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">What is the quality of your receivables? How many months’ sales do they represent? Can you give an ageing of your receivables?
                </h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">AGE Less than 1 months old:</label>
                        <span *ngIf="projectLoanDetails.modelname != null">{{projectLoanDetails.modelname}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">AGE 1 to 2 months old:</label>
                        <span *ngIf="projectLoanDetails.old != null">{{projectLoanDetails.old}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">AGE 2 to 3 months old:</label>
                        <span *ngIf="projectLoanDetails.employed != null">{{projectLoanDetails.employed}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">INFORMATION REGARDING REPAYMENT OF DEBTS - I</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Debts incurred in connection with
                </h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">AGE More than 3 months old:</label>
                        <span *ngIf="projectLoanDetails.monthold != null">{{projectLoanDetails.monthold}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Total percentage:</label>
                        <span *ngIf="projectLoanDetails.finishloan != null">{{projectLoanDetails.finishloan}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel "> What is the quality of your finished goods inventory? Are they sold
                            against order / as and when
                            produced? Are they kept in stock for long? How many months’ sales do they represent?:
                        </label>
                        <span *ngIf="projectLoanDetails.concern != null">{{projectLoanDetails.concern}}</span>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel "> What will be the time period between procurement of raw materials
                            to realization of sale proceeds?
                            Can you cut short the cycle without increasing your liability?:
                        </label>
                        <span *ngIf="projectLoanDetails.period != null">{{projectLoanDetails.period}}</span>

                    </div>
                </div>


                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Give the details of cost and the means of finance for your project</h4>
                <div class="col-md-12 ">&nbsp;</div>

                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;"> Land :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.person != null">{{projectLoanDetails.person}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.personone != null">{{projectLoanDetails.personone}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.persondata != null">{{projectLoanDetails.persondata}}</span>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">
                    Building:</h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.persontwo != null">{{projectLoanDetails.persontwo}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.persontwodta != null">{{projectLoanDetails.persontwodta}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.pertwodta != null">{{projectLoanDetails.pertwodta}}</span>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">
                    Machinery:</h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.personthree != null">{{projectLoanDetails.personthree}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.personthredta != null">{{projectLoanDetails.personthredta}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.personval != null">{{projectLoanDetails.personval}}</span>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Other Items :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.otheritem != null">{{projectLoanDetails.otheritem}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.otheritemone != null">{{projectLoanDetails.otheritemone}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.otheritemtwo != null">{{projectLoanDetails.otheritemtwo}}</span>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">From own sources:
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.ownsource != null">{{projectLoanDetails.ownsource}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.ownsourceone != null">{{projectLoanDetails.ownsourceone}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>


                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Bank loan :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.bankloans != null">{{projectLoanDetails.bankloans}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.bankloandata != null">{{projectLoanDetails.bankloandata}}</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">
                    Friends and relatives:</h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.friends != null">{{projectLoanDetails.friends}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.friendsrela != null">{{projectLoanDetails.friendsrela}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Others :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.othersdata != null">{{projectLoanDetails.othersdata}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.othersdatee != null">{{projectLoanDetails.othersdatee}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Total :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.totaldata != null">{{projectLoanDetails.totaldata}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.totaltwo != null">{{projectLoanDetails.totaltwo}}</span>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">Has the project been vetted by consultancy cell of the Bank or
                            consultants of repute? Give details:</label>
                        <span *ngIf="projectLoanDetails.registration != null">{{projectLoanDetails.registration}}</span>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label for="Email " id="emaillabel ">What is the repayment period you are looking for the
                            term loan? What will be the annual cash
                            accruals? What will be your liability towards payment of installments and interest on
                            term
                            loan in a year?:</label>
                        <span *ngIf="projectLoanDetails.authorised != null">{{projectLoanDetails.authorised}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">What is the collateral you would be able to offer? GIVE DETAILS</h4>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Item :
                </h5>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.benefit != null">{{projectLoanDetails.benefit}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.relationship != null">{{projectLoanDetails.relationship}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.studying != null">{{projectLoanDetails.studying}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Value :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.course != null">{{projectLoanDetails.course}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.obtained != null">{{projectLoanDetails.obtained}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.evidence != null">{{projectLoanDetails.evidence}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Basis :
                </h5>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.under != null">{{projectLoanDetails.under}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.annualincome != null">{{projectLoanDetails.annualincome}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.borrower != null">{{projectLoanDetails.borrower}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Total :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.deposite != null">{{projectLoanDetails.deposite}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.additional != null">{{projectLoanDetails.additional}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.account != null">{{projectLoanDetails.account}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Anything else you would like to tell about your business:</label>
                        <span *ngIf="projectLoanDetails.membership != null">{{projectLoanDetails.membership}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Please tall us about your future plans:</label>
                        <span *ngIf="projectLoanDetails.futureplan != null">{{projectLoanDetails.futureplan}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">What is the level of sales you are projecting? Briefly describe the
                            basis:</label>
                        <span *ngIf="projectLoanDetails.officedatp != null">{{projectLoanDetails.officedatp}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> What is the level of receivables you are expecting in terms of
                            month’s sale?:</label>
                        <span *ngIf="projectLoanDetails.receivables != null">{{projectLoanDetails.receivables}}</span>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Application Type:</label>
                        <span *ngIf="projectLoanDetails.income == 'true'">New</span>
                        <span *ngIf="projectLoanDetails.operative == 'true'">Update</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Account Holder Type:</label>
                        <span *ngIf="projectLoanDetails.holder != null">{{projectLoanDetails.holder}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">US Reportable:</label>
                        <span *ngIf="projectLoanDetails.reportable != null">{{projectLoanDetails.reportable}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Other Reportable:</label>
                        <span *ngIf="projectLoanDetails.mortgagor != null">{{projectLoanDetails.mortgagor}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Proof of Identity</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Certificate of Incorporation / Formation:</label>
                        <span *ngIf="projectLoanDetails.formation != null">{{projectLoanDetails.formation}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Officially valid document(s) in respect of person authorized to
                            transact:
                        </label>
                        <span *ngIf="projectLoanDetails.transact != null">{{projectLoanDetails.transact}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Resolution of Board / Managing Committee:</label>
                        <span *ngIf="projectLoanDetails.letter != null">{{projectLoanDetails.letter}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Memorandum and article of Association / Partnership /Trust Document:
                        </label>
                        <span *ngIf="projectLoanDetails.proaddress != null">{{projectLoanDetails.proaddress}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>



                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Activity proof (For dole proprietorship only):</label>
                        <span *ngIf="projectLoanDetails.proprietorship != null">{{projectLoanDetails.proprietorship}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Other:</label>
                        <span *ngIf="projectLoanDetails.area != null">{{projectLoanDetails.area}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Proof Of Address (POA)</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel ">Registered Office Address In India(If Applicable) / Place of
                            Business:
                        </label>
                        <span *ngIf="projectLoanDetails.candidate != null">{{projectLoanDetails.candidate}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Address Type:</label>
                        <span *ngIf="projectLoanDetails.squares != null">{{projectLoanDetails.squares}}</span>
                    </div>
                </div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Proof Of Address:</label>
                        <span *ngIf="projectLoanDetails.classfield != null">{{projectLoanDetails.classfield}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Certificate of Incorporation / Formation:</label>
                        <span *ngIf="projectLoanDetails.persquare != null">{{projectLoanDetails.persquare}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Registration Certificate:</label>
                        <span *ngIf="projectLoanDetails.estimate != null">{{projectLoanDetails.estimate}}</span>

                    </div>
                </div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Address Line 1:</label>
                        <span *ngIf="projectLoanDetails.foundation != null">{{projectLoanDetails.foundation}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 2:</label>
                        <span *ngIf="projectLoanDetails.roofing != null">{{projectLoanDetails.roofing}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 3:</label>
                        <span *ngIf="projectLoanDetails.sanitary != null">{{projectLoanDetails.sanitary}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">City:</label>
                        <span *ngIf="projectLoanDetails.flooring != null">{{projectLoanDetails.flooring}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">District:</label>
                        <span *ngIf="projectLoanDetails.presentstate != null">{{projectLoanDetails.presentstate}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">PIN/Post Code:</label>
                        <span *ngIf="projectLoanDetails.presentstate != null">{{projectLoanDetails.presentstate}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Sate / UT Name:</label>
                        <span *ngIf="projectLoanDetails.building != null">{{projectLoanDetails.building}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">State / UT Code:</label>
                        <span *ngIf="projectLoanDetails.statecode != null">{{projectLoanDetails.statecode}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Country Name:</label>
                        <span *ngIf="projectLoanDetails.country != null">{{projectLoanDetails.country}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Country Code:</label>
                        <span *ngIf="projectLoanDetails.stock != null">{{projectLoanDetails.stock}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> (ii)Correspondence / Local Address Details
                </h4>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Proof Of Address:</label>
                        <span *ngIf="projectLoanDetails.refundable != null">{{projectLoanDetails.refundable}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Certificate of Incorporation / Formation:</label>
                        <span *ngIf="projectLoanDetails.fundpro != null">{{projectLoanDetails.fundpro}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Registration Certificate:</label>
                        <span *ngIf="projectLoanDetails.others != null">{{projectLoanDetails.others}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 1:</label>
                        <span *ngIf="projectLoanDetails.bankfrom != null">{{projectLoanDetails.bankfrom}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 2:</label>
                        <span *ngIf="projectLoanDetails.durable != null">{{projectLoanDetails.durable}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 3:</label>
                        <span *ngIf="projectLoanDetails.consumer != null">{{projectLoanDetails.consumer}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">City:</label>
                        <span *ngIf="projectLoanDetails.rental != null">{{projectLoanDetails.rental}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">District:</label>
                        <span *ngIf="projectLoanDetails.house != null">{{projectLoanDetails.house}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">PIN/Post Code:</label>
                        <span *ngIf="projectLoanDetails.expected != null">{{projectLoanDetails.expected}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Sate / UT Name:</label>
                        <span *ngIf="projectLoanDetails.advance != null">{{projectLoanDetails.advance}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">State / UT Code:</label>
                        <span *ngIf="projectLoanDetails.unable != null">{{projectLoanDetails.unable}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Country Name:</label>
                        <span *ngIf="projectLoanDetails.escalation != null">{{projectLoanDetails.escalation}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Country Code.:</label>
                        <span *ngIf="projectLoanDetails.accumulation != null">{{projectLoanDetails.accumulation}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> (iii)Address in the Jurisdiction where Entity is Residence Outside India for Tax Purposes Details</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Same as Current / Permanent / Overseas Address:</label>
                        <span *ngIf="projectLoanDetails.preowner != null">{{projectLoanDetails.preowner}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Same as Correspondence / Local Address Details:</label>
                        <span *ngIf="projectLoanDetails.purchase != null">{{projectLoanDetails.purchase}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Type:</label>
                        <span *ngIf="projectLoanDetails.land != null">{{projectLoanDetails.land}}</span>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Proof Of Address:</label>
                        <span *ngIf="projectLoanDetails.agreed != null">{{projectLoanDetails.agreed}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Certificate of Incorporation / Formation:</label>
                        <span *ngIf="projectLoanDetails.paid != null">{{projectLoanDetails.paid}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Registration Certificate:</label>
                        <span *ngIf="projectLoanDetails.entered != null">{{projectLoanDetails.entered}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Probable date of registration and amount Rp.</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 1:</label>
                        <span *ngIf="projectLoanDetails.probable != null">{{projectLoanDetails.probable}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 2:</label>
                        <span *ngIf="projectLoanDetails.amtrs != null">{{projectLoanDetails.amtrs}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 3:</label>
                        <span *ngIf="projectLoanDetails.proposedatp != null">{{projectLoanDetails.proposedatp}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">City:</label>
                        <span *ngIf="projectLoanDetails.children != null">{{projectLoanDetails.children}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">District:</label>
                        <span *ngIf="projectLoanDetails.districtdata != null">{{projectLoanDetails.districtdata}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">PIN/Post Code:</label>
                        <span *ngIf="projectLoanDetails.schcolg != null">{{projectLoanDetails.schcolg}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Sate / UT Name:</label>
                        <span *ngIf="projectLoanDetails.utname != null">{{projectLoanDetails.utname}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> State / UT Code:</label>
                        <span *ngIf="projectLoanDetails.conveyance != null">{{projectLoanDetails.conveyance}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Country Name:</label>
                        <span *ngIf="projectLoanDetails.conname != null">{{projectLoanDetails.conname}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Country Code:</label>
                        <span *ngIf="projectLoanDetails.concode != null">{{projectLoanDetails.concode}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Related Persons/ Beneficial Owner
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Number of Related Persons:</label>
                        <span *ngIf="projectLoanDetails.rent != null">{{projectLoanDetails.rent}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Number of Beneficial Owners:</label>
                        <span *ngIf="projectLoanDetails.annualin != null">{{projectLoanDetails.annualin}}</span>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-8 row ">
                        <label id="emaillabel ">Tax Residence of India only and not other Country outside India:
                        </label>
                        <span *ngIf="projectLoanDetails.agriculture == 'true'">Yes</span>
                        <span *ngIf="projectLoanDetails.residence == 'true'">No</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Tax Residence of US:</label>
                        <span *ngIf="projectLoanDetails.taxresidenew == 'true'">New</span>
                        <span *ngIf="projectLoanDetails.taxreside == 'true'">Update</span>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">US TIN:</label>
                        <span *ngIf="projectLoanDetails.investment != null">{{projectLoanDetails.investment}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-3 row ">
                        <label id="emaillabel ">A US Person:</label>
                        <span *ngIf="projectLoanDetails.usperson == 'true'">Yes</span>
                        <span *ngIf="projectLoanDetails.uspersondata == 'true'">No</span>
                    </div>


                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">A Specified US Person:</label>
                        <span *ngIf="projectLoanDetails.ontherdata == 'true'">Yes</span>
                        <span *ngIf="projectLoanDetails.specified == 'true'">No</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Tax Resident Outside India other than US:</label>
                        <span *ngIf="projectLoanDetails.required == 'true'">  NO</span>
                        <span *ngIf="projectLoanDetails.required == null">  Yes</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Country Code:</label>
                        <span *ngIf="projectLoanDetails.respect != null">{{projectLoanDetails.respect}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> TIN / Functional Equivalent:</label>
                        <span *ngIf="projectLoanDetails.household != null">{{projectLoanDetails.household}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">A Governmental Entity:</label>
                        <span *ngIf="projectLoanDetails.education == 'true'">Yes</span>
                        <span *ngIf="projectLoanDetails.governmental == 'true'">No</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">An International Organization: :</label>
                        <span *ngIf="projectLoanDetails.entertainment == 'true'">Yes</span>
                        <span *ngIf="projectLoanDetails.international == 'true'">No</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">A Central Bank:</label>
                        <span *ngIf="projectLoanDetails.telephone == 'true'">  Yes</span>
                        <span *ngIf="projectLoanDetails.central == 'true'">   No</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">A Financial Institution:</label>
                        <span *ngIf="projectLoanDetails.petrol == 'true'">  Yes</span>
                        <span *ngIf="projectLoanDetails.petrol == null"> No</span>
                    </div>

                </div>

                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Nature Of Business</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Manufacture:</label>
                        <span *ngIf="projectLoanDetails.repayment != null">{{projectLoanDetails.repayment}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Trade:</label>
                        <span *ngIf="projectLoanDetails.monthcome != null">{{projectLoanDetails.monthcome}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Retailer:</label>
                        <span *ngIf="projectLoanDetails.expenditure != null">{{projectLoanDetails.expenditure}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>


                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Service Provider:</label>
                        <span *ngIf="projectLoanDetails.service   == null">  True</span>
                        <span *ngIf="projectLoanDetails.service   != null">  False</span>
                    </div>

                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Export / Import:</label>
                        <span *ngIf="projectLoanDetails.exImport == null">  True</span>
                        <span *ngIf="projectLoanDetails.exImport != null">  False</span>
                    </div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Other:</label>
                        <span *ngIf="projectLoanDetails.otherprovider  != null">True</span>
                        <span *ngIf="projectLoanDetails.otherprovider != null">False</span>
                    </div>
                </div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Industry Code:</label>
                        <span *ngIf="projectLoanDetails.over != null">{{projectLoanDetails.over}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Other:</label>
                        <span *ngIf="projectLoanDetails.applied != null">{{projectLoanDetails.applied}}</span>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-3 row ">&nbsp;</div>
                    <label id="emaillabel ">Other:</label>
                    <span *ngIf="projectLoanDetails.valuelak != null">  0-5 Lakh</span>
                    <span *ngIf="projectLoanDetails.tenlakh != null">  5-10 Lakh</span>
                    <span *ngIf="projectLoanDetails.twentylakhs != null">  10-25 Lakh</span>
                    <span *ngIf="projectLoanDetails.onecre != null">  25 Lakh - 1 Cr</span>
                    <span *ngIf="projectLoanDetails.onetofive != null">  1 - 5 Cr</span>
                    <span *ngIf="projectLoanDetails.fiftycr != null">  5 - 50Cr</span>
                    <span *ngIf="projectLoanDetails.hundredcr != null">  50 - 100 Cr</span>
                    <span *ngIf="projectLoanDetails.crmore != null">  50 - 100 Cr</span>



                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>



                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Mode Of Operations</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>
                    <label for="Email " id="emaillabel ">1.</label>
                    <span *ngIf="projectLoanDetails.singledata != null">Single</span>
                    <span *ngIf="projectLoanDetails.jointly != null">Jointly</span>
                    <span *ngIf="projectLoanDetails.severally != null">Severally</span>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>
                    <label for="Email " id="emaillabel ">2. As per Board Resolution</label>
                    <!-- <span *ngIf="projectLoanDetails.resolution != null">As per Board Resolution</span> -->
                    <span *ngIf="projectLoanDetails.boardResolution != null">True</span>
                    <div class="col-md-2 row ">&nbsp;</div>
                    <label for="Email " id="emaillabel ">Other</label>
                    <!-- <span *ngIf="projectLoanDetails.resolution != null">As per Board Resolution</span> -->
                    <span *ngIf="projectLoanDetails.boardResoluother != null">True</span>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>
                    <label for="Email " id="emaillabel ">3.</label>
                    <span *ngIf="projectLoanDetails.internet != null">As per Board ResolutionCorporate Internet
                        Banking</span>
                    <span *ngIf="projectLoanDetails.viewing != null">Viewing Rights</span>
                    <span *ngIf="projectLoanDetails.transaction != null">Transaction Rights</span>
                    <span *ngIf="projectLoanDetails.chequebook != null">Cheque Book</span>
                    <span *ngIf="projectLoanDetails.debitcd != null">Business Debit Card</span>


                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>

                    <label for="Email " id="emaillabel ">4.</label>
                    <span *ngIf="projectLoanDetails.swiping != null">POS Facility (card Swiping Machine)</span>
                    <span *ngIf="projectLoanDetails.alerts != null">SMS Alerts</span>
                    <span *ngIf="projectLoanDetails.facilityup != null">Cash Pick up Facility</span>
                    <span *ngIf="projectLoanDetails.collectsta != null">Sate Bank Collect</span>
                </div>
                <div class="col-md-1 row ">

                </div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>
                    <label for="Email " id="emaillabel ">5.</label>
                    <span *ngIf="projectLoanDetails.frequency != null">Statement Frequency</span>
                    <span *ngIf="projectLoanDetails.fremonth != null">Monthly</span>
                    <span *ngIf="projectLoanDetails.quarterlyfre != null">Quarterly</span>
                    <span *ngIf="projectLoanDetails.yearlythalf != null">Half-Yearlyt</span>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Account Variant</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <span *ngIf="projectLoanDetails.normal != null">Normal Current Account (MBA Rs 10000)</span>
                    <span *ngIf="projectLoanDetails.power != null"> &nbsp;&nbsp;Power Gain Current Account (MBA Rs.
                        200000)</span>
                    <span *ngIf="projectLoanDetails.currentpack != null">&nbsp;&nbsp;Power Pack Current Account (MBA Rs
                        500000)</span>
                    <span *ngIf="projectLoanDetails.pOS != null">&nbsp;&nbsp;Power POS Current Account</span>
                    <span *ngIf="projectLoanDetails.jyoti != null">&nbsp;&nbsp;Power Jyoti (MBA Rs 50000)</span>
                    <span *ngIf="projectLoanDetails.uploaded != null">&nbsp;&nbsp;Power Jyoti (Pre Uploaded)(MBA Rs
                        50000)</span>
                    <span *ngIf="projectLoanDetails.surbhi != null">&nbsp;&nbsp;Surbhi Current Account (MBA Rs 10000)</span>
                    <span *ngIf="projectLoanDetails.surbhiother != null">&nbsp;&nbsp;Other</span>


                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Nomination: Applicable only for sale proprietorship
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>
                    <span *ngIf="projectLoanDetails.nomination != null">I / We want to make a nomination in My/Our
                        Account</span>

                    <span *ngIf="projectLoanDetails.accountour != null">I / We do not want to make a nomination in
                        My/Our Account</span>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Nomination Form (DA1)</h4>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">Nomination under Section 45Z of the Banking Regulation Act, 1949 and
                            rule 2(1) of Banking Companies
                            (Nomination) Rule 1985 in the respect of Bank Deposits.I / We :</label>
                        <span *ngIf="projectLoanDetails.commitment != null">{{projectLoanDetails.commitment}}</span>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">nominate the following person to whom in the event of my / our /
                            minor's death amount of Deposit,
                            particulars where are given below, may be returned by State Bank of India :</label>
                        <span *ngIf="projectLoanDetails.about != null">{{projectLoanDetails.about}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> I/we want the name of the nominee to be printed on the Passbook:
                        </label>
                        <span *ngIf="projectLoanDetails.cheques != null">{{projectLoanDetails.cheques}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Deposit:</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Type of Deposit:</label>
                        <span *ngIf="projectLoanDetails.arrangement != null">{{projectLoanDetails.arrangement}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Account Number:</label>
                        <span *ngIf="projectLoanDetails.casefield != null">{{projectLoanDetails.casefield}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Nominee:</h4>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name:</label>
                        <span *ngIf="projectLoanDetails.details != null">{{projectLoanDetails.details}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Relationship with Depositor:</label>
                        <span *ngIf="projectLoanDetails.annum != null">{{projectLoanDetails.annum}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Age:</label>
                        <span *ngIf="projectLoanDetails.obligant != null">{{projectLoanDetails.obligant}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">DOB:</label>
                        <span *ngIf="projectLoanDetails.employedat != null">{{projectLoanDetails.employedat}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line One :</label>
                        <span *ngIf="projectLoanDetails.net != null">{{projectLoanDetails.net}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line Two :</label>
                        <span *ngIf="projectLoanDetails.comment != null">{{projectLoanDetails.comment}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line Three :</label>
                        <span *ngIf="projectLoanDetails.remittance != null">{{projectLoanDetails.remittance}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> CREDIT APPRAISAL REPORT</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> City:</label>
                        <span *ngIf="projectLoanDetails.legal != null">{{projectLoanDetails.legal}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> PIN Code:</label>
                        <span *ngIf="projectLoanDetails.opinion != null">{{projectLoanDetails.opinion}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Sate:</label>
                        <span *ngIf="projectLoanDetails.report != null">{{projectLoanDetails.report}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> As the nominee is minor on this date, I/ we Appoint Shri/Smt:
                        </label>
                        <span *ngIf="projectLoanDetails.existing != null">{{projectLoanDetails.existing}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Age:</label>
                        <span *ngIf="projectLoanDetails.renovation != null">{{projectLoanDetails.renovation}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Years:</label>
                        <span *ngIf="projectLoanDetails.eligibility != null">{{projectLoanDetails.eligibility}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>




                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Reference 1</h4>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Name:
                        </label>
                        <span *ngIf="projectLoanDetails.referenceoneName != null">{{projectLoanDetails.referenceoneName}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Relationship with Depositor:</label>
                        <span *ngIf="projectLoanDetails.referenceoneRelationship != null">{{projectLoanDetails.referenceoneRelationship}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Address Line One:</label>
                        <span *ngIf="projectLoanDetails.referenceoneaddone != null">{{projectLoanDetails.referenceoneaddone}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Address Line Two :
                        </label>
                        <span *ngIf="projectLoanDetails.referenceoneaddtwo != null">{{projectLoanDetails.referenceoneaddtwo}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">City:</label>
                        <span *ngIf="projectLoanDetails.referenceonecity != null">{{projectLoanDetails.referenceonecity}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">PIN:</label>
                        <span *ngIf="projectLoanDetails.referenceonepin != null">{{projectLoanDetails.referenceonepin}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Mobile :
                        </label>
                        <span *ngIf="projectLoanDetails.referenceonemobile != null">{{projectLoanDetails.referenceonemobile}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Phone:</label>
                        <span *ngIf="projectLoanDetails.referenceonephone != null">{{projectLoanDetails.referenceonephone}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Email ID:</label>
                        <span *ngIf="projectLoanDetails.referenceoneemail != null">{{projectLoanDetails.referenceoneemail}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>



                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Reference 2</h4>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Name:
                        </label>
                        <span *ngIf="projectLoanDetails.referencetwoName != null">{{projectLoanDetails.referencetwoName}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Relationship with Depositor:</label>
                        <span *ngIf="projectLoanDetails.referencetwoRelationship != null">{{projectLoanDetails.referencetwoRelationship}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Address Line One:</label>
                        <span *ngIf="projectLoanDetails.referencetwoaddone != null">{{projectLoanDetails.referencetwoaddone}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Address Line Two :
                        </label>
                        <span *ngIf="projectLoanDetails.referencetwoaddtwo != null">{{projectLoanDetails.referencetwoaddtwo}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">City:</label>
                        <span *ngIf="projectLoanDetails.referencetwocity != null">{{projectLoanDetails.referencetwocity}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">PIN:</label>
                        <span *ngIf="projectLoanDetails.referencetwopin != null">{{projectLoanDetails.referencetwopin}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Mobile :
                        </label>
                        <span *ngIf="projectLoanDetails.referencetwomobile != null">{{projectLoanDetails.referencetwomobile}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Phone:</label>
                        <span *ngIf="projectLoanDetails.referencetwophone != null">{{projectLoanDetails.referencetwophone}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Email ID:</label>
                        <span *ngIf="projectLoanDetails.referencetwoemail != null">{{projectLoanDetails.referencetwoemail}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Preferred Delivery mode :
                        </label>
                        <span *ngIf="projectLoanDetails.preferredDeliverymode != null">{{projectLoanDetails.preferredDeliverymode}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Preferred Mailing Address:</label>
                        <span *ngIf="projectLoanDetails.preferredMailingAddress != null">{{projectLoanDetails.preferredMailingAddress}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


            </div>

            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">&nbsp;</div>

            <div class="col-md-12 " *ngIf="smallscaleloan">
                <!-- <h2 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Small Scale Loan</h2> -->
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Small Scale Loan</h2>                     </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'" class="btn btn-primary" (click)="smallscaleloandetailsclick()">Edit</button>
                    </div>
                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">
                    <div class="col-md-12 ">&nbsp;</div>
                    <h3 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Credit facilities required</h3>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Working Capital : </label>
                            <span *ngIf="SmallScaleDetails.facilities != null">{{SmallScaleDetails.facilities}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Equipment/Machinery finance : </label>
                            <!-- <span *ngIf="SmallScaleDetails.finance == null">New work</span> -->
                            <span *ngIf="SmallScaleDetails.finance != null">{{SmallScaleDetails.finance}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Name of the Concern/Firm : </label>
                            <span *ngIf="SmallScaleDetails.concern != null">{{SmallScaleDetails.concern}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Business Address and Tel. Nos : </label>
                            <span *ngIf="SmallScaleDetails.business != null">{{SmallScaleDetails.business}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Registration No. & Dates : </label>
                            <span *ngIf="SmallScaleDetails.register != null">{{SmallScaleDetails.register}}</span>


                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Registration Authority : </label>
                            <span *ngIf="SmallScaleDetails.authority != null">{{SmallScaleDetails.authority}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Nature of activity : </label>
                            <span *ngIf="SmallScaleDetails.activity != null">{{SmallScaleDetails.activity}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Date of establishment : </label>
                            <span *ngIf="SmallScaleDetails.establishment != null">{{SmallScaleDetails.establishment}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">In case of Proprietary Concern : </label>
                            <span *ngIf="SmallScaleDetails.proprietary != null">{{SmallScaleDetails.proprietary}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Whether basic books of accounts maintained : </label>
                            <span *ngIf="SmallScaleDetails.maintained != null">{{SmallScaleDetails.maintained}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">a) Father's/Husband's Name : </label>
                            <span *ngIf="SmallScaleDetails.purchased != null">{{SmallScaleDetails.purchased}}</span>


                        </div>
                    </div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">b) Whether applicant belongs to : </label>
                            <span *ngIf="SmallScaleDetails.belongs != null">{{SmallScaleDetails.belongs}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Furnish PAN No. : </label>
                            <span *ngIf="SmallScaleDetails.furnish != null">{{SmallScaleDetails.furnish}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel "> TIN No. : </label>
                            <span *ngIf="SmallScaleDetails.tinno != null">{{SmallScaleDetails.tinno}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">VAT No. : </label>
                            <span *ngIf="SmallScaleDetails.vatno != null">{{SmallScaleDetails.vatno}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Registered with any other competent authority to
                    run the business (give details) : </label>
                            <span *ngIf="SmallScaleDetails.competent != null">{{SmallScaleDetails.competent}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Whether related to any of the Board of Directors of the bank are
                    associated with the firm : </label>
                            <span *ngIf="SmallScaleDetails.related != null">{{SmallScaleDetails.related}}</span>


                        </div>
                    </div>
                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">


                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Name of associate concern : </label>
                            <span *ngIf="SmallScaleDetails.associate != null">{{SmallScaleDetails.associate}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">a) Name of present Banker : </label>
                            <span *ngIf="SmallScaleDetails.present != null">{{SmallScaleDetails.present}}</span>

                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">b) Details of Credit Facilities availed : </label>
                            <span *ngIf="SmallScaleDetails.credit != null">{{SmallScaleDetails.credit}}</span>


                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Type : </label>
                            <span *ngIf="SmallScaleDetails.type != null">{{SmallScaleDetails.type}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Amt. Adv. : </label>
                            <span *ngIf="SmallScaleDetails.amtadv != null">{{SmallScaleDetails.amtadv}}</span>



                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Bal. O/s : </label>
                            <span *ngIf="SmallScaleDetails.balance != null">{{SmallScaleDetails.balance}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">c) If no credit facilities availed so for, how the
                    funds are managed : </label>
                            <span *ngIf="SmallScaleDetails.managed != null">{{SmallScaleDetails.managed}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">d) Reasons for shifting over to NCB : </label>
                            <span *ngIf="SmallScaleDetails.reasons != null">{{SmallScaleDetails.reasons}}</span>



                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Details of assets owned and its present value : </label>
                            <span *ngIf="SmallScaleDetails.owned != null">{{SmallScaleDetails.owned}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Net Worth : </label>
                            <span *ngIf="SmallScaleDetails.worth != null">{{SmallScaleDetails.worth}}</span>



                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Details of security offered Primary security : </label>
                            <span *ngIf="SmallScaleDetails.primary != null">{{SmallScaleDetails.primary}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Details of additional security if any (with present value) : </label>
                            <span *ngIf="SmallScaleDetails.additional != null">{{SmallScaleDetails.additional}}</span>



                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Whether applicant is technically qualified / experienced ? if so furnish details : </label>
                            <span *ngIf="SmallScaleDetails.technically != null">{{SmallScaleDetails.technically}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Size of applicant's family</h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Adults : </label>
                            <span *ngIf="SmallScaleDetails.adults != null">{{SmallScaleDetails.adults}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Children : </label>
                            <span *ngIf="SmallScaleDetails.children != null">{{SmallScaleDetails.children}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Total : </label>
                            <span *ngIf="SmallScaleDetails.sstotal != null">{{SmallScaleDetails.sstotal}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Whether member of any other Co-operative Bank : </label>
                            <span *ngIf="SmallScaleDetails.member != null">{{SmallScaleDetails.member}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Name and Membership Nos. of Co-obligation /Surety/Guarantee with Occupation : </label>
                            <span *ngIf="SmallScaleDetails.obligation != null">{{SmallScaleDetails.obligation}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Name : </label>
                            <span *ngIf="SmallScaleDetails.ssname != null">{{SmallScaleDetails.ssname}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Age : </label>
                            <span *ngIf="SmallScaleDetails.ssage != null">{{SmallScaleDetails.ssage}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Residential Address : </label>
                            <span *ngIf="SmallScaleDetails.residentialadd != null">{{SmallScaleDetails.residentialadd}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Loan AMount : </label>
                            <span *ngIf="SmallScaleDetails.scaleloan != null">{{SmallScaleDetails.scaleloan}}</span>

                        </div>
                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Details of LPG Gas</h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">a) Consumer No. : </label>
                        <span *ngIf="SmallScaleDetails.consumer != null">{{SmallScaleDetails.consumer}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">b) In the name of : </label>
                        <span *ngIf="SmallScaleDetails.relationship != null">{{SmallScaleDetails.relationship}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
            </div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 " *ngIf="suretyloan">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Surety Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'"  class="btn btn-primary"  (click)="suretyloanclick()">Edit</button>
                    </div>
                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Purpose of loan: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.purofloan != null">{{suretyLoanDetails.purofloan}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Loan Amount: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.loama != null">{{suretyLoanDetails.loama}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Document produced in support of purpose of
                                loan: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.support != null">{{suretyLoanDetails.support}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Details of immovable property: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.furnish != null">{{suretyLoanDetails.furnish}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">No. of Dependencies: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.dependencies != null">{{suretyLoanDetails.dependencies}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Average monthly expenses of applicant and his
                                dependents: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.dependents != null">{{suretyLoanDetails.dependents}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">What is the repaying capacity of the applicant per month:
                                &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.repaying != null">{{suretyLoanDetails.repaying}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Details of Life Insurance policies held by the applicant:
                                &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.policies != null">{{suretyLoanDetails.policies}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Details of family members who can supplement the applicant's
                                income for repayment of loans: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.loaamt != null">{{suretyLoanDetails.loaamt}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Whether salary of the applicant is being remitted to The
                                National Co-op. Bank Ltd.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.bank != null">{{suretyLoanDetails.bank}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Designation of salary disbursing officer:
                                &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.outstanding != null">{{suretyLoanDetails.outstanding}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Loan Amount: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.value != null">{{suretyLoanDetails.value}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Income of applicant(for salary earners)
                </h3>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Basic pay Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.purchased != null">{{suretyLoanDetails.purchased}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">House rent allowance Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.inclusive != null">{{suretyLoanDetails.inclusive}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">City compensatory allowance Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.compensatory != null">{{suretyLoanDetails.compensatory}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Other allowance Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.allowance != null">{{suretyLoanDetails.allowance}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Deductions</h3>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Income tax Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.payment != null">{{suretyLoanDetails.payment}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Insurance premium Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.delivery != null">{{suretyLoanDetails.delivery}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">H.R.A. Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.hba != null">{{suretyLoanDetails.hba}}</span>

                    </div>
                </div>


                <div class="col-md-12 ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Other deductions Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.applicant != null">{{suretyLoanDetails.applicant}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Other than Salary Earners(fill up the following columns)</h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Furnish full particulars and address where the business/profession
                                is carried out: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.particulars != null">{{suretyLoanDetails.particulars}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Nature of business/profession and when
                                established: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.partnersloan != null">{{suretyLoanDetails.partnersloan}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Annual Turnover: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.turnover != null">{{suretyLoanDetails.turnover}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Annual Income: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.cashloan != null">{{suretyLoanDetails.cashloan}}</span>

                    </div>
                </div>


                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">IT No./PAN No.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.acnumber != null">{{suretyLoanDetails.acnumber}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Documentary evidence produced in support of nature
                                of business: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.balance != null">{{suretyLoanDetails.balance}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Is the applicant income tax/sales tax
                                assessee: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.assessee != null">{{suretyLoanDetails.assessee}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">In case of applicant is a non-earning member state how repayment
                                will be made: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.earning != null">{{suretyLoanDetails.earning}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Other sources of income, if any, furnish
                                details: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.modelname != null">{{suretyLoanDetails.modelname}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Other informations, if any: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.remarks != null">{{suretyLoanDetails.remarks}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> For Salaried Person</h3>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a. Employee Name: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.employed != null">{{suretyLoanDetails.employed}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b. Period of service completed: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.finishloan != null">{{suretyLoanDetails.finishloan}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> For Business Person</h3>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a. Name of concern: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.concern != null">{{suretyLoanDetails.concern}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">b. Address where business is located: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.located != null">{{suretyLoanDetails.located}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">c. Nature of Activity: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.activity != null">{{suretyLoanDetails.activity}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">d. if assessed under: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.under != null">{{suretyLoanDetails.under}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">e. ANNUAL INCOME: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.annualincome != null">{{suretyLoanDetails.annualincome}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Relationship with borrower: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.borrower != null">{{suretyLoanDetails.borrower}}</span>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Do you have any immovable property : If so, furnish full
                                particulars: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.property != null">{{suretyLoanDetails.property}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Assets with bank: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.assetsdata != null">{{suretyLoanDetails.assetsdata}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Whether drawing salary through The National Co-op.
                                Bank: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.drawing != null">{{suretyLoanDetails.drawing}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Additional Information (Education)
                </h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name of the person for whose benefit Loan is being
                                availed: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.benefit != null">{{suretyLoanDetails.benefit}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Relationship: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.relationship != null">{{suretyLoanDetails.relationship}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Name of School /College in which the children are
                                studying: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.studying != null">{{suretyLoanDetails.studying}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Particulars of course i.e. Degree Course/Engineering/Medical
                                etc.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.course != null">{{suretyLoanDetails.course}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Is the loan proposed to be utilised for payment of
                                donation (Furnish full particulars): &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.modelname != null">{{suretyLoanDetails.modelname}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <!-- <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">List of documentary evidence produced in support of
                                the purpose: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.evidence != null">{{suretyLoanDetails.evidence}}</span>

                    </div> -->
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Full particulars of the deposits held with the bank: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.deposits != null">{{suretyLoanDetails.deposits}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Additional information if any: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.additional != null">{{suretyLoanDetails.additional}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Loan Account Number: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.account != null">{{suretyLoanDetails.account}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <!-- <div class="col-md-3 row ">
                        <label id="emaillabel ">Membership Number: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.membership != null">{{suretyLoanDetails.membership}}</span>

                    </div> -->

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">SB Account Number: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.numbersb != null">{{suretyLoanDetails.numbersb}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Age: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.age != null">{{suretyLoanDetails.age}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Office Address: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.Officedata != null">{{suretyLoanDetails.Officedata}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Department: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.department != null">{{suretyLoanDetails.department}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">GST Number: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.salesdata != null">{{suretyLoanDetails.salesdata}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Annual Income: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.income != null">{{suretyLoanDetails.income}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Whether member of any other Co-operative Bank. If
                                so,particulars & liabilities, if any: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.operative != null">{{suretyLoanDetails.operative}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Whether related to any of the Board of
                                Directors: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.board != null">{{suretyLoanDetails.board}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Salary Particulars of the month of: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.salary != null">{{suretyLoanDetails.salary}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Applicant's and his/her family monthly Domestic
                                Expenses: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.domestic != null">{{suretyLoanDetails.domestic}}</span>

                    </div>
                </div>
                <br>
                <div class="col-md-12 row ">&nbsp;</div>
            </div>

          

            <div class="col-md-12 " *ngIf="vehicleloan">

                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Vehicle Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button  *ngIf="roleid!='2'" class="btn btn-primary" (click)="vehicleLoanclick()">Edit</button>
                    </div>
                </div>



                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 " *ngIf="vehicleLoanDetails.vehicaltype == 1">
                    <div class="col-md-12 ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">New Vehicle</h3>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">If applicant has no source of income ,state how the loan
                                will be repaid:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.repaid == null">New work</span> -->
                            <span *ngIf="vehicleLoanDetails.repaid != null">{{vehicleLoanDetails.repaid}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Monthly Instalment towards other loans with the
                                bank Rs:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.instalment == null">New work</span> -->
                            <span *ngIf="vehicleLoanDetails.instalment != null">{{vehicleLoanDetails.instalment}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel " style="text-align: initial;">Do you have driving licence? Is so enclose
                                copy;If not, who will drive the vehicle:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.driving == null">New work</span> -->
                            <span *ngIf="vehicleLoanDetails.driving != null">{{vehicleLoanDetails.driving}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Furnish particulars of additional security:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.furnish == null">Furnish 123</span> -->
                            <span *ngIf="vehicleLoanDetails.furnish != null">{{vehicleLoanDetails.furnish}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Residential Address:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.residential == null"> ABC Residential 123</span> -->
                            <span *ngIf="vehicleLoanDetails.residential != null">{{vehicleLoanDetails.residential}}</span>


                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Partner / Proprietor / Directors Amount Share
                                in Rs.</label>
                            <!-- <span *ngIf="vehicleLoanDetails.directors == null"> directors 33</span> -->
                            <span *ngIf="vehicleLoanDetails.directors != null">{{vehicleLoanDetails.directors}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Loan Amount :</label>
                            <!-- <span *ngIf="vehicleLoanDetails.loaamt == null"> 880000</span> -->
                            <span *ngIf="vehicleLoanDetails.loaamt != null">{{vehicleLoanDetails.loaamt}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Whether you have availed any other type of loan ? If so furnish details</h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">loan A/c Number:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.loanac == null"> 54245615156</span> -->
                            <span *ngIf="vehicleLoanDetails.loanac != null">{{vehicleLoanDetails.loanac}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Balance Outstanding:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.outstanding == null"> 54245615156</span> -->
                            <span *ngIf="vehicleLoanDetails.outstanding != null">{{vehicleLoanDetails.outstanding}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel " style="text-align: initial;">Do you belong to SC/ST/BC Sick Muslim Christian Zoroastrian
                                Buddist Othrs:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.christian == null"> YES</span> -->
                            <span *ngIf="vehicleLoanDetails.christian != null">{{vehicleLoanDetails.christian}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Type of vehicle to be purchased/make:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.purchased == null"> NEW</span> -->
                            <span *ngIf="vehicleLoanDetails.purchased != null">{{vehicleLoanDetails.purchased}}</span>


                        </div>
                    </div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Cost of vehicle inclusive of KST/CST:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.inclusive == null"> 85000</span> -->
                            <span *ngIf="vehicleLoanDetails.inclusive != null">{{vehicleLoanDetails.inclusive}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Whether the vehicle is Passenger/Goods
                                carrier:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.passenger == null"> Passengers</span> -->
                            <span *ngIf="vehicleLoanDetails.passenger != null">{{vehicleLoanDetails.passenger}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel "> For what purpose the vehicle is used:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.vehicleuse == null"> Ride</span> -->
                            <span *ngIf="vehicleLoanDetails.vehicleuse != null">{{vehicleLoanDetails.vehicleuse}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel " style="text-align: initial;">Name and address of the authorised dealer
                                through whom vehicle is being purchased:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.authorised == null"> Rajeshwari</span> -->
                            <span *ngIf="vehicleLoanDetails.authorised != null">{{vehicleLoanDetails.authorised}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">In whose favour payment is to be made:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.payment == null"> 50000</span> -->
                            <span *ngIf="vehicleLoanDetails.payment != null">{{vehicleLoanDetails.payment}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel " style="text-align: initial;">Time likely to be taken for delivery of vehicle
                                place of delivery:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.delivery == null"> 2days</span> -->
                            <span *ngIf="vehicleLoanDetails.delivery != null">{{vehicleLoanDetails.delivery}}</span>


                        </div>
                    </div>
                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 " *ngIf="vehicleLoanDetails.vehicaltype == 2">
                    <div class="col-md-12 ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Second Hand Vehicle</h3>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">If applicant has no source of income ,state how the loan
                                will be repaid:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.applicant == null"> Work</span> -->
                            <span *ngIf="vehicleLoanDetails.applicant != null">{{vehicleLoanDetails.applicant}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Monthly Instalment towards other loans with the
                                bank Rs:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.towards == null"> 1200</span> -->
                            <span *ngIf="vehicleLoanDetails.towards != null">{{vehicleLoanDetails.towards}}</span>

                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel " style="text-align: initial;">Do you have driving licence? Is so enclose
                                copy;If not,who will drive the vehicle:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.enclose == null"> OK</span> -->
                            <span *ngIf="vehicleLoanDetails.enclose != null">{{vehicleLoanDetails.enclose}}</span>


                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Furnish particulars of additional security:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.particulars == null"> OK</span> -->
                            <span *ngIf="vehicleLoanDetails.particulars != null">{{vehicleLoanDetails.particulars}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Residential Address of Partners / Proprietor /
                                Directors:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.residential == null"> OK</span> -->
                            <span *ngIf="vehicleLoanDetails.residential != null">{{vehicleLoanDetails.residential}}</span>



                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Partner / Proprietor / Directors Amount Share
                                in Rs:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.share == null"> 30000</span> -->
                            <span *ngIf="vehicleLoanDetails.share != null">{{vehicleLoanDetails.share}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Loan Amount:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.loaamt == null"> 30000</span> -->
                            <span *ngIf="vehicleLoanDetails.loaamt != null">{{vehicleLoanDetails.loaamt}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Whether you have availed any other type of loan ? If so furnish details</h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Loan A/c Number:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.loanac == null"> 30000</span> -->
                            <span *ngIf="vehicleLoanDetails.loanac != null">{{vehicleLoanDetails.loanac}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Balance Outstanding:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.outstanding == null"> 50000</span> -->
                            <span *ngIf="vehicleLoanDetails.outstanding != null">{{vehicleLoanDetails.outstanding}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel " style="text-align: initial;">Name of the present owner:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.owner == null"> Budur</span> -->
                            <span *ngIf="vehicleLoanDetails.owner != null">{{vehicleLoanDetails.owner}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Vehicle Registration No:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.registration == null"> KA 22 HG 2020</span> -->
                            <span *ngIf="vehicleLoanDetails.registration != null">{{vehicleLoanDetails.registration}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Model and type of vehicle:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.modelname == null"> Honda</span> -->
                            <span *ngIf="vehicleLoanDetails.modelname != null">{{vehicleLoanDetails.modelname}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Agreed Sale Price:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.agreed == null"> Honda</span> -->
                            <span *ngIf="vehicleLoanDetails.agreed != null">{{vehicleLoanDetails.agreed}}</span>

                        </div>
                    </div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Do you want any other vehicle? so furnish details:</label>
                            <!-- <span *ngIf="vehicleLoanDetails.furnish == null"> Honda</span> -->
                            <span *ngIf="vehicleLoanDetails.furnish != null">{{vehicleLoanDetails.furnish}}</span>

                        </div>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

            </div>
       
            <!-- added -->
            <div class="col-md-12 " *ngIf="fixeddepositloan">


                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Fixed Deposit Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'" class="btn btn-primary" (click)="fixeddepositLoanclick()">Edit</button>
                    </div>
                </div>


                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row">
                    <div class="col-md-1 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <label id="emaillabel">Applicant Name:</label>
                        <span *ngIf="fixedDeposit.applicantname != null">{{fixedDeposit.applicantname}}</span>

                    </div>
                    <div class="col-md-1 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <label id="emaillabel">Joint Applicant 1:</label>
                        <span *ngIf="fixedDeposit.jointApplicantone != null">{{fixedDeposit.jointApplicantone}}</span>

                    </div>
                    <div class="col-md-1 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <label id="emaillabel">Joint Applicant 2:</label>
                        <span *ngIf="fixedDeposit.jointApplicanttwo != null">{{fixedDeposit.jointApplicanttwo}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Mobile Number:</label>
                        <span *ngIf="fixedDeposit.jointNumber != null">{{fixedDeposit.jointNumber}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Type of Deposit:</label>
                        <span *ngIf="fixedDeposit.selcetedfdtypeString != null">{{fixedDeposit.selcetedfdtypeString}}</span>

                        
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Interest payout:</label>
                        <span *ngIf="fixedDeposit.selectedInterestpayoutString != null">{{fixedDeposit.selectedInterestpayoutString}}</span>

                        
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Deposit amount:</label>
                        <span *ngIf="fixedDeposit.depositamount != null">{{fixedDeposit.depositamount}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Period of Deposit:</label>
                        <span *ngIf="fixedDeposit.periodofDeposit != null">{{fixedDeposit.periodofDeposit}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <!-- <div class="col-md-3 row ">
                        <label id="emaillabel ">Return on investment (ROI):</label>
                        <span *ngIf="fixedDeposit.roi != null">{{fixedDeposit.roi}}</span>

                    </div> -->
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">PAN:</label>
                        <span *ngIf="fixedDeposit.depositpan != null">{{fixedDeposit.depositpan}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Payment Details:</label>
                        <span *ngIf="fixedDeposit.paymentDetails != null">{{fixedDeposit.paymentDetails}}</span>

                    </div>


                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Maturity Instruction:</label>
                        <span *ngIf="fixedDeposit.selectedMaturityInstructionString != null">{{fixedDeposit.selectedMaturityInstructionString}}</span>

                        
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                 <div class="col-md-12">&nbsp;</div>
            </div>


            <!-- STEP-6 Uploaded DOCUMENTS -->
            <div id="downloadid">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Uploaded Certificates</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'" class="btn btn-primary" (click)="certificatesclick()">Edit</button>
                    </div>
                </div>
    
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">
    
                    <div class="col-md-12 ">
                        <div *ngIf="certificatesexist">
                            <div class="col-md-12 row">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>
    
                            <div class="col-md-12 row">&nbsp;</div>
                            <div class="col-md-12 row">
                                <div class="col-md-1 row">&nbsp;</div>
    
                                <div class="col-md-2 row" *ngFor="let item of CertificateDisplay">
                                    <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'image/jpeg' ||
                             item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                        <img [src]="item.certificate" height="100" width="100" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
    
                                            </div>
    
                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/pdf' ||
                             item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                        <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
    
                                            </div>
    
                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                             item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
    
                                            </div>
    
                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                             item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
    
                                            </div>
    
                                        </div>
                                    </div>
    
    
                                </div>
                            </div>
                            <div>
                                <div class="col-md-12 row">&nbsp;</div>
                                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Uploaded Documents</h4>
    
                                <div class="col-md-12 row">
                                    <div class="col-md-1 row">&nbsp;</div>
    
                                    <div class="col-md-2 row" *ngFor="let item of certificates">
                                        <div *ngIf="item.actualCertificateName!=null  && (item.documentType == 'image/jpeg' ||
                             item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                            <img [src]="item.certificate" height="100" width="100" />
                                            <div class="col-md-12 row">
                                                <div class="col-md-2 row">&nbsp;</div>
                                                <div class="col-md-10 row">
                                                    <p style="text-align: center;">{{item.fileName}}</p>
    
                                                </div>
    
                                            </div>
                                        </div>
                                        <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/pdf' ||
                             item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                            <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                            <div class="col-md-12 row">
                                                <div class="col-md-2 row">&nbsp;</div>
                                                <div class="col-md-10 row">
                                                    <p style="text-align: center;">{{item.fileName}}</p>
    
                                                </div>
    
                                            </div>
                                        </div>
                                        <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                             item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                            <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                            <div class="col-md-12 row">
                                                <div class="col-md-2 row">&nbsp;</div>
                                                <div class="col-md-10 row">
                                                    <p style="text-align: center;">{{item.fileName}}</p>
    
                                                </div>
    
                                            </div>
                                        </div>
                                        <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                             item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                            <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                            <div class="col-md-12 row">
                                                <div class="col-md-2 row">&nbsp;</div>
                                                <div class="col-md-10 row">
                                                    <p style="text-align: center;">{{item.fileName}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="col-md-12 ">&nbsp;</div>
                </div>
        </div>
    </div>
</div>

<!-- <button printSectionId="print-section" (click)="Print()" ngxPrint>print</button>  -->



<!-- <app-header></app-header>
<div class="col-md-12 row">&nbsp;</div>
<div class="col-md-12 row">&nbsp;</div>

<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
        
        
        <div class="col-md-12 row" *ngIf="roleid=='2'">
            <div class="col-md-9"></div>
            <div class="col-md-3">
                <button class="btn btn-primary" (click)="DownloadForm()">Download</button>
            </div>
        </div>
        <div class="col-md-12 row">&nbsp;</div>
        <div class="col-md-12 row">&nbsp;</div>
        <div class="homecard" #content id="print-section" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%">
        
            <div class="col-md-12 row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Personal Details</h2>
                </div>
                <div class="col-md-2">
                    <button *ngIf="roleid!='2'"  class="btn btn-primary" (click)="personaldetailsclick()">Edit</button>
                </div>
            </div>

            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">
                <div class="col-md-12 row">
                    <div class="col-md-2 row">&nbsp;</div>
                    <div class="col-md-3 row" style="display: flex;">
                        <label id="emaillabel">First Name:</label>&nbsp;{{fname}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email" id="emaillabel ">Middle Name:</label>&nbsp;{{mname}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel">Last Name:</label>&nbsp;{{lname}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel">Place of Birth:</label>&nbsp;{{placebirth}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email" id="emaillabel">Gender:</label>&nbsp;{{gender}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel"> Marital Status:</label>&nbsp;{{marital}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Mobile Number:</label>&nbsp;{{mobile}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Date of Birth:</label>&nbsp;{{dob | date : 'shortDate'}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Religion:</label>&nbsp;{{religion}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Caste:</label>&nbsp;{{caste}} Brahmin

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Qualification:</label>&nbsp;{{qualification}}


                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Occupation:</label>&nbsp;{{occupation}}


                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> Email:</label>&nbsp;{{email}}

                    </div>

                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Designation:</label>&nbsp;{{designation}}


                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Postal Code:</label>&nbsp;{{rPostal}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Country:</label>&nbsp;{{rCountry}} India
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> State:</label>&nbsp;{{rState}}
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">District:</label>&nbsp;{{rDistrict}}
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Address one:</label>&nbsp;{{rAddress1}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address two:</label>&nbsp;{{rAddress2}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Permanent Address</h4>

             
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Postal Code:</label>&nbsp;{{pPostal}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Country:</label>&nbsp;{{pCountry}} India
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> State:</label>&nbsp;{{pState}}
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">District:</label>&nbsp;{{pDistrict}}
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address one:</label>&nbsp;{{pAddress1}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address two:</label>&nbsp;{{pAddress2}}

                        </div>
                    </div>
                </div>

            </div>
            <br>
            <br>
            <div class="col-md-12 " *ngIf="familyDetails">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Family Details</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'" class="btn btn-primary" (click)="familydetailsclick()">Edit</button>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 ">
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Family Type:</label> &nbsp;
                            <span *ngIf="familyDetails?.familyTypeString != null">{{familyDetails?.familyTypeString}}</span>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Number of Dependents:</label>&nbsp;
                            <span *ngIf="familyDetails?.numberofDependents != null">{{familyDetails.numberofDependents}}</span>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Number of Children:</label>&nbsp;
                           <span *ngIf="familyDetails.numberofChildren != null">{{familyDetails.numberofChildren}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Annual Income in Rs. :</label>&nbsp;
                            <span *ngIf="familyDetails.annualIncomeAmount != null">{{familyDetails.annualIncomeAmount}}</span>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Income from Other sources in Rs. :</label>&nbsp;
                            <span *ngIf="familyDetails.otherSourceAmount != null">{{familyDetails.otherSourceAmount}}</span>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Total Annual Income:</label>&nbsp;
                            <span *ngIf="familyDetails.totalAmount != null">{{familyDetails.totalAmount}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Minor Nominee</h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">First Name:</label>&nbsp;
                            <span *ngIf="familyDetails.minorNomineeName != null">{{familyDetails.minorNomineeName}}</span>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Middle Name:</label>&nbsp;
                            <span *ngIf="familyDetails.minorNomineeDOB != null">{{familyDetails.minorNomineeDOB|date}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>

                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Last Name:</label>&nbsp;
                            <span *ngIf="familyDetails.minorNomineeName != null">{{familyDetails.minorNomineeName}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Postal Code:</label>&nbsp;{{familyDetails.minorPostalCode}}
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Country:</label>&nbsp;{{familyDetails.minorCountry}}
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> State:</label>&nbsp;{{familyDetails.minorState}}
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">District:</label>&nbsp;{{familyDetails.minorDistrict}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Address Line One:</label>&nbsp;
                            <span *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.minorAddress1}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address Line Two:</label>&nbsp;
                            <span *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.minorAddress2}}</span>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                    </div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Guardian Details</h3>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Guardian Name:</label>&nbsp;
                            <span *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.guardianName}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Relationship:</label>&nbsp;
                            <span *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.guardianReationship}}</span>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                    </div>
                    <div id="familydocsid" *ngIf="familydocsexist">
                        <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="col-md-12 row">
                            <div class="col-md-1 row">&nbsp;</div>

                            <div class="col-md-2 row" *ngFor="let item of familydocs">
                                <div *ngIf="item.documentType == 'image/jpeg' ||
                            item.documentType == 'image/png'" (click)="ViewBankFamDoc(item)">
                                    <img [src]="item.file" height="100" width="100" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf=" (item.documentType == 'application/pdf' ||
                            item.documentType == 'pdf')" (click)="ViewBankFamDoc(item)">
                                    <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                            item.documentType == 'xlsx')" (click)="ViewBankFamDoc(item)">
                                    <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                            item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewBankFamDoc(item)">
                                    <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                </div>
            </div>

            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12 row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Bank Details</h2>
                </div>
                <div class="col-md-2">
                    <button *ngIf="roleid!='2'" class="btn btn-primary"  (click)="bankdetailsclick()">Edit</button>
                </div>
            </div>

            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">
                <div class="container" style="margin-top: 5%">

                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Bank Account Type</th>
                                <th>Bank Name</th>
                                <th>Branch</th>
                                <th>IFSC Code</th>
                                <th>Cheque Facility</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of bankdetails">
                                <td>{{data.banktypeid}}</td>
                                <td>{{data.bankname}} </td>
                                <td>{{data.branch}} </td>
                                <td>{{data.ifscecode}} </td>
                                <td>{{data.chequefacility}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12 row ">


                    <div class="col-md-12 ">&nbsp;</div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;text-align: center;    margin-left: 42%; ">Identity Details</h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Ration Card Number:</label> &nbsp;{{userBankDetails.rationCardNum}}

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Aadhar Number:</label>&nbsp;{{userBankDetails.aadharNum}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Voter ID Number:</label>&nbsp;{{userBankDetails.voterId}}

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">PAN Number:</label>&nbsp;{{userBankDetails.panNum}}

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-4 row ">
                            <label for="Email " id="emaillabel ">Service Tax Number:</label>&nbsp;{{userBankDetails.serviceTaxNum}}

                        </div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Passport Number:</label>&nbsp;{{userBankDetails.passportNum}}

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Life Insurance Value:</label>&nbsp;{{userBankDetails.lifeInsuranceValue}}

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Confirm:</label>&nbsp;{{userBankDetails.isConfirm}}

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>



                    <BR/>
                    <div class="col-md-12 row" id="bankdocsid">
                    <div class="col-md-12 row">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;margin-left: 15%; ">Uploaded Documents</h3> <br>
                    <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;margin-left: 15%; ">Download Documents</h5> <br>

                    <ol>
                        <li *ngIf="userBankDetails.aadharFile"><a href="{{userBankDetails.aadharFile}}">Aadhar</a></li>
                        <li *ngIf="userBankDetails.bankStatements"><a href="{{userBankDetails.bankStatements}}">Bank Statement</a></li>
                        <li *ngIf="userBankDetails.gstCert"><a href="{{userBankDetails.gstCert}}">GST</a></li>
                        <li *ngIf="userBankDetails.itReturnsFile"><a href="{{userBankDetails.itReturnsFile}}">ITR</a></li>
                        <li *ngIf="userBankDetails.passportFile"><a href="{{userBankDetails.passportFile}}">Passport</a></li>
                        <li *ngIf="userBankDetails.voterIdFile"><a href="{{userBankDetails.voterIdFile}}">Voter ID</a></li>

                    </ol>
                    <div class="col-md-12 row">&nbsp;</div>
                    <div class="col-md-12 row">&nbsp;</div>

                    <div class="col-md-2 row" *ngFor="let item of bankdocs" style="    margin-left: 8%;">
                        <div class="col-md-1 row">&nbsp;</div>

                        <div *ngIf="item.documentType == 'image/jpeg' ||
                        item.documentType == 'image/png'" (click)="ViewBankFamDoc(item)">
                            <img [src]="item.file" height="100" width="100" />
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-10 row">
                                    <p style="text-align: center;">{{item.documentName}}</p>

                                </div>

                            </div>
                        </div>
                        <div *ngIf=" (item.documentType == 'application/pdf' ||
                        item.documentType == 'pdf')" (click)="ViewBankFamDoc(item)">
                            <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-10 row">
                                    <p style="text-align: center;">{{item.documentName}}</p>

                                </div>

                            </div>
                        </div>
                        <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        item.documentType == 'xlsx')" (click)="ViewBankFamDoc(item)">
                            <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-10 row">
                                    <p style="text-align: center;">{{item.documentName}}</p>

                                </div>

                            </div>
                        </div>
                        <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewBankFamDoc(item)">
                            <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                            <div class="col-md-12 row">
                                <div class="col-md-2 row">&nbsp;</div>
                                <div class="col-md-10 row">
                                    <p style="text-align: center;">{{item.documentName}}</p>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                    <br>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
            </div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Asset Details</h2>
                </div>
                <div class="col-md-2">
                    <button  *ngIf="roleid!='2'" class="btn btn-primary"  (click)="assetdetailsclick()">Edit</button>
                </div>
            </div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 " *ngIf="moveableexist">
                <div class="container" style="margin-top :  5%">

                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Fixed Asset</th>
                                <th>Location with Address</th>
                                <th>Measurement</th>
                                <th>Guidance Value</th>
                                <th>Present Value</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of fixedassetDetails.fixedAssets;let i = index" style="font-size : 16px;font-family : DINNextRoundedLTPro ">
                                <td>
                                    {{data.fixedAssetId}}
                                </td>
                                <td>{{data.placeallocation}}</td>

                                <td>{{data.measurement}}</td>
                                <td>{{data.giidancevalue}}</td>
                                <td>{{data.presentvalue}}</td>
                                <td>{{data.total}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="container" style="margin-top :  5%">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Movable Asset</th>
                                <th>Model</th>
                                <th>Date Of Purchase</th>
                                <th>Quantity</th>
                                <th>Present Value</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let dat of fixedassetDetails.movableAssets;let i = index" style="font-size : 16px;font-family : DINNextRoundedLTPro ">
                                <td>
                                    {{dat.movableAssetId}}
                                </td>
                                <td>{{dat.model}}</td>
                                <td>{{dat.dateofpurchase|date}}</td>
                                <td>{{dat.quantity}}</td>
                                <td>{{dat.prevalues}}</td>
                                <td>
                                    {{dat.cartotal}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">&nbsp;</div>
      
            <div class="col-md-12 " *ngIf="businessloan">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Business Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'"  class="btn btn-primary" (click)="businessdetailsclick()">Edit</button>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 ">&nbsp;</div>

                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">GST No:</label> &nbsp;{{GSTNo}} 

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Business Address & Tel No:</label>&nbsp;{{businessAddTelNo}}

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Whether the Applicant belongs to : SC / ST /
                            OTHERS:</label>&nbsp;{{scst}} 
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Factory / Godown Address & Tel No:</label>&nbsp;{{Factoryaddress}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Name(s) of Partners / Proprietor /
                            Directors:</label>&nbsp;{{partnername}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Partner Age / Proprietor / Directors:</label>&nbsp;{{agepartner}} 45

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Past Experience:</label>&nbsp;{{experiencepast}} Srichid

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Residential Address of Partners / Proprietor /
                            Directors:</label>&nbsp;{{resiaddr}}

                    </div>


                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Partner / Proprietor / Directors Amount Share in
                            amt:</label>&nbsp;{{partnershare}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Whether the applicant is technically qualified /experienced /
                            educated unemployed? if so furnish details:</label>&nbsp;{{techqualified}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel "> Line of activity and year of Establishment:</label>&nbsp;{{loaandyearofest}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Loan Amount:</label>&nbsp;{{loanamt}}

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Credit facilities required</h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Term Loan Purpose:</label>&nbsp;{{TermLoanpurpose}}

                    </div>


                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Working capital Purpose:</label>&nbsp;{{WorkingcapitalPurpose}}

                    </div>

                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Term Loan Amount:</label>&nbsp;{{TermLoanAmount}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Working capital Amount:</label>&nbsp;{{WorkingcapitalAmount}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Term Loan Repayment programme:</label>&nbsp;{{TermLoanRepaymentprogramme}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div>
                    <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;font-size: 20px; ">Whether the Business Premises are own / rented / leased:
                    </h5>&nbsp;{{businessPremisesOwn}}
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div>
                    <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;font-size: 20px; ">Will applicant be able to route his trade proceeds through the bank:</h5>&nbsp;{{routerThroughBank}}
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div>
                    <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;font-size: 20px; ">Are basic books of accounts like Stock book, sales / Purchase books, Day Book,etc. being maintained:</h5>&nbsp;{{booksmaintained}}
                </div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Working capital Repayment programme:</label> &nbsp;{{workingrepaymentprogramme}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Names and addresses of Associate concerns (if any)
                            Ph No:</label>&nbsp;{{nameandAddressofAssociateConcerns}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">If not able to route his, what difficulties prevent
                            him from doing so
                        :</label>&nbsp;{{difficulties}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Commodities traded in or services rendered (Marketing):</label>&nbsp;{{commoditiesTraded}}
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Sources, Major Suppliers of the commodities
                            (Marketing):</label>&nbsp;{{majorSuppliers}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">(iii) No. of firms (with names) engaged in similar
                            activity in the same locality (Marketing):</label>&nbsp;{{numberOfFirms}} 1

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">(iv) How will the competition be met successfully?
                            (Marketing):</label>&nbsp;{{competationSuccessfull}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Anticipated performance in the case of Existing units</h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">(i) Performance in the past two years
                </h3>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Year1:</label>&nbsp;{{year1}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Sales1:</label>&nbsp;{{sales1}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Net Profit1:</label>&nbsp;{{netProfit1}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Remarks1:</label>&nbsp;{{remarks1}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Year2:</label>&nbsp;{{year2}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Sales2:</label>&nbsp;{{sales2}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Remarks2:</label>&nbsp;{{remarks2}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Net Profit2:</label>&nbsp;{{netProfit2}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">(ii) Anticipated turn over</h3>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Year:</label>&nbsp;{{anticipatedYear}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Sales:</label>&nbsp;{{anticipatedSales}}

                    </div>

                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Net Profit:</label>&nbsp;{{anticipatedProfit}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Remarks:</label>&nbsp;{{anticipatedRemarks}}

                    </div>
                </div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">(iii) How is the anticipated turnover considered feasible of achievement?</h3>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Year:</label>&nbsp;{{feasibleYear}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Sales:</label>&nbsp;{{feasibleSales}}

                    </div>

                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Net Profit:</label>&nbsp;{{feasibleNetProfit}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Remarks:</label>&nbsp;{{feasibleRemarks}}

                    </div>
                </div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">(iv) Particulars of Sales Tax and Income Tax Assessment.(copies to the enclosed)</h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">I.T. Assessment Year:</label>&nbsp;{{itAssessmentYear}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Amount Paid:</label>&nbsp;{{itAmounPaid}}

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">S.T. Assessment Year:</label>&nbsp;{{stAssessmentYear}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Amount Paid:</label>&nbsp;{{stAmountPaid}}

                    </div>
                </div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Working capital requirement for anticipated turn over</h3>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Value:</label>&nbsp;{{AnticipatedValue}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Margin:{{AnticipatedMargin}}</label>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Permissible Limit:{{AnticipatedPermissibleLimit}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Remarks:{{AntiRemarks}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Average level of stocks required at any one time</h3>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Value:</label>&nbsp;{{avgLvlValue}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Margin:</label>&nbsp;{{avgLvlMargin}}

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Permissible Limit:</label>&nbsp;{{avgLvlPermissibleLimit}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Remarks:</label>&nbsp;{{avgLvlRemarks}}

                    </div>
                </div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Average level of outstanding DEBTORS at any one time</h3>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Value:</label>&nbsp;{{outSatndingValue}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Margin:</label>&nbsp;{{outSatndingMargin}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Permissible Limit:</label>&nbsp;{{outStandingPermissibleLimit}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Remarks:</label>&nbsp;{{outStandingRemarks}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Working Capital required</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Rs: </label>&nbsp;{{workingCapitalRequired}}

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Credit available from suppliers</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Rs:</label>&nbsp;{{creditAvailableSuppliers}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Repayment Programme if any, for the Loan.
                            (Applicable to Term Loan):</label>&nbsp;{{repaymentProgramme}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Finance for Equipment (proforma Invoice / to be attached)</h3>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <h4> SL. No</h4>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <h4>(i)</h4>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <h4>(ii)</h4>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <h4> (iii)</h4>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Item:</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{item1}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{item2}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{item3}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Supplier:</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{supplier1}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{suppier2}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{supplier3}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Cost:</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{cost}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{cost1}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">&nbsp;{{cost2}}</label>

                    </div>
                </div>





                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Less applicant's contribution Rs:</label>&nbsp;{{lessapplicantscontribution}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Loan required for equipment Rs:</label>&nbsp;{{loanEquipmentRupees}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">


                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Repayment Program :</label>&nbsp;{{reapymentProgamme}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel "> Security : Primary<br />(for Wc & TL to be
                            indicated separately):</label>&nbsp;{{security}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> How long it will take for the equipment to be erected /
                            anticipated sales to be reached:</label>&nbsp;{{timeToErect}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Start up period require:</label>&nbsp;{{startUpPeriod}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

               
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Details relating to collateral security
                </h3>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">In case of immovable property:</label> &nbsp;{{detailsofImmovableProperty}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Convert as LSR/Valuation:</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Co-obligant/Guarantor (His Direct & Indirect Liability to be mentioned)
                </h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Guarantor Property:</label>&nbsp;{{guarantor}} Nil


                    </div>

                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Guarantor Convert:</label>&nbsp;{{guarantorValue}}


                    </div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Firm name:</label>&nbsp;{{firmName}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Brief description of goods:</label>&nbsp;{{desc1}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Rs:</label>&nbsp;{{desc1Rs}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Brief description of goods:</label>&nbsp;{{desc2}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Rs:</label>&nbsp;{{desc2Rs}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Brief description of goods:</label>&nbsp;{{desc3}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Rs:</label>&nbsp;{{desc3Rs}}

                    </div>
                </div>
            </div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">&nbsp;</div>

            <div class="col-md-12 " *ngIf="educationloan">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Education Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'"  (click)="educationloandetailsclick()" class="btn btn-primary">Edit</button>
                    </div>
                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">FINANCIAL DETAILS</h3>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Income : </label>
                        <span *ngIf="educationLoanDetails.facilities != null">{{educationLoanDetails.facilities}}</span>


                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Assets : </label>
                        <span *ngIf="educationLoanDetails.finance != null">{{educationLoanDetails.finance}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Liabilities : </label>
                        <span *ngIf="educationLoanDetails.worth != null">{{educationLoanDetails.worth}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">ACADEMIC BACKGROUND</h3>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Examination from SSC : </label>
                        <span *ngIf="educationLoanDetails.examination != null">{{educationLoanDetails.examination}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Name of board,University : </label>
                        <span *ngIf="educationLoanDetails.university != null">{{educationLoanDetails.university}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Year of Passing : </label>
                        <span *ngIf="educationLoanDetails.register != null">{{educationLoanDetails.register}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Attempts Made : </label>
                        <span *ngIf="educationLoanDetails.authority != null">{{educationLoanDetails.authority}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">% of marks obtained : </label>
                        <span *ngIf="educationLoanDetails.activity != null">{{educationLoanDetails.activity}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Class Divison : </label>
                        <span *ngIf="educationLoanDetails.establishment != null">{{educationLoanDetails.establishment}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Particular of Scholarship/Prize won : </label>
                        <span *ngIf="educationLoanDetails.proprietary != null">{{educationLoanDetails.proprietary}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Extra Curricular activities : </label>
                        <span *ngIf="educationLoanDetails.maintained != null">{{educationLoanDetails.maintained}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Other relevant information : </label>
                        <span *ngIf="educationLoanDetails.relevant != null">{{educationLoanDetails.relevant}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Remarks : </label>
                        <span *ngIf="educationLoanDetails.eduremrks != null">{{educationLoanDetails.eduremrks}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">LOAN REQUIREMENT</h3>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Course applied : </label>
                        <span *ngIf="educationLoanDetails.course != null">{{educationLoanDetails.course}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Fulltime/Parttime : </label>
                        <span *ngIf="educationLoanDetails.parttime != null">{{educationLoanDetails.parttime}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Duration of course : </label>
                        <span *ngIf="educationLoanDetails.competent != null">{{educationLoanDetails.competent}}</span>
                    </div>
                </div>

                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Date of Admission : </label>
                        <span *ngIf="educationLoanDetails.admission != null">{{educationLoanDetails.admission}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Name of institution/university : </label>
                        <span *ngIf="educationLoanDetails.institution != null">{{educationLoanDetails.institution}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Other Relevant inform : </label>
                        <span *ngIf="educationLoanDetails.present != null">{{educationLoanDetails.present}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">EXPENDITURE OF COURSE</h3>
                <div class="col-md-12 ">&nbsp;</div>


                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th style=" width: 22%;"></th>
                            <th>1st Year</th>
                            <th>2nd Year</th>
                            <th>3rd Year</th>
                            <th>4th Year</th>
                            <th>5th Year</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Tuition Fees</td>
                            <td>
                                <span *ngIf="educationLoanDetails.tuition != null">{{educationLoanDetails.tuition}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.tuitionsec != null">{{educationLoanDetails.tuitionsec}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.tuitionthr != null">{{educationLoanDetails.tuitionthr}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.tuitionfour != null">{{educationLoanDetails.tuitionfour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.tuitionfive != null">{{educationLoanDetails.tuitionfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Examination Fees</td>
                            <td>
                                <span *ngIf="educationLoanDetails.feesfirst != null">{{educationLoanDetails.feesfirst}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.feessec != null">{{educationLoanDetails.feessec}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.feesthree != null">{{educationLoanDetails.feesthree}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.feesfour != null">{{educationLoanDetails.feesfour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.feesfive != null">{{educationLoanDetails.feesfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Books, Stationary</td>
                            <td>
                                <span *ngIf="educationLoanDetails.stationary != null">{{educationLoanDetails.stationary}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.books != null">{{educationLoanDetails.books}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.bookssta != null">{{educationLoanDetails.bookssta}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.booksstafour != null">{{educationLoanDetails.booksstafour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.booksfive != null">{{educationLoanDetails.booksfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Equipment</td>
                            <td>
                                <span *ngIf="educationLoanDetails.equipmentone != null">{{educationLoanDetails.equipmentone}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.equipmenttwo != null">{{educationLoanDetails.equipmenttwo}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.equipmentthree != null">{{educationLoanDetails.equipmentthree}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.equipmentfour != null">{{educationLoanDetails.equipmentfour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.equipmentfive != null">{{educationLoanDetails.equipmentfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Hostel Board</td>
                            <td>
                                <span *ngIf="educationLoanDetails.hostel != null">{{educationLoanDetails.hostel}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.hostelboard != null">{{educationLoanDetails.hostelboard}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.boardHostel != null">{{educationLoanDetails.boardHostel}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.boardHotfour != null">{{educationLoanDetails.boardHotfour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.boardHfive != null">{{educationLoanDetails.boardHfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Sundries</td>
                            <td>
                                <span *ngIf="educationLoanDetails.sundriesone != null">{{educationLoanDetails.sundriesone}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.sundriessec != null">{{educationLoanDetails.sundriessec}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.sundriesthrd != null">{{educationLoanDetails.sundriesthrd}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.sundriesfour != null">{{educationLoanDetails.sundriesfour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.sundriesfive != null">{{educationLoanDetails.sundriesfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Insurance</td>
                            <td>
                                <span *ngIf="educationLoanDetails.insuranceone != null">{{educationLoanDetails.insuranceone}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.insurancetwo != null">{{educationLoanDetails.insurancetwo}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.insurancethrd != null">{{educationLoanDetails.insurancethrd}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.insurancefour != null">{{educationLoanDetails.insurancefour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.insurancefive != null">{{educationLoanDetails.insurancefive}}</span>
                            </td>
                        </tr>

                        <tr>
                            <td>Others</td>
                            <td>
                                <span *ngIf="educationLoanDetails.othersfirst != null">{{educationLoanDetails.othersfirst}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.otherstwo != null">{{educationLoanDetails.otherstwo}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.othersthree != null">{{educationLoanDetails.othersthree}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.othersfour != null">{{educationLoanDetails.othersfour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.othersfive != null">{{educationLoanDetails.othersfive}}</span>
                            </td>
                        </tr>
                    </tbody>

                </table>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th style=" width: 22%;">Sources of Finance</th>
                            <th>1st Year</th>
                            <th>2nd Year</th>
                            <th>3rd Year</th>
                            <th>4th Year</th>
                            <th>5th Year</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Non repayable scholarship/Fellowship/Stipend or any other financial assistance</td>
                            <td>
                                <span *ngIf="educationLoanDetails.scholarship != null">{{educationLoanDetails.scholarship}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.stipend != null">{{educationLoanDetails.stipend}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.fellowship != null">{{educationLoanDetails.fellowship}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.repayable != null">{{educationLoanDetails.repayable}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.assistance != null">{{educationLoanDetails.assistance}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Funds available from family sources</td>
                            <td>
                                <span *ngIf="educationLoanDetails.fundsfirst != null">{{educationLoanDetails.fundsfirst}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.fundssec != null">{{educationLoanDetails.fundssec}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.availablethrd != null">{{educationLoanDetails.availablethrd}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.availablefour != null">{{educationLoanDetails.availablefour}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.sourcesfive != null">{{educationLoanDetails.sourcesfive}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Loan from Bank</td>
                            <td>
                                <span *ngIf="educationLoanDetails.bankfirst != null">{{educationLoanDetails.bankfirst}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.banksecond != null">{{educationLoanDetails.banksecond}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.bankthird != null">{{educationLoanDetails.bankthird}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.bankfourth != null">{{educationLoanDetails.bankfourth}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.bankfifth != null">{{educationLoanDetails.bankfifth}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>
                                <span *ngIf="educationLoanDetails.first != null">{{educationLoanDetails.first}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.second != null">{{educationLoanDetails.second}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.third != null">{{educationLoanDetails.third}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.fourth != null">{{educationLoanDetails.fourth}}</span>
                            </td>
                            <td>
                                <span *ngIf="educationLoanDetails.fifth != null">{{educationLoanDetails.fifth}}</span>

                            </td>
                        </tr>

                    </tbody>
                </table>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Explain Utility / scope future prospects of the course
                        : </label>
                        <span *ngIf="educationLoanDetails.credit != null">{{educationLoanDetails.credit}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Expected Total Loan Amount : </label>
                        <span *ngIf="educationLoanDetails.type != null">{{educationLoanDetails.type}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Expected Loan Tenor : </label>
                        <span *ngIf="educationLoanDetails.relationship != null">{{educationLoanDetails.relationship}}</span>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Expected income by completion of course : </label>
                        <span *ngIf="educationLoanDetails.amtadv != null">{{educationLoanDetails.amtadv}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Expected Monthly Expensive : </label>
                        <span *ngIf="educationLoanDetails.facilities != null">{{educationLoanDetails.balance}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Expected EMI : </label>
                        <span *ngIf="educationLoanDetails.managed != null">{{educationLoanDetails.managed}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">REFERENCE</h3>
                <div class="col-md-12 ">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float:left;margin-left: 8%;">REFERENCE 1
                </h5>
                <div class="col-md-12 ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name : </label>
                        <span *ngIf="educationLoanDetails.facilities != null">{{educationLoanDetails.reasons}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address : </label>
                        <span *ngIf="educationLoanDetails.owned != null">{{educationLoanDetails.owned}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Pin Code : </label>
                        <span *ngIf="educationLoanDetails.primary != null">{{educationLoanDetails.primary}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float:left;margin-left: 8%;">REFERENCE 2
                </h5>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name : </label>
                        <span *ngIf="educationLoanDetails.adults != null">{{educationLoanDetails.adults}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Address : </label>
                        <span *ngIf="educationLoanDetails.children != null">{{educationLoanDetails.children}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Pin Code : </label>
                        <span *ngIf="educationLoanDetails.pintotal != null">{{educationLoanDetails.pintotal}}</span>
                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Applicant Name : </label>
                        <span *ngIf="educationLoanDetails.member != null">{{educationLoanDetails.member}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Application Date : </label>
                        <span *ngIf="educationLoanDetails.obligation != null">{{educationLoanDetails.obligation}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Place : </label>
                        <span *ngIf="educationLoanDetails.place != null">{{educationLoanDetails.place}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
            </div>


            <div class="col-md-12 row ">&nbsp;</div>

            <div class="col-md-12">&nbsp;</div>

            <div  class="col-md-12" *ngIf="goldloan">

                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Gold Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'"  class="btn btn-primary" (click)="goldloandetailsclick()">Edit</button>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-3 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel ">TYPE OF LOAN : &nbsp;</label>
                        <span>{{goldType}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row">
                    <div class="col-md-2 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <label id="emaillabel">REQUIRED AMOUNT : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.requiredamount != null">{{goldLoanDetails.requiredamount}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">DETAILS OF CURRENT LOAN AND OVERDRAFTS : &nbsp;                                 <span *ngIf="goldLoanDetails.overdrafts != null">{{goldLoanDetails.overdrafts}}</span>
                        </label>


                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">INTEREST PAYMENT MODE</h4>
                    </div>
                    <label for="Email " id="emaillabel ">Monthly : &nbsp;</label>
                    <div class="col-md-1 row ">
                        <span *ngIf="goldLoanDetails.mon != null">{{goldLoanDetails.mon}}</span>

                    </div>
                    <label for="Email " id="emaillabel ">Quarterly : &nbsp;</label>
                    <div class="col-md-1 row ">
                        <span *ngIf="goldLoanDetails.quar != null">{{goldLoanDetails.quar}}</span>

                    </div>
                    <div class="col-md-2 row ">&nbsp;</div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>

                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">REASON FOR LOAN : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.reasonId != null">{{goldLoanDetails.reasonId}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">MODE OF DISBURSEMENT : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.disbursement != null">{{goldLoanDetails.disbursement}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> NET ANNUAL INCOME : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.goldamt != null">{{goldLoanDetails.goldamt}}</span>


                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">DETAILS OF GOLD ORNAMENTS : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.oranaments != null">{{goldLoanDetails.oranaments}}</span>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> Total : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.goldtotal != null">{{goldLoanDetails.goldtotal}}</span>


                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> Value : &nbsp;</label>
                        <span *ngIf="goldLoanDetails.goldvalue != null">{{goldLoanDetails.goldvalue}}</span>
                    </div>
                </div>

            </div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">&nbsp;</div>


            <div class="col-md-12  " *ngIf="fixedassetloan">

                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Fixed Asset Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'"  class="btn btn-primary" (click)="fixedassetloandetailsclick()">Edit</button>
                    </div>
                </div>


                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Measurement</h4>


                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">East to West(in ft/Mts): {{fixedAssetLoanDetails.easttowest}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">North to south (in ft/Mts): {{fixedAssetLoanDetails.northtosouth}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">North: {{fixedAssetLoanDetails.north}}</label>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">South: {{fixedAssetLoanDetails.south}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">East: {{fixedAssetLoanDetails.east}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">West: {{fixedAssetLoanDetails.west}}</label>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-9 row ">
                        <label id="emaillabel ">Present value of property as estimated by the
                            applicant(valuation should be realistic): {{fixedAssetLoanDetails.repaying}}</label>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Is the property self-acquired or ancestral: {{fixedAssetLoanDetails.ancestral}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Is the property encumbered; if so, to whom and to what
                            extent: {{fixedAssetLoanDetails.encumbered}}</label>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-9 row ">
                        <label id="emaillabel ">Had the property been mortgaged on previous occasion, if so
                            furnish details: {{fixedAssetLoanDetails.bank}}</label>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Since how long the applicant is in enjoyment of the
                            property: {{fixedAssetLoanDetails.outstanding}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Name(s) of tenants (if any) and monthly
                            rent: {{fixedAssetLoanDetails.value}}</label>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Source of Income (monthly)(for salary earners)
                </h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Applicant: </h4>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Gross salary Rs: {{fixedAssetLoanDetails.purchased}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Deductions Rs: {{fixedAssetLoanDetails.deductions}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Net Salary Rs: {{fixedAssetLoanDetails.salary}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Applicant's Spouse:</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Gross salary Rs: {{fixedAssetLoanDetails.purchased}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Deductions Rs: {{fixedAssetLoanDetails.inclusive}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Net Salary Rs: {{fixedAssetLoanDetails.satory}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">(Indicate only if documentary evidence is produced)</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Income from other Source: {{fixedAssetLoanDetails.incomesource}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Loan Amount: {{fixedAssetLoanDetails.allowance}}</label>
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-9 row ">
                        <label id="emaillabel ">Source of Income (for self employed and others)[furnish full
                            particulars with documentary evidence]: {{fixedAssetLoanDetails.allowance}}</label>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Is the applicant Income Tax / Sales Tax assessee: {{fixedAssetLoanDetails.payment}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Details of other immovable properties: {{fixedAssetLoanDetails.properties}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-9 row ">
                        <label for="Email " id="emaillabel ">Average Monthly expenses of Applicant and his
                            dependents: {{fixedAssetLoanDetails.delivery}}</label>
                    </div>

                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Name and age of near relatives living
                </h4>
                <div class="col-md-12 ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Father Name: {{fixedAssetLoanDetails.applicant}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Father Age: {{fixedAssetLoanDetails.fatherage}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Mother Name: {{fixedAssetLoanDetails.mother}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Mother Age: {{fixedAssetLoanDetails.motherage}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Husband/Wife Name: {{fixedAssetLoanDetails.husband}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Husband/Wife Age: {{fixedAssetLoanDetails.wife}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Sons Name: {{fixedAssetLoanDetails.sons}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Sons Age: {{fixedAssetLoanDetails.sonsage}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Daughters Name: {{fixedAssetLoanDetails.daughters}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Daughters Age: {{fixedAssetLoanDetails.daughterage}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Brothers Name: {{fixedAssetLoanDetails.brothers}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Brothers Age: {{fixedAssetLoanDetails.brotherage}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Sisters Name: {{fixedAssetLoanDetails.sisters}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Sisters Age: {{fixedAssetLoanDetails.sisterage}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Grandsons Name: {{fixedAssetLoanDetails.grandsons}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Grandsons Age: {{fixedAssetLoanDetails.grandsonage}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Brother's son Name: {{fixedAssetLoanDetails.brotherson}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Brother's son Age: {{fixedAssetLoanDetails.brothersonage}}</label>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">If applicant is female Her husband's coparceners &
                            heirs her father's coparceners & heirs: {{fixedAssetLoanDetails.balance}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Relatives who have an interest in the property (in
                            case of H.U.F. property): {{fixedAssetLoanDetails.assessee}}</label>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Name of widows, minors and othe entitled to maintenance from the
                            property: {{fixedAssetLoanDetails.earning}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Details of family members who can supplement the
                            applicant's income for repayment of loan: {{fixedAssetLoanDetails.modelname}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Information Regarding Repayment Of Debts - I</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Debts incurred in connection with
                </h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">a) Construction/Additional loan/Repairs of house Rs: {{fixedAssetLoanDetails.employed}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">House Purchase/Site Purchase Rs.: {{fixedAssetLoanDetails.finishloan}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">1)Date of completion of construction: {{fixedAssetLoanDetails.concern}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">2)Total construction area: {{fixedAssetLoanDetails.located}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">3)Total amount spent on construction Rs.: {{fixedAssetLoanDetails.activity}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b) Ceremonial Expenses such as Marriages, etc. Rs: {{fixedAssetLoanDetails.under}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Medical (Furnish details) Rs: {{fixedAssetLoanDetails.annualincome}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">d) Educational (Furnish details) Rs: {{fixedAssetLoanDetails.borrower}}</label>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Parties to whom debt to be repayed in full and final settlement</h4>
                <div class="col-md-12 ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Name of the party: {{fixedAssetLoanDetails.registration}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Amount: {{fixedAssetLoanDetails.authorised}}</label>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Whether documentary evidence is available to
                            establish borrowings? If so, furnish details: {{fixedAssetLoanDetails.towards}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Whether direct payment can be made to parties concerned: {{fixedAssetLoanDetails.property}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Information Regarding Details of Business - II</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name of the business concern: {{fixedAssetLoanDetails.benefit}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Nature of business: {{fixedAssetLoanDetails.relationship}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Year of establishment: {{fixedAssetLoanDetails.course}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Licenses/registration certificate obtained
                            in: {{fixedAssetLoanDetails.obtained}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Connection with business furnish details: {{fixedAssetLoanDetails.evidence}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Are you an IT Assessee if so: {{fixedAssetLoanDetails.deposite}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Pan No. & Return submitted upto: {{fixedAssetLoanDetails.additional}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Annual turnover for the year: {{fixedAssetLoanDetails.account}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Enclose the B/s - P & I: {{fixedAssetLoanDetails.membership}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Present Banker: {{fixedAssetLoanDetails.numbersb}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">How the loan is proposed to be utilised in: {{fixedAssetLoanDetails.proposed}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Business activity: {{fixedAssetLoanDetails.officedata}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">No. of Months required for repayment of
                            loan: {{fixedAssetLoanDetails.Department}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Take Over Of Liability - III</h4>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name of the Institution where the loan is: {{fixedAssetLoanDetails.income}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Outstanding and amount O/S, EMI Amount: {{fixedAssetLoanDetails.operative}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Mode of payment of loan EMI: {{fixedAssetLoanDetails.board}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Whether Paid up-to date: {{fixedAssetLoanDetails.salary}}</label>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Whether the application has any other liability
                            with the present situation: {{fixedAssetLoanDetails.domestic}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Whether NOC is obtained from the present
                            Institution: {{fixedAssetLoanDetails.institution}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Whether authenticated copies of the documents are obtained for
                            submission to legal scrutiny: {{fixedAssetLoanDetails.copies}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Whether direct payment to be made to the: {{fixedAssetLoanDetails.made}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Present Mortgagor: {{fixedAssetLoanDetails.mortgagor}}</label>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Higher Education - IV</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">Name of the person and relation for whom the loan is proposed to
                            be utilised and the details of the course of the education: {{fixedAssetLoanDetails.whom}}</label>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-9 row ">
                        <label id="emaillabel ">Total period of education and details of the course Fees, Hostel
                            charges, Capitation Fees etc: {{fixedAssetLoanDetails.fees}}</label>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-9 row ">
                        <label id="emaillabel ">Whether undertaking letter from the student about completion of
                            course and employment particulars is obtained: {{fixedAssetLoanDetails.letter}}</label>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">Whether undertaking letters from the parent about the place of
                            employment of the candidate from time to time is obtained: {{fixedAssetLoanDetails.candidate}}</label>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Information Regarding Details of Construction- V</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel ">Address of the property: {{fixedAssetLoanDetails.proaddress}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Measurement of site</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">East to West: {{fixedAssetLoanDetails.etow}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> North to South: {{fixedAssetLoanDetails.ntos}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Total area(in Sqft.): {{fixedAssetLoanDetails.area}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Existing Construction in Squares: {{fixedAssetLoanDetails.squares}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Proposed Construction: {{fixedAssetLoanDetails.construction}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Total area of construction (Sqt.): {{fixedAssetLoanDetails.totalarea}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Class of construction: {{fixedAssetLoanDetails.classfield}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Cost of construction per square: {{fixedAssetLoanDetails.persquare}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Total estimate of construction: {{fixedAssetLoanDetails.estimate}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Cost of construction in stages</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a. Foundation Rs: {{fixedAssetLoanDetails.foundation}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b. Basement Lintel & Roofing Rs: {{fixedAssetLoanDetails.roofing}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">c. Electrical, sanitary, water supply & plasting: {{fixedAssetLoanDetails.sanitary}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">d. Flooring and final finish: {{fixedAssetLoanDetails.flooring}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Present state of constructions: {{fixedAssetLoanDetails.presentstate}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Amount spent so for on construction:
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">a. On building Rs: {{fixedAssetLoanDetails.building}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">b. Value of stock Rs: {{fixedAssetLoanDetails.stock}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Source of Funds</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a. By savings Rs: {{fixedAssetLoanDetails.savings}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b. By hand loans Rs: {{fixedAssetLoanDetails.hand}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">c. From other financial Institutions Rs: {{fixedAssetLoanDetails.financial}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">d. Refundable provident fund loan Rs: {{fixedAssetLoanDetails.refundable}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">e. Non-refundable provident fund loan Rs: {{fixedAssetLoanDetails.fundpro}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">f. Others if any Rs: {{fixedAssetLoanDetails.others}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">g. By loan from this bank Rs: {{fixedAssetLoanDetails.bankfrom}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">h. Other loan like Credit Card, Loans for consumer durable etc.
                            Rs: {{fixedAssetLoanDetails.durable}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Loans for consumer durable etc Rs: {{fixedAssetLoanDetails.consumer}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Whether you are residing in a rental house, if so, what is the
                            monthly rent paid?: {{fixedAssetLoanDetails.rental}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a. Is the house for self-occupation or for letting out?
                            Rs: {{fixedAssetLoanDetails.house}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b. If for letting out, what is the rent expected? Area of
                            letting out in Sft. Rs: {{fixedAssetLoanDetails.expected}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">c. What is the house advance expected Rs: {{fixedAssetLoanDetails.advance}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel ">If you are unable to complete construction within the estimated
                            value due to rise in cost of building materials, how you propose to complete
                            construction ? Rs: {{fixedAssetLoanDetails.unable}}</label>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">What is the escalation in estimated value of construction is
                            expected ? Rs: {{fixedAssetLoanDetails.escalation}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Accumulation of Provident fund amount & the amount of
                            non-refundable fund, you are entitled for Rs.: {{fixedAssetLoanDetails.accumulation}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Purchasing House/Site </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name of the Present Owner: {{fixedAssetLoanDetails.preowner}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Full address of Property under purchase: {{fixedAssetLoanDetails.purchase}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Area coming under: {{fixedAssetLoanDetails.coming}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Area</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Land: {{fixedAssetLoanDetails.land}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Building: {{fixedAssetLoanDetails.builds}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Squares: {{fixedAssetLoanDetails.squaresed}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Agreed to purchase price Rs: {{fixedAssetLoanDetails.agreed}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Advance paid Rs: {{fixedAssetLoanDetails.paid}}</label>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Whether sale agreement entered into: {{fixedAssetLoanDetails.entered}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Balance proposed to be paid by: {{fixedAssetLoanDetails.proposedata}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Probable date of registration and amount Rs.</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Date: {{fixedAssetLoanDetails.probable}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Rs: {{fixedAssetLoanDetails.amtrs}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Class/School/College/in which children are studying</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name: {{fixedAssetLoanDetails.children}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Class: {{fixedAssetLoanDetails.classdata}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">School/College: {{fixedAssetLoanDetails.schcolg}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Whether owning moped/motorcycle/scooter/car, if yes, Reg.
                            No: {{fixedAssetLoanDetails.moped}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel ">monthly reimbursement of conveyance expenses rent / provision by
                            the employer, availed by the applicant, if any, furnish details: {{fixedAssetLoanDetails.conveyance}}</label>

                    </div>
                </div>

                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Other Income</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a. Rent: {{fixedAssetLoanDetails.rent}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Annual Income Rs: {{fixedAssetLoanDetails.annualin}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Monthly Income: {{fixedAssetLoanDetails.monthly}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b.Agriculture: {{fixedAssetLoanDetails.agriculture}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Annual Income Rs: {{fixedAssetLoanDetails.incant}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Monthly Income: {{fixedAssetLoanDetails.monthinc}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">c. Interest on Investment: {{fixedAssetLoanDetails.investment}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Annual Income Rs: {{fixedAssetLoanDetails.annualcant}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Monthly Income: {{fixedAssetLoanDetails.monthdata}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">d. Any other source of income: {{fixedAssetLoanDetails.ontherdata}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Annual Income Rs: {{fixedAssetLoanDetails.amout}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Monthly Income: {{fixedAssetLoanDetails.incomedata}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Income of other members of the family
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 5%;"> Person 1
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.person}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.personone}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.persondata}}
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 5%;"> Person 2
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.persontwo}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.persontwodta}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.pertwodta}}
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 5%;"> Person 3
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.personthree}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.personthredta}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        {{fixedAssetLoanDetails.personval}}
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Monthly Family maintences Expenses
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a) Rent in respect of the residential premises: {{fixedAssetLoanDetails.respect}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b) Provisions, milk, electricity, water,gas,vegetables and
                            household expenses: {{fixedAssetLoanDetails.household}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">c) Education expenses: {{fixedAssetLoanDetails.education}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">d) Conveyance expenses petrol, insurance, tax: {{fixedAssetLoanDetails.petrol}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">e) Telephone expenses: {{fixedAssetLoanDetails.telephone}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">f) Entertainment expenses: {{fixedAssetLoanDetails.entertainment}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">g) Repayment of loan(E.M.I. for existing Loan): {{fixedAssetLoanDetails.repayment}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Monthly income: {{fixedAssetLoanDetails.monthcome}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Monthly Total Expenditure: {{fixedAssetLoanDetails.expenditure}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Excess of income over Expenditure: {{fixedAssetLoanDetails.over}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Amount of loan applied for: {{fixedAssetLoanDetails.applied}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> E.M.I for the recommended loan: {{fixedAssetLoanDetails.recommended}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Scrutiny Sheet </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Present Repayment commitment p.m: {{fixedAssetLoanDetails.commitment}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Comment about repayment or existing Loan/Previous Loan: {{fixedAssetLoanDetails.about}}</label>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel "> Comments regarding method of Creation of charge like mortgage,
                            lein, Assignment, etc. on the security(for existing loan): {{fixedAssetLoanDetails.regarding}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Applicant employed at: {{fixedAssetLoanDetails.employeee}}</label>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel ">Comments about SB / C A/c operation Transaction, Minimum
                            balance, Return of cheques: {{fixedAssetLoanDetails.cheques}}</label>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> Period of service left over: {{fixedAssetLoanDetails.left}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Whether applicant is drawing salary through the bank/arrangement
                            for salary remittance is made: {{fixedAssetLoanDetails.arrangement}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Business particulars in the case of Applicant being a
                            Businessmen: {{fixedAssetLoanDetails.casefield}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">i) Details of financial accounts submitted: {{fixedAssetLoanDetails.details}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">ii) Details of Income proof submitted Income per annum: {{fixedAssetLoanDetails.annum}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Name of Co-obligant(s) & M.No: {{fixedAssetLoanDetails.obligant}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Co-obligant(s) employed at: {{fixedAssetLoanDetails.employedat}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Co-obligant Net Salary: {{fixedAssetLoanDetails.net}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> comment about Co-obligant if any: {{fixedAssetLoanDetails.comment}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-8 row ">
                        <label id="emaillabel ">Whether Co-obligant is drawing salary through the
                            bank/arrangement for salary remittance is made: {{fixedAssetLoanDetails.remittance}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Credit Appraisal Report </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Legal Opinion: {{fixedAssetLoanDetails.legal}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Legal Opinion obtained from: {{fixedAssetLoanDetails.opinion}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Valuation-Report obtained from: {{fixedAssetLoanDetails.report}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Value of site/Value of existing house: {{fixedAssetLoanDetails.existing}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Estimation for Construction / Renovation: {{fixedAssetLoanDetails.renovation}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Eligibility of loan as per loan Rule No: {{fixedAssetLoanDetails.eligibility}}</label>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> A.O. / Branch Copy</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Committee Resolution Dated: {{fixedAssetLoanDetails.resolution}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Loan Sanctioned Rs: {{fixedAssetLoanDetails.sanctioned}}</label>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Conditions of sanction: {{fixedAssetLoanDetails.conditions}}</label>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Compliance to post sanction / disbursement formalities: {{fixedAssetLoanDetails.formalities}}</label>

                    </div>
                </div>


            </div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">&nbsp;</div>

            <div class="col-md-12 " *ngIf="homeloan">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Home loan</h2>                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-primary" (click)="homeloandetailsclick()">Edit</button>
                    </div>
                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">FINANCIAL DETAILS</h3>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Applicant : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.applicant != 'null'">{{homeLoanDetails.applicant}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Income : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.income != null">{{homeLoanDetails.income}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Assets : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.assets != null">{{homeLoanDetails.assets}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Liabilities : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.liabilities != null">{{homeLoanDetails.liabilities}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Co-Applicant : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coApplicant != null">{{homeLoanDetails.coApplicant}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Co-Applicant Income : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coIncome != null">{{homeLoanDetails.coIncome}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Co-Applicant Assets : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coAssets != null">{{homeLoanDetails.coAssets}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Co-Applicant Liabilities : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coLiabilities != null">{{homeLoanDetails.coLiabilities}}</span>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">PERSONAL REFERENCE</h3>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name : &nbsp;</label>
                        <span>{{homeLoanDetails.coName}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Relation with applicant : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.corelationApplicant != null">{{homeLoanDetails.corelationApplicant}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Residential Address : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coresidentialadd != null">{{homeLoanDetails.coresidentialadd}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">City : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coCity != null">{{homeLoanDetails.coCity}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">PIN : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coPIN != null">{{homeLoanDetails.coPIN}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Mobile : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coMobile != null">{{homeLoanDetails.coMobile}}</span>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Phone : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coPhone != null">{{homeLoanDetails.coPhone}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Email ID : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.coEmail != null">{{homeLoanDetails.coEmail}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">LOAN REQUIREMENT</h3>
                <div class="col-md-12 ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Home Loan Amount : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.homeloanamount != null">{{homeLoanDetails.homeloanamount}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Tenor of Loan : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.tenor != null">{{homeLoanDetails.tenor}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Purpose : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.purposeloan != null">{{homeLoanDetails.purposeloan}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">DETAILS OF PROPERTY</h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.propertyaddress != null">{{homeLoanDetails.propertyaddress}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Area of Land/Flat : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.propertyarea != null">{{homeLoanDetails.propertyarea}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Present Owner : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.presentowner != null">{{homeLoanDetails.presentowner}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Property Type : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.presentowner == '1'">Freehold</span>
                        <span *ngIf="homeLoanDetails.presentowner == '2'">Lease</span>


                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Ownership Type : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.ownershiptype == '1'">Sole</span>
                        <span *ngIf="homeLoanDetails.ownershiptype == '2'">Joint</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Expected Date of Possession : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.expected != null">{{homeLoanDetails.expected}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Percentage of Work Complete : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.percentage != null">{{homeLoanDetails.percentage}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Proposed Owner's Name : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.proposeowner != null">{{homeLoanDetails.proposeowner}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">REPAYMENT OPTIONS : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.presentowner == '1'">ECS</span>
                        <span *ngIf="homeLoanDetails.presentowner == '2'">Auto Debit</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Applicant Name : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.applicantname != null">{{homeLoanDetails.applicantname}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Application Date : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.appdate != null">{{homeLoanDetails.appdate}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Place : &nbsp;</label>
                        <span *ngIf="homeLoanDetails.applicationplace != null">{{homeLoanDetails.applicationplace}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

            </div>

            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">&nbsp;</div>



            <div class="col-md-12 " *ngIf="projectloan">

                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Project Loan</h2>                    
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-primary" (click)="projectloandetailsclick()">Edit</button>
                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name Of the Company / Firm:</label>
                        <span>{{projectLoanDetails.companyfirm}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Ploat/Building/Plat Name and Number:</label>
                        <span>{{projectLoanDetails.ploat}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Street Name:</label>
                        <span *ngIf="projectLoanDetails.streetname != null">{{projectLoanDetails.streetname}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">City:</label>
                        <span *ngIf="projectLoanDetails.towname != null">{{projectLoanDetails.towname}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Telephone No:</label>
                        <span *ngIf="projectLoanDetails.telephoneno != null">{{projectLoanDetails.telephoneno}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Mobile No:</label>
                        <span *ngIf="projectLoanDetails.mobile != null">{{projectLoanDetails.mobile}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Fax:</label>
                        <span *ngIf="projectLoanDetails.fax != null">{{projectLoanDetails.fax}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Loan Applied for:</label>
                        <span *ngIf="projectLoanDetails.ancestral != null">{{projectLoanDetails.ancestral}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Purpose of Loan:</label>
                        <span *ngIf="projectLoanDetails.encumbered != null">{{projectLoanDetails.encumbered}}</span>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">When and how the loan will be repaid?:</label>
                        <span *ngIf="projectLoanDetails.loanac  != null">{{projectLoanDetails.loanac }}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Name of the Chief/ Promoter/ Chief Executive:
                        </label>
                        <span *ngIf="projectLoanDetails.outstanding != null">{{projectLoanDetails.outstanding}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Your House:</label>
                        <span *ngIf="projectLoanDetails.bank == 'true'">Owned</span>
                        <span *ngIf="projectLoanDetails.bank == null">Rented</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">What is your academic qualification? Is related to your
                            line of trade?:
                        </label>
                        <span *ngIf="projectLoanDetails.academicqualification != null">{{projectLoanDetails.academicqualification}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Your Account Number? What would be the minimum balance
                            in the account?:</label>
                        <span *ngIf="projectLoanDetails.deductions != null">{{projectLoanDetails.deductions}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Are you assessed the Income-Tax:</label>
                        <span *ngIf="projectLoanDetails.salary == 'true'">   Yes</span>
                        <span *ngIf="projectLoanDetails.intax == 'true'">   No</span>
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label for="Email " id="emaillabel ">Do you have a life insurance Policy?:</label>
                        <span *ngIf="projectLoanDetails.insur == 'true'">   Yes</span>
                        <span *ngIf="projectLoanDetails.insurpol == 'true'">   No</span>
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Year of commencement of business:</label>
                        <span *ngIf="projectLoanDetails.purchased != null">{{projectLoanDetails.purchased}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">Loan Amount:</label>
                        <span *ngIf="projectLoanDetails.inclusive != null">{{projectLoanDetails.inclusive}}</span>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">State the profit / loss and sales turnover for the last 3 financial year</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">First Year:</label>
                        <span *ngIf="projectLoanDetails.incomesource != null">{{projectLoanDetails.incomesource}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Second Year:</label>
                        <span *ngIf="projectLoanDetails.allowance != null">{{projectLoanDetails.allowance}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Three Year:</label>
                        <span *ngIf="projectLoanDetails.thrdyr != null">{{projectLoanDetails.thrdyr}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Tell us about your factory Premises:</label>
                        <span *ngIf="projectLoanDetails.premises == null">Yes</span>
                        <span *ngIf="projectLoanDetails.premises != null">No</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Tell us about your product:</label>
                        <span *ngIf="projectLoanDetails.applicant != null">{{projectLoanDetails.applicant}}</span>

                    </div>
                </div>

                <div class="col-md-12 ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>

                    <div class="col-md-4 row ">
                        <label id="emaillabel ">How do you plan to produce it?:</label>
                        <span *ngIf="projectLoanDetails.produce != null">{{projectLoanDetails.produce}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Does the product require special know how? If so, are you in
                            possession of the know:</label>
                        <span *ngIf="projectLoanDetails.prospecial != null">{{projectLoanDetails.prospecial}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">What are raw materials required?:</label>
                        <span *ngIf="projectLoanDetails.materials != null">{{projectLoanDetails.materials}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Whether your line of activity falls under priority sector?:
                        </label>
                        <span *ngIf="projectLoanDetails.sector == null">{{projectLoanDetails.sector}}</span> &nbsp;&nbsp;&nbsp;
                        <span *ngIf="projectLoanDetails.board == 'true'">No</span>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">How do you plan to produces them? Are they available on credit? If
                            so what are the terms of credit?:</label>
                        <span *ngIf="projectLoanDetails.terms != null">{{projectLoanDetails.terms}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">How do you plan to ensure the quality of your product? Any special
                            steps being taken?:</label>
                        <span *ngIf="projectLoanDetails.special != null">{{projectLoanDetails.special}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">Whether you need skilled labour? Please describe your plants for
                            skilled labour?:</label>
                        <span *ngIf="projectLoanDetails.skilled != null">{{projectLoanDetails.skilled}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">Any special reason to locate your business at the present location?
                            Is there any advantage in
                            procuring the raw materials locally? Is the local market big enough to subscribe the
                            finished
                            product of your firm? Any other advantage?:</label>
                        <span *ngIf="projectLoanDetails.advantage != null">{{projectLoanDetails.advantage}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Manufacturing process in brief:</label>
                        <span *ngIf="projectLoanDetails.manufacturing != null">{{projectLoanDetails.manufacturing}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">What the level of competition for the product? How do
                            you plan to meet the competition?:</label>
                        <span *ngIf="projectLoanDetails.competition != null">{{projectLoanDetails.competition}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Give the total outside liabilities for the last 3 years and your tangible network:</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">PARAMETER:</label>
                        <span *ngIf="projectLoanDetails.parameter != null">{{projectLoanDetails.parameter}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Total outside liabilities:</label>
                        <span *ngIf="projectLoanDetails.outside != null">{{projectLoanDetails.outside}}</span>
                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Tangible net worth(including loans from friends and relatives):
                        </label>
                        <span *ngIf="projectLoanDetails.tangible != null">{{projectLoanDetails.tangible}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">TOL / TNW:</label>
                        <span *ngIf="projectLoanDetails.balance != null">{{projectLoanDetails.balance}}</span>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">What is the quality of your receivables? How many months’ sales do they represent? Can you give an ageing of your receivables?
                </h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">AGE Less than 1 months old:</label>
                        <span *ngIf="projectLoanDetails.modelname != null">{{projectLoanDetails.modelname}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">AGE 1 to 2 months old:</label>
                        <span *ngIf="projectLoanDetails.old != null">{{projectLoanDetails.old}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">AGE 2 to 3 months old:</label>
                        <span *ngIf="projectLoanDetails.employed != null">{{projectLoanDetails.employed}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">INFORMATION REGARDING REPAYMENT OF DEBTS - I</h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Debts incurred in connection with
                </h4>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">AGE More than 3 months old:</label>
                        <span *ngIf="projectLoanDetails.monthold != null">{{projectLoanDetails.monthold}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Total percentage:</label>
                        <span *ngIf="projectLoanDetails.finishloan != null">{{projectLoanDetails.finishloan}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel "> What is the quality of your finished goods inventory? Are they sold
                            against order / as and when
                            produced? Are they kept in stock for long? How many months’ sales do they represent?:
                        </label>
                        <span *ngIf="projectLoanDetails.concern != null">{{projectLoanDetails.concern}}</span>

                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel "> What will be the time period between procurement of raw materials
                            to realization of sale proceeds?
                            Can you cut short the cycle without increasing your liability?:
                        </label>
                        <span *ngIf="projectLoanDetails.period != null">{{projectLoanDetails.period}}</span>

                    </div>
                </div>


                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Give the details of cost and the means of finance for your project</h4>
                <div class="col-md-12 ">&nbsp;</div>

                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;"> Land :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.person != null">{{projectLoanDetails.person}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.personone != null">{{projectLoanDetails.personone}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.persondata != null">{{projectLoanDetails.persondata}}</span>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">
                    Building:</h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.persontwo != null">{{projectLoanDetails.persontwo}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.persontwodta != null">{{projectLoanDetails.persontwodta}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.pertwodta != null">{{projectLoanDetails.pertwodta}}</span>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">
                    Machinery:</h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.personthree != null">{{projectLoanDetails.personthree}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.personthredta != null">{{projectLoanDetails.personthredta}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.personval != null">{{projectLoanDetails.personval}}</span>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Other Items :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.otheritem != null">{{projectLoanDetails.otheritem}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.otheritemone != null">{{projectLoanDetails.otheritemone}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.otheritemtwo != null">{{projectLoanDetails.otheritemtwo}}</span>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">From own sources:
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.ownsource != null">{{projectLoanDetails.ownsource}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.ownsourceone != null">{{projectLoanDetails.ownsourceone}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>


                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Bank loan :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.bankloans != null">{{projectLoanDetails.bankloans}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.bankloandata != null">{{projectLoanDetails.bankloandata}}</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">
                    Friends and relatives:</h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.friends != null">{{projectLoanDetails.friends}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.friendsrela != null">{{projectLoanDetails.friendsrela}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Others :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.othersdata != null">{{projectLoanDetails.othersdata}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.othersdatee != null">{{projectLoanDetails.othersdatee}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Total :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.totaldata != null">{{projectLoanDetails.totaldata}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.totaltwo != null">{{projectLoanDetails.totaltwo}}</span>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">Has the project been vetted by consultancy cell of the Bank or
                            consultants of repute? Give details:</label>
                        <span *ngIf="projectLoanDetails.registration != null">{{projectLoanDetails.registration}}</span>
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label for="Email " id="emaillabel ">What is the repayment period you are looking for the
                            term loan? What will be the annual cash
                            accruals? What will be your liability towards payment of installments and interest on
                            term
                            loan in a year?:</label>
                        <span *ngIf="projectLoanDetails.authorised != null">{{projectLoanDetails.authorised}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">What is the collateral you would be able to offer? GIVE DETAILS</h4>
                <div class="col-md-12">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Item :
                </h5>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.benefit != null">{{projectLoanDetails.benefit}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.relationship != null">{{projectLoanDetails.relationship}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.studying != null">{{projectLoanDetails.studying}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Value :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.course != null">{{projectLoanDetails.course}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.obtained != null">{{projectLoanDetails.obtained}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.evidence != null">{{projectLoanDetails.evidence}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Basis :
                </h5>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.under != null">{{projectLoanDetails.under}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.annualincome != null">{{projectLoanDetails.annualincome}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.borrower != null">{{projectLoanDetails.borrower}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Total :
                </h5>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.deposite != null">{{projectLoanDetails.deposite}}</span>
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.additional != null">{{projectLoanDetails.additional}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <span *ngIf="projectLoanDetails.account != null">{{projectLoanDetails.account}}</span>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Anything else you would like to tell about your business:</label>
                        <span *ngIf="projectLoanDetails.membership != null">{{projectLoanDetails.membership}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Please tall us about your future plans:</label>
                        <span *ngIf="projectLoanDetails.futureplan != null">{{projectLoanDetails.futureplan}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">What is the level of sales you are projecting? Briefly describe the
                            basis:</label>
                        <span *ngIf="projectLoanDetails.officedatp != null">{{projectLoanDetails.officedatp}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> What is the level of receivables you are expecting in terms of
                            month’s sale?:</label>
                        <span *ngIf="projectLoanDetails.receivables != null">{{projectLoanDetails.receivables}}</span>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Application Type:</label>
                        <span *ngIf="projectLoanDetails.income == 'true'">New</span>
                        <span *ngIf="projectLoanDetails.operative == 'true'">Update</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Account Holder Type:</label>
                        <span *ngIf="projectLoanDetails.holder != null">{{projectLoanDetails.holder}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">US Reportable:</label>
                        <span *ngIf="projectLoanDetails.reportable != null">{{projectLoanDetails.reportable}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Other Reportable:</label>
                        <span *ngIf="projectLoanDetails.mortgagor != null">{{projectLoanDetails.mortgagor}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Proof of Identity</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Certificate of Incorporation / Formation:</label>
                        <span *ngIf="projectLoanDetails.formation != null">{{projectLoanDetails.formation}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Officially valid document(s) in respect of person authorized to
                            transact:
                        </label>
                        <span *ngIf="projectLoanDetails.transact != null">{{projectLoanDetails.transact}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Resolution of Board / Managing Committee:</label>
                        <span *ngIf="projectLoanDetails.letter != null">{{projectLoanDetails.letter}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Memorandum and article of Association / Partnership /Trust Document:
                        </label>
                        <span *ngIf="projectLoanDetails.proaddress != null">{{projectLoanDetails.proaddress}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>



                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Activity proof (For dole proprietorship only):</label>
                        <span *ngIf="projectLoanDetails.proprietorship != null">{{projectLoanDetails.proprietorship}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Other:</label>
                        <span *ngIf="projectLoanDetails.area != null">{{projectLoanDetails.area}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Proof Of Address (POA)</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-6 row ">
                        <label id="emaillabel ">Registered Office Address In India(If Applicable) / Place of
                            Business:
                        </label>
                        <span *ngIf="projectLoanDetails.candidate != null">{{projectLoanDetails.candidate}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel ">Address Type:</label>
                        <span *ngIf="projectLoanDetails.squares != null">{{projectLoanDetails.squares}}</span>
                    </div>
                </div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Proof Of Address:</label>
                        <span *ngIf="projectLoanDetails.classfield != null">{{projectLoanDetails.classfield}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Certificate of Incorporation / Formation:</label>
                        <span *ngIf="projectLoanDetails.persquare != null">{{projectLoanDetails.persquare}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Registration Certificate:</label>
                        <span *ngIf="projectLoanDetails.estimate != null">{{projectLoanDetails.estimate}}</span>

                    </div>
                </div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Address Line 1:</label>
                        <span *ngIf="projectLoanDetails.foundation != null">{{projectLoanDetails.foundation}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 2:</label>
                        <span *ngIf="projectLoanDetails.roofing != null">{{projectLoanDetails.roofing}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 3:</label>
                        <span *ngIf="projectLoanDetails.sanitary != null">{{projectLoanDetails.sanitary}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">City:</label>
                        <span *ngIf="projectLoanDetails.flooring != null">{{projectLoanDetails.flooring}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">District:</label>
                        <span *ngIf="projectLoanDetails.presentstate != null">{{projectLoanDetails.presentstate}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">PIN/Post Code:</label>
                        <span *ngIf="projectLoanDetails.presentstate != null">{{projectLoanDetails.presentstate}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Sate / UT Name:</label>
                        <span *ngIf="projectLoanDetails.building != null">{{projectLoanDetails.building}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">State / UT Code:</label>
                        <span *ngIf="projectLoanDetails.statecode != null">{{projectLoanDetails.statecode}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Country Name:</label>
                        <span *ngIf="projectLoanDetails.country != null">{{projectLoanDetails.country}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Country Code:</label>
                        <span *ngIf="projectLoanDetails.stock != null">{{projectLoanDetails.stock}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> (ii)Correspondence / Local Address Details
                </h4>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Proof Of Address:</label>
                        <span *ngIf="projectLoanDetails.refundable != null">{{projectLoanDetails.refundable}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Certificate of Incorporation / Formation:</label>
                        <span *ngIf="projectLoanDetails.fundpro != null">{{projectLoanDetails.fundpro}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Registration Certificate:</label>
                        <span *ngIf="projectLoanDetails.others != null">{{projectLoanDetails.others}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 1:</label>
                        <span *ngIf="projectLoanDetails.bankfrom != null">{{projectLoanDetails.bankfrom}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 2:</label>
                        <span *ngIf="projectLoanDetails.durable != null">{{projectLoanDetails.durable}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 3:</label>
                        <span *ngIf="projectLoanDetails.consumer != null">{{projectLoanDetails.consumer}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">City:</label>
                        <span *ngIf="projectLoanDetails.rental != null">{{projectLoanDetails.rental}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">District:</label>
                        <span *ngIf="projectLoanDetails.house != null">{{projectLoanDetails.house}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">PIN/Post Code:</label>
                        <span *ngIf="projectLoanDetails.expected != null">{{projectLoanDetails.expected}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Sate / UT Name:</label>
                        <span *ngIf="projectLoanDetails.advance != null">{{projectLoanDetails.advance}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">State / UT Code:</label>
                        <span *ngIf="projectLoanDetails.unable != null">{{projectLoanDetails.unable}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Country Name:</label>
                        <span *ngIf="projectLoanDetails.escalation != null">{{projectLoanDetails.escalation}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Country Code.:</label>
                        <span *ngIf="projectLoanDetails.accumulation != null">{{projectLoanDetails.accumulation}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> (iii)Address in the Jurisdiction where Entity is Residence Outside India for Tax Purposes Details</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Same as Current / Permanent / Overseas Address:</label>
                        <span *ngIf="projectLoanDetails.preowner != null">{{projectLoanDetails.preowner}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Same as Correspondence / Local Address Details:</label>
                        <span *ngIf="projectLoanDetails.purchase != null">{{projectLoanDetails.purchase}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Type:</label>
                        <span *ngIf="projectLoanDetails.land != null">{{projectLoanDetails.land}}</span>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Proof Of Address:</label>
                        <span *ngIf="projectLoanDetails.agreed != null">{{projectLoanDetails.agreed}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Certificate of Incorporation / Formation:</label>
                        <span *ngIf="projectLoanDetails.paid != null">{{projectLoanDetails.paid}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Registration Certificate:</label>
                        <span *ngIf="projectLoanDetails.entered != null">{{projectLoanDetails.entered}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Probable date of registration and amount Rp.</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 1:</label>
                        <span *ngIf="projectLoanDetails.probable != null">{{projectLoanDetails.probable}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 2:</label>
                        <span *ngIf="projectLoanDetails.amtrs != null">{{projectLoanDetails.amtrs}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line 3:</label>
                        <span *ngIf="projectLoanDetails.proposedatp != null">{{projectLoanDetails.proposedatp}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">City:</label>
                        <span *ngIf="projectLoanDetails.children != null">{{projectLoanDetails.children}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">District:</label>
                        <span *ngIf="projectLoanDetails.districtdata != null">{{projectLoanDetails.districtdata}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">PIN/Post Code:</label>
                        <span *ngIf="projectLoanDetails.schcolg != null">{{projectLoanDetails.schcolg}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Sate / UT Name:</label>
                        <span *ngIf="projectLoanDetails.utname != null">{{projectLoanDetails.utname}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> State / UT Code:</label>
                        <span *ngIf="projectLoanDetails.conveyance != null">{{projectLoanDetails.conveyance}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Country Name:</label>
                        <span *ngIf="projectLoanDetails.conname != null">{{projectLoanDetails.conname}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Country Code:</label>
                        <span *ngIf="projectLoanDetails.concode != null">{{projectLoanDetails.concode}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Related Persons/ Beneficial Owner
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Number of Related Persons:</label>
                        <span *ngIf="projectLoanDetails.rent != null">{{projectLoanDetails.rent}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Number of Beneficial Owners:</label>
                        <span *ngIf="projectLoanDetails.annualin != null">{{projectLoanDetails.annualin}}</span>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-8 row ">
                        <label id="emaillabel ">Tax Residence of India only and not other Country outside India:
                        </label>
                        <span *ngIf="projectLoanDetails.agriculture == 'true'">Yes</span>
                        <span *ngIf="projectLoanDetails.residence == 'true'">No</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Tax Residence of US:</label>
                        <span *ngIf="projectLoanDetails.taxresidenew == 'true'">New</span>
                        <span *ngIf="projectLoanDetails.taxreside == 'true'">Update</span>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">US TIN:</label>
                        <span *ngIf="projectLoanDetails.investment != null">{{projectLoanDetails.investment}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-3 row ">
                        <label id="emaillabel ">A US Person:</label>
                        <span *ngIf="projectLoanDetails.usperson == 'true'">Yes</span>
                        <span *ngIf="projectLoanDetails.uspersondata == 'true'">No</span>
                    </div>


                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">A Specified US Person:</label>
                        <span *ngIf="projectLoanDetails.ontherdata == 'true'">Yes</span>
                        <span *ngIf="projectLoanDetails.specified == 'true'">No</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">Tax Resident Outside India other than US:</label>
                        <span *ngIf="projectLoanDetails.required == 'true'">  NO</span>
                        <span *ngIf="projectLoanDetails.required == null">  Yes</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Country Code:</label>
                        <span *ngIf="projectLoanDetails.respect != null">{{projectLoanDetails.respect}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> TIN / Functional Equivalent:</label>
                        <span *ngIf="projectLoanDetails.household != null">{{projectLoanDetails.household}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">A Governmental Entity:</label>
                        <span *ngIf="projectLoanDetails.education == 'true'">Yes</span>
                        <span *ngIf="projectLoanDetails.governmental == 'true'">No</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">An International Organization: :</label>
                        <span *ngIf="projectLoanDetails.entertainment == 'true'">Yes</span>
                        <span *ngIf="projectLoanDetails.international == 'true'">No</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">A Central Bank:</label>
                        <span *ngIf="projectLoanDetails.telephone == 'true'">  Yes</span>
                        <span *ngIf="projectLoanDetails.central == 'true'">   No</span>
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">A Financial Institution:</label>
                        <span *ngIf="projectLoanDetails.petrol == 'true'">  Yes</span>
                        <span *ngIf="projectLoanDetails.petrol == null"> No</span>
                    </div>

                </div>

                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Nature Of Business</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Manufacture:</label>
                        <span *ngIf="projectLoanDetails.repayment != null">{{projectLoanDetails.repayment}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Trade:</label>
                        <span *ngIf="projectLoanDetails.monthcome != null">{{projectLoanDetails.monthcome}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Retailer:</label>
                        <span *ngIf="projectLoanDetails.expenditure != null">{{projectLoanDetails.expenditure}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>


                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Service Provider:</label>
                        <span *ngIf="projectLoanDetails.service   == null">  True</span>
                        <span *ngIf="projectLoanDetails.service   != null">  False</span>
                    </div>

                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Export / Import:</label>
                        <span *ngIf="projectLoanDetails.exImport == null">  True</span>
                        <span *ngIf="projectLoanDetails.exImport != null">  False</span>
                    </div>
                    <div class="col-md-2 row ">
                        <label for="Email " id="emaillabel ">Other:</label>
                        <span *ngIf="projectLoanDetails.otherprovider  != null">True</span>
                        <span *ngIf="projectLoanDetails.otherprovider != null">False</span>
                    </div>
                </div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Industry Code:</label>
                        <span *ngIf="projectLoanDetails.over != null">{{projectLoanDetails.over}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Other:</label>
                        <span *ngIf="projectLoanDetails.applied != null">{{projectLoanDetails.applied}}</span>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-3 row ">&nbsp;</div>
                    <label id="emaillabel ">Other:</label>
                    <span *ngIf="projectLoanDetails.valuelak != null">  0-5 Lakh</span>
                    <span *ngIf="projectLoanDetails.tenlakh != null">  5-10 Lakh</span>
                    <span *ngIf="projectLoanDetails.twentylakhs != null">  10-25 Lakh</span>
                    <span *ngIf="projectLoanDetails.onecre != null">  25 Lakh - 1 Cr</span>
                    <span *ngIf="projectLoanDetails.onetofive != null">  1 - 5 Cr</span>
                    <span *ngIf="projectLoanDetails.fiftycr != null">  5 - 50Cr</span>
                    <span *ngIf="projectLoanDetails.hundredcr != null">  50 - 100 Cr</span>
                    <span *ngIf="projectLoanDetails.crmore != null">  50 - 100 Cr</span>



                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>



                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Mode Of Operations</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>
                    <label for="Email " id="emaillabel ">1.</label>
                    <span *ngIf="projectLoanDetails.singledata != null">Single</span>
                    <span *ngIf="projectLoanDetails.jointly != null">Jointly</span>
                    <span *ngIf="projectLoanDetails.severally != null">Severally</span>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>
                    <label for="Email " id="emaillabel ">2. As per Board Resolution</label>
                    <span *ngIf="projectLoanDetails.boardResolution != null">True</span>
                    <div class="col-md-2 row ">&nbsp;</div>
                    <label for="Email " id="emaillabel ">Other</label>
                    <span *ngIf="projectLoanDetails.boardResoluother != null">True</span>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>
                    <label for="Email " id="emaillabel ">3.</label>
                    <span *ngIf="projectLoanDetails.internet != null">As per Board ResolutionCorporate Internet
                        Banking</span>
                    <span *ngIf="projectLoanDetails.viewing != null">Viewing Rights</span>
                    <span *ngIf="projectLoanDetails.transaction != null">Transaction Rights</span>
                    <span *ngIf="projectLoanDetails.chequebook != null">Cheque Book</span>
                    <span *ngIf="projectLoanDetails.debitcd != null">Business Debit Card</span>


                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>

                    <label for="Email " id="emaillabel ">4.</label>
                    <span *ngIf="projectLoanDetails.swiping != null">POS Facility (card Swiping Machine)</span>
                    <span *ngIf="projectLoanDetails.alerts != null">SMS Alerts</span>
                    <span *ngIf="projectLoanDetails.facilityup != null">Cash Pick up Facility</span>
                    <span *ngIf="projectLoanDetails.collectsta != null">Sate Bank Collect</span>
                </div>
                <div class="col-md-1 row ">

                </div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>
                    <label for="Email " id="emaillabel ">5.</label>
                    <span *ngIf="projectLoanDetails.frequency != null">Statement Frequency</span>
                    <span *ngIf="projectLoanDetails.fremonth != null">Monthly</span>
                    <span *ngIf="projectLoanDetails.quarterlyfre != null">Quarterly</span>
                    <span *ngIf="projectLoanDetails.yearlythalf != null">Half-Yearlyt</span>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Account Variant</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <span *ngIf="projectLoanDetails.normal != null">Normal Current Account (MBA Rs 10000)</span>
                    <span *ngIf="projectLoanDetails.power != null"> &nbsp;&nbsp;Power Gain Current Account (MBA Rs.
                        200000)</span>
                    <span *ngIf="projectLoanDetails.currentpack != null">&nbsp;&nbsp;Power Pack Current Account (MBA Rs
                        500000)</span>
                    <span *ngIf="projectLoanDetails.pOS != null">&nbsp;&nbsp;Power POS Current Account</span>
                    <span *ngIf="projectLoanDetails.jyoti != null">&nbsp;&nbsp;Power Jyoti (MBA Rs 50000)</span>
                    <span *ngIf="projectLoanDetails.uploaded != null">&nbsp;&nbsp;Power Jyoti (Pre Uploaded)(MBA Rs
                        50000)</span>
                    <span *ngIf="projectLoanDetails.surbhi != null">&nbsp;&nbsp;Surbhi Current Account (MBA Rs 10000)</span>
                    <span *ngIf="projectLoanDetails.surbhiother != null">&nbsp;&nbsp;Other</span>


                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Nomination: Applicable only for sale proprietorship
                </h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">

                    <div class="col-md-2 row ">&nbsp;</div>
                    <span *ngIf="projectLoanDetails.nomination != null">I / We want to make a nomination in My/Our
                        Account</span>

                    <span *ngIf="projectLoanDetails.accountour != null">I / We do not want to make a nomination in
                        My/Our Account</span>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Nomination Form (DA1)</h4>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">Nomination under Section 45Z of the Banking Regulation Act, 1949 and
                            rule 2(1) of Banking Companies
                            (Nomination) Rule 1985 in the respect of Bank Deposits.I / We :</label>
                        <span *ngIf="projectLoanDetails.commitment != null">{{projectLoanDetails.commitment}}</span>

                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-10 row ">
                        <label id="emaillabel ">nominate the following person to whom in the event of my / our /
                            minor's death amount of Deposit,
                            particulars where are given below, may be returned by State Bank of India :</label>
                        <span *ngIf="projectLoanDetails.about != null">{{projectLoanDetails.about}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> I/we want the name of the nominee to be printed on the Passbook:
                        </label>
                        <span *ngIf="projectLoanDetails.cheques != null">{{projectLoanDetails.cheques}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Deposit:</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Type of Deposit:</label>
                        <span *ngIf="projectLoanDetails.arrangement != null">{{projectLoanDetails.arrangement}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Account Number:</label>
                        <span *ngIf="projectLoanDetails.casefield != null">{{projectLoanDetails.casefield}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Nominee:</h4>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name:</label>
                        <span *ngIf="projectLoanDetails.details != null">{{projectLoanDetails.details}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Relationship with Depositor:</label>
                        <span *ngIf="projectLoanDetails.annum != null">{{projectLoanDetails.annum}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Age:</label>
                        <span *ngIf="projectLoanDetails.obligant != null">{{projectLoanDetails.obligant}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">DOB:</label>
                        <span *ngIf="projectLoanDetails.employedat != null">{{projectLoanDetails.employedat}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line One :</label>
                        <span *ngIf="projectLoanDetails.net != null">{{projectLoanDetails.net}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line Two :</label>
                        <span *ngIf="projectLoanDetails.comment != null">{{projectLoanDetails.comment}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Address Line Three :</label>
                        <span *ngIf="projectLoanDetails.remittance != null">{{projectLoanDetails.remittance}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> CREDIT APPRAISAL REPORT</h4>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> City:</label>
                        <span *ngIf="projectLoanDetails.legal != null">{{projectLoanDetails.legal}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> PIN Code:</label>
                        <span *ngIf="projectLoanDetails.opinion != null">{{projectLoanDetails.opinion}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Sate:</label>
                        <span *ngIf="projectLoanDetails.report != null">{{projectLoanDetails.report}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> As the nominee is minor on this date, I/ we Appoint Shri/Smt:
                        </label>
                        <span *ngIf="projectLoanDetails.existing != null">{{projectLoanDetails.existing}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Age:</label>
                        <span *ngIf="projectLoanDetails.renovation != null">{{projectLoanDetails.renovation}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Years:</label>
                        <span *ngIf="projectLoanDetails.eligibility != null">{{projectLoanDetails.eligibility}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>




                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Reference 1</h4>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Name:
                        </label>
                        <span *ngIf="projectLoanDetails.referenceoneName != null">{{projectLoanDetails.referenceoneName}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Relationship with Depositor:</label>
                        <span *ngIf="projectLoanDetails.referenceoneRelationship != null">{{projectLoanDetails.referenceoneRelationship}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Address Line One:</label>
                        <span *ngIf="projectLoanDetails.referenceoneaddone != null">{{projectLoanDetails.referenceoneaddone}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Address Line Two :
                        </label>
                        <span *ngIf="projectLoanDetails.referenceoneaddtwo != null">{{projectLoanDetails.referenceoneaddtwo}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">City:</label>
                        <span *ngIf="projectLoanDetails.referenceonecity != null">{{projectLoanDetails.referenceonecity}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">PIN:</label>
                        <span *ngIf="projectLoanDetails.referenceonepin != null">{{projectLoanDetails.referenceonepin}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Mobile :
                        </label>
                        <span *ngIf="projectLoanDetails.referenceonemobile != null">{{projectLoanDetails.referenceonemobile}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Phone:</label>
                        <span *ngIf="projectLoanDetails.referenceonephone != null">{{projectLoanDetails.referenceonephone}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Email ID:</label>
                        <span *ngIf="projectLoanDetails.referenceoneemail != null">{{projectLoanDetails.referenceoneemail}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>



                <div class="col-md-12">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Reference 2</h4>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Name:
                        </label>
                        <span *ngIf="projectLoanDetails.referencetwoName != null">{{projectLoanDetails.referencetwoName}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Relationship with Depositor:</label>
                        <span *ngIf="projectLoanDetails.referencetwoRelationship != null">{{projectLoanDetails.referencetwoRelationship}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Address Line One:</label>
                        <span *ngIf="projectLoanDetails.referencetwoaddone != null">{{projectLoanDetails.referencetwoaddone}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Address Line Two :
                        </label>
                        <span *ngIf="projectLoanDetails.referencetwoaddtwo != null">{{projectLoanDetails.referencetwoaddtwo}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">City:</label>
                        <span *ngIf="projectLoanDetails.referencetwocity != null">{{projectLoanDetails.referencetwocity}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">PIN:</label>
                        <span *ngIf="projectLoanDetails.referencetwopin != null">{{projectLoanDetails.referencetwopin}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Mobile :
                        </label>
                        <span *ngIf="projectLoanDetails.referencetwomobile != null">{{projectLoanDetails.referencetwomobile}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Phone:</label>
                        <span *ngIf="projectLoanDetails.referencetwophone != null">{{projectLoanDetails.referencetwophone}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Email ID:</label>
                        <span *ngIf="projectLoanDetails.referencetwoemail != null">{{projectLoanDetails.referencetwoemail}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel "> Preferred Delivery mode :
                        </label>
                        <span *ngIf="projectLoanDetails.preferredDeliverymode != null">{{projectLoanDetails.preferredDeliverymode}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-2 row ">
                        <label id="emaillabel ">Preferred Mailing Address:</label>
                        <span *ngIf="projectLoanDetails.preferredMailingAddress != null">{{projectLoanDetails.preferredMailingAddress}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    
                </div>
                <div class="col-md-12 row ">&nbsp;</div>


            </div>

            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">&nbsp;</div>

            <div class="col-md-12 " *ngIf="smallscaleloan">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Small Scale Loan</h2>                     </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'" class="btn btn-primary" (click)="smallscaleloandetailsclick()">Edit</button>
                    </div>
                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">
                    <div class="col-md-12 ">&nbsp;</div>
                    <h3 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Credit facilities required</h3>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Working Capital : </label>
                            <span *ngIf="SmallScaleDetails.facilities != null">{{SmallScaleDetails.facilities}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Equipment/Machinery finance : </label>
                            <span *ngIf="SmallScaleDetails.finance != null">{{SmallScaleDetails.finance}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Name of the Concern/Firm : </label>
                            <span *ngIf="SmallScaleDetails.concern != null">{{SmallScaleDetails.concern}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Business Address and Tel. Nos : </label>
                            <span *ngIf="SmallScaleDetails.business != null">{{SmallScaleDetails.business}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Registration No. & Dates : </label>
                            <span *ngIf="SmallScaleDetails.register != null">{{SmallScaleDetails.register}}</span>


                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Registration Authority : </label>
                            <span *ngIf="SmallScaleDetails.authority != null">{{SmallScaleDetails.authority}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Nature of activity : </label>
                            <span *ngIf="SmallScaleDetails.activity != null">{{SmallScaleDetails.activity}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Date of establishment : </label>
                            <span *ngIf="SmallScaleDetails.establishment != null">{{SmallScaleDetails.establishment}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">In case of Proprietary Concern : </label>
                            <span *ngIf="SmallScaleDetails.proprietary != null">{{SmallScaleDetails.proprietary}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Whether basic books of accounts maintained : </label>
                            <span *ngIf="SmallScaleDetails.maintained != null">{{SmallScaleDetails.maintained}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">a) Father's/Husband's Name : </label>
                            <span *ngIf="SmallScaleDetails.purchased != null">{{SmallScaleDetails.purchased}}</span>


                        </div>
                    </div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">b) Whether applicant belongs to : </label>
                            <span *ngIf="SmallScaleDetails.belongs != null">{{SmallScaleDetails.belongs}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Furnish PAN No. : </label>
                            <span *ngIf="SmallScaleDetails.furnish != null">{{SmallScaleDetails.furnish}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel "> TIN No. : </label>
                            <span *ngIf="SmallScaleDetails.tinno != null">{{SmallScaleDetails.tinno}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">VAT No. : </label>
                            <span *ngIf="SmallScaleDetails.vatno != null">{{SmallScaleDetails.vatno}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Registered with any other competent authority to
                    run the business (give details) : </label>
                            <span *ngIf="SmallScaleDetails.competent != null">{{SmallScaleDetails.competent}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Whether related to any of the Board of Directors of the bank are
                    associated with the firm : </label>
                            <span *ngIf="SmallScaleDetails.related != null">{{SmallScaleDetails.related}}</span>


                        </div>
                    </div>
                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">


                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Name of associate concern : </label>
                            <span *ngIf="SmallScaleDetails.associate != null">{{SmallScaleDetails.associate}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">a) Name of present Banker : </label>
                            <span *ngIf="SmallScaleDetails.present != null">{{SmallScaleDetails.present}}</span>

                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">b) Details of Credit Facilities availed : </label>
                            <span *ngIf="SmallScaleDetails.credit != null">{{SmallScaleDetails.credit}}</span>


                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Type : </label>
                            <span *ngIf="SmallScaleDetails.type != null">{{SmallScaleDetails.type}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Amt. Adv. : </label>
                            <span *ngIf="SmallScaleDetails.amtadv != null">{{SmallScaleDetails.amtadv}}</span>



                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Bal. O/s : </label>
                            <span *ngIf="SmallScaleDetails.balance != null">{{SmallScaleDetails.balance}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">c) If no credit facilities availed so for, how the
                    funds are managed : </label>
                            <span *ngIf="SmallScaleDetails.managed != null">{{SmallScaleDetails.managed}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">d) Reasons for shifting over to NCB : </label>
                            <span *ngIf="SmallScaleDetails.reasons != null">{{SmallScaleDetails.reasons}}</span>



                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Details of assets owned and its present value : </label>
                            <span *ngIf="SmallScaleDetails.owned != null">{{SmallScaleDetails.owned}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Net Worth : </label>
                            <span *ngIf="SmallScaleDetails.worth != null">{{SmallScaleDetails.worth}}</span>



                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Details of security offered Primary security : </label>
                            <span *ngIf="SmallScaleDetails.primary != null">{{SmallScaleDetails.primary}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Details of additional security if any (with present value) : </label>
                            <span *ngIf="SmallScaleDetails.additional != null">{{SmallScaleDetails.additional}}</span>



                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Whether applicant is technically qualified / experienced ? if so furnish details : </label>
                            <span *ngIf="SmallScaleDetails.technically != null">{{SmallScaleDetails.technically}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Size of applicant's family</h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Adults : </label>
                            <span *ngIf="SmallScaleDetails.adults != null">{{SmallScaleDetails.adults}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Children : </label>
                            <span *ngIf="SmallScaleDetails.children != null">{{SmallScaleDetails.children}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Total : </label>
                            <span *ngIf="SmallScaleDetails.sstotal != null">{{SmallScaleDetails.sstotal}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Whether member of any other Co-operative Bank : </label>
                            <span *ngIf="SmallScaleDetails.member != null">{{SmallScaleDetails.member}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Name and Membership Nos. of Co-obligation /Surety/Guarantee with Occupation : </label>
                            <span *ngIf="SmallScaleDetails.obligation != null">{{SmallScaleDetails.obligation}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Name : </label>
                            <span *ngIf="SmallScaleDetails.ssname != null">{{SmallScaleDetails.ssname}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Age : </label>
                            <span *ngIf="SmallScaleDetails.ssage != null">{{SmallScaleDetails.ssage}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Residential Address : </label>
                            <span *ngIf="SmallScaleDetails.residentialadd != null">{{SmallScaleDetails.residentialadd}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Loan AMount : </label>
                            <span *ngIf="SmallScaleDetails.scaleloan != null">{{SmallScaleDetails.scaleloan}}</span>

                        </div>
                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Details of LPG Gas</h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label id="emaillabel ">a) Consumer No. : </label>
                        <span *ngIf="SmallScaleDetails.consumer != null">{{SmallScaleDetails.consumer}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-5 row ">
                        <label for="Email " id="emaillabel ">b) In the name of : </label>
                        <span *ngIf="SmallScaleDetails.relationship != null">{{SmallScaleDetails.relationship}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
            </div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">&nbsp;</div>


            <div class="col-md-12 " *ngIf="suretyloan">
                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Surety Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'"  class="btn btn-primary"  (click)="suretyloanclick()">Edit</button>
                    </div>
                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Purpose of loan: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.purofloan != null">{{suretyLoanDetails.purofloan}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Loan Amount: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.loama != null">{{suretyLoanDetails.loama}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Document produced in support of purpose of
                                loan: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.support != null">{{suretyLoanDetails.support}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Details of immovable property: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.furnish != null">{{suretyLoanDetails.furnish}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">No. of Dependencies: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.dependencies != null">{{suretyLoanDetails.dependencies}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Average monthly expenses of applicant and his
                                dependents: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.dependents != null">{{suretyLoanDetails.dependents}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">What is the repaying capacity of the applicant per month:
                                &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.repaying != null">{{suretyLoanDetails.repaying}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Details of Life Insurance policies held by the applicant:
                                &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.policies != null">{{suretyLoanDetails.policies}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Details of family members who can supplement the applicant's
                                income for repayment of loans: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.loaamt != null">{{suretyLoanDetails.loaamt}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Whether salary of the applicant is being remitted to The
                                National Co-op. Bank Ltd.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.bank != null">{{suretyLoanDetails.bank}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Designation of salary disbursing officer:
                                &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.outstanding != null">{{suretyLoanDetails.outstanding}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Loan Amount: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.value != null">{{suretyLoanDetails.value}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Income of applicant(for salary earners)
                </h3>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Basic pay Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.purchased != null">{{suretyLoanDetails.purchased}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">House rent allowance Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.inclusive != null">{{suretyLoanDetails.inclusive}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">City compensatory allowance Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.compensatory != null">{{suretyLoanDetails.compensatory}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Other allowance Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.allowance != null">{{suretyLoanDetails.allowance}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Deductions</h3>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Income tax Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.payment != null">{{suretyLoanDetails.payment}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Insurance premium Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.delivery != null">{{suretyLoanDetails.delivery}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">H.B.A. Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.hba != null">{{suretyLoanDetails.hba}}</span>

                    </div>
                </div>


                <div class="col-md-12 ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Other deductions Rs.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.applicant != null">{{suretyLoanDetails.applicant}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Other than Salary Earners(fill up the following columns)</h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Furnish full particulars and address where the business/profession
                                is carried out: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.particulars != null">{{suretyLoanDetails.particulars}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Nature of business/profession and when
                                established: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.partnersloan != null">{{suretyLoanDetails.partnersloan}}</span>
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Annual Turnover: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.turnover != null">{{suretyLoanDetails.turnover}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Annual Income: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.cashloan != null">{{suretyLoanDetails.cashloan}}</span>

                    </div>
                </div>


                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">IT No./PAN No.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.acnumber != null">{{suretyLoanDetails.acnumber}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Documentary evidence produced in support of nature
                                of business: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.balance != null">{{suretyLoanDetails.balance}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Is the applicant income tax/sales tax
                                assessee: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.assessee != null">{{suretyLoanDetails.assessee}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">In case of applicant is a non-earning member state how repayment
                                will be made: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.earning != null">{{suretyLoanDetails.earning}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Other sources of income, if any, furnish
                                details: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.modelname != null">{{suretyLoanDetails.modelname}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Other informations, if any: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.remarks != null">{{suretyLoanDetails.remarks}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> For Salaried Person</h3>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a. Where employed: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.employed != null">{{suretyLoanDetails.employed}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">b. Period of service completed: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.finishloan != null">{{suretyLoanDetails.finishloan}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> For Business Person</h3>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">a. Name of concern: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.concern != null">{{suretyLoanDetails.concern}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">b. Address where business is located: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.located != null">{{suretyLoanDetails.located}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">c. Nature of Activity: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.activity != null">{{suretyLoanDetails.activity}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">d. if assessed under: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.under != null">{{suretyLoanDetails.under}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">e. ANNUAL INCOME: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.annualincome != null">{{suretyLoanDetails.annualincome}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Relationship with borrower: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.borrower != null">{{suretyLoanDetails.borrower}}</span>

                    </div>
                </div>

                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Do you have any immovable property : If so, furnish full
                                particulars: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.property != null">{{suretyLoanDetails.property}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Assets with bank: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.assetsdata != null">{{suretyLoanDetails.assetsdata}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Whether drawing salary through The National Co-op.
                                Bank: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.drawing != null">{{suretyLoanDetails.drawing}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Additional Information (Education)
                </h3>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Name of the person for whose benefit Loan is being
                                availed: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.benefit != null">{{suretyLoanDetails.benefit}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Relationship: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.relationship != null">{{suretyLoanDetails.relationship}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Name of School /College in which the person is
                                studying: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.studying != null">{{suretyLoanDetails.studying}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Particulars of course i.e. Degree Course/Engineering/Medical
                                etc.: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.course != null">{{suretyLoanDetails.course}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Is the loan proposed to be utilised for payment of
                                donation (Furnish full particulars): &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.modelname != null">{{suretyLoanDetails.modelname}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">List of documentary evidence produced in support of
                                the purpose: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.evidence != null">{{suretyLoanDetails.evidence}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Full particulars of the deposits held with the bank: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.deposits != null">{{suretyLoanDetails.deposits}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Additional information if any: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.additional != null">{{suretyLoanDetails.additional}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Loan Account Number: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.account != null">{{suretyLoanDetails.account}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Membership Number: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.membership != null">{{suretyLoanDetails.membership}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">SB Account Number: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.numbersb != null">{{suretyLoanDetails.numbersb}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Age: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.age != null">{{suretyLoanDetails.age}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Office Address: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.Officedata != null">{{suretyLoanDetails.Officedata}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Department: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.department != null">{{suretyLoanDetails.department}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Sales Tax Number: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.salesdata != null">{{suretyLoanDetails.salesdata}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Annual Income: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.income != null">{{suretyLoanDetails.income}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Whether member of any other Co-operative Bank. If
                                so,particulars & liabilities, if any: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.operative != null">{{suretyLoanDetails.operative}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Whether related to any of the Board of
                                Directors: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.board != null">{{suretyLoanDetails.board}}</span>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Salary Particulars of the month of: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.salary != null">{{suretyLoanDetails.salary}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Applicant's and his/her family monthly Domestic
                                Expenses: &nbsp;</label>
                        <span *ngIf="suretyLoanDetails.domestic != null">{{suretyLoanDetails.domestic}}</span>

                    </div>
                </div>
                <br>
                <div class="col-md-12 row ">&nbsp;</div>
            </div>

          

            <div class="col-md-12 " *ngIf="vehicleloan">

                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Vehicle Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button  *ngIf="roleid!='2'" class="btn btn-primary" (click)="vehicleLoanclick()">Edit</button>
                    </div>
                </div>



                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 " *ngIf="vehicleLoanDetails.vehicaltype == 1">
                    <div class="col-md-12 ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">New Vehicle</h3>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">If applicant has no source of income ,state how the loan
                                will be repaid:</label>
                            <span *ngIf="vehicleLoanDetails.repaid != null">{{vehicleLoanDetails.repaid}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Monthly Instalment towards other loans with the
                                bank Rs:</label>
                            <span *ngIf="vehicleLoanDetails.instalment != null">{{vehicleLoanDetails.instalment}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel " style="text-align: initial;">Do you have driving licence? Is so enclose
                                copy;If not, who will drive the vehicle:</label>
                            <span *ngIf="vehicleLoanDetails.driving != null">{{vehicleLoanDetails.driving}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Furnish particulars of additional security:</label>
                            <span *ngIf="vehicleLoanDetails.furnish != null">{{vehicleLoanDetails.furnish}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Residential Address:</label>
                            <span *ngIf="vehicleLoanDetails.residential != null">{{vehicleLoanDetails.residential}}</span>


                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Partner / Proprietor / Directors Amount Share
                                in Rs.</label>
                            <span *ngIf="vehicleLoanDetails.directors != null">{{vehicleLoanDetails.directors}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Loan Amount :</label>
                            <span *ngIf="vehicleLoanDetails.loaamt != null">{{vehicleLoanDetails.loaamt}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Whether you have availed any other type of loan ? If so furnish details</h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">loan A/c Number:</label>
                            <span *ngIf="vehicleLoanDetails.loanac != null">{{vehicleLoanDetails.loanac}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Balance Outstanding:</label>
                            <span *ngIf="vehicleLoanDetails.outstanding != null">{{vehicleLoanDetails.outstanding}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel " style="text-align: initial;">Do you belong to SC/ST/BC Sick Muslim Christian Zoroastrian
                                Buddist Othrs:</label>
                            <span *ngIf="vehicleLoanDetails.christian != null">{{vehicleLoanDetails.christian}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Type of vehicle to be purchased/make:</label>
                            <span *ngIf="vehicleLoanDetails.purchased != null">{{vehicleLoanDetails.purchased}}</span>


                        </div>
                    </div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Cost of vehicle inclusive of KST/CST:</label>
                            <span *ngIf="vehicleLoanDetails.inclusive != null">{{vehicleLoanDetails.inclusive}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Whether the vehicle is Passenger/Goods
                                carrier:</label>
                            <span *ngIf="vehicleLoanDetails.passenger != null">{{vehicleLoanDetails.passenger}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel "> For what purpose the vehicle is used:</label>
                            <span *ngIf="vehicleLoanDetails.vehicleuse != null">{{vehicleLoanDetails.vehicleuse}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel " style="text-align: initial;">Name and address of the authorised dealer
                                through whom vehicle is being purchased:</label>
                            <span *ngIf="vehicleLoanDetails.authorised != null">{{vehicleLoanDetails.authorised}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">In whose favour payment is to be made:</label>
                            <span *ngIf="vehicleLoanDetails.payment != null">{{vehicleLoanDetails.payment}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel " style="text-align: initial;">Time likely to be taken for delivery of vehicle
                                place of delivery:</label>
                            <span *ngIf="vehicleLoanDetails.delivery != null">{{vehicleLoanDetails.delivery}}</span>


                        </div>
                    </div>
                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 " *ngIf="vehicleLoanDetails.vehicaltype == 2">
                    <div class="col-md-12 ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Second Hand Vehicle</h3>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">If applicant has no source of income ,state how the loan
                                will be repaid:</label>
                            <span *ngIf="vehicleLoanDetails.applicant != null">{{vehicleLoanDetails.applicant}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Monthly Instalment towards other loans with the
                                bank Rs:</label>
                            <span *ngIf="vehicleLoanDetails.towards != null">{{vehicleLoanDetails.towards}}</span>

                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel " style="text-align: initial;">Do you have driving licence? Is so enclose
                                copy;If not,who will drive the vehicle:</label>
                            <span *ngIf="vehicleLoanDetails.enclose != null">{{vehicleLoanDetails.enclose}}</span>


                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Furnish particulars of additional security:</label>
                            <span *ngIf="vehicleLoanDetails.particulars != null">{{vehicleLoanDetails.particulars}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Residential Address of Partners / Proprietor /
                                Directors:</label>
                            <span *ngIf="vehicleLoanDetails.residential != null">{{vehicleLoanDetails.residential}}</span>



                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Partner / Proprietor / Directors Amount Share
                                in Rs:</label>
                            <span *ngIf="vehicleLoanDetails.share != null">{{vehicleLoanDetails.share}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Loan Amount:</label>
                            <span *ngIf="vehicleLoanDetails.loaamt != null">{{vehicleLoanDetails.loaamt}}</span>


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Whether you have availed any other type of loan ? If so furnish details</h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Loan A/c Number:</label>
                            <span *ngIf="vehicleLoanDetails.loanac != null">{{vehicleLoanDetails.loanac}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Balance Outstanding:</label>
                            <span *ngIf="vehicleLoanDetails.outstanding != null">{{vehicleLoanDetails.outstanding}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel " style="text-align: initial;">Name of the present owner:</label>
                            <span *ngIf="vehicleLoanDetails.owner != null">{{vehicleLoanDetails.owner}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Vehicle Registration No:</label>
                            <span *ngIf="vehicleLoanDetails.registration != null">{{vehicleLoanDetails.registration}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Model and type of vehicle:</label>
                            <span *ngIf="vehicleLoanDetails.modelname != null">{{vehicleLoanDetails.modelname}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label for="Email " id="emaillabel ">Agreed Sale Price:</label>
                            <span *ngIf="vehicleLoanDetails.agreed != null">{{vehicleLoanDetails.agreed}}</span>

                        </div>
                    </div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Do you want any other vehicle? so furnish details:</label>
                            <span *ngIf="vehicleLoanDetails.furnish != null">{{vehicleLoanDetails.furnish}}</span>

                        </div>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

            </div>
       
            <div class="col-md-12 " *ngIf="fixeddepositloan">


                <div class="col-md-12 row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Fixed Deposit Loan</h2>
                    </div>
                    <div class="col-md-2">
                        <button *ngIf="roleid!='2'" class="btn btn-primary" (click)="fixeddepositLoanclick()">Edit</button>
                    </div>
                </div>


                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row">
                    <div class="col-md-1 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <label id="emaillabel">Applicant Name:</label>
                        <span *ngIf="fixedDeposit.applicantname != null">{{fixedDeposit.applicantname}}</span>

                    </div>
                    <div class="col-md-1 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <label id="emaillabel">Joint Applicant 1:</label>
                        <span *ngIf="fixedDeposit.jointApplicantone != null">{{fixedDeposit.jointApplicantone}}</span>

                    </div>
                    <div class="col-md-1 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <label id="emaillabel">Joint Applicant 2:</label>
                        <span *ngIf="fixedDeposit.jointApplicanttwo != null">{{fixedDeposit.jointApplicanttwo}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Mobile Number:</label>
                        <span *ngIf="fixedDeposit.jointNumber != null">{{fixedDeposit.jointNumber}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>

                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Type of Deposit:</label>
                        <span *ngIf="fixedDeposit.selcetedfdtypeString != null">{{fixedDeposit.selcetedfdtypeString}}</span>

                        
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Interest payout:</label>
                        <span *ngIf="fixedDeposit.selectedInterestpayoutString != null">{{fixedDeposit.selectedInterestpayoutString}}</span>

                        
                    </div>

                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Deposit amount:</label>
                        <span *ngIf="fixedDeposit.depositamount != null">{{fixedDeposit.depositamount}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Period of Deposit:</label>
                        <span *ngIf="fixedDeposit.periodofDeposit != null">{{fixedDeposit.periodofDeposit}}</span>

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Return on investment (ROI):</label>
                        <span *ngIf="fixedDeposit.roi != null">{{fixedDeposit.roi}}</span>

                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 row">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">PAN:</label>
                        <span *ngIf="fixedDeposit.depositpan != null">{{fixedDeposit.depositpan}}</span>

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Payment Details:</label>
                        <span *ngIf="fixedDeposit.paymentDetails != null">{{fixedDeposit.paymentDetails}}</span>

                    </div>


                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Maturity Instruction:</label>
                        <span *ngIf="fixedDeposit.selectedMaturityInstructionString != null">{{fixedDeposit.selectedMaturityInstructionString}}</span>

                        
                    </div>
                </div>
                <div class="col-md-12">&nbsp;</div>
                 <div class="col-md-12">&nbsp;</div>
            

            </div>

            <div id="downloadid">
            <div class="col-md-12 row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Uploaded Certificates</h2>
                </div>
                <div class="col-md-2">
                    <button *ngIf="roleid!='2'" class="btn btn-primary" (click)="certificatesclick()">Edit</button>
                </div>
            </div>

            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">&nbsp;</div>
            <div class="col-md-12 ">

                <div class="col-md-12 ">
                    <div *ngIf="certificatesexist">
                        <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="col-md-12 row">
                            <div class="col-md-1 row">&nbsp;</div>

                            <div class="col-md-2 row" *ngFor="let item of CertificateDisplay">
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'image/jpeg' ||
                         item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                    <img [src]="item.certificate" height="100" width="100" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/pdf' ||
                         item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                    <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                         item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                    <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                         item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                    <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                        <div>
                            <div class="col-md-12 row">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Uploaded Documents</h4>

                            <div class="col-md-12 row">
                                <div class="col-md-1 row">&nbsp;</div>

                                <div class="col-md-2 row" *ngFor="let item of certificates">
                                    <div *ngIf="item.actualCertificateName!=null  && (item.documentType == 'image/jpeg' ||
                         item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                        <img [src]="item.certificate" height="100" width="100" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/pdf' ||
                         item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                        <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                         item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                         item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div class="col-md-12 ">&nbsp;</div>
            </div>

        </div>
    </div>
</div>

 -->
