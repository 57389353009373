<app-header></app-header>
<div>&nbsp;</div>
<div>&nbsp;</div>

<body class="bodycolor">
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div id="menuslist">
        <p class="headtext">Dashboard</p>
        <div>&nbsp;</div>
        <div class="col-md-12 row" style="background-color: #ececec;">
            <div class="column">
                <div class="card cardcolor">
                    <br />
                    <p class="complaintext">Mailbox</p>
                    <br /> <br />
                    <div class="col-md-12 row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8" style="text-align: center;">
                            <button (click)="gotoinbox()" class="btn btnprop">View Messages</button>
                        </div>
                    </div>
                    <br /> <br />
                </div>
            </div>
    
            <div class="column">
                <div class="card cardcolor">
                    <br />
                    <p class="complaintext">In Progress Services</p>
                    <p class="complaintext">{{AssignedServicesList.length}}</p>
                    <div class="col-md-12 row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8" style="text-align: center;">
                            <button pageScroll href="#ShowAssignedDetails" class="btn btnprop"
                                (click)="AssignedServicesTrue()">View Details</button>
                        </div>
                    </div>
                    <br /> <br />
                </div>
            </div>
            <div class="column">
                <div class="card cardcolor">
                    <br />
                    <p class="complaintext">Completed Services</p>
                    <p class="complaintext">{{ResolvedServiceList.length}}</p>
                    <div class="col-md-12 row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8" style="text-align: center;">
                            <button pageScroll href="#ShowCompletedDetails" class="btn btnprop"
                                (click)="ResolvedServicesTrue()">View Details</button>
                        </div>
                    </div>
                    <br /> <br />
                </div>
            </div>
        </div>
        <div style="background-color: #ececec;">&nbsp;</div>
        <div style="background-color: #ececec;">&nbsp;</div>
        <div class="col-md-12 row" style="background-color: #ececec;">
    
    
            <div class="column">
                <div class="card cardcolor">
                    <br />
                    <p class="complaintext">Loans Forms</p>
                    <br /> <br />
                    <div class="col-md-12 row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8" style="text-align: center;">
                            <button class="btn btnprop" data-toggle="modal" data-target="#Fulldetails">View Details</button>
                        </div>
                    </div>
                    <br /> <br />
                </div>
            </div>
    
            <div class="column">
                <div class="card cardcolor">
                    <br />
                    <p class="complaintext">Branded Loan Forms</p>
                    <br /> <br />
                    <div class="col-md-12 row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8" style="text-align: center;">
                            <button class="btn btnprop" data-toggle="modal" data-target="#BankProducts">View
                                Details</button>
                        </div>
                    </div>
                    <br /> <br />
                </div>
            </div>
            <div class="column">
                <div class="card cardcolor">
                    <br />
                    <p class="complaintext">Commission Details</p>
                    <br /> <br />
                    <div class="col-md-12 row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8" style="text-align: center;">
                            <button (click)="gotoloanapprovedlist()" class="btn btnprop">View Details</button>
                        </div>
                    </div>
                    <br /> <br />
                </div>
            </div>
    
    
    
        </div>
        <div style="background-color: #ececec;">&nbsp;</div>
        <div style="background-color: #ececec;">&nbsp;</div>
        <div class="col-md-12 row" style="background-color: #ececec;">
            <div class="column">
                <div class="card cardcolor">
                    <br />
                    <p class="complaintext">Insurances Forms</p>
                    <br /> <br />
                    <div class="col-md-12 row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8" style="text-align: center;">
                            <button class="btn btnprop" data-toggle="modal" data-target="#Insurances">View Details</button>
                        </div>
                    </div>
                    <br /> <br />
                </div>
            </div>
            <div class="column">
                <div class="card cardcolor">
                    <br />
                    <p class="complaintext">Mutual Funds</p>
                    <br /> <br />
                    <div class="col-md-12 row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8" style="text-align: center;">
                            <button class="btn btnprop" (click)="GotoMutualFunds()">View Details</button>
                        </div>
                    </div>
                    <br /> <br />
                </div>
            </div>
            <div class="column">
                <div class="card cardcolor">
                    <br />
                    <p class="complaintext">Fixed deposit</p>
                    <br /> <br />
                    <div class="col-md-12 row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8" style="text-align: center;">
                            <button class="btn btnprop" (click)="GotoMutualFunds()">View Details</button>
                        </div>
                    </div>
                    <br /> <br />
                </div>
            </div>
    
        </div>
        <div style="background-color: #ececec;">&nbsp;</div>
        <div style="background-color: #ececec;">&nbsp;</div>
        <div class="col-md-12 row" style="background-color: #ececec;">
            <div class="column">
                <div class="card cardcolor">
                    <br />
                    <p class="complaintext">Pending Forms</p>
                    <p class="complaintext">{{PendingApplicationsList.length}}</p>
                    <div class="col-md-12 row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8" style="text-align: center;">
                            <button class="btn btnprop" pageScroll href="#ShowPendingApplications"
                                (click)="PendingFormTrue()">View Details</button>
                        </div>
                    </div>
                    <br /> <br />
                </div>
            </div>
    
    
    
        </div>
    </div>
    <div style="background-color: #ececec;">&nbsp;</div>
    <div style="background-color: #ececec;">&nbsp;</div>
    <!-- Insurances -->

    <div class="modal modal-angular fade" id="Insurances" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog modalsize" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">
                        <p style="color: darkslateblue;text-transform: uppercase;">INSURANCES
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="col-md-12 row">
                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Health Insurance</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoHealthInsurance()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Accident Insurance</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoAccidentInsurance()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>

                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Life Insurance</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoLifeInsurance()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Term Life Insurance</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoTermLifeInsurance()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Home Insurance</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoHomeInsurance()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Motor Insurance</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoMotorInsurance()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-warning waves-light" data-dismiss="modal"
                        mdbWavesEffect>Close</button>
                </div>
            </div>
        </div>
    </div>


    <!-- Bank Products -->

    <div class="modal modal-angular fade" id="BankProducts" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog modalsize" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">
                        <p style="color: darkslateblue;text-transform: uppercase;">LOANS
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="col-md-12 row">
                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Business Loan by ICICI Bank</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoBusinessLoan()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Small Scale Loan by Canara Bank</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoSmallScaleLoan()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Personal Loan by HDFC</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoSuretyLoan()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Vehicle Loan by SBI</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoVehicleLoanbySBI()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>

                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Education Loan by Indian Bank</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoEducationLoan()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>

                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Gold Loan by Vijaya Bank</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoGoldLoan()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>

                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Fixed Asset Loan by Andhra Bank</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoFixedAssetLoan()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>

                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Project Loan by ICICI</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoProjectLoan()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>

                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Home Loan by Canara Bank</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoHomeLoan()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-warning waves-light" data-dismiss="modal"
                        mdbWavesEffect>Close</button>
                </div>
            </div>
        </div>

    </div>

    <!-- Loans Popup -->

    <div class="modal modal-angular fade" id="Fulldetails" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog modalsize" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">
                        <p style="color: darkslateblue;text-transform: uppercase;">LOANS
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                </div>
                <div class="col-md-12 row">
                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Business Loan</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoBusinessLoan()" data-dismiss="modal"
                                        mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Small Scale Loan</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoSmallScaleLoan()" data-dismiss="modal"
                                        mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Personal Loan</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoSuretyLoan()" data-dismiss="modal"
                                        mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Vehicle Loan</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoVehicleLoan()" data-dismiss="modal"
                                        mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>

                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Education Loan</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoEducationLoan()" data-dismiss="modal"
                                        mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>

                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Gold Loan</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoGoldLoan()" data-dismiss="modal"
                                        mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>

                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Fixed Asset Loan</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoFixedAssetLoan()" data-dismiss="modal"
                                        mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>

                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Project Loan</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoProjectLoan()" data-dismiss="modal"
                                        mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>

                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Home Loan</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop" (click)="GotoHomeLoan()" data-dismiss="modal"
                                        mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>

                    <div style="width: 45%; padding: 2px 80px;">
                        <div class="card cardcolor">
                            <br />
                            <p class="complaintext">Fixed Deposit</p>
                            <br /> <br />
                            <div class="col-md-12 row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8" style="text-align: center;">
                                    <button class="btn btnprop"  (click)="GotoFixedDepositLoan()" data-dismiss="modal"
                                    mdbWavesEffect>Enter Here</button>
                                </div>
                            </div>
                            <br /> <br />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-warning waves-light" data-dismiss="modal"
                        mdbWavesEffect>Close</button>
                </div>
            </div>
        </div>

    </div>
    <div class="col-md-12 row" style="background-color: #ececec;" *ngIf="ShowAssignedDetails" id="ShowAssignedDetails">
        <div class="col-md-2"></div>
        <div class="col-md-8 card">
            <div>
                <br /> <br />
                <h3 style="text-align: center;">Assigned Services</h3>
                <br />
                <div class="col-md-12 row">
                    <div class="col-md-1"></div>

                    <div class="col-md-3 row">
                        <label>From Date</label>
                        <input type="date" class="form-control" id="Assignfromdate" name="Assignfromdate"
                            placeholder="Any Day" [(ngModel)]="Assignfromdate"
                            (change)="onChangeAssignfromdate(Assignfromdate)" />
                    </div>
                    <div class="col-md-1"></div>

                    <div class="col-md-3 row">
                        <label>To Date</label>
                        <input type="date" class="form-control" id="Assigntodate" name="Assigntodate"
                            placeholder="Any Day" [(ngModel)]="Assigntodate"
                            (change)="onChangeAssignTodate(Assigntodate)" />
                    </div>
                    <div class="col-md-1"></div>

                    <div class="col-md-3 row">
                        <div class="col-md-12">&nbsp;</div>

                        <button class="btn btnpropother" (click)="cancelAssign()">Cancel</button>
                    </div>
                </div>
                <br />
                <br />
                <div class="search-hero">
                    <input class="form-control" type="text" name="SearchAssignedServices"
                        [(ngModel)]="SearchAssignedServices" autocomplete="off" placeholder="&#61442; Search">
                </div>
                <br/>
                <div class="table-responsive">
                    <div class="col-md-12 row" id="btndiv">
                        <div class="col-md-9"></div>
                        <div class="col-md-1">
                            <button id ="btn" type="submit" (click)="print()">Print</button>
                        </div>
                        <!-- <div class="col-md-2">
                            <button id ="btn" type="submit" (click)="download()">Download</button>
                        </div> -->
                    </div><br/><br/>
                    <table class="table table-bordered" style="border-collapse: collapse;">
                        <tr style="font-size:17px">
                            <th>Sl No</th>
                            <th style="cursor: pointer;" (click)="sortbyserviceid()">Service Id</th>
                            <!--<th>Service Provider</th>-->
                            <th>Customer Number</th>
                            <th>Information</th>
                            <th>Service Time</th>
                            <th style="cursor: pointer;" (click)="sortbydate()">Service Date</th>
                        </tr>
                        <tr *ngFor="let com  of AssignedServicesList | LockFilter: SearchAssignedServices  | paginate: { itemsPerPage: 5, currentPage: p };let i = index"
                            style="font-size:16px;font-family: DINNextRoundedLTPro">
                            <td> {{ 5 * (p - 1) + (i+1) }}</td>
                            <td>{{com.complaintId}} </td>
                            <!--<td>{{com.serviceProvider}}</td>-->
                            <td>{{com.contactNumber}}</td>
                            <td>{{com.information}}</td>
                            <td>{{com.serviceTime}}</td>
                            <td>{{com.lastUpdated|date}}</td>
                        </tr>
                    </table>
                    <br />
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    <br /><br />
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12 row" style="background-color: #ececec;" *ngIf="ShowAssignedDetails1" id="testdiv">
        <div class="col-md-2"></div>
        <div class="col-md-8 card">
            <div>
                <br /> <br />
                <h3 style="text-align: center;">Assigned Services</h3>
                
                <div class="table-responsive">
                    <div class="col-md-12 row" id="btndiv">
                        <div class="col-md-9"></div>
                        <div class="col-md-1">
                            <button id ="btn" type="submit" (click)="print()">Print</button>
                        </div>
                        <!-- <div class="col-md-2">
                            <button id ="btn" type="submit" (click)="download()">Download</button>
                        </div> -->
                    </div><br/><br/>
                    <table class="table table-bordered" style="border-collapse: collapse;">
                        <tr style="font-size:17px">
                            <th>Sl No</th>
                            <th style="cursor: pointer;" (click)="sortbyserviceid()">Service Id</th>
                            <!--<th>Service Provider</th>-->
                            <th>Customer Number</th>
                            <th>Information</th>
                            <th>Service Time</th>
                            <th style="cursor: pointer;" (click)="sortbydate()">Service Date</th>
                        </tr>
                        <tr *ngFor="let com  of AssignedServicesList;let i = index"
                            style="font-size:16px;font-family: DINNextRoundedLTPro">
                            <td> {{ 5 * (p - 1) + (i+1) }}</td>
                            <td>{{com.complaintId}} </td>
                            <!--<td>{{com.serviceProvider}}</td>-->
                            <td>{{com.contactNumber}}</td>
                            <td>{{com.information}}</td>
                            <td>{{com.serviceTime}}</td>
                            <td>{{com.lastUpdated|date}}</td>
                        </tr>
                    </table>
                    <br />
                </div>
            </div>
        </div>
    </div>
    
    <div class="col-md-12 row" style="background-color: #ececec;" *ngIf="ShowCompletedDetails"
        id="ShowCompletedDetails">
        <div class="col-md-2"></div>
        <div class="col-md-8 card">
            <div>
                <br /> <br />
                <h3 style="text-align: center;">Completed Services</h3>
                <br />
                <div class="col-md-12 row">
                    <div class="col-md-1"></div>

                    <div class="col-md-3 row">
                        <label>From Date</label>
                        <input type="date" class="form-control" id="Completedfromdate" name="Completedfromdate"
                            placeholder="Any Day" [(ngModel)]="Completedfromdate"
                            (change)="onChangeCompletedfromdate(Completedfromdate)" />
                    </div>
                    <div class="col-md-1"></div>

                    <div class="col-md-3 row">
                        <label>To Date</label>
                        <input type="date" class="form-control" id="Completedtodate" name="Completedtodate"
                            placeholder="Any Day" [(ngModel)]="Completedtodate"
                            (change)="onChangeCompletedTodate(Completedtodate)" />
                    </div>
                    <div class="col-md-1"></div>

                    <div class="col-md-3 row">
                        <div class="col-md-12">&nbsp;</div>

                        <button class="btn btnpropother" (click)="cancelCompleted()">Cancel</button>
                    </div>
                </div>
                <br />
                <div class="search-hero">
                    <input class="form-control" type="text" name="SearchCompletedServices"
                        [(ngModel)]="SearchCompletedServices" autocomplete="off" placeholder="&#61442; Search">
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered" style="border-collapse: collapse;">
                        <tr style="font-size:17px">
                            <th>Sl No</th>
                            <th style="cursor: pointer;" (click)="sortbyserviceid()">Service Id</th>
                            <!--<th>Service Provider</th>-->
                            <th>Customer Number</th>
                            <th>Information</th>
                            <th>Service Time</th>
                            <th style="cursor: pointer;" (click)="sortbydate()">Service Date</th>
                        </tr>
                        <tr *ngFor="let com of ResolvedServiceList | LockFilter: SearchCompletedServices  | paginate: { itemsPerPage: 5, currentPage: p };let i = index"
                            style="font-size:16px;font-family: DINNextRoundedLTPro">
                            <td> {{ 5 * (p - 1) + (i+1) }}</td>
                            <td>{{com.complaintId}} </td>
                            <!--<td>{{com.serviceProvider}}</td>-->
                            <td>{{com.contactNumber}}</td>
                            <td>{{com.information}}</td>
                            <td>{{com.serviceTime}}</td>
                            <td>{{com.lastUpdated|date}}</td>
                        </tr>
                    </table>
                    <br />
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                    <br /><br />
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 row" style="background-color: #ececec;" *ngIf="ShowPendingApplications"
    id="ShowPendingApplications">
    <div class="col-md-2"></div>
    <div class="col-md-8 card">
        <div>
            <br /> <br />
            <h3 style="text-align: center;">Pending Applications</h3>
            <br />
            <div class="col-md-12 row">
                <div class="col-md-1"></div>

                <div class="col-md-3 row">
                    <label>From Date</label>
                    <input type="date" class="form-control" id="Pendingfromdate" name="Pendingfromdate"
                        placeholder="Any Day" [(ngModel)]="Pendingfromdate"
                        (change)="onChangePendingFormfromdate(Pendingfromdate)" />
                </div>
                <div class="col-md-1"></div>

                <div class="col-md-3 row">
                    <label >To Date</label>
                    <input type="date" class="form-control" id="Pendingtodate" name="Pendingtodate"
                         placeholder="Any Day" [(ngModel)]="Pendingtodate"
                        (change)="onChangePendingFormTodate(Pendingtodate)" />
                </div>
                <div class="col-md-1"></div>

                <div class="col-md-3 row">
                    <div class="col-md-12">&nbsp;</div>

                    <button class="btn btnpropother"
                            (click)="cancelPendingForm()">Cancel</button>
                </div>
            </div>
            <br/>
            <div class="search-hero">
                <input class="form-control" type="text" name="SearchPending" [(ngModel)]="SearchPending" autocomplete="off" placeholder="&#61442; Search">
              </div>
            <br />
            <div class="table-responsive">
                <table class="table table-bordered" style="border-collapse: collapse;">
                    <tr style="font-size:17px">
                        <th>Sl No</th>
                        <th>Product Type</th>
                        <th>Loan/Isurance Type</th>
                        <th style="cursor: pointer;" (click)="sortbyserviceid()">Service Id</th>
                        <th>Details</th>
                    </tr>
                    <tr *ngFor="let com of PendingApplicationsList | LockFilter: SearchPending   | paginate: { itemsPerPage: 5, currentPage: p };let i = index"
                        style="font-size:16px;font-family: DINNextRoundedLTPro">
                        <td> {{ 5 * (p - 1) + (i+1) }}</td>
                        <td>{{com.productType}} </td>
                        <td>{{com.loanorInsuranceType}}</td>
                        <td>{{com.tickedId}}</td>
                        <td (click)="GotoForm(com)"> <input class="btn btnprop" type="button" value="View" /></td>
                      
                    </tr>
                </table>
                <br />
                <pagination-controls (pageChange)="p = $event" ></pagination-controls>
                <br /><br />
            </div>
        </div>
    </div>
</div>
    <br /> <br />
</body>

<style>
    .modalsize {
        width: 950px;
        max-width: 950px;
        overflow-y: 50vh;
    }

    @media (max-width: 960px) {
        .modalsize {
            width: 450px;
            max-width: 650px;
            overflow-y: 50vh;
        }
    }

    @media (max-width: 575px) {
        .modalsize {
            width: 480px;
            max-width: 550px;
            overflow-y: 50vh;
        }
    }

    @media (max-width: 460px) {
        .modalsize {
            width: 380px;
            max-width: 450px;
            overflow-y: 50vh;
        }
    }

    @media (max-width: 390px) {
        .modalsize {
            width: 350px;
            max-width: 450px;
            overflow-y: 50vh;
        }
    }

    @media (max-width: 320px) {
        .modalsize {
            width: 310px;
            max-width: 450px;
            overflow-y: 50vh;
        }
    }
</style>