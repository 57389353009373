<app-header></app-header><br />
<br /><br /><br /><br />
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12 row">
    <div class="col-md-11">
        <p id="dispfile" style="zoom: 90%;">
            <pdf-viewer src="{{gotfile}}" [render-text]="true">
            </pdf-viewer>
        </p>
    </div>
</div>