<app-header></app-header>
<div>&nbsp;</div>
<div class="bodycolor">&nbsp;</div>
<div class="bodycolor">&nbsp;</div>
<div class="bodycolor">&nbsp;</div>
<div class="bodycolor">&nbsp;</div>
<mat-horizontal-stepper #stepper>
    <!-- <mat-step>
        <div>
            <button mat-button matStepperPrevious></button>
            <button mat-button (click)="stepper.reset()"></button>
        </div>
    </mat-step> -->
</mat-horizontal-stepper>
<mat-horizontal-stepper style="background-color :  #c2b6b6;
background-image :  linear-gradient(315deg, #c2b6b6 0%, #576574 74%);
" #stepper1>


<mat-step label="Step 1" state="details" style="text-align :  center;    height :  96%;width :  136%;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
        <div class="col-md-1"></div>
        <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10">

            <div class="homecard" id="signin" align="center" style=" margin-left :  0px;margin-right :  0px;margin-top :  1%">
                <h2 style="font-family :  DINNextRoundedLTPro;color :  #2b156f;">Personal Details</h2>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>

                <div class="col-md-12">
                    <div class="col-md-12 row">
                        <div class="col-md-2 row">&nbsp;</div>
                        <div class="col-md-3 row" style="display :  flex;">
                            <label id="emaillabel">First Name : </label>&nbsp;{{fname}}
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Middle Name : </label>&nbsp;{{mname}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Last Name : </label>&nbsp;{{lname}}

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Place of Birth : </label>&nbsp;{{placebirth}}
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Gender : </label>&nbsp;{{gender}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Marital Status : </label>&nbsp;{{marital}}
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel "> Mobile Number : </label>&nbsp;{{mobile}}

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Date of Birth : </label>&nbsp;{{dob | date :  'shortDate'}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Religion : </label>&nbsp;{{religion}}
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Caste : </label>&nbsp;{{caste}} Brahmin

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Qualification : </label>&nbsp;{{qualification}}


                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Occupation : </label>&nbsp;{{occupation}}


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-4 row ">
                            <label id="emaillabel "> Email : </label>&nbsp;{{email}}

                        </div>

                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Designation : </label>&nbsp;{{designation}}


                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <h3 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; "> Residential Address</h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Postal Code : </label>&nbsp;{{rPostal}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Country : </label>&nbsp;{{rCountry}} India
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> State : </label>&nbsp;{{rState}}
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>


                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">District : </label>&nbsp;{{rDistrict}}
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Address one : </label>&nbsp;{{rAddress1}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address two : </label>&nbsp;{{rAddress2}}

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <h3 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Permanent Address</h3>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Postal Code : </label>&nbsp;{{pPostal}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Country : </label>&nbsp;{{pCountry}} India
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> State : </label>&nbsp;{{pState}}
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">District : </label>&nbsp;{{pDistrict}}
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address one : </label>&nbsp;{{pAddress1}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address two : </label>&nbsp;{{pAddress2}}

                            </div>
                        </div>
                    </div>

                </div>
                <BR />
                <div class="col-md-12 row">
                    <div class="col-md-3 row" *ngIf="role=='1'">&nbsp;</div>
                    <div class="col-md-4 row" *ngIf="role!='1'">&nbsp;</div>
                    <div class="col-md-4 row" *ngIf="role=='1'">
                        <button (click)="EditPersonalDetails()" id="signinbutton">Edit</button>

                    </div>
                    <br />
                    <br />
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button (click)="signIn(stepper1)" id="signinbutton">NEXT</button>
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
            </div>
        </div>
    </div>
</mat-step>
<mat-step label="Step 2 " state="account" style="text-align :  center; height :  96%; width :  136%; ">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
        <div class="col-md-1 "></div>
        <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
            <div class="homecard " id="signin " align="center "
                style=" margin-left :  0px;margin-right :  0px;margin-top :  1% ">
                <h2 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Bank Details</h2>

                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>

                <div class="col-md-12 ">
                    <div class="container" style="margin-top: 5%">

                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Bank Account Type</th>
                                    <th>Bank Name</th>
                                    <th>Branch</th>
                                    <th>IFSC Code</th>
                                    <th>Cheque Facility</th>
                                    <!-- <th>Loan Facility</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of bankdetails">
                                    <td>{{data.banktypeid}}</td>
                                    <td>{{data.bankname}} </td>
                                    <td>{{data.branch}} </td>
                                    <td>{{data.ifscecode}} </td>
                                    <td>{{data.chequefacility}}</td>
                                    <!-- <td>{{data.loanfacility}}</td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-12 row ">


                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h3
                            style="font-family: DINNextRoundedLTPro;color: #2b156f;text-align: center;    margin-left: 42%; ">
                            Identity Details</h3>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Ration Card Number:</label>
                                &nbsp;{{rationCardNum}}

                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Aadhar
                                    Number:</label>&nbsp;{{aadharNum}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Voter ID
                                    Number:</label>&nbsp;{{voterId}}

                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">PAN Number:</label>&nbsp;{{panNum}}

                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label for="Email " id="emaillabel ">Service Tax
                                    Number:</label>&nbsp;{{serviceTaxNum}}

                            </div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Passport
                                    Number:</label>&nbsp;{{passportNum}}

                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Life Insurance
                                    Value:</label>&nbsp;{{lifeInsuranceValue}}

                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Is
                                    Confirm:</label>&nbsp;{{isConfirm}}

                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>



                        <BR />
                        <div class="col-md-12 row">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;margin-left: 15%; ">Uploaded
                            Documents</h3> <br>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;margin-left: 15%; ">Download
                            Documents</h5> <br>

                        <ol>
                            <li *ngIf="aadharFile"><a
                                    href="{{aadharFile}}">Aadhar</a></li>
                            <li *ngIf="bankStatements"><a
                                    href="{{bankStatements}}">Bank Statement</a></li>
                            <li *ngIf="gstCert"><a href="{{gstCert}}">GST</a></li>
                            <li *ngIf="itReturnsFile"><a
                                    href="{{itReturnsFile}}">ITR</a></li>
                            <li *ngIf="passportFile"><a
                                    href="{{passportFile}}">Passport</a></li>
                          
                            <li *ngIf="voterIdFile"><a href="{{voterIdFile}}">Voter
                                    ID</a></li>

                        </ol>
                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="col-md-2 row" *ngFor="let item of bankdocs">
                            <div *ngIf="item.documentType == 'image/jpeg' ||
                            item.documentType == 'image/png'" >
                                <img [src]="item.file" height="100" width="100" />
                                <div class="col-md-12 row">
                                    <div class="col-md-2 row">&nbsp;</div>
                                    <div class="col-md-10 row">
                                        <p style="text-align: center;">{{item.documentName}}</p>

                                    </div>

                                </div>
                            </div>
                            <div *ngIf=" (item.documentType == 'application/pdf' ||
                            item.documentType == 'pdf')">
                                <img src="assets/img/pdficon.png"
                                    style="width: 100px; height: 100px; cursor: pointer;" />
                                <div class="col-md-12 row">
                                    <div class="col-md-2 row">&nbsp;</div>
                                    <div class="col-md-10 row">
                                        <p style="text-align: center;">{{item.documentName}}</p>

                                    </div>

                                </div>
                            </div>
                            <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                            item.documentType == 'xlsx')">
                                <img src="assets/img/xls.png"
                                    style="width: 100px; height: 100px; cursor: pointer;" />
                                <div class="col-md-12 row">
                                    <div class="col-md-2 row">&nbsp;</div>
                                    <div class="col-md-10 row">
                                        <p style="text-align: center;">{{item.documentName}}</p>

                                    </div>

                                </div>
                            </div>
                            <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                            item.documentType == 'doc' || item.documentType == 'docx')"
                               >
                                <img src="assets/img/docx.png"
                                    style="width: 100px; height: 100px; cursor: pointer;" />
                                <div class="col-md-12 row">
                                    <div class="col-md-2 row">&nbsp;</div>
                                    <div class="col-md-10 row">
                                        <p style="text-align: center;">{{item.documentName}}</p>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <br>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                </div>
                <br>
                <div class="col-md-12 row">
                    <div class="col-md-3 row" *ngIf="role=='1'">&nbsp;</div>
                    <div class="col-md-4 row" *ngIf="role!='1'">&nbsp;</div>
                    <div class="col-md-4 row" *ngIf="role=='1'">
                        <button (click)="EditBankDetails()" id="signinbutton">Edit</button>

                    </div>
                    <br />
                    <br />
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button (click)="bankIn(stepper1)" id="signinbutton">NEXT</button>
                    </div>

                </div>
                <br>
                <br>
            </div>
        </div>
    </div>
</mat-step>
<mat-step label="Step 3 " state="account" style="text-align :  center; height :  96%; width :  136%; ">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
        <div class="col-md-1 "></div>
        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 ">
            <div class="homecard " id="eignin " align="center " style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">

                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Health Insurance</h2>

                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of person(s) proposed to be insured</h4>
                    <div class="col-md-12 ">&nbsp;</div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Sources of funds:</label>
                           <span *ngIf="sourcefund != null">{{sourcefund}}</span>
                           
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Monthly Income:</label>
                           <span *ngIf="monthincome != null">{{monthincome}}</span> 
                           
                        </div>
                    </div>
                    <div class="col-md-12 ">&nbsp;</div>


                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th style=" width: 22%;">Details</th>
                                <th>Member 1</th>
                                <th>Member 2</th>
                                <th>Member 3</th>
                                <th>Member 4</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td>
                                   <span *ngIf="detailsfirst != null">{{detailsfirst}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="detailsthrd != null">{{detailsthrd}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="detailsthird != null">{{detailsthird}}</span>
                                </td>
                                <td>
                                   <span *ngIf="detailsfour != null">{{detailsfour}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>DOB</td>
                                <td>
                                   <span *ngIf="dateofbirthFirst != null">{{dateofbirthFirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="dateofbirthsec != null">{{dateofbirthsec}}</span>
                                </td>
                                <td>
                                   <span *ngIf="dateofbirthtrd != null">{{dateofbirthtrd}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="dateofbirthfourth != null">{{dateofbirthfourth}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>Gender</td>
                                <td>
                                   <span *ngIf="genderfirst != null">{{genderfirst}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="gendersec != null">{{gendersec}}</span>
                                </td>
                                <td>
                                   <span *ngIf="genderthrd != null">{{genderthrd}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="genderfour != null">{{genderfour}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>Nationality</td>
                                <td>
                                   <span *ngIf="nationalityfirst != null">{{nationalityfirst}}</span> 
]                                </td>
                                <td>
                                   <span *ngIf="nationalitysec != null">{{nationalitysec}}</span>
                                </td>
                                <td>
                                   <span *ngIf="nationalitytrd != null">{{nationalitytrd}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="nationality != null">{{nationality}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>Relationship with proposer</td>
                                <td>
                                   <span *ngIf="proposertfirst != null">{{proposertfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="proposersec != null">{{proposersec}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="proposerthrd != null">{{proposerthrd}}</span>
                                </td>
                                <td>
                                   <span *ngIf="proposertforth != null">{{proposertforth}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Marital Status</td>
                                <td>
                                   <span *ngIf="statusfirst != null">{{statusfirst}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="statussecond != null">{{statussecond}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="statusthird != null">{{statusthird}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="statusfourth != null">{{statusfourth}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Occupation</td>
                                <td>
                                   <span *ngIf="insuranceone != null">{{insuranceone}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="insurancetwo != null">{{insurancetwo}}</span>
                                </td>
                                <td>
                                   <span *ngIf="insurancethrd != null">{{insurancethrd}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="insurancefour != null">{{insurancefour}}</span>
                                </td>

                            </tr>

                            <tr>
                                <td>Height(in cm)</td>
                                <td>
                                   <span *ngIf="othersfirst != null">{{othersfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="otherstwo != null">{{otherstwo}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="othersthree != null">{{othersthree}}</span>
                                </td>
                                <td>
                                   <span *ngIf="othersfour != null">{{othersfour}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Weight (in kg)</td>
                                <td>
                                   <span *ngIf="insuredone != null">{{insuredone}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="insuredsec != null">{{insuredsec}}</span>
                                </td>
                                <td>
                                   <span *ngIf="insuredthrd != null">{{insuredthrd}}</span>
                                </td>
                                <td>
                                   <span *ngIf="insuredfour != null">{{insuredfour}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>Diabetes</td>
                                <td>
                                   <span *ngIf="diabetesfirst != null">{{diabetesfirst}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="diabetessecond != null">{{diabetessecond}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="diabetesthird != null">{{diabetesthird}}</span>
                                </td>
                                <td>
                                   <span *ngIf="diabetesfourth != null">{{diabetesfourth}}</span> 
                                </td>

                            </tr>

                            <tr>
                                <td>Hypertension</td>
                                <td>
                                   <span *ngIf="nomineefirst != null">{{nomineefirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="nomineesecond != null">{{nomineesecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="nomineethird != null">{{nomineethird}}</span>
                                </td>
                                <td>
                                   <span *ngIf="nomineefourth != null">{{nomineefourth}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Respiratory disorder</td>
                                <td>
                                   <span *ngIf="relationfirst != null">{{relationfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="relationsecond != null">{{relationsecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="relationthird != null">{{relationthird}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="relationfourth != null">{{relationfourth}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>HIV/AIDS/STD</td>
                                <td>
                                   <span *ngIf="medicalfirst != null">{{medicalfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="medicalsecond != null">{{medicalsecond}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="medicalthird != null">{{medicalthird}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="medicalfourth != null">{{medicalfourth}}</span> 
                                </td>
                            </tr>
                            <tr>
                                <td>Liver disease </td>
                                <td>
                                   <span *ngIf="liverfirst != null">{{liverfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="liversecond != null">{{liversecond}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="liverthird != null">{{liverthird}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="liverfourth != null">{{liverfourth}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Cancer/Tumor</td>
                                <td>
                                   <span *ngIf="cancerfirst != null">{{cancerfirst}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="cancersecond != null">{{cancersecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="cancerthird != null">{{cancerthird}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="cancerfourth != null">{{cancerfourth}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>Heart disease</td>
                                <td>
                                   <span *ngIf="heartfirst != null">{{heartfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="heartsecond != null">{{heartsecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="heartthird != null">{{heartthird}}</span>
                                </td>
                                <td>
                                   <span *ngIf="heartfourth != null">{{heartfourth}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>Arthritis/Joint pain</td>
                                <td>
                                   <span *ngIf="arthritisfirst != null">{{arthritisfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="arthritissecond != null">{{arthritissecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="arthritisthird != null">{{arthritisthird}}</span>
                                </td>
                                <td>
                                   <span *ngIf="arthritisfourth != null">{{arthritisfourth}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Kidney Disease</td>
                                <td>
                                   <span *ngIf="kidneyfirst != null">{{kidneyfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="kidneysecond != null">{{kidneysecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="kidneythird != null">{{kidneythird}}</span>
                                </td>
                                <td>
                                   <span *ngIf="kidneyfourth != null">{{kidneyfourth}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>Paralysis/Stroke</td>
                                <td>
                                   <span *ngIf="paralysisfirst != null">{{paralysisfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="paralysissecond != null">{{paralysissecond}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="paralysisthird != null">{{paralysisthird}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="paralysisfourth != null">{{paralysisfourth}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Congenital Disease</td>
                                <td>
                                   <span *ngIf="congenitalfirst != null">{{congenitalfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="congenitalsecond != null">{{congenitalsecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="congenitalthird != null">{{congenitalthird}}</span>
                                </td>
                                <td>
                                   <span *ngIf="congenitalfourth != null">{{congenitalfourth}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Injurt</td>
                                <td>
                                   <span *ngIf="injurtfirst != null">{{injurtfirst}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="injurtsecond != null">{{injurtsecond}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="injurtthird != null">{{injurtthird}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="injurtfourth != null">{{injurtfourth}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>Other(Specify)</td>
                                <td>
                                   <span *ngIf="specifyfirst != null">{{specifyfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="specifysecond != null">{{specifysecond}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="specifythird != null">{{specifythird}}</span>
                                </td>
                                <td>
                                   <span *ngIf="specifyfourth != null">{{specifyfourth}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Details of Drugs/Medicine prescribed</td>
                                <td>
                                   <span *ngIf="drugsfirst != null">{{drugsfirst}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="drugssecond != null">{{drugssecond}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="drugsthird != null">{{drugsthird}}</span>
                                </td>
                                <td>
                                   <span *ngIf="drugsfourth != null">{{drugsfourth}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>Consume tobacco or alchohol?</td>
                                <td>
                                   <span *ngIf="tobaccofirst != null">{{tobaccofirst}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="tobaccosecond != null">{{tobaccosecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="tobaccothird != null">{{tobaccothird}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="tobaccofourth != null">{{tobaccofourth}}</span>
                                </td>

                            </tr>
                        </tbody>

                    </table>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Previous/Current health Insurance Details</h4>
                    <div class="col-md-12 ">&nbsp;</div>

                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th style=" width: 22%;">Details</th>
                                <th>Member 1</th>
                                <th>Member 2</th>
                                <th>Member 3</th>
                                <th>Member 4</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Name of insurer</td>
                                <td>
                                   <span *ngIf="personone != null">{{personone}}</span>
\                                </td>
                                <td>
                                   <span *ngIf="personsec != null">{{personsec}}</span>
                                </td>
                                <td>
                                   <span *ngIf="personsthrd != null">{{personsthrd}}</span>
                                </td>
                                <td>
                                   <span *ngIf="personsfourth != null">{{personsfourth}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Policy no.</td>
                                <td>
                                   <span *ngIf="policyfirst != null">{{policyfirst}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="policysec != null">{{policysec}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="policythrd != null">{{policythrd}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="policyfour != null">{{policyfour}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Policy period(from&to)</td>
                                <td>
                                   <span *ngIf="periodone != null">{{periodone}}</span>
                                </td>
                                <td>
                                   <span *ngIf="periodsecond != null">{{periodsecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="periodthrd != null">{{periodthrd}}</span>
                                </td>
                                <td>
                                   <span *ngIf="periodfourth != null">{{periodfourth}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>Sum Insured</td>
                                <td>
                                   <span *ngIf="sumfirst != null">{{sumfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="sumsecond != null">{{sumsecond}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="sumthird != null">{{sumthird}}</span>
                                </td>
                                <td>
                                   <span *ngIf="sumfourth != null">{{sumfourth}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>Previous Claim?</td>
                                <td>
                                   <span *ngIf="filedfirst != null">{{filedfirst}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="filedsecond != null">{{filedsecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="filedthird != null">{{filedthird}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="filedfourth != null">{{filedfourth}}</span> 
                                </td>
                            </tr>
                            <tr>
                                <td>Has any proposal of life, criticalillness or health insurance beendeclined, cancelled or charged ahigher premium?</td>
                                <td>
                                   <span *ngIf="healthfirst != null">{{healthfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="healthsecond != null">{{healthsecond}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="healththird != null">{{healththird}}</span>
                                </td>
                                <td>
                                   <span *ngIf="healthfourth != null">{{healthfourth}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Are any of the persons proposed for insurance covered under any other health insurance policy with the Company? (Including Critical Illness</td>
                                <td>
                                   <span *ngIf="coveredfirst != null">{{coveredfirst}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="coveredsecond != null">{{coveredsecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="coveredthird != null">{{coveredthird}}</span>
                                </td>
                                <td>
                                   <span *ngIf="coveredfourth != null">{{coveredfourth}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>Applying for portability</td>
                                <td>
                                   <span *ngIf="companyfirst != null">{{companyfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="companysecond != null">{{companysecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="companythird != null">{{companythird}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="companyfourth != null">{{companyfourth}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Name of the family Physician</td>
                                <td>
                                   <span *ngIf="infirmityfirst != null">{{infirmityfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="infirmitysecond != null">{{infirmitysecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="infirmitythird != null">{{infirmitythird}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="infirmityfourth != null">{{infirmityfourth}}</span> 
                                </td>

                            </tr>
                            <tr>
                                <td>Contact</td>
                                <td>
                                   <span *ngIf="contactfirst != null">{{contactfirst}}</span>
                                </td>
                                <td>
                                   <span *ngIf="contactsecond != null">{{contactsecond}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="contactthird != null">{{contactthird}}</span>
                                </td>
                                <td>
                                   <span *ngIf="contactfourth != null">{{contactfourth}}</span>
                                </td>

                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>
                                   <span *ngIf="Emailfirst != null">{{Emailfirst}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="Emailsecond != null">{{Emailsecond}}</span>
                                </td>
                                <td>
                                   <span *ngIf="Emailthird != null">{{Emailthird}}</span> 
                                </td>
                                <td>
                                   <span *ngIf="Emailfourth != null">{{Emailfourth}}</span>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <div class="col-md-12 row ">&nbsp;</div>


                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Premium Payment Details</h4>
                    <div class="col-md-12 row ">&nbsp;</div>


                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Cheque/DD/Credit Card/Debit Card:</label>
                           <span *ngIf="debitcard != null">{{debitcard}}</span> 
                           
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Cheque/DD no./Amount:</label>
                           <span *ngIf="chequeamt != null">{{chequeamt}}</span> 
                           
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Bank Name:</label>
                           <span *ngIf="bnkname != null">{{bnkname}}</span>
                            
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Cheque date:</label>
                           <span *ngIf="chequedate != null">{{chequedate}}</span>
                            
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Name of the payer:</label>
                           <span *ngIf="nmpayer != null">{{nmpayer}}</span> 
                          
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <br>
                <div class="col-md-12 row">
                    <div class="col-md-3 row" *ngIf="role=='1'">&nbsp;</div>
                    <div class="col-md-4 row" *ngIf="role!='1'">&nbsp;</div>
                    <div class="col-md-4 row" *ngIf="role=='1'">
                        <button (click)="EditHealthInsuranceDetails()" id="signinbutton">Edit</button>

                    </div>
                    <br />
                    <br />
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button (click)="healthinsurance(stepper1)" id="signinbutton">NEXT</button>
                    </div>

                </div>
                <br>
              <br /><br /><br />
            </div>
        </div>
    </div>
</mat-step>

<mat-step label="Step 4" state="Certificates" style="text-align: center; height: 96%; width: 136%; ">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
        <div class="col-md-1 "></div>
        <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
            <div class="homecard " id="signin " align="center "
                style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                <div *ngIf="certificatesexist">
                    <div class="col-md-12 row">&nbsp;</div>
                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                    <div class="col-md-12 row">&nbsp;</div>
                    <div class="col-md-12 row">
                        <!-- <div class="col-md-1 row">&nbsp;</div> -->

                        <div class="col-md-4 row" *ngFor="let item of CertificateDisplay">
                            <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'image/jpeg' ||
                         item.documentType == 'image/png')" >
                                <img [src]="item.certificate" height="100" width="100" />
                                <p style="text-align: center;"><br />{{item.fileName}}</p>
                                <!-- <div class="col-md-12 row">
                                    <div class="col-md-2 row">&nbsp;</div>
                                    <div class="col-md-10 row">
                                        <p style="text-align: center;">{{item.fileName}}</p>
                                    </div>
                                </div> -->
                            </div>
                            <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/pdf' ||
                         item.documentType == 'pdf')" >
                                <img src="assets/img/pdficon.png"
                                    style="width: 100px; height: 100px; cursor: pointer;" />
                                <p style="text-align: center;"><br />{{item.fileName}}</p>
                                <!-- <div class="col-md-12 row">
                                    <div class="col-md-2 row">&nbsp;</div>
                                    <div class="col-md-10 row">
                                        <p style="text-align: center;">{{item.fileName}}</p>
                                    </div>
                                </div> -->
                            </div>
                            <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                         item.documentType == 'xlsx')" >
                                <img src="assets/img/xls.png"
                                    style="width: 100px; height: 100px; cursor: pointer;" />
                                <p style="text-align: center;"><br />{{item.fileName}}</p>
                                <!-- <div class="col-md-12 row">
                                    <div class="col-md-2 row">&nbsp;</div>
                                    <div class="col-md-10 row">
                                        <p style="text-align: center;">{{item.fileName}}</p>
                                    </div>
                                </div> -->
                            </div>
                            <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                         item.documentType == 'doc' || item.documentType == 'docx')" >
                                <img src="assets/img/docx.png"
                                    style="width: 100px; height: 100px; cursor: pointer;" />
                                <p style="text-align: center;"><br />{{item.fileName}}</p>
                                <!-- <div class="col-md-12 row">
                                    <div class="col-md-2 row">&nbsp;</div>
                                    <div class="col-md-10 row">
                                        <p style="text-align: center;">{{item.fileName}}</p>
                                    </div>
                                </div> -->
                            </div>


                        </div>
                    </div>
                    <div class="col-md-12 row">&nbsp;</div>

                    <div *ngIf="enableothercertificates">
                        <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Uploaded Documents</h4>

                        <div class="col-md-12 row">

                            <div class="col-md-3 row" *ngFor="let item of allcertificates">
                                <div *ngIf="item.actualCertificateName!=null  && (item.documentType == 'image/jpeg' ||
                         item.documentType == 'image/png')" >
                                    <img [src]="item.certificate" height="100" width="100" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/pdf' ||
                         item.documentType == 'pdf')" >
                                    <img src="assets/img/pdficon.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                         item.documentType == 'xlsx')" >
                                    <img src="assets/img/xls.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                         item.documentType == 'doc' || item.documentType == 'docx')" >
                                    <img src="assets/img/docx.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 row">
                    <div class="col-md-5 row">&nbsp;</div>
                    <div class="col-md-4 row" *ngIf="role=='1'">
                        <button (click)="EditCertificatesDetails()" id="signinbutton">Edit</button>

                    </div>
                    <br />


                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 row" *ngIf="!showapprove">
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div class="col-md-2">&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div class="col-md-8 row card">
                        <br />
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Select Group/Customer</label>
                            <select (change)="onselecttouser($event.target.value)" class="form-control">
                                <option selected="selected" value="">Select Here</option>
                                <option selected="selected" value="1">Customer</option>
                                <option selected="selected" value="2">Group</option>
                            </select>
                        </div>
                        <br /><br />
                        <div class="row" *ngIf="operation == '1'" style="margin-top: -8%;">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <br />
                                <label for="amountPerYear">Select Status</label>
                                <select (change)="onSelectStatus($event.target.value)" class="form-control">
                                    <option value="">Select Status</option>
                                    <option *ngFor="let do of WFStatusList" value={{do.workflowStateId}}>
                                        {{do.workflowStateName}}
                                    </option>
                                </select>
                                <br /><br />
                            </div>
                        </div>
                        <br />
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngIf="operation == '1'">
                            <br />
                            <div>
                                <label for="amountPerYear">Comments</label>
                                <textarea type="text" class="form-control" placeholder="Enter Your Comments"
                                    name="comments" [(ngModel)]="comments"></textarea>
                            </div>
                            <br /><br />
                            <div>
                                <label for="amountPerYear">Upload File</label>
                                <input type="file" (change)="onChangeApproveFiles($event)" />
                            </div>
                            <br /><br />
                            <button type="button" class="btn btn-primary waves-light" mdbWavesEffect
                                (click)="SendVerificationtoCustomer()" style="width: 50%;">Send</button>
                            <br /><br />
                        </div>

                        <div class="row" *ngIf="operation == '2'" style="margin-top: -8%;">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <br />
                                <label for="amountPerYear">Select Status</label>
                                <select (change)="onSelectStatus($event.target.value)" class="form-control">
                                    <option value="">Select Status</option>
                                    <option *ngFor="let do of WFStatusList" value={{do.workflowStateId}}>
                                        {{do.workflowStateName}}
                                    </option>
                                </select>
                            </div><br />

                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngIf="operation == '2'">
                            <br />
                            <div>
                                <label for="amountPerYear">Comments</label>
                                <textarea type="text" class="form-control" placeholder="Enter Your Comments"
                                    name="comments" [(ngModel)]="comments"></textarea>
                            </div>
                            <br /><br />
                            <div>
                                <label for="amountPerYear">Upload File</label>
                                <input type="file" (change)="onChangeApproveFiles($event)" />
                            </div>
                            <br /><br />
                            <button type="button" class="btn btn-primary waves-light" mdbWavesEffect
                                (click)="SendVerificationtoApprover()" style="width: 50%;">Send</button>
                            <br /><br />
                        </div>

                    </div>
                    <br /><br />
                </div>
                <div class="col-md-12 row">
                    <div class="col-md-5 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <button *ngIf="showapprove" type="button" (click)="approve()"
                            id="signinbutton">Approve</button>

                    </div>
                    <br />

                </div>



                <div class="col-md-12 row ">&nbsp;</div>

            </div>
        </div>
    </div>
</mat-step>

<br />
<br />
<br />
<br />
<br />

    <!-- Icon overrides. -->
    <!-- #docregion override-icons -->
    <ng-template matStepperIcon="details">
        <mat-icon>account_circle</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="family">
        <mat-icon>family_restroom</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="account">
        <mat-icon>account_balance</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="homei">
        <mat-icon>home</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="business">
        <mat-icon>business</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="vehicalloan">
        <mat-icon>directions_car</mat-icon>
    </ng-template>

</mat-horizontal-stepper>