<br><br><br><br><br><br><br>
<div class="col-md-12 row">

    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-8">
        <span class="col-md-12 text-center"><h2>SOURCING AGREEMENT</h2></span>

        <p>This Agreement is made and entered into on this, ________________(DD MM YYYY) between Finspace Advisors Private Limited (FINSPACE), a company incorporated under the Companies Act, 1956 having its office at RH 53, Vighnaharta Enclave Sr No 46-5/1B
            shilottar malewadi panvel Maharashtra 410206 (hereinafter referred to as “FINSPACE”, which expression shall unless it be repugnant to the context or meaning hereof shall be deemed, to mean and include its successors and assigns) of the First
            Part </p>

        <span class="col-md-12 text-center"><h2>AND</h2></span>

        <!-- (Use for Individuals / sole Proprietor ) -->
        <p> Mr./Ms.______________ (Name) (opt from S/o/D/o/W/o) (Enter the Name) aged () sole proprietor of _______________, residing at (Mention the Address) (hereinafter referred to as “BUSINESS ASSOCIATE”, which expression shall unless it be repugnant
            to the context or meaning hereof shall be deemed, to mean and include its successors and assigns) of the Second Part
        </p>
        <!-- (For Partnership Firm) -->

        <p>
            M/S._________________________________ (Entity Name) with registered address at _____________________, represented by Mr/Ms________________ (Name & Designation of Partner) S/o/D/o/W/o_______________ (hereinafter referred to as “BUSINESS ASSOCIATE”, which
            expression shall unless it be repugnant to the context or meaning hereof shall be deemed, to mean and include its successors and assigns) of the Second Part
        </p>


        <!-- (For Company) -->
        <p> M/S._________________________________ (Entity Name) CIN_______________ with registered address at _____________________, represented by Mr/ Ms________________ (Name & Designation of Authorised Signatory) S/o/D/o/W/o_______________ (hereinafter
            referred to as “BUSINESS ASSOCIATE”, which expression shall unless it be repugnant to the context or meaning hereof shall be deemed, to mean and include its successors and assigns) of the Second Part
        </p>

        <p>SRICHID and the BUSINESS ASSOCIATE, are hereinafter individually referred to as “Party” and collectively referred to as "the Parties".</p>
        <strong>WHEREAS</strong>

        <ol type="A">
            <li>SRICHID is a Company engaged in the business of sourcing various financial products for Banks, NBFCs, Insurance companies registered with respective regulators, including but not limited to loans, Insurance and related products.</li>
            <li>BUSINESS ASSOCIATE is engaged in the business of providing various services, including acting as sourcing partner of third parties. </li>
            <li>SRICHID is desirous of appointing sourcing partners for sourcing all the products being intermediated by it for registered companies like Loans, Insurance, Deposits, Mutual Funds, Trading Accounts etc.(hereinafter referred to as the ‘Products’)
            </li>
            <li>BUSINESS ASSOCIATE has approached FINSPACE and represented that it has the requisite skills, knowledge, experience, expertise, infrastructure, and capability to act and function as a sourcing partner and efficiently and professionally to source
                the Applications for the Products. BUSINESS ASSOCIATE has also represented and assured FINSPACE that it has adequately trained and experienced persons/employees having the requisite skills and knowledge to effectively perform the above
                functions.
            </li>
            <li>Relying on the representations made by BUSINESS ASSOCIATE, FINSPACE has agreed to appoint BUSINESS ASSOCIATE as its sourcing partner for the Products & Services as particularly set out in Annexure A and BUSINESS ASSOCIATE has accepted such
                offer on the terms and conditions hereinafter appearing. </li>
        </ol>

        <h3>NOW THEREFORE THIS AGREEMENT WITNESSETH AS FOLLOWS:</h3>
        <ol class="boldol">

            <h4>
                <li> APPOINTMENT:</li>
            </h4>

            <ol type="a">
                <li>That in consideration of the terms and conditions hereinafter stated, FINSPACE has agreed to appoint BUSINESS ASSOCIATE as a sourcing partner for sourcing the Applications for the financial Products and for doing all such acts, deeds and
                    things as more specifically set out in Annexure A herein (hereinafter referred to as “the Services”). The role of the BUSINESS ASSOCIATE shall primarily be that of a facilitator and / or a catalyst and / or for sourcing interested
                    individuals/ firms for the Products and however, FINSPACE shall have the sole right to take decisions on the applications sourced by the BUSINESS ASSOCIATE. </li>
                <li>BUSINESS ASSOCIATE also acknowledges and agrees that, notwithstanding anything contained herein, FINSPACE shall be entitled to issue directions, guidelines, instructions, manuals and procedure etc. from time to time as to the manner in
                    which the BUSINESS ASSOCIATE’s duties and obligation under this Agreement shall be performed and the BUSINESS ASSOCIATE shall ensure strict compliance of all such guidelines and directions issued by FINSPACE.</li>
                <li>The appointment of BUSINESS ASSOCIATE as sourcing partner as above shall be on a non-exclusive basis and FINSPACE reserves the right to engage other partners including sourcing partners for providing similar services as contemplated herein.
                </li>

            </ol>


            <h4>
                <li>DUTIES, FUNCTIONS & OBLIGATIONS OF THE BUSINESS ASSOCIATE: </li>
            </h4>

            <p>The BUSINESS ASSOCIATE shall undertake and perform, apart from the general operations, the following specific services /operations, duties and functions:</p>
            <ol type="a">
                <li>Referral of the Customers who are interested in the Products;</li>
                <li>BUSINESS ASSOCIATE shall guide the Customer through FINSPACE website and mobile application; </li>
                <li>Enhance and simultaneously tap the potential market for the Products and/or the business opportunities for FINSPACE; </li>
                <li>Make every possible effort to check and verify the bonafides and the credentials of the Customers referred;</li>
                <li>Provide the name, address, mobile number and e-mail Id of the Customer who is interested in availing Products from FINSPACE while referring any Customer to FINSPACE;</li>
                <li>Obtain the authorization from the Customer to refer the above-mentioned details of the Customer to the FINSPACE in a form and substance acceptable to the FINSPACE;</li>
                <li>The referrals under this arrangement shall be purely based on personal interaction with the Customers and the brief to be explained to the Customers shall be pre-approved by the FINSPACE. The BUSINESS ASSOCIATE shall not advertise the
                    referral arrangement referenced herein through any media whatsoever;</li>
                <li>BUSINESS ASSOCIATE shall, along with the application duly filled and signed by the Customer, also submit a brief note on the background of the Customer and any recommendation from BUSINESS ASSOCIATE. If the BUSINESS ASSOCIATE is having
                    any interest in the Customer (relatives, friends, etc.,) such interest shall also be disclosed in the said sheet.</li>
                <li>Providing any assistance to FINSPACE, if required by FINSPACE, for the purpose of documentation in accordance to the policies and procedures of the Financial institutions empaneled with FINSPACE;</li>
                <li>Forward to FINSPACE and/or to any of its representatives all such papers and/or documents and/or details and/or clarifications and/or information as they may require from the Customer;</li>
                <li>Referral of Customers through BUSINESS ASSOCIATE shall be done strictly in accordance with the prevalent law and the procedures specified in writing by FINSPACE from time to time;</li>
                <li>Calling for papers/documents from the Customers and collecting & reviewing the papers/documents produced by the Customers pertaining to their personal details, income, employment or professional status, property(s) owned by them and, such
                    other information and documents as FINSPACE may require, and also to verify and to make all efforts to ensure that these documents and their copies (if any) are genuine documents/copies;</li>
                <li>Prominently display and/or exhibit and/or mail and/or distribute all information brochures and other material (including promotional material) relating to the Products;</li>
                <li>Attending to the Customer’s service requests, processing and forwarding them to FINSPACE and coordinating with FINSPACE and completing/attending to these requests and routing them/reverting to the Customers;</li>
                <li>Co-ordinate and communicate between the Customer and FINSPACE for all purposes & matters relating to the Products;</li>
                <li>Do such work promptly, efficiently and acknowledge to maintain the required confidentiality while providing the services under this Agreement and/or on having access to confidential or proprietary information of FINSPACE and related business
                    entities (the "Information"). The BUSINESS ASSOCIATE shall also authorize FINSPACE to conduct required due diligence including credit checks on BUSINESS ASSOCIATE which FINSPACE may consider necessary in its sole discretion;</li>
                <li>Such duties, functions and operations as may be authorized and required in writing by FINSPACE;</li>
                <li>The approval for Products shall always be at the sole discretion of FINSPACE and Financial Insitutions, and FINSPACE or the Financial Institutions shall without assigning any reason can reject the application for availing the financial
                    products. This fact should be clearly communicated to the Customers referred by BUSINESS ASSOCIATE;</li>
                <li>The BUSINESS ASSOCIATE shall perform the obligations in compliance with all relevant legislation, executive orders, regulations, codes, permits, certificates, and approvals, each as applicable to it and as amended from time to time. The
                    BUSINESS ASSOCIATE shall also comply, and shall ensure that all its employees, agents and representatives comply, with the policies and standards of FINSPACE, as amended or supplemented from time to time in FINSPACE’s reasonable discretion;</li>
                <li>In addition to the above, the BUSINESS ASSOCIATE shall at all times adhere to the code of conduct (“Code”) issued by FINSPACE as attached in Annexure B; </li>
                <li>To make all efforts to enhance the business reputation and goodwill of FINSPACE;</li>
                <li>The discretion of Financial Institutions empaneled with FINSPACE shall be final and binding in processing the loan applications;</li>
                <li>If BUSINESS ASSOCIATE is found to provide wrong information or commit any fraudulent activity, BUSINESS ASSOCIATE would make good all the losses incurred by FINSPACE due to such activity</li>
            </ol>

            <h4>
                <li>INDEPENDENT BUSINESS ASSOCIATE - PRINCIPAL TO PRINCIPAL</li>
            </h4>
            <p>This arrangement is on a Principal to Principal basis and does not create and shall not be deemed to create any association, partnership, joint venture, agency, employer-employee relationship between FINSPACE and BUSINESS ASSOCIATE. The BUSINESS
                ASSOCIATE shall provide the financial products sourcing services as an independent BUSINESS ASSOCIATE and the BUSINESS ASSOCIATE and/or their employees, staff etc. shall not be treated as an employee of FINSPACE and neither would they
                represent to be so. Other than to the extent provided herein, the BUSINESS ASSOCIATE is not authorized to represent FINSPACE and shall not, in any communication, written or otherwise, allude to such relationship with FINSPACE. Further,
                BUSINESS ASSOCIATE shall not enter into any agreement with any entity on behalf of FINSPACE. The BUSINESS ASSOCIATE confirms and acknowledges that it is an independent BUSINESS ASSOCIATE and FINSPACE shall be in no manner vicariously or
                in any other manner liable for any act or omission of the BUSINESS ASSOCIATE and/or any of its employees/personnel, agent etc. The BUSINESS ASSOCIATE’s personnel, employees, agents, etc., have no authority/right to bind FINSPACE in any
                manner.
            </p>

            <h4>
                <li>CLAIMS AND RESPONSIBILITIES</li>
            </h4>
            <ol type="a">
                <li>BUSINESS ASSOCIATE shall be liable for all acts of its employees, persons, staff, personnel etc (If applicable). that may cause any damage, loss or injury to FINSPACE and/or are considered as detrimental to the interest of FINSPACE and
                    FINSPACE at its sole discretion without prejudice to its other rights and contentions, may call upon the BUSINESS ASSOCIATE to compensate FINSPACE by way of damages to the extent of such loss or injury suffered by FINSPACE. The BUSINESS
                    ASSOCIATE will also be wholly liable/responsible for any breach/default either on the BUSINESS ASSOCIATE’s part and/or on the part of its personnel/employees, staff etc. and FINSPACE will be entitled to terminate this contract forthwith
                    for such breach/default without assigning any reason whatsoever to the BUSINESS ASSOCIATE and the BUSINESS ASSOCIATE shall indemnify FINSPACE from any/all loss or damage arising out of such breach or default.</li>
                <li>The role of the BUSINESS ASSOCIATE is primarily of a facilitator and or a catalyst and in no circumstances the BUSINESS ASSOCIATE or its employees shall offer or give commitments to the Customer.</li>
                <li>The BUSINESS ASSOCIATE shall ensure that all its employees/personnel, staff etc. who may carry out any function under and/or in respect the present agreement and/or appointment shall possess the minimum qualification and training as FINSPACE
                    may in its sole discretion require and no person who is objected to by FINSPACE shall be engaged by the BUSINESS ASSOCIATE. Further, the BUSINESS ASSOCIATE exclusively and absolutely undertakes all liabilities concerning its sourcing
                    personnel/staff, employees, or persons engaged to render the services hereunder and shall abide by all legal requirements/ statutory payments or entitlements that may be applicable to the engagement of such personnel and FINSPACE shall
                    not have any responsibility or liability nor shall be responsible or liable in any manner whatsoever on this account. Accordingly, no claim shall be raised against FINSPACE in terms and/or in relation to any of the terms and conditions
                    of such engagement. The BUSINESS ASSOCIATE shall indemnify FINSPACE and keep it indemnified and save harmless at all times against all claims, actions, demands, suits and proceedings including any claims or actions made by statutory
                    authorities whatsoever which may be brought, commenced or instituted or prosecuted against FINSPACE and also against all losses, expenses, costs, charges, damages incurred, paid, suffered by FINSPACE or which FINSPACE may be caused
                    to incur, suffer or pay by reason of or arising out of this contract or in relation to any acts and/or omission either on the BUSINESS ASSOCIATE’s part or on the part of the personnel to be engaged by the BUSINESS ASSOCIATE for the
                    purpose of this contract. The BUSINESS ASSOCIATE will also indemnify FINSPACE against all and any liability that may accrue and/or on any claims, actions or proceedings that may arise for any default on the BUSINESS ASSOCIATE’s part
                    on account of its failure to conform or comply with any obligation (whether statutory or otherwise) that the BUSINESS ASSOCIATE is required to observe in respect of and for executing this contract.</li>
                <li>It is expressly understood that none of the personnel appointed or engaged by the BUSINESS ASSOCIATE for carrying out the services hereunder shall be deemed to be or construed in any manner whatsoever as employee(s) or staff or workmen
                    of FINSPACE. FINSPACE shall under no circumstances be deemed to have any employer-employee relationship with them. In case of death or bodily injury or any other injury which may incur to any other staff /personnel/representatives
                    of the BUSINESS ASSOCIATE in the course of carrying out the services hereunder, FINSPACE shall not be liable in any manner whatsoever or bound to pay any compensation or otherwise be responsible in any manner, whatsoever, for the same.
                    The BUSINESS ASSOCIATE shall ensure that their employees/personnel/staff are adequately insured against any mishap or risk as above.</li>

            </ol>

            <h4>
                <li>TERM</li>
            </h4>
            <p>Unless sooner terminated as provided herein, this Agreement shall be valid for a period of 3 (Three) years from the date of execution hereof. Upon the completion of said 3 years, Parties may renew this Agreement for further period upon the
                terms and conditions as may be mutually agreed upon. </p>


            <h4>
                <li>FEES AND CHARGES/COMMISSION</li>
            </h4>
            <ol type="a">
                <li>The fee shall be success based and payable to BUSINESS ASSOCIATE only upon successful completion of product delivery of the Products or loan amount to Customer by FINSPACE. No fees shall be payable to BUSINESS ASSOCIATE in case a referral
                    does not result into product delivery.</li>
                <li>The payout will be applicable only for all new customer who have not transacted with FINSPACE. </li>
                <li>SRICHID shall pay the BUSINESS ASSOCIATE fees as mentioned on website/ mobile application. The BUSINESS ASSOCIATE confirms and agrees that FINSPACE shall be entitled to vary/alter/amend the fee structure & policy at its sole discretion
                    at any stage without any notice to the BUSINESS ASSOCIATE and the BUSINESS ASSOCIATE shall be bound by such Fees structure. Further, FINSPACE shall also be entitled to set up/assign targets for the BUSINESS ASSOCIATE and fix such fees
                    structures that are based on the business generated and/or target achievement by the BUSINESS ASSOCIATE. </li>
                <li>The fees structure is inclusive of all taxes, deductions, cess, levies, duties etc. (whether past, present and/or future) and all the payments to the BUSINESS ASSOCIATE shall be made after making statutory deductions, if any, under the
                    relevant laws. FINSPACE shall also have right to set off, deduct and recover from the fees and/or charges and/or from any other sum payable to the BUSINESS ASSOCIATE, any and all amounts which may or become payable by the BUSINESS
                    ASSOCIATE to FINSPACE even if the same is in respect of any indemnity offered by the BUSINESS ASSOCIATE.</li>
                <li>In addition to the fees for financial products referrals, BUSINESS ASSOCIATE shall be entitled to performance bonus as set out by company from time to time on its website (If available). FINSPACE doesnot guarantee any such bonus and it
                    shall be only at sole discretion of FINSPACE </li>
                <li>BUSINESS ASSOCIATE shall raise the invoice to FINSPACE after completion of delivery of services, which will be reflected on the mobile application. FINSPACE shall not be liable to pay invoices if, such invoices are not raised within the
                    period of two months from the date of product delivery.</li>
                <li>In case of any discrepancies in the invoice, FINSPACE shall raise such discrepancies within 3 working days and the Parties shall use its best efforts to reconcile such discrepancies with 15 days and in such circumstances the fee to the
                    extent of dispute raised, shall become payable only after resolution of the dispute raised.</li>


            </ol>
            <h4>
                <li>BUSINESS ASSOCIATE’s REPRESENTATIONS AND WARRANTIES</li>
            </h4>
            <ol type="a">
                <p>The BUSINESS ASSOCIATE hereby represents warrants and confirms as hereunder: -</p>
                <li>That the BUSINESS ASSOCIATE has full capacity, power, and authority to enter into this agreement and the agreement so executed and delivered would be legally binding and the BUSINESS ASSOCIATE would be duly bound by it.</li>
                <li>That the BUSINESS ASSOCIATE is fully entitled to carry out and observe all its duties, obligations and all the terms and conditions of the present agreement and is not precluded for any reason whatsoever from entering into the present
                    agreement and/or carrying out its obligations and observing the terms and conditions of the present agreement or any part thereof.</li>
                <li>That the BUSINESS ASSOCIATE has all the necessary permissions, statutory approvals for offering the service contemplated herein and for carrying on its business, consents etc. required for the purpose of entering into the present agreement
                    and for the purpose of observing its obligations, duties, covenants etc. under the present agreement. That the BUSINESS ASSOCIATE further represents and assures FINSPACE that it would also ensure it would continue to have the full
                    capacity, power and authority to carry out and perform all its duties and obligations as contemplated herein and if required would take all necessary and/or required approvals for and at all times during the continuance of this agreement.</li>
                <li>That the BUSINESS ASSOCIATE has the necessary skill, knowledge, experience, expertise, adequate capital, competent personnel, infrastructure, computer and other systems and procedural know-how and capability to perform its obligations
                    in accordance with the terms of this Agreement and to the complete satisfaction of FINSPACE.</li>
                <li>The execution of this Agreement and providing services by the BUSINESS ASSOCIATE to FINSPACE, does not and will not violate, breach, and contravene any of the terms and conditions of any Agreement entered with any third party/ies.</li>
                <li>That the BUSINESS ASSOCIATE has complied and shall continue to comply with all laws including to labour enactments, rules etc. and has obtained all necessary permissions / licenses /authorizations required under law from all concerned
                    authorities, departments etc. including from the central, state and local authorities and has also obtained all required permissions / licenses for carrying out its obligations under this agreement.</li>
                <li>That the BUSINESS ASSOCIATE (including its directors/partners/management/officers/employees etc. and their family members) has no pecuniary or other interests (in any manner whatsoever) which may be prejudicial, conflicting to the interest
                    of FINSPACE and/or the performance of the present agreement.</li>
            </ol>

            <h4>
                <li>BUSINESS ASSOCIATE’s CONVENANTS</li>
            </h4>
            <p>The BUSINESS ASSOCIATE hereby agrees, undertakes, and confirms as under:</p>
            <ol type="a">
                <li>That the BUSINESS ASSOCIATE shall carry out all its obligations and functions under this agreement strictly in accordance with all the guidelines prescribed by FINSPACE in connection with this agreement from time to time. </li>
                <li>That the BUSINESS ASSOCIATE shall immediately notify FINSPACE in writing of any event which may result in and/or which may give reason to believe that there may be work stoppage, slowdown, labour dispute, strike, any labour disruption
                    or any other such impediment(s) or disruptions in the due performance of the duties, functions and obligations of the BUSINESS ASSOCIATE under this Agreement. The BUSINESS ASSOCIATE shall forthwith submit a report of the events resulting
                    in stoppage of work to FINSPACE. The BUSINESS ASSOCIATE agrees that notwithstanding anything contained in this agreement in the event of any of the eventualities mentioned herein above FINSPACE shall have a right to terminate this
                    Agreement by giving fifteen days’ notice to the BUSINESS ASSOCIATE.</li>
                <li>That the BUSINESS ASSOCIATE shall not at any time use or attempt to use name of FINSPACE or logo for any purpose including for the purpose of this agreement unless prior written permission/authorization from FINSPACE has been obtained.
                    Further all stationery, Boards, Advertising Material, letterheads, envelopes etc. of the BUSINESS ASSOCIATE shall require the prior approval of FINSPACE and shall also comply with the guidelines issued by FINSPACE.</li>
                <li>That the BUSINESS ASSOCIATE shall ensure that its employees/personnel shall at all times conduct themselves within the parameters of laws and shall not commit, abet or permit the commission of any illegal act while working in the course
                    of this Agreement and in the event of any illegal act being committed or abetted, the BUSINESS ASSOCIATE shall be solely liable for the same and shall also be inter-alia liable to pay to FINSPACE any loss and/or compensation for loss
                    of goodwill/ business suffered by FINSPACE.</li>
                <li>That the BUSINESS ASSOCIATE shall obtain all the requisite/required license and/or permission and/or authorizations under all the applicable laws and keep the same valid and subsisting at all times always during the tenure of the present
                    Agreement.
                </li>
                <li>That the BUSINESS ASSOCIATE shall maintain all the registers and records required to be maintained and, in the manner required, under the various labour enactments and rules framed there under.</li>
                <li>That the BUSINESS ASSOCIATE shall ensure:
                    <ol type="i">
                        <li>No personnel employed by the BUSINESS ASSOCIATE shall commit any misconduct or act(s) in contravention of any of the provisions of this agreements or instructions issued by FINSPACE from time to time and/or commit any fraud or
                            is involved in any criminal or civil case. If any personnel commit any act and/or omission and/or misconduct or is any manner involved in a criminal and/or civil case or is guilty of acting in contravention of any provision
                            of this agreement or any law the BUSINESS ASSOCIATE shall be solely and entirely responsible for the same and shall further ensure that no liability and/or loss and/or claim etc. comes upon FINSPACE.</li>
                        <li>The BUSINESS ASSOCIATE and/or any of its employees or any other person employed by the BUSINESS ASSOCIATE for the purpose of this agreement shall not use any immoral, unethical, illegal or unlawful means in performing its obligations
                            under this agreement. In case any such means are employed by the BUSINESS ASSOCIATE and/or its employees or any other person, then the BUSINESS ASSOCIATE alone shall be responsible for the same, <br>Further, the BUSINESS ASSOCIATE
                            agrees to indemnify and keep FINSPACE indemnified and safe from the consequence of any such act of the personnel and also against any suits, claims, demands, petitions, actions, proceeding etc. that may be filed/initiated against
                            FINSPACE by any person for any reason whatsoever as a result which FINSPACE has suffered or is likely to suffer any loss, damages, claims, costs, charges, expenses, etc.</li>
                        <li>That the personnel employed by the BUSINESS ASSOCIATE shall not have any claim whatsoever on FINSPACE and shall not raise any industrial dispute, either directly or indirectly, with or against FINSPACE, in respect of any of the
                            service condition or otherwise.</li>
                        <li>That it is further expressly clarified and agreed that in case of death or bodily injury to any such personnel appointed by the BUSINESS ASSOCIATE in some course of carrying out of the services of this agreement, FINSPACE shall
                            not be liable or bound to pay any monetary compensation or otherwise be responsible in any way, whatsoever.</li>
                        <li>That the BUSINESS ASSOCIATE shall immediately notify FINSPACE in writing if any of its employee or any other person engaged by the BUSINESS ASSOCIATE has committed breach of any of the employment agreement and/or has committed
                            any act amounting to moral turpitude and/or has been arrested by the police and/or removed from the employment of the BUSINESS ASSOCIATE and/or committed any act which in the opinion of the BUSINESS ASSOCIATE affects the integrity
                            of the person. FINSPACE shall have the right to call upon the BUSINESS ASSOCIATE to replace any personnel of the BUSINESS ASSOCIATE who in sole opinion of FINSPACE is jeopardizing the interest of FINSPACE and the BUSINESS ASSOCIATE
                            shall forthwith comply with the demand of FINSPACE.</li>
                        <li>That the BUSINESS ASSOCIATE shall procure from all its employees (whether presently working with the BUSINESS ASSOCIATE or employed by it in the future) and from such other staff as FINSPACE may require, an undertaking not to disclose
                            to any third party any confidential information or knowledge concerning the business of FINSPACE and take such steps at the BUSINESS ASSOCIATEs own expenses as FINSPACE may direct in order to enforce or restrain any breach
                            of the terms of any such undertaking.</li>
                        <li>That the BUSINESS ASSOCIATE undertakes to execute such other documents as may be required by FINSPACE.</li>
                        <li>That the BUSINESS ASSOCIATE and its employees, staff, agents etc. shall not in any manner forge / fabricate / manipulate / tamper any documents submitted by the potential customer for availing products from FINSPACE. The BUSINESS
                            ASSOCIATE shall also be liable in the event of submission of any such fake/forged document.</li>
                        <li>That the BUSINESS ASSOCIATE, shall not in any manner and in any circumstance accept any separate fee/charges/commission from the potential Customer in any manner whatsoever (whether directly or indirectly or in kind) at any point
                            of time including but not limited to the time of sourcing or even after disbursal, failing which the BUSINESS ASSOCIATE will be liable for any losses/damages incurred and for the loss of business/ goodwill of FINSPACE and FINSPACE
                            shall be entitled to file and/or prosecute against the BUSINESS ASSOCIATE both civil and criminal remedies.</li>
                        <li>That in the event of any violation/non-compliance/breach of any of the representations, covenants, warranties, clauses or sub- clauses etc. of this agreement, the BUSINESS ASSOCIATE shall be liable to indemnify and immediately
                            pay on demand all the losses/damages incurred and also for the loss of business/goodwill suffered by FINSPACE and also keep FINSPACE indemnified and safe from all actions (including legal action) which are a consequence (whether
                            direct or indirect) of the acts and/or omissions of the BUSINESS ASSOCIATE and/or its employees, staff/personnel etc.</li>
                        <li>That the BUSINESS ASSOCIATE and its personnel shall not claim any lien on the amount collected by the BUSINESS ASSOCIATE even if any amount is due and payable by FINSPACE to the BUSINESS ASSOCIATE. Further the BUSINESS ASSOCIATE
                            shall hold all such monies/amounts that may come into its possession in trust for FINSPACE.</li>
                        <li>That the BUSINESS ASSOCIATE would maintain all the required records, registers, accounts, documents and in such manner that FINSPACE may require and FINSPACE and/or its officers, agents, employees etc. shall be fully authorized
                            and entitled to enter and call for and/or inspect the office of the BUSINESS ASSOCIATE and also such records at all times and even without any prior notice and the BUSINESS ASSOCIATE and its employees/staff/personnel etc. shall
                            extend full co-operation and assistance for such inspection.</li>
                        <li>That the BUSINESS ASSOCIATE would not do/commit and act and/or omission, which in the sole and absolute opinion of FINSPACE would be in conflict with the interest of FINSPACE.</li>
                    </ol>
                </li>
            </ol>
            <h4>
                <li>CONFIDENTIALITY AND NON-DISCLOSURE</li>
            </h4>
            <ol type="a">
                <li>All details, documents, data, papers, statements, business/ Customer information and FINSPACE’s practices and trade secrets (hereinafter referred to as ‘confidential information’) which may come to the knowledge and/or be communicated
                    to the BUSINESS ASSOCIATE and / or its employees shall be treated as absolutely confidential and privileged; and the BUSINESS ASSOCIATE irrevocably agrees and undertakes and ensures that the BUSINESS ASSOCIATE and all its employees
                    shall keep the same secret and confidential and not disclose the same, in whole or in part, to any person without the prior written permission of FINSPACE nor shall use or allow to be used any information except to the very limited
                    extent that may be required for the due performance of the BUSINESS ASSOCIATE’s obligation hereunder. The BUSINESS ASSOCIATE hereby specifically agrees to indemnify and keep FINSPACE indemnified, safe and harmless at all times against
                    all and for any consequences that may arise out of any breach of this undertaking by the BUSINESS ASSOCIATE and/or its employees and shall immediately reimburse and pay to FINSPACE on demand all damages, loss, cost, expenses or any
                    changes that FINSPACE may suffer, incur or pay in connection therewith.</li>
                <li>Take all necessary action to protect the confidential information against misuse, loss, destruction and/or alteration and to maintain all such information in the manner and mode specified and/or required by FINSPACE.</li>
                <li>Not to misuse or permit the misuse (whether directly or indirectly) and/or commercially exploit the confidential information for economic or any other benefit.</li>
                <li>Not to make or retain any copies or record of any confidential information submitted by FINSPACE or Customer other than as may be required for the performance of the BUSINESS ASSOCIATE ‘s obligation under this Agreement. Further, the BUSINESS
                    ASSOCIATE agrees and undertakes to destroy all such copies and/or record after the purpose of the same has been achieved and/or when FINSPACE so requires.</li>
                <li>The BUSINESS ASSOCIATE shall promptly notify FINSPACE of any unauthorized or improper use or disclosure (that may come its knowledge) of the confidential information.</li>
                <li>Return all the information, material, records, material etc. which is in the custody of the BUSINESS ASSOCIATE at the end/termination of the present Agreement.</li>
                <li>The BUSINESS ASSOCIATE hereby unconditionally agrees and undertakes that it and/or it’s personnel shall not disclose or publish the terms and conditions of this agreement or of any information submitted by FINSPACE or otherwise coming
                    to its/their knowledge under this agreement to any third party unless such disclosure is required by law.</li>
            </ol>
            <h4>
                <li>INDEMNITY </li>
            </h4>
            <p>The BUSINESS ASSOCIATE hereby agrees and undertakes to indemnify and keep FINSPACE indemnified & safe at all times always against: -</p>
            <ol type="a">
                <li>All losses/es, misappropriation, misuse, or damage of any of the documents which may be in possession of the BUSINESS ASSOCIATE or its personnel or within the control of the BUSINESS ASSOCIATE or its personnel.</li>
                <li>Any or all claims, liabilities, damages, losses, costs, charges, expenses, proceedings, and actions of any nature whatsoever made or instituted against FINSPACE and/ or any customer directly or indirectly by reason of-</li>
                <li>Any wrongful, incorrect, dishonest, criminal, fraudulent or negligent work, misfeasance, disregard of duties by the BUSINESS ASSOCIATE or its personnel and/or</li>
                <li>Any theft, robbery, fraud or wrongful act or omission by the BUSINESS ASSOCIATE or its personnel: and/or</li>
                <li>Any breach of any of the provisions of the agreement or any of the annexure(s) by the BUSINESS ASSOCIATE or its personnel and/or</li>
                <li>Any loss or damage caused to FINSPACE or its Customer for any default or mistake or error or commission or omission and/or any act which is not authorized or done in accordance with the procedure laid down by FINSPACE and/or</li>
                <li>Any claim or loss due to any mis-representation or false commitment made by the BUSINESS ASSOCIATE or its personnel/ employees or its agents during the course of acquiring business for and on behalf of FINSPACE.</li>
                <li>All indemnities shall be in addition to one another and shall also survive expiry or termination of this Agreement and the BUSINESS ASSOCIATE shall continue to be liable under all the indemnities contained in the present Agreement (whether
                    in the present clause or otherwise).</li>
            </ol>
            <h4>
                <li>TERMINATION</li>
            </h4>
            <p>The BUSINESS ASSOCIATE shall terminate this arrangement during the initial term period or during the extended term by giving 30 days prior written notice to FINSPACE specifying the purpose for termination of this arrangement. Without prejudice
                to the rights and remedies available to FINSPACE under this Agreement, FINSPACE may terminate this Agreement without any cause by issuing a written notice to BUSINESS ASSOCIATE. </p>
            <h4>
                <li>CONSEQUENCES OF TERMINATION</li>
            </h4>
            <p>That, upon the termination or expiration of this Agreement for any reason, the BUSINESS ASSOCIATE shall:</p>
            <ol type="a">
                <li>Immediately cease to operate as the BUSINESS ASSOCIATE and not hold/represent itself in any manner as the BUSINESS ASSOCIATE of FINSPACE and refrain from any act and/or omission that would or may indicate any relationship between it and
                    FINSPACE.
                </li>
                <li>Return to FINSPACE forthwith all documents, confidential financial, commercial, customer and business statements or information, computer floppies/diskettes/storage devices and all other materials and documents that might may have come
                    into its possession whether the same are confidential or otherwise.</li>
                <li>The expiration or termination of this Agreement shall be without prejudice to the accrued rights of the parties.</li>
            </ol>
            <h4>
                <li>WARRANTIES WITHOUT AUTHORITY</li>
            </h4>
            <p>That, the BUSINESS ASSOCIATE shall make no statement, representation or claim and shall give no warranty to any person in respect of the business of FINSPACE except to the very limited extent that may be specifically authorized in writing
                by FINSPACE.</p>
            <h4>
                <li>ASSIGNMENTS AND SUB-CONTRACTING</li>
            </h4>
            <p>The BUSINESS ASSOCIATE shall itself perform its obligation under this agreement and the BUSINESS ASSOCIATE shall not assign, transfer or sub-contract any of its rights and obligation under this agreement or any part thereof to anyone, expect
                with prior written permission of FINSPACE. However, FINSPACE shall be entitled to assign/ transfer its rights and benefits under this agreement, to any third party/person/entity etc. as it may require.</p>
            <h4>
                <li>NOTICE</li>
            </h4>
            <p>That any notice or request or communication to be given or made by a party to the other shall be in writing and shall be sent to the concerned party(s) at the address mentioned above in the present agreement.</p>
            <h4>
                <li>COMPENSATION</li>
            </h4>
            <p>That in the event of any loss and/or damage and/or expense etc. being incurred and/or suffered by FINSPACE and/or by any client of FINSPACE due to the failure and/or breach on part of BUSINESS ASSOCIATE as to its obligations, duties, indemnities,
                representation and/or terms of this agreement (whether the same is willful or not), the BUSINESS ASSOCIATE shall be liable to pay to FINSPACE thrice the amount of such loss and/or damage and/or expense etc. incurred by FINSPACE and/or
                its client, including the loss of business and goodwill of FINSPACE. </p>
            <h4>
                <li>FORCE MAJEURE</li>
            </h4>
            <ol type="a">
                <li>Neither Party shall bear responsibility for the complete or partial non-performance of any of its obligations, if the non-performance results from such Force Majeure circumstances as Flood, Fire, Earth Quake and other acts of God as well
                    as War, Military operation, natural calamities, pandemic, Acts or Actions of State Authorities or any other circumstances beyond the Parties control. </li>
                <li>In such circumstances the time stipulated for the performance of an obligation under the Annexure to this Agreement is extended correspondingly for the period of time of action of these circumstances and their consequences. </li>
                <li>The Party for which it becomes impossible to meet obligations under the Agreement due to Force Majeure conditions, is to notify in written form the other Party of the beginning and cessation of the above circumstances immediately, but
                    in any case, not later than 10 (Ten) days from the moment of their beginning.</li>
                <li>If the impossibility of complete or partial performance of an obligation lasts for more than 45 days, either Party hereto reserves the right to terminate the Agreement upon giving prior written notice of 15 (fifteen) days to the other
                    Party of the intention to terminate without any liability.</li>
            </ol>
            <h4>
                <li>ARBITRATION & JURISDICTION</li>
            </h4>
            <p>That in the eventuality of any dispute or difference should arise on any matter relating to or arising out of the of the present agreement and/or any future amendment, modification, supplementary agreement thereof, whether during its subsistence
                or thereafter the same shall be referred to the Sole Arbitration of an arbitrator to appointed by FINSPACE whose decision shall be final and binding upon the parties. The sole Arbitrator shall conduct the arbitration proceedings at Thiruvananthapuram.
                It is also mutually agreed between the parties that FINSPACE would be entitled to invoke the present arbitration agreement even after the termination of the same. That in the event of such an Arbitrator to whom the matter has been originally
                referred dying or bring unable/failing/refusing to act for any reason whatsoever and/or withdrawing from his office at any stage/time, FINSPACE shall be entitled to appoint another person to act as the Sole arbitrator. That such arbitrator
                shall be entitled to proceed with the reference from the stage at which it was left by his predecessor. It is also agreed between the parties that arbitration proceeding would be conducted in English only and in no other language. That
                the Courts at Thiruvananthapuram shall have exclusive jurisdiction (subject to the arbitration proceedings which are to be also conducted at Thiruvananthapuram) over any or all disputes arising out of this Agreement and the parties hereby
                submit themselves to the jurisdiction of such Courts and/or Tribunals.</p>
            <h4>
                <li>GENERAL</li>
            </h4>
            <ol type="a">
                <li>This Agreement shall not be supplemented, interpreted, amended, or modified in any manner except by in writing signed by a duly authorized officer or representative of each of the Parties hereto. Such an Agreement containing the additions,
                    deletion and/or alteration and signed by all parties hereto shall form an annexure and be deemed to be a part of to this Agreement.</li>
                <li>The heading to the clauses in the present Agreement shall be read in conformity to the contents of the clause and/or Agreement and in case the same is in contradiction to the terms and conditions of the Agreement then the terms of the
                    Agreement shall have precedence,</li>
                <li>This Agreement, together with all annexure attached hereto, which are incorporated herein by this reference, constitutes the entire Agreement between the parties and supersedes all prior oral and/or written negotiations and Agreement between
                    the parties with respect to the subject matter hereof.</li>
                <li>Any provision of this Agreement which is prohibited or unenforceable in any jurisdiction shall, as to such jurisdiction, be ineffective to the extent of prohibition or unenforceability but shall not invalidate the remaining provisions
                    of this Agreement or Agreement or affect such provision in any other jurisdiction. </li>
            </ol>
            <div class="col-md-12 row">

                <div class="col-md-6">
                    <br><br>
                    <p>Signed, sealed, and delivered by the </p>
                    <strong>SRICHID ADVISORS PRIVATE LIMITED</strong>
                    <p>Authorized representative</p>
                    <p>Signature: </p>
                    <br><br>
                    <strong>Witness I	</strong><br>
                    <span>Name : ___________________</span><br>
                    <span>Address : _________<br>__________</span><br>
                    <p>Signature: </p>
                </div>
                <div class="col-md-6">
                    <br><br>
                    <p>Signed, sealed, and delivered by the </p>
                    <strong>BUSINESS ASSOCIATE</strong>
                    <p>Authorized representative</p>
                    <p>Signature: </p>
                    <br><br>
                    <strong>Witness II	</strong><br>
                    <span>Name  ___________________</span><br>
                    <span>Address  _________<br>__________</span><br>
                    <p>Signature: </p>
                </div>
            </div>
            <p class="text-center">
                <strong>Annexure A</strong>
            </p>
            <ol type="a">
                <li>Referral of the Customers who are interested in the Products;</li>
                <li>Enhance and simultaneously tap the potential market for the Products and/or the business opportunities for FINSPACE;</li>
                <li>Make every possible effort to check and verify the bonafides and the credentials of the Customers referred; </li>
                <li>Provide the name, address, mobile number and e-mail Id of the Customer who is interested in availing Products from FINSPACE while referring any Customer to FINSPACE;</li>
                <li>Obtain the authorization from the Customer to refer the above-mentioned details of the Customer to the FINSPACE in a form and substance acceptable to the FINSPACE;</li>
                <li>Providing any assistance to FINSPACE, if required by FINSPACE, for the purpose of product documentation in accordance to the policies and procedures of the FINSPACE and its empaneled Financial Institutions;</li>
                <li>Forward to FINSPACE and/or to any of its representatives all such papers and/or documents and/or details and/or clarifications and/or information as they may require from the Customer;</li>
                <li>Calling for papers/documents from the Customers and collecting & reviewing the papers/documents produced by the Customers pertaining to their personal details, income, employment or professional status, property(s) owned by them and, such
                    other information and documents as FINSPACE may require, and also to verify and to make all efforts to ensure that these documents and their copies (if any) are genuine documents/copies;</li>
                <li>Prominently display and/or exhibit and/or mail and/or distribute all information brochures and other material (including promotional material) relating to the Products;</li>
                <li>Attending to the Customer’s service requests, processing and forwarding them to FINSPACE and coordinating with FINSPACE and completing/attending to these requests and routing them/reverting to the Customers;</li>
                <li>Co-ordinate and communicate between the Customer and FINSPACE for all purposes & matters relating to the Products;</li>
                <li>Do such work promptly, efficiently and acknowledge to maintain the required confidentiality while providing the services under this Agreement and/or on having access to confidential or proprietary information of FINSPACE and related business
                    entities (the "Information"). The BUSINESS ASSOCIATE shall also authorize FINSPACE to conduct required due diligence including credit checks on BUSINESS ASSOCIATE which FINSPACE may consider necessary in its sole discretion;</li>
                <li>Such duties, functions and operations as may be authorized and required in writing by FINSPACE;</li>
                <li>To make all efforts to enhance the business reputation and goodwill of FINSPACE;</li>
            </ol>
            <p class="text-center">
                <strong>Annexure B</strong>
            </p>
            <p class="text-center">
                <strong>Code of Conduct</strong>
            </p>
            <p>This code of conduct (“Code”) shall apply to BUSINESS ASSOCIATE (for the purpose of this Code, the term BUSINESS ASSOCIATE shall include BUSINESS ASSOCIATEs and their officers, employees, agents) involved in marketing and distribution of the
                Products. The BUSINESS ASSOCIATE shall abide by this Code and any BUSINESS ASSOCIATE found to be violating this Code may be blacklisted by FINSPACE, in addition to any other action as may be provided herein.

            </p>
            <ol>
                <h4>
                    <li>Tele-calling a Customer </li>
                </h4>
                <p>A Customer is to be contacted for sourcing FINSPACE’s products only under the following circumstances:</p>
                <ol type="a">
                    <li>When Customer has expressed a desire to acquire a Products through internet site/call centre/Branch or has been referred to by another Customer/customer who has given consent for accepting calls on other products of FINSPACE.</li>
                    <li>When the Customer’s name/telephone number/ address is available and has been taken from one of the lists/directories/databases approved by the BUSINESS ASSOCIATE Manager/Team leader, after taking his/ her consent.</li>
                    <li>The BUSINESS ASSOCIATE should not call a person whose name/number is flagged in any “do not disturb” list.</li>
                </ol>
                <h4>
                    <li>When you may contact a Customer on telephone?</li>
                </h4>
                <p>Telephonic contact must normally be limited between 09:30 hours and 17:30 hours. However, it may be ensured that a Customer is contacted only when the call is not expected to inconvenience him/her. Calls earlier or later than the prescribed
                    time may be placed when the Customer has expressly authorized BUSINESS ASSOCIATE to do so either in writing or orally.</p>
                <h4>
                    <li>Can the Customer’s interest be discussed with anybody else?</li>
                </h4>
                <p>BUSINESS ASSOCIATE should respect a Customer’s privacy. The Customer’s interest may normally be discussed only with the Customer and any other individual/family member such as Customer’s accountant/secretary /spouse, authorized by the
                    Customer.
                </p>
                <h4>
                    <li>Leaving messages and contacting persons other than the Customer.</li>
                </h4>
                <p>Calls must first be placed to the Customer. In the event the Customer is not available, a message may be left for him/her. The aim of the message should be to get the Customer to return the call or to check for a convenient time to call
                    again. Ordinarily, such messages may be restricted to:</p>
                <p>“Please leave a message that ______________ (Name of officer) representing FINSPACE called and requested to call back at __________ (phone number)”. <br> As a general rule, the message must indicate that the purpose of the call is regarding
                    selling or distributing a products of FINSPACE.
                </p>
                <h4>
                    <li>No misleading statements/misrepresentations permitted</li>
                </h4>
                <p>BUSINESS ASSOCIATE should not:</p>
                <ol type="a">
                    <li>Mislead the Customer on any Products offered;</li>
                    <li>Mislead the Customer about their business or organization's name, or falsely represent themselves;

                    </li>
                    <li>Make any false / unauthorised commitment on behalf of FINSPACE for any facility/service;</li>
                    <li>BUSINESS ASSOCIATE shall not assure any favourable consideration by FINSPACE to the Customer;</li>
                    <li>BUSINESS ASSOCIATE shall not use any inducement, threat, coercion, misrepresentation, unethical or unlawful means and false, incorrect or misleading representations to procure the referral of the potential Customers for availing the
                        loans/ products and adhere to the Code of Conduct issued by FINSPACE in this regard at all times</li>
                </ol>
                <h4>
                    <li>Telemarketing Etiquettes</li>
                </h4>
                <h5>PRE-CALL</h5>
                <ol type="a">
                    <li>No calls prior to 09:30 hours or post 17:30 Hours unless specifically requested: No serial dialing</li>
                    <li>No calling on lists unless list is cleared by team leader</li>
                </ol>
                <h5>DURING CALL</h5>
                <ol type="a">
                    <li>Identify yourself, your company and FINSPACE</li>
                    <li>Request permission to proceed</li>
                    <li>If denied permission, apologize and politely disconnect.</li>
                    <li>State reason for your call</li>
                    <li>Always offer to call back on landline, if call is made to a cell number - Never interrupt or argue</li>
                    <li>To the extent possible, talk in the language which is most comfortable to the Customer keep the conversation limited to business matters</li>
                    <li>Check for understanding of “Most Important Terms and Conditions” by the customer, if he plans to buy the products

                    </li>
                    <li>Reconfirm next call or next visit details</li>
                    <li>Provide BUSINESS ASSOCIATE’s telephone number name or contact details if asked for by the Customer.</li>
                    <li>Thank the customer for his/her time</li>
                </ol>
                <h5>POST CALL</h5>
                <ol type="a">
                    <li>Customers who have expressed their lack of interest for the offering should not be called for the next 3 months with the same offer

                    </li>
                    <li>Provide feedback to FINSPACE on Customers who have expressed their desire to be flagged “Do Not Disturb”</li>
                    <li>Never call or entertain calls from Customers regarding products already sold. Advise them to Contact the Customer Service of FINSPACE.</li>
                </ol>
                <h4>
                    <li>Gifts or bribes</li>
                </h4>
                <p>BUSINESS ASSOCIATE must not accept gifts from Customers or bribes of any kind. Any BUSINESS ASSOCIATE offered a bribe or payment of any kind by a Customer must report the offer to his/her management.</p>
                <h4>
                    <li>Precautions to be taken on visits/ contacts</li>
                </h4>
                <h5>BUSINESS ASSOCIATE should:</h5>
                <ol type="a">
                    <li>Respect personal space - maintain adequate distance from the Customer.</li>
                    <li>Not enter the Customer's residence/office against his/her wishes

                    </li>
                    <li>Not visit in large numbers - i.e. not more than one BUSINESS ASSOCIATE and one supervisor, if required. Respect the Customer's privacy.</li>
                    <li>If the Customer is not present and only family members/office persons are present at the time of the visit, he/she should end the visit with a request for the Customer to call back.</li>
                    <li>Provide his/her telephone number, name or the contact details, if asked for by the Customer.</li>
                    <li>Limit discussions with the Customer to the business - Maintain a professional distance.</li>
                </ol>
                <h4>
                    <li>Other important aspects - Appearance & Dress Code</li>
                </h4>
                <p>BUSINESS ASSOCIATE's must be appropriately dressed -</p>
                <h5>For men this means</h5>
                <ol type="a">
                    <li>Well ironed trousers;</li>
                    <li>Well ironed shirt, shirt sleeves preferably buttoned down.</li>
                </ol>
                <h5>For women this means</h5>
                <ol type="a">
                    <li>Well ironed formal attire (Saree, Suit etc.)</li>
                    <li>Well-groomed appearance (Jeans and/or T Shirt, open sandals are not considered appropriate under formal attire)</li>
                </ol>
                <h4>
                    <li>Handling of letters & other communication</li>
                </h4>
                <p>Any communication sent to the Customer should be only in the mode and format approved by FINSPACE.</p>
            </ol>
            <br><br>
        </ol>

    </div>
</div>