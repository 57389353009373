<app-header></app-header>
<br><br><br><br><br><br><br><br><br>
<div class="col-md-12 row">

    <div class="col-md-1">&nbsp;</div>
    <div class="col-md-10" style="max-width: 109%;
    position: relative;">
        <span class="col-md-12 text-center"><h6>#c60c0c</h6></span>
        <span class="col-md-12 text-center"><h5>Terms of Use</h5></span>
        <br>
        <p>This Terms of Use (TOU) is published in accordance with the provisions of Rule3(1) of the Information Technology (Intermediaries guidelines) Rules 2011 that require publishing the rules and regulation, privacy policy and Terms of Use for access
            or usage of this website.</p>
        <p>The domain name
            <a [routerLink]="['/']">www.Myfinspace.com</a> (Website) is owned by FINSPACE Advisors Private Limited, a company incorporated under the Companies Act, 2013, having its registered office at RH 53, Vighnaharta Enclave Sr no 46-4/1B, Shilottar,
            Malewadi, Panvel, Raigad, Maharashtra-410206.
        </p>
        <p>For the purpose of these TOU, wherever the contect so requires “User” or “You” shall mean any natural or legal person who has agreed to access the Website by providing registration data while registering on the Website as registered user using
            the computer systems. The term “We”, “Us”, Our” shall mean the said company.</p>
        <p> <b>BY USING THIS WEBSITE, YOU AGREE TO THE TERMS AND CONDITIONS OF THIS POLICY. IF YOU DO NOT AGREE WITH THE TERMS AND CONDITIONS OF THIS POLICY, PLEASE DO NOT PROCEED FURTHER TO USE THIS WEBSITE</b> </p>
        <p><strong>General Terms</strong></p>
        <p>When You use any of the services provided by Us through the Website You will be subject to the rules, guidelines, policies, terms and conditions applicable to such service and they shall be deemed to be incorporated into this Terms of Use and
            shall be considered as part and parcel of this Terms of Use. We Reserve the right, at Our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to You, It is Your responsibility
            to review these Terms of Use periodically for updates/ Changes. Your continues use of the Website following the posting of changes will mean that You accept and agree to the revisions. As long as You comply with these Tems of Use, We grant
            You a personal, non exclusive, non-Transferable, limited privilege to enter and use the Website.</p>
        <p>Access to and use of this Website is subject to these ToU and our Privacy Policy. By accessing and using this Website you agree to be bound by and to act in accordance with these ToU and our Privacy Policy. If you do not agree to these ToU or
            our Privacy Policy, you are not permitted to access and use this Website and you should cease such access and/or use immediately.</p>
        <p>If you breach any term of these ToU, your right to access and use this Website shall cease immediately.</p>
        <p><strong>Eligibility</strong></p>
        <p>The Website is available for use only to persons who can form legally binding contracts under Contract Act 1872, Hence, the Service is not available to minors under the age of 18 or to any users suspended or removed from the system by FINSPACE
            for any reason. Additionally, users are prohibited from selling, trading, or otherwise transferring their accounts to another party.</p>
        <p><strong>Confidentiality</strong></p>
        <p>You shall be responsible for maintaining the confidentiality of your display name and password and You shall be responsible for all activities that occur under your Login ID and Password. You agree that if You provide any information that us untrue,
            inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with this Terms of Use, and / or the said shall amount to any illegal
            activity in future then We shall have right to indefinitely suspend or terminate or block access of your membership on the Website and refuse to provide You with access to the Website.</p>
        <p><strong>Nature of Business</strong></p>
        <p>In the nature of business, Finspace Will be intermediating between the users and the service providers in several different ways and this engagement we will require to use all the information data, identity and their family and personal details
            for the express purpose of providing efficient and seamless service to the registrant users. By registering for the use of this portal, the users give their unconditional acceptance for the use of the data provided by them without any restriction
            or limitation and also explicitly consented agree to protect and indemnify Finspace against all manner of liabilities and litigations including tart liabilities. </p>
        <p><strong>Right to use Personal/Contact Details</strong></p>
        <p>By making use of this Website and furnishing your personal/contact details, you hereby agree that you are interested in availing and/or purchasing the products/services that you have selected. You hereby agree that we may contact you either electronically
            or through phone, to understand your interest in our products and/or services and to fulfill your demand. You also agree that we reserve the right to make your details available to any of our authorized members/agents or partners and you may
            be contacted by them for information and for sales through email, telephone and/or SMS. You agree to receive promotional materials and/or special offers from us through email or SMS. If you disagree with this ToU, please do not use this Website.</p>
        <p>Please be aware that information provided on this Website is provided for general information purposes only and is provided so that you can avail the product and/or service that you feel is appropriate to meet your needs. You should always check
            the suitability, adequacy and appropriateness of the product and/or service that is of interest to you and it is your sole decision whether to obtain or refrain from obtaining any product and/or service. If you are in any doubt as to the suitability,
            adequacy or appropriateness of any product and/or service referred to on this Website, we suggest that you seek independent professional advice before you obtain any product and/or service via this Website.</p>
        <p>Please be aware that nothing on this Website is, or shall be deemed to constitute, an offer by us or any third party to sell to you any product and/or service or to enter into any contract with you in respect of any product and/or service. By
            submitting your details, you are making an offer to obtain the relevant product and/or service that may be accepted or rejected. The contract for the product and/or service will only be concluded once your offer has been accepted. You will
            receive confirmation when your offer has been accepted.</p>
        <p>We aim to provide uninterrupted access to this Website but we give no warranty as to the uninterrupted availability of this Website. We reserve the right to suspend, restrict or terminate your access to this Website at any time.</p>
        <p>We reserve the right to add, amend, delete, edit, remove or modify any information, content, material or data displayed on this Website and without notice from time to time.</p>
        <p><strong>Permission To Use</strong></p>
        <p>Any unauthorized use by you or any illegal or unlawful activity by you in or for this Website shall terminate the permission or license granted to you by us to use this Website and may give rise to a claim for damages and/or be considered a criminal
            offence.
        </p>
        <p>You may operate a link to this Website provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, as determined by us. You must not operate a link to this Website in such a way as to suggest
            or imply any form of association, approval or endorsement by us, unless otherwise agreed between you and us through a separate legal agreement. We reserve the right to require you to immediately remove any link to this Website at any time
            and we may withdraw any linking permission at any time.</p>
        <p><strong>Intellectual Property Rights</strong></p>
        <p>The copyright in the information, content, material or data displayed on this Website belongs to us or our licensors or our licensees or authorized or permitted agents/members. You may temporarily print, copy, download or store extracts of information,
            content, material or data displayed on this Website for your own personal use but should not use for any commercial purpose.</p>
        <p>This Website contains material which is owned by or licensed to us and are protected by copyright. This material includes, but is not limited to, data, documents, information, details, design, layout, appearance and graphics; logos, business names,
            trading names, service marks and trade-marks, internet domain names, moral rights, rights specifications, know how, processes and business methods; software programs (in all cases whether registered or unregistered and including all rights
            to apply for registration) and all such rights are reserved. Reproduction of any material of our Website is prohibited for any commercial use.</p>
        <p><strong>Our Limited Liabilities</strong></p>
        <p>We use reasonable endeavours to ensure that the data, material and information on this Website is accurate and to correct any errors or omissions as soon as practicable after being notified of them. However, we are not guaranteeing that the data,
            material and information on this Website are accurate or that there are no errors or omissions in the data, material and information.</p>
        <p>We do not guarantee the accuracy of, and disclaim all liability for any errors or other inaccuracies relating to the information and description of the content, products, and services. All such information and services are provided “AS IS” without
            warranty of any kind.</p>
        <p>We disclaim that all warranties and conditions that this Website, its services or any email sent from us, our affiliates, and / or their respective agents are free of viruses or other harmful components.</p>
        <p>We are not responsible for any losses or damages arising from an inability to access the Website, from any use of the Website or from reliance on the data transmitted using the Website where such losses or damages are caused by any event beyond
            our reasonable control including as a result of the nature of electronic transmission of data over the internet.</p>
        <p>We are not responsible or liable for any direct and indirect losses or damages suffered or incurred by you which were not foreseeable by us when you accessed or used the Website.</p>
        <p>Please be aware that by submitting your details, you are making an offer to obtain the relevant product or service from us and our affiliates on the terms and conditions that may be accepted or rejected. The contract for the product or service
            will only be concluded once your offer has been accepted. You will receive confirmation when your offer has been accepted. In case your offer for any product and/or service is rejected even after acceptance for any reason, we are not responsible
            or liable for any losses or damages suffered or incurred by you in the process.</p>
        <p>You may also be able to access user reviews directly on the Website. The views expressed therein do not represent our views or the views of our affiliates and we are not responsible or liable for the accuracy or content of any such views or expressions.
            We are not responsible or liable for any loss or damage you may suffer or incur in connection with your use of user reviews on the Website.</p>
        <p>Please be aware that the information and descriptions of products and/or services on this Website may not represent the complete descriptions of all the features and terms and conditions of those products and/or services. You must ensure that
            you carefully read all the features and terms and conditions of any product and/or service before availing it.</p>
        <p>If you apply for and obtain any product and/or service through this Website, it is your responsibility to ensure that you understand and agree with the prescribed terms and conditions before entering into a contract to obtain that product or service.</p>
        <p>Any views, opinions, advice or assistance which is given or provided to you by a third party after you have used this Website do not represent our views, opinions, advice or assistance and are not checked, monitored, reviewed, verified or endorsed
            by us. We do not endorse, recommend or take responsibility for any third party who provides you with any views, opinions advice or assistance. You act or refrain from acting on any third party’s views, opinions, advice or assistance at your
            sole risk and sole discretion and you are solely responsible for any decision to act or refrain from acting on such views, opinions, advice or assistance. We are not responsible or liable for any loss or damage you may suffer or incur in connection
            with such views, opinions, advice or assistance including in relation to their accuracy, truthfulness or completeness or for any acts, omissions, errors or defaults of any third party in connection with such views, opinions, advice or assistance.</p>
        <p>In no event shall we and/or our affiliates be liable for any direct, indirect, punitive, incidental, special, or consequential damages arising out of, or in any way connected with, your access to, display of or use of this Website or with the
            delay or inability to access, display or use this Website (including, but not limited to, your reliance upon opinions appearing on this Website; any computer viruses, information, software, linked Sites, products, and services obtained through
            this Site; or otherwise arising out of the access to, display of or use of this Site) whether based on a theory of negligence, contract, tort, strict liability, or otherwise.</p>
        <p><strong>Responsibilities of Users</strong></p>
        <p>You must take all reasonable precautions (including using appropriate virus checking software) to ensure that any information, content, material or data you provide is free from viruses, spyware, malicious software, trojans, worms, logic bombs
            and anything else which may have a contaminating, harmful or destructive effect on any part of this Website or any other technology.</p>
        <p>You may complete a registration process as part of your use of this Website which may include the creation of a username, password and/or other identification information. Any username, password and/or other identification information must be
            kept confidential by you and must not be disclosed to, or shared with, anyone. Where you do disclose to or share with anyone your username, password and/or other identification information, you are solely responsible for all activities undertaken
            on this Website using your username, password and/or other identification information.</p>
        <p>It is your responsibility to check and ensure that all information, content, material or data you provide on this Website is correct, complete, accurate and not misleading and that you disclose all relevant facts. We do not accept any responsibility
            or liability for any loss or damage you may suffer or incur if any information, content, material or data you provide on this Website is not correct, complete and accurate or if it is misleading or if you fail to disclose all relevant facts.</p>
        <p>You must get permission from any other person about whom you propose to provide information before you provide it. In submitting any other person’s details, you are confirming to us that you have their permission to do so and that they understand
            how their details will be used.</p>
        <p>You are solely liable for any impersonation or fraud that you may commit while submitting any information on this Website and the resultant damage or injury that such impersonation or fraud may cause to us or to any third party. We, and such affected
            third parties shall have the rights to initiate such legal action against you as deemed fit.</p>
        <p>You undertake to comply with all laws, rules and regulations in force at all times during the subsistence of these terms within the territory of India.</p>
        <p>You are solely responsible and liable for your conduct on this Website and for your User Content.</p>
        <p><strong>Indemnity</strong></p>
        <p>You release and indemnify the said company and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the Website and specifically waive any claims that you may have
            in this behalf under applicable law. Notwithstanding its reasonable efforts in that behalf, the said company cannot take responsibility or control the information provided by other Users which is made available on the Website. You may find
            other User’s information to be offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution regarding the same.</p>
        <p>You agree that you will be liable to us for any damage, loss, claim, demand, liability or expense that we may suffer or incur arising out of or in connection with your conduct on this Website and/or your User Content.</p>
        <p><strong>Recording your Calls</strong></p>
        <p>Telephone calls that you may make to or receive from our customer service help lines may be monitored and/or recorded. This will help us to train our staff and improve our service to you. A recording will only be used under proper and careful
            supervision.
        </p>
        <p><strong>Complaints/Grievances</strong></p>
        <p>Our aim is to provide you with an excellent service at all times. If you are unhappy with our service for any reason or have complain or grievance of any kind, you can inform us about the same by sending email at <a href="mailto:grievance.redressal@finspace.com">grievance.redressal@finspace.com</a>            </p>
        <p>We will aim to resolve your complaint as soon as possible and if we are not able to do so within two working days, we will provide you with an acknowledgement. After we have had an opportunity to investigate your concerns, we will issue you with
            a final response.</p>
        <p>If you remain dissatisfied with our response or in case of any kind of disputes, differences, controversies relating to or arising out of products or services or acts or relating to the use of this Website all such disputes, differences and controversies
            shall be finally, exclusively and conclusively settled by Arbitration in accordance with the Rules of Arbitration under the provisions of Arbitration and Conciliation Act, 1996. The Arbitration shall be conducted in English Language and venue
            for such arbitration shall be Ahmedabad, India. The Award of Arbitral Tribunal shall be final and binding on the parties to the dispute. The cost of the Arbitration shall be borne by the respective parties.</p>
        <p><strong>Privacy</strong></p>
        <p>We are committed to protecting your privacy and we treat your privacy very seriously. We process information about you in line with our Privacy Policy. By using this Website, you agree to the way in which we process and deal with your personal
            information.
        </p>
        <p>We may disclose your personal information or access your account if required to do so by law, any court, the Financial Services Authority, or any other applicable regulatory, compliance, Governmental or law enforcement agency.</p>
        <p><strong>Miscellaneous</strong></p>
        <p>This Website is only intended for use by residents of India. We make no warranty or representation that any product or service referred to on this Website and/or any service we provide is available or otherwise appropriate for use outside India.
            If you choose to use this Website from locations outside India, you do so at your sole risk and you are responsible for compliance with all applicable local laws.</p>
        <p>If any provision of these ToU is held to be unlawful, invalid or unenforceable, that provision shall be deemed deleted from these ToU and the validity and enforceability of the remaining provisions of these ToU shall not be affected.</p>
        <p>These ToU constitute the entire agreement between you and us relating to your access to and use of this Website and supersedes any prior agreements (including any previous terms of use of this Website).</p>
        <p>No failure or delay by us in exercising any right under these ToU will operate as a waiver of that right nor will any single or partial exercise by us of any right preclude any further exercise of any right.</p>
        <p><strong>Governing Law</strong></p>
        <p>These ToU and your access to and use of this Website shall be governed by and interpreted in accordance with Indian laws.Each of you and us submits to the exclusive jurisdiction of the courts of Raigad, India in connection with these ToU and your
            access to and use of this Website (including any claims or disputes).</p>
        <p>Notwithstanding any other provisions of this ToU, or any general legal principles to the contrary, any provision of this ToU that imposes or contemplates continuing obligations on a party will survive the expiration or termination of this ToU.</p>
        <br><br><br><br><br>
    </div>
    <div class="col-md-1">&nbsp;</div>
</div>