<app-header></app-header>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>

<div class="row inbox">
    <div class="col-md-1"></div>
    <div class="col-md-2">
        <div class="panel panel-default">
            <div class="panel-body inbox-menu">
                <a class="btn btn-danger btn-block" data-toggle="modal" mdbWavesEffect data-target="#Compose"
                    style="color: white;background-color: #212529;">Compose</a>

                <ul>


                    <li>
                        <a (click)="gotoinbox()"><i class="fa fa-inbox"></i> Inbox</a>
                    </li>

                    <li>
                        <a (click)="gotosentitems()"><i class="fa fa-rocket"></i> Sent</a>
                    </li>
                    <li>
                        <a (click)="gotodeleteitems()"><i class="fa fa-trash-o"></i> Trash</a>
                    </li>



                </ul>

            </div>

        </div>
        <div>&nbsp;</div>

        <div class="card">
            <div class="card-body">
                <div>
                    <div class="col-md-1"></div>

                    <div>
                        <label>From Date</label>
                        <input type="date" class="form-control" id="fromdate" name="fromdate" placeholder="Any Day"
                            [(ngModel)]="fromdate" (change)="onChangefromdate(fromdate)" />
                    </div>
                    <div class="col-md-1"></div>

                    <div>
                        <label>To Date</label>
                        <input type="date" class="form-control" id="todate" name="todate" placeholder="Any Day"
                            [(ngModel)]="todate" (change)="onChangeTodate(todate)" />
                    </div>
                    <div class="col-md-1"></div>

                    <div>
                        <div class="col-md-12">&nbsp;</div>
                        <button type="button" class="btn btn-warning waves-light"
                            style="background-color: gray;color: white;border: black" (click)="cancel()">Cancel</button>

                    </div>
                </div>
            </div>
        </div>





    </div>

    <div class="col-md-8 ">



        <aside class="lg-side">
            <div class="inbox-head">
                <h3>Inbox</h3>


                <form class="pull-right position" action="#">
                    <div class="input-append">
                        <!-- <button type="button" class="btn sr-btn" data-original-title="" title=""><i class="fa fa-search"></i></button> -->
                        <input class="form-control" type="text" name="SearchInbox" [(ngModel)]="SearchInbox"
                            autocomplete="off" placeholder="Search">

                    </div>
                </form>
            </div>

        </aside>








        <table class="table table-striped table-hover" style="    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);">
            <tr class="thead-dark" style="font-size:17px;cursor: pointer;">
                <th>Sl No</th>
                <!-- <th>Ticket Id</th> -->
                <th style="cursor: pointer;" (click)="sortbyname()">From User </th>
                <th>Mail Id</th>
                <th>Comments</th>
                <th>Service Finished</th>
                <th style="cursor: pointer;" (click)="sortbydate()">Last Updated</th>
                <th style="cursor: pointer;" (click)="sortbydate()">Status</th>
                <th *ngIf="roleid == '4' || roleid == '3' "></th>
                <th *ngIf="useridexist">New User</th>
                <th *ngIf="istm=='true'">External User</th>

                <th>Delete</th>
            </tr>
            <tr *ngFor="let aa of InboxMessages | LockFilter: SearchInbox  | paginate: { itemsPerPage: 5, currentPage: p };let i = index"
                style="font-size:16px;font-family:DINNextRoundedLTPro ">
                <td><a [style.color]=" aa.role==1008 ?'#eb8324' : '#1d3a8f'" class="fakelink">{{ 5 * (p - 1) + (i+1)
                        }}</a> </td>
                <!-- <td><a class="fakelink" (click)="GetInboxData(aa)">{{aa.ticketId}}</a></td> -->
                <td *ngIf="istm=='true'"><a [style.color]=" aa.role==1008 ?'#eb8324' : '#1d3a8f'" class="fakelink"
                        (click)="GetInboxData(aa)">{{aa.fromUser}}</a></td>
                <td *ngIf="istm=='true'"><a [style.color]=" aa.role==1008 ?'#eb8324' : '#1d3a8f'" class="fakelink"
                        data-toggle="modal" data-target="#FulldetailsTM" (click)="openMailTM(aa)">{{aa.mailId}}</a></td>
                <td *ngIf="istm=='true'"><a [style.color]=" aa.role==1008 ?'#eb8324' : '#1d3a8f'" class="fakelink"
                        data-toggle="modal" data-target="#FulldetailsTM" (click)="openMailTM(aa)">{{aa.comments}}</a>
                </td>
                <td *ngIf="istm=='true'"><a [style.color]=" aa.role==1008 ?'#eb8324' : '#1d3a8f'" class="fakelink"
                        data-toggle="modal" data-target="#FulldetailsTM"
                        (click)="openMailTM(aa)">{{aa.serviceStatus}}</a></td>
                <td *ngIf="istm=='true'"><a [style.color]=" aa.role==1008 ?'#eb8324' : '#1d3a8f'" class="fakelink"
                        data-toggle="modal" data-target="#FulldetailsTM"
                        (click)="openMailTM(aa)">{{aa.lastUpdated|date:'short'}}</a></td>



                <td *ngIf="istm=='false'"><a [style.color]=" aa.role==1008 ?'#eb8324' : '#1d3a8f'" class="fakelink"
                        (click)="GetInboxData(aa)">{{aa.fromUser}}</a></td>
                <td *ngIf="istm=='false'"><a [style.color]=" aa.role==1008 ?'#eb8324' : '#1d3a8f'" class="fakelink"
                        data-toggle="modal" data-target="#Fulldetails" (click)="openMail(aa)">{{aa.mailId}}</a></td>
                <td *ngIf="istm=='false'"><a [style.color]=" aa.role==1008 ?'#eb8324' : '#1d3a8f'" class="fakelink"
                        data-toggle="modal" data-target="#Fulldetails" (click)="openMail(aa)">{{aa.comments}}</a></td>
                <td *ngIf="istm=='false'"><a [style.color]=" aa.role==1008 ?'#eb8324' : '#1d3a8f'" class="fakelink"
                        data-toggle="modal" data-target="#Fulldetails" (click)="openMail(aa)">{{aa.serviceStatus}}</a>
                </td>
                <td *ngIf="istm=='false'"><a [style.color]=" aa.role==1008 ?'#eb8324' : '#1d3a8f'" class="fakelink"
                        data-toggle="modal" data-target="#Fulldetails"
                        (click)="openMail(aa)">{{aa.lastUpdated|date:'short'}}</a></td>

                <td><a [style.color]=" aa.role==1008 ?'#eb8324' : '#1d3a8f'" class="fakelink" data-toggle="modal"
                        data-target="#Fulldetails" (click)="openMail(aa)">{{aa.status}}</a></td>



                <!-- <td *ngIf="roleid=='4'|| roleid == '3'">
                    <button type="button" class="btn btn-warning waves-light" data-dismiss="modal" mdbWavesEffect (click)="sendtoppu(aa)">Send to PPU</button>

                </td>
 -->
                <td *ngIf="istm=='true'">

                </td>

                <td *ngIf="istm=='true'">
                    <button type="button" class="btn btn-warning waves-light" data-dismiss="modal" mdbWavesEffect
                        (click)="sendtoppu(aa)">Send to PPU</button>

                </td>


                <td *ngIf="aa.workflowId=='1011'|| aa.workflowId=='1' && istm=='false'">
                    <p [style.color]=" aa.role==1008 ?'#eb8324' : '#1d3a8f'" style="color: darkblue;font-size: 18px;;"
                        mdbWavesEffect>{{aa.fromUserId}}
                    </p>

                </td>

                <!-- <td *ngIf="roleid=='4'|| roleid == '3'">
                    <button type="button" class="btn btn-warning waves-light" mdbWavesEffect (click)="openMailforExternal(aa)" data-toggle="modal" data-target="#ExternalUser">Send To External Mail</button>

                </td> -->

                <td *ngIf="istm=='true'">
                    <button type="button" class="btn btn-warning waves-light" mdbWavesEffect
                        (click)="openMailforExternal(aa)" data-toggle="modal" data-target="#ExternalUser">Send To
                        External Mail</button>

                </td>
                <!-- <td *ngIf="aa.workflowId!='1011'"></td> -->
                <td> <a (click)="deletemail(aa)" style="color: red;"><i class=" fa fa-trash"></i>
                    </a>
                </td>

            </tr>
        </table>




        <br />
        <pagination-controls (pageChange)="p = $event" style="float: right"></pagination-controls>
        <br /><br />
        <div>&nbsp;</div>
        <div>&nbsp;</div>
    </div>
</div>


<div class="modal modal-angular fade" id="Fulldetails" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    style="margin-top: 40px;">
    <div class="modal-dialog modalsize" role="document" style="margin-top: 100px;">
        <div class="modal-content" *ngIf="workflowid || (roleid=='3' ||roleid=='4' || roleid=='2') ">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">
                    <p style="color: darkslateblue;text-transform: uppercase;">Send Mail
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body" *ngIf="maildata">

                <br />
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">From User</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.name" autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Mobile Number</label>
                            <input readonly type="text" class="form-control" name="amountPerYear"
                                [(ngModel)]="maildata.contactNumber" autofocus>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Email</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.email" autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Address</label>
                            <input readonly type="text" class="form-control" name="amountPerYear"
                                [(ngModel)]="maildata.address" autofocus>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Information</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.information" autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Service Time</label>
                            <input readonly type="text" class="form-control" name="amountPerYear"
                                [(ngModel)]="maildata.serviceTime" autofocus>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Service Date</label>
                            <input readonly type="text" class="form-control" name="serviceperiod" [(ngModel)]="date"
                                autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">MailId</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.mailId" autofocus>

                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Select Status</label>
                            <select (change)="onSelectStatus($event.target.value)" class="form-control">
                                <option value="">Select Status</option>
                                <option *ngFor="let do of StatusList" value={{do.workflowStateId}}>
                                    {{do.workflowStateName}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Select To User</label>
                            <select (change)="onSelectUser($event.target.value)" class="form-control">
                                <option value="">Select To User</option>
                                <option *ngFor="let do of UserListtt" value={{do.userId}}
                                    [selected]="do.userId == selecteduserid">
                                    {{do.userName}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary waves-light" data-dismiss="modal" mdbWavesEffect
                    (click)="assigntask()">Send</button>
                <button type="button" class="btn btn-warning waves-light" data-dismiss="modal"
                    mdbWavesEffect>Close</button>

            </div>
        </div>

        <!-- Employee Task Accept Form -->

        <div class="modal-content" *ngIf="!workflowid && roleid=='5' && !composemail">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">
                    <p style="color: darkslateblue;text-transform: uppercase;">Send Mail (Task From Service Provider)
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body" *ngIf="maildata">

                <br />
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">From User</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.name" autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Mobile Number</label>
                            <input readonly type="text" class="form-control" name="amountPerYear"
                                [(ngModel)]="maildata.contactNumber" autofocus>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Email</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.email" autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Address</label>
                            <input readonly type="text" class="form-control" name="amountPerYear"
                                [(ngModel)]="maildata.address" autofocus>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Information</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.information" autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Service Time</label>
                            <input readonly type="text" class="form-control" name="amountPerYear"
                                [(ngModel)]="maildata.serviceTime" autofocus>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Service Date</label>
                            <input readonly type="text" class="form-control" name="serviceperiod" [(ngModel)]="date"
                                autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">MailId</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.mailId" autofocus>

                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Select Status</label>
                            <select (change)="onSelectStatus($event.target.value)" class="form-control">
                                <option value="">Select Status</option>
                                <option *ngFor="let do of StatusList" value={{do.workflowStateId}}>
                                    {{do.workflowStateName}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Select To User</label>
                            <select (change)="onSelectUserforemp($event.target.value)" class="form-control">
                                <option value="">Select To User</option>
                                <option *ngFor="let do of UserListForEmployee" value={{do.userId}}>
                                    {{do.userName}}
                                </option>
                            </select>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary waves-light" mdbWavesEffect
                    (click)="AcceptOrRejecttask()">Send</button>
                <button type="button" class="btn btn-warning waves-light" #closebutton data-dismiss="modal"
                    mdbWavesEffect>Close</button>

            </div>
        </div>
        <div class="modal-content" *ngIf="roleid=='1' ">
            <button style="display: none;" type="button" class="btn btn-warning waves-light" #closebuttonuser
                data-dismiss="modal" mdbWavesEffect>Close</button>
        </div>
    </div>

</div>
<br /><br />
<div>&nbsp;</div>
<div>&nbsp;</div>


<div class="modal modal-angular fade" id="FulldetailsTM" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    style="margin-top: 40px;">
    <div class="modal-dialog modalsize" role="document" style="margin-top: 100px;">
        <div class="modal-content" *ngIf="workflowid || (roleid=='3' ||roleid=='4' || roleid=='2') ">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">
                    <p style="color: darkslateblue;text-transform: uppercase;">Send Mail
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body" *ngIf="maildata">

                <br />
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">From User</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.name" autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Mobile Number</label>
                            <input readonly type="text" class="form-control" name="amountPerYear"
                                [(ngModel)]="maildata.contactNumber" autofocus>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Email</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.email" autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Address</label>
                            <input readonly type="text" class="form-control" name="amountPerYear"
                                [(ngModel)]="maildata.address" autofocus>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Information</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.information" autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Service Time</label>
                            <input readonly type="text" class="form-control" name="amountPerYear"
                                [(ngModel)]="maildata.serviceTime" autofocus>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Service Date</label>
                            <input readonly type="text" class="form-control" name="serviceperiod" [(ngModel)]="date"
                                autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">MailId</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.mailId" autofocus>

                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Select Status</label>
                            <select (change)="onSelectStatus($event.target.value)" class="form-control">
                                <option value="">Select Status</option>
                                <option *ngFor="let do of StatusList" value={{do.workflowStateId}}>
                                    {{do.workflowStateName}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Select To User</label>
                            <select (change)="onSelectUser($event.target.value)" class="form-control">
                                <option value="">Select To User</option>
                                <option *ngFor="let doo of SPTMList" value={{doo.id}}>
                                    {{doo.companyName}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary waves-light" data-dismiss="modal" mdbWavesEffect
                    (click)="assigntaskSP()">Send</button>
                <button type="button" class="btn btn-warning waves-light" data-dismiss="modal"
                    mdbWavesEffect>Close</button>

            </div>
        </div>

        <!-- Employee Task Accept Form -->

        <div class="modal-content" *ngIf="!workflowid && roleid=='5' && !composemail">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">
                    <p style="color: darkslateblue;text-transform: uppercase;">Send Mail (Task From Service Provider)
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body" *ngIf="maildata">

                <br />
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">From User</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.name" autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Mobile Number</label>
                            <input readonly type="text" class="form-control" name="amountPerYear"
                                [(ngModel)]="maildata.contactNumber" autofocus>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Email</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.email" autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Address</label>
                            <input readonly type="text" class="form-control" name="amountPerYear"
                                [(ngModel)]="maildata.address" autofocus>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Information</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.information" autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Service Time</label>
                            <input readonly type="text" class="form-control" name="amountPerYear"
                                [(ngModel)]="maildata.serviceTime" autofocus>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Service Date</label>
                            <input readonly type="text" class="form-control" name="serviceperiod" [(ngModel)]="date"
                                autofocus>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">MailId</label>
                            <input readonly type="text" class="form-control" name="serviceperiod"
                                [(ngModel)]="maildata.mailId" autofocus>

                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Select Status</label>
                            <select (change)="onSelectStatus($event.target.value)" class="form-control">
                                <option value="">Select Status</option>
                                <option *ngFor="let do of StatusList" value={{do.workflowStateId}}>
                                    {{do.workflowStateName}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">Select To User</label>
                            <select (change)="onSelectUserforemp($event.target.value)" class="form-control">
                                <option value="">Select To User</option>
                                <option *ngFor="let do of UserListForEmployee" value={{do.userId}}>
                                    {{do.userName}}
                                </option>
                            </select>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary waves-light" mdbWavesEffect
                    (click)="AcceptOrRejecttask()">Send</button>
                <button type="button" class="btn btn-warning waves-light" #closebutton data-dismiss="modal"
                    mdbWavesEffect>Close</button>

            </div>
        </div>
        <div class="modal-content" *ngIf="roleid=='1' ">
            <button style="display: none;" type="button" class="btn btn-warning waves-light" #closebuttonuser
                data-dismiss="modal" mdbWavesEffect>Close</button>
        </div>
    </div>

</div>
<br /><br />
<div>&nbsp;</div>
<div>&nbsp;</div>




<div class="modal modal-angular fade" id="ExternalUser" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    style="margin-top: 40px;">
    <div class="modal-dialog modalsize" role="document" style="margin-top: 100px;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">
                    <p style="color: darkslateblue;text-transform: uppercase;">Enter Mail
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body" *ngIf="maildata">

                <br />
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label for="amountPerYear">External Email</label>
                            <input type="text" class="form-control" name="externalemail" [(ngModel)]="externalemail">
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary waves-light" data-dismiss="modal" mdbWavesEffect
                    (click)="SendExternalUserMail()">Send</button>
                <button type="button" class="btn btn-warning waves-light" data-dismiss="modal"
                    mdbWavesEffect>Close</button>

            </div>
        </div>
    </div>
</div>
<br /><br />
<div>&nbsp;</div>
<div>&nbsp;</div>




<div class="modal modal-angular fade" id="Compose" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    style="padding-top: 6%;margin-top: 40px;">
    <div class="modal-dialog modalsize" role="document" style="margin-top: 100px;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">
                    <p style="color: darkslateblue;text-transform: uppercase;">Send New Mail
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-8">
                        <label for="occupation">Ticket Id</label>
                        <select (change)="onSelectTicket($event.target.value)" class="form-control">
                            <option value="">Select Ticket Id</option>
                            <option *ngFor="let do of TicketsList" value={{do.ticketId}}>
                                {{do.ticketId}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-12 row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-8">
                        <label for="occupation"> To User</label>
                        <select (change)="onSelectUserformail($event.target.value)" class="form-control">
                            <option value="">Select To User</option>
                            <option *ngFor="let do of ToUsersForMail" value={{do.userId}}>
                                {{do.firstName}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-12 row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-8">
                        <label for="amountPerYear">Subject
                        </label>
                        <input type="text" class="form-control" name="mailsubject" [(ngModel)]="mailsubject" autofocus>
                    </div>
                </div>

                <div class="col-lg-12 row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-8">
                        <label for="amountPerYear">Message
                        </label>
                        <textarea type="text" class="form-control" name="message" [(ngModel)]="message" rows="4"
                            cols="50">
                                </textarea>
                    </div>
                </div>


                <div class="col-lg-12 row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-8">
                        <label for="amountPerYear">Upload Documents</label>
                        <input type="file" name="avatars" multiple=true (change)="onChangeDocumnets($event)">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal" mdbWavesEffect
                    (click)="sendmail()">Send</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal" mdbWavesEffect>Close</button>

            </div>
        </div>
    </div>
</div>
<br /><br />
<div>&nbsp;</div>
<div>&nbsp;</div>
<button id="composemaildetails" [hidden]="true" data-toggle="modal" data-target="#maildetailss"></button>

<div class="modal modal-angular fade" id="maildetailss" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    style="margin-top: 40px;">
    <div class="modal-dialog modalsize" role="document" style="margin-top: 100px;">
        <div class="modal-content" *ngIf="composemail">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">
                    <p style="color: darkslateblue;text-transform: uppercase;">Mail Details
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()"><span
                        aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <label for="Email" id="emaillabel ">TicketId :
                        &nbsp;</label>&nbsp;{{MailDetails.allDetails.ticketId}}
                </div>
                <div class="col-md-12">
                    <label for="Email" id="emaillabel ">From User :
                        &nbsp;</label>&nbsp;{{MailDetails.allDetails.fromName}}
                </div>
                <div class="col-md-12">
                    <label for="Email" id="emaillabel ">Subject : &nbsp;</label>&nbsp;{{MailDetails.allDetails.subject}}
                </div>
                <div class="col-md-12">
                    <label for="Email" id="emaillabel ">Message : &nbsp;</label>&nbsp;{{MailDetails.allDetails.message}}
                </div>
                <div class="col-md-12">
                    <label for="Email" id="emaillabel ">Mail Date :
                        &nbsp;</label>&nbsp;{{MailDetails.createdOn|date:'short'}}
                </div>
                <br>
                <div *ngIf="docscount">
                    <h3 style="text-align: center;">Documents</h3><br>
                    <div class="col-md-12 row">
                        <div class="col-md-2"></div>
                        <div class="col-md-10">
                            <div class="table-responsive" style="overflow-x:visible;overflow-y: visible">
                                <table class="table table-striped table-responsive table-bordered">
                                    <tr class="onhover" style="font-size:17px;cursor: pointer;">
                                        <th>Sl No</th>
                                        <th>Documnet Name</th>
                                        <th>Documnet Type</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <tr *ngFor="let aa of DocumnetsList | paginate: { itemsPerPage: 5, currentPage: q };let i = index"
                                        style="font-size:16px;font-family:DINNextRoundedLTPro ">
                                        <td>{{ 5 * (q - 1) + (i+1) }} </td>
                                        <td>{{aa.documentName}}</td>
                                        <td>{{aa.documentType}}</td>
                                        <td><button type="button" class="btn btn-primary waves-light">View</button></td>
                                        <td><button type="button" class="btn btn-primary waves-light">Download</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-warning waves-light" data-dismiss="modal" mdbWavesEffect
                    (click)="close()">Close</button>
            </div>
        </div>
    </div>
</div>
<br /><br />
<div>&nbsp;</div>
<div>&nbsp;</div>


<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info"
            style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 550px;width: 350px;">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <i class="material-icons" style="color: white">close</i>
            </button>
            <span style="color: white;margin-top: 6px;">
                <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
        </div>
    </div>
</div>