import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/MessageService/meaagse.service';
import { BusinessLoanServiceService } from 'src/app/Shared/BusinessLoan/business-loan-service.service';
import { CollateralformService } from 'src/app/Shared/CollateralForm/collateralform.service';
import { ServiceProviderService } from 'src/app/Shared/ServiceProvider/service-provider.service';

@Component({
  selector: 'app-collateralform',
  templateUrl: './collateralform.component.html',
  styleUrls: ['./collateralform.component.css']
})
export class CollateralformComponent implements OnInit {
  curr: any;
  CB: any;
  selectedowner: any;
  category: any;
  selectedcategory: any;
  categorytype: any;
  selectedtype: any;
  collnatu: any;
  selectedcolnature: any;
  ChangValue: any;
  CustomerID: any;
  LoanID: any;
  capsId: any;
  collBe: any;
  glcmsid: any;
  algoid: any;
  collaCategoryId: any;
  colltypeid: any;
  assectLocation: any;
  coollateralnature: any;
  eligibilty: any;
  description: any;
  revocable: any;
  pairPassu: any;
  confirmed: any;
  receivingAccount: any;
  sanctionValue: any;
  sccurrency: any;
  valuationDate: any;
  dismissalert: boolean;
  valuationFreq: any;
  freqUnit: any;
  marginingFreq: any;
  marginingFreqUnit: any;
  comment: any;
  workflowstate: any;
  revoflag: any;
  islimited: any;
  pename: any;
  guarantype: any;
  fab: any;
  raringagency: any;
  rtype: any;
  rvalue: any;
  doe: any;
  networth: any;
  nwcurrency: any;
  relawithborrower: any;
  scValue: any;
  marginfreq: any;
  marginfreqUnit: any;
  valuehaircut: any;
  vhccurrance: any;
  valudate: any;
  matudate: any;
  guarnteeamount: any;
  gacurrance: any;
  chargetype: any;
  chargevalue: any;
  cvcurrance: any;
  scvalue: any;
  scvaluecurr: any;
  scvaldat: any;
  valFreq: any;
  FreqUnit: any;
  MargiFreq: any;
  marfrequnit: any;
  vahcut: any;
  vahcutcurr: any;
  chaType: any;
  chnavalCurr: any;
  accnum: any;
  accclass: any;
  FDMdate: any;
  vahcdate: any;
  ownornot: any;
  chageindicato: any;
  regornot: any;
  mortgagepro: any;
  revadate: any;
  valueby: any;
  marketvalue: any;
  mvccurr: any;
  reavaper: any;
  forcedsalval: any;
  fsvcurr: any;
  spouse:any;
  roleid: string;
  workflowstateslist: any;
  residentiallineone:any;
  Guaranteeammount:any;
  customerid: string;
  username: string;
  uid: string;
  DateTime: Date;
  serviceprovidername: any;
  ticketid: string;
  ProposID: string;
  CollAssignList: any;
  CollBankList: any;
  lduser: any;
  groupId: string;
  groupid: any;
  SelectedUser: string;
  touserlist: any=[];
  touser: any;
  customername: any;
  Nomineetype: string;
  role: string;
  notify: string;
  constructor(private collateralService: CollateralformService,private messageService: MessageService,private datepipe: DatePipe,
    private serviceproviderservice: ServiceProviderService, 
    private businessloanservice: BusinessLoanServiceService) { 
      this.messageService.sendSession('true');

      this.uid = localStorage.getItem("userId");
      this.username = localStorage.getItem("fullname");
      this.ticketid = localStorage.getItem("TicketId");
      this.ProposID = localStorage.getItem("ProposID");
      this.role = localStorage.getItem("role");
      this.Nomineetype = "";
  
      localStorage.setItem("customerId", this.customerid);
      this.customerid = localStorage.getItem("customerId");
    }
  

  ngOnInit() {
    this.GetCollateralCategory();
    this.GetCollateralbelongs();
    this.GetCollateralNature();
    this.GetWorkflowStates();
    this.GetCurrency();
   // this.GetCollateralAssignment();
    this.GetCollateralBankGuarantee();
    this.GetCollateralCashMargin();
    this.GetCollateralLien();
    this.GetCollateralMortgage();
    this.GetToUser();
    this.GetServiceProvider();
  }
  
 GetCollateralbelongs() {
      this.collateralService.getCollateral().subscribe((data: any) => {
          this.CB = data;
          });
  }

  onSelectOwner(id)
  {
    this.selectedowner=id;
  }

  onSelectcategory(id: any) {
    this.selectedcategory = id;
    this.GetCollateralType();
  
  }
  
     GetCollateralCategory() {
        this.collateralService.getcategory().subscribe((data: any) =>{
            this.category = data;
        });    
    }
    GetCollateralType() {
      this.collateralService.getcategorytype(this.selectedcategory).subscribe((data: any) => {
          this.categorytype = data;
          });
  }

  onSelectType(id: any){
    this.selectedtype = id;
  }

  
   GetCollateralNature() {
    this.collateralService.getCollateralNature().subscribe((data: any) => {
         this.collnatu = data;
        });     
  }

  onSelectcollNature(id: any){
    this.selectedcolnature = id;
  }


   GetCurrency() {
    this.collateralService.getcurrency().subscribe((data: any) => {
         this.curr = data;
          });
  }

  onSelectCurrency(id){
    this.sccurrency = id;
  }
  
GetWorkflowStates() {
  this.collateralService.getWorkflowstatesforwf(this.roleid).subscribe((data: any) => {
          this.workflowstateslist = data;
          console.log(data)
   });
 }
 onSelectFrequencyUnit(id){
  this.marginingFreqUnit= id
}

onSelectworkflow(id){
  this.workflowstate = id;
}

onSelectVAHCurrency(id){
  this.vhccurrance = id;
}
onSelectBorrower(id){
  this.relawithborrower= id;
}
onSelectRatVal(id){
  this.rvalue=id.target.value;
}
onSelectRatType(id){
  this.rtype=id.target.value;
}
onSelectRatAgency(id){
  this.raringagency=id.target.value;
}
onSelectCurrNetWorth(id){
  this.nwcurrency=id.target.value;
}
onSelectCurrChangeValue(id){
  this.cvcurrance=id.target.value;
}
onSelectCurrAmt(id){
  this.gacurrance=id.target.value;
}

onSelectMortgage(id){
  this.mortgagepro = id;
}
onSelectFSVCurrency(id){
  this.fsvcurr = id;
}
onSelectMVCurrency(id){
  this.mvccurr = id;
}
// onSelectlduser(id){
//   this.touser = id;
// }

GetCollateralBankGuarantee(){
  this.collateralService.getcollateralbankguarantee(this.uid).subscribe((data: any) => {
    this.CollBankList = data;
    console.log(data)
});
}
GetCollateralCashMargin(){
  this.collateralService.getcollateralcashmargin(this.uid).subscribe((data: any) => {
    this.CollBankList = data;
    console.log(data)
});
}

GetCollateralLien(){
  this.collateralService.getcollaterallien(this.uid).subscribe((data: any) => {
    this.CollBankList = data;
    console.log(data)
});
}

GetCollateralMortgage(){
  this.collateralService.getcollateralmortgage(this.uid).subscribe((data: any) => {
    this.CollBankList = data;
    console.log(data)
});
}




GetToUser(){
  // if(this.groupId == ""){
  //   alert("This user is not a LD proposal!!!")
  // }
  // else{
   this.SelectedUser = this.GetGroupId(this.groupId),
  this.collateralService.gettouser(this.SelectedUser).subscribe((data: any) => {
    this.touserlist = data;

    this.touser = this.touserlist[0].userId;
    console.log(this.touser);
});
// }
}
GetGroupId(grpid) {
  var groupid = "";
  if (grpid === "1003") {
    groupid = "1004";
  }
  else if (grpid === "1004") {
    groupid = "1005";
  }
  else if (grpid === "1005") {
    groupid = "1006";
  }
  else if (grpid === "1006") {
    groupid = "1007";
  }
  else if (grpid === "1007") {
    groupid = "1008";
  }
  else if (grpid === "1007") {
    groupid = "1008";
  }
  else if (grpid === "1008") {
    groupid = "1009";
  }
  else if (grpid === "1009") {
    groupid = "1010";
  }
  else if (grpid === "1010") {
    groupid = "1014";
  }
  else if (grpid === "1014") {
    groupid = "1015";
  }
  else if (grpid === "1015") {
    groupid = "1016";
  }
  else if (grpid === "1016") {
    groupid = "1017";
  }
  else if (grpid === "1017") {
    groupid = "1018";
  }
  else if (grpid === "1018") {
    groupid = "1019";
  }
  else if (grpid === "1019") {
    groupid = "1018";
  }
  else if (grpid === "1020") {
    groupid = "1021";
  }
  else if (grpid === "1021") {
    groupid = "1020";
  }
  else if (grpid === "1022") {
    groupid = "1023";
  }
  else if (grpid === "1023") {
    groupid = "1024";
  }
  else if (grpid === "1024") {
    groupid = "1024";
  }
  return groupid;
}
async GetServiceProvider() {

  await this.serviceproviderservice.getservcieprovider(this.ticketid).subscribe((response: any) => {
    this.serviceprovidername = response;


  });
}
SaveCollateralAssignment() {
  this.DateTime = new Date();
  let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
  var details = {
      CollateralAssignmentsId: 0,
      CustomerId: this.customerid,
      CustomerName: this.customername,
      CAPSCollateralReferance: this.capsId,
      GuarantorName: this.selectedowner,
      GLCMSId: this.glcmsid,
      ALGOId: this.algoid,
      CollateralCategoryId: this.selectedcategory,
      CollateralTypeId: this.selectedtype,
      AssectLocation: this.assectLocation,
      CollateralNature: this.selectedcolnature,
      Eligibilty: this.eligibilty,
      Description: this.description,
      IsRevocable: this.revocable,
      IsPairPassu: this.pairPassu,
      IsConfirmed: this.confirmed,
      ProceedsReceivingAccount: this.receivingAccount,
      SanctionCollateralValue: this.sanctionValue,
      SCCurrency: this.sccurrency,
      ValuationDate: this.valuationDate,
      ValuationFrequency: this.valuationFreq,
      FrequencyUnit: this.marginingFreqUnit,
      MarginingFrequency: this.marginingFreq,
      MargininigFrequencyUnit: this.marginingFreqUnit,
     // CreatedBy: this.username,

      Comment: this.comment,
     // MailId: 0,
      FromUser: this.uid,
     // ToUser: GetGroupId(authData.groupId),
      // WorkflowState: this.workflowstate,
      ProsperityId: this.ProposID,
      TickedId: this.ticketid,
      CreatedOn: latest_date,
     // ToUser:this.touser,
      CollAssignIDD:"1",
      FromName: this.username,
      
  };
  this.collateralService.saveCollateralAssignment(details).subscribe((data: any) => {
      
      this.submitloanform();
  },
      function (error) {
          this.Status = 'Error' + error.message;
      });
};


submitloanform() {
  this.DateTime = new Date();
  let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');

  var cdata = {
    CustomerId: this.customerid,
    CreatedBy: this.username,
    CreatedOn: latest_date,
    TicketId: this.ticketid,
    IsAllFileldsFilled: true,
    otherdocdetails: null,
    ProsperityId: this.ProposID,
    Role: this.role,
    SendCopytoSP: false,
    UserId: this.uid,
    RequestType: 3041,
    Information:"Collateral Form"

  }

  this.businessloanservice.SubmitCollateralForm(cdata).subscribe((data: any) => {
    if (data == "ok") {
      if (this.role == '1008') {
        alert("Collateral Form Submitted Successfully!!")
        // this.route.navigate(['/badashboard']);

      }
      else {
        alert("Collateral Form Submitted Successfully with ticket ID " + this.ticketid + " and to ServcieProvider " + this.serviceprovidername)
        // this.route.navigate(['/customerdashboard']);

      }
    //  localStorage.setItem("editloanform", "false")
      


    } else {
      this.notify = "Something Went Wrong. Try again.!!"
      setTimeout(() => this.dismissalert = true, 100);
      setTimeout(function () {
        this.dismissalert = false;
      }.bind(this), 3000);
    


    }
  })

}
SaveCollateralBankGuarantee() {
  this.DateTime = new Date();
  let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
  var details = {
      Id: 0,
      CustomerId: this.customerid,
      CustomerName: this.customername,
      CAPSCollateralReferance: this.capsId,
      GuarantorName: this.selectedowner,
      GLCMSId: this.glcmsid,
      ALGOId: this.algoid,
      CollateralCategoryId: this.selectedcategory,
      CollateralTypeId: this.selectedtype,
      AssectLocation: this.assectLocation,
      CollateralNature: this.selectedcolnature,
      Eligibilty: this.eligibilty,
      Description: this.description,
      RevocabilityFlag: this.revoflag,
      IsLimited: this.islimited,
      ProjectEmploerName: this.pename,
      GuarantorType: this.guarantype,
      FABORR: this.fab,
      RatingAgency: this.raringagency,
      RatingType: this.rtype,
      RatingValue: this.rvalue,
      DateofEsitimation: this.doe,
      EstimatedNetWorth: this.networth,
      ENWCurrency: this.nwcurrency,
      RelationshipwithBorrower: this.relawithborrower,
      SanctionCollateralValue: this.scValue,
      SCCurrency: this.sccurrency,
      ValuationDate: this.valuationDate,
      ValuationFrequency: this.valuationFreq,
      FrequencyUnit: this.marginingFreqUnit,
      MarginingFrequency: this.marginfreq,
      MargininigFrequencyUnit: this.marginingFreqUnit,
      ValueAfterHaircut: this.valuehaircut,
      VHCCurrency: this.vhccurrance,
      VHCValuationdate: this.valudate,
      MaturitesDate: this.matudate,
      GuaranteedAmount: this.guarnteeamount,
      GACurrency: this.gacurrance,
      ChargeType: this.chargetype,
      ChargeValue: this.chargevalue,
      CVCurrency: this.cvcurrance,
      CreatedBy: this.username,

      Comment: this.comment,
    //  MailId: 0,
      FromUser: this.uid,
     // ToUser: GetGroupId(authData.groupId),
      // WorkflowState: this.workflowstate,
      ProsperityId: this.ProposID,
      TickedId: this.ticketid,
      CreatedOn: latest_date,
     // ToUser:this.touser,
      CollAssignIDD:"2",
      FromName: this.username,
  };
  this.collateralService.SaveCollateralBankGuarantee(details).subscribe((data: any) => {
    this.submitloanform();
  },
      function (error) {
          this.Status = 'Error' + error.message;
      });
};

SaveCollateralCashMagin() {
  this.DateTime = new Date();
  let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
  var details = {
      Id: 0,
      CustomerId: this.customerid,
      CustomerName: this.customername,
      CAPSCollateralReferance: this.capsId,
      GuarantorName: this.selectedowner,
      GLCMSId: this.glcmsid,
      ALGOId: this.algoid,
      CollateralCategoryId: this.selectedcategory,
      CollateralTypeId: this.selectedtype,
      AssectLocation: this.assectLocation,
      CollateralNature: this.selectedcolnature,
      Eligibilty: this.eligibilty,
      Description: this.description,
      SanctionCollateralValue: this.scvalue,
      SCCurrency: this.sccurrency,
      ValuationDate: this.scvaldat,
      ValuationFrequency: this.valFreq,
      FrequencyUnit: this.marginingFreqUnit,
      MarginingFrequency: this.MargiFreq,
      MargininigFrequencyUnit: this.marginingFreqUnit,
      ValueAfterHaircut: this.vahcut,
      VHCCurrency: this.vhccurrance,
      VHCValuationdate: this.valudate,
      MaturitesDate: this.matudate,
      ChargeType: this.chaType,
      ChargeValue: this.ChangValue,
      CVCurrency: this.cvcurrance,
      CreatedBy: this.username,

      Comment: this.comment,
     // MailId: 0,
      FromUser: this.uid,
     // ToUser: GetGroupId(authData.groupId),
      // WorkflowState: this.workflowstate,
      ProsperityId: this.ProposID,
      TickedId: this.ticketid,
      CreatedOn: latest_date,
     // ToUser:this.touser,
      CollAssignIDD:"3",
      FromName: this.username,
  };
  this.collateralService.SaveCollateralCashMagin(details).subscribe((data: any) => {
    this.submitloanform();
    //  $location.path('/administrativedashboard');
  },
      function (error) {
          this.Status = 'Error' + error.message;
      });
};

SaveCollateralLien() {
  this.DateTime = new Date();
  let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
  var details = {
      Id: 0,
      CustomerId: this.customerid,
      CustomerName: this.customername,
      CAPSCollateralReferance: this.capsId,
      GuarantorName: this.selectedowner,
      GLCMSId: this.glcmsid,
      ALGOId: this.algoid,
      CollateralCategoryId: this.selectedcategory,
      CollateralTypeId: this.selectedtype,
      AssectLocation: this.assectLocation,
      CollateralNature: this.selectedcolnature,
      Eligibilty: this.eligibilty,
      Description: this.description,
      AccountwithBank: this.accnum,
      AccountClasification: this.accclass,
      FDMaturityDate: this.FDMdate,
      SanctionCollateralValue: this.scvalue,
      SCCurrency: this.sccurrency,
      ValuationDate: this.scvaldat,
      ValuationFrequency: this.valFreq,
      FrequencyUnit: this.marginingFreqUnit,
      MarginingFrequency: this.MargiFreq,
      MargininigFrequencyUnit: this.marginingFreqUnit,
      ValueAfterHaircut: this.vahcut,
      VHCCurrency: this.vhccurrance,
      VHCValuationdate: this.vahcdate,
      MaturitesDate: this.matudate,
      ChargeType: this.chaType,
      ChargeValue: this.ChangValue,
      CVCurrency: this.cvcurrance,
      CreatedBy: this.username,

      Comment: this.comment,
    //  MailId: 0,
      FromUser: this.uid,
    //  ToUser: GetGroupId(authData.groupId),
    // WorkflowState: this.workflowstate,
    ProsperityId: this.ProposID,
    TickedId: this.ticketid,
    CreatedOn: latest_date,
  //  ToUser:this.touser,
    CollAssignIDD:"4",
    FromName: this.username,

  };
  this.collateralService.saveCollateralLien(details).subscribe((data: any) =>{
    this.submitloanform();
  },
      function (error) {
          this.Status = 'Error' + error.message;
      });
};

SaveCollateralMorgage() {
  this.DateTime = new Date();
  let latest_date = this.datepipe.transform(this.DateTime, 'dd-MM-yyyy hh:mm:ss a');
  var details = {
      Id: 0,
      CustomerId: this.customerid,
      CustomerName: this.customername,
      CAPSCollateralReferance: this.capsId,
      GuarantorName: this.selectedowner,
      GLCMSId: this.glcmsid,
      ALGOId: this.algoid,
      CollateralCategoryId: this.selectedcategory,
      CollateralTypeId: this.selectedtype,
      AssectLocation: this.assectLocation,
      CollateralNature: this.selectedcolnature,
      Eligibilty: this.eligibilty,
      Description: this.description,
      BorrowerisOwnerofassect: this.ownornot,
      ChargeLevelIndicater: this.chageindicato,
      Registerdornotarised: this.regornot,
      MortagePropertyType: this.mortgagepro,
      SanctionCollateralValue: this.scvalue,
      SCCurrency: this.sccurrency,
      ValuationDate: this.scvaldat,
      ValuationFrequency: this.valFreq,
      FrequencyUnit: this.marginingFreqUnit,
      MarginingFrequency: this.MargiFreq,
      MargininigFrequencyUnit: this.marginingFreqUnit,
      ValueAfterHaircut: this.vahcut,
      VHCCurrency: this.vhccurrance,
      VHCValuationdate: this.vahcdate,
      MaturitesDate: this.matudate,
      RevalutionInitiationDate: this.revadate,
      ValueBy: this.valueby,
      MarketValue: this.marketvalue,
      MVCurrency: this.mvccurr,
      RevalutionPercentage: this.reavaper,
      ChargeValue: this.ChangValue,
      CVCurrency: this.cvcurrance,
      ForcedSaleValue: this.forcedsalval,
      FSVCurrency: this.fsvcurr,
      ChargeType: this.chaType,
      CreatedBy: this.username,

      Comment: this.comment,
    //  MailId: 0,
      FromUser: this.uid,
     // ToUser: GetGroupId(authData.groupId),
     // WorkflowState: this.workflowstate,
     ProsperityId: this.ProposID,
     TickedId: this.ticketid,
     CreatedOn: latest_date,
   //  ToUser:this.touser,
     CollAssignIDD:"5",
     FromName: this.username,
 

  };
  this.collateralService.saveCollateralMorgage(details).subscribe((data: any) => {
    this.submitloanform();
  },
      function (error) {
          this.Status = 'Error' + error.message;
      });
};












           
}

