<app-header></app-header>
<div>&nbsp;</div>
<div>&nbsp;</div>
<mat-horizontal-stepper #stepper>
    <!-- <mat-step>
        <div>
            <button mat-button matStepperPrevious></button>
            <button mat-button (click)="stepper.reset()"></button>
        </div>
    </mat-step> -->
</mat-horizontal-stepper>

<!-- #docregion states -->
<mat-horizontal-stepper style="background-color: #c2b6b6;
background-image: linear-gradient(315deg, #c2b6b6 0%, #576574 74%);" #stepper1>


    <mat-step label="Step 1" state="details" style="text-align: center;    height: 96%;width: 136%;">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
            <div class="col-md-1"></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10">

                <div class="homecard" id="signin" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%">
                  <h1 style="font-family: DINNextRoundedLTPro;color: #2b156f;font-size: 34px;">Life Insurance Form</h1>

                  <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Personal Details</h2>

                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12">&nbsp;</div>
                    <form id="msform" [formGroup]="RegistrationForm">
                        <div class="col-md-12 row">
                            <div class="col-md-4">&nbsp;</div>
                            <div class="col-md-4">
                                <div class="form-card">
                                    <div class="container fileUploadWrapper">
                                        <div class="col-md-12">
                                            <img src="{{userImage}}" class="img-circle" style="height: 150px;width: 150px;border: solid 2px;
                                border-radius: 7px;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row">
                            <div class="col-md-2 row">&nbsp;</div>
                            <div class="col-md-3 row">
                                <label id="emaillabel">Customer Id</label>
                                <input (keyup.enter)="signIn(stepper1)" (change)="getCustomerImage(customerid)" formControlName="customerid" class="form-control" type="text" id="inputbutton" [(ngModel)]="customerid">
                            </div>
                        </div>
                        <br>
                        <div class="col-md-12 row">
                            <div class="col-md-2 row">&nbsp;</div>
                            <div class="col-md-3 row">

                                <label id="emaillabel">First Name</label>

                                <input (keyup.enter)="signIn(stepper1)" formControlName="firstname" class="form-control" type="text" id="inputbutton" [(ngModel)]="firstname" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.firstname.errors.required ">First Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Middle Name</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="middlename" class="form-control " type="text " id="inputbutton " [(ngModel)]="middlename ">
                                <!-- <div *ngIf="submitted && f.middlename.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="f.middlename.errors.required ">Middle Name is required
                            </div>
                        </div> -->
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Last Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" (keyup.enter)="signIn(stepper1) " formControlName="lastname" class="form-control " type="text " id="inputbutton " [(ngModel)]="lastname ">
                                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.lastname.errors.required ">Last Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Place of Birth</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.placeofbirth.errors }" (keyup.enter)="signIn(stepper1) " formControlName="placeofbirth" class="form-control " type="text " id="inputbutton " [(ngModel)]="placeofbirth ">
                                <div *ngIf="submitted && f.placeofbirth.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.placeofbirth.errors.required ">Place is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Gender</label>
                                <select (change)="onSelectgender($event.target.value)" class="form-control">
                        <option value="">Select Gender</option>
                        <option *ngFor="let do of genders" value={{do.genderId}}
                            [selected]="do.genderId==genderid">
                            {{do.genderName}}
                        </option>
                    </select>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Caste</label>
                                <select (change)="onSelectCaste($event.target.value)" class="form-control">
                        <option value="">Select Caste</option>
                        <option *ngFor="let do of castes" value={{do.raceId}}
                            [selected]="do.raceId==casteid">
                            {{do.raceName}}
                        </option>
                    </select>
                            </div>
                            <div class="col-md-2 row " *ngIf="othercastedetails">&nbsp;</div>

                            <div class="col-md-3 row " *ngIf="othercastedetails">
                                <label for="Email " id="emaillabel ">Caste</label>
                                <input formControlName="castedetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="castedetail">
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Mobile Number</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.Number.errors }" (keyup.enter)="signIn(stepper1) " formControlName="Number" class="form-control" type="text" id="inputbutton " [(ngModel)]="Number ">
                                <div *ngIf="submitted && f.Number.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.Number.errors.required ">Mobile Number is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Date of Birth</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.dateofbirth.errors }" (keyup.enter)="signIn(stepper1) " formControlName="dateofbirth" class="form-control" type="date" id="inputbutton " [(ngModel)]="dateofbirth ">
                                <div *ngIf="submitted && f.dateofbirth.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.dateofbirth.errors.required ">Date of Birth is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Religion</label>
                                <select (change)="onSelectrelogion($event.target.value)" class="form-control">
                        <option value="">Select Religion</option>
                        <option *ngFor="let do of religions" value={{do.religionId}}
                            [selected]="do.religionId==rid">
                            {{do.religionName}}
                        </option>
                    </select> </div>
                            <div class="col-md-2 row " *ngIf="othereligiondetails">&nbsp;</div>
                            <div class="col-md-3 row " *ngIf="othereligiondetails">
                                <label for="Email " id="emaillabel ">Religion</label>
                                <input formControlName="religiondetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="religiondetail">
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Marital Status</label>
                                <select (change)="onSelectmarital($event.target.value)" class="form-control">
                        <option value="">Select Marital Status</option>
                        <option *ngFor="let do of status" value={{do.maritalStatusId}}
                            [selected]="do.maritalStatusId==martid">
                            {{do.maritalStatus}}
                        </option>
                    </select> </div>



                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Qualification</label>
                                <select (change)="onSelectqualification($event.target.value)" class="form-control">
                        <option value="">Select Qualification</option>
                        <option *ngFor="let do of qualifications" value={{do.qualificationId}}
                            [selected]="do.qualificationId==quaid">
                            {{do.qualificationName}}
                        </option>
                    </select>

                            </div>
                            <div class="col-md-1 row " *ngIf="otherequalification ">&nbsp;</div>
                            <!-- <div class="col-md-1 row " *ngIf="otherequalification">&nbsp;</div> -->

                            <div class="col-md-3 row " *ngIf="otherequalification">
                                <label for="Email " id="emaillabel ">Qualification</label>
                                <input formControlName="qualificationdetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="qualificationdetail">
                            </div>
                            <div class="col-md-1 row " *ngIf="otherequalification" style="  margin-left: 1%;">&nbsp;</div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Occupation</label>
                                <select (change)="onSelectoccupation($event.target.value)" class="form-control">
                        <option value="">Select Occupation</option>
                        <option *ngFor="let do of occupations" value={{do.occupationId}}
                            [selected]="do.occupationId==occuid">
                            {{do.occupationName}}
                        </option>
                    </select>

                            </div>

                            <div class="col-md-2 row " *ngIf="othersoccupation">&nbsp;</div>

                            <div class="col-md-3 row " *ngIf="othersoccupation">
                                <label for="Email " id="emaillabel ">Occupation</label>
                                <input formControlName="occupatoiondetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="occupatoiondetail">
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Email</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (keyup.enter)="signIn(stepper1) " formControlName="email" class="form-control " type="text " id="inputbutton " [(ngModel)]="email ">
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.email.errors.required ">Email is required</div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Designation</label>
                                <select (change)="onSelectdesignation($event.target.value)" class="form-control">
                        <option value="">Select Designations</option>
                        <option *ngFor="let do of designations" value={{do.id}} [selected]="do.id==desid">
                            {{do.designationName}}
                        </option>
                    </select>
                            </div>
                            <div class="col-md-3 row " *ngIf="otherdesignation" style="margin-left: 5%;">

                                <label for="Email " id="emaillabel ">Designation</label>
                                <input formControlName="designationd" class="form-control " type="text " id="inputbutton " [(ngModel)]="designationd">
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h4>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Postal Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" (keyup.enter)="signIn(stepper1) " formControlName="pincode" class="form-control " type="number " id="inputbutton " [(ngModel)]="pincode" (change)="getalldata()">
                                <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.pincode.errors.required ">Postal is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Country</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="country" class="form-control " type="text " id="inputbutton " [(ngModel)]="country" readonly>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> State</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="state" class="form-control " type="text " id="inputbutton " [(ngModel)]="state" readonly>
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">District</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="district" class="form-control " type="text " id="inputbutton " [(ngModel)]="district" readonly>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Address one</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.city.errors }" (keyup.enter)="signIn(stepper1) " formControlName="city" class="form-control " type="text " id="inputbutton " [(ngModel)]="city">
                                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.city.errors.required ">City Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address two</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.street.errors }" (keyup.enter)="signIn(stepper1) " formControlName="street" class="form-control " type="text " id="inputbutton " [(ngModel)]="street">
                                <div *ngIf="submitted && f.street.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.street.errors.required ">Street Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-7 row ">
                                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Permanent Address is Same as Residential Address</h4>
                            </div>
                            <div class="col-md-1 row ">
                                <input class="form-control" formControlName="PermanentSameasResidential" [checked]="PermanentSameasResidential" [(ngModel)]="PermanentSameasResidential" (change)="onSelectaddress($event)" type="checkbox" id="inputbutton ">
                            </div>
                            <div class="col-md-2 row ">&nbsp;</div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Postal Code</label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" (keyup.enter)="signIn(stepper1) " formControlName="postalcode" class="form-control " type="number " id="inputbutton " (change)="getpincoddata()" [(ngModel)]="postalcode">
                                    <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="f.postalcode.errors.required ">Postal is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Country</label>
                                    <input (keyup.enter)="signIn(stepper1) " formControlName="countryone" class="form-control " type="text " id="inputbutton " [(ngModel)]="countryone" readonly>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel "> State</label>
                                    <input (keyup.enter)="signIn(stepper1) " formControlName="stateone" class="form-control " type="text " id="inputbutton " [(ngModel)]="stateone" readonly>
                                </div>

                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>

                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">District</label>
                                    <input (keyup.enter)="signIn(stepper1) " formControlName="districtone" class="form-control " type="text " id="inputbutton " [(ngModel)]="districtone" readonly>
                                </div>

                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Address one</label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.adressone.errors }" (keyup.enter)="signIn(stepper1) " formControlName="adressone" class="form-control " type="text " id="inputbutton " [(ngModel)]="adressone">
                                    <div *ngIf="submitted && f.adressone.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="f.adressone.errors.required ">Address one is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Address two</label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.adresstwo.errors }" (keyup.enter)="signIn(stepper1) " formControlName="adresstwo" class="form-control " type="text " id="inputbutton " [(ngModel)]="adresstwo">
                                    <div *ngIf="submitted && f.adresstwo.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="f.adresstwo.errors.required ">Address two is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <BR /> &nbsp;
                    <div class="col-md-12 row">
                        <div class="col-md-4 row">&nbsp;</div>
                        <div class="col-md-3 row">
                            <button (click)="SaveBasicDraft(stepper1)"  id="signinbutton">Draft</button>

                        </div>
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-3 row">
                            <button (click)="signIn(stepper1)"  id="signinbutton">Next</button>
                        </div>

                    </div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                </div>
            </div>
        </div>
    </mat-step>


    <mat-step label="Step 2" state="account" style="text-align: center; height: 96%; width: 136%; ">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
          <div class="col-md-1 "></div>
          <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
              <div class="homecard " id="signin " align="center " style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                  <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Bank Details</h2>

                  <div class="col-md-12 ">&nbsp;</div>
                  <div class="col-md-12 ">&nbsp;</div>

                  <div class="container" style="margin-top: 5%">

                      <table class="table table-striped table-responsive table-bordered">
                          <thead>
                              <tr>
                                  <th>Bank Account Type</th>
                                  <th>Bank Name</th>
                                  <th>Branch</th>
                                  <th>IFSC Code</th>
                                  <th>Cheque Facility</th>
                                  <!-- <th>Loan Facility</th> -->
                                  <th></th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let field of fieldArray; let i = index">
                                  <td>
                                      <select (change)="onSelectbank($event.target.value)" class="form-control">
                                      <option value="">Select Bank Account Type</option>
                                      <option *ngFor="let do of banks" value={{do.bankAccTypeId}}
                                          [selected]="do.bankAccTypeId == field.banktypeid">
                                          {{do.accountType}}
                                      </option>
                                  </select>
                                  </td>
                                  <td>
                                      <input [(ngModel)]="field.bankname" class="form-control" type="text" name="{{field.bankname}}" />
                                  </td>
                                  <td>
                                      <input [(ngModel)]="field.branch" class="form-control" type="text" name="{{field.branch}}" />
                                  </td>
                                  <td>
                                      <input [(ngModel)]="field.ifscecode" class="form-control" type="text" name="{{field.ifscecode}}" />
                                  </td>
                                  <td>
                                      <input [(ngModel)]="field.selectedchequetypeid" class="form-control" />
                                  </td>
                                  <!-- <td>
                                  <input [(ngModel)]="field.loanfacility" class="form-control" type="checkbox"
                                      name="{{field.loanfacility}}" />
                              </td> -->
                                  <td>
                                      <button class="btn btn-default" type="button" (click)="deleteFieldValue(i)">Delete</button>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                      <select (change)="onSelectbank($event.target.value)" class="form-control">
                                      <option value="">Select Bank Account Type</option>
                                      <option *ngFor="let do of banks" value={{do.bankAccTypeId}}>
                                          {{do.accountType}}
                                      </option>
                                  </select>
                                  </td>
                                  <td>
                                      <input class="form-control" type="text" id="bankname" [(ngModel)]="newAttribute.bankname" name="bankname" />

                                  </td>
                                  <td>
                                      <input class="form-control" type="text" id="branch" [(ngModel)]="newAttribute.branch" name="branch" />
                                  </td>
                                  <td>
                                      <input class="form-control" type="text" id="ifscecode" [(ngModel)]="newAttribute.ifscecode" name="ifscecode" />
                                  </td>
                                  <td>
                                      <select (change)="onSelectChequeType($event.target.value)" class="form-control">
                                      <option value="">Select Cheque Facility Type</option>
                                      <option value="1">Yes</option>
                                      <option value="2">No</option>
                                  </select>
                                      <!-- <input class="form-control" type="checkbox" id="chequefacility"
                                      [(ngModel)]="newAttribute.chequefacility" name="chequefacility" /> -->
                                  </td>
                                  <!-- <td>
                                  <input class="form-control" type="checkbox" id="loanfacility"
                                      [(ngModel)]="newAttribute.loanfacility" name="loanfacility" />
                              </td> -->
                                  <td>
                                      <button class="btn btn-default" type="button" (click)="addFieldValue()">Add</button>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
                  <div class="col-md-12 row ">&nbsp;</div>

                  <form class="register-form " [formGroup]="BankDetailsForm">
                      <div class="col-md-12 ">&nbsp;</div>

                      <div class="col-md-12 row ">&nbsp;</div>
                      <div class="col-md-12 row ">&nbsp;</div>

                      <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Identity Details</h4>
                      <div class="col-md-12 row ">&nbsp;</div>
                      <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Ration Card Number</label>
                            <input formControlName="rationcardnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="rationcardnum">
                            <!-- <div *ngIf="submitted && k.rationcardnum.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.rationcardnum.errors.required "> Ration Card
                                Number is required</div>
                            <div style="color: red; " *ngIf="k.rationcardnum.errors.pattern ">enter valid Ration
                                Card Number</div>
                        </div> -->
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Aadhar Number</label>
                            <input  [ngClass]="{ 'is-invalid': submitted && k.aadharnum.errors } " formControlName="aadharnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="aadharnum" >
                            <div *ngIf="submitted && k.aadharnum.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.aadharnum.errors.required ">Aadhar Number is required
                            </div>
                            <div style="color: red; " *ngIf="k.aadharnum.errors.pattern ">enter valid Aadhar Number
                            </div>

                        </div>
                        <!-- <div style="color: red; " *ngIf="aadhaarNumberError" class="error-message">Invalid Aadhaar number</div> -->
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Voter ID Number</label>
                            <input [ngClass]="{ 'is-invalid': submitted && k.voternum.errors } " formControlName="voternum" class="form-control " type="text " id="inputbutton " [(ngModel)]="voternum"  >
                            <div *ngIf="submitted && k.voternum.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.voternum.errors.required "> Voter ID Number is required
                            </div>
                            <div style="color: red; " *ngIf="k.voternum.errors.pattern ">enter valid Voter ID
                            </div>

                        </div>

                        <!-- <div  style="color: red; " *ngIf="voterIdError" class="error-message">Invalid Voter ID</div> -->
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">PAN Number</label>
                            <input [ngClass]="{ 'is-invalid': submitted && k.pannumber.errors }" formControlName="pannumber" class="form-control " type="Date " id="inputbutton " [(ngModel)]="pannumber">
                            <div *ngIf="submitted && k.pannumber.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.pannumber.errors.required "> PAN Number is required
                                </div>
                                <div style="color: red; " *ngIf="k.pannumber.errors.pattern ">enter valid PAN Number
                                </div>

                            </div>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">GST No</label>
                            <input [ngClass]="{ 'is-invalid': submitted && k.servicetax.errors }" formControlName="servicetax" class="form-control " type="text " id="inputbutton " [(ngModel)]="servicetax" >
                            <div *ngIf="submitted && k.servicetax.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.servicetax.errors.required ">Service Tax Number
                                is required</div>
                            <div style="color: red; " *ngIf="k.servicetax.errors.pattern ">enter valid GST No
                            </div>

                        </div>

                        <!-- <div *ngIf="gstNumberError" class="error-message">Invalid GST Number</div> -->
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Passport Number</label>
                            <input [ngClass]="{ 'is-invalid': submitted && k.passportnum.errors }" formControlName="passportnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="passportnum"  >
                            <div *ngIf="submitted && k.passportnum.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.passportnum.errors.required "> Passport Number is
                                required</div>
                            <div style="color: red; " *ngIf="k.passportnum.errors.pattern ">enter valid Passport
                                Number </div>

                        </div>
                        <!-- <div *ngIf="passportNumberError" class="error-message">Invalid passport number</div> -->
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                      <div class="col-md-12 row ">
                          <div class="col-md-1 row ">&nbsp;</div>
                          <div class="col-md-3 row ">
                              <label id="emaillabel ">Life Insurance Value</label>
                              <input formControlName="lifeinsurance" class="form-control " type="input " id="inputbutton " [(ngModel)]="lifeinsurance">
                              <!-- <div *ngIf="submitted && k.lifeinsurance.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="k.lifeinsurance.errors.required ">Life Insurance
                                  Value is required</div>
                          </div> -->
                          </div>

                          <div class="col-md-1 row ">&nbsp;</div>
                          <div class="col-md-3 row ">
                              <label for="Email " id="emaillabel ">Confirm</label>
                              <input class="form-control" formControlName="IsConfirm" [(ngModel)]="IsConfirm" (change)="onSelectIsConfirm($event)" type="checkbox" id="inputbutton ">

                          </div>
                      </div>
                      <div class="col-md-12 row ">&nbsp;</div>
                      <div class="col-md-12 row ">&nbsp;</div>

                      <BR />

                      <BR />
                      <div *ngIf="bankdocsexist">
                          <div class="col-md-12 row">&nbsp;</div>
                      <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                      <div class="col-md-12 row">&nbsp;</div>
                       <div class="col-md-12 row">
                          <div class="col-md-1 row">&nbsp;</div>

                           <div class="col-md-2 row" *ngFor="let item of bankdocs">
                               <img [src]="item.file" height="100" width="100"/>
                               <br/>
                               <p style="text-align: center;">{{item.documentName}}</p>
                           </div>
                       </div> 
                      </div>  
                      <div class="col-md-12 row">&nbsp;</div>
                      <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Upload Documents</h4>

                      <div class="col-md-12 row">&nbsp;</div>
                        <div class="container" style="margin-top: 5%">
                            <div class="col-md-12 row">
                                <div class="col-md-3">&nbsp;</div>
                                <div class="col-md-9">
                                    <div class="table-responsive" style="overflow-x: visible">
                                        <table class="table table-striped table-responsive table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Sl No</th>
                                                    <th>Certificate Name</th>
                                                    <th>Upload Here</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Aadhar Card</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField (change)="onChangeAadhar($event)">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Voter ID</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField (change)="onChangeVoterId($event)">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Passport</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField (change)="onChangePassport($event)">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>GST Certificate</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField (change)="onChangeGSTCert($event)">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>IT Returns</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField (change)="onChangeITReturns($event)">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>Bank Statements</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField (change)="onChangeBankStatements($event)">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                      <div class="col-md-12 row">&nbsp;</div>
                  </form>
                  <BR />
                  <div class="col-md-12 row">
                      <div class="col-md-4 row">&nbsp;</div>
                      <div class="col-md-3 row">
                          <button (click)="SaveBankDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;

                      </div>
                      <br />
                      <div class="col-md-1 row">
                      </div>
                      <div class="col-md-3 row">
                          <button (click)="bankIn(stepper1)" id="signinbutton">Next</button>
                      </div>

                  </div>                    <div class="col-md-12 row">&nbsp;</div>
                  <div class="col-md-12 row">&nbsp;</div>
              </div>
          </div>
      </div>
  </mat-step>



  <mat-step label="Step 3" state="insurance" style="text-align: center; height: 96%; width: 136%; ">

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
        <div class="col-md-1 "></div>
        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 ">
            <div class="homecard " id="eignin " align="center " style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">

                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Life Insurance</h2>

                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>


                <form class="register-form" [formGroup]="lifeinsuranceform">
                    
                  <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Employment Details</h4>
                  <div class="col-md-12 row ">&nbsp;</div>
                  <div class="col-md-12 row ">&nbsp;</div>
                  <div class="col-md-12 row ">
                      <div class="col-md-2 row ">&nbsp;</div>
                      <div class="col-md-3 row ">
                          <label for="Email " id="emaillabel ">Occupation</label>
                          <input [ngClass]="{ 'is-invalid': submitted && e.empOccupation.errors }" 
                          (keyup.enter)="saveInsurance(stepper1) " formControlName="empOccupation" class="form-control "
                           type="text " id="inputbutton " [(ngModel)]="empOccupation" >
                          <div *ngIf="submitted && e.empOccupation.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.empOccupation.errors.required">Occupation is required
                              </div>
                          </div>
                      </div>
                      <div class="col-md-1 row ">&nbsp;</div>
                      <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Nature of Work</label>
                        <input [ngClass]="{ 'is-invalid': submitted && e.empNatureofWork.errors }" 
                        (keyup.enter)="saveInsurance(stepper1) " formControlName="empNatureofWork" class="form-control "
                         type="text " id="inputbutton " [(ngModel)]="empNatureofWork" >
                        <div *ngIf="submitted && e.empNatureofWork.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="e.empNatureofWork.errors.required">Nature of Work is required
                            </div>
                        </div>
                      </div>
                      <div class="col-md-1 row ">&nbsp;</div>
                      <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Length of Service</label>
                        <input [ngClass]="{ 'is-invalid': submitted && e.emplengthofService.errors }" 
                        (keyup.enter)="saveInsurance(stepper1) " formControlName="emplengthofService" class="form-control "
                         type="text " id="inputbutton " [(ngModel)]="emplengthofService" >
                         <div *ngIf="submitted && e.emplengthofService.errors" class="invalid-feedback">
                          <div style="color: red; " *ngIf="e.emplengthofService.errors.required">Length of Service is required
                          </div>
                      </div>
                      </div>

                  </div>
                  <div class="col-md-12 row ">&nbsp;</div>

                  <div class="col-md-12 row ">
                      <div class="col-md-2 row ">&nbsp;</div>
                      <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Annual Income(In Rs.)</label>
                        <input [ngClass]="{ 'is-invalid': submitted && e.empAnnualIncome.errors }" 
                        (keyup.enter)="saveInsurance(stepper1) " formControlName="empAnnualIncome" 
                        class="form-control "
                         type="number " id="inputbutton " [(ngModel)]="empAnnualIncome" >
                         <div *ngIf="submitted && e.empAnnualIncome.errors" class="invalid-feedback">
                          <div style="color: red; " *ngIf="e.empAnnualIncome.errors.required">Annual Income is required
                          </div>
                          </div>

                  </div>

                      <div class="col-md-1 row ">&nbsp;</div>
                      <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Details of Income proof submitted</label>
                        <input [ngClass]="{ 'is-invalid': submitted && e.empIncomeProof.errors }" 
                        (keyup.enter)="saveInsurance(stepper1) " formControlName="empIncomeProof" 
                        class="form-control "
                         type="text " id="inputbutton " [(ngModel)]="empIncomeProof" >
                          <div *ngIf="submitted && e.empIncomeProof.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.empIncomeProof.errors.required">
                                Income Proof Details is required
                              </div>
                          </div>
                      </div>
                      <div class="col-md-1 row ">&nbsp;</div>
                      <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Whether covered under pension
                          scheme
                          </label>
                        <input [ngClass]="{ 'is-invalid': submitted && e.empunderPension.errors }" 
                        (keyup.enter)="saveInsurance(stepper1) " formControlName="empunderPension" 
                        class="form-control "
                         type="text " id="inputbutton " [(ngModel)]="empunderPension" >
                          <div *ngIf="submitted && e.empunderPension.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.empunderPension.errors.required">
                                Whether covered under pension
                          scheme is required
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12 row ">&nbsp;</div>

                  <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                      <label for="Email " id="emaillabel ">Normal retirement age</label>
                      <input [ngClass]="{ 'is-invalid': submitted && e.empnormalRetirement.errors }" 
                      (keyup.enter)="saveInsurance(stepper1) " formControlName="empnormalRetirement" 
                      class="form-control "
                       type="text " id="inputbutton " [(ngModel)]="empnormalRetirement" >
                       <div *ngIf="submitted && e.empnormalRetirement.errors" class="invalid-feedback">
                        <div style="color: red; " *ngIf="e.empnormalRetirement.errors.required">
                          Normal retirement age is required
                        </div>
                        </div>
                      </div>
                  </div>

                  <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> 
                    Pension Details</h4>

                  <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                      <label for="Email " id="emaillabel ">Employer’s Scheme/Insurance</label>
                      <input [ngClass]="{ 'is-invalid': submitted && e.empschemeInsurance.errors }" 
                      (keyup.enter)="saveInsurance(stepper1) " formControlName="empschemeInsurance" 
                      class="form-control "
                       type="text " id="inputbutton " [(ngModel)]="empschemeInsurance" >
                       <div *ngIf="submitted && e.empschemeInsurance.errors" class="invalid-feedback">
                        <div style="color: red; " *ngIf="e.empschemeInsurance.errors.required">
                          Employer’s Scheme/Insurance is required
                        </div>
                        </div>
                      </div>
                      <div class="col-md-1 row ">&nbsp;</div>
                      <div class="col-md-3 row ">

                        <label for="Email " id="emaillabel ">Personal contribution/Premium</label>
                        <input [ngClass]="{ 'is-invalid': submitted && e.emppersonalPremium.errors }" 
                        (keyup.enter)="saveInsurance(stepper1) " formControlName="emppersonalPremium" 
                        class="form-control "
                         type="text " id="inputbutton " [(ngModel)]="emppersonalPremium" >
                         <div *ngIf="submitted && e.emppersonalPremium.errors" class="invalid-feedback">
                          <div style="color: red; " *ngIf="e.emppersonalPremium.errors.required">
                            Personal contribution/Premium is required
                          </div>
                          </div>

                      </div>
                      <div class="col-md-1 row ">&nbsp;</div>
                      <div class="col-md-3 row ">

                        <label for="Email " id="emaillabel ">Retirement age</label>
                      <input [ngClass]="{ 'is-invalid': submitted && e.empretirementage.errors }" 
                      (keyup.enter)="saveInsurance(stepper1) " formControlName="empretirementage" 
                      class="form-control "
                       type="text " id="inputbutton " [(ngModel)]="empretirementage" >
                       <div *ngIf="submitted && e.empretirementage.errors" class="invalid-feedback">
                        <div style="color: red; " *ngIf="e.empretirementage.errors.required">
                          Retirement age is required
                        </div>
                        </div>

                      </div>
                  </div>

                  <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                      <label for="Email " id="emaillabel ">Anticipated value</label>
                      <input [ngClass]="{ 'is-invalid': submitted && e.empanticipatedValue.errors }" 
                      (keyup.enter)="saveInsurance(stepper1) " formControlName="empanticipatedValue" 
                      class="form-control "
                       type="text " id="inputbutton " [(ngModel)]="empanticipatedValue" >
                     
                      </div>
                  </div>
                  <div class="col-md-12">&nbsp;</div>
                  <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-8 row ">
                      <label for="Email " id="emaillabel ">How do you project your work span?</label>
                      <select (change)="onSelectProjectSpan($event.target.value)" class="form-control">
                        <option value="">Select Project Span</option>
                        <option *ngFor="let do of ProjectWorkSpans" value={{do.id}} 
                        [selected]="do.id==projectSpanId">
                          {{do.projectWorkSpan}}
                        </option>
                      </select>
                      </div>
                  </div>
                  <div class="col-md-12">&nbsp;</div>

                  <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> 
                    Income/Expenditure --Current and projected</h4>
                  <div class="col-md-12 row ">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-12 row">
                    
                      <br/>

                      <div class="col-md-12 row ">
                        <div class="col-md-4">

                            <div style="    padding: 0%;">YEAR</div><br/>
                            <div style="    padding: 2%;">Last Year</div><br/>
                            <div style="    padding: 2%;">Current Year</div><br/>
                            <div style="    padding: 3%;">5-10 Years</div><br/>
                            <div style="    padding: 3%;">10-15 Years</div><br/>
                            <div style="    padding: 3%;">15-20 Years</div><br/>
                            <div style="    padding: 3%;">20-25 Years</div><br/>
                            <div>25-30 Years</div>
  
                        </div>
                        <div class="col-md-4 row ">
                          <div class="column">
                            <label>Income (In Rs.)</label>
  
                            
                            <input [ngClass]="{ 'is-invalid': submitted && e.incomelastyear.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="incomelastyear" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="incomelastyear" >
                             <br/>                               <div *ngIf="submitted && e.incomelastyear.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.incomelastyear.errors.required">
                                Last year Income is required
                              </div>
                              </div>
  
                              <input [ngClass]="{ 'is-invalid': submitted && e.incomecuryear.errors }" 
                              (keyup.enter)="saveInsurance(stepper1) " formControlName="incomecuryear" 
                              class="form-control "
                               type="text " id="inputbutton " [(ngModel)]="incomecuryear" >
                               <br/>   
                               <div *ngIf="submitted && e.incomecuryear.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="e.incomecuryear.errors.required">
                                  Current year Income is required
                                </div>
                                </div>
  
                                <input [ngClass]="{ 'is-invalid': submitted && e.income5to10.errors }" 
                                (keyup.enter)="saveInsurance(stepper1) " formControlName="income5to10" 
                                class="form-control "
                                 type="text " id="inputbutton " [(ngModel)]="income5to10" >
                                 <br/>   
                                 <div *ngIf="submitted && e.income5to10.errors" class="invalid-feedback">
                                  <div style="color: red; " *ngIf="e.income5to10.errors.required">
                                    5-10 Years Income value is required
                                  </div>
                                  </div>
  
                                  <input [ngClass]="{ 'is-invalid': submitted && e.income10to15.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="income10to15" 
                                  class="form-control "
                                   type="text " id="inputbutton " [(ngModel)]="income10to15" >
                                   <br/>   
                                   <div *ngIf="submitted && e.income10to15.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.income10to15.errors.required">
                                      10-15 Years Income value is required
                                    </div>
                                    </div>
  
                                    <input [ngClass]="{ 'is-invalid': submitted && e.income15to20.errors }" 
                                    (keyup.enter)="saveInsurance(stepper1) " formControlName="income15to20" 
                                    class="form-control "
                                     type="text " id="inputbutton " [(ngModel)]="income15to20" >
                                     <br/>   
                                     <div *ngIf="submitted && e.income15to20.errors" class="invalid-feedback">
                                      <div style="color: red; " *ngIf="e.income15to20.errors.required">
                                        15-20 Years Income value is required
                                      </div>
                                      </div>
  
                                      <input [ngClass]="{ 'is-invalid': submitted && e.income20to25.errors }" 
                                      (keyup.enter)="saveInsurance(stepper1) " formControlName="income20to25" 
                                      class="form-control "
                                       type="text " id="inputbutton " [(ngModel)]="income20to25" >
                                       <br/>   
                                       <div *ngIf="submitted && e.income20to25.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="e.income20to25.errors.required">
                                          20-25 Years Income value is required
                                        </div>
                                        </div>
  
                                        <input [ngClass]="{ 'is-invalid': submitted && e.income25to30.errors }" 
                                        (keyup.enter)="saveInsurance(stepper1) " formControlName="income25to30" 
                                        class="form-control "
                                         type="text " id="inputbutton " [(ngModel)]="income25to30" >
                                         <br/>   
                                         <div *ngIf="submitted && e.income25to30.errors" class="invalid-feedback">
                                          <div style="color: red; " *ngIf="e.income25to30.errors.required">
                                            25-30 Years Income value is required
                                          </div>
                                          </div>
                          </div>
                        </div>
                        <div class="col-md-4 row ">
                          <div class="column">
                            <label>Expenditure (In Rs.)</label>
  
                            <input [ngClass]="{ 'is-invalid': submitted && e.explastyear.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="explastyear" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="explastyear" >
                             <br/>   
                             <div *ngIf="submitted && e.explastyear.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.explastyear.errors.required">
                                Last year Expenditure is required
                              </div>
                              </div>
  
                              <input [ngClass]="{ 'is-invalid': submitted && e.expcuryear.errors }" 
                              (keyup.enter)="saveInsurance(stepper1) " formControlName="expcuryear" 
                              class="form-control "
                               type="text " id="inputbutton " [(ngModel)]="expcuryear" >
                               <br/>   
                               <div *ngIf="submitted && e.expcuryear.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="e.expcuryear.errors.required">
                                  Current year Expenditure is required
                                </div>
                                </div>
  
                                <input [ngClass]="{ 'is-invalid': submitted && e.exp5to10.errors }" 
                                (keyup.enter)="saveInsurance(stepper1) " formControlName="exp5to10" 
                                class="form-control "
                                 type="text " id="inputbutton " [(ngModel)]="exp5to10" >
                                 <br/>   
                                 <div *ngIf="submitted && e.exp5to10.errors" class="invalid-feedback">
                                  <div style="color: red; " *ngIf="e.exp5to10.errors.required">
                                    5-10 Years Expenditure value is required
                                  </div>
                                  </div>
  
                                  <input [ngClass]="{ 'is-invalid': submitted && e.exp10to15.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="exp10to15" 
                                  class="form-control "
                                   type="text " id="inputbutton " [(ngModel)]="exp10to15" >
                                   <br/>   
                                   <div *ngIf="submitted && e.exp10to15.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.exp10to15.errors.required">
                                      10-15 Years Expenditure value is required
                                    </div>
                                    </div>
  
                                    <input [ngClass]="{ 'is-invalid': submitted && e.exp15to20.errors }" 
                                    (keyup.enter)="saveInsurance(stepper1) " formControlName="exp15to20" 
                                    class="form-control "
                                     type="text " id="inputbutton " [(ngModel)]="exp15to20" >
                                     <br/>   
                                     <div *ngIf="submitted && e.exp15to20.errors" class="invalid-feedback">
                                      <div style="color: red; " *ngIf="e.exp15to20.errors.required">
                                        15-20 Years Expenditure value is required
                                      </div>
                                      </div>
  
                                      <input [ngClass]="{ 'is-invalid': submitted && e.exp20to25.errors }" 
                                      (keyup.enter)="saveInsurance(stepper1) " formControlName="exp20to25" 
                                      class="form-control "
                                       type="text " id="inputbutton " [(ngModel)]="exp20to25" >
                                       <br/>   
                                       <div *ngIf="submitted && e.exp20to25.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="e.exp20to25.errors.required">
                                          20-25 Years Expenditure value is required
                                        </div>
                                        </div>
  
                                        <input [ngClass]="{ 'is-invalid': submitted && e.exp25to30.errors }" 
                                        (keyup.enter)="saveInsurance(stepper1) " formControlName="exp25to30" 
                                        class="form-control "
                                         type="text " id="inputbutton " [(ngModel)]="exp25to30" >
                                         <br/>   
                                         <div *ngIf="submitted && e.exp25to30.errors" class="invalid-feedback">
                                          <div style="color: red; " *ngIf="e.exp25to30.errors.required">
                                            25-30 Years Expenditure value is required
                                          </div>
                                          </div>
                          </div>
                        </div>
                      </div>

                      
                      </div>
                  </div>


                  <div class="col-md-12">&nbsp;</div>

                  <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> 
                    Financial Details</h4>
                  <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                      <label for="Email " id="emaillabel ">Value of savings and assets</label>
                      <input [ngClass]="{ 'is-invalid': submitted && e.finsavings.errors }" 
                      (keyup.enter)="saveInsurance(stepper1) " formControlName="finsavings" 
                      class="form-control "
                       type="text " id="inputbutton " [(ngModel)]="finsavings" >
                       <div *ngIf="submitted && e.finsavings.errors" class="invalid-feedback">
                        <div style="color: red; " *ngIf="e.finsavings.errors.required">
                          Value of savings and assets is required
                        </div>
                        </div>
                      </div>
                      <div class="col-md-1 row ">&nbsp;</div>

                      <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Details of liabilities</label>
                        <input [ngClass]="{ 'is-invalid': submitted && e.finliabilities.errors }" 
                        (keyup.enter)="saveInsurance(stepper1) " formControlName="finliabilities" 
                        class="form-control "
                         type="text " id="inputbutton " [(ngModel)]="finliabilities" >
                         <div *ngIf="submitted && e.finliabilities.errors" class="invalid-feedback">
                          <div style="color: red; " *ngIf="e.finliabilities.errors.required">
                            Details of liabilities is required
                          </div>
                          </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>

                        <div class="col-md-3 row ">
                          <label for="Email " id="emaillabel ">Expected inheritance</label>
                          <input [ngClass]="{ 'is-invalid': submitted && e.fininheritance.errors }" 
                          (keyup.enter)="saveInsurance(stepper1) " formControlName="fininheritance" 
                          class="form-control "
                           type="text " id="inputbutton " [(ngModel)]="fininheritance" >
                           <div *ngIf="submitted && e.fininheritance.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="e.fininheritance.errors.required">
                              Expected inheritance is required
                            </div>
                            </div>
                          </div>
                  </div>

                  <div class="col-md-12 row">&nbsp;</div>

                  <!-- LIFE HEALTH -->
                    <br/>
                    <div class="col-md-12 row ">
                      <div class="col-md-3">
                        <div style="    padding: 0%;"> </div><br/>
                        <div style="    padding: 0%;"> </div><br/>
                        <div style="    padding: 0%;">Life </div><br/>
                        <div style="    padding: 2%;">Health</div><br/>
                        <div style="    padding: 2%;">Savings and Investment </div><br/>
                        <div style="    padding: 3%;">Pension</div><br/>
                        <div style="    padding: 3%;">Other ( to specify)</div>
                      </div>
                      <div class="col-md-3">
                        <div class="column">
                          <label style="    font-size: 12px;">Name of Member (whether proposer or covered person)</label>
                          <input [ngClass]="{ 'is-invalid': submitted && e.lifeNameMemberOne.errors }" 
                          (keyup.enter)="saveInsurance(stepper1) " formControlName="lifeNameMemberOne" 
                          class="form-control "
                           type="text " id="inputbutton " [(ngModel)]="lifeNameMemberOne" >
                           <br/>                              
                            <div *ngIf="submitted && e.lifeNameMemberOne.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="e.lifeNameMemberOne.errors.required">
                              Life Name Member is required
                            </div>
                            </div>

                            <input [ngClass]="{ 'is-invalid': submitted && e.healthNameMemberOne.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="healthNameMemberOne" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="healthNameMemberOne" >
                             <br/>   
                             <div *ngIf="submitted && e.healthNameMemberOne.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.healthNameMemberOne.errors.required">
                                Health Name Member is required
                              </div>
                              </div>

                              <input [ngClass]="{ 'is-invalid': submitted && e.savingsNameMemberOne.errors }" 
                              (keyup.enter)="saveInsurance(stepper1) " formControlName="savingsNameMemberOne" 
                              class="form-control "
                               type="text " id="inputbutton " [(ngModel)]="savingsNameMemberOne" >
                               <br/>   
                               <div *ngIf="submitted && e.savingsNameMemberOne.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="e.savingsNameMemberOne.errors.required">
                                  Savings Name Member value is required
                                </div>
                                </div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.pensionNameMember.errors }" 
                                (keyup.enter)="saveInsurance(stepper1) " formControlName="pensionNameMember" 
                                class="form-control "
                                 type="text " id="inputbutton " [(ngModel)]="pensionNameMember" >
                                 <br/>   
                                 <div *ngIf="submitted && e.pensionNameMember.errors" class="invalid-feedback">
                                  <div style="color: red; " *ngIf="e.pensionNameMember.errors.required">
                                    Pension Name Member value is required
                                  </div>
                                  </div>

                                  <input [ngClass]="{ 'is-invalid': submitted && e.otheNameofMember.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="otheNameofMember" 
                                  class="form-control "
                                   type="text " id="inputbutton " [(ngModel)]="otheNameofMember" >
                                   <br/>   
                                   <div *ngIf="submitted && e.otheNameofMember.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.otheNameofMember.errors.required">
                                      Other Name ofMember value is required
                                    </div>
                                    </div>
                        </div>
                      </div>
                      <div class="col-md-2">


                        <div class="column">
                          <label  style="font-size: 12px;">Details of premium being paid</label>

                              <input [ngClass]="{ 'is-invalid': submitted && e.lifeDetailsone.errors }" 
                              (keyup.enter)="saveInsurance(stepper1) " formControlName="lifeDetailsone" 
                              class="form-control "
                               type="text " id="inputbutton " [(ngModel)]="lifeDetailsone" >
                               <br/>   
                               <div *ngIf="submitted && e.lifeDetailsone.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="e.lifeDetailsone.errors.required">
                                  Life Details value is required
                                </div>
                                </div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.healthDetailsone.errors }" 
                                (keyup.enter)="saveInsurance(stepper1) " formControlName="healthDetailsone" 
                                class="form-control "
                                 type="text " id="inputbutton " [(ngModel)]="healthDetailsone" >
                                 <br/>   
                                 <div *ngIf="submitted && e.healthDetailsone.errors" class="invalid-feedback">
                                  <div style="color: red; " *ngIf="e.healthDetailsone.errors.required">
                                    Health Details value is required
                                  </div>
                                  </div>

                                  <input [ngClass]="{ 'is-invalid': submitted && e.savingsDetailsone.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="savingsDetailsone" 
                                  class="form-control "
                                   type="text " id="inputbutton " [(ngModel)]="savingsDetailsone" >
                                   <br/>   
                                   <div *ngIf="submitted && e.savingsDetailsone.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.savingsDetailsone.errors.required">
                                      Savings Details one value is required
                                    </div>
                                    </div>

                                    <input [ngClass]="{ 'is-invalid': submitted && e.pensionDetailsone.errors }" 
                                    (keyup.enter)="saveInsurance(stepper1) " formControlName="pensionDetailsone" 
                                    class="form-control "
                                     type="text " id="inputbutton " [(ngModel)]="pensionDetailsone" >
                                     <br/>   
                                     <div *ngIf="submitted && e.pensionDetailsone.errors" class="invalid-feedback">
                                      <div style="color: red; " *ngIf="e.pensionDetailsone.errors.required">
                                        pension Details one value is required
                                      </div>
                                      </div>

                                      <input [ngClass]="{ 'is-invalid': submitted && e.otherDetailsone.errors }" 
                                      (keyup.enter)="saveInsurance(stepper1) " formControlName="otherDetailsone" 
                                      class="form-control "
                                       type="text " id="inputbutton " [(ngModel)]="otherDetailsone" >
                                       <br/>   
                                       <div *ngIf="submitted && e.otherDetailsone.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="e.otherDetailsone.errors.required">
                                          Other Details value is required
                                        </div>
                                        </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="column">
                          <label  style="    font-size: 10.7px;">Name of Member (whether proposer or covered person)</label>
                          <input [ngClass]="{ 'is-invalid': submitted && e.lifeNameMemberTwo.errors }" 
                          (keyup.enter)="saveInsurance(stepper1) " formControlName="lifeNameMemberTwo" 
                          class="form-control "
                           type="text " id="inputbutton " [(ngModel)]="lifeNameMemberTwo" >
                           <br/>                               <div *ngIf="submitted && e.lifeNameMemberTwo.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="e.lifeNameMemberTwo.errors.required">
                              Life Name Member is required
                            </div>
                            </div>

                            <input [ngClass]="{ 'is-invalid': submitted && e.healthNameMemberTwo.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="healthNameMemberTwo" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="healthNameMemberTwo" >
                             <br/>   
                             <div *ngIf="submitted && e.healthNameMemberTwo.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.healthNameMemberTwo.errors.required">
                                Health Name Member  is required
                              </div>
                              </div>

                              <input [ngClass]="{ 'is-invalid': submitted && e.savingsNameMemberTwo.errors }" 
                              (keyup.enter)="saveInsurance(stepper1) " formControlName="savingsNameMemberTwo" 
                              class="form-control "
                               type="text " id="inputbutton " [(ngModel)]="savingsNameMemberTwo" >
                               <br/>   
                               <div *ngIf="submitted && e.savingsNameMemberTwo.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="e.savingsNameMemberTwo.errors.required">
                                  Savings Name Member is required
                                </div>
                                </div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.pensionNameMemberTwo.errors }" 
                                (keyup.enter)="saveInsurance(stepper1) " formControlName="pensionNameMemberTwo" 
                                class="form-control "
                                 type="text " id="inputbutton " [(ngModel)]="pensionNameMemberTwo" >
                                 <br/>   
                                 <div *ngIf="submitted && e.pensionNameMemberTwo.errors" class="invalid-feedback">
                                  <div style="color: red; " *ngIf="e.pensionNameMemberTwo.errors.required">
                                    Pension Name Member is required
                                  </div>
                                  </div>

                                  <input [ngClass]="{ 'is-invalid': submitted && e.otherNameMemberTwo.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="otherNameMemberTwo" 
                                  class="form-control "
                                   type="text " id="inputbutton " [(ngModel)]="otherNameMemberTwo" >
                                   <br/>   
                                   <div *ngIf="submitted && e.otherNameMemberTwo.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.otherNameMemberTwo.errors.required">
                                      other Name Member is required
                                    </div>
                                    </div>

                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="column">
                          <label  style="font-size: 12px;">Details of premium being paid</label>

                          <input [ngClass]="{ 'is-invalid': submitted && e.lifeDetailsTwo.errors }" 
                          (keyup.enter)="saveInsurance(stepper1) " formControlName="lifeDetailsTwo" 
                          class="form-control "
                           type="text " id="inputbutton " [(ngModel)]="lifeDetailsTwo" >
                           <br/>                               <div *ngIf="submitted && e.lifeDetailsTwo.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="e.lifeDetailsTwo.errors.required">
                              life Details is required
                            </div>
                            </div>

                            <input [ngClass]="{ 'is-invalid': submitted && e.healthDetailsTwo.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="healthDetailsTwo" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="healthDetailsTwo" >
                             <br/>   
                             <div *ngIf="submitted && e.healthDetailsTwo.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.healthDetailsTwo.errors.required">
                               Health Details is required
                              </div>
                              </div>

                              <input [ngClass]="{ 'is-invalid': submitted && e.savingsDetailsTwo.errors }" 
                              (keyup.enter)="saveInsurance(stepper1) " formControlName="savingsDetailsTwo" 
                              class="form-control "
                               type="text " id="inputbutton " [(ngModel)]="savingsDetailsTwo" >
                               <br/>   
                               <div *ngIf="submitted && e.savingsDetailsTwo.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="e.savingsDetailsTwo.errors.required">
                                  Savings Details value is required
                                </div>
                                </div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.pensionDetailsTwo.errors }" 
                                (keyup.enter)="saveInsurance(stepper1) " formControlName="pensionDetailsTwo" 
                                class="form-control "
                                 type="text " id="inputbutton " [(ngModel)]="pensionDetailsTwo" >
                                 <br/>   
                                 <div *ngIf="submitted && e.pensionDetailsTwo.errors" class="invalid-feedback">
                                  <div style="color: red; " *ngIf="e.pensionDetailsTwo.errors.required">
                                    Pension Details is required
                                  </div>
                                  </div>

                                  <input [ngClass]="{ 'is-invalid': submitted && e.otherDetailsTwo.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="otherDetailsTwo" 
                                  class="form-control "
                                   type="text " id="inputbutton " [(ngModel)]="otherDetailsTwo" >
                                   <br/>   
                                   <div *ngIf="submitted && e.otherDetailsTwo.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.otherDetailsTwo.errors.required">
                                      Other Details is required
                                    </div>
                                    </div>

                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">&nbsp;</div>

                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> 
                      B. SPECIALISED/ADDITIONAL INFORMATION</h4>
                      <div class="col-md-12">&nbsp;</div>
                      <label>Physical/Medical Information (Questionnaire)—for each covered person</label>
                      <div class="col-md-12">&nbsp;</div>
                      

                      
                      <div class="col-md-12 row">
                        <div class="col-md-6">
                          <label>Height</label>
                          <input [ngClass]="{ 'is-invalid': submitted && e.phyHeight.errors }" 
                          (keyup.enter)="saveInsurance(stepper1) " formControlName="phyHeight" 
                          class="form-control "
                           type="text " id="inputbutton " [(ngModel)]="phyHeight" >
                           <br/>                               
                           <div *ngIf="submitted && e.phyHeight.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="e.phyHeight.errors.required">
                              Height is required
                            </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                          <label>Weight</label>
                          <input [ngClass]="{ 'is-invalid': submitted && e.phyWeight.errors }" 
                          (keyup.enter)="saveInsurance(stepper1) " formControlName="phyWeight" 
                          class="form-control "
                           type="text " id="inputbutton " [(ngModel)]="phyWeight" >
                           <br/>                               <div *ngIf="submitted && e.phyWeight.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="e.phyWeight.errors.required">
                              Weight is required
                            </div>
                            </div>
                        </div>
                      </div>
              
                      <div class="col-md-12">&nbsp;</div>
                      <div>
                      <div class="col-md-12" style="text-align: left;">
                        <span id="showpinsText">
                          Do you have any physical deformity/handicap/congenital 
                          defect/abnormality?
                        </span>
                        <mat-slide-toggle formControlName="physicaltg"   [(ngModel)]="physicaltg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                        <span id="showpinsText">
                          Are you currently undergoing/have undergone any tests, investigations,
                           awaiting results of any tests, investigations or have you ever 
                           been advised to undergo any tests, investigations or
                          surgery or been hospitalized for general checkup, observations, 
                          treatment or surgery?
                        </span>
                        <mat-slide-toggle [(ngModel)]="surgerytg" formControlName="surgerytg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                        <span id="showpinsText">
                          Have you ever been referred to an Oncologist or Cancer hospital for any investigation or treatment?                       
                        </span>
                        <mat-slide-toggle [(ngModel)]="Oncologisttg" formControlName="Oncologisttg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                        <span id="showpinsText">
                          Did you have any ailment/injury/accident requiring treatment/medication 
                          for more than a	week?         
                        </span>               
                        <mat-slide-toggle [(ngModel)]="ailmenttg"  formControlName="ailmenttg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                        <span id="showpinsText">
                          Have you ever been absent from work for more than a week in last 2 years due to 
                          any illness?
                          </span>
                          <mat-slide-toggle [(ngModel)]="absentfromworktg" formControlName="absentfromworktg">
                          </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                        <span id="showpinsText">
                          Were you or your spouse ever tested for Hepatitis B or C, HIV/AIDS or any other sexually
                          transmitted disease?                 
                        </span>
                        <mat-slide-toggle [(ngModel)]="Hepatitistg" formControlName="Hepatitistg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">                          
                        <span id="showpinsText">
                          Have you ever suffered Chest pain, Palpitation, Rheumatic fever, Heart Murmur, Heart attack,
                          shortness of Breath or any other Heart related disorder?                  
                        </span>
                        <mat-slide-toggle [(ngModel)]="Rheumatictg" formControlName="Rheumatictg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                        <span id="showpinsText">
                          Have you ever suffered symptoms/ailment relating to Kidney, Prostate,
                          Hydrocele, And Urinary System?                  
                        </span>
                        <mat-slide-toggle [(ngModel)]="Kidneytg" formControlName="Kidneytg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                        <span id="showpinsText">
                          Have you ever suffered Gastritis, Stomach or Duodenal Ulcer, Hernia, Jaundice, Fistula Piles or
                           any other disease or disorders of the Gastrointestinal System?                 
                        </span>
                        <mat-slide-toggle [(ngModel)]="Gastritistg" formControlName="Gastritistg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                       
                        <span id="showpinsText">
                          Have you ever suffered Thyroid disorder or any other disease or disorder of the 
                          Endocrine system?                 
                        </span>
                        <mat-slide-toggle [(ngModel)]="Thyroidtg" formControlName="Thyroidtg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                     
                        <span id="showpinsText">
                          Have you undergone/have been recommended to undergo Angioplasty, Bypass 
                          Surgery, Brain
                          Surgery, Heart Valve Surgery, Aorta Surgery or Organ Transplant?             
                        </span>
                        <mat-slide-toggle [(ngModel)]="Angioplastytg"  formControlName="Angioplastytg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                        
                        <span id="showpinsText">
                          Have you ever suffered Diabetes/ High Blood Sugar?            
                        </span>
                        <mat-slide-toggle [(ngModel)]="Diabetestg" formControlName="Diabetestg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                       
                        <span id="showpinsText">
                          Have you ever suffered High/Low Blood Pressure?             
                        </span>
                        <mat-slide-toggle [(ngModel)]="Bloodpressuretg" formControlName="Bloodpressuretg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                      
                        <span id="showpinsText">
                          Have you ever suffered Disorders of Eye, Ear, Nose,
                           Throat including defective sight, speech or hearing & discharge from ears?        
                        </span>
                        <mat-slide-toggle [(ngModel)]="Throattg" formControlName="Throattg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                       
                        <span id="showpinsText">
                          Have you ever suffered ailments relating to Liver or reproductive System?       
                        </span>
                        <mat-slide-toggle [(ngModel)]="Livertg" formControlName="Livertg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                       
                        <span id="showpinsText">
                          Have you ever suffered Symptoms/ailments relating to Brian, Depression? Mental/Psychiatric	
                           ailment, Multiple Sclerosis, Nervous System, Stroke, Paralysis, Parkinsonism or Epilepsy?       
                        </span>
                        <mat-slide-toggle [(ngModel)]="Sclerosistg" formControlName="Sclerosistg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                       
                        <span id="showpinsText">
                          Have you ever suffered Asthma, Bronchitis, Blood spitting, 
                          Tuberculosis or other Respiratory disorders?    
                        </span>
                        <mat-slide-toggle [(ngModel)]="Bronchitistg" formControlName="Bronchitistg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                        
                        <span id="showpinsText">
                          Have you ever suffered Anaemia, Blood or Blood related disorders?     
                        </span>
                        <mat-slide-toggle [(ngModel)]="Anaemiatg" formControlName="Anaemiatg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                     
                        <span id="showpinsText">
                          Have you ever suffered Musculoskeletal disorders such as Arthritis,
                           Recurrent Back Pain, Slipped disc or any other disorder of Spine, Joints, Limbs or Leprosy?   
                        </span>
                        <mat-slide-toggle [(ngModel)]="Musculoskeletaltg" formControlName="Musculoskeletaltg">
                        </mat-slide-toggle>
                      </div>
                      <div class="col-md-12">&nbsp;</div>

                      <div class="col-md-12" style="text-align: left;">
                      
                        <span id="showpinsText">
                          Have you ever suffered any other illness or impairment not mentioned above?
                        </span>
                        <mat-slide-toggle [(ngModel)]="impairmenttg" formControlName="impairmenttg">
                        </mat-slide-toggle>
                      </div>



                      <!-- raj -->
                      <div class="col-md-12">&nbsp;</div>
                      <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> 
                        Details of Female Prospects /covered person</h4>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12" style="text-align: left;">
                          <span id="showpinsText">
                            Are you Currently Pregnant?
                          </span>
                          <mat-slide-toggle [(ngModel)]="curpregnanttg" formControlName="curpregnanttg">
                          </mat-slide-toggle>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12 row" style="text-align: left;">

                          <div class="col-md-6">
                            <span id="showpinsText">&nbsp;&nbsp;
                              If yes, current months of pregnancy 
                            </span>
                            <input [ngClass]="{ 'is-invalid': submitted && e.pregmonths.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="pregmonths" 
                            class="form-control " type="text " id="inputbutton "  [(ngModel)]="pregmonths ">
                          
                          </div>
                          <div class="col-md-6">
                            <span id="showpinsText">
                              State age of first pregnancy
                            </span>
                            <input [ngClass]="{ 'is-invalid': submitted && e.stageage.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="stageage" 
                            class="form-control " type="text " id="inputbutton "  [(ngModel)]="stageage ">
                        
                          </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>


                        <div class="col-md-12" style="text-align: left;">
                          <span id="showpinsText">
                            Have you ever had any abortion, miscarriage or ectopic pregnancy?
                          </span>
                          <mat-slide-toggle [(ngModel)]="miscariagetg" formControlName="miscariagetg">
                          </mat-slide-toggle>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12" style="text-align: left;">
                          <span id="showpinsText">
                            Have you undergone any gynecological investigations, 
                            internal checkups, breast checks such as mammogram or biopsy?                            </span>
                          <mat-slide-toggle [(ngModel)]="gynecologicaltg" formControlName="gynecologicaltg">
                          </mat-slide-toggle>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12" style="text-align: left;">
                          <span id="showpinsText">
                            Have you ever consulted a doctor because of an 
                            irregularity at the breast, vagina, uterus,
                            ovary, fallopian tubes, menstruation, birth delivery, 
                             complications during pregnancy or
                            child delivery or a sexually transmitted disease?
                         </span>
                          <mat-slide-toggle [(ngModel)]="transmittedtg" formControlName="transmittedtg">
                          </mat-slide-toggle>
                        </div>
                      </div>
                        <div class="col-md-12">&nbsp;</div>
                        <!-- MINI -->
                        <div class="col-md-12 row">
                          <div class="col-md-4">
                          </div>
                          <div class="col-md-4">
                            <div class="form-card" style="border: solid 2px #c60c0c;background-color: #545d64;border-radius: 4%;">
                              <h2 class="fs-titledata" style="text-align: center;">Upload Signature of Proposer</h2>
                              <div class="container fileUploadWrapper">
                                  <div class="col-md-12">
                                      <div class="fileuploaddata" style="background-image: url(./assets/img/upload-icon.png);" appDragDropFileUpload accept="image/*" (click)="fileField.click()" (fileDropped)="onDrop($event)">
                                          <span class="ddinfo">Choose an object or drag here</span>
                                      </div>
                                      <input type="file" accept="image/*" name="avatars" #fileField (change)="onChangeUploadProposerSign($event)">
                                  </div>
                                  <h3 *ngIf="validfile" style="color: red;">Please Upload Only One File of type png or jpg or jpeg</h3>
                              </div>
                          </div>
                          <!-- Uploaded image put here -->
                          <img [src]="dispProposerSignFile" *ngIf="dispProposerSignFile" height="100" width="100"/>

                          </div>
                          <div class="col-md-4">
                          </div>
                        
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>


                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> 
                         C.	NEEDS OF PROSPECT</h4>
                         <div class="col-md-12">&nbsp;</div>

                        <h5>1.	Affordable contribution—Current and projected</h5> 

                        <div class="col-md-12 row ">
                          <div class="col-md-1 row ">&nbsp;</div>
                          <div class="col-md-12 row">
                          
                            <br/>
                            <div class="col-md-12 row ">
                              <div class="col-md-4">
    
                                  <div style="    padding: 0%;">YEAR</div><br/>
                                  <div style="    padding: 2%;">Last Year</div><br/>
                                  <div style="    padding: 2%;">Current Year</div><br/>
                                  <div style="    padding: 3%;">5-10 Years</div><br/>
                                  <div style="    padding: 3%;">10-15 Years</div><br/>
                                  <div style="    padding: 3%;">15-20 Years</div><br/>
                                  <div style="    padding: 3%;">20-25 Years</div><br/>
                                  <div>25-30 Years</div>
        
                              </div>
                              <div class="col-md-4 row ">
                                <div class="column">
                                  <label>Yearly</label>
        
                                  <input [ngClass]="{ 'is-invalid': submitted && e.prospectlastyear.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="prospectlastyear" 
                                  class="form-control "
                                   type="text " id="inputbutton " [(ngModel)]="prospectlastyear" >
                                   <br/>                               <div *ngIf="submitted && e.prospectlastyear.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.prospectlastyear.errors.required">
                                      Last year Prospect is required
                                    </div>
                                    </div>
        
                                    <input [ngClass]="{ 'is-invalid': submitted && e.prospectcuryear.errors }" 
                                    (keyup.enter)="saveInsurance(stepper1) " formControlName="prospectcuryear" 
                                    class="form-control "
                                     type="text " id="inputbutton " [(ngModel)]="prospectcuryear" >
                                     <br/>   
                                     <div *ngIf="submitted && e.prospectcuryear.errors" class="invalid-feedback">
                                      <div style="color: red; " *ngIf="e.prospectcuryear.errors.required">
                                        Current year prospect is required
                                      </div>
                                      </div>
        
                                      <input [ngClass]="{ 'is-invalid': submitted && e.prospect5to10.errors }" 
                                      (keyup.enter)="saveInsurance(stepper1) " formControlName="prospect5to10" 
                                      class="form-control "
                                       type="text " id="inputbutton " [(ngModel)]="prospect5to10" >
                                       <br/>   
                                       <div *ngIf="submitted && e.prospect5to10.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="e.prospect5to10.errors.required">
                                          5-10 Years prospect value is required
                                        </div>
                                        </div>
        
                                        <input [ngClass]="{ 'is-invalid': submitted && e.prospect10to15.errors }" 
                                        (keyup.enter)="saveInsurance(stepper1) " formControlName="prospect10to15" 
                                        class="form-control "
                                         type="text " id="inputbutton " [(ngModel)]="prospect10to15" >
                                         <br/>   
                                         <div *ngIf="submitted && e.prospect10to15.errors" class="invalid-feedback">
                                          <div style="color: red; " *ngIf="e.prospect10to15.errors.required">
                                            10-15 Years prospect value is required
                                          </div>
                                          </div>
        
                                          <input [ngClass]="{ 'is-invalid': submitted && e.prospect15to20.errors }" 
                                          (keyup.enter)="saveInsurance(stepper1) " formControlName="prospect15to20" 
                                          class="form-control "
                                           type="text " id="inputbutton " [(ngModel)]="prospect15to20" >
                                           <br/>   
                                           <div *ngIf="submitted && e.prospect15to20.errors" class="invalid-feedback">
                                            <div style="color: red; " *ngIf="e.prospect15to20.errors.required">
                                              15-20 Years prospect value is required
                                            </div>
                                            </div>
        
                                            <input [ngClass]="{ 'is-invalid': submitted && e.prospect20to25.errors }" 
                                            (keyup.enter)="saveInsurance(stepper1) " formControlName="prospect20to25" 
                                            class="form-control "
                                             type="text " id="inputbutton " [(ngModel)]="prospect20to25" >
                                             <br/>   
                                             <div *ngIf="submitted && e.prospect20to25.errors" class="invalid-feedback">
                                              <div style="color: red; " *ngIf="e.prospect20to25.errors.required">
                                                20-25 Years prospect value is required
                                              </div>
                                              </div>
        
                                              <input [ngClass]="{ 'is-invalid': submitted && e.prospect25to30.errors }" 
                                              (keyup.enter)="saveInsurance(stepper1) " formControlName="prospect25to30" 
                                              class="form-control "
                                               type="text " id="inputbutton " [(ngModel)]="prospect25to30" >
                                               <br/>   
                                               <div *ngIf="submitted && e.prospect25to30.errors" class="invalid-feedback">
                                                <div style="color: red; " *ngIf="e.prospect25to30.errors.required">
                                                  25-30 Years prospect value is required
                                                </div>
                                                </div>
                                </div>
                              </div>
                              <div class="col-md-4 row ">
                                <div class="column">
                                  <label>Monthly</label>
        
                                  <input [ngClass]="{ 'is-invalid': submitted && e.prospectMonthlylastyear.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="prospectMonthlylastyear" 
                                  class="form-control "
                                   type="text " id="inputbutton " [(ngModel)]="prospectMonthlylastyear" >
                                   <br/>   
                                   <div *ngIf="submitted && e.prospectMonthlylastyear.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.prospectMonthlylastyear.errors.required">
                                      Last year prospect Monthly is required
                                    </div>
                                    </div>
        
                                    <input [ngClass]="{ 'is-invalid': submitted && e.prospectMonthlycuryear.errors }" 
                                    (keyup.enter)="saveInsurance(stepper1) " formControlName="prospectMonthlycuryear" 
                                    class="form-control "
                                     type="text " id="inputbutton " [(ngModel)]="prospectMonthlycuryear" >
                                     <br/>   
                                     <div *ngIf="submitted && e.prospectMonthlycuryear.errors" class="invalid-feedback">
                                      <div style="color: red; " *ngIf="e.prospectMonthlycuryear.errors.required">
                                        Current year prospect Monthly is required
                                      </div>
                                      </div>
        
                                      <input [ngClass]="{ 'is-invalid': submitted && e.prospectMonthly5to10.errors }" 
                                      (keyup.enter)="saveInsurance(stepper1) " formControlName="prospectMonthly5to10" 
                                      class="form-control "
                                       type="text " id="inputbutton " [(ngModel)]="prospectMonthly5to10" >
                                       <br/>   
                                       <div *ngIf="submitted && e.prospectMonthly5to10.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="e.prospectMonthly5to10.errors.required">
                                          5-10 Years prospect Monthly value is required
                                        </div>
                                        </div>
        
                                        <input [ngClass]="{ 'is-invalid': submitted && e.prospectMonthly10to15.errors }" 
                                        (keyup.enter)="saveInsurance(stepper1) " formControlName="prospectMonthly10to15" 
                                        class="form-control "
                                         type="text " id="inputbutton " [(ngModel)]="prospectMonthly10to15" >
                                         <br/>   
                                         <div *ngIf="submitted && e.prospectMonthly10to15.errors" class="invalid-feedback">
                                          <div style="color: red; " *ngIf="e.prospectMonthly10to15.errors.required">
                                            10-15 Years prospect Monthly value is required
                                          </div>
                                          </div>
        
                                          <input [ngClass]="{ 'is-invalid': submitted && e.prospectMonthly15to20.errors }" 
                                          (keyup.enter)="saveInsurance(stepper1) " formControlName="prospectMonthly15to20" 
                                          class="form-control "
                                           type="text " id="inputbutton " [(ngModel)]="prospectMonthly15to20" >
                                           <br/>   
                                           <div *ngIf="submitted && e.prospectMonthly15to20.errors" class="invalid-feedback">
                                            <div style="color: red; " *ngIf="e.prospectMonthly15to20.errors.required">
                                              15-20 Years prospect Monthly value is required
                                            </div>
                                            </div>
        
                                            <input [ngClass]="{ 'is-invalid': submitted && e.prospectMonthly20to25.errors }" 
                                            (keyup.enter)="saveInsurance(stepper1) " formControlName="prospectMonthly20to25" 
                                            class="form-control "
                                             type="text " id="inputbutton " [(ngModel)]="prospectMonthly20to25" >
                                             <br/>   
                                             <div *ngIf="submitted && e.prospectMonthly20to25.errors" class="invalid-feedback">
                                              <div style="color: red; " *ngIf="e.prospectMonthly20to25.errors.required">
                                                20-25 Years prospect Monthly value is required
                                              </div>
                                              </div>
        
                                              <input [ngClass]="{ 'is-invalid': submitted && e.prospectMonthly25to30.errors }" 
                                              (keyup.enter)="saveInsurance(stepper1) " formControlName="prospectMonthly25to30" 
                                              class="form-control "
                                               type="text " id="inputbutton " [(ngModel)]="prospectMonthly25to30" >
                                               <br/>   
                                               <div *ngIf="submitted && e.prospectMonthly25to30.errors" class="invalid-feedback">
                                                <div style="color: red; " *ngIf="e.prospectMonthly25to30.errors.required">
                                                  25-30 Years prospect Monthly value is required
                                                </div>
                                                </div>
                                </div>
                              </div>
                            </div>
                            </div>
                        </div>
    




                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <h5>2. Identified insurance needs 
                        </h5> 
                        <div class="col-md-12 row">
                          <div class="col-md-6">
                            <label>Life Insurance ( Death/Maturity) </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.lifeincDeathMaturity.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="lifeincDeathMaturity" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="lifeincDeathMaturity" >
                             <br/>                               
                             <div *ngIf="submitted && e.lifeincDeathMaturity.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.lifeincDeathMaturity.errors.required">
                                Life incurance Death/Maturity is required
                              </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                            <label>Desirable Sum Assured</label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.desirableSumAssured.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="desirableSumAssured" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="desirableSumAssured" >
                             <br/>                               
                             <div *ngIf="submitted && e.desirableSumAssured.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.desirableSumAssured.errors.required">
                                Desirable Sum Assured is required
                              </div>
                              </div>
                          </div>
                        </div>

                        <div class="col-md-12 row">
                          <div class="col-md-6">
                            <label>Health Insurance</label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.healthInsurance.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="healthInsurance" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="healthInsurance" >
                             <br/>                               
                             <div *ngIf="submitted && e.healthInsurance.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.healthInsurance.errors.required">
                                Health Insurance is required
                              </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                            <label>Desirable limit of coverage per annum</label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.desirablecoverageperAnnum.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="desirablecoverageperAnnum" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="desirablecoverageperAnnum" >
                             <br/>                               
                             <div *ngIf="submitted && e.desirablecoverageperAnnum.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.desirablecoverageperAnnum.errors.required">
                                Desirable coverage per Annum is required
                              </div>
                              </div>
                          </div>
                        </div>
                        <div class="col-md-12 row">
                          <div class="col-md-6">
                            <label>Savings and Investment Planning </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.savingandinvestplan.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="savingandinvestplan" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="savingandinvestplan" >
                             <br/>                               
                             <div *ngIf="submitted && e.savingandinvestplan.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.savingandinvestplan.errors.required">
                                Saving andinvestment planning is required
                              </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                            <label>Desirable returns per annum </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.desirablereturns.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="desirablereturns" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="desirablereturns" >
                             <br/>                               
                             <div *ngIf="submitted && e.desirablereturns.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.desirablereturns.errors.required">
                                Desirable Returns is required
                              </div>
                              </div>
                          </div>
                        </div>
                        <div class="col-md-12 row">
                          <div class="col-md-6">
                            <label>Pension planning </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.pensionPlanning.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="pensionPlanning" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="pensionPlanning" >
                             <br/>                               
                             <div *ngIf="submitted && e.pensionPlanning.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.pensionPlanning.errors.required">
                                Pension Planning is required
                              </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                            <label>Desirable pension per annum</label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.desirablepensionperAnnum.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="desirablepensionperAnnum" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="desirablepensionperAnnum" >
                             <br/>                               
                             <div *ngIf="submitted && e.desirablepensionperAnnum.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.desirablepensionperAnnum.errors.required">
                                Desirable pension per Annum is required
                              </div>
                              </div>
                          </div>
                        </div>





                        <div class="col-md-12">&nbsp;</div>

                        <h5>3.	Insurance Plan Details </h5>

                        <div class="col-md-12 row">
                          <div class="col-md-6">
                            <label>Plan Name </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.incPlanName.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="incPlanName" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="incPlanName" >
                             <br/>                               
                             <div *ngIf="submitted && e.incPlanName.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.incPlanName.errors.required">
                                Incurance Plan Name is required
                              </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                            <label>Premium Type </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.incpremiumType.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="incpremiumType" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="incpremiumType" >
                             <br/>                               
                             <div *ngIf="submitted && e.incpremiumType.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.incpremiumType.errors.required">
                                Insurance premium Type is required
                              </div>
                              </div>
                          </div>
                        </div>

                        <div class="col-md-12 row">
                          <div class="col-md-6">
                            <label>Payment Mode </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.incPaymentMode.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="incPaymentMode" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="incPaymentMode" >
                             <br/>                               
                             <div *ngIf="submitted && e.incPaymentMode.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.incPaymentMode.errors.required">
                                Incurance Payment Mode is required
                              </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                            <label>Payment Method  </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.incpaymentmethod.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="incpaymentmethod" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="incpaymentmethod" >
                             <br/>                               
                             <div *ngIf="submitted && e.incpaymentmethod.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.incpaymentmethod.errors.required">
                                Insurance payment method is required
                              </div>
                              </div>
                          </div>
                        </div>

                        <div class="col-md-12 row">
                          <div class="col-md-6">
                            <label>Premium Term </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.incPremiumTerm.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="incPremiumTerm" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="incPremiumTerm" >
                             <br/>                               
                             <div *ngIf="submitted && e.incPremiumTerm.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.incPremiumTerm.errors.required">
                                Incurance Premium Term is required
                              </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                            <label>Coverage Term  </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.inccoverageaTerm.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="inccoverageaTerm" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="inccoverageaTerm" >
                             <br/>                               
                             <div *ngIf="submitted && e.inccoverageaTerm.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.inccoverageaTerm.errors.required">
                                Insurance Coverage Term  is required
                              </div>
                              </div>
                          </div>
                        </div>

                        <div class="col-md-12 row">
                          <div class="col-md-6">
                            <label>Sum Assured </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.incSumAssured.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="incSumAssured" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="incSumAssured" >
                             <br/>                               
                             <div *ngIf="submitted && e.incSumAssured.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.incSumAssured.errors.required">
                                Incurance Sum Assured is required
                              </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                            <label>Benefits/Riders/Fund Allocation</label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.incBenefits.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="incBenefits" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="incBenefits" >
                             <br/>                               
                             <div *ngIf="submitted && e.incBenefits.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.incBenefits.errors.required">
                                Insurance Benefits is required
                              </div>
                              </div>
                          </div>
                        </div>


                        <div class="col-md-12">&nbsp;</div>


                        <h5>4.	Identified Life needs</h5>

                        <div class="col-md-12 row ">
                          <div class="col-md-1 row ">&nbsp;</div>
                          <div class="col-md-12 row">
                          
                            <br/>
                            <div class="col-md-12 row ">
                              <div class="col-md-3">
    
                                  <div style="    padding: 0%;">ITEM/YEAR</div><br/>
                                  <div style="    padding: 2%;">Last Year</div><br/>
                                  <div style="    padding: 2%;">Current Year</div><br/>
                                  <div style="    padding: 3%;">5-10 Years</div><br/>
                                  <div style="    padding: 3%;">10-15 Years</div><br/>
                                  <div style="    padding: 3%;">15-20 Years</div><br/>
                                  <div style="    padding: 3%;">20-25 Years</div><br/>
                                  <div>25-30 Years</div>
        
                              </div>
                              <div class="col-md-3 row ">
                                <div class="column">
                                  <label style="font-size: 11px;">Food, shelter, clothing and other living expenses 
                                    such as transportation expenses, utilities etc</label>
        
                                  <input [ngClass]="{ 'is-invalid': submitted && e.foodlastyear.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="foodlastyear" 
                                  class="form-control "
                                   type="text " id="inputbutton " [(ngModel)]="foodlastyear" >
                                   <br/>                               <div *ngIf="submitted && e.foodlastyear.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.foodlastyear.errors.required">
                                      Food, shelter Last year is required
                                    </div>
                                    </div>
        
                                    <input [ngClass]="{ 'is-invalid': submitted && e.foodcuryear.errors }" 
                                    (keyup.enter)="saveInsurance(stepper1) " formControlName="foodcuryear" 
                                    class="form-control "
                                     type="text " id="inputbutton " [(ngModel)]="foodcuryear" >
                                     <br/>   
                                     <div *ngIf="submitted && e.foodcuryear.errors" class="invalid-feedback">
                                      <div style="color: red; " *ngIf="e.foodcuryear.errors.required">
                                        Food, shelter Current year is required
                                      </div>
                                      </div>
        
                                      <input [ngClass]="{ 'is-invalid': submitted && e.food5to10.errors }" 
                                      (keyup.enter)="saveInsurance(stepper1) " formControlName="food5to10" 
                                      class="form-control "
                                       type="text " id="inputbutton " [(ngModel)]="food5to10" >
                                       <br/>   
                                       <div *ngIf="submitted && e.food5to10.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="e.food5to10.errors.required">
                                          Food, shelter 5-10 Years value is required
                                        </div>
                                        </div>
        
                                        <input [ngClass]="{ 'is-invalid': submitted && e.food10to15.errors }" 
                                        (keyup.enter)="saveInsurance(stepper1) " formControlName="food10to15" 
                                        class="form-control "
                                         type="text " id="inputbutton " [(ngModel)]="food10to15" >
                                         <br/>   
                                         <div *ngIf="submitted && e.food10to15.errors" class="invalid-feedback">
                                          <div style="color: red; " *ngIf="e.food10to15.errors.required">
                                            Food, shelter 10-15 Years value is required
                                          </div>
                                          </div>
        
                                          <input [ngClass]="{ 'is-invalid': submitted && e.food15to20.errors }" 
                                          (keyup.enter)="saveInsurance(stepper1) " formControlName="food15to20" 
                                          class="form-control "
                                           type="text " id="inputbutton " [(ngModel)]="food15to20" >
                                           <br/>   
                                           <div *ngIf="submitted && e.food15to20.errors" class="invalid-feedback">
                                            <div style="color: red; " *ngIf="e.food15to20.errors.required">
                                              Food, shelter 15-20 Years  value is required
                                            </div>
                                            </div>
        
                                            <input [ngClass]="{ 'is-invalid': submitted && e.food20to25.errors }" 
                                            (keyup.enter)="saveInsurance(stepper1) " formControlName="food20to25" 
                                            class="form-control "
                                             type="text " id="inputbutton " [(ngModel)]="food20to25" >
                                             <br/>   
                                             <div *ngIf="submitted && e.food20to25.errors" class="invalid-feedback">
                                              <div style="color: red; " *ngIf="e.food20to25.errors.required">
                                                Food, shelter 20-25 Years value is required
                                              </div>
                                              </div>
        
                                              <input [ngClass]="{ 'is-invalid': submitted && e.food25to30.errors }" 
                                              (keyup.enter)="saveInsurance(stepper1) " formControlName="food25to30" 
                                              class="form-control "
                                               type="text " id="inputbutton " [(ngModel)]="food25to30" >
                                               <br/>   
                                               <div *ngIf="submitted && e.food25to30.errors" class="invalid-feedback">
                                                <div style="color: red; " *ngIf="e.food25to30.errors.required">
                                                  Food, shelter 25-30 Years value is required
                                                </div>
                                                </div>
                                </div>
                              </div>
                              <div class="col-md-3 row ">
                                <div class="column">
                                  <label>Education Expenses </label>
                                  <div>&nbsp;</div>
                                  <input [ngClass]="{ 'is-invalid': submitted && e.edulastyear.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="edulastyear" 
                                  class="form-control "
                                   type="text " id="inputbutton " [(ngModel)]="edulastyear" >
                                   <br/>   
                                   <div *ngIf="submitted && e.edulastyear.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.edulastyear.errors.required">
                                      Education Last year is required
                                    </div>
                                    </div>
        
                                    <input [ngClass]="{ 'is-invalid': submitted && e.educuryear.errors }" 
                                    (keyup.enter)="saveInsurance(stepper1) " formControlName="educuryear" 
                                    class="form-control "
                                     type="text " id="inputbutton " [(ngModel)]="educuryear" >
                                     <br/>   
                                     <div *ngIf="submitted && e.educuryear.errors" class="invalid-feedback">
                                      <div style="color: red; " *ngIf="e.educuryear.errors.required">
                                        Education  Current year is required
                                      </div>
                                      </div>
        
                                      <input [ngClass]="{ 'is-invalid': submitted && e.edu5to10.errors }" 
                                      (keyup.enter)="saveInsurance(stepper1) " formControlName="edu5to10" 
                                      class="form-control "
                                       type="text " id="inputbutton " [(ngModel)]="edu5to10" >
                                       <br/>   
                                       <div *ngIf="submitted && e.edu5to10.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="e.edu5to10.errors.required">
                                          Education 5-10 Years value is required
                                        </div>
                                        </div>
        
                                        <input [ngClass]="{ 'is-invalid': submitted && e.edu10to15.errors }" 
                                        (keyup.enter)="saveInsurance(stepper1) " formControlName="edu10to15" 
                                        class="form-control "
                                         type="text " id="inputbutton " [(ngModel)]="edu10to15" >
                                         <br/>   
                                         <div *ngIf="submitted && e.edu10to15.errors" class="invalid-feedback">
                                          <div style="color: red; " *ngIf="e.edu10to15.errors.required">
                                            Education 10-15 Years value is required
                                          </div>
                                          </div>
        
                                          <input [ngClass]="{ 'is-invalid': submitted && e.edu15to20.errors }" 
                                          (keyup.enter)="saveInsurance(stepper1) " formControlName="edu15to20" 
                                          class="form-control "
                                           type="text " id="inputbutton " [(ngModel)]="edu15to20" >
                                           <br/>   
                                           <div *ngIf="submitted && e.edu15to20.errors" class="invalid-feedback">
                                            <div style="color: red; " *ngIf="e.edu15to20.errors.required">
                                              Education 15-20 Years value is required
                                            </div>
                                            </div>
        
                                            <input [ngClass]="{ 'is-invalid': submitted && e.edu20to25.errors }" 
                                            (keyup.enter)="saveInsurance(stepper1) " formControlName="edu20to25" 
                                            class="form-control "
                                             type="text " id="inputbutton " [(ngModel)]="edu20to25" >
                                             <br/>   
                                             <div *ngIf="submitted && e.edu20to25.errors" class="invalid-feedback">
                                              <div style="color: red; " *ngIf="e.edu20to25.errors.required">
                                                Education 20-25 Years value is required
                                              </div>
                                              </div>
        
                                              <input [ngClass]="{ 'is-invalid': submitted && e.edu25to30.errors }" 
                                              (keyup.enter)="saveInsurance(stepper1) " formControlName="edu25to30" 
                                              class="form-control "
                                               type="text " id="inputbutton " [(ngModel)]="edu25to30" >
                                               <br/>   
                                               <div *ngIf="submitted && e.edu25to30.errors" class="invalid-feedback">
                                                <div style="color: red; " *ngIf="e.edu25to30.errors.required">
                                                  Education 25-30 Years value is required
                                                </div>
                                                </div>
                                </div>
                              </div>
                              <div class="col-md-3 row ">
                                <div class="column">
                                  <label>Marriage Expenses </label>
                                  <div>&nbsp;</div>
                                  <input [ngClass]="{ 'is-invalid': submitted && e.marlastyear.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="marlastyear" 
                                  class="form-control "
                                   type="text " id="inputbutton " [(ngModel)]="marlastyear" >
                                   <br/>   
                                   <div *ngIf="submitted && e.marlastyear.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.marlastyear.errors.required">
                                      Marriage Last year is required
                                    </div>
                                    </div>
        
                                    <input [ngClass]="{ 'is-invalid': submitted && e.marcuryear.errors }" 
                                    (keyup.enter)="saveInsurance(stepper1) " formControlName="marcuryear" 
                                    class="form-control "
                                     type="text " id="inputbutton " [(ngModel)]="marcuryear" >
                                     <br/>   
                                     <div *ngIf="submitted && e.marcuryear.errors" class="invalid-feedback">
                                      <div style="color: red; " *ngIf="e.marcuryear.errors.required">
                                        Marriage  Current year is required
                                      </div>
                                      </div>
        
                                      <input [ngClass]="{ 'is-invalid': submitted && e.mar5to10.errors }" 
                                      (keyup.enter)="saveInsurance(stepper1) " formControlName="mar5to10" 
                                      class="form-control "
                                       type="text " id="inputbutton " [(ngModel)]="mar5to10" >
                                       <br/>   
                                       <div *ngIf="submitted && e.mar5to10.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="e.mar5to10.errors.required">
                                          Marriage 5-10 Years value is required
                                        </div>
                                        </div>
        
                                        <input [ngClass]="{ 'is-invalid': submitted && e.mar10to15.errors }" 
                                        (keyup.enter)="saveInsurance(stepper1) " formControlName="mar10to15" 
                                        class="form-control "
                                         type="text " id="inputbutton " [(ngModel)]="mar10to15" >
                                         <br/>   
                                         <div *ngIf="submitted && e.mar10to15.errors" class="invalid-feedback">
                                          <div style="color: red; " *ngIf="e.mar10to15.errors.required">
                                            Marriage 10-15 Years value is required
                                          </div>
                                          </div>
        
                                          <input [ngClass]="{ 'is-invalid': submitted && e.mar15to20.errors }" 
                                          (keyup.enter)="saveInsurance(stepper1) " formControlName="mar15to20" 
                                          class="form-control "
                                           type="text " id="inputbutton " [(ngModel)]="mar15to20" >
                                           <br/>   
                                           <div *ngIf="submitted && e.mar15to20.errors" class="invalid-feedback">
                                            <div style="color: red; " *ngIf="e.mar15to20.errors.required">
                                              Marriage 15-20 Years value is required
                                            </div>
                                            </div>
        
                                            <input [ngClass]="{ 'is-invalid': submitted && e.mar20to25.errors }" 
                                            (keyup.enter)="saveInsurance(stepper1) " formControlName="mar20to25" 
                                            class="form-control "
                                             type="text " id="inputbutton " [(ngModel)]="mar20to25" >
                                             <br/>   
                                             <div *ngIf="submitted && e.mar20to25.errors" class="invalid-feedback">
                                              <div style="color: red; " *ngIf="e.mar20to25.errors.required">
                                                Marriage 20-25 Years value is required
                                              </div>
                                              </div>
        
                                              <input [ngClass]="{ 'is-invalid': submitted && e.mar25to30.errors }" 
                                              (keyup.enter)="saveInsurance(stepper1) " formControlName="mar25to30" 
                                              class="form-control "
                                               type="text " id="inputbutton " [(ngModel)]="mar25to30" >
                                               <br/>   
                                               <div *ngIf="submitted && e.mar25to30.errors" class="invalid-feedback">
                                                <div style="color: red; " *ngIf="e.mar25to30.errors.required">
                                                  Marriage 25-30 Years value is required
                                                </div>
                                                </div>
                                </div>
                              </div>
                            </div>


                            <div class="col-md-12 row ">
                              <div class="col-md-3">
    
                                  <div style="    padding: 0%;">ITEM/YEAR</div><br/>
                                  <div style="    padding: 2%;">Last Year</div><br/>
                                  <div style="    padding: 2%;">Current Year</div><br/>
                                  <div style="    padding: 3%;">5-10 Years</div><br/>
                                  <div style="    padding: 3%;">10-15 Years</div><br/>
                                  <div style="    padding: 3%;">15-20 Years</div><br/>
                                  <div style="    padding: 3%;">20-25 Years</div><br/>
                                  <div>25-30 Years</div>
        
                              </div>
                              <div class="col-md-3 row ">
                                <div class="column">
                                  <label>Vacations and other travel expenses</label>
        
                                  <input [ngClass]="{ 'is-invalid': submitted && e.vacationlastyear.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="vacationlastyear" 
                                  class="form-control "
                                   type="text " id="inputbutton " [(ngModel)]="vacationlastyear" >
                                   <br/>                               <div *ngIf="submitted && e.vacationlastyear.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.vacationlastyear.errors.required">
                                      Vacation Last year is required
                                    </div>
                                    </div>
        
                                    <input [ngClass]="{ 'is-invalid': submitted && e.vacationcuryear.errors }" 
                                    (keyup.enter)="saveInsurance(stepper1) " formControlName="vacationcuryear" 
                                    class="form-control "
                                     type="text " id="inputbutton " [(ngModel)]="vacationcuryear" >
                                     <br/>   
                                     <div *ngIf="submitted && e.vacationcuryear.errors" class="invalid-feedback">
                                      <div style="color: red; " *ngIf="e.vacationcuryear.errors.required">
                                        Vacation Current year is required
                                      </div>
                                      </div>
        
                                      <input [ngClass]="{ 'is-invalid': submitted && e.vacation5to10.errors }" 
                                      (keyup.enter)="saveInsurance(stepper1) " formControlName="vacation5to10" 
                                      class="form-control "
                                       type="text " id="inputbutton " [(ngModel)]="vacation5to10" >
                                       <br/>   
                                       <div *ngIf="submitted && e.vacation5to10.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="e.vacation5to10.errors.required">
                                          Vacation 5-10 Years value is required
                                        </div>
                                        </div>
        
                                        <input [ngClass]="{ 'is-invalid': submitted && e.vacation10to15.errors }" 
                                        (keyup.enter)="saveInsurance(stepper1) " formControlName="vacation10to15" 
                                        class="form-control "
                                         type="text " id="inputbutton " [(ngModel)]="vacation10to15" >
                                         <br/>   
                                         <div *ngIf="submitted && e.vacation10to15.errors" class="invalid-feedback">
                                          <div style="color: red; " *ngIf="e.vacation10to15.errors.required">
                                            Vacation 10-15 Years value is required
                                          </div>
                                          </div>
        
                                          <input [ngClass]="{ 'is-invalid': submitted && e.vacation15to20.errors }" 
                                          (keyup.enter)="saveInsurance(stepper1) " formControlName="vacation15to20" 
                                          class="form-control "
                                           type="text " id="inputbutton " [(ngModel)]="vacation15to20" >
                                           <br/>   
                                           <div *ngIf="submitted && e.vacation15to20.errors" class="invalid-feedback">
                                            <div style="color: red; " *ngIf="e.vacation15to20.errors.required">
                                              Vacation 15-20 Years  value is required
                                            </div>
                                            </div>
        
                                            <input [ngClass]="{ 'is-invalid': submitted && e.vacation20to25.errors }" 
                                            (keyup.enter)="saveInsurance(stepper1) " formControlName="vacation20to25" 
                                            class="form-control "
                                             type="text " id="inputbutton " [(ngModel)]="vacation20to25" >
                                             <br/>   
                                             <div *ngIf="submitted && e.vacation20to25.errors" class="invalid-feedback">
                                              <div style="color: red; " *ngIf="e.vacation20to25.errors.required">
                                                Vacation 20-25 Years value is required
                                              </div>
                                              </div>
        
                                              <input [ngClass]="{ 'is-invalid': submitted && e.vacation25to30.errors }" 
                                              (keyup.enter)="saveInsurance(stepper1) " formControlName="vacation25to30" 
                                              class="form-control "
                                               type="text " id="inputbutton " [(ngModel)]="vacation25to30" >
                                               <br/>   
                                               <div *ngIf="submitted && e.vacation25to30.errors" class="invalid-feedback">
                                                <div style="color: red; " *ngIf="e.vacation25to30.errors.required">
                                                  Vacation 25-30 Years value is required
                                                </div>
                                                </div>
                                </div>
                              </div>
                              <div class="col-md-3 row ">
                                <div class="column">
                                  <label style="font-size: 12px;    margin-top: -3%;">Other commitments such as insurance premium, various contributions etc </label>
        
                                  <input [ngClass]="{ 'is-invalid': submitted && e.otherlastyear.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="otherlastyear" 
                                  class="form-control " (change)="TotalLastYear()"
                                   type="text " id="inputbutton " [(ngModel)]="otherlastyear" >
                                   <br/>   
                                   <div *ngIf="submitted && e.otherlastyear.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.otherlastyear.errors.required">
                                      Other Last year is required
                                    </div>
                                    </div>
        
                                    <input [ngClass]="{ 'is-invalid': submitted && e.othercuryear.errors }" 
                                    (keyup.enter)="saveInsurance(stepper1) " formControlName="othercuryear" 
                                    class="form-control " (change)="TotalCurrentYear()"
                                     type="text " id="inputbutton " [(ngModel)]="othercuryear" >
                                     <br/>   
                                     <div *ngIf="submitted && e.othercuryear.errors" class="invalid-feedback">
                                      <div style="color: red; " *ngIf="e.othercuryear.errors.required">
                                        Other  Current year is required
                                      </div>
                                      </div>
        
                                      <input [ngClass]="{ 'is-invalid': submitted && e.other5to10.errors }" 
                                      (keyup.enter)="saveInsurance(stepper1) " formControlName="other5to10" 
                                      class="form-control " (change)="Total5to10Year()"
                                       type="text " id="inputbutton " [(ngModel)]="other5to10" >
                                       <br/>   
                                       <div *ngIf="submitted && e.other5to10.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="e.other5to10.errors.required">
                                          Other 5-10 Years value is required
                                        </div>
                                        </div>
        
                                        <input [ngClass]="{ 'is-invalid': submitted && e.other10to15.errors }" 
                                        (keyup.enter)="saveInsurance(stepper1) " (change)="Total10to15Year()"
                                         formControlName="other10to15" 
                                        class="form-control "
                                         type="text " id="inputbutton " [(ngModel)]="other10to15" >
                                         <br/>   
                                         <div *ngIf="submitted && e.other10to15.errors" class="invalid-feedback">
                                          <div style="color: red; " *ngIf="e.other10to15.errors.required">
                                            Other 10-15 Years value is required
                                          </div>
                                          </div>
        
                                          <input [ngClass]="{ 'is-invalid': submitted && e.other15to20.errors }" 
                                          (keyup.enter)="saveInsurance(stepper1) " formControlName="other15to20" 
                                          class="form-control " (change)="Total15to20Year()"
                                           type="text " id="inputbutton " [(ngModel)]="other15to20" >
                                           <br/>   
                                           <div *ngIf="submitted && e.other15to20.errors" class="invalid-feedback">
                                            <div style="color: red; " *ngIf="e.other15to20.errors.required">
                                              Other 15-20 Years value is required
                                            </div>
                                            </div>
        
                                            <input [ngClass]="{ 'is-invalid': submitted && e.other20to25.errors }" 
                                            (keyup.enter)="saveInsurance(stepper1) " formControlName="other20to25" 
                                            class="form-control " (change)="Total20to25Year()"
                                             type="text " id="inputbutton " [(ngModel)]="other20to25" >
                                             <br/>   
                                             <div *ngIf="submitted && e.other20to25.errors" class="invalid-feedback">
                                              <div style="color: red; " *ngIf="e.other20to25.errors.required">
                                                Other 20-25 Years value is required
                                              </div>
                                              </div>
        
                                              <input [ngClass]="{ 'is-invalid': submitted && e.other25to30.errors }" 
                                              (keyup.enter)="saveInsurance(stepper1) " formControlName="other25to30" 
                                              class="form-control " (change)="Total25to30Year()"
                                               type="text " id="inputbutton " [(ngModel)]="other25to30" >
                                               <br/>   
                                               <div *ngIf="submitted && e.other25to30.errors" class="invalid-feedback">
                                                <div style="color: red; " *ngIf="e.other25to30.errors.required">
                                                  Other 25-30 Years value is required
                                                </div>
                                                </div>
                                </div>
                              </div>
                              <div class="col-md-3 row ">
                                <div class="column">
                                  <label>TOTAL </label>
                                  <div>&nbsp;</div>
                                  <input [ngClass]="{ 'is-invalid': submitted && e.totallastyear.errors }" 
                                  (keyup.enter)="saveInsurance(stepper1) " formControlName="totallastyear" 
                                  class="form-control "
                                   type="text " id="inputbutton " [(ngModel)]="totallastyear" readonly>
                                   <br/>   
        
                                    <input [ngClass]="{ 'is-invalid': submitted && e.totalcuryear.errors }" 
                                    (keyup.enter)="saveInsurance(stepper1) " formControlName="totalcuryear" 
                                    class="form-control "
                                     type="text " id="inputbutton " [(ngModel)]="totalcuryear" readonly>
                                     <br/>   
        
                                      <input [ngClass]="{ 'is-invalid': submitted && e.total5to10.errors }" 
                                      (keyup.enter)="saveInsurance(stepper1) " formControlName="total5to10" 
                                      class="form-control "
                                       type="text " id="inputbutton " [(ngModel)]="total5to10" readonly>
                                       <br/>   
        
                                        <input [ngClass]="{ 'is-invalid': submitted && e.total10to15.errors }" 
                                        (keyup.enter)="saveInsurance(stepper1) " formControlName="total10to15" 
                                        class="form-control "
                                         type="text " id="inputbutton " [(ngModel)]="total10to15" readonly>
                                         <br/>   
        
                                          <input [ngClass]="{ 'is-invalid': submitted && e.total15to20.errors }" 
                                          (keyup.enter)="saveInsurance(stepper1) " formControlName="total15to20" 
                                          class="form-control "
                                           type="text " id="inputbutton " [(ngModel)]="total15to20" readonly>
                                           <br/>   
        
                                            <input [ngClass]="{ 'is-invalid': submitted && e.total20to25.errors }" 
                                            (keyup.enter)="saveInsurance(stepper1) " formControlName="total20to25" 
                                            class="form-control "
                                             type="text " id="inputbutton " [(ngModel)]="total20to25" readonly>
                                             <br/>   
        
                                              <input [ngClass]="{ 'is-invalid': submitted && e.total25to30.errors }" 
                                              (keyup.enter)="saveInsurance(stepper1) " formControlName="total25to30" 
                                              class="form-control "
                                               type="text " id="inputbutton " [(ngModel)]="total25to30" readonly>
                                               <br/>   
                                </div>
                              </div>
                            </div>


                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> 
                          D.	RECOMMENDATION</h4>
                          <div class="col-md-12">&nbsp;</div>
                          <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">1.	Life stage</label>
                                <select (change)="onSelectlifeStage($event.target.value)" class="form-control">
                                  <option value="">Select Life stage</option>
                                  <option *ngFor="let do of LifeStages" value={{do.id}} [selected]="do.id==lifeStageId">
                                      {{do.lifeStageName}}
                                  </option>
                              </select>
                              
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">2.	Protection needs</label>
                                <select (change)="onSelectProtectionNeed($event.target.value)" class="form-control">
                                    <option value="">Select Protection need</option>
                                    <option *ngFor="let do of ProtectionNeeds" value={{do.id}} [selected]="do.id==protectionNeedId">
                                        {{do.protectionNeedName}}
                                    </option>
                                </select>
                               
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> 3.	Appetite for risk</label>
                                <select (change)="onSelectAppetiteRisk($event.target.value)" class="form-control">
                                    <option value="">Select Appetite</option>
                                    <option *ngFor="let do of AppetiteRisks" value={{do.id}} [selected]="do.id==appetiteRiskId">
                                        {{do.appetiteForRiskName}}
                                    </option>
                                </select> 
                                <div *ngIf="submitted && e.appetiteId.errors" class="invalid-feedback">
                                  <div style="color: red; " *ngIf="e.appetiteId.errors.required">
                                    Work span value is required
                                  </div>
                                  </div>
                              </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row">
                          <div class="col-md-2 row ">&nbsp;</div>
                          <div class="col-md-5">
                            <label>4.	Policy recommended, including name of insurer</label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.recommendedpolicy.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="recommendedpolicy" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="recommendedpolicy" >
                             <br/>                               
                             <div *ngIf="submitted && e.recommendedpolicy.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.recommendedpolicy.errors.required">
                                Recommended Policy is required
                              </div>
                              </div>
                          </div>
                          <div class="col-md-5">
                            <label>5.	Details of commitment for the
                              current and future years
                              </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.recommendedcommit.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="recommendedcommit" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="recommendedcommit" >
                             <br/>                               <div *ngIf="submitted && e.recommendedcommit.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.recommendedcommit.errors.required">
                                Recommended Commit is required
                              </div>
                              </div>
                          </div>
                       
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row">
                          <div class="col-md-2 row ">&nbsp;</div>
                          <div class="col-md-5">
                            <label>6. Whether all risk elements and
                              details of charges to be incurred and 
                              all other obligations have been explained
                              </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.recommendedRisk.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="recommendedRisk" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="recommendedRisk" >
                             <br/>                               <div *ngIf="submitted && e.recommendedRisk.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.recommendedRisk.errors.required">
                                Recommended Risk is required
                              </div>
                              </div>
                          </div>
                          <div class="col-md-5">
                            <label>7.	Why you think this policy is most
                              suited for the prospect
                              </label>
                            <input [ngClass]="{ 'is-invalid': submitted && e.recommendedsuitedPolicy.errors }" 
                            (keyup.enter)="saveInsurance(stepper1) " formControlName="recommendedsuitedPolicy" 
                            class="form-control "
                             type="text " id="inputbutton " [(ngModel)]="recommendedsuitedPolicy" >
                             <br/>                               <div *ngIf="submitted && e.recommendedsuitedPolicy.errors" class="invalid-feedback">
                              <div style="color: red; " *ngIf="e.recommendedsuitedPolicy.errors.required">
                                Why you think this policy is most
                                suited for the prospect is required
                              </div>
                              </div>
                          </div>
                        </div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>

                        <p style="float: left;"> 
                          <u>Agent/Broker’s Certification:</u></p>
                        
                          <p> &nbsp;I /We hereby certify that I/we believe that the product/s recommended me/us above is suitable for the prospect, bas
                             on the information submitted by him/her, as recorded above.</p>

                            <div class="col-md-12 row">
                              <div class="col-md-6" style="float:left;text-align: left;">
                                  <p>Dated: {{todaysdateAgent}}</p>
                              </div>
                              <div class="col-md-6" style="float: right;">
                               
                                <div class="form-card" style="border: solid 2px #c60c0c;background-color: #545d64;border-radius: 4%;">
                                  <h2 class="fs-titledata" style="text-align: center;">Upload Signature of Agent/Broker</h2>
                                  <div class="container fileUploadWrapper">
                                      <div class="col-md-12">
                                          <div class="fileuploaddata" style="background-image: url(./assets/img/upload-icon.png);" appDragDropFileUpload accept="image/*" (click)="fileField.click()" (fileDropped)="onDrop($event)">
                                              <span class="ddinfo">Choose an object or drag here</span>
                                          </div>
                                          <input type="file" accept="image/*" name="avatars" #fileField (change)="onChangeUploadAgentSign($event)">
                                      </div>
                                      <h3 *ngIf="validfile" style="color: red;">Please Upload Only One File of type png or jpg or jpeg</h3>
                                  </div>
                              </div>
                              <img [src]="dispAgentSignFile" *ngIf="dispAgentSignFile" height="100" width="100"/>
                                (Signature of Agent/Broker)
                              </div>
                            </div>
                            <div class="col-md-12">&nbsp;</div>
                            <p style="float: left;"> 
                              <u>Prospect’s Acknowledgement:</u></p>
                              <p> &nbsp;The above recommendation is based on the information
                                 provided by me. I have been explained about the features 
                                 of the product and believe it would be suitable
                                 for me based on my insurance needs and financial objectives.</p>
  
                                <div class="col-md-12 row">
                                  <div class="col-md-6" style="float:left;text-align: left;">
                                      <p>Dated: {{todaysdateprospect}}</p>
                                  </div>
                                  <div class="col-md-6" style="float: right;">
                                    
                                    <div class="form-card" style="border: solid 2px #c60c0c;background-color: #545d64;border-radius: 4%;">
                                      <h2 class="fs-titledata" style="text-align: center;">Upload Signature of Prospect</h2>
                                      <div class="container fileUploadWrapper">
                                          <div class="col-md-12">
                                              <div class="fileuploaddata" style="background-image: url(./assets/img/upload-icon.png);" appDragDropFileUpload accept="image/*" (click)="fileField.click()" (fileDropped)="onDrop($event)">
                                                  <span class="ddinfo">Choose an object or drag here</span>
                                              </div>
                                              <input type="file" accept="image/*" name="avatars" #fileField (change)="onChangeUploadProspectSign($event)">
                                          </div>
                                          <h3 *ngIf="validfile" style="color: red;">Please Upload Only One File of type png or jpg or jpeg</h3>
                                      </div>
                                  </div>
                                  <img [src]="dispProspectsSignFile" *ngIf="dispProspectsSignFile" height="100" width="100"/>

                                    
                                    (Signature of Prospect)
                                  </div>
                                </div>


















































                  <div class="col-md-12 row ">&nbsp;</div>
                  <div class="col-md-12 row ">&nbsp;</div>

                </form>

                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <BR/> 
                <div class="col-md-12 row">
                  <div class="col-md-6">
                    <button (click)="SaveInsuranceDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;
                  </div>
                  <div class="col-md-6">
                    <button (click)="saveInsurance(stepper1)" id="signinbutton">Next</button>
                  </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
            </div>
        </div>
    </div>
</mat-step>




  <mat-step label="Step 4" state="upload" style="text-align: center; height: 96%; width: 136%; ">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
        <div class="col-md-1 "></div>
        <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
            <div class="homecard " id="signin " align="center "
                style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                <div *ngIf="certificatesexist">
                    <div class="col-md-12 row">&nbsp;</div>
                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                <div class="col-md-12 row">&nbsp;</div>
                 <div class="col-md-12 row" >
                    <div class="col-md-1 row">&nbsp;</div>

                     <div class="col-md-2 row" *ngFor="let item of CertificateDisplay">
                         <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'image/jpeg' ||
                         item.documentType == 'image/png')" (click)="ViewDoc(item)" >
                         <img [src]="item.certificate" height="100" width="100"/>
                         <div class="col-md-12 row">
                             <div class="col-md-2 row">&nbsp;</div>
                             <div class="col-md-10 row">
                                <p style="text-align: center;">{{item.fileName}}</p>

                             </div>

                            </div>
                         </div>
                         <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/pdf' ||
                         item.documentType == 'pdf')" (click)="ViewDoc(item)" >
                         <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                         <div class="col-md-12 row">
                             <div class="col-md-2 row">&nbsp;</div>
                             <div class="col-md-10 row">
                                <p style="text-align: center;">{{item.fileName}}</p>

                             </div>

                            </div>
                         </div> 
                         <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                         item.documentType == 'xlsx')" (click)="ViewDoc(item)" >
                         <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                         <div class="col-md-12 row">
                             <div class="col-md-2 row">&nbsp;</div>
                             <div class="col-md-10 row">
                                <p style="text-align: center;">{{item.fileName}}</p>

                             </div>

                            </div>
                         </div> 
                          <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                         item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)" >
                         <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                         <div class="col-md-12 row">
                             <div class="col-md-2 row">&nbsp;</div>
                             <div class="col-md-10 row">
                                <p style="text-align: center;">{{item.fileName}}</p>

                             </div>

                            </div>
                         </div>


                     </div>
                 </div> 
                 <div *ngIf="documentArray.length!=0">
                 <div class="col-md-12 row">&nbsp;</div>
                 <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Uploaded Documents</h4>

                 <div class="col-md-12 row" >
                    <div class="col-md-1 row">&nbsp;</div>

                     <div class="col-md-2 row" *ngFor="let item of certificates">
                         <div *ngIf="item.actualCertificateName!=null  && (item.documentType == 'image/jpeg' ||
                         item.documentType == 'image/png')" (click)="ViewDoc(item)" >
                         <img [src]="item.certificate" height="100" width="100"/>
                         <div class="col-md-12 row">
                             <div class="col-md-2 row">&nbsp;</div>
                             <div class="col-md-10 row">
                                <p style="text-align: center;">{{item.fileName}}</p>

                             </div>

                            </div>
                         </div>
                         <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/pdf' ||
                         item.documentType == 'pdf')" (click)="ViewDoc(item)" >
                         <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                         <div class="col-md-12 row">
                             <div class="col-md-2 row">&nbsp;</div>
                             <div class="col-md-10 row">
                                <p style="text-align: center;">{{item.fileName}}</p>

                             </div>

                            </div>
                         </div> 
                         <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                         item.documentType == 'xlsx')" (click)="ViewDoc(item)" >
                         <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                         <div class="col-md-12 row">
                             <div class="col-md-2 row">&nbsp;</div>
                             <div class="col-md-10 row">
                                <p style="text-align: center;">{{item.fileName}}</p>

                             </div>

                            </div>
                         </div> 
                          <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                         item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)" >
                         <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                         <div class="col-md-12 row">
                             <div class="col-md-2 row">&nbsp;</div>
                             <div class="col-md-10 row">
                                <p style="text-align: center;">{{item.fileName}}</p>

                             </div>

                            </div>
                         </div>
                     </div>
                 </div> 
                </div> </div>
                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Upload Certificates</h2>

                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>

                <div class="container" style="margin-top: 5%">

                    <table class="table table-striped  table-responsive table-bordered">
                        <thead>
                            <tr>
                                <th>Sl No</th>
                                <th>Certificate Name</th>
                                <th>Upload Here</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let field of CertificateList; let i = index">
                                <td>{{i+1}}</td>
                                <td>{{field.certificateName}}</td>
                                <td>
                                    <input type="file"   name="avatars" #fileField
                                        (change)="onChangeCertificates($event,field)">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Documents</h2>
                    <table class="table table-striped  table-responsive table-bordered">
                        <thead>
                            <tr>
                                <th>Document Name</th>
                                <th>Document</th>
                               
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let field of documentArray; let i = index">
                                
                                <td>
                                    <input [(ngModel)]="field.otherdocumentname" class="form-control" type="text"
                                        name="{{field.otherdocumentname}}" />
                                </td>
                                <td>
                                    <input [(ngModel)]="field.otherdocactualname" class="form-control" type="text"
                                        name="{{field.otherdocactualname}}" />
                                </td>
                      
                             
                                <td>
                                    <button class="btn btn-default" type="button"
                                        (click)="deleteFieldValueDocument(i)">Delete</button>
                                </td>
                            </tr>
                            <tr>
                              
                                <td>
                                    <input class="form-control" type="text" id="otherdocumentname"
                                        [(ngModel)]="newDocAttribute.otherdocumentname" name="otherdocumentname" />

                                </td>
                                <td>
                                    <input class="form-control" type="file" id="otherdocument"
                                        (change)="OnChangeOtherDocs($event)" name="otherdocument" />
                                </td>
                            
                              
                                <td>
                                    <button class="btn btn-default" type="button"
                                        (click)="addDocFieldValue()">Add</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row">
                    <div class="col-md-3 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <button (click)="uploadCertificatesDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button (click)="uploadCertificates(stepper1)" id="signinbutton">Upload</button>
                    </div>
                    <div class="col-md-1 row">
                    </div>
                 
                </div>
            
                <div class="col-md-12 row" *ngIf="ProspectIdtoDisplydata==null">
                    <div class="col-md-3 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <button (click)="previewinsurancedetails()"  id="signinbutton">Preview</button>

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button  (click)="submitInsuranceform()" id="signinbutton">Submit</button>
                    </div>
                    <div class="col-md-1 row">
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</mat-step>


  <!-- Icon overrides. -->
  <!-- #docregion override-icons -->
  <ng-template matStepperIcon="details">
    <mat-icon>account_circle</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="account">
    <mat-icon>account_balance</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="insurance">
    <mat-icon>local_hospital</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="upload">
    <mat-icon>cloud_upload</mat-icon>
  </ng-template>




</mat-horizontal-stepper>
<br /><br />
<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 500px;width: 350px;">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <i class="material-icons" style="color: white">close</i>
      </button>
            <span style="color: white;margin-top: 6px;">
        <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
        </div>
    </div>
</div>
