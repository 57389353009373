<app-header></app-header>
<div>&nbsp;</div>
<div>&nbsp;</div>

<div>&nbsp;</div>
<div class="col-md-12 row" style="background-color: #ececec;" *ngIf="showhidevalue" id="showhidevalue">
    <div class="col-md-2"></div>
    <div class="col-md-8 card">
        <div>
            <br /> <br />
            <h3 style="text-align: center;">New Services</h3>
            <br />
            <div class="col-md-12 row">
                <div class="col-md-1"></div>

                <div class="col-md-3 row">
                    <label>From Date</label>
                    <input type="date" class="form-control" id="Newfromdate" name="Newfromdate"
                        placeholder="Any Day" [(ngModel)]="Newfromdate"
                        (change)="onChangeNewfromdate(Newfromdate)" />
                </div>
                <div class="col-md-1"></div>

                <div class="col-md-3 row">
                    <label >To Date</label>
                    <input type="date" class="form-control" id="Newtodate" name="Newtodate"
                         placeholder="Any Day" [(ngModel)]="Newtodate"
                        (change)="onChangeNewTodate(Newtodate)" />
                </div>
                <div class="col-md-1"></div>

                <div class="col-md-3 row">
                    <div class="col-md-12">&nbsp;</div>

                    <button class="btn btnpropother"
                            (click)="cancelNew()">Cancel</button>
                </div>
            </div>
            <br/>
            <div class="search-hero">
                <input class="form-control" type="text" name="SearchNewServices" [(ngModel)]="SearchNewServices" autocomplete="off" placeholder="&#61442; Search">
              </div>
            <div class="table-responsive">

                <table class="table table-bordered" style="border-collapse: collapse;">
                    <tr style="font-size:17px">
                        <th>Sl No</th>
                        <th style="cursor: pointer;" (click)="sortbyserviceid()">Service Id</th>
                        <th>Service Provider</th>
                        <th>Contact Number</th>
                        <th>Information</th>
                        <th>Service Time</th>
                        <th style="cursor: pointer;" (click)="sortbydate()">Service Date</th>
                        <th>Assign</th>
                    </tr>
                    <tr *ngFor="let com of NewServicesList | LockFilter: SearchNewServices | paginate: { itemsPerPage: 5, currentPage: p };let i = index"
                        style="font-size:16px;font-family: DINNextRoundedLTPro">
                        <td> {{ 5 * (p - 1) + (i+1) }}</td>
                        <td>{{com.complaintId}} </td>
                        <td>{{com.serviceProvider}}</td>
                        <td>{{com.contactNumber}}</td>
                        <td>{{com.information}}</td>
                        <td>{{com.serviceTime}}</td>
                        <td>{{com.lastUpdated|date}}</td>
                        <td>
                            <button class="btn btn-small btn-primary" (click)="assigntask(com)">Assign</button>

                        </td>

                    </tr>
                </table>
                <br />
                <pagination-controls (pageChange)="p = $event"></pagination-controls>

            </div>
        </div>
    </div>
</div>

<div class="col-md-12 row" style="background-color: #ececec;" *ngIf="ShowAssignedDetails" id="ShowAssignedDetails">
    <div class="col-md-2"></div>
    <div class="col-md-8 card">
        <div>
            <br /> <br />
            <h3 style="text-align: center;">Assigned Services</h3>
            <br />
            <div class="col-md-12 row">
                <div class="col-md-1"></div>

                <div class="col-md-3 row">
                    <label>From Date</label>
                    <input type="date" class="form-control" id="Assignfromdate" name="Assignfromdate"
                        placeholder="Any Day" [(ngModel)]="Assignfromdate"
                        (change)="onChangeAssignfromdate(Assignfromdate)" />
                </div>
                <div class="col-md-1"></div>

                <div class="col-md-3 row">
                    <label >To Date</label>
                    <input type="date" class="form-control" id="Assigntodate" name="Assigntodate"
                         placeholder="Any Day" [(ngModel)]="Assigntodate"
                        (change)="onChangeAssignTodate(Assigntodate)" />
                </div>
                <div class="col-md-1"></div>

                <div class="col-md-3 row">
                    <div class="col-md-12">&nbsp;</div>

                    <button class="btn btnpropother"
                            (click)="cancelAssign()">Cancel</button>
                </div>
            </div>
            <br />
            <br />
            <div class="search-hero">
                <input class="form-control" type="text" name="SearchAssignedServices" [(ngModel)]="SearchAssignedServices" autocomplete="off" placeholder="&#61442; Search">
              </div>
              
            <div class="table-responsive">
                <div class="col-md-12 row" id="btndiv">
                    <div class="col-md-9"></div>
                    <div class="col-md-1">
                        <button id="btn" type="submit" (click)="print()">Print</button>
                    </div>
                    <!-- <div class="col-md-2">
                            <button id ="btn" type="submit" (click)="download()">Download</button>
                        </div> -->
                </div><br /><br />

                <table class="table table-bordered" style="border-collapse: collapse;">
                    <tr style="font-size:17px">
                        <th>Sl No</th>
                        <th style="cursor: pointer;" (click)="sortbyserviceid()">Service Id</th>
                        <!--<th>Service Provider</th>-->
                        <th>Customer Number</th>
                        <th>Information</th>
                        <th>Service Time</th>
                        <th style="cursor: pointer;" (click)="sortbydate()">Service Date</th>
                        <th>Assigned To</th>
                        <th>Details</th>
                    </tr>
                    <tr *ngFor="let com  of AssignedServicesList | LockFilter: SearchAssignedServices  | paginate: { itemsPerPage: 5, currentPage: p };let i = index"
                        style="font-size:16px;font-family: DINNextRoundedLTPro">
                        <td> {{ 5 * (p - 1) + (i+1) }}</td>
                        <td>{{com.complaintId}} </td>
                        <!--<td>{{com.serviceProvider}}</td>-->
                        <td>{{com.contactNumber}}</td>
                        <td>{{com.information}}</td>
                        <td>{{com.serviceTime}}</td>
                        <td>{{com.lastUpdated|date}}</td>
                        <td>{{com.serviceProvider}}</td>
                        <td> <input class="btn btn-info" type="button" data-toggle="modal"
                                data-target="#Fulldetails" (click)="getfulldeatails(com)" value="Details" /></td>
                    </tr>
                </table>
                <br />
                <pagination-controls (pageChange)="p = $event" ></pagination-controls>
                <br /><br />
            </div>
        </div>
    </div>
</div>

<div class="col-md-12 row" style="background-color: #ececec;" *ngIf="ShowAssignedDetails1">
    <div class="col-md-2"></div>
    <div class="col-md-8 card">
        <div>
            <br /> <br />
            <h3 style="text-align: center;">Assigned Services</h3>
            <br />
            <br />
              
            <div class="table-responsive">
               
                <div class="col-md-12 row" id="btndiv">
                    <div class="col-md-9"></div>
                    <div class="col-md-1">
                        <button id="printnew" type="submit" (click)="printneww()" hidden>Print</button>
                    </div>
                    <!-- <div class="col-md-2">
                            <button id ="btn" type="submit" (click)="download()">Download</button>
                        </div> -->
                </div><br /><br />
                <table class="table table-bordered" style="border-collapse: collapse;">
                    <tr style="font-size:17px">
                        <th>Sl No</th>
                        <th style="cursor: pointer;" (click)="sortbyserviceid()">Service Id</th>
                        <!--<th>Service Provider</th>-->
                        <th>Customer Number</th>
                        <th>Information</th>
                        <th>Service Time</th>
                        <th style="cursor: pointer;" (click)="sortbydate()">Service Date</th>
                        <th>Assigned To</th>
                        <th>Details</th>
                    </tr>
                    <tr *ngFor="let com  of AssignedServicesList ;let i = index"
                        style="font-size:16px;font-family: DINNextRoundedLTPro">
                        <td> {{ 5 * (p - 1) + (i+1) }}</td>
                        <td>{{com.complaintId}} </td>
                        <!--<td>{{com.serviceProvider}}</td>-->
                        <td>{{com.contactNumber}}</td>
                        <td>{{com.information}}</td>
                        <td>{{com.serviceTime}}</td>
                        <td>{{com.lastUpdated|date}}</td>
                        <td>{{com.serviceProvider}}</td>
                        <td> <input class="btn btn-info" type="button" data-toggle="modal"
                                data-target="#Fulldetails" (click)="getfulldeatails(com)" value="Details" /></td>
                    </tr>
                </table>
                <br />
               
                <br />
            </div>
        </div>
    </div>
</div>

<div class="col-md-12 row" style="background-color: #ececec;" *ngIf="ShowCompletedDetails" id="ShowCompletedDetails">
    <div class="col-md-2"></div>
    <div class="col-md-8 card">
        <div>
            <br /> <br />
            <h3 style="text-align: center;">Completed Services</h3>
            <br />
            <div class="col-md-12 row">
                <div class="col-md-1"></div>

                <div class="col-md-3 row">
                    <label>From Date</label>
                    <input type="date" class="form-control" id="Completedfromdate" name="Completedfromdate"
                        placeholder="Any Day" [(ngModel)]="Completedfromdate"
                        (change)="onChangeCompletedfromdate(Completedfromdate)" />
                </div>
                <div class="col-md-1"></div>

                <div class="col-md-3 row">
                    <label >To Date</label>
                    <input type="date" class="form-control" id="Completedtodate" name="Completedtodate"
                         placeholder="Any Day" [(ngModel)]="Completedtodate"
                        (change)="onChangeCompletedTodate(Completedtodate)" />
                </div>
                <div class="col-md-1"></div>

                <div class="col-md-3 row">
                    <div class="col-md-12">&nbsp;</div>

                    <button class="btn btnpropother"
                            (click)="cancelCompleted()">Cancel</button>
                </div>
            </div>
            <br/>
            <div class="search-hero">
                <input class="form-control" type="text" name="SearchCompletedServices" [(ngModel)]="SearchCompletedServices" autocomplete="off" placeholder="&#61442; Search">
              </div>
            <div class="table-responsive">
                <div class="col-md-12 row">
                    <div class="col-md-9"></div>
                    <div class="col-md-1">
                        <button id="btn" type="submit" (click)="print1()">Print</button>
                    </div>
                </div><br /><br />
                <table class="table table-bordered" style="border-collapse: collapse;">
                    <tr style="font-size:17px">
                        <th>Sl No</th>
                        <th style="cursor: pointer;" (click)="sortbyserviceid()">Service Id</th>
                        <!--<th>Service Provider</th>-->
                        <th>Customer Number</th>
                        <th>Information</th>
                        <th>Service Time</th>
                        <th style="cursor: pointer;" (click)="sortbydate()">Service Date</th>
                        <th>Assigned To</th>
                        <th>Details</th>
                    </tr>
                    <tr *ngFor="let com of ResolvedServiceList | LockFilter: SearchCompletedServices  | paginate: { itemsPerPage: 5, currentPage: p };let i = index"
                        style="font-size:16px;font-family: DINNextRoundedLTPro">
                        <td> {{ 5 * (p - 1) + (i+1) }}</td>
                        <td>{{com.complaintId}} </td>
                        <!--<td>{{com.serviceProvider}}</td>-->
                        <td>{{com.contactNumber}}</td>
                        <td>{{com.information}}</td>
                        <td>{{com.serviceTime}}</td>
                        <td>{{com.lastUpdated|date}}</td>
                        <td>{{com.serviceProvider}}</td>
                        <td> <input class="btn btn-info" data-toggle="modal" data-target="#Fulldetails" Fulldetails
                                type="button" (click)="getfulldeatails(com)" value="Details" /></td>

                    </tr>
                </table>
                <br />
                <pagination-controls (pageChange)="p = $event" ></pagination-controls>
                <br /><br />
            </div>
        </div>
    </div>
</div>


<div class="col-md-12 row" style="background-color: #ececec;" *ngIf="ShowCompletedDetails1"
    id="ShowCompletedDetails">
    <div class="col-md-2"></div>
    <div class="col-md-8 card">
        <div>
            <br /> <br />
            <h3 style="text-align: center;">Completed Services</h3>
           
            <br/>
            <div class="table-responsive">
                <div class="col-md-12 row">
                    <div class="col-md-9"></div>
                    <div class="col-md-1">
                        <button id="printcompleteddetails" type="submit" (click)="printneww1()" hidden>Print</button>
                    </div>
                </div><br /><br />
                <table class="table table-bordered" style="border-collapse: collapse;">
                    <tr style="font-size:17px">
                        <th>Sl No</th>
                        <th style="cursor: pointer;" (click)="sortbyserviceid()">Service Id</th>
                        <!--<th>Service Provider</th>-->
                        <th>Customer Number</th>
                        <th>Information</th>
                        <th>Service Time</th>
                        <th style="cursor: pointer;" (click)="sortbydate()">Service Date</th>
                        <th>Assigned To</th>
                        <th>Details</th>
                    </tr>
                    <tr *ngFor="let com of ResolvedServiceList ;let i = index"
                        style="font-size:16px;font-family: DINNextRoundedLTPro">
                        <td> {{ 5 * (p - 1) + (i+1) }}</td>
                        <td>{{com.complaintId}} </td>
                        <!--<td>{{com.serviceProvider}}</td>-->
                        <td>{{com.contactNumber}}</td>
                        <td>{{com.information}}</td>
                        <td>{{com.serviceTime}}</td>
                        <td>{{com.lastUpdated|date}}</td>
                        <td>{{com.serviceProvider}}</td>
                        <td> <input class="btn btn-info" data-toggle="modal" data-target="#Fulldetails" Fulldetails
                                type="button" (click)="getfulldeatails(com)" value="Details" /></td>

                    </tr>
                </table>
                <br />
            </div>
        </div>
    </div>
</div>


<div class="modal modal-angular fade" id="Fulldetails" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modalsize" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">
                    <p style="color: darkslateblue;text-transform: uppercase;">Information
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body text-center" style="background-color: #ececec;" *ngIf="Alldetails">

                <div class="col-md-12 row">
                    <div class="col-md-3"></div>
                    <div class="col-md-3">
                        <img src={{Alldetails.imageLogo}} height="100" width="100" />
                    </div>
                    <div class="col-md-3">
                        <h4 class="gradienttext">{{Alldetails.serviceProvider}}</h4>
                    </div>
                </div>
                <br />
                <div style="padding: 15px;;">
                    <div class="col-md-12 row">
                        <div class="table-responsive">
                            <table class="table-bordered tablebordercolor">
                                <thead class="tablebordercolor">
                                    <th class="tablebordercolor" style=" padding: 10px">Name</th>
                                    <th class="tablebordercolor" style=" padding: 10px">Contact Number</th>
                                    <th style=" padding: 10px">Email</th>
                                    <th class="tablebordercolor" style=" padding: 10px">Address</th>
                                    <th class="tablebordercolor" style=" padding: 10px">Information</th>
                                    <th class="tablebordercolor" style=" padding: 10px">Service Time</th>
                                    <th class="tablebordercolor" style=" padding: 10px">Service Date</th>
                                    <th class="tablebordercolor" style=" padding: 10px">Request</th>
                                </thead>
                                <tbody class="tablebordercolor">
                                    <tr class="tablebordercolor" style=" padding: 10px">
                                        <td class="tablebordercolor">{{Alldetails.name}}</td>
                                        <td class="tablebordercolor">{{Alldetails.contactNumber}}</td>
                                        <td class="tablebordercolor">{{Alldetails.email}}</td>
                                        <td class="tablebordercolor">{{Alldetails.address}}</td>
                                        <td class="tablebordercolor">{{Alldetails.information}}</td>
                                        <td class="tablebordercolor">{{Alldetails.serviceTime}}</td>
                                        <td class="tablebordercolor">{{Alldetails.lastUpdated|date:'short'}}</td>
                                        <td class="tablebordercolor">{{Alldetails.request}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-warning waves-light" data-dismiss="modal"
                    mdbWavesEffect>Close</button>

            </div>
        </div>

    </div>

</div>

<!-- notification alert -->
<div class="col-md-12 row">
<div class="col-md-2">&nbsp;</div>
<div class="col-md-4" *ngIf="dismissalert">
  <div class="alert alert-info"
    style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 550px;width: 350px;">
    <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <i class="material-icons" style="color: white">close</i>
    </button>
    <span style="color: white;margin-top: 6px;">
      <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
  </div>
</div>
</div>

<style>
.modalsize {
width: 950px;
max-width: 950px;
overflow-y: 50vh;
}
@media (max-width: 960px){
.modalsize {
width: 450px;
max-width:650px;
overflow-y: 50vh;
}
}
@media (max-width: 575px){
.modalsize {
width: 480px;
max-width:550px;
overflow-y: 50vh;
}
}
@media (max-width: 460px){
.modalsize {
width: 380px;
max-width:450px;
overflow-y: 50vh;
}
}
@media (max-width: 390px){
.modalsize {
width: 350px;
max-width:450px;
overflow-y: 50vh;
}
}
@media (max-width: 320px){
.modalsize {
width: 310px;
max-width:450px;
overflow-y: 50vh;
}
}
</style>