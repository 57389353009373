<app-header></app-header>
<div>&nbsp;</div>
<div>&nbsp;</div>

<body>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <p class="headtext">Request New Service</p>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div class="col-md-12 row">
        <div class="col-md-1"></div>
        <div class="col-md-3">
            <div class="homecard" (click)="GetLoanProviders()">
                <img src="assets/Images/loan.jpg" id="image" />
                <h4>Loans</h4> <br />
            </div>
        </div>
        <br />
        <div class="col-md-3">
            <div class="homecard" (click)="GetInsuranceProviders()">
                <img src="assets/Images/insurance1.jpg" id="image" />
                <h4>Insurance</h4> <br />
            </div>
        </div><br />

        <div class="col-md-3">
            <div class="homecard" (click)="GetMutualFundProviders()">
                <img src="assets/Images/mutualfund1.svg" id="image" />
                <h4>Investments</h4> <br />
            </div>
        </div><br />
        <!-- <div class="col-md-3">
            <div class="homecard">
                <img src="assets/Images/profile.jpg" id="image" />
                <h4>Profile</h4>
            </div>
        </div> -->
    </div>


</body>