<app-header></app-header>
<br>
<br>
<br>
<br>
<br><br><br><br>
<div id="signin" align="center"
  style="background: linear-gradient(0deg, rgb(67, 67, 72) -8.79%, rgb(215, 212, 222) 36.31%, rgba(153, 119, 234, 0) 95.86%); margin-left: 0px;margin-right: 0px;height: 100%;margin-top: 6%"
  (window:resize)="onResize($event)">
  <fieldset id="container" *ngIf="!ismailed">
    <br />
    <legend align="center" id="legend">SRICHID</legend>
     <div id="notification" style="display: none;">Check Your Mail or Phone Number for further instructions</div> 
    <div id="matter">
      Forgot Password?
    </div>
    <label for="Email" id="emaillabel">Email Address/Phone Number</label>
    <input  (blur)="updateEmail($event.currentTarget.value)"
      class="form-control" type="text" id="inputbutton" style="background-color:#eaf1f7" #email>
    <br>
    <div id="emt">Enter your registered email address or phone number to reset your password</div>
   
    <button id="forgotbutton" (click)="forgot(email.value)">SUBMIT</button>
    <br />
    <br />

  </fieldset>

    <!-- Password Link Expire Page -->

    <br />

</div>
<b #openpopup style="display: none;" data-toggle="modal" data-target="#MessagePopup">Edit</b>
<!--  modal -->
<div class="modal modal-angular fade" id="MessagePopup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
  <div class="modal-dialog" role="document">
      <div class="modal-content">
        
       
              <div class="modal-body text-center">
                  <div class="col-md-12">&nbsp;</div>
              <h4 style="font-size: 24px; font-family: auto; color: darkblue;">{{showmessage}}</h4>
              </div>
        
          <div class="modal-footer">
            
              <button *ngIf="idenable=='1'" type="button" class="btn btn-primary" data-dismiss="modal"
                  mdbWavesEffect>OK</button>
                  <button *ngIf="idenable=='2'" (click)="Routetohome()" type="button" class="btn btn-primary" data-dismiss="modal"
                  mdbWavesEffect>OK</button>
          </div>
      </div>
  </div>
</div>