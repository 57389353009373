<br/>
<br/>
<br/>
<br/>
<br/>
<br/>


<div class="col-md-12 row">
    <div class="col-md-4">

    </div>
    <div class="col-md-4">
      <p>Thank you ! Please look for the
        mail in your inbox and send the
        Service Request for  further processing .</p>  
        <br/>
        <button type="button" class="btn btnprop waves-light" data-dismiss="modal" mdbWavesEffect
            (click)="GotoCdashboard()" style="background: rgb(196, 196, 226)" >Go To Dashboard</button>
    </div>
    <div class="col-md-4">

    </div>
</div>