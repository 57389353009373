<app-header></app-header>
<div>&nbsp;</div>
<div class="bodycolor">&nbsp;</div>
<div class="bodycolor">&nbsp;</div>
<mat-horizontal-stepper #stepper>
    <!-- <mat-step>
        <div>
            <button mat-button matStepperPrevious></button>
            <button mat-button (click)="stepper.reset()"></button>
        </div>
    </mat-step> -->
</mat-horizontal-stepper>
<mat-horizontal-stepper style="background-color: #c2b6b6;
background-image: linear-gradient(315deg, #c2b6b6 0%, #576574 74%);
" #stepper1>

    <mat-step label="Step 1" state="details" style="text-align: center;    height: 96%;width: 136%;">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
            <div class="col-md-1"></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10">

                <div class="homecard" id="signin" align="center"
                    style=" margin-left: 0px;margin-right: 0px;margin-top: 1%">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Personal Details</h2>

                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12">&nbsp;</div>

                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12">&nbsp;</div>

                    <div class="col-md-12">
                        <div class="col-md-12 row">
                            <div class="col-md-2 row">&nbsp;</div>
                            <div class="col-md-3 row" style="display: flex;">
                                <label id="emaillabel">First Name : &nbsp;</label>&nbsp;{{fname}}
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Middle Name : &nbsp;</label>&nbsp;{{mname}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Last Name : &nbsp;</label>&nbsp;{{lname}}

                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Place of Birth : &nbsp;</label>&nbsp;{{placebirth}}
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Gender : &nbsp;</label>&nbsp;{{gender}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Marital Status : &nbsp;</label>&nbsp;{{marital}}
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Mobile Number : &nbsp;</label>&nbsp;{{mobile}}

                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Date of Birth :
                                    &nbsp;</label>&nbsp;{{dob | date : 'shortDate'}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Religion : &nbsp;</label>&nbsp;{{religion}}
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Caste : &nbsp;</label>&nbsp;{{caste}} Brahmin

                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Qualification :
                                    &nbsp;</label>&nbsp;{{qualification}}


                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Occupation : &nbsp;</label>&nbsp;{{occupation}}


                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel "> Email : &nbsp;</label>&nbsp;{{email}}

                            </div>

                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Designation : &nbsp;</label>&nbsp;{{designation}}


                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h3>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Postal Code : &nbsp;</label>&nbsp;{{rPostal}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Country : &nbsp;</label>&nbsp;{{rCountry}} India
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> State : &nbsp;</label>&nbsp;{{rState}}
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>


                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">District : &nbsp;</label>&nbsp;{{rDistrict}}
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Address one : &nbsp;</label>&nbsp;{{rAddress1}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address two : &nbsp;</label>&nbsp;{{rAddress2}}

                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-7 row ">
                                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Permanent Address is Same
                                    as Residential Address</h3>
                            </div>
                            <div class="col-md-1 row ">
                            </div>
                            <div class="col-md-2 row ">&nbsp;</div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Postal Code : &nbsp;</label>&nbsp;{{pPostal}}

                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Country : &nbsp;</label>&nbsp;{{pCountry}}
                                    India
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel "> State : &nbsp;</label>&nbsp;{{pState}}
                                </div>

                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>

                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">District : &nbsp;</label>&nbsp;{{pDistrict}}
                                </div>

                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Address one : &nbsp;</label>&nbsp;{{pAddress1}}

                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Address two : &nbsp;</label>&nbsp;{{pAddress2}}

                                </div>
                            </div>
                        </div>

                    </div>
                    <BR />
                    <button (click)="signIn(stepper1)" id="signinbutton">NEXT</button>
                    <div class="col-md-12 row ">&nbsp;</div>
                </div>
            </div>
        </div>
    </mat-step>

    <mat-step label="Step 2 " state="account" style="text-align: center; height: 96%; width: 136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center "
                    style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Bank Details</h2>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>

                    <div class="container" style="margin-top: 5%">

                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Bank Account Type</th>
                                    <th>Bank Name</th>
                                    <th>Branch</th>
                                    <th>IFSC Code</th>
                                    <th>Cheque Facility</th>
                                    <!-- <th>Loan Facility</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of bankdetails">
                                    <td>{{data.accounttypename}}</td>
                                    <td>{{data.bankname}} </td>
                                    <td>{{data.branch}} </td>
                                    <td>{{data.ifscecode}} </td>
                                    <td>{{data.chequefacility}}</td>
                                    <!-- <td>{{data.loanfacility}}</td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-12 row ">


                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;margin-left: 10%; ">Identity Details
                        </h3>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Ration Card Number : &nbsp;</label>
                                &nbsp;{{userBankDetails[0]?.rationCardNum}}

                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Aadhar Number :
                                    &nbsp;</label>&nbsp;{{userBankDetails[0]?.aadharNum}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Voter ID Number :
                                    &nbsp;</label>&nbsp;{{userBankDetails[0]?.voterId}}

                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">PAN Number : &nbsp;</label>&nbsp;{{userBankDetails[0]?.panNum}}
                                

                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label for="Email " id="emaillabel ">Service Tax Number :
                                    &nbsp;</label>&nbsp;{{userBankDetails[0]?.serviceTaxNum}}

                            </div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Passport Number :
                                    &nbsp;</label>&nbsp;{{userBankDetails[0]?.passportNum}}

                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Life Insurance Value :
                                    &nbsp;</label>&nbsp;{{userBankDetails[0]?.lifeInsuranceValue}}

                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Confirm :
                                    &nbsp;</label>&nbsp;{{userBankDetails[0]?.isConfirm}}

                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>



                        <BR />
                        <div class="col-md-12 row">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;margin-left: 10%; ">Uploaded
                            Documents</h3> <br>
                        <ol>
                            <li *ngIf="userBankDetails[0]?.aadharFile"><a
                                    href="{{userBankDetails[0]?.aadharFile}}">Aadhar</a></li>
                            <li *ngIf="userBankDetails[0]?.bankStatements"><a
                                    href="{{userBankDetails[0]?.bankStatements}}">Bank Statement</a></li>
                            <li *ngIf="userBankDetails[0]?.gstCert"><a href="{{userBankDetails[0]?.gstCert}}">GST</a>
                            </li>
                            <li *ngIf="userBankDetails[0]?.itReturnsFile"><a
                                    href="{{userBankDetails[0]?.itReturnsFile}}">ITR</a></li>
                            <li *ngIf="userBankDetails[0]?.passportFile"><a
                                    href="{{userBankDetails[0]?.passportFile}}">Passport</a></li>

                        </ol>
                        <br>

                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">&nbsp;</div>
                    </div>

                    <BR />
                    <button (click)="bankIn(stepper1)" id="signinbutton">NEXT</button>
                </div>
            </div>
        </div>
    </mat-step>

    <mat-step label="Step 3" state="goldloan" style="text-align: center; height: 96%; width: 136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
            <div class="col-md-1"></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10">

                <div class="homecard" id="signin" align="center"
                    style=" margin-left: 0px;margin-right: 0px;margin-top: 1%">
                    <div class="col-md-12">&nbsp;</div>
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Accident Insurance</h2>
                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Sources of funds: &nbsp;</label>
                            <span>{{accidentInsurancedata.sourcefund}}</span>
                        </div>
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-5 row ">
                            <label id="emaillabel ">Monthly Income: &nbsp;</label>
                            <span>{{accidentInsurancedata.monthlyIncome}}</span>
                        </div>
                    </div>
                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12">&nbsp;</div>
                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Existing Personal Accident Insurance Policy</h4>
                    <div class="col-md-12 ">&nbsp;</div>
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th style=" width: 22%;">Details</th>
                                <th>Member 1</th>
                                <th>Member 2</th>
                                <th>Member 3</th>
                                <th>Member 4</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td>
                                    {{accidentInsurancedata.personone}}
                                </td>
                                <td>
                                    {{accidentInsurancedata.personsec}}
                                </td>
                                <td>
                                    {{accidentInsurancedata.personsthrd}}
                                </td>
                                <td>
                                    {{accidentInsurancedata.personsfourth}}
                                </td>
                            </tr>
                            <tr>
                                <td>DOB</td>
                                <td>
                                    {{accidentInsurancedata.dateofbirthFirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.dateofbirthsec}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.dateofbirthtrd}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.dateofbirthfourth}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Gender</td>
                                <td>
                                    {{accidentInsurancedata.genderfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.gendersec}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.genderthrd}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.genderfour}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Nationality</td>
                                <td>
                                    {{accidentInsurancedata.nationalityfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.nationalitysec}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.nationalitytrd}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.nationality}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Relationship with proposer</td>
                                <td>
                                    {{accidentInsurancedata.proposertfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.proposersec}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.proposerthrd}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.proposertforth}} 
                                </td>
                            </tr>

                            <tr>
                                <td>Occupation</td>
                                <td>
                                    {{accidentInsurancedata.insuranceone}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.insurancetwo}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.insurancethrd}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.insurancefour}} 
                                </td>

                            </tr>

                            <tr>
                                <td>Annual Income</td>
                                <td>
                                    {{accidentInsurancedata.othersfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.otherstwo}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.othersthree}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.othersfour}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Capital Sum Insured</td>
                                <td>
                                    {{accidentInsurancedata.insuredone}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.insuredsec}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.insuredthrd}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.insuredfour}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Diabetes</td>
                                <td>
                                    {{accidentInsurancedata.diabetesfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.diabetessecond}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.diabetesthird}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.diabetesfourth}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Marital Status</td>
                                <td>
                                    {{accidentInsurancedata.statusfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.statussecond}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.statusthird}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.statusfourth}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Nominee</td>
                                <td>
                                    {{accidentInsurancedata.nomineefirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.nomineesecond}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.nomineethird}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.nomineefourth}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Relationship with nominee</td>
                                <td>
                                    {{accidentInsurancedata.relationfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.relationsecond}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.relationthird}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.relationfourth}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Medical expenses extention (Due to Accident)</td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalsecond}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalthird}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfourth}} 
                                </td>
                        </tbody>

                    </table>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Existing Personal
                        Accident Insurance Policy</h4>
                    <div class="col-md-12 ">&nbsp;</div>

                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th style=" width: 22%;">Details</th>
                                <th>Member 1</th>
                                <th>Member 2</th>
                                <th>Member 3</th>
                                <th>Member 4</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Name of insurer</td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Policy no.</td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Policy Period(from date)</td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Sum Insured</td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Claim Filed before?</td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                            </tr>
                            <tr>
                                <td>Any proposal of health,life,PA or critical illness declined, rejected or charged
                                    a higher premium?</td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Covered under any other health insurance policy?</td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Are any of the persons proposed for insurance covered under any other health
                                    insurance policy with the Company? (Including Critical Illness</td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>

                            </tr>
                            <tr>
                                <td>Details of existing physical defect or infirmity</td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>
                                <td>
                                    {{accidentInsurancedata.medicalfirst}} 
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Nominee Details(incase of death)
                    </h3>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Nominee Name: &nbsp;</label>
                            <span>{{accidentInsurancedata.nomineename}}</span>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Relationship: &nbsp;</label>
                            <span>{{accidentInsurancedata.nomineerelationship}}</span>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address of Nominee: &nbsp;</label>
                            <span>{{accidentInsurancedata.addressofnominee}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Premium Payment Details</h4>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Cheque/DD/Credit Card/Debit Card: &nbsp;</label>
                            <span>{{accidentInsurancedata.creditcard}}</span>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Cheque/DD no./Amount: &nbsp;</label>
                            <span>{{accidentInsurancedata.chequeamt}}</span>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Bank Name: &nbsp;</label>
                            <span>{{accidentInsurancedata.inbankname}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Cheque Date: &nbsp;</label>
                            <span>{{accidentInsurancedata.chequedate}}</span>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Name of the payer: &nbsp;</label>
                            <span>{{accidentInsurancedata.nameofpayer}}</span>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                    </div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Proposer's Bank Details</h4>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Name of A/C holder: &nbsp;</label>
                            <span>{{accidentInsurancedata.acholder}}</span>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Bank Account no.: &nbsp;</label>
                            <span>{{accidentInsurancedata.chequeamt}}</span>
                        </div>
                        <div class="col-md-1">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Bank Name: &nbsp;</label>
                            <span>{{accidentInsurancedata.inbankname}}</span>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <button (click)="accidentinsurance(stepper1)" id="signinbutton">NEXT</button>
                    <br /><br /><br /><br />
                </div>
            </div>
        </div>
    </mat-step>

 
    <mat-step label="Step 4" state="Certificates" style="text-align: center; height: 96%; width: 136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center "
                    style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Certificates</h2>
    
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row">
                        <div class="col-md-3 row"></div>
                        <div class="col-md-9 row">
                            <div class="container" style="margin-top: 5%">
    
                                <div class="table-responsive" style="overflow-x: visible">
                                    <table class="table table-striped table-responsive table-bordered">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th style="padding-right: 50px;
                                            padding-left: 50px;">Certificate Name</th>
                                            <th style="padding-right: 50px;
                                            padding-left: 50px;">Certificates</th>
                                            <th style="padding-right: 50px;
                                            padding-left: 50px;">Download</th>
        
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="enablecertificates">
                                        <tr >
                                            <td></td>
                                            <td>Birth Certificate</td>
                                            <td><img src="{{CertificateList[0].birthCertificate}}" class="img-circle" style="height: 150px;width: 150px;border: solid 2px;"/></td>
                                            <td><a href={{CertificateList[0].birthCertificate}}>Download</a></td>
                                        </tr>
                                        <tr >
                                            <td></td>
                                            <td>Caste Certificate</td>
                                            <td><img src="{{CertificateList[0].casteCertificate}}" class="img-circle" style="height: 150px;width: 150px;border: solid 2px;"/></td>
                                            <td><a href={{CertificateList[0].casteCertificate}}>Download</a></td>
        
                                        </tr>
                                        <tr >
                                            <td></td>
                                            <td>Medical Certificate</td>
                                            <td><img src="{{CertificateList[0].medicalCertificate}}" class="img-circle" style="height: 150px;width: 150px;border: solid 2px;"/></td>
                                            <td><a href={{CertificateList[0].medicalCertificate}}>Download</a></td>
        
                                        </tr>
                                        <tr >
                                            <td></td>
                                            <td>SSC Certificate</td>
                                            <td><img src="{{CertificateList[0].sscCertificate}}" class="img-circle" style="height: 150px;width: 150px;border: solid 2px;"/></td>
                                            <td><a href={{CertificateList[0].sscCertificate}}>Download</a></td>
        
                                        </tr>
                                        <tr >
                                            <td></td>
                                            <td>Degree Certificate</td>
                                            <td><img src="{{CertificateList[0].degreeCertificate}}" class="img-circle" style="height: 150px;width: 150px;border: solid 2px;"/></td>
                                            <td><a href={{CertificateList[0].degreeCertificate}}>Download</a></td>
        
                                        </tr>
                                        <tr >
                                            <td></td>
                                            <td>PG Certificate</td>
                                            <td><img src="{{CertificateList[0].pgCertificate}}" class="img-circle" style="height: 150px;width: 150px;border: solid 2px;"/></td>
                                            <td><a href={{CertificateList[0].pgCertificate}}>Download</a></td>
        
                                        </tr>
                                    </tbody>
                                </table>
                            </div>  </div>
                        </div>
                    </div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row" *ngIf="!showapprove">
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div class="col-md-2">&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div class="col-md-8 row card">
                            <br />
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <label for="amountPerYear">Select Group/Customer</label>
                                <select (change)="onselecttouser($event.target.value)" class="form-control">
                                    <option selected="selected" value="">Select Here</option>
                                    <option selected="selected" value="1">Customer</option>
                                    <option selected="selected" value="2">Group</option>
                                </select>
                            </div>
                            <br /><br />
                            <div class="row" *ngIf="operation == '1'" style="margin-top: -8%;">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <br />
                                    <label for="amountPerYear">Select Status</label>
                                    <select (change)="onSelectStatus($event.target.value)" class="form-control">
                                        <option value="">Select Status</option>
                                        <option *ngFor="let do of WFStatusList" value={{do.workflowStateId}}>
                                            {{do.workflowStateName}}
                                        </option>
                                    </select>
                                    <br /><br />
                                </div>
                            </div>
                            <br />
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngIf="operation == '1'">
                                <br/>
                                <div>
                                    <label for="amountPerYear">Comments</label>
                                    <textarea type="text" class="form-control" placeholder="Enter Your Comments" name="comments"
                                        [(ngModel)]="comments"></textarea>
                                </div>
                                <br/><br/>
                                <div>
                                    <label for="amountPerYear">Upload File</label>
                                    <input type="file" (change)="onChangeApproveFiles($event)" />
                                </div>
                                <br /><br />
                                <button type="button" class="btn btn-primary waves-light" mdbWavesEffect
                                    (click)="SendVerificationtoCustomer()" style="width: 50%;">Send</button>
                                <br /><br />
                            </div>
                    
                            <div class="row" *ngIf="operation == '2'" style="margin-top: -8%;">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <br />
                                    <label for="amountPerYear">Select Status</label>
                                    <select (change)="onSelectStatus($event.target.value)" class="form-control">
                                        <option value="">Select Status</option>
                                        <option *ngFor="let do of WFStatusList" value={{do.workflowStateId}}>
                                            {{do.workflowStateName}}
                                        </option>
                                    </select>
                                </div><br />
                    
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngIf="operation == '2'">
                                <br/>
                                <div>
                                    <label for="amountPerYear">Comments</label>
                                    <textarea type="text" class="form-control" placeholder="Enter Your Comments" name="comments"
                                    [(ngModel)]="comments"></textarea>
                                </div>
                                <br/><br/>
                                <div>
                                    <label for="amountPerYear">Upload File</label>
                                    <input type="file" (change)="onChangeApproveFiles($event)" />
                                </div>
                                <br /><br />
                                <button type="button" class="btn btn-primary waves-light" mdbWavesEffect
                                    (click)="SendVerificationtoApprover()" style="width: 50%;">Send</button>
                                <br /><br />
                            </div>
                    
                        </div>
                        <br /><br />
                    </div>
                    <button *ngIf="showapprove" type="button" (click)="approve()" id="signinbutton">Approve</button>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                </div>
            </div>
        </div>
        <br />
        <br />
        <br />
    </mat-step>
    <br />
    <br />
    <br />
    <br />
    <br />

    <ng-template matStepperIcon="details">
        <mat-icon>account_circle</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="account">
        <mat-icon>account_balance</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="insurance">
        <mat-icon>directions_car</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="Certificates">
        <mat-icon>cloud_upload</mat-icon>
    </ng-template>
    

</mat-horizontal-stepper>