<app-header></app-header>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<div class="col-md-12">&nbsp;</div>
<h2 style="text-align:center">Cities List</h2>
<div>&nbsp;</div>

<div class="row col-sm-12">
  <div class="col-sm-9"></div>
  <div class="col-sm-1">
    <button style="background-color: green" class="btn btn-success" data-toggle="modal" data-target="#basicExample"
      mdbWavesEffect (click)="addClick()">
      Add
    </button>
  </div>
</div>
<br /><br />

<div class="col-md-12 row">
  <div class="col-md-1"></div>
  <div class="col-md-10">
    <div class="table-responsive">
      <div style="overflow-x:visible;height: 300px;overflow-y: visible">
        <table class="table table-bordered" style="border-collapse: collapse;">
          <tr style="font-size:17px">
            <th>Sl No</th>
            <th>Country</th>
            <th>State</th>
            <th>District</th>
            <th>City </th>
            <th>City Description</th>
            <th>Created By</th>
            <th>Created On</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
          <tr *ngFor="let aa of Cities| paginate: { itemsPerPage: 5, currentPage: p };let i = index"
            style="font-size:16px;font-family:DINNextRoundedLTPro ">
            <td> {{ 5 * (p - 1) + (i+1) }}</td>
            <td>{{aa.CountryName}}</td>
            <td>{{aa.StateName}}</td>
            <td>{{aa.DistrictName}}</td>
            <td>{{aa.CityName}}</td>
            <td>{{aa.CityDesc}}</td>
            <td>{{aa.CreatedBy}}</td>
            <td>{{aa.CreatedOn|date}}</td>
            <td>
              <button style="background-color:#ff8000 " class="btn btn-success" data-toggle="modal"
                data-target="#basicExample" mdbWavesEffect (click)="editCity(aa)">
                Edit
              </button>
            </td>
            <td>
              <button class="btn btn-danger" data-toggle="modal" data-target="#deleteExample" mdbWavesEffect
                (click)="deleteCity(aa)">Delete</button>
            </td>
          </tr>
        </table>

        <br />
        <pagination-controls (pageChange)="p = $event" style="float: right" ></pagination-controls>
      </div>
    </div>
    <br /><br /><br /><br />
  </div>
</div>

<br /><br /><br />
<div class="modal modal-angular fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center"> City</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body text-center">
        <form #CityForm="ngForm">
          <div class="col-md-12 row">
            <div class="form-horizontal container">
              <div class="col-md-12 row">
                <label class="col-md-4" style="color:black;padding-top: 13px">Country :</label>
                <div class="col-md-8">
                  <select (change)="onSelectCountry($event.target.value)" class="form-control">
                    <option value="">Select Country</option>
                    <option *ngFor="let do of Countries" value={{do.CountryId}} [selected]="do.CountryId == countryid">
                      {{do.CountryName}}
                    </option>
                  </select>
                </div>
              </div><br />
              <div class="col-md-12 row">
                <label class="col-md-4" style="color:black;padding-top: 13px">State :</label>
                <div class="col-md-8">
                  <select (change)="onSelectState($event.target.value)" class="form-control">
                    <option value="">Select State</option>
                    <option *ngFor="let do of StatesFromCountry" value={{do.StateId}}
                      [selected]="do.StateId == stateid">
                      {{do.StateName}}
                    </option>
                  </select>
                </div>
              </div><br />
              <div class="col-md-12 row">
                <label class="col-md-4" style="color:black;padding-top: 13px">District :</label>
                <div class="col-md-8">
                  <select (change)="onSelectDistrict($event.target.value)" class="form-control">
                    <option value="">Select District</option>
                    <option *ngFor="let do of DistrictsFromState" value={{do.DistrictId}}
                      [selected]="do.DistrictId == districtid">
                      {{do.DistrictName}}
                    </option>
                  </select>
                </div>
              </div><br />
              <div class="col-md-12 row">
                <label class="col-md-4" style="color:black;padding-top: 13px">City Name :</label>
                <div class="col-md-8">
                  <input type="text" class="form-control" id="cityName" name="cityName" placeholder="Enter City Name"
                    [(ngModel)]="cityName" />
                </div>
              </div><br />
              <div class="col-md-12 row">
                <label class="col-md-4" style="color:black;padding-top: 13px">City Description :</label>
                <div class="col-md-8">
                  <input type="text" class="form-control" id="cityDesc" name="cityDesc"
                    placeholder="Enter City Description" [(ngModel)]="cityDesc" />
                </div>
              </div><br />
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning btn-sm waves-light" data-dismiss="modal"
           mdbWavesEffect>Close</button>
        <button *ngIf="showSave" data-dismiss="modal" type="submit" class="btn btn-success btn-sm waves-light" mdbWavesEffect
          (click)="SaveCity(CityForm)">Save</button>
        <button *ngIf="showUpdate" data-dismiss="modal" type="submit" class="btn btn-success btn-sm waves-light"
          (click)="UpdateCity(CityForm)">Update </button>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-angular fade" id="deleteExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Delete City</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <h3 style="text-align: center">Are you sure to delete..??</h3>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-dismiss="modal" 
          mdbWavesEffect>Close</button>
        <button type="submit" data-dismiss="modal" style="background-color: red" class="btn btn-danger" (click)="deleteCityRow()"
          mdbWavesEffect>Delete</button>
      </div>
    </div>

  </div>

</div>
<!-- notification alert -->
<div class="col-md-12 row">
  <div class="col-md-2">&nbsp;</div>
  <div class="col-md-4" *ngIf="dismissalert">
    <div class="alert alert-info"
      style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 550px;width: 350px;">
      <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <i class="material-icons" style="color: white">close</i>
      </button>
      <span style="color: white;margin-top: 6px;">
        <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
    </div>
  </div>
</div>
<br /> <br />