<app-header></app-header>

<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div class="container padding-bottom-3x mb-1">
    <div class="card mb-3">
        <div class="p-4 text-center text-white text-lg bg-dark rounded-top"
            style="background: -webkit-linear-gradient(left, #848484, #444141);">
            <span class="text-uppercase" style="font-size: 22px;font-weight:bold;">Track Status </span>
        </div>
        <div class="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2 bg-secondary">
        </div>
        <div class="card-body">
            <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                <div class="step completed" id="step1">
                    <div class="step-icon-wrap">
                        <div class="step-icon">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                    </div>
                    <h4 class="step-title">Service Requested</h4>
                </div>
                <div class="step completed" id="step2">
                    <div class="step-icon-wrap">
                        <div class="step-icon">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                    </div>
                    <h4 class="step-title">Service Request Accepted</h4>
                </div>
                <div class="step completed" id="step3">
                    <div class="step-icon-wrap">
                        <div class="step-icon">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                    </div>
                    <h4 class="step-title">Sent to Service Provider</h4>
                </div>
                <div class="step completed" id="step4">
                    <div class="step-icon-wrap">
                        <div class="step-icon">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                    </div>
                    <h4 class="step-title">Service In Progress</h4>
                </div>
                <div class="step completed" id="step5">
                    <div class="step-icon-wrap">
                        <div class="step-icon">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                    </div>
                    <h4 class="step-title">Done</h4>
                </div>

            </div>
        </div>
    </div>
    <br /><br />
    <br /><br />


</div>