<app-header></app-header>
<div>&nbsp;</div>
<div class="bodycolor">&nbsp;</div>
<div class="bodycolor">&nbsp;</div>
<div class="bodycolor">&nbsp;</div>
<div class="bodycolor">&nbsp;</div>
<mat-horizontal-stepper #stepper>
    <!-- <mat-step>
        <div>
            <button mat-button matStepperPrevious></button>
            <button mat-button (click)="stepper.reset()"></button>
        </div>
    </mat-step> -->
</mat-horizontal-stepper>
<mat-horizontal-stepper style="background-color :  #c2b6b6;
background-image :  linear-gradient(315deg, #c2b6b6 0%, #576574 74%);
" #stepper1>



    <mat-step label="Step 1" state="details" style="text-align :  center;    height :  96%;width :  136%;">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
            <div class="col-md-1"></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10">

                <div class="homecard" id="signin" align="center"
                    style=" margin-left :  0px;margin-right :  0px;margin-top :  1%">
                    <h2 style="font-family :  DINNextRoundedLTPro;color :  #2b156f;">Personal Details</h2>

                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12">&nbsp;</div>

                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12">&nbsp;</div>

                    <div class="col-md-12">
                        <div class="col-md-12 row">
                            <div class="col-md-2 row">&nbsp;</div>
                            <div class="col-md-3 row" style="display :  flex;">
                                <label id="emaillabel">First Name : &nbsp;</label>&nbsp;{{fname}}
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Middle Name : &nbsp;</label>&nbsp;{{mname}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Last Name : &nbsp;</label>&nbsp;{{lname}}

                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Place of Birth : &nbsp;</label>&nbsp;{{placebirth}}
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Gender : &nbsp;</label>&nbsp;{{gender}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Marital Status : &nbsp;</label>&nbsp;{{marital}}
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Mobile Number : &nbsp;</label>&nbsp;{{mobile}}

                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Date of Birth :
                                    &nbsp;</label>&nbsp;{{dob | date : 'shortDate'}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Religion : &nbsp;</label>&nbsp;{{religion}}
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Caste : &nbsp;</label>&nbsp;{{caste}} Brahmin

                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Qualification :
                                    &nbsp;</label>&nbsp;{{qualification}}


                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Occupation : &nbsp;</label>&nbsp;{{occupation}}


                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel "> Email : &nbsp;</label>&nbsp;{{email}}

                            </div>

                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Designation : &nbsp;</label>&nbsp;{{designation}}


                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <h3 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; "> Residential Address</h3>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Postal Code : &nbsp;</label>&nbsp;{{rPostal}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Country : &nbsp;</label>&nbsp;{{rCountry}} India
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> State : &nbsp;</label>&nbsp;{{rState}}
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>


                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">District : &nbsp;</label>&nbsp;{{rDistrict}}
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Address one : &nbsp;</label>&nbsp;{{rAddress1}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address two : &nbsp;</label>&nbsp;{{rAddress2}}

                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <h3 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Permanent Address</h3>



                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Postal Code : &nbsp;</label>&nbsp;{{pPostal}}

                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Country : &nbsp;</label>&nbsp;{{pCountry}}
                                    India
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel "> State : &nbsp;</label>&nbsp;{{pState}}
                                </div>

                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>

                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">District : &nbsp;</label>&nbsp;{{pDistrict}}
                                </div>

                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Address one : &nbsp;</label>&nbsp;{{pAddress1}}

                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Address two : &nbsp;</label>&nbsp;{{pAddress2}}

                                </div>
                            </div>
                        </div>
                    </div>
                    <BR />
                    <div class="col-md-12 row">
                        <div class="col-md-3 row" *ngIf="role=='1'">&nbsp;</div>
                        <div class="col-md-4 row" *ngIf="role!='1'">&nbsp;</div>
                        <div class="col-md-4 row" *ngIf="role=='1'">
                            <button (click)="EditPersonalDetails()" id="signinbutton">Edit</button>

                        </div>
                        <br />
                        <br />
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-4 row">
                            <button (click)="signIn(stepper1)" id="signinbutton">NEXT</button>
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                </div>
            </div>
        </div>
    </mat-step>
    <mat-step label="Step 2 " state="family" style="text-align: center; height: 96%; width: 136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center "
                    style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Family Details</h2>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Family Type:</label> &nbsp;
                                <!-- <span *ngIf="familyDetails?.familyTypeString == null">Extended Family</span> -->
                                <span
                                    *ngIf="familyDetails?.familyTypeString != null">{{familyDetails?.familyTypeString}}</span>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Number of Dependents:</label>&nbsp;
                                <!-- <span
                                *ngIf="familyDetails?.numberofDependents == null || familyDetails?.numberofDependents == undefined">2</span> -->
                                <span
                                    *ngIf="familyDetails?.numberofDependents != null">{{familyDetails.numberofDependents}}</span>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Number of Children:</label>&nbsp;
                                <!-- <span
                                *ngIf="familyDetails.numberofChildren == null || familyDetails.numberofChildren == undefined">3</span> -->
                                <span
                                    *ngIf="familyDetails.numberofChildren != null">{{familyDetails.numberofChildren}}</span>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Annual Income in Rs. :</label>&nbsp;
                                <!-- <span *ngIf="familyDetails.annualIncomeAmount == null">25000</span> -->
                                <span
                                    *ngIf="familyDetails.annualIncomeAmount != null">{{familyDetails.annualIncomeAmount}}</span>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Income from Other sources in Rs. :</label>&nbsp;
                                <!-- <span *ngIf="familyDetails.otherSourceAmount == null">1000</span> -->
                                <span
                                    *ngIf="familyDetails.otherSourceAmount != null">{{familyDetails.otherSourceAmount}}</span>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Total Annual Income:</label>&nbsp;
                                <!-- <span *ngIf="familyDetails.totalAmount == null">270000</span> -->
                                <span *ngIf="familyDetails.totalAmount != null">{{familyDetails.totalAmount}}</span>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Minor Nominee</h3>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">First Name:</label>&nbsp;
                                <!-- <span *ngIf="familyDetails.minorNomineeName == null">Raj</span> -->
                                <span
                                    *ngIf="familyDetails.minorNomineeName != null">{{familyDetails.minorNomineeName}}</span>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Middle Name:</label>&nbsp;
                                <!-- <span *ngIf="familyDetails.minorNomineeDOB == null">Raj</span> -->
                                <span
                                    *ngIf="familyDetails.minorNomineeDOB != null">{{familyDetails.minorNomineeDOB|date}}</span>

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Last Name:</label>&nbsp;
                                <!-- <span *ngIf="familyDetails.minorNomineeName == null">Raj</span> -->
                                <span
                                    *ngIf="familyDetails.minorNomineeName != null">{{familyDetails.minorNomineeName}}</span>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Postal
                                    Code:</label>&nbsp;{{familyDetails.nomineePincode}}
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Country:</label>&nbsp;{{familyDetails.minorNomineeName}}
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> State:</label>&nbsp;{{familyDetails.nomineeState}}
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email "
                                    id="emaillabel ">District:</label>&nbsp;{{familyDetails.nomineeDistrict}}

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Address Line One:</label>&nbsp;
                                <!-- <span *ngIf="familyDetails.minorNomineeAddress == null">DIVYANKA, 2nd cross</span> -->
                                <span
                                    *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.minorAddress1}}</span>

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line Two:</label>&nbsp;
                                <!-- <span *ngIf="familyDetails.minorNomineeAddress == null">3rd main</span> -->
                                <span
                                    *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.minorAddress2}}</span>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                        </div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Minor Nominee</h3>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Guardian Name:</label>&nbsp;
                                <!-- <span *ngIf="familyDetails.minorNomineeAddress == null">DIVYANKA, 2nd cross</span> -->
                                <span
                                    *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.guardianName}}</span>

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Relationship:</label>&nbsp;
                                <!-- <span *ngIf="familyDetails.minorNomineeAddress == null">3rd main</span> -->
                                <span
                                    *ngIf="familyDetails.minorNomineeAddress != null">{{familyDetails.minorAddress2}}</span>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                        </div>
                        <div *ngIf="familydocsexist">
                            <div class="col-md-12 row">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                            <div class="col-md-12 row">&nbsp;</div>
                            <div class="col-md-12 row">
                                <div class="col-md-1 row">&nbsp;</div>

                                <div class="col-md-2 row" *ngFor="let item of familydocs">
                                    <div *ngIf="item.documentType == 'image/jpeg' ||
                                item.documentType == 'image/png'" (click)="ViewBankFamDoc(item)">
                                        <img [src]="item.file" height="100" width="100" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.documentName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf=" (item.documentType == 'application/pdf' ||
                                item.documentType == 'pdf')" (click)="ViewBankFamDoc(item)">
                                        <img src="assets/img/pdficon.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.documentName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                item.documentType == 'xlsx')" (click)="ViewBankFamDoc(item)">
                                        <img src="assets/img/xls.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.documentName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                                item.documentType == 'doc' || item.documentType == 'docx')"
                                        (click)="ViewBankFamDoc(item)">
                                        <img src="assets/img/docx.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.documentName}}</p>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                    </div>
                    <div class="col-md-12 row">
                        <div class="col-md-3 row" *ngIf="role=='1'">&nbsp;</div>
                        <div class="col-md-4 row" *ngIf="role!='1'">&nbsp;</div>
                        <div class="col-md-4 row" *ngIf="role=='1'">
                            <button (click)="EditFamilyDetails()" id="signinbutton">Edit</button>

                        </div>
                        <br />
                        <br />
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-4 row">
                            <button (click)="details(stepper1)" id="signinbutton">NEXT</button>
                        </div>

                    </div>
                    <br>
                </div>
            </div>
        </div>
    </mat-step>
    <mat-step label="Step 3 " state="account" style="text-align :  center; height :  96%; width :  136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center "
                    style=" margin-left :  0px;margin-right :  0px;margin-top :  1% ">
                    <h2 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Bank Details</h2>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>

                    <div class="col-md-12 ">
                        <div class="container" style="margin-top: 5%">

                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Bank Account Type</th>
                                        <th>Bank Name</th>
                                        <th>Branch</th>
                                        <th>IFSC Code</th>
                                        <th>Cheque Facility</th>
                                        <!-- <th>Loan Facility</th> -->
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of bankdetails">
                                        <td>{{data.banktypeid}}</td>
                                        <td>{{data.bankname}} </td>
                                        <td>{{data.branch}} </td>
                                        <td>{{data.ifscecode}} </td>
                                        <td>{{data.chequefacility}}</td>
                                        <!-- <td>{{data.loanfacility}}</td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-12 row ">


                            <div class="col-md-12 ">&nbsp;</div>

                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <h3
                                style="font-family: DINNextRoundedLTPro;color: #2b156f;text-align: center;    margin-left: 42%; ">
                                Identity Details</h3>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>

                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Ration Card Number:</label>
                                    &nbsp;{{userBankDetails.rationCardNum}}

                                </div>

                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Aadhar
                                        Number:</label>&nbsp;{{userBankDetails.aadharNum}}

                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Voter ID
                                        Number:</label>&nbsp;{{userBankDetails.voterId}}

                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>

                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">PAN Number:</label>&nbsp;{{userBankDetails.panNum}}

                                </div>

                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-4 row ">
                                    <label for="Email " id="emaillabel ">Service Tax
                                        Number:</label>&nbsp;{{userBankDetails.serviceTaxNum}}

                                </div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Passport
                                        Number:</label>&nbsp;{{userBankDetails.passportNum}}

                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>

                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Life Insurance
                                        Value:</label>&nbsp;{{userBankDetails.lifeInsuranceValue}}

                                </div>

                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Is
                                        Confirm:</label>&nbsp;{{userBankDetails.isConfirm}}

                                </div>
                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>
                            <div class="col-md-12 row ">&nbsp;</div>



                            <BR />
                            <div class="col-md-12 row">&nbsp;</div>
                            <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;margin-left: 15%; ">Uploaded
                                Documents</h3> <br>
                            <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;margin-left: 15%; ">Download
                                Documents</h5> <br>

                            <ol>
                                <li *ngIf="userBankDetails.aadharFile"><a
                                        href="{{userBankDetails.aadharFile}}">Aadhar</a></li>
                                <li *ngIf="userBankDetails.bankStatements"><a
                                        href="{{userBankDetails.bankStatements}}">Bank Statement</a></li>
                                <li *ngIf="userBankDetails.gstCert"><a href="{{userBankDetails.gstCert}}">GST</a></li>
                                <li *ngIf="userBankDetails.itReturnsFile"><a
                                        href="{{userBankDetails.itReturnsFile}}">ITR</a></li>
                                <li *ngIf="userBankDetails.passportFile"><a
                                        href="{{userBankDetails.passportFile}}">Passport</a></li>
                                        <li *ngIf="userBankDetails.voterIdFile"><a href="{{userBankDetails.voterIdFile}}">Voter
                                            ID</a></li>
                            </ol>
                            <div class="col-md-12 row">&nbsp;</div>
                            <div class="col-md-2 row" *ngFor="let item of bankdocs">
                                <div *ngIf="item.documentType == 'image/jpeg' ||
                                item.documentType == 'image/png'" (click)="ViewBankFamDoc(item)">
                                    <img [src]="item.file" height="100" width="100" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf=" (item.documentType == 'application/pdf' ||
                                item.documentType == 'pdf')" (click)="ViewBankFamDoc(item)">
                                    <img src="assets/img/pdficon.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                item.documentType == 'xlsx')" (click)="ViewBankFamDoc(item)">
                                    <img src="assets/img/xls.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                                item.documentType == 'doc' || item.documentType == 'docx')"
                                    (click)="ViewBankFamDoc(item)">
                                    <img src="assets/img/docx.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>

                                        </div>

                                    </div>
                                </div>

                            </div>
                            <br>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>
                    </div>
                    <br />

                    <div class="col-md-12 row">
                        <div class="col-md-3 row" *ngIf="role=='1'">&nbsp;</div>
                        <div class="col-md-4 row" *ngIf="role!='1'">&nbsp;</div>
                        <div class="col-md-4 row" *ngIf="role=='1'">
                            <button (click)="EditBankDetails()" id="signinbutton">Edit</button>

                        </div>
                        <br />
                        <br />
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-4 row">
                            <button (click)="bankIn(stepper1)" id="signinbutton">NEXT</button>
                        </div>

                    </div>
                    <br>
                </div>
            </div>
        </div>
    </mat-step>
    <mat-step label="Step 4 " state="homei" style="text-align :  center; height :  96%; width :  136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center "
                    style=" margin-left :  0px;margin-right :  0px;margin-top :  1% ">
                    <div class="col-md-12 ">&nbsp;</div>

                    <h2 style="font-family :  DINNextRoundedLTPro;color :  #2b156f; ">Asset Details</h2>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>

                    <div class="container" style="margin-top :  5%">

                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Fixed Asset</th>
                                    <th>Location with Address</th>
                                    <th>Measurement</th>
                                    <th>Guidance Value</th>
                                    <th>Present Value</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of fixedassetDetails.fixedAssets;let i = index"
                                    style="font-size : 16px;font-family : DINNextRoundedLTPro ">
                                    <!-- <td> {{ 5 * (p - 1) + (i+1) }}</td> -->
                                    <td>
                                        {{data.fixedAssetId}}
                                    </td>
                                    <td>{{data.placeallocation}}</td>

                                    <td>{{data.measurement}}</td>
                                    <td>{{data.giidancevalue}}</td>
                                    <td>{{data.presentvalue}}</td>
                                    <td>{{data.total}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="container" style="margin-top :  5%">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Movable Asset</th>
                                    <th>Model</th>
                                    <th>Date Of Purchase</th>
                                    <th>Quantity</th>
                                    <th>Present Value</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dat of fixedassetDetails.movableAssets;let i = index"
                                    style="font-size : 16px;font-family : DINNextRoundedLTPro ">
                                    <td>
                                        {{dat.movableAssetId}}
                                    </td>
                                    <td>{{dat.model}}</td>
                                    <td>{{dat.dateofpurchase|date}}</td>
                                    <td>{{dat.quantity}}</td>
                                    <td>{{dat.prevalues}}</td>
                                    <td>
                                        {{dat.cartotal}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br>
                    <div class="col-md-12 row">
                        <div class="col-md-3 row" *ngIf="role=='1'">&nbsp;</div>
                        <div class="col-md-4 row" *ngIf="role!='1'">&nbsp;</div>

                        <div class="col-md-4 row" *ngIf="role=='1'">
                            <button (click)="EditHomeVehicleDetails()" id="signinbutton">Edit</button>

                        </div>
                        <br />
                        <br />
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-4 row">
                            <button (click)="homeIn(stepper1)" id="signinbutton">NEXT</button>
                        </div>

                    </div>
                    <br>
                </div>
            </div>
        </div>
    </mat-step>

    <mat-step label="Step 5" state="vehicalloan" style="text-align :  center; height :  96%; width :  136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center "
                    style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Surety Loan</h2>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>


                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Purpose of loan: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.purofloan != null">{{suretyLoanDetails.purofloan}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Loan Amount: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.loama != null">{{suretyLoanDetails.loama}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Document produced in support of purpose of
                                loan: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.support != null">{{suretyLoanDetails.support}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Details of immovable property: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.furnish != null">{{suretyLoanDetails.furnish}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">No. of Dependencies: &nbsp;</label>
                            <span
                                *ngIf="suretyLoanDetails.dependencies != null">{{suretyLoanDetails.dependencies}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Average monthly expenses of applicant and his
                                dependents: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.dependents != null">{{suretyLoanDetails.dependents}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">What is the repaying capacity of the applicant per month:
                                &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.repaying != null">{{suretyLoanDetails.repaying}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Details of Life Insurance policies held by the applicant:
                                &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.policies != null">{{suretyLoanDetails.policies}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Details of family members who can supplement the applicant's
                                income for repayment of loans: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.loaamt != null">{{suretyLoanDetails.loaamt}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Whether salary of the applicant is being remitted to The
                                National Co-op. Bank Ltd.: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.bank != null">{{suretyLoanDetails.bank}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Designation of salary disbursing officer:
                                &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.outstanding != null">{{suretyLoanDetails.outstanding}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Loan Amount: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.value != null">{{suretyLoanDetails.value}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Income of applicant(for salary
                        earners)
                    </h3>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Basic pay Rs.: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.purchased != null">{{suretyLoanDetails.purchased}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">House rent allowance Rs.: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.inclusive != null">{{suretyLoanDetails.inclusive}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">City compensatory allowance Rs.: &nbsp;</label>
                            <span
                                *ngIf="suretyLoanDetails.compensatory != null">{{suretyLoanDetails.compensatory}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Other allowance Rs.: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.allowance != null">{{suretyLoanDetails.allowance}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Deductions</h3>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Income tax Rs.: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.payment != null">{{suretyLoanDetails.payment}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Insurance premium Rs.: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.delivery != null">{{suretyLoanDetails.delivery}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">H.R.A. Rs.: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.hba != null">{{suretyLoanDetails.hba}}</span>

                        </div>
                    </div>


                    <div class="col-md-12 ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Other deductions Rs.: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.applicant != null">{{suretyLoanDetails.applicant}}</span>

                        </div>
                    </div>
                    <div class="col-md-12">&nbsp;</div>

                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Other than Salary Earners(fill up the
                        following columns)</h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Furnish full particulars and address where the business/profession
                                is carried out: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.particulars != null">{{suretyLoanDetails.particulars}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Nature of business/profession and when
                                established: &nbsp;</label>
                            <span
                                *ngIf="suretyLoanDetails.partnersloan != null">{{suretyLoanDetails.partnersloan}}</span>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Annual Turnover: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.turnover != null">{{suretyLoanDetails.turnover}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Annual Income: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.cashloan != null">{{suretyLoanDetails.cashloan}}</span>

                        </div>
                    </div>


                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">IT No./PAN No.: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.acnumber != null">{{suretyLoanDetails.acnumber}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Documentary evidence produced in support of nature
                                of business: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.balance != null">{{suretyLoanDetails.balance}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Is the applicant income tax/sales tax
                                assessee: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.assessee != null">{{suretyLoanDetails.assessee}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">In case of applicant is a non-earning member state how repayment
                                will be made: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.earning != null">{{suretyLoanDetails.earning}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Other sources of income, if any, furnish
                                details: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.modelname != null">{{suretyLoanDetails.modelname}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Other informations, if any: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.remarks != null">{{suretyLoanDetails.remarks}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> For Salaried Person</h3>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">a. Name of the employee: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.employed != null">{{suretyLoanDetails.employed}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">b. Period of service completed: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.finishloan != null">{{suretyLoanDetails.finishloan}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> For Business Person</h3>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">a. Name of concern: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.concern != null">{{suretyLoanDetails.concern}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">b. Address where business is located: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.located != null">{{suretyLoanDetails.located}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">c. Nature of Activity: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.activity != null">{{suretyLoanDetails.activity}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">d. if assessed under: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.under != null">{{suretyLoanDetails.under}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">e. ANNUAL INCOME: &nbsp;</label>
                            <span
                                *ngIf="suretyLoanDetails.annualincome != null">{{suretyLoanDetails.annualincome}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Relationship with borrower: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.borrower != null">{{suretyLoanDetails.borrower}}</span>

                        </div>
                    </div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Do you have any immovable property : If so, furnish full
                                particulars: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.property != null">{{suretyLoanDetails.property}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Assets with bank: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.assetsdata != null">{{suretyLoanDetails.assetsdata}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Whether drawing salary through The National Co-op.
                                Bank: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.drawing != null">{{suretyLoanDetails.drawing}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Additional Information (Education)
                    </h3>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Name of the person for whose benefit Loan is being
                                availed: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.benefit != null">{{suretyLoanDetails.benefit}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Relationship: &nbsp;</label>
                            <span
                                *ngIf="suretyLoanDetails.relationship != null">{{suretyLoanDetails.relationship}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Name of School /College in which the Children are
                                studying: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.studying != null">{{suretyLoanDetails.studying}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Particulars of course i.e. Degree Course/Engineering/Medical
                                etc.: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.course != null">{{suretyLoanDetails.course}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Is the loan proposed to be utilised for payment of
                                donation (Furnish full particulars): &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.modelname != null">{{suretyLoanDetails.modelname}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <!-- <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">List of documentary evidence produced in support of
                                the purpose: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.evidence != null">{{suretyLoanDetails.evidence}}</span>

                        </div> -->
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Full particulars of the deposits held with the bank: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.deposits != null">{{suretyLoanDetails.deposits}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Additional information if any: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.additional != null">{{suretyLoanDetails.additional}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Loan Account Number: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.account != null">{{suretyLoanDetails.account}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <!-- <div class="col-md-3 row ">
                            <label id="emaillabel ">Membership Number: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.membership != null">{{suretyLoanDetails.membership}}</span>

                        </div> -->

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">SB Account Number: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.numbersb != null">{{suretyLoanDetails.numbersb}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Age: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.age != null">{{suretyLoanDetails.age}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Office Address: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.Officedata != null">{{suretyLoanDetails.Officedata}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Department: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.department != null">{{suretyLoanDetails.department}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">GST Number: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.salesdata != null">{{suretyLoanDetails.salesdata}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Annual Income: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.income != null">{{suretyLoanDetails.income}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Whether member of any other Co-operative Bank. If
                                so,particulars & liabilities, if any: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.operative != null">{{suretyLoanDetails.operative}}</span>

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Whether related to any of the Board of
                                Directors: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.board != null">{{suretyLoanDetails.board}}</span>

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Salary Particulars of the month of: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.salary != null">{{suretyLoanDetails.salary}}</span>

                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Applicant's and his/her family monthly Domestic
                                Expenses: &nbsp;</label>
                            <span *ngIf="suretyLoanDetails.domestic != null">{{suretyLoanDetails.domestic}}</span>

                        </div>
                    </div>
                    <br>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row">
                        <div class="col-md-3 row" *ngIf="role=='1'">&nbsp;</div>
                        <div class="col-md-4 row" *ngIf="role!='1'">&nbsp;</div>
                        <div class="col-md-4 row" *ngIf="role=='1'">
                            <button (click)="EditSuretyDetails()" id="signinbutton">Edit</button>

                        </div>
                        <br />
                        <br />
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-4 row">
                            <button (click)="suretyloan(stepper1)" id="signinbutton">NEXT</button>
                        </div>

                    </div>
                    <br />

                    <br />

                </div>
            </div>
        </div>
    </mat-step>


    <mat-step label="Step 6" state="Certificates" style="text-align: center; height: 96%; width: 136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center "
                    style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                    <div *ngIf="certificatesexist">
                        <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="col-md-12 row">
                            <!-- <div class="col-md-1 row">&nbsp;</div> -->

                            <div class="col-md-4 row" *ngFor="let item of CertificateDisplay">
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'image/jpeg' ||
                             item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                    <img [src]="item.certificate" height="100" width="100" />
                                    <p style="text-align: center;"><br />{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/pdf' ||
                             item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                    <img src="assets/img/pdficon.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <p style="text-align: center;"><br />{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                             item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                    <img src="assets/img/xls.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <p style="text-align: center;"><br />{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                             item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                    <img src="assets/img/docx.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <p style="text-align: center;"><br />{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                </div>


                            </div>
                        </div>
                        <div class="col-md-12 row">&nbsp;</div>

                        <div *ngIf="enableothercertificates">
                            <div class="col-md-12 row">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Uploaded Documents</h4>

                            <div class="col-md-12 row">

                                <div class="col-md-3 row" *ngFor="let item of allcertificates">
                                    <div *ngIf="item.actualCertificateName!=null  && (item.documentType == 'image/jpeg' ||
                             item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                        <img [src]="item.certificate" height="100" width="100" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/pdf' ||
                             item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                        <img src="assets/img/pdficon.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                             item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/xls.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                             item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/docx.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 row">
                        <div class="col-md-5 row">&nbsp;</div>
                        <div class="col-md-4 row" *ngIf="role=='1'">
                            <button (click)="EditCertificatesDetails()" id="signinbutton">Edit</button>

                        </div>
                        <br />


                    </div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row" *ngIf="!showapprove">
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div class="col-md-2">&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div class="col-md-8 row card">
                            <br />
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <label for="amountPerYear">Select Group/Customer</label>
                                <select (change)="onselecttouser($event.target.value)" class="form-control">
                                    <option selected="selected" value="">Select Here</option>
                                    <option selected="selected" value="1">Customer</option>
                                    <option selected="selected" value="2">Group</option>
                                </select>
                            </div>
                            <br /><br />
                            <div class="row" *ngIf="operation == '1'" style="margin-top: -8%;">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <br />
                                    <label for="amountPerYear">Select Status</label>
                                    <select (change)="onSelectStatus($event.target.value)" class="form-control">
                                        <option value="">Select Status</option>
                                        <option *ngFor="let do of WFStatusList" value={{do.workflowStateId}}>
                                            {{do.workflowStateName}}
                                        </option>
                                    </select>
                                    <br /><br />
                                </div>
                            </div>
                            <br />
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngIf="operation == '1'">
                                <br />
                                <div>
                                    <label for="amountPerYear">Comments</label>
                                    <textarea type="text" class="form-control" placeholder="Enter Your Comments"
                                        name="comments" [(ngModel)]="comments"></textarea>
                                </div>
                                <br /><br />
                                <div>
                                    <label for="amountPerYear">Upload File</label>
                                    <input type="file" (change)="onChangeApproveFiles($event)" />
                                </div>
                                <br /><br />
                                <button type="button" class="btn btn-primary waves-light" mdbWavesEffect
                                    (click)="SendVerificationtoCustomer()" style="width: 50%;">Send</button>
                                <br /><br />
                            </div>

                            <div class="row" *ngIf="operation == '2'" style="margin-top: -8%;">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <br />
                                    <label for="amountPerYear">Select Status</label>
                                    <select (change)="onSelectStatus($event.target.value)" class="form-control">
                                        <option value="">Select Status</option>
                                        <option *ngFor="let do of WFStatusList" value={{do.workflowStateId}}>
                                            {{do.workflowStateName}}
                                        </option>
                                    </select>
                                </div><br />

                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngIf="operation == '2'">
                                <br />
                                <div>
                                    <label for="amountPerYear">Comments</label>
                                    <textarea type="text" class="form-control" placeholder="Enter Your Comments"
                                        name="comments" [(ngModel)]="comments"></textarea>
                                </div>
                                <br /><br />
                                <div>
                                    <label for="amountPerYear">Upload File</label>
                                    <input type="file" (change)="onChangeApproveFiles($event)" />
                                </div>
                                <br /><br />
                                <button type="button" class="btn btn-primary waves-light" mdbWavesEffect
                                    (click)="SendVerificationtoApprover()" style="width: 50%;">Send</button>
                                <br /><br />
                            </div>

                        </div>
                        <br /><br />
                    </div>
                    <div class="col-md-12 row">
                        <div class="col-md-5 row">&nbsp;</div>
                        <div class="col-md-4 row">
                            <button *ngIf="showapprove" type="button" (click)="approve()"
                                id="signinbutton">Approve</button>

                        </div>
                        <br />

                    </div>



                    <div class="col-md-12 row ">&nbsp;</div>

                </div>
            </div>
        </div>
    </mat-step>

    <br /><br /><br /><br /> <br />

    <!-- Icon overrides. -->
    <!-- #docregion override-icons -->
    <ng-template matStepperIcon="details">
        <mat-icon>account_circle</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="family">
        <mat-icon>family_restroom</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="account">
        <mat-icon>account_balance</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="homei">
        <mat-icon>home</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="business">
        <mat-icon>business</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="vehicalloan">
        <mat-icon>directions_car</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="upload">
        <mat-icon>cloud_upload</mat-icon>
    </ng-template>

</mat-horizontal-stepper>