<app-header></app-header>
<div>&nbsp;</div>
<div>&nbsp;</div>
<mat-horizontal-stepper #stepper>
    <!-- <mat-step>
        <div>
            <button mat-button matStepperPrevious></button>
            <button mat-button (click)="stepper.reset()"></button>
        </div>
    </mat-step> -->
</mat-horizontal-stepper>

<!-- #docregion states -->
<mat-horizontal-stepper style="background-color: #c2b6b6;
background-image: linear-gradient(315deg, #c2b6b6 0%, #576574 74%);" #stepper1>



    <mat-step label="Step 1" state="details" style="text-align: center;    height: 96%;width: 136%;">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
            <div class="col-md-1"></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10">

                <div class="homecard" id="signin" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%">
                    <h1 style="font-family: DINNextRoundedLTPro;color: #2b156f;font-size: 34px;">Home Insurance Form</h1>

                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Personal Details</h2>

                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12">&nbsp;</div>
                    <form id="msform" [formGroup]="RegistrationForm">
                        <div class="col-md-12 row">
                            <div class="col-md-4">&nbsp;</div>
                            <div class="col-md-4">
                                <div class="form-card">
                                    <div class="container fileUploadWrapper">
                                        <div class="col-md-12">
                                            <img src="{{userImage}}" class="img-circle" style="height: 150px;width: 150px;border: solid 2px;
                                border-radius: 7px;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row">
                            <div class="col-md-2 row">&nbsp;</div>
                            <div class="col-md-3 row">
                                <label id="emaillabel">Customer Id</label>
                                <input (keyup.enter)="signIn(stepper1)" (change)="getCustomerImage(customerid)" formControlName="customerid" class="form-control" type="text" id="inputbutton" [(ngModel)]="customerid">
                            </div>
                        </div>
                        <br>
                        <div class="col-md-12 row">
                            <div class="col-md-2 row">&nbsp;</div>
                            <div class="col-md-3 row">

                                <label id="emaillabel">First Name</label>

                                <input (keyup.enter)="signIn(stepper1)" formControlName="firstname" class="form-control" type="text" id="inputbutton" [(ngModel)]="firstname" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.firstname.errors.required ">First Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Middle Name</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="middlename" class="form-control " type="text " id="inputbutton " [(ngModel)]="middlename ">
                                <!-- <div *ngIf="submitted && f.middlename.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="f.middlename.errors.required ">Middle Name is required
                            </div>
                        </div> -->
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Last Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" (keyup.enter)="signIn(stepper1) " formControlName="lastname" class="form-control " type="text " id="inputbutton " [(ngModel)]="lastname ">
                                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.lastname.errors.required ">Last Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Place of Birth</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.placeofbirth.errors }" (keyup.enter)="signIn(stepper1) " formControlName="placeofbirth" class="form-control " type="text " id="inputbutton " [(ngModel)]="placeofbirth ">
                                <div *ngIf="submitted && f.placeofbirth.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.placeofbirth.errors.required ">Place is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Gender</label>
                                <select (change)="onSelectgender($event.target.value)" class="form-control">
                        <option value="">Select Gender</option>
                        <option *ngFor="let do of genders" value={{do.genderId}}
                            [selected]="do.genderId==genderid">
                            {{do.genderName}}
                        </option>
                    </select>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Caste</label>
                                <select (change)="onSelectCaste($event.target.value)" class="form-control">
                        <option value="">Select Caste</option>
                        <option *ngFor="let do of castes" value={{do.raceId}}
                            [selected]="do.raceId==casteid">
                            {{do.raceName}}
                        </option>
                    </select>
                            </div>
                            <div class="col-md-2 row " *ngIf="othercastedetails">&nbsp;</div>

                            <div class="col-md-3 row " *ngIf="othercastedetails">
                                <label for="Email " id="emaillabel ">Caste</label>
                                <input formControlName="castedetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="castedetail">
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Mobile Number</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.Number.errors }" (keyup.enter)="signIn(stepper1) " formControlName="Number" class="form-control" type="text" id="inputbutton " [(ngModel)]="Number ">
                                <div *ngIf="submitted && f.Number.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.Number.errors.required ">Mobile Number is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Date of Birth</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.dateofbirth.errors }" (keyup.enter)="signIn(stepper1) " formControlName="dateofbirth" class="form-control" type="date" id="inputbutton " [(ngModel)]="dateofbirth ">
                                <div *ngIf="submitted && f.dateofbirth.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.dateofbirth.errors.required ">Date of Birth is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Religion</label>
                                <select (change)="onSelectrelogion($event.target.value)" class="form-control">
                        <option value="">Select Religion</option>
                        <option *ngFor="let do of religions" value={{do.religionId}}
                            [selected]="do.religionId==rid">
                            {{do.religionName}}
                        </option>
                    </select> </div>
                            <div class="col-md-2 row " *ngIf="othereligiondetails">&nbsp;</div>
                            <div class="col-md-3 row " *ngIf="othereligiondetails">
                                <label for="Email " id="emaillabel ">Religion</label>
                                <input formControlName="religiondetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="religiondetail">
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Marital Status</label>
                                <select (change)="onSelectmarital($event.target.value)" class="form-control">
                        <option value="">Select Marital Status</option>
                        <option *ngFor="let do of status" value={{do.maritalStatusId}}
                            [selected]="do.maritalStatusId==martid">
                            {{do.maritalStatus}}
                        </option>
                    </select> </div>



                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Qualification</label>
                                <select (change)="onSelectqualification($event.target.value)" class="form-control">
                        <option value="">Select Qualification</option>
                        <option *ngFor="let do of qualifications" value={{do.qualificationId}}
                            [selected]="do.qualificationId==quaid">
                            {{do.qualificationName}}
                        </option>
                    </select>

                            </div>
                            <div class="col-md-1 row " *ngIf="otherequalification ">&nbsp;</div>
                            <!-- <div class="col-md-1 row " *ngIf="otherequalification">&nbsp;</div> -->

                            <div class="col-md-3 row " *ngIf="otherequalification">
                                <label for="Email " id="emaillabel ">Qualification</label>
                                <input formControlName="qualificationdetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="qualificationdetail">
                            </div>
                            <div class="col-md-1 row " *ngIf="otherequalification" style="  margin-left: 1%;">&nbsp;</div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Occupation</label>
                                <select (change)="onSelectoccupation($event.target.value)" class="form-control">
                        <option value="">Select Occupation</option>
                        <option *ngFor="let do of occupations" value={{do.occupationId}}
                            [selected]="do.occupationId==occuid">
                            {{do.occupationName}}
                        </option>
                    </select>

                            </div>

                            <div class="col-md-2 row " *ngIf="othersoccupation">&nbsp;</div>

                            <div class="col-md-3 row " *ngIf="othersoccupation">
                                <label for="Email " id="emaillabel ">Occupation</label>
                                <input formControlName="occupatoiondetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="occupatoiondetail">
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Email</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (keyup.enter)="signIn(stepper1) " formControlName="email" class="form-control " type="text " id="inputbutton " [(ngModel)]="email ">
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.email.errors.required ">Email is required</div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Designation</label>
                                <select (change)="onSelectdesignation($event.target.value)" class="form-control">
                        <option value="">Select Designations</option>
                        <option *ngFor="let do of designations" value={{do.id}} [selected]="do.id==desid">
                            {{do.designationName}}
                        </option>
                    </select>
                            </div>
                            <div class="col-md-3 row " *ngIf="otherdesignation" style="margin-left: 5%;">

                                <label for="Email " id="emaillabel ">Designation</label>
                                <input formControlName="designationd" class="form-control " type="text " id="inputbutton " [(ngModel)]="designationd">
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h4>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Postal Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" (keyup.enter)="signIn(stepper1) " formControlName="pincode" class="form-control " type="number " id="inputbutton " [(ngModel)]="pincode" (change)="getalldata()">
                                <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.pincode.errors.required ">Postal is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Country</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="country" class="form-control " type="text " id="inputbutton " [(ngModel)]="country" readonly>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> State</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="state" class="form-control " type="text " id="inputbutton " [(ngModel)]="state" readonly>
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">District</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="district" class="form-control " type="text " id="inputbutton " [(ngModel)]="district" readonly>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Address one</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.city.errors }" (keyup.enter)="signIn(stepper1) " formControlName="city" class="form-control " type="text " id="inputbutton " [(ngModel)]="city">
                                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.city.errors.required ">City Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address two</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.street.errors }" (keyup.enter)="signIn(stepper1) " formControlName="street" class="form-control " type="text " id="inputbutton " [(ngModel)]="street">
                                <div *ngIf="submitted && f.street.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.street.errors.required ">Street Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-7 row ">
                                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Permanent Address is Same as Residential Address</h4>
                            </div>
                            <div class="col-md-1 row ">
                                <input class="form-control" formControlName="PermanentSameasResidential" [checked]="PermanentSameasResidential" [(ngModel)]="PermanentSameasResidential" (change)="onSelectaddress($event)" type="checkbox" id="inputbutton ">
                            </div>
                            <div class="col-md-2 row ">&nbsp;</div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Postal Code</label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" (keyup.enter)="signIn(stepper1) " formControlName="postalcode" class="form-control " type="number " id="inputbutton " (change)="getpincoddata()" [(ngModel)]="postalcode">
                                    <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="f.postalcode.errors.required ">Postal is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Country</label>
                                    <input (keyup.enter)="signIn(stepper1) " formControlName="countryone" class="form-control " type="text " id="inputbutton " [(ngModel)]="countryone" readonly>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel "> State</label>
                                    <input (keyup.enter)="signIn(stepper1) " formControlName="stateone" class="form-control " type="text " id="inputbutton " [(ngModel)]="stateone" readonly>
                                </div>

                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>

                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">District</label>
                                    <input (keyup.enter)="signIn(stepper1) " formControlName="districtone" class="form-control " type="text " id="inputbutton " [(ngModel)]="districtone" readonly>
                                </div>

                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Address one</label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.adressone.errors }" (keyup.enter)="signIn(stepper1) " formControlName="adressone" class="form-control " type="text " id="inputbutton " [(ngModel)]="adressone">
                                    <div *ngIf="submitted && f.adressone.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="f.adressone.errors.required ">Address one is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Address two</label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.adresstwo.errors }" (keyup.enter)="signIn(stepper1) " formControlName="adresstwo" class="form-control " type="text " id="inputbutton " [(ngModel)]="adresstwo">
                                    <div *ngIf="submitted && f.adresstwo.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="f.adresstwo.errors.required ">Address two is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <BR /> &nbsp;
                    <div class="col-md-12 row">
                        <div class="col-md-4 row">&nbsp;</div>
                        <div class="col-md-3 row">
                            <button (click)="SaveBasicDraft(stepper1)"  id="signinbutton">Draft</button>

                        </div>
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-3 row">
                            <button (click)="signIn(stepper1)"  id="signinbutton">Next</button>
                        </div>

                    </div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                </div>
            </div>
        </div>
    </mat-step>


 
    <mat-step label="Step 2" state="account" style="text-align: center; height: 96%; width: 136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center " style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Bank Details</h2>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>

                    <div class="container" style="margin-top: 5%">

                        <table class="table table-striped   table-responsive table-bordered">
                            <thead>
                                <tr>
                                    <th>Bank Account Type</th>
                                    <th>Bank Name</th>
                                    <th>Branch</th>
                                    <th>IFSC Code</th>
                                    <th>Cheque Facility</th>
                                    <!-- <th>Loan Facility</th> -->
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let field of fieldArray; let i = index">
                                    <td>
                                        <select (change)="onSelectbank($event.target.value)" class="form-control">
                                        <option value="">Select Bank Account Type</option>
                                        <option *ngFor="let do of banks" value={{do.bankAccTypeId}}
                                            [selected]="do.bankAccTypeId == field.banktypeid">
                                            {{do.accountType}}
                                        </option>
                                    </select>
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.bankname" class="form-control" type="text" name="{{field.bankname}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.branch" class="form-control" type="text" name="{{field.branch}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.ifscecode" class="form-control" type="text" name="{{field.ifscecode}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.selectedchequetypeid" class="form-control" />
                                    </td>
                                    <!-- <td>
                                    <input [(ngModel)]="field.loanfacility" class="form-control" type="checkbox"
                                        name="{{field.loanfacility}}" />
                                </td> -->
                                    <td>
                                        <button class="btn btn-default" type="button" (click)="deleteFieldValue(i)">Delete</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <select (change)="onSelectbank($event.target.value)" class="form-control">
                                        <option value="">Select Bank Account Type</option>
                                        <option *ngFor="let do of banks" value={{do.bankAccTypeId}}>
                                            {{do.accountType}}
                                        </option>
                                    </select>
                                    </td>
                                    <td>
                                        <input class="form-control" type="text" id="bankname" [(ngModel)]="newAttribute.bankname" name="bankname" />

                                    </td>
                                    <td>
                                        <input class="form-control" type="text" id="branch" [(ngModel)]="newAttribute.branch" name="branch" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="text" id="ifscecode" [(ngModel)]="newAttribute.ifscecode" name="ifscecode" />
                                    </td>
                                    <td>
                                        <select (change)="onSelectChequeType($event.target.value)" class="form-control">
                                        <option value="">Select Cheque Facility Type</option>
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </select>
                                        <!-- <input class="form-control" type="checkbox" id="chequefacility"
                                        [(ngModel)]="newAttribute.chequefacility" name="chequefacility" /> -->
                                    </td>
                                    <!-- <td>
                                    <input class="form-control" type="checkbox" id="loanfacility"
                                        [(ngModel)]="newAttribute.loanfacility" name="loanfacility" />
                                </td> -->
                                    <td>
                                        <button class="btn btn-default" type="button" (click)="addFieldValue()">Add</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <form class="register-form " [formGroup]="BankDetailsForm">
                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Identity Details</h4>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Ration Card Number</label>
                                <input formControlName="rationcardnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="rationcardnum">
                                <!-- <div *ngIf="submitted && k.rationcardnum.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.rationcardnum.errors.required "> Ration Card
                                    Number is required</div>
                                <div style="color: red; " *ngIf="k.rationcardnum.errors.pattern ">enter valid Ration
                                    Card Number</div>
                            </div> -->
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Aadhar Number</label>
                                <input  [ngClass]="{ 'is-invalid': submitted && k.aadharnum.errors } " formControlName="aadharnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="aadharnum" >
                                <div *ngIf="submitted && k.aadharnum.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.aadharnum.errors.required ">Aadhar Number is required
                                </div>
                                <div style="color: red; " *ngIf="k.aadharnum.errors.pattern ">enter valid Aadhar Number
                                </div>

                            </div>
                            <!-- <div style="color: red; " *ngIf="aadhaarNumberError" class="error-message">Invalid Aadhaar number</div> -->
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Voter ID Number</label>
                                <input [ngClass]="{ 'is-invalid': submitted && k.voternum.errors } " formControlName="voternum" class="form-control " type="text " id="inputbutton " [(ngModel)]="voternum"  >
                                <div *ngIf="submitted && k.voternum.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.voternum.errors.required "> Voter ID Number is required
                                </div>
                                <div style="color: red; " *ngIf="k.voternum.errors.pattern ">enter valid Voter ID
                                </div>

                            </div>

                            <!-- <div  style="color: red; " *ngIf="voterIdError" class="error-message">Invalid Voter ID</div> -->
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">PAN Number</label>
                                <input [ngClass]="{ 'is-invalid': submitted && k.pannumber.errors }" formControlName="pannumber" class="form-control " type="Date " id="inputbutton " [(ngModel)]="pannumber">
                                <div *ngIf="submitted && k.pannumber.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="k.pannumber.errors.required "> PAN Number is required
                                    </div>
                                    <div style="color: red; " *ngIf="k.pannumber.errors.pattern ">enter valid PAN Number
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">GST No</label>
                                <input [ngClass]="{ 'is-invalid': submitted && k.servicetax.errors }" formControlName="servicetax" class="form-control " type="text " id="inputbutton " [(ngModel)]="servicetax" >
                                <div *ngIf="submitted && k.servicetax.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.servicetax.errors.required ">Service Tax Number
                                    is required</div>
                                <div style="color: red; " *ngIf="k.servicetax.errors.pattern ">enter valid GST No
                                </div>

                            </div>

                            <!-- <div *ngIf="gstNumberError" class="error-message">Invalid GST Number</div> -->
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Passport Number</label>
                                <input [ngClass]="{ 'is-invalid': submitted && k.passportnum.errors }" formControlName="passportnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="passportnum"  >
                                <div *ngIf="submitted && k.passportnum.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.passportnum.errors.required "> Passport Number is
                                    required</div>
                                <div style="color: red; " *ngIf="k.passportnum.errors.pattern ">enter valid Passport
                                    Number </div>

                            </div>
                            <!-- <div *ngIf="passportNumberError" class="error-message">Invalid passport number</div> -->
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Life Insurance Value</label>
                                <input formControlName="lifeinsurance" class="form-control " type="input " id="inputbutton " [(ngModel)]="lifeinsurance">
                                <!-- <div *ngIf="submitted && k.lifeinsurance.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.lifeinsurance.errors.required ">Life Insurance
                                    Value is required</div>
                            </div> -->
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Confirm</label>
                                <input class="form-control" formControlName="IsConfirm" [(ngModel)]="IsConfirm" (change)="onSelectIsConfirm($event)" type="checkbox" id="inputbutton ">

                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <BR />

                        <BR />
                        <div *ngIf="bankdocsexist">
                            <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                         <div class="col-md-12 row">
                            <div class="col-md-1 row">&nbsp;</div>

                             <div class="col-md-2 row" *ngFor="let item of bankdocs">
                                 <img [src]="item.file" height="100" width="100"/>
                                 <br/>
                                 <p style="text-align: center;">{{item.documentName}}</p>
                             </div>
                         </div> 
                        </div>  
                        <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Upload Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="container" style="margin-top: 5%">
                            <div class="col-md-12 row">
                                <div class="col-md-3">&nbsp;</div>
                                <div class="col-md-9">
                                    <div class="table-responsive" style="overflow-x: visible">
                                        <table class="table table-striped table-responsive table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Sl No</th>
                                                    <th>Certificate Name</th>
                                                    <th>Upload Here</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Aadhar Card</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField (change)="onChangeAadhar($event)">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Voter ID</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField (change)="onChangeVoterId($event)">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Passport</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField (change)="onChangePassport($event)">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>GST Certificate</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField (change)="onChangeGSTCert($event)">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>IT Returns</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField (change)="onChangeITReturns($event)">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>Bank Statements</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField (change)="onChangeBankStatements($event)">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row">&nbsp;</div>
                    </form>
                    <BR />
                    <div class="col-md-12 row">
                        <div class="col-md-4 row">&nbsp;</div>
                        <div class="col-md-3 row">
                            <button (click)="SaveBankDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;

                        </div>
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-3 row">
                            <button (click)="bankIn(stepper1)" id="signinbutton">Next</button>
                        </div>

                    </div>                    <div class="col-md-12 row">&nbsp;</div>
                    <div class="col-md-12 row">&nbsp;</div>
                </div>
            </div>
        </div>
    </mat-step>


    <mat-step label="setep 3" state="homeinsurance" style="text-align: center; height: 96%; width: 136%; ">

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="eignin " align="center " style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Home Insurance Details</h2>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>


                    <form class="register-form" [formGroup]="homeinsuranceform">
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">DETAILS OF THE PREMISES TO BE INSURED
                        </h4>
                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address of property proposed for insurance</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.propaddress.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="propaddress" class="form-control " type="text " id="inputbutton " [(ngModel)]="propaddress">
                                <div *ngIf="submitted && e.propaddress.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.propaddress.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Year of Construction</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.yearofconstr.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="yearofconstr" class="form-control " type="number" id="inputbutton " [(ngModel)]="yearofconstr">
                                <div *ngIf="submitted && e.yearofconstr.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.yearofconstr.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Built up area </label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.builtuparea.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="builtuparea" class="form-control " type="text " id="inputbutton " [(ngModel)]="builtuparea">
                                <div *ngIf="submitted && e.builtuparea.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.builtuparea.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>


                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>

                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Class of Construction</label>
                                <select (change)="onSelectClassofConst($event.target.value)" class="form-control">
                                    <option value="">Select Class of Construction</option>
                                    <option value="Standard">Standard
                                    </option>
                                    <option value="Kutcha">Kutcha
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Sum Insured of Building</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.suminsuredbuildingval.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="suminsuredbuildingval" class="form-control " type="text " id="inputbutton " [(ngModel)]="suminsuredbuildingval">
                                <div *ngIf="submitted && e.suminsuredbuildingval.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.suminsuredbuildingval.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Name of Financier, if any</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.nameoffinancier.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="nameoffinancier" class="form-control " type="text " id="inputbutton " [(ngModel)]="nameoffinancier">
                                <div *ngIf="submitted && e.nameoffinancier.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.nameoffinancier.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Sum Insured of Contents</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.sumcontentinsured.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="sumcontentinsured" class="form-control " type="text " id="inputbutton " [(ngModel)]="sumcontentinsured">
                                <div *ngIf="submitted && e.sumcontentinsured.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.sumcontentinsured.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Distance from water front</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.distfromwater.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="distfromwater" class="form-control " type="text " id="inputbutton " [(ngModel)]="distfromwater">
                                <div *ngIf="submitted && e.distfromwater.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.distfromwater.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Location of property(Basement/Ground floor & above/
                                    others_____)</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.locationofprop.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="locationofprop" class="form-control " type="text " id="inputbutton " [(ngModel)]="locationofprop">
                                <div *ngIf="submitted && e.locationofprop.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.locationofprop.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>


                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Is property located is</h4>
                        <div class="col-md-12 row ">&nbsp;</div>


                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-2 row ">
                                <label id="emaillabel ">Low lying area &nbsp;</label>
                                <div class="col-md-12 row ">

                                    <mat-slide-toggle [(ngModel)]="lowlyingarea" formControlName="lowlyingarea">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Area prone to Natural Catastrophes?&nbsp;</label>
                                <div class="col-md-12 row ">

                                    <mat-slide-toggle [(ngModel)]="areaprone" formControlName="areaprone">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Flood prone area?&nbsp;</label>
                                <div class="col-md-12 row ">

                                    <mat-slide-toggle [(ngModel)]="floddprone" formControlName="floddprone">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Section 1- Building</h4>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Structure(Sum Insured)</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.buildingstructure.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="buildingstructure" class="form-control " type="number" id="inputbutton " [(ngModel)]="buildingstructure">
                                <div *ngIf="submitted && e.buildingstructure.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.buildingstructure.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Underground services(Sum Insured)</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.undergroundservices.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="undergroundservices" class="form-control " type="number" id="inputbutton " [(ngModel)]="undergroundservices" (change)="buildingsum()">
                                <div *ngIf="submitted && e.undergroundservices.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.undergroundservices.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Total(Sum Insured)</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.buildingtotal.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="buildingtotal" class="form-control " type="text " id="inputbutton " [(ngModel)]="buildingtotal" readonly>

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>


                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Do you want coverage for Tenant Fixtures?</label>

                                <mat-slide-toggle formControlName="coveragefortenant" [(ngModel)]="coveragefortenant">
                                </mat-slide-toggle>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Do you want to opt for lock & key coverage?</label>

                                <mat-slide-toggle formControlName="lockkeyopt" [(ngModel)]="lockkeyopt">
                                </mat-slide-toggle>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>

                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Do you want coverage for Fire extinguishing expenses?</label>

                                <mat-slide-toggle formControlName="coverageextinguish" [(ngModel)]="coverageextinguish">
                                </mat-slide-toggle>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Do you wish to opt for terrorism extension?</label>

                                <mat-slide-toggle formControlName="terrorismopt" [(ngModel)]="terrorismopt">
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Section 2- Home contents</h4>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Valueables proposed for insurance
                        </h5>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Description</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.Valueablesdesc.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="Valueablesdesc" class="form-control " type="text" id="inputbutton " [(ngModel)]="Valueablesdesc">
                                <div *ngIf="submitted && e.Valueablesdesc.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.Valueablesdesc.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Quantity</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.Valueablesquantity.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="Valueablesquantity" class="form-control " type="number" id="inputbutton " [(ngModel)]="Valueablesquantity" (change)="buildingsum()">
                                <div *ngIf="submitted && e.Valueablesquantity.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.Valueablesquantity.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Weight</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.Valueablesweight.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="Valueablesweight" class="form-control " type="text " id="inputbutton " [(ngModel)]="Valueablesweight">
                                <div *ngIf="submitted && e.Valueablesweight.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.Valueablesweight.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Sum Insured</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.Valueablessuminsured.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="Valueablessuminsured" class="form-control " type="number" id="inputbutton " [(ngModel)]="Valueablessuminsured">
                                <div *ngIf="submitted && e.Valueablessuminsured.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.Valueablessuminsured.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Appliances</h4>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Description</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.Appliancesdesc.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="Appliancesdesc" class="form-control " type="text" id="inputbutton " [(ngModel)]="Appliancesdesc">
                                <div *ngIf="submitted && e.Appliancesdesc.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.Appliancesdesc.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Make&Model</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.Appliancesmodel.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="Appliancesmodel" class="form-control " type="text" id="inputbutton " [(ngModel)]="Appliancesmodel" (change)="buildingsum()">
                                <div *ngIf="submitted && e.Appliancesmodel.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.Appliancesmodel.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Year of Mfg</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.Appliancesyear.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="Appliancesyear" class="form-control " type="number" id="inputbutton " [(ngModel)]="Appliancesyear">
                                <div *ngIf="submitted && e.Appliancesyear.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.Appliancesyear.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> ID no. </label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.AppliancesIDNo.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="AppliancesIDNo" class="form-control " type="number" id="inputbutton " [(ngModel)]="AppliancesIDNo">
                                <div *ngIf="submitted && e.AppliancesIDNo.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.AppliancesIDNo.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Sum Insured </label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.AppliancesSumInsured.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="AppliancesSumInsured" class="form-control " type="number" id="inputbutton " [(ngModel)]="AppliancesSumInsured">
                                <div *ngIf="submitted && e.AppliancesSumInsured.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.AppliancesSumInsured.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Fixed Plate Glass and Sanitary ware
                        </h4>
                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Location within premises</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.locationwithinpremises.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="locationwithinpremises" class="form-control " type="text " id="inputbutton " [(ngModel)]="locationwithinpremises">
                                <div *ngIf="submitted && e.locationwithinpremises.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.locationwithinpremises.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Dimensions</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.dimensions.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="dimensions" class="form-control " type="text " id="inputbutton " [(ngModel)]="dimensions">
                                <div *ngIf="submitted && e.dimensions.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.dimensions.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Sum Insured</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.fixedplatessum.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="fixedplatessum" class="form-control " type="text " id="inputbutton " [(ngModel)]="fixedplatessum">
                                <div *ngIf="submitted && e.fixedplatessum.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.fixedplatessum.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Additional Benefits</h4>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Do you want to cover Loss of Documents?</label>
                                <div class="col-md-12 row ">

                                    <mat-slide-toggle formControlName="coverlossofdocs" [(ngModel)]="coverlossofdocs">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Do you want to cover expenses for household removal?</label>
                                <div class="col-md-12 row ">

                                    <mat-slide-toggle formControlName="coverexpensesforhousehold" [(ngModel)]="coverexpensesforhousehold">
                                    </mat-slide-toggle>
                                </div>
                            </div>

                        </div>

                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Loss of rent</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.lossofrent.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="lossofrent" class="form-control " type="text " id="inputbutton " [(ngModel)]="lossofrent">
                                <div *ngIf="submitted && e.lossofrent.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.lossofrent.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Additional rent for alternate accomodation</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.additionalrent.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="additionalrent" class="form-control " type="text " id="inputbutton " [(ngModel)]="additionalrent">
                                <div *ngIf="submitted && e.additionalrent.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.additionalrent.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Pedigree Pet Insurance</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.pedigreeinsu.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="pedigreeinsu" class="form-control " type="text " id="inputbutton " [(ngModel)]="pedigreeinsu">
                                <div *ngIf="submitted && e.pedigreeinsu.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.pedigreeinsu.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Baggage Insurance</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.baggageinsu.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="baggageinsu" class="form-control " type="text " id="inputbutton " [(ngModel)]="baggageinsu">
                                <div *ngIf="submitted && e.baggageinsu.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.baggageinsu.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Domestic Servants</h4>
                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">No. of servants </label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.noofservents.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="noofservents" class="form-control " type="number" id="inputbutton " [(ngModel)]="noofservents">
                                <div *ngIf="submitted && e.noofservents.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.noofservents.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> nature of work/ duties </label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.natureofwork.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="natureofwork" class="form-control " type="text " id="inputbutton " [(ngModel)]="natureofwork">
                                <div *ngIf="submitted && e.natureofwork.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.natureofwork.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Estimated wages</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.estimatedwages.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="estimatedwages" class="form-control " type="text " id="inputbutton " [(ngModel)]="estimatedwages">
                                <div *ngIf="submitted && e.estimatedwages.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.estimatedwages.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of expirying Policy</h4>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-6 row ">
                                <label for="Email " id="emaillabel ">Name & address of insurance company </label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.addressofinsurancecom.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="addressofinsurancecom" class="form-control " type="text " id="inputbutton " [(ngModel)]="addressofinsurancecom">
                                <div *ngIf="submitted && e.addressofinsurancecom.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.addressofinsurancecom.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>


                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Security Arrangements</h4>
                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>

                            <div class="col-md-4 row ">
                                <label id="emaillabel ">In case of independent house 24 hours dedicated watchman</label>

                                <mat-slide-toggle formControlName="independenthouse" [(ngModel)]="independenthouse">
                                </mat-slide-toggle>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                            <div class="col-md-4 row ">
                                <label id="emaillabel ">In case of apartment Are all entry/exit gates manned around the
                                    clock</label>

                                <mat-slide-toggle formControlName="apartment" [(ngModel)]="apartment">
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>

                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Are the flats connected with each other/Security office with
                                    interom</label>

                                <mat-slide-toggle formControlName="flatsconnected" [(ngModel)]="flatsconnected">
                                </mat-slide-toggle>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>


                        </div>

                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">General</h4>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Item already insured</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.Itemalreadyinsured.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="Itemalreadyinsured" class="form-control " type="text " id="inputbutton " [(ngModel)]="Itemalreadyinsured">
                                <div *ngIf="submitted && e.Itemalreadyinsured.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.Itemalreadyinsured.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Policy number</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.Policynumber.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="Policynumber" class="form-control " type="text " id="inputbutton " [(ngModel)]="Policynumber">
                                <div *ngIf="submitted && e.Policynumber.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.Policynumber.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Name of the Insurance Company</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.insurancecompname.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="insurancecompname" class="form-control " type="text " id="inputbutton " [(ngModel)]="insurancecompname">
                                <div *ngIf="submitted && e.insurancecompname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.insurancecompname.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Period of insurance</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.Periodofinsurance.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="Periodofinsurance" class="form-control " type="number" id="inputbutton " [(ngModel)]="Periodofinsurance">
                                <div *ngIf="submitted && e.Periodofinsurance.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.Periodofinsurance.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Source of fund</h4>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Salary/Business/Other, Specify</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.sourceoffund.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="sourceoffund" class="form-control " type="text " id="inputbutton " [(ngModel)]="sourceoffund">
                                <div *ngIf="submitted && e.sourceoffund.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.sourceoffund.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>

                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Payment Details</h4>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Cheque/DD/PO/Cash/Other________</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.paymenttype.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="paymenttype" class="form-control " type="text " id="inputbutton " [(ngModel)]="paymenttype">
                                <div *ngIf="submitted && e.paymenttype.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.paymenttype.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Cheque/DD/PO no.</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.chequeno.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="chequeno" class="form-control " type="text " id="inputbutton " [(ngModel)]="chequeno">
                                <div *ngIf="submitted && e.chequeno.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.chequeno.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Dated</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.paymentdated.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="paymentdated" class="form-control " type="Date" id="inputbutton " [(ngModel)]="paymentdated">
                                <div *ngIf="submitted && e.paymentdated.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.paymentdated.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Bank Name</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.BankName.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="BankName" class="form-control " type="text " id="inputbutton " [(ngModel)]="BankName">
                                <div *ngIf="submitted && e.BankName.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.BankName.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Amount</label>
                                <span style="display: flex;">Rs.&nbsp;&nbsp;&nbsp;&nbsp;

                                    <input [ngClass]="{ 'is-invalid': submitted && e.paymentamount.errors }"
                                        (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="paymentamount"
                                        class="form-control " type="text " id="inputbutton " [(ngModel)]="paymentamount"
                                        style=" margin-top: -2%;"></span>
                                <div *ngIf="submitted && e.paymentamount.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.paymentamount.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Amount in words</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.paymentamtinwords.errors }" (keyup.enter)="SaveHomeInsurance(stepper) " formControlName="paymentamtinwords" class="form-control " type="Date " id="inputbutton " [(ngModel)]="paymentamtinwords">
                                <div *ngIf="submitted && e.paymentamtinwords.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.paymentamtinwords.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row" *ngIf="role!='1008'">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-1 row ">

                                <input  class="form-control" type="checkbox" [checked]="SendCopytoSP" formControlName="SendCopytoSP" [(ngModel)]="SendCopytoSP" style="font-size: 7px;">
                            </div>
                            <div class="col-md-5 row ">
                                <p style="font-size: 20px;color:darkblue">Send one copy to {{serviceprovidername}}</p>

                            </div>
                        </div>

                    </form>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row">
                        <div class="col-md-4 row">&nbsp;</div>
                        <div class="col-md-3 row">
                            <button (click)="SaveHomeInsuranceDraft()" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;

                        </div>
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-3 row">
                            <button (click)="SaveHomeInsurance(stepper)" id="signinbutton">Next</button>
                        </div>

                    </div>

                    <BR />
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                </div>
            </div>
        </div>
    </mat-step>

    <mat-step label="Step 4" state="upload" style="text-align: center; height: 96%; width: 136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center "
                    style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                    <div *ngIf="certificatesexist">
                        <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="col-md-12 row">
                            <!-- <div class="col-md-1 row">&nbsp;</div> -->

                            <div class="col-md-4 row" *ngFor="let item of CertificateDisplay">
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'image/jpeg' ||
                             item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                    <img [src]="item.certificate" height="100" width="100" />
                                    <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/pdf' ||
                             item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                    <img src="assets/img/pdficon.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                        <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                             item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                    <img src="assets/img/xls.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                        <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                             item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                    <img src="assets/img/docx.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                        <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                </div>


                            </div>
                        </div>
                        <div *ngIf="documentArray.length!=0">
                            <div class="col-md-12 row">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Uploaded Documents</h4>

                            <div class="col-md-12 row">
                                <!-- <div class="col-md-1 row">&nbsp;</div> -->

                                <div class="col-md-4 row" *ngFor="let item of certificates">
                                    <div *ngIf="item.actualCertificateName!=null  && (item.documentType == 'image/jpeg' ||
                             item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                        <img [src]="item.certificate" height="100" width="100" />
                                        <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                        <!-- <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/pdf' ||
                             item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                        <img src="assets/img/pdficon.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                            <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                        <!-- <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                             item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/xls.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                            <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                        <!-- <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                             item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/docx.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                            <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                        <!-- <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Upload Certificates</h2>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row">
                        <div class="col-md-3">&nbsp;</div>
                        <div class="col-md-9">
                            <div class="table-responsive" style="overflow-x: visible">

                                <table class="table table-striped table-responsive table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Certificate Name</th>
                                            <th>Upload Here</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let field of CertificateList; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{field.certificateName}}</td>
                                            <td>
                                                <input type="file" name="avatars" #fileField
                                                    (change)="onChangeCertificates($event,field)">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Documents</h2>
                                <table class="table table-striped table-responsive table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Document Name</th>
                                            <th>Document</th>

                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let field of documentArray; let i = index">

                                            <td>
                                                <input [(ngModel)]="field.otherdocumentname" class="form-control"
                                                    type="text" name="{{field.otherdocumentname}}" />
                                            </td>
                                            <td>
                                                <input [(ngModel)]="field.otherdocactualname" class="form-control"
                                                    type="text" name="{{field.otherdocactualname}}" />
                                            </td>

                                            <td>
                                                <button class="btn btn-default" type="button"
                                                    (click)="deleteFieldValueDocument(i)">Delete</button>
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>
                                                <input class="form-control" type="text" id="otherdocumentname"
                                                    [(ngModel)]="newDocAttribute.otherdocumentname"
                                                    name="otherdocumentname" />

                                            </td>
                                            <td>
                                                <input class="form-control" type="file" id="otherdocument"
                                                    (change)="OnChangeOtherDocs($event)" name="otherdocument" />
                                            </td>


                                            <td>
                                                <button class="btn btn-default" type="button"
                                                    (click)="addDocFieldValue()">Add</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    
               
               
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row" *ngIf="role!='1008' && ticketid!=null">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-1 row ">

                        <input  class="form-control" type="checkbox" [checked]="SendCopytoSP"
                            [(ngModel)]="SendCopytoSP" style="font-size: 7px;">
                    </div>
                    <div class="col-md-5 row ">
                        <p style="font-size: 20px;color:darkblue">Send one copy to {{serviceprovidername}}</p>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <!-- <div class="col-md-12 row">
                    <div class="col-md-3 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <button (click)="uploadCertificatesDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button (click)="uploadCertificates(stepper1)" id="signinbutton">Upload</button>
                    </div>
                    <div class="col-md-1 row">
                    </div>
                 
                </div>
                <div class="col-md-12 row" *ngIf="!enablepreview && ProspectIdtoDisplydata==null" >
                    <div class="col-md-3 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        
                        <button  id="signinbuttonpreview" >Preview</button>

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button id="signinbuttonpreview" >Submit</button>
                    </div>
                    <div class="col-md-1 row">
                    </div>
                  
                </div>
                <div class="col-md-12 row" *ngIf="enablepreview && ProspectIdtoDisplydata==null">
                    <div class="col-md-3 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <button (click)="previewinsurancedetails()"  id="signinbutton">Preview</button>

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button  (click)="submitloanform()" id="signinbutton">Submit</button>
                    </div>
                    <div class="col-md-1 row">
                    </div>
                    
                </div> -->
                
                
                <div class="col-md-12 row">
                    <div class="col-md-3 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <button (click)="uploadCertificatesDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button (click)="uploadCertificates(stepper1)" id="signinbutton">Upload</button>
                    </div>
                    <div class="col-md-1 row">
                    </div>
                 
                </div>
            
                <div class="col-md-12 row" *ngIf="ProspectIdtoDisplydata==null">
                    <div class="col-md-3 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <button (click)="previewinsurancedetails()"  id="signinbutton">Preview</button>

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button  (click)="submitInsuranceform()" id="signinbutton">Submit</button>
                    </div>
                    <div class="col-md-1 row">
                    </div>
                    
                </div>
                
                
                
                
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>

                </div>
            </div>
        </div>
    </mat-step>





    <!-- Icon overrides. -->
    <!-- #docregion override-icons -->
    <ng-template matStepperIcon="details">
        <mat-icon>account_circle</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="family">
        <mat-icon>family_restroom</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="account">
        <mat-icon>account_balance</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="homeinsurance">
        <mat-icon>home</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="business">
        <mat-icon>business</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="upload">
        <mat-icon>cloud_upload</mat-icon>
    </ng-template>

</mat-horizontal-stepper>
<br /><br />
<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 500px;width: 350px;">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <i class="material-icons" style="color: white">close</i>
            </button>
            <span style="color: white;margin-top: 6px;">
                <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
        </div>
    </div>
</div>