<app-header>

</app-header>
<div>&nbsp;</div>
<div>&nbsp;</div>
<mat-horizontal-stepper #stepper>
    <!-- <mat-step>
        <div>
            <button mat-button matStepperPrevious></button>
            <button mat-button (click)="stepper.reset()"></button>
        </div>
    </mat-step> -->
</mat-horizontal-stepper>

<!-- #docregion states -->
<mat-horizontal-stepper style="background-color: #c2b6b6;
background-image: linear-gradient(315deg, #c2b6b6 0%, #576574 74%);" #stepper1>

<mat-step label="Step 1" state="details" style="text-align: center;    height: 96%;width: 136%;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
        <div class="col-md-1"></div>
        <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10">

            <div class="homecard" id="signin" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%">
                <h1 style="font-family: DINNextRoundedLTPro;color: #2b156f;font-size: 34px;">Project Loan Form</h1>

                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Personal Details</h2>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <form id="msform" [formGroup]="RegistrationForm">
                    <div class="col-md-12 row">
                        <div class="col-md-4">&nbsp;</div>
                        <div class="col-md-4">
                            <div class="form-card">
                                <div class="container fileUploadWrapper">
                                    <div class="col-md-12">
                                        <img src="{{userImage}}" class="img-circle" style="height: 150px;width: 150px;border: solid 2px;
                            border-radius: 7px;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12">&nbsp;</div>

                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12 row">
                        <div class="col-md-2 row">&nbsp;</div>
                        <div class="col-md-3 row">
                            <label id="emaillabel">Customer Id</label>
                            <input (keyup.enter)="signIn(stepper1)" (change)="getCustomerImage(customerid)" formControlName="customerid" class="form-control" type="text" id="inputbutton" [(ngModel)]="customerid">
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12 row">
                        <div class="col-md-2 row">&nbsp;</div>
                        <div class="col-md-3 row">

                            <label id="emaillabel">First Name</label>

                            <input (keyup.enter)="signIn(stepper1)" formControlName="firstname" class="form-control" type="text" id="inputbutton" [(ngModel)]="firstname" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                            <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.firstname.errors.required ">First Name is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Middle Name</label>
                            <input (keyup.enter)="signIn(stepper1) " formControlName="middlename" class="form-control " type="text " id="inputbutton " [(ngModel)]="middlename ">
                            <!-- <div *ngIf="submitted && f.middlename.errors" class="invalid-feedback">
                        <div style="color: red; " *ngIf="f.middlename.errors.required ">Middle Name is required
                        </div>
                    </div> -->
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Last Name</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" (keyup.enter)="signIn(stepper1) " formControlName="lastname" class="form-control " type="text " id="inputbutton " [(ngModel)]="lastname ">
                            <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.lastname.errors.required ">Last Name is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Place of Birth</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.placeofbirth.errors }" (keyup.enter)="signIn(stepper1) " formControlName="placeofbirth" class="form-control " type="text " id="inputbutton " [(ngModel)]="placeofbirth ">
                            <div *ngIf="submitted && f.placeofbirth.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.placeofbirth.errors.required ">Place is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Gender</label>
                            <select (change)="onSelectgender($event.target.value)" class="form-control">
                    <option value="">Select Gender</option>
                    <option *ngFor="let do of genders" value={{do.genderId}}
                        [selected]="do.genderId==genderid">
                        {{do.genderName}}
                    </option>
                </select>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>

                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Caste</label>
                            <select (change)="onSelectCaste($event.target.value)" class="form-control">
                    <option value="">Select Caste</option>
                    <option *ngFor="let do of castes" value={{do.raceId}}
                        [selected]="do.raceId==casteid">
                        {{do.raceName}}
                    </option>
                </select>
                        </div>
                        <div class="col-md-2 row " *ngIf="othercastedetails">&nbsp;</div>

                        <div class="col-md-3 row " *ngIf="othercastedetails">
                            <label for="Email " id="emaillabel ">Caste</label>
                            <input (keyup.enter)="details(stepper1) " formControlName="castedetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="castedetail">
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel "> Mobile Number</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.Number.errors }" (keyup.enter)="signIn(stepper1) " formControlName="Number" class="form-control" type="text" id="inputbutton " [(ngModel)]="Number ">
                            <div *ngIf="submitted && f.Number.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.Number.errors.required ">Mobile Number is required
                                </div>
                            </div>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Date of Birth</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.dateofbirth.errors }" (keyup.enter)="signIn(stepper1) " formControlName="dateofbirth" class="form-control" type="date" id="inputbutton " [(ngModel)]="dateofbirth ">
                            <div *ngIf="submitted && f.dateofbirth.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.dateofbirth.errors.required ">Date of Birth is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Religion</label>
                            <select (change)="onSelectrelogion($event.target.value)" class="form-control">
                    <option value="">Select Religion</option>
                    <option *ngFor="let do of religions" value={{do.religionId}}
                        [selected]="do.religionId==rid">
                        {{do.religionName}}
                    </option>
                </select> </div>
                        <div class="col-md-2 row " *ngIf="othereligiondetails">&nbsp;</div>
                        <div class="col-md-3 row " *ngIf="othereligiondetails">
                            <label for="Email " id="emaillabel ">Religion</label>
                            <input (keyup.enter)="details(stepper1) " formControlName="religiondetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="religiondetail">
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Marital Status</label>
                            <select (change)="onSelectmarital($event.target.value)" class="form-control">
                    <option value="">Select Marital Status</option>
                    <option *ngFor="let do of status" value={{do.maritalStatusId}}
                        [selected]="do.maritalStatusId==martid">
                        {{do.maritalStatus}}
                    </option>
                </select> </div>



                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Qualification</label>
                            <select (change)="onSelectqualification($event.target.value)" class="form-control">
                    <option value="">Select Qualification</option>
                    <option *ngFor="let do of qualifications" value={{do.qualificationId}}
                        [selected]="do.qualificationId==quaid">
                        {{do.qualificationName}}
                    </option>
                </select>

                        </div>
                        <div class="col-md-1 row " *ngIf="otherequalification ">&nbsp;</div>
                        <!-- <div class="col-md-1 row " *ngIf="otherequalification">&nbsp;</div> -->

                        <div class="col-md-3 row " *ngIf="otherequalification">
                            <label for="Email " id="emaillabel ">Qualification</label>
                            <input (keyup.enter)="details(stepper1) " formControlName="qualificationdetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="qualificationdetail">
                        </div>
                        <div class="col-md-1 row " *ngIf="otherequalification" style="  margin-left: 1%;">&nbsp;</div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Occupation</label>
                            <select (change)="onSelectoccupation($event.target.value)" class="form-control">
                    <option value="">Select Occupation</option>
                    <option *ngFor="let do of occupations" value={{do.occupationId}}
                        [selected]="do.occupationId==occuid">
                        {{do.occupationName}}
                    </option>
                </select>

                        </div>

                        <div class="col-md-2 row " *ngIf="othersoccupation">&nbsp;</div>

                        <div class="col-md-3 row " *ngIf="othersoccupation">
                            <label for="Email " id="emaillabel ">Occupation</label>
                            <input (keyup.enter)="details(stepper1) " formControlName="occupatoiondetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="occupatoiondetail">
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel "> Email</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (keyup.enter)="signIn(stepper1) " formControlName="email" class="form-control " type="text " id="inputbutton " [(ngModel)]="email ">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.email.errors.required ">Email is required</div>
                            </div>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Designation</label>
                            <select (change)="onSelectdesignation($event.target.value)" class="form-control">
                    <option value="">Select Designations</option>
                    <option *ngFor="let do of designations" value={{do.id}} [selected]="do.id==desid">
                        {{do.designationName}}
                    </option>
                </select>
                        </div>
                        <div class="col-md-3 row " *ngIf="otherdesignation" style="margin-left: 5%;">

                            <label for="Email " id="emaillabel ">Designation</label>
                            <input (keyup.enter)="details(stepper1) " formControlName="designationd" class="form-control " type="text " id="inputbutton " [(ngModel)]="designationd">
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h4>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Postal Code</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" (keyup.enter)="signIn(stepper1) " formControlName="pincode" class="form-control " type="number " id="inputbutton " [(ngModel)]="pincode" (change)="getalldata()">
                            <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.pincode.errors.required ">Postal is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Country</label>
                            <input (keyup.enter)="signIn(stepper1) " formControlName="country" class="form-control " type="text " id="inputbutton " [(ngModel)]="country" readonly>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> State</label>
                            <input (keyup.enter)="signIn(stepper1) " formControlName="state" class="form-control " type="text " id="inputbutton " [(ngModel)]="state" readonly>
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">District</label>
                            <input (keyup.enter)="signIn(stepper1) " formControlName="district" class="form-control " type="text " id="inputbutton " [(ngModel)]="district" readonly>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Address one</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.city.errors }" (keyup.enter)="signIn(stepper1) " formControlName="city" class="form-control " type="text " id="inputbutton " [(ngModel)]="city">
                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.city.errors.required ">City Name is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address two</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.street.errors }" (keyup.enter)="signIn(stepper1) " formControlName="street" class="form-control " type="text " id="inputbutton " [(ngModel)]="street">
                            <div *ngIf="submitted && f.street.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.street.errors.required ">Street Name is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-7 row ">
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Permanent Address is Same as Residential Address</h4>
                        </div>
                        <div class="col-md-1 row ">
                            <input class="form-control" formControlName="PermanentSameasResidential" [checked]="PermanentSameasResidential" [(ngModel)]="PermanentSameasResidential" (change)="onSelectaddress($event)" type="checkbox" id="inputbutton ">
                        </div>
                        <div class="col-md-2 row ">&nbsp;</div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Postal Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" (keyup.enter)="signIn(stepper1) " formControlName="postalcode" class="form-control " type="number " id="inputbutton " (change)="getpincoddata()" [(ngModel)]="postalcode">
                                <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.postalcode.errors.required ">Postal is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Country</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="countryone" class="form-control " type="text " id="inputbutton " [(ngModel)]="countryone" readonly>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> State</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="stateone" class="form-control " type="text " id="inputbutton " [(ngModel)]="stateone" readonly>
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">District</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="districtone" class="form-control " type="text " id="inputbutton " [(ngModel)]="districtone" readonly>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address one</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.adressone.errors }" (keyup.enter)="signIn(stepper1) " formControlName="adressone" class="form-control " type="text " id="inputbutton " [(ngModel)]="adressone">
                                <div *ngIf="submitted && f.adressone.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.adressone.errors.required ">Address one is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address two</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.adresstwo.errors }" (keyup.enter)="signIn(stepper1) " formControlName="adresstwo" class="form-control " type="text " id="inputbutton " [(ngModel)]="adresstwo">
                                <div *ngIf="submitted && f.adresstwo.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.adresstwo.errors.required ">Address two is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <BR /> &nbsp;
                <div class="col-md-12 row">
                    <div class="col-md-4 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <button (click)="SaveBasicDraft(stepper1)"  id="signinbutton">Draft</button>

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-3 row">
                        <button (click)="signIn(stepper1)" id="signinbutton">Next</button>
                    </div>

                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
            </div>
        </div>
    </div>
</mat-step>
<mat-step label="Step 2 " state="family" style="text-align: center; height: 96%; width: 136%; ">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
        <div class="col-md-1"></div>
        <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
            <div class="homecard " id="signin " align="center " style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Family Details</h2>

                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>

                <form class="register-form " [formGroup]="DetailsForm">
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Family Type</label>
                            <select (change)="onSelectfamily($event.target.value)" class="form-control">
                                <option value="">Select Family Type</option>
                                <option *ngFor="let do of families" value={{do.familyTypeId}}>
                                    {{do.familyType}}
                                </option>
                            </select> 
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Number of Dependents</label>
                            <input [ngClass]="{ 'is-invalid': submitted && d.numberofdep.errors }" (keyup.enter)="details(stepper)" formControlName="numberofdep" class="form-control " type="number" id="inputbutton " [(ngModel)]="numberofdep">
                            <div *ngIf="submitted && d.numberofdep.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.numberofdep.errors.required "> Number of Dependents is required</div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Number of Children</label>
                            <input [ngClass]="{ 'is-invalid': submitted && d.numofchild.errors }" (keyup.enter)="details(stepper)" formControlName="numofchild" class="form-control " type="number" id="inputbutton " [(ngModel)]="numofchild">
                            <div *ngIf="submitted && d.numofchild.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.numofchild.errors.required ">Number of Children is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Annual Income  </label>
                            <span style="display: flex;">Rs.&nbsp;&nbsp;&nbsp;&nbsp;
                            <input [ngClass]="{ 'is-invalid': submitted && d.Anualamt.errors }" (keyup.enter)="details(stepper)" formControlName="Anualamt" class="form-control " type="number" id="inputbutton " [(ngModel)]="Anualamt" style=" margin-top: -2%;"></span>
                            <div *ngIf="submitted && d.Anualamt.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.Anualamt.errors.required ">Anual Income is required
                                </div>
                            </div>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Income from Other sources </label>
                            <span style="display: flex;">Rs.&nbsp;&nbsp;&nbsp;&nbsp;
                            <input [ngClass]="{ 'is-invalid': submitted && d.otheramt.errors }" (keyup.enter)="details(stepper)" formControlName="otheramt" class="form-control " type="number" id="inputbutton " [(ngModel)]="otheramt" style=" margin-top: -2%;" (change)="GetTotalAmout()"></span>
                            <div *ngIf="submitted && d.otheramt.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.otheramt.errors.required "> Source Amount is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Total Annual Income</label>
                            <span style="display: flex;">Rs.&nbsp;&nbsp;&nbsp;&nbsp;
                            <input [ngClass]="{ 'is-invalid': submitted && d.totalamt.errors }" (keyup.enter)="details(stepper) " formControlName="totalamt" class="form-control " type="number" id="inputbutton " style=" margin-top: -2%;" [(ngModel)]="totalamt" readonly></span>
                            <div *ngIf="submitted && d.totalamt.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.totalamt.errors.required "> Total Annual Income is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Nominee Date of Birth</label>
                            <input [ngClass]="{ 'is-invalid': submitted && d.Nagedob.errors }" (change)="ageCalculator()" (keyup.enter)="details(stepper) " formControlName="Nagedob" class="form-control " type="Date" id="inputbutton " [(ngModel)]="Nagedob">
                            <div *ngIf="submitted && d.Nagedob.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.Nagedob.errors.required "> Date of Birth is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="Nomineetype == 'major'">
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Major Nominee</h3>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Family Type</label>
                                <select (change)="onSelectfamily($event.target.value)" class="form-control">
                                    <option value="">Select Family Type</option>
                                    <option *ngFor="let do of families" value={{do.familyTypeId}}
                                    [selected]="do.familyTypeId == fid">
                                        {{do.familyType}}
                                    </option>
                                </select> </div>
                                <label id="emaillabel ">Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.nominnename.errors }" (keyup.enter)="details(stepper) " formControlName="nominnename" class="form-control " type="text " id="inputbutton " [(ngModel)]="nominnename">
                                <div *ngIf="submitted && d.nominnename.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.nominnename.errors.required "> Nominee Name is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Middle Name</label>
                                <input (keyup.enter)="details(stepper1) " formControlName="nominnemiddlename" class="form-control " type="text " id="inputbutton " [(ngModel)]="nominnemiddlename">
                                <!-- <div *ngIf="submitted && d.nominnemiddlename.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.nominnemiddlename.errors.required "> Nominee Name is
                                    required
                                </div>
                            </div> -->
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Last Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.nominnelastname.errors }" (keyup.enter)="details(stepper1) " formControlName="nominnelastname" class="form-control " type="text " id="inputbutton " [(ngModel)]="nominnelastname">
                                <div *ngIf="submitted && d.nominnelastname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.nominnelastname.errors.required "> Nominee Last Name is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <!-- <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Relationship</label>
                            <input [ngClass]="{ 'is-invalid': submitted && d.relationship.errors }"
                                (keyup.enter)="details(stepper) " formControlName="relationship"
                                class="form-control " type="text " id="inputbutton "
                                
                                [(ngModel)]="relationship">
                            <div *ngIf="submitted && d.relationship.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.relationship.errors.required "> Relationship Name
                                    is required</div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Name on Passbook</label>
                            <input [ngClass]="{ 'is-invalid': submitted && d.passbook.errors }"
                                (keyup.enter)="details(stepper) " formControlName="passbook" class="form-control "
                                type="text " id="inputbutton "
                                
                                [(ngModel)]="passbook ">
                            <div *ngIf="submitted && d.passbook.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.passbook.errors.required ">Name on Passbook is required
                                </div>
                            </div>
                        </div> -->
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Name on Passbook</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.passbook.errors }" (keyup.enter)="details(stepper1) " formControlName="passbook" class="form-control " type="text " id="inputbutton " [(ngModel)]="passbook ">
                                <div *ngIf="submitted && d.passbook.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.passbook.errors.required ">Name on Passbook is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Relationship</label>
                                <select (change)="onSelectRelationType($event.target.value)" class="form-control">
                                    <option value="">Select Relation Ship Type</option>
                                    <option *ngFor="let do of RelationShipTyes" value={{do.id}} [selected]="do.id==relationshiptypeid">
                                        {{do.relationShip}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row " *ngIf="othersinrelationshiptypes">
                                <label for="Email " id="emaillabel ">Others</label>
                                <input (keyup.enter)="details(stepper1) " formControlName="relationship" class="form-control " type="text " id="inputbutton " [(ngModel)]="relationship">

                                <!-- <div *ngIf="submitted && d.relationship.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.relationship.errors.required "> Field is
                                        required</div>
                                </div> -->
                            </div>
                        </div>

                        <div class="col-md-12 row ">
                            <!-- <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Date of Birth</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.Ndob.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="Ndob" class="form-control "
                                    type="Date" id="inputbutton " [(ngModel)]="Ndob">
                                <div *ngIf="submitted && d.Ndob.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.Ndob.errors.required "> Date of Birth is
                                        required
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Postal Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.Npincode.errors }" (keyup.enter)="details(stepper) " formControlName="Npincode" class="form-control " type="number " id="inputbutton " [(ngModel)]="Npincode" (change)="getallpincoddata(Npincode)">
                                <div *ngIf="submitted && d.Npincode.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.Npincode.errors.required ">Postal Code is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Country</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.ncountry.errors }" (keyup.enter)="details(stepper) " formControlName="ncountry" class="form-control " type="text " id="inputbutton " [(ngModel)]="ncountry" readonly>
                                <div *ngIf="submitted && d.ncountry.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.ncountry.errors.required "> Country is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">State</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.Nstate.errors }" (keyup.enter)="details(stepper) " formControlName="Nstate" class="form-control " type="input " id="inputbutton " [(ngModel)]="Nstate" readonly>
                                <div *ngIf="submitted && d.Nstate.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.Nstate.errors.required "> state is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">District</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.Ndistrict.errors }" (keyup.enter)="details(stepper) " formControlName="Ndistrict" class="form-control " type="text " id="inputbutton " [(ngModel)]="Ndistrict" readonly>
                                <div *ngIf="submitted && d.Ndistrict.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.Ndistrict.errors.required "> Address is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Address Line One</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.Naddress.errors }" (keyup.enter)="details(stepper) " formControlName="Naddress" class="form-control " type="text " id="inputbutton " [(ngModel)]="Naddress">
                                <div *ngIf="submitted && d.Naddress.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.Naddress.errors.required "> Address Line One is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line Two</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.Naddress2.errors }" (keyup.enter)="details(stepper) " formControlName="Naddress2" class="form-control " type="input " id="inputbutton " [(ngModel)]="Naddress2">
                                <div *ngIf="submitted && d.Naddress2.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.Naddress2.errors.required "> state is required
                                    </div>
                                </div>
                            </div>


                        </div>

                    <!-- </div> -->


                    <div class="col-md-12 row ">&nbsp;</div>

                    <div *ngIf="Nomineetype == 'minor'">
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Minor Nominee</h3>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">First Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minorname.errors }" (keyup.enter)="details(stepper) " formControlName="minorname" class="form-control " type="text " id="inputbutton " [(ngModel)]="minorname">
                                <div *ngIf="submitted && d.minorname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.minorname.errors.required "> Minor Nominee name is required</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Middle Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minormiddname.errors }" (keyup.enter)="details(stepper) " formControlName="minormiddname" class="form-control " type="text " id="inputbutton " [(ngModel)]="minormiddname">
                                <div *ngIf="submitted && d.minormiddname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.minormiddname.errors.required "> Minor Nominee name is required</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Last Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minorlastname.errors }" (keyup.enter)="details(stepper) " formControlName="minorlastname" class="form-control " type="text " id="inputbutton " [(ngModel)]="minorlastname">
                                <div *ngIf="submitted && d.minorlastname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.minorlastname.errors.required "> Minor Nominee name is required</div>
                                </div>
                            </div>
                            <!-- <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Date of Birth</label>
                            <input [ngClass]="{ 'is-invalid': submitted && d.minordob.errors }"
                                (keyup.enter)="details(stepper) " formControlName="minordob" class="form-control "
                                type="Date" id="inputbutton "
                                
                                [(ngModel)]="minordob">
                            <div *ngIf="submitted && d.minordob.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.minordob.errors.required "> Date of Birth is
                                    required</div>
                            </div>
                        </div> -->


                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Address Line One</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minoraddress.errors }" (keyup.enter)="details(stepper) " formControlName="minoraddress" class="form-control " type="text " id="inputbutton " [(ngModel)]="minoraddress">
                                <div *ngIf="submitted && d.minoraddress.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.minoraddress.errors.required ">Address Line One is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line Two</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minoraddress1.errors }" (keyup.enter)="details(stepper) " formControlName="minoraddress1" class="form-control " type="text " id="inputbutton " [(ngModel)]="minoraddress1">
                                <div *ngIf="submitted && d.minoraddress1.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.minoraddress1.errors.required "> Address Line Two is required</div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Postal Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minorpincode.errors }" (keyup.enter)="details(stepper) " formControlName="minorpincode" class="form-control " type="number " id="inputbutton " [(ngModel)]="minorpincode" (change)="getallpincoddataforminor(minorpincode)">
                                <div *ngIf="submitted && d.minorpincode.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.minorpincode.errors.required ">Postal Code is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Country</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minorcountry.errors }" (keyup.enter)="details(stepper) " formControlName="minorcountry" class="form-control " type="text " id="inputbutton " [(ngModel)]="minorcountry" readonly>

                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> State</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minorstate.errors }" (keyup.enter)="details(stepper) " formControlName="minorstate" class="form-control " type="text " id="inputbutton " [(ngModel)]="minorstate" readonly>

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">District</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minordistrict.errors }" (keyup.enter)="details(stepper) " formControlName="minordistrict" class="form-control " type="text " id="inputbutton " [(ngModel)]="minordistrict" readonly>

                            </div>
                        </div>
                        <br><br>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Guardian Details</h3><br>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">

                                <label id="emaillabel ">Guardian Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.guardianname.errors }" (keyup.enter)="details(stepper) " formControlName="guardianname" class="form-control " type="text " id="inputbutton " [(ngModel)]="guardianname">
                                <div *ngIf="submitted && d.guardianname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.guardianname.errors.required ">Field is required</div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Relationship</label>
                                <select (change)="onSelectMinorRelationType($event.target.value)" class="form-control">
                                    <option value="">Select Relation Ship Type</option>
                                    <option *ngFor="let do of RelationShipTyes" value={{do.id}} [selected]="do.id==guardioanrelationshipwithminorid">
                                        {{do.relationShip}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row " *ngIf="minorrelationshipforguardian">
                                <label for="Email " id="emaillabel ">Others</label>
                                <input (keyup.enter)="details(stepper1) " formControlName="guardioanrelationshipwithminor" class="form-control " type="text " id="inputbutton " [(ngModel)]="guardioanrelationshipwithminor">

                                <!-- <div *ngIf="submitted && d.guardioanrelationshipwithminor.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.guardioanrelationshipwithminor.errors.required "> Field is
                                        required</div>
                                </div> -->
                            </div>
                        </div>
                        <br>
                        <BR />
                        <div *ngIf="familydocsexist">
                            <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                         <div class="col-md-12 row">
                            <div class="col-md-1 row">&nbsp;</div>

                             <div class="col-md-2 row" *ngFor="let item of familydocs">
                                 <img [src]="item.file" height="100" width="100"/>
                                 <br/>
                                 <p style="text-align: center;">{{item.documentName}}</p>
                             </div>
                         </div> 
                        </div> 
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Upload Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="container" style="margin-top: 5%">
                            <div class="col-md-12 row">
                                <div class="col-md-3">&nbsp;</div>
                                <div class="col-md-9">
                                    <div class="table-responsive" style="overflow-x: visible">
                                        <table class="table table-striped table-responsive table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Sl No</th>
                                                    <th>Certificate Name</th>
                                                    <th>Upload Here</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Proof of Address</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField
                                                            (change)="onChangeguardianproofofaddress($event)">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Upload Pan Card</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField (change)="onChangeGuardianpancard($event)">
                                                    </td>
                                                </tr>
                        
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                </form>
                <br>
                <div class="col-md-12 row">
                    <div class="col-md-4 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <button (click)="SaveFamilyDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-3 row">
                        <button (click)="details(stepper1)" id="signinbutton">Next</button>
                    </div>

                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>

            </div>
        </div>
    </div>
</mat-step>

<mat-step label="Step 3" state="account" style="text-align: center; height: 96%; width: 136%; ">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
        <div class="col-md-1 "></div>
        <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
            <div class="homecard " id="signin " align="center " style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Bank Details</h2>

                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>

                <div class="container" style="margin-top: 5%">

                    <table class="table table-striped  table-responsive table-bordered">
                        <thead>
                            <tr>
                                <th>Bank Account Type</th>
                                <th>Bank Name</th>
                                <th>Branch</th>
                                <th>IFSC Code</th>
                                <th>Cheque Facility</th>
                                <!-- <th>Loan Facility</th> -->
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let field of fieldArray; let i = index">
                                <td>
                                    <select (change)="onSelectbank($event.target.value)" class="form-control">
                                    <option value="">Select Bank Account Type</option>
                                    <option *ngFor="let do of banks" value={{do.bankAccTypeId}}
                                        [selected]="do.bankAccTypeId == field.banktypeid">
                                        {{do.accountType}}
                                    </option>
                                </select>
                                </td>
                                <td>
                                    <input [(ngModel)]="field.bankname" class="form-control" type="text" name="{{field.bankname}}" />
                                </td>
                                <td>
                                    <input [(ngModel)]="field.branch" class="form-control" type="text" name="{{field.branch}}" />
                                </td>
                                <td>
                                    <input [(ngModel)]="field.ifscecode" class="form-control" type="text" name="{{field.ifscecode}}" />
                                </td>
                                <td>
                                    <input [(ngModel)]="field.selectedchequetypeid" class="form-control" />
                                </td>
                                <!-- <td>
                                <input [(ngModel)]="field.loanfacility" class="form-control" type="checkbox"
                                    name="{{field.loanfacility}}" />
                            </td> -->
                                <td>
                                    <button class="btn btn-default" type="button" (click)="deleteFieldValue(i)">Delete</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <select (change)="onSelectbank($event.target.value)" class="form-control">
                                    <option value="">Select Bank Account Type</option>
                                    <option *ngFor="let do of banks" value={{do.bankAccTypeId}}>
                                        {{do.accountType}}
                                    </option>
                                </select>
                                </td>
                                <td>
                                    <input class="form-control" type="text" id="bankname" [(ngModel)]="newAttribute.bankname" name="bankname" />

                                </td>
                                <td>
                                    <input class="form-control" type="text" id="branch" [(ngModel)]="newAttribute.branch" name="branch" />
                                </td>
                                <td>
                                    <input class="form-control" type="text" id="ifscecode" [(ngModel)]="newAttribute.ifscecode" name="ifscecode" />
                                </td>
                                <td>
                                    <select (change)="onSelectChequeType($event.target.value)" class="form-control">
                                    <option value="">Select Cheque Facility Type</option>
                                    <option value="1">Yes</option>
                                    <option value="2">No</option>
                                </select>
                                    <!-- <input class="form-control" type="checkbox" id="chequefacility"
                                    [(ngModel)]="newAttribute.chequefacility" name="chequefacility" /> -->
                                </td>
                                <!-- <td>
                                <input class="form-control" type="checkbox" id="loanfacility"
                                    [(ngModel)]="newAttribute.loanfacility" name="loanfacility" />
                            </td> -->
                                <td>
                                    <button class="btn btn-default" type="button" (click)="addFieldValue()">Add</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <form class="register-form " [formGroup]="BankDetailsForm">
                    <div class="col-md-12 ">&nbsp;</div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Identity Details</h4>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Ration Card Number</label>
                            <input formControlName="rationcardnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="rationcardnum">
                            <!-- <div *ngIf="submitted && k.rationcardnum.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.rationcardnum.errors.required "> Ration Card
                                Number is required</div>
                            <div style="color: red; " *ngIf="k.rationcardnum.errors.pattern ">enter valid Ration
                                Card Number</div>
                        </div> -->
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Aadhar Number</label>
                            <input  [ngClass]="{ 'is-invalid': submitted && k.aadharnum.errors } " formControlName="aadharnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="aadharnum" >
                            <div *ngIf="submitted && k.aadharnum.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.aadharnum.errors.required ">Aadhar Number is required
                            </div>
                            <div style="color: red; " *ngIf="k.aadharnum.errors.pattern ">enter valid Aadhar Number
                            </div>

                        </div>
                        <!-- <div style="color: red; " *ngIf="aadhaarNumberError" class="error-message">Invalid Aadhaar number</div> -->
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Voter ID Number</label>
                            <input [ngClass]="{ 'is-invalid': submitted && k.voternum.errors } " formControlName="voternum" class="form-control " type="text " id="inputbutton " [(ngModel)]="voternum"  >
                            <div *ngIf="submitted && k.voternum.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.voternum.errors.required "> Voter ID Number is required
                            </div>
                            <div style="color: red; " *ngIf="k.voternum.errors.pattern ">enter valid Voter ID
                            </div>

                        </div>

                        <!-- <div  style="color: red; " *ngIf="voterIdError" class="error-message">Invalid Voter ID</div> -->
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">PAN Number</label>
                            <input [ngClass]="{ 'is-invalid': submitted && k.pannumber.errors }" formControlName="pannumber" class="form-control " type="Date " id="inputbutton " [(ngModel)]="pannumber">
                            <div *ngIf="submitted && k.pannumber.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.pannumber.errors.required "> PAN Number is required
                                </div>
                                <div style="color: red; " *ngIf="k.pannumber.errors.pattern ">enter valid PAN Number
                                </div>

                            </div>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">GST No</label>
                            <input [ngClass]="{ 'is-invalid': submitted && k.servicetax.errors }" formControlName="servicetax" class="form-control " type="text " id="inputbutton " [(ngModel)]="servicetax" >
                            <div *ngIf="submitted && k.servicetax.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.servicetax.errors.required ">Service Tax Number
                                is required</div>
                            <div style="color: red; " *ngIf="k.servicetax.errors.pattern ">enter valid GST No
                            </div>

                        </div>

                        <!-- <div *ngIf="gstNumberError" class="error-message">Invalid GST Number</div> -->
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Passport Number</label>
                            <input [ngClass]="{ 'is-invalid': submitted && k.passportnum.errors }" formControlName="passportnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="passportnum"  >
                            <div *ngIf="submitted && k.passportnum.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.passportnum.errors.required "> Passport Number is
                                required</div>
                            <div style="color: red; " *ngIf="k.passportnum.errors.pattern ">enter valid Passport
                                Number </div>

                        </div>
                        <!-- <div *ngIf="passportNumberError" class="error-message">Invalid passport number</div> -->
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>


                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Life Insurance Value</label>
                            <input formControlName="lifeinsurance" class="form-control " type="input " id="inputbutton " [(ngModel)]="lifeinsurance">
                            <!-- <div *ngIf="submitted && k.lifeinsurance.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.lifeinsurance.errors.required ">Life Insurance
                                Value is required</div>
                        </div> -->
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Confirm</label>
                            <input class="form-control" formControlName="IsConfirm" [(ngModel)]="IsConfirm" (change)="onSelectIsConfirm($event)" type="checkbox" id="inputbutton ">

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <BR />

                    <BR />
                    <div *ngIf="bankdocsexist">
                        <div class="col-md-12 row">&nbsp;</div>
                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                    <div class="col-md-12 row">&nbsp;</div>
                     <div class="col-md-12 row">
                        <div class="col-md-1 row">&nbsp;</div>

                         <div class="col-md-2 row" *ngFor="let item of bankdocs">
                             <img [src]="item.file" height="100" width="100"/>
                             <br/>
                             <p style="text-align: center;">{{item.documentName}}</p>
                         </div>
                     </div> 
                    </div>  
                    <div class="col-md-12 row">&nbsp;</div>
                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Upload Documents</h4>

                    <div class="col-md-12 row">&nbsp;</div>
                    <div class="container" style="margin-top: 5%">
                        <div class="col-md-12 row">
                            <div class="col-md-3">&nbsp;</div>
                            <div class="col-md-9">
                                <div class="table-responsive" style="overflow-x: visible">
                                    <table class="table table-striped table-responsive table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sl No</th>
                                                <th>Certificate Name</th>
                                                <th>Upload Here</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Aadhar Card</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField (change)="onChangeAadhar($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Voter ID</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField (change)="onChangeVoterId($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Passport</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField (change)="onChangePassport($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>GST Certificate</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField (change)="onChangeGSTCert($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>IT Returns</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField (change)="onChangeITReturns($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Bank Statements</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField (change)="onChangeBankStatements($event)">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <BR />
                <div class="col-md-12 row">
                    <div class="col-md-4 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <button (click)="SaveBankDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-3 row">
                        <button (click)="bankIn(stepper1)" id="signinbutton">Next</button>
                    </div>

                </div>                    <div class="col-md-12 row">&nbsp;</div>
                <div class="col-md-12 row">&nbsp;</div>
            </div>
        </div>
    </div>
</mat-step>

<mat-step label="Step 4 " state="homei" style="text-align: center; height: 96%; width: 136%; ">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
        <div class="col-md-1 "></div>
        <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
            <div class="homecard " id="signin " align="center " style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                <div class="col-md-12 ">&nbsp;</div>

                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Asset Details</h2>

                <div class="col-md-12 ">&nbsp;</div>

                <div class="container" style="margin-top: 5%">

                    <div class="table-responsive" style="overflow-x: visible">
                        <table class="table table-striped table-responsive table-bordered">
                            <thead>
                                <tr>
                                    <th>Fixed Asset</th>
                                    <th>Location with Address</th>
                                    <th>Measurement</th>
                                    <th>Guidance Value</th>
                                    <th>Present Value</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let field of fieldArrayFA; let i = index">
                                    <td>
                                        <select (change)="onSelecthome($event.target.value)" class="form-control">
                                            <option value="">Select Asset</option>
                                            <option *ngFor="let do of assets" value={{do.fixedAssetId}}
                                                [selected]="do.fixedAssetId == field.fixedAssetId">
                                                {{do.fixedAssetName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.placeallocation" class="form-control" type="text" name="{{field.placeallocation}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.measurement" class="form-control" type="text" name="{{field.measurement}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.giidancevalue" class="form-control" type="text" name="{{field.giidancevalue}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.presentvalue" class="form-control" type="text" name="{{field.presentvalue}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.total" class="form-control" type="text" name="{{field.total}}" />
                                    </td>
                                    <td>
                                        <button class="btn btn-default" type="button" (click)="deleteFieldValue(i)">Delete</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <select (change)="onSelecthome($event.target.value)" class="form-control">
                                            <option value="">Select Asset</option>
                                            <option *ngFor="let do of assets" value={{do.fixedAssetId}}
                                                [selected]="do.fixedAssetId == cid">
                                                {{do.fixedAssetName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input class="form-control" type="text" id="placeallocation" [(ngModel)]="newAttribute.placeallocation" name="placeallocation" />

                                    </td>
                                    <td>
                                        <input class="form-control" type="number" id="measurement" [(ngModel)]="newAttribute.measurement" name="measurement" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="number" id="giidancevalue" [(ngModel)]="newAttribute.giidancevalue" name="giidancevalue" (change)="GetFixedAssetTotal(newAttribute.measurement,newAttribute.giidancevalue)" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="number" id="presentvalue" [(ngModel)]="newAttribute.presentvalue" name="presentvalue" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="number" id="total" [(ngModel)]="newAttribute.total" name="total" readonly />
                                    </td>
                                    <td>
                                        <button class="btn btn-default" type="button" (click)="addFieldValueFA()">Add</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="container" style="margin-top: 5%">

                    <div class="table-responsive" style="overflow-x: visible">
                        <table class="table table-striped table-responsive table-bordered">
                            <thead>
                                <tr>
                                    <th>Movable Asset</th>
                                    <th>Model</th>
                                    <th>Date Of Purchase</th>
                                    <th>Quantity</th>
                                    <th>Present Value</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let field of fieldArrayMove; let i = index">
                                    <td>
                                        <select (change)="onSelectcar($event.target.value)" class="form-control">
                                            <option value="">Select Movable Asset</option>
                                            <option *ngFor="let do of moveable" value={{do.movableAssetId}}
                                                [selected]="do.movableAssetId == field.movableAssetId">
                                                {{do.movableAssetName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.model" class="form-control" type="text" name="{{field.model}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.dateofpurchase" class="form-control" type="date" name="{{field.dateofpurchase}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.quantity" class="form-control" type="text" name="{{field.quantity}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.prevalues" class="form-control" type="text" name="{{field.prevalues}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.cartotal" class="form-control" type="text" name="{{field.cartotal}}" />
                                    </td>
                                    <td>
                                        <button class="btn btn-default" type="button" (click)="deleteFieldcarValue(i)">Delete</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <select (change)="onSelectcar($event.target.value)" class="form-control">
                                            <option value="">Select Movable Asset</option>
                                            <option *ngFor="let do of moveable" value={{do.movableAssetId}}
                                                [selected]="do.movableAssetId == cid">
                                                {{do.movableAssetName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input class="form-control" type="text" id="model" [(ngModel)]="carAttribute.model" name="model" />

                                    </td>
                                    <td>
                                        <input class="form-control" type="date" id="dateofpurchase" [(ngModel)]="carAttribute.dateofpurchase" name="dateofpurchase" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="number" id="quantity" [(ngModel)]="carAttribute.quantity" name="quantity" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="number" id="prevalues" [(ngModel)]="carAttribute.prevalues" name="prevalues" (change)="GetMovableAssetTotal(carAttribute.quantity,carAttribute.prevalues)" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="text" id="cartotal" [(ngModel)]="carAttribute.cartotal" name="cartotal" readonly />
                                    </td>
                                    <td>
                                        <button class="btn btn-default" type="button" (click)="addFieldcarValue()">Add</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <form class="register-form " [formGroup]="homeDetailsForm">

                </form>

                <BR />
                <BR />
                <div class="col-md-12 row">
                    <div class="col-md-4 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <button (click)="SaveHomeVehicleDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-3 row">
                        <button (click)="homeIn(stepper1)" id="signinbutton">Next</button>
                    </div>

                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
            </div>
        </div>
    </div>
</mat-step>



    <mat-step label="Step 5" state="project" style="text-align: center; height: 96%; width: 136%; ">

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center "
                    style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Project Loan</h2>

                    <div class="col-md-12 ">&nbsp;</div>

                    <form class="register-form " [formGroup]="projectloanForm">
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Name Of the Company / Firm</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Companyfirm.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Companyfirm"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Companyfirm">
                                <div *ngIf="submitted && p.Companyfirm.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Companyfirm.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Ploat/Building/Plat Name and Number</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Ploat.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Ploat" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Ploat">
                                <div *ngIf="submitted && p.Ploat.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Ploat.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Street Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Streetname.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Streetname"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Streetname">
                                <div *ngIf="submitted && p.Streetname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Streetname.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">City</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.towname.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="towname" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="towname">
                                <div *ngIf="submitted && p.towname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.towname.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Telephone No</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Telephoneno.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Telephoneno"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Telephoneno">
                                <div *ngIf="submitted && p.Telephoneno.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Telephoneno.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Mobile No</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Mobile.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Mobile" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Mobile">
                                <div *ngIf="submitted && p.Mobile.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Mobile.errors.required "> Required Field</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Fax</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Fax.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Fax" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Fax">
                                <div *ngIf="submitted && p.Fax.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Fax.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Loan Applied for</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.ancestral.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="ancestral"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="ancestral">
                                <div *ngIf="submitted && p.ancestral.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.ancestral.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Purpose of Loan</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.encumbered.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="encumbered"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="encumbered">
                                <div *ngIf="submitted && p.encumbered.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.encumbered.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">When and how the loan will be repaid?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.loanac.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="loanac" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="loanac">
                                <div *ngIf="submitted && p.loanac.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.loanac.errors.required "> Required Field</div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Name of the Chief/ Promoter/ Chief
                                    Executive</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Outstanding.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Outstanding"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Outstanding">
                                <div *ngIf="submitted && p.Outstanding.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Outstanding.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Your House</label>
                            </div>
                            <label for="Email " id="emaillabel ">Owned</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Bank" class="form-control "
                                    type="checkbox" id="inputbutton " [(ngModel)]="Bank">
                            </div>
                            <label for="Email " id="emaillabel ">Rented</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="value" class="form-control "
                                    type="checkbox" id="inputbutton " [(ngModel)]="Christian">
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">What is your academic qualification? Is related to your line of
                                    trade?</label>
                                <input
                                    [ngClass]="{ 'is-invalid': academicqualification && p.academicqualification.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="academicqualification"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="academicqualification">
                                <div *ngIf="submitted && p.academicqualification.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.academicqualification.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Your Account Number? What would be the minimum
                                    balance in the account?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.deductions.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="deductions"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="deductions">
                                <div *ngIf="submitted && p.deductions.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.deductions.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label for="Email " id="emaillabel ">Are you assessed the Income-Tax</label>
                            </div>
                            <label for="Email " id="emaillabel ">Yes</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="salary"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="salary">
                            </div>
                            <label for="Email " id="emaillabel ">No</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="intax" class="form-control "
                                    type="checkbox" id="inputbutton " [(ngModel)]="intax">

                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label for="Email " id="emaillabel ">Do you have a life insurance Policy?</label>
                            </div>
                            <label for="Email " id="emaillabel ">Yes</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="insur" class="form-control "
                                    type="checkbox" id="inputbutton " [(ngModel)]="insur">
                            </div>
                            <label for="Email " id="emaillabel ">No</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="insurpol"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="insurpol">
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Year of commencement of business</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.purchased.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="purchased"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="purchased">
                                <div *ngIf="submitted && p.purchased.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.purchased.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Loan Amount</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.inclusive.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="inclusive"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="inclusive">
                                <div *ngIf="submitted && p.inclusive.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.inclusive.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">State the profit / loss and sales
                            turnover for the last 3 financial year</h3>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">First Year</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.incomesource.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="incomesource"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="incomesource">
                                <div *ngIf="submitted && p.incomesource.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.incomesource.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Second Year</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.allowance.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="allowance"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="allowance">
                                <div *ngIf="submitted && p.allowance.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.allowance.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Three Year</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.thrdyr.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="thrdyr" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="thrdyr">
                                <div *ngIf="submitted && p.thrdyr.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.thrdyr.errors.required "> Required Field</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Tell us about your factory Premises</label>
                            </div>
                            <label for="Email " id="emaillabel ">Yes</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="payment"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="payment">
                            </div>
                            <label for="Email " id="emaillabel ">No</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Premises"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Premises">
                            </div>
                        </div>

                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Tell us about your product</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.applicant.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="applicant"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="applicant">
                                <div *ngIf="submitted && p.applicant.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.applicant.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">How do you plan to produce it?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.produce.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="produce" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="produce">
                                <div *ngIf="submitted && p.produce.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.produce.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Does the product require special know how? If so, are you in
                                    possession of the know</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.prospecial.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="prospecial"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="prospecial">
                                <div *ngIf="submitted && p.prospecial.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.prospecial.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">What are raw materials required?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.materials.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="materials"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="materials">
                                <div *ngIf="submitted && p.materials.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.materials.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Whether your line of activity falls under priority
                                    sector?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.sector.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="sector" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="sector">
                                <div *ngIf="submitted && p.sector.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.sector.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">No</label>
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Board" class="form-control "
                                    type="checkbox" id="inputbutton " [(ngModel)]="Board">

                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">How do you plan to produces them? Are they available on credit?
                                    If so what are the terms of credit?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.terms.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="terms" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="terms">
                                <div *ngIf="submitted && p.terms.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.terms.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">How do you plan to ensure the quality of your product? Any
                                    special steps being taken?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.special.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="special" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="special">
                                <div *ngIf="submitted && p.special.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.special.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Whether you need skilled labour? Please describe your plants for
                                    skilled labour?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.skilled.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="skilled" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="skilled">
                                <div *ngIf="submitted && p.skilled.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.skilled.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-6 row ">
                                <label id="emaillabel ">Any special reason to locate your business at the present
                                    location? Is there any advantage in procuring the raw materials locally?
                                    Is the local market big enough to subscribe the finished product of your firm? Any
                                    other advantage?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.advantage.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="advantage"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="advantage">
                                <div *ngIf="submitted && p.advantage.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.advantage.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Manufacturing process in brief</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Manufacturing.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Manufacturing"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Manufacturing">
                                <div *ngIf="submitted && p.Manufacturing.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Manufacturing.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">What the level of competition for the product? How
                                    do you plan to meet the competition?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.competition.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="competition"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="competition">
                                <div *ngIf="submitted && p.competition.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.competition.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Give the total outside liabilities
                            for the last 3 years and your tangible network:</h3>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">PARAMETER</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.parameter.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="parameter"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="parameter">
                                <div *ngIf="submitted && p.parameter.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.parameter.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Total outside liabilities</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.outside.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="outside" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="outside">
                                <div *ngIf="submitted && p.outside.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.outside.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Tangible net worth(including loans from friends and
                                    relatives)</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Tangible.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Tangible" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Tangible">
                                <div *ngIf="submitted && p.Tangible.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Tangible.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">TOL / TNW</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Balance.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Balance" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Balance">
                                <div *ngIf="submitted && p.Balance.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Balance.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">What is the quality of your
                            receivables? How many months’ sales do they represent? Can you give an ageing of your
                            receivables?
                        </h4>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">AGE Less than 1 months old</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.modelname.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="modelname"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="modelname">
                                <div *ngIf="submitted && p.modelname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.modelname.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">AGE 1 to 2 months old</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.old.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="old" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="old">
                                <div *ngIf="submitted && p.old.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.old.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">AGE 2 to 3 months old</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.employed.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="employed" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="employed">
                                <div *ngIf="submitted && p.employed.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.employed.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">INFORMATION REGARDING REPAYMENT OF
                            DEBTS - I</h3>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Debts incurred in connection with
                        </h4>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">AGE More than 3 months old</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.monthold.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="monthold" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="monthold">
                                <div *ngIf="submitted && p.monthold.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.monthold.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Total percentage</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.finishloan.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="finishloan"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="finishloan">
                                <div *ngIf="submitted && p.finishloan.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.finishloan.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-6 row ">
                                <label id="emaillabel "> What is the quality of your finished goods inventory? Are they
                                    sold against order / as
                                    and when produced? Are they kept in stock for long? How many months’ sales do they
                                    represent?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.concern.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="concern" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="concern">
                                <div *ngIf="submitted && p.concern.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.concern.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-6 row ">
                                <label id="emaillabel "> What will be the time period between procurement of raw
                                    materials to realization of
                                    sale proceeds? Can you cut short the cycle without increasing your
                                    liability?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.period.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="period" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="period">
                                <div *ngIf="submitted && p.period.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.period.errors.required "> Required Field</div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-12 row ">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Give the details of cost and the
                            means of finance for your project</h3>
                        <div class="col-md-12 ">&nbsp;</div>

                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;"> Land
                        </h5>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Person.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Person" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Person">
                                <div *ngIf="submitted && p.Person.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Person.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Personone.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Personone"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Personone">
                                <div *ngIf="submitted && p.Personone.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Personone.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Persondata.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Persondata"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Persondata">
                                <div *ngIf="submitted && p.Persondata.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Persondata.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">
                            Building</h5>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Persontwo.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Persontwo"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Persontwo">
                                <div *ngIf="submitted && p.Persontwo.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Persontwo.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Persontwodta.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Persontwodta"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Persontwodta">
                                <div *ngIf="submitted && p.Persontwodta.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Persontwodta.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Pertwodta.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Pertwodta"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Pertwodta">
                                <div *ngIf="submitted && p.Pertwodta.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Pertwodta.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">
                            Machinery</h5>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Personthree.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Personthree"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Personthree">
                                <div *ngIf="submitted && p.Personthree.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Personthree.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Personthredta.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Personthredta"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Personthredta">
                                <div *ngIf="submitted && p.Personthredta.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Personthredta.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Personval.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Personval"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Personval">
                                <div *ngIf="submitted && p.Personval.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Personval.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Other
                            Items
                        </h5>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.otheritem.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="otheritem"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="otheritem">
                                <div *ngIf="submitted && p.otheritem.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.otheritem.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="otheritemone"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="otheritemone">

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="otheritemtwo"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="otheritemtwo">

                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">From
                            own sources</h5>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.ownsource.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="ownsource"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="ownsource">
                                <div *ngIf="submitted && p.ownsource.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.ownsource.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.ownsourceone.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="ownsourceone"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="ownsourceone">

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Bank
                            loan
                        </h5>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.bankloans.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="bankloans"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="bankloans">
                                <div *ngIf="submitted && p.bankloans.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.bankloans.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.bankloandata.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="bankloandata"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="bankloandata">
                                <div *ngIf="submitted && p.bankloandata.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.bankloandata.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">
                            Friends and relatives</h5>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Friends.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Friends" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Friends">
                                <div *ngIf="submitted && p.Friends.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Friends.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Friendsrela.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Friendsrela"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Friendsrela">

                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Others
                        </h5>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Othersdata.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Othersdata"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Othersdata">

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Othersdatee.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Othersdatee"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Othersdatee">

                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Total
                        </h5>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Totaldata.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Totaldata"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Totaldata">
                                <div *ngIf="submitted && p.Totaldata.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Totaldata.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Totaltwo.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Totaltwo" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Totaltwo">
                                <div *ngIf="submitted && p.Totaltwo.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Totaltwo.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Has the project been vetted by consultancy cell of the Bank or
                                    consultants of repute? Give details</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Registration.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Registration"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Registration">
                                <div *ngIf="submitted && p.Registration.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Registration.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-6 row ">
                                <label for="Email " id="emaillabel ">What is the repayment period you are looking for
                                    the term loan? What will be the annual cash accruals? What will be your liability
                                    towards
                                    payment of installments and interest on term loan in a year?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.authorised.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="authorised"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="authorised">
                                <div *ngIf="submitted && p.authorised.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.authorised.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">What is the collateral you would be
                            able to offer? GIVE DETAILS</h4>
                        <div class="col-md-12">&nbsp;</div>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Item
                        </h5>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.benefit.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="benefit" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="benefit">
                                <div *ngIf="submitted && p.benefit.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.benefit.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Relationship.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Relationship"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Relationship">
                                <div *ngIf="submitted && p.Relationship.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Relationship.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.studying.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="studying" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="studying">
                                <div *ngIf="submitted && p.studying.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.studying.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Value
                        </h5>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.course.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="course" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="course">
                                <div *ngIf="submitted && p.course.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.course.errors.required "> Required Field</div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.obtained.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="obtained" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="obtained">
                                <div *ngIf="submitted && p.obtained.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.obtained.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.evidence.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="evidence" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="evidence">
                                <div *ngIf="submitted && p.evidence.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.evidence.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Basis
                        </h5>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.under.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="under" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="under">
                                <div *ngIf="submitted && p.under.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.under.errors.required "> Required Field</div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.annualincome.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="annualincome"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="annualincome">
                                <div *ngIf="submitted && p.annualincome.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.annualincome.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.borrower.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="borrower" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="borrower">
                                <div *ngIf="submitted && p.borrower.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.borrower.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h5 style="font-family: DINNextRoundedLTPro;color: #2b156f;float: left;margin-left: 13%;">Total
                        </h5>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.deposite.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="deposite" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="deposite">
                                <div *ngIf="submitted && p.deposite.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.deposite.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Additional.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Additional"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Additional">
                                <div *ngIf="submitted && p.Additional.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Additional.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <input [ngClass]="{ 'is-invalid': submitted && p.Account.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Account" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Account">
                                <div *ngIf="submitted && p.Account.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Account.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel ">Anything else you would like to tell about your business</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Membership.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Membership"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Membership">
                                <div *ngIf="submitted && p.Membership.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Membership.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel "> Please tall us about your future plans</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.futureplan.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="futureplan"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="futureplan">
                                <div *ngIf="submitted && p.futureplan.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.futureplan.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel ">What is the level of sales you are projecting? Briefly describe
                                    the basis</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Officedata.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Officedata"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Officedata">
                                <div *ngIf="submitted && p.Officedata.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Officedata.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel "> What is the level of receivables you are expecting in terms of
                                    month’s sale?</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.receivables.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="receivables"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="receivables">
                                <div *ngIf="submitted && p.receivables.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.receivables.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Application Type</label>
                            </div>
                            <label for="Email " id="emaillabel ">New</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Income"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Income">

                            </div>

                            <label for="Email " id="emaillabel ">Update</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="operative"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="operative">

                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Account Holder Type</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Holder.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Holder" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Holder">
                                <div *ngIf="submitted && p.Holder.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Holder.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">US Reportable</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Reportable.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Reportable"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Reportable">
                                <div *ngIf="submitted && p.Reportable.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Reportable.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Other Reportable</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Mortgagor.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Mortgagor"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Mortgagor">
                                <div *ngIf="submitted && p.Mortgagor.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Mortgagor.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Proof of Identity</h3>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Certificate of Incorporation / Formation</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Formation.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Formation"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Formation">
                                <div *ngIf="submitted && p.Formation.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Formation.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Officially valid document(s) in respect of person authorized to
                                    transact</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.transact.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="transact" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="transact">
                                <div *ngIf="submitted && p.transact.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.transact.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Resolution of Board / Managing Committee</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.letter.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="letter" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="letter">
                                <div *ngIf="submitted && p.letter.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.letter.errors.required "> Required Field</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Memorandum and article of Association / Partnership /Trust Document</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.proaddress.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="proaddress"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="proaddress">
                                <div *ngIf="submitted && p.proaddress.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.proaddress.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Memorandum and article of Association / Partnership /Trust Document</label>
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Document"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Document">
                                <!-- <div *ngIf="submitted && p.Document.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Document.errors.required "> Required Field
                                    </div>
                                </div> -->
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Activity proof (For dole proprietorship only)</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.proprietorship.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="proprietorship"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="proprietorship">
                                <div *ngIf="submitted && p.proprietorship.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.proprietorship.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Other</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.area.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="area" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="area">
                                <div *ngIf="submitted && p.area.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.area.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12 row ">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Proof Of Address (POA)</h3>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Registered Office Address In India(If Applicable) / Place of Business</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.candidate.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="candidate"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="candidate">
                                <div *ngIf="submitted && p.candidate.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.candidate.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Type</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Squares.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Squares" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Squares">
                                <div *ngIf="submitted && p.Squares.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Squares.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Proof Of Address:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.classfield.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="classfield"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="classfield">
                                <div *ngIf="submitted && p.classfield.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.classfield.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Certificate of Incorporation / Formation</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.persquare.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="persquare"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="persquare">
                                <div *ngIf="submitted && p.persquare.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.persquare.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Registration Certificate</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.estimate.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="estimate" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="estimate">
                                <div *ngIf="submitted && p.estimate.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.estimate.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Address Line 1:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Foundation.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Foundation"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Foundation">
                                <div *ngIf="submitted && p.Foundation.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Foundation.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line 2:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Roofing.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Roofing" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Roofing">
                                <div *ngIf="submitted && p.Roofing.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Roofing.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line 3:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.sanitary.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="sanitary" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="sanitary">
                                <div *ngIf="submitted && p.sanitary.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.sanitary.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">City</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Flooring.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Flooring" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Flooring">
                                <div *ngIf="submitted && p.Flooring.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Flooring.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">District</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Presentstate.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Presentstate"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Presentstate">
                                <div *ngIf="submitted && p.Presentstate.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Presentstate.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">PIN/Post Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Presentstate.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Presentstate"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Presentstate">
                                <div *ngIf="submitted && p.Presentstate.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Presentstate.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Sate / UT Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.building.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="building" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Country">
                                <div *ngIf="submitted && p.building.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.building.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">State / UT Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.statecode.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="statecode"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="statecode">
                                <div *ngIf="submitted && p.statecode.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.statecode.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Country Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Country.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Country" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="building">
                                <div *ngIf="submitted && p.Country.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Country.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Country Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.stock.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="stock" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="stock">
                                <div *ngIf="submitted && p.stock.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.stock.errors.required "> Required Field</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> (ii)Correspondence / Local Address
                            Details
                        </h3>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Proof Of Address:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Refundable.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Refundable"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Refundable">
                                <div *ngIf="submitted && p.Refundable.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Refundable.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Certificate of Incorporation / Formation</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.fundpro.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="fundpro" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="fundpro">
                                <div *ngIf="submitted && p.fundpro.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.fundpro.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Registration Certificate</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Others.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Others" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Others">
                                <div *ngIf="submitted && p.Others.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Others.errors.required "> Required Field</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line 1:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.bankfrom.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="bankfrom" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="bankfrom">
                                <div *ngIf="submitted && p.bankfrom.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.bankfrom.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line 2:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.durable.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="durable" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="durable">
                                <div *ngIf="submitted && p.durable.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.durable.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line 3:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.consumer.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="consumer" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="consumer">
                                <div *ngIf="submitted && p.consumer.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.consumer.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">City</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.rental.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="rental" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="rental">
                                <div *ngIf="submitted && p.rental.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.rental.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">District</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.house.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="house" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="house">
                                <div *ngIf="submitted && p.house.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.house.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">PIN/Post Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.expected.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="expected" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="expected">
                                <div *ngIf="submitted && p.expected.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.expected.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Sate / UT Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.advance.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="advance" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="advance">
                                <div *ngIf="submitted && p.advance.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.advance.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">State / UT Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.unable.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="unable" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="unable">
                                <div *ngIf="submitted && p.unable.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.unable.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Country Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.escalation.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="escalation"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="escalation">
                                <div *ngIf="submitted && p.escalation.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.escalation.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Country Code.</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Accumulation.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Accumulation"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Accumulation">
                                <div *ngIf="submitted && p.Accumulation.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Accumulation.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> (iii)Address in the Jurisdiction
                            where Entity is Residence Outside India for Tax Purposes Details</h3>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Same as Current / Permanent / Overseas Address</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.preowner.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="preowner" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="preowner">
                                <div *ngIf="submitted && p.preowner.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.preowner.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Same as Correspondence / Local Address Details</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.purchase.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="purchase" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="purchase">
                                <div *ngIf="submitted && p.purchase.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.purchase.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Type</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Land.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Land" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Land">
                                <div *ngIf="submitted && p.Land.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Land.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Proof Of Address:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Agreed.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Agreed" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Agreed">
                                <div *ngIf="submitted && p.Agreed.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Agreed.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Certificate of Incorporation / Formation</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.paid.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="paid" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="paid">
                                <div *ngIf="submitted && p.paid.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.paid.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Registration Certificate</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.entered.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="entered" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="entered">
                                <div *ngIf="submitted && p.entered.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.entered.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> Probable date of registration and
                            amount Rp.</h3>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line 1:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Probable.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Probable" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Probable">
                                <div *ngIf="submitted && p.Probable.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Probable.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line 2:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.amtrs.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="amtrs" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="amtrs">
                                <div *ngIf="submitted && p.amtrs.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.amtrs.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line 3:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.proposedata.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="proposedata"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="proposedata">
                                <div *ngIf="submitted && p.proposedata.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.proposedata.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">City</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.children.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="children" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="children">
                                <div *ngIf="submitted && p.children.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.children.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">District</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Districtdata.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Districtdata"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Districtdata">
                                <div *ngIf="submitted && p.Districtdata.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Districtdata.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">PIN/Post Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.schcolg.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="schcolg" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="schcolg">
                                <div *ngIf="submitted && p.schcolg.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.schcolg.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Sate / UT Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.utname.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="utname" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="utname">
                                <div *ngIf="submitted && p.utname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.utname.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> State / UT Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.conveyance.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="conveyance"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="conveyance">
                                <div *ngIf="submitted && p.conveyance.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.conveyance.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Country Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.conname.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="conname" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="conname">
                                <div *ngIf="submitted && p.conname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.conname.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Country Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.concode.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="concode" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="concode">
                                <div *ngIf="submitted && p.concode.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.concode.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Related Persons/
                            Beneficial Owner</h3>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Number of Related Persons:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.rent.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="rent" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="rent">
                                <div *ngIf="submitted && p.rent.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.rent.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Number of Beneficial Owners:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.annualin.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="annualin" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="annualin">
                                <div *ngIf="submitted && p.annualin.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.annualin.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel ">Tax Residence of India only and not other Country outside
                                    India</label>
                            </div>
                            <label for="Email " id="emaillabel ">Yes</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Agriculture"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Agriculture">
                            </div>
                            <label for="Email " id="emaillabel ">No</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Residence"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Residence">
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel ">Tax Residence of US</label>
                            </div>
                            <label for="Email " id="emaillabel ">New</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Taxresidenew"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Taxresidenew">
                            </div>
                            <label for="Email " id="emaillabel ">Update</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Taxreside"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Taxreside">
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">US TIN</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Investment.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Investment"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Investment">
                                <div *ngIf="submitted && p.Investment.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Investment.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                            <div class="col-md-3 row ">
                                <label id="emaillabel ">A US Person</label>
                            </div>
                            <label for="Email " id="emaillabel ">Yes</label>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="usperson"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="usperson">
                            </div>
                            <label for="Email " id="emaillabel ">No</label>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="uspersondata"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="uspersondata">
                            </div>

                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel ">A Specified US Person</label>
                            </div>
                            <label for="Email " id="emaillabel ">Yes</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="ontherdata"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="ontherdata">
                            </div>
                            <label for="Email " id="emaillabel ">No</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Specified"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Specified">
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel ">Tax Resident Outside India other than US</label>
                            </div>
                            <label for="Email " id="emaillabel ">Yes</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="required"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="required">
                            </div>
                            <label for="Email " id="emaillabel ">No</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Resident"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Resident">
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Country Code:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.respect.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="respect" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="respect">
                                <div *ngIf="submitted && p.respect.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.respect.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> TIN / Functional Equivalent:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.household.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="household"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="household">
                                <div *ngIf="submitted && p.household.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.household.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel ">A Governmental Entity:</label>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <label for="Email " id="emaillabel ">Yes</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Education"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Education">
                            </div>
                            <label for="Email " id="emaillabel ">No</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Governmental"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Governmental">
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel ">An International Organization: </label>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <label for="Email " id="emaillabel ">Yes</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Entertainment"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Entertainment">
                            </div>
                            <label for="Email " id="emaillabel ">No</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="International"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="International">
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel ">A Central Bank:</label>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <label for="Email " id="emaillabel ">Yes</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Telephone"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Telephone">
                            </div>
                            <label for="Email " id="emaillabel ">No</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Central"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Central">
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel ">A Financial Institution:</label>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <label for="Email " id="emaillabel ">Yes</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="petrol"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="petrol">
                            </div>
                            <label for="Email " id="emaillabel ">No</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Institution"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Institution">
                            </div>
                        </div>

                        <div class="col-md-12">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Nature Of Business</h3>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Manufacture</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Repayment.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Repayment"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Repayment">
                                <div *ngIf="submitted && p.Repayment.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Repayment.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Trade</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.monthcome.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="monthcome"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="monthcome">
                                <div *ngIf="submitted && p.monthcome.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.monthcome.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Retailer</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Expenditure.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Expenditure"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Expenditure">
                                <div *ngIf="submitted && p.Expenditure.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Expenditure.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">

                            <div class="col-md-2 row ">&nbsp;</div>
                            <label for="Email " id="emaillabel ">Service Provider</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Service"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Service">
                            </div>
                            <label for="Email " id="emaillabel ">Export / Import</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="exImport"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="exImport">
                            </div>
                            <label for="Email " id="emaillabel ">Other</label>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Otherprovider"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Otherprovider">
                            </div>
                        </div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Industry Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.over.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="over" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="over">
                                <div *ngIf="submitted && p.over.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.over.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Other</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.applied.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="applied" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="applied">
                                <div *ngIf="submitted && p.applied.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.applied.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">

                            <div class="col-md-2 row ">&nbsp;</div>
                            <label for="Email " id="emaillabel ">0-5 Lakh</label>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="valuelak"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="valuelak">
                            </div>
                            <label for="Email " id="emaillabel ">5-10 Lakh</label>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="tenlakh"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="tenlakh">
                            </div>
                            <label for="Email " id="emaillabel ">10-25 Lakh</label>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="twentylakhs"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="twentylakhs">
                            </div>

                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <label for="Email " id="emaillabel ">25 Lakh - 1 Cr</label>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="onecre"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="onecre">
                            </div>
                            <label for="Email " id="emaillabel ">1 - 5 Cr</label>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="onetofive"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="onetofive">
                            </div>
                            <label for="Email " id="emaillabel ">5 - 50Cr</label>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="fiftycr"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="fiftycr">
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <label for="Email " id="emaillabel ">50 - 100 Cr</label>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="hundredcr"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="hundredcr">

                            </div>
                            <label for="Email " id="emaillabel ">100 Cr More</label>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="crmore"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="crmore">
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Mode Of Operations</h3>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">

                            <div class="col-md-2 row ">&nbsp;</div>
                            <label for="Email " id="emaillabel ">1.Single</label>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Singledata"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Singledata">

                            </div>
                            <label for="Email " id="emaillabel ">Jointly</label>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Jointly"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Jointly">

                            </div>
                            <label for="Email " id="emaillabel ">Severally</label>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Severally"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Severally">

                            </div>

                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">

                            <div class="col-md-2 row ">&nbsp;</div>
                            <label for="Email " id="emaillabel ">2.As per Board Resolution</label>
                            <div class="col-md-3 row ">

                                <input (keyup.enter)="projectIn(stepper) " formControlName="Resolution"
                                    class="form-control " type="checkbox" id="inputbutton "
                                    [(ngModel)]="BoardResolution">

                            </div>
                            <label for="Email " id="emaillabel ">Other</label>
                            <div class="col-md-3 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Resoluother"
                                    class="form-control " type="checkbox" id="inputbutton "
                                    [(ngModel)]="BoardResoluother">
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">

                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">3.As per Board ResolutionCorporate Internet
                                    Banking</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Internet"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Internet">
                            </div>
                            <div class="col-md-1 row ">
                                <label for="Email " id="emaillabel ">Viewing Rights</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Viewing"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Viewing">
                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Transaction Rights</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Transaction"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Transaction">

                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Cheque Book</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Chequebook"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Chequebook">

                            </div>
                            <div class="col-md-1 row ">
                                <label for="Email " id="emaillabel ">Business Debit Card</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Debitcd"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Debitcd">
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">

                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">4.POS Facility (card Swiping Machine)</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Swiping"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Swiping">
                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">SMS Alerts</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Alerts"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Alerts">

                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Cash Pick up Facility</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Facilityup"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Facilityup">

                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Sate Bank Collect</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Collectsta"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Collectsta">

                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">

                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">5.Statement Frequency</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Frequency"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Frequency">

                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Monthly</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Fremonth"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Fremonth">

                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Quarterly</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Quarterlyfre"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Quarterlyfre">

                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Half-Yearlyt</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Yearlythalf"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Yearlythalf">

                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Account Variant</h3>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Normal Current Account (MBA Rs 10000)</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Normal"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Normal">

                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Power Gain Current Account (MBA Rs. 200000)</label>
                            </div>
                            <div class="col-md-1 row ">

                                <input (keyup.enter)="projectIn(stepper) " formControlName="Power" class="form-control "
                                    type="checkbox" id="inputbutton " [(ngModel)]="Power">

                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Power Pack Current Account (MBA Rs 500000)</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Currentpack"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Currentpack">

                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Power POS Current Account</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="POS" class="form-control "
                                    type="checkbox" id="inputbutton " [(ngModel)]="POS">

                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Power Jyoti (MBA Rs 50000)</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Jyoti" class="form-control "
                                    type="checkbox" id="inputbutton " [(ngModel)]="Jyoti">

                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Power Jyoti (Pre Uploaded)(MBA Rs 50000)</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Uploaded"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Uploaded">

                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Surbhi Current Account (MBA Rs 10000)</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Surbhi"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Surbhi">

                            </div>
                            <div class="col-md-2 row ">
                                <label for="Email " id="emaillabel ">Other</label>
                            </div>
                            <div class="col-md-1 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Surbhiother"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Surbhiother">

                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Nomination: Applicable only for
                            sale proprietorship</h3>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">

                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">I / We want to make a nomination in My/Our
                                    Account</label>
                            </div>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="nomination"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="nomination">

                            </div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">I / We do not want to make a nomination in My/Our
                                    Account </label>
                            </div>
                            <div class="col-md-2 row ">
                                <input (keyup.enter)="projectIn(stepper) " formControlName="Accountour"
                                    class="form-control " type="checkbox" id="inputbutton " [(ngModel)]="Accountour">
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Nomination Form (DA1)</h3>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel ">Nomination under Section 45Z of the Banking Regulation Act, 1949
                                    and rule 2(1) of Banking Companies (Nomination)
                                    Rule 1985 in the respect of Bank Deposits.I / We </label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.commitment.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="commitment"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="commitment">
                                <div *ngIf="submitted && p.commitment.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.commitment.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel ">nominate the following person to whom in the event of my / our /
                                    minor's death amount of Deposit, particulars where are given below,
                                    may be returned by State Bank of India </label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.about.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="about" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="about">
                                <div *ngIf="submitted && p.about.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.about.errors.required "> Required Field</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> I we want the name of the nominee to be printed on the
                                    Passbook</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.cheques.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="cheques" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="cheques">
                                <div *ngIf="submitted && p.cheques.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.cheques.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Upload Nominee Image</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.employeee.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="employeee"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="employeee">
                                <div *ngIf="submitted && p.employeee.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.employeee.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">No image choosed</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.left.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="left" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="left">
                                <div *ngIf="submitted && p.left.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.left.errors.required "> Required Field</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Deposit:</h3>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Type of Deposit:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.arrangement.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="arrangement"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="arrangement">
                                <div *ngIf="submitted && p.arrangement.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.arrangement.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Account Number:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.casefield.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="casefield"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="casefield">
                                <div *ngIf="submitted && p.casefield.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.casefield.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Nominee:</h3>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Name:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Details.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Details" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Details">
                                <div *ngIf="submitted && p.Details.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Details.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Relationship with Depositor:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.annum.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="annum" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="annum">
                                <div *ngIf="submitted && p.annum.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.annum.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Age:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.obligant.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="obligant" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="obligant">
                                <div *ngIf="submitted && p.obligant.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.obligant.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">DOB:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.employedat.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="employedat"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="employedat">
                                <div *ngIf="submitted && p.employedat.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.employedat.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line One :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Net.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Net" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Net">
                                <div *ngIf="submitted && p.Net.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Net.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line Two :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.comment.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="comment" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="comment">
                                <div *ngIf="submitted && p.comment.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.comment.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line Three :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.remittance.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="remittance"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="remittance">
                                <div *ngIf="submitted && p.remittance.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.remittance.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-12">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;"> CREDIT APPRAISAL REPORT</h3>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> City:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Legal.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Legal" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Legal">
                                <div *ngIf="submitted && p.Legal.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Legal.errors.required "> Required Field</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> PIN Code:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Opinion.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Opinion" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Opinion">
                                <div *ngIf="submitted && p.Opinion.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Opinion.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Sate:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Report.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Report" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="Report">
                                <div *ngIf="submitted && p.Report.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Report.errors.required "> Required Field</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> As the nominee is minor on this date, I/ we Appoint
                                    Shri/Smt:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.existing.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="existing" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="existing">
                                <div *ngIf="submitted && p.existing.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.existing.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Age:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Renovation.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Renovation"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Renovation">
                                <div *ngIf="submitted && p.Renovation.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Renovation.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Years:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.Eligibility.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="Eligibility"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Eligibility">
                                <div *ngIf="submitted && p.Eligibility.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.Eligibility.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>








                        <div class="col-md-12">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Reference 1</h4>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Name:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referenceoneName.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referenceoneName"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="referenceoneName">
                                <div *ngIf="submitted && p.referenceoneName.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referenceoneName.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Relationship with Depositor:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referenceoneRelationship.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referenceoneRelationship"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="referenceoneRelationship">
                                <div *ngIf="submitted && p.referenceoneRelationship.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referenceoneRelationship.errors.required "> field
                                        is required</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line One :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referenceoneaddone.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referenceoneaddone"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="referenceoneaddone">
                                <div *ngIf="submitted && p.referenceoneaddone.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referenceoneaddone.errors.required "> field is
                                        required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line Two :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referenceoneaddtwo.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referenceoneaddtwo"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="referenceoneaddtwo">
                                <div *ngIf="submitted && p.referenceoneaddtwo.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referenceoneaddtwo.errors.required "> field is
                                        required</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">City :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referenceonecity.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referenceonecity"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="referenceonecity">
                                <div *ngIf="submitted && p.referenceonecity.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referenceonecity.errors.required "> field is
                                        required</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">PIN :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referenceonepin.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referenceonepin"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="referenceonepin">
                                <div *ngIf="submitted && p.referenceonepin.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referenceonepin.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Mobile :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referenceonemobile.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referenceonemobile"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="referenceonemobile">
                                <div *ngIf="submitted && p.referenceonemobile.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referenceonemobile.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Phone :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referenceonephone.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referenceonephone"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="referenceonephone">
                                <div *ngIf="submitted && p.referenceonephone.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referenceonephone.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Email ID :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referenceoneemail.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referenceoneemail"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="referenceoneemail">
                                <div *ngIf="submitted && p.referenceoneemail.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referenceoneemail.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="col-md-12">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Reference 2</h4>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Name:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referencetwoName.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referencetwoName"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="referencetwoName">
                                <div *ngIf="submitted && p.referencetwoName.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referencetwoName.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Relationship with Depositor:</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referencetwoRelationship.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referencetwoRelationship"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="referencetwoRelationship">
                                <div *ngIf="submitted && p.referencetwoRelationship.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referencetwoRelationship.errors.required "> field
                                        is required</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line One :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referencetwoaddone.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referencetwoaddone"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="referencetwoaddone">
                                <div *ngIf="submitted && p.referencetwoaddone.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referencetwoaddone.errors.required "> field is
                                        required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line Two :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referencetwoaddtwo.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referencetwoaddtwo"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="referencetwoaddtwo">
                                <div *ngIf="submitted && p.referencetwoaddtwo.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referencetwoaddtwo.errors.required "> field is
                                        required</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">City :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referencetwocity.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referencetwocity"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="referencetwocity">
                                <div *ngIf="submitted && p.referencetwocity.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referencetwocity.errors.required "> field is
                                        required</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">PIN :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referencetwopin.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referencetwopin"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="referencetwopin">
                                <div *ngIf="submitted && p.referencetwopin.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referencetwopin.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Mobile :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referencetwomobile.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referencetwomobile"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="referencetwomobile">
                                <div *ngIf="submitted && p.referencetwomobile.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referencetwomobile.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Phone :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referencetwophone.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referencetwophone"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="referencetwophone">
                                <div *ngIf="submitted && p.referencetwophone.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referencetwophone.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Email ID :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.referencetwoemail.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="referencetwoemail"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="referencetwoemail">
                                <div *ngIf="submitted && p.referencetwoemail.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.referencetwoemail.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Preferred Delivery mode :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.PreferredDeliverymode.errors }"
                                    (keyup.enter)="projectIn(stepper) " formControlName="PreferredDeliverymode"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="PreferredDeliverymode" placeholder="Mail / EMail / Both">
                                <div *ngIf="submitted && p.PreferredDeliverymode.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.PreferredDeliverymode.errors.required "> field is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Preferred Mailing Address :</label>
                                <input [ngClass]="{ 'is-invalid': submitted && p.PreferredMailingAddress.errors }"
                                    placeholder="Resident / Office" (keyup.enter)="projectIn(stepper) "
                                    formControlName="PreferredMailingAddress" class="form-control " type="text "
                                    id="inputbutton " [(ngModel)]="PreferredMailingAddress">
                                <div *ngIf="submitted && p.PreferredMailingAddress.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="p.PreferredMailingAddress.errors.required "> field
                                        is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>











                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row" *ngIf="role!='1008'">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-1 row ">

                                <input class="form-control" type="checkbox" [checked]="SendCopytoSP"
                                    formControlName="SendCopytoSP" [(ngModel)]="SendCopytoSP" style="font-size: 7px;">
                            </div>
                            <div class="col-md-5 row ">
                                <p style="font-size: 20px;color:darkblue">Send one copy to {{serviceprovidername}}</p>

                            </div>
                        </div>
                    </form>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row">
                        <div class="col-md-4 row">&nbsp;</div>
                        <div class="col-md-3 row">
                            <button  id="signinbutton">Draft</button> &nbsp; &nbsp;
                            &nbsp;

                        </div>
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-3 row">
                            <button (click)="projectIn(stepper)" id="signinbutton">Next</button>
                        </div>

                    </div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                </div>
            </div>
        </div>
    </mat-step>


    <mat-step label="Step 6" state="upload" style="text-align: center; height: 96%; width: 136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center "
                    style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                    <div *ngIf="certificatesexist">
                        <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="col-md-12 row">
                            <div class="col-md-1 row">&nbsp;</div>

                            <div class="col-md-2 row" *ngFor="let item of CertificateDisplay">
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'image/jpeg' ||
                             item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                    <img [src]="item.certificate" height="100" width="100" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/pdf' ||
                             item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                    <img src="assets/img/pdficon.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                             item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                    <img src="assets/img/xls.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                             item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                    <img src="assets/img/docx.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>

                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                        <div *ngIf="documentArray.length!=0">
                            <div class="col-md-12 row">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Uploaded Documents</h4>

                            <div class="col-md-12 row">
                                <div class="col-md-1 row">&nbsp;</div>

                                <div class="col-md-2 row" *ngFor="let item of certificates">
                                    <div *ngIf="item.actualCertificateName!=null  && (item.documentType == 'image/jpeg' ||
                             item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                        <img [src]="item.certificate" height="100" width="100" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/pdf' ||
                             item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                        <img src="assets/img/pdficon.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                             item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/xls.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                             item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/docx.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                        <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Upload Certificates</h2>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>

                    <div class="col-md-12 row">
                        <div class="col-md-3">&nbsp;</div>
                        <div class="col-md-9">
                            <div class="table-responsive" style="overflow-x: visible">

                                <table class="table table-striped table-responsive table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Certificate Name</th>
                                            <th>Upload Here</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let field of CertificateList; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{field.certificateName}}</td>
                                            <td>
                                                <input type="file" name="avatars" #fileField
                                                    (change)="onChangeCertificates($event,field)">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Documents</h2>
                                <table class="table table-striped table-responsive table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Document Name</th>
                                            <th>Document</th>

                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let field of documentArray; let i = index">

                                            <td>
                                                <input [(ngModel)]="field.otherdocumentname" class="form-control"
                                                    type="text" name="{{field.otherdocumentname}}" />
                                            </td>
                                            <td>
                                                <input [(ngModel)]="field.otherdocactualname" class="form-control"
                                                    type="text" name="{{field.otherdocactualname}}" />
                                            </td>

                                            <td>
                                                <button class="btn btn-default" type="button"
                                                    (click)="deleteFieldValueDocument(i)">Delete</button>
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>
                                                <input class="form-control" type="text" id="otherdocumentname"
                                                    [(ngModel)]="newDocAttribute.otherdocumentname"
                                                    name="otherdocumentname" />

                                            </td>
                                            <td>
                                                <input class="form-control" type="file" id="otherdocument"
                                                    (change)="OnChangeOtherDocs($event)" name="otherdocument" />
                                            </td>


                                            <td>
                                                <button class="btn btn-default" type="button"
                                                    (click)="addDocFieldValue()">Add</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <!-- <div class="col-md-12 row">
                        <div class="col-md-3 row">&nbsp;</div>
                        <div class="col-md-2 row">
                            <button (click)="uploadCertificatesDraft(stepper1)" id="signinbutton">Draft</button> &nbsp;
                            &nbsp; &nbsp;

                        </div>
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-2 row">
                            <button (click)="uploadCertificates(stepper1)" id="signinbutton">Upload</button>
                        </div>
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-2 row">
                            <button (click)="previewloandetails()" id="signinbutton">Preview</button>
                        </div>
                    </div> -->
                    <div class="col-md-12 row">
                        <div class="col-md-3 row">&nbsp;</div>

                        <div class="col-md-4">
                            <button (click)="uploadCertificatesDraft()" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;
                        </div>
                        <div class="col-md-4">
                            <button (click)="uploadCertificates()" id="signinbutton">Upload</button>
                        </div>

                        <div class="col-md-1 row">
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row">
                        <div class="col-md-3 row">&nbsp;</div>
                       
                        <div class="col-md-4">
                            <button (click)="previewloandetails()" id="signinbutton">Preview</button>
                        </div>
                        <div class="col-md-4" *ngIf = "showsubmitbtn">
                            <button style="padding-bottom: 6%;"  (click)="submitloanform()" id="signinbutton">Submit</button>
                        </div>

                        <div class="col-md-1 row">
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                </div>
            </div>
        </div>
    </mat-step>

    <!-- Icon overrides. -->
    <!-- #docregion override-icons -->
    <ng-template matStepperIcon="details">
        <mat-icon>account_circle</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="family">
        <mat-icon>family_restroom</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="account">
        <mat-icon>account_balance</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="homei">
        <mat-icon>home</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="project">
        <mat-icon>content_copy</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="upload">
        <mat-icon>cloud_upload</mat-icon>
    </ng-template>

</mat-horizontal-stepper>
<br /><br />

<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info"
            style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 500px;width: 350px;">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <i class="material-icons" style="color: white">close</i>
            </button>
            <span style="color: white;margin-top: 6px;">
                <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
        </div>
    </div>
</div>