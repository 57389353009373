<app-header>
</app-header>
<br/>
<br/>
<br/>


<div class="container">
    <div class="col-lg-12 table-bordered" style="padding-top:15px;border-width:4px;border-color: black;font-size:12px;">
        <div class="col-lg-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: black;">
            <h3 style="color:rgb(204, 29, 29);"><b>Identification</b></h3>
            <div class="form-group">

                <div class="row">
                    <div class=" col-md-3 grid_box1">
                        <br />    <span><b>Customer ID</b></span>
                    </div>
                    <div class="col-md-3">
                        <input type="text" class="form-control" name="spouse" [(ngModel)]="customerid">

                    </div>
                    <div class=" col-md-3 grid_box1">
                        <br />    <span><b>Customer Name</b></span>
                    </div>
                    <div class="col-md-3">
                        <input type="text" class="form-control" name="spouse" [(ngModel)]="customername">

                    </div>
                    <div class="clearfix"> </div>
                </div>

                <div class="row">
                    <div class=" col-md-3 grid_box1">
                        <br />    <span><b>CAPS Collateral Reference</b></span>
                    </div>
                    <div class="col-md-3">
                        <input type="text" class="form-control" name="spouse" [(ngModel)]="capsId">

                    </div>
                    <div class=" col-md-3 grid_box1">

                        <br />  <span><b>Owner/ Guarantor Name</b></span>
                    </div>
                    <div class="col-md-3">

                        <select (change)="onSelectOwner($event.target.value)" class="form-control">
                            <option value="">Select</option>
                            <option *ngFor="let do of CB" value="{{do.collateralBelongs}}" [selected]="do.collateralBelongs == selectedowner">
                                {{do.collateralBelongs}}
                            </option>
                        </select>
                    </div>
                    <div class="clearfix"> </div>
                </div>
                <div class="row">
                    <div class=" col-md-3 grid_box1">
                        <br />    <span><b>GLCMS Collateral ID</b></span>
                    </div>
                    <div class="col-md-3">
                        <input type="text" class="form-control" name="spouse" [(ngModel)]="glcmsid">

                    </div>
                    <div class=" col-md-3 grid_box1">

                        <br />  <span><b>ALGO Collateral ID</b></span>
                    </div>
                    <div class="col-md-3">
                        <input type="text" class="form-control" name="spouse" [(ngModel)]="algoid" >
                    </div>
                    <div class="clearfix"> </div>
                </div>
                <div class="row">
                    <div class=" col-md-3 grid_box1">
                        <br />    <span><b>Collateral Category</b></span>
                    </div>
                    <div class="col-md-3">

                        <select (change)="onSelectcategory($event.target.value)" class="form-control">
                            <option class="option" value="">Select</option>
                            <option class="option" *ngFor="let do of category" value="{{do.id}}"> 
                              {{do.collateralCategory}}
                            </option>
                          </select>

                    </div>
                    <div class=" col-md-3 grid_box1">

                        <br />  <span><b>Collateral Type</b></span>
                    </div>
                    <div class="col-md-3">
                        <select (change)="onSelectType($event.target.value)" class="form-control">
                            <option class="option" value="">Select</option>
                            <option class="option" *ngFor="let do of categorytype" value="{{do.collateralType}}" [selected]="do.collateralType == selectedtype">
                              {{do.collateralType}}
                            </option>
                          </select>

                    </div>
                    <div class="clearfix"> </div>
                </div>
                <div class="row">
                    <div class=" col-md-3 grid_box1">
                        <br />    <span><b>Asset Location</b></span>
                    </div>
                    <div class="col-md-3">
                        <input type="text" class="form-control" name="spouse" [(ngModel)]="assectLocation" >
                    </div>
                    <div class=" col-md-3 grid_box1">
                        <br />  <span><b>Collateral Nature</b></span>
                    </div>
                    <div class="col-md-3">
                        <select (change)="onSelectcollNature($event.target.value)" class="form-control">
                            <option class="option" value="">Select</option>
                            <option class="option" *ngFor="let do of collnatu" value="{{do.collateralNature}}" [selected]="do.collateralNature == selectedcolnature">
                              {{do.collateralNature}}
                            </option>
                          </select>


                    </div>
                    <div class="clearfix"> </div>
                </div>
                <div class="row">
                    <div class=" col-md-3 grid_box1">
                        <br />    <span><b>Eligibility Flag</b></span>
                    </div>
                    <div class="col-md-3" style="text-align:center">
                        <input type="checkbox" style="width :50px;" name="spouse" [(ngModel)]="eligibilty">

                    </div>

                    <div class="clearfix"> </div>
                </div>
            </div>
        </div>
        <div>&nbsp;</div>

        <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;" *ngIf="selectedcategory== 1 "> 
            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Description</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-12 grid_box1">
                            <textarea class="form-control" name="residencialaddress" [(ngModel)]="description"> </textarea>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>
            <div>&nbsp;</div>

            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Guarantee Information</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Is Revocable</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="checkbox" style="width :50px;" [(ngModel)]="revocable" />
                        </div>
                        <div class=" col-md-3 grid_box1">
                            <br />  <span><b>Is Pair Passu</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="checkbox" style="width :50px;" [(ngModel)]="pairPassu" />
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Is Confirmed</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="checkbox" style="width :50px;" [(ngModel)]="confirmed" />
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Proceeds Receiving Accounts</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="checkbox" style="width :50px;" [(ngModel)]="receivingAccount" />
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>
            <div>&nbsp;</div>
            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Valuation</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Sanction Collateral Value</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="sanctionValue">
                        </div>
                        <div class="col-md-2">
                            <select (change)="onSelectCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == sccurrency">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Valuation Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="valuationDate">

                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Valuation Frequency</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="valuationFreq">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Frequency Unit</b></span>
                        </div>
                        <div class="col-md-3">

                            <select (change)="onSelectFrequencyUnit($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" value="Days">Days</option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Margining Frequency</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="marginingFreq">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Margining Frequency Unit</b></span>
                        </div>
                        <div class="col-md-3">
                            <select (change)="onSelectFrequencyUnit($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" value="Days">Days</option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>

            <div>&nbsp;</div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                <div class="row" align="center">
                    <br />
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label for="middlename">Enter Comment</label>
                        <textarea type="text" class="form-control" name="middlename" placeholder="Leave Your Comments Here" style="height:150px" [(ngModel)]="comment" autofocus></textarea>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class=" col-md-4 grid_box1">
                </div>
                <div class="col-md-4" style="text-align:center">
                    <br /><br /> <button type="submit" class="btn btn-success" style="text-align:center;font-size:medium;" (click)="SaveCollateralAssignment()">Save</button>
                </div>
                <div class=" col-md-4 grid_box1">
                </div>
                <div class="clearfix"> </div>
            </div>

        </div>

        <div>&nbsp;</div>

        <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;" *ngIf="selectedcategory== 3 || selectedcategory== 12 || selectedcategory== 13">

            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Description</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-12 grid_box1">
                            <textarea class="form-control" name="residencialaddress" [(ngModel)]="description"> </textarea>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>

            <div>&nbsp;</div>

            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Guarantee Information</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Revocability Flag</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="checkbox" style="width :50px;" [(ngModel)]="revoflag" />
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Is Limited</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="checkbox" style="width :50px;" [(ngModel)]="islimited" />
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Project Employer Name</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="pename">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Guarantor Type</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="guarantype">
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>FAB ORR</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="fab">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Rating Agency</b></span>
                        </div>
                        <div class="col-md-3">
                            <select class="form-control"  (change)="onSelectRatAgency($event)" >
                                <option selected="selected" value="">Select</option>
                                <option selected="selected" value="CRISIL">CRISIL</option>
                            </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Rating Type</b></span>
                        </div>
                        <div class="col-md-3">
                            <select class="form-control"  (change)="onSelectRatType($event)" >
                                <option selected="selected" value="">Select</option>
                                <option selected="selected" value="Short Term">Short Term</option>
                            </select>
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Rating Value</b></span>
                        </div>
                        <div class="col-md-3">
                            <select class="form-control" (change)="onSelectRatVal($event)"  >
                                <option selected="selected" value="">Select</option>
                                <option selected="selected" value="AAA">AAA</option>
                                <option selected="selected" value="A++">A++</option>
                            </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />  <span><b>Date of Estimation</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="doe">
                        </div>
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Net Worth</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="networth">
                        </div>
                        <div class="col-md-2">

                            <select (change)="onSelectCurrNetWorth($event)" class="form-control">
                                <option class="option" value="">Select Currency</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == nwcurrency">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                    <div class="row">
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Relationship With Borrower</b></span>
                        </div>
                        <div class="col-md-3">
                            <select class="form-control"  (change)="onSelectBorrower($event.target.value)"   name="membertype" >
                                <option selected="selected" value="">Select</option>
                                <option selected="selected" value="Father">Father</option>
                                <option selected="selected" value="Mother">Mother</option>

                            </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>

            <div>&nbsp;</div>

            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Valuation</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Sanction Collateral Value</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="scValue">
                        </div>
                        <div class="col-md-2">
                            <select (change)="onSelectCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == sccurrency">
                                  {{do.currency}}
                                </option>
                              </select>


                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Valuation Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="valuationDate">
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Valuation Frequency</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="valuationFreq">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Frequency Unit</b></span>
                        </div>
                        <div class="col-md-3">
                            <select (change)="onSelectFrequencyUnit($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" value="Days">Days</option>
                              </select>
                            
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Margining Frequency</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="marginfreq">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Margining Frequency Unit</b></span>
                        </div>
                        <div class="col-md-3">
                            <select (change)="onSelectFrequencyUnit($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" value="Days">Days</option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-2 grid_box1">
                            <span><b>Valuation after haircut</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="valuehaircut">
                        </div>
                        <div class="col-md-2">
                            <select (change)="onSelectVAHCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == vhccurrance">
                                  {{do.currency}}
                                </option>
                              </select>

                        </div>
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Valuation Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="valudate">
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Maturity Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="matudate">
                        </div>
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Guarantee Amount</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="guarnteeamount">
                        </div>
                        <div class="col-md-2">

                            <select (change)="onSelectCurrAmt($event)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == gacurrance">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />   <span><b>Change Type</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="chargetype">
                        </div>
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Change Value</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="chargevalue">
                        </div>
                        <div class="col-md-2">

                            <select (change)="onSelectCurrChangeValue($event)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == cvcurrance">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>
            <div>&nbsp;</div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                <div class="row" align="center">
                    <br />
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label for="middlename">Enter Comment</label>
                        <textarea type="text" class="form-control" name="middlename" placeholder="Leave Your Comments Here" style="height:150px" [(ngModel)]="comment" autofocus></textarea>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class=" col-md-4 grid_box1">
                </div>
                <div class="col-md-4" style="text-align:center">
                    <br /><br /> <button type="submit" class="btn btn-success" style="text-align:center;font-size:medium;" (click)="SaveCollateralBankGuarantee()">Save</button>
                </div>
                <div class=" col-md-4 grid_box1">
                </div>
                <div class="clearfix"> </div>
            </div>
        </div>

        <div>&nbsp;</div>

        <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;" *ngIf="selectedcategory== 4 || selectedcategory==5 || selectedcategory==7 || selectedcategory==8 ||  selectedcategory==14 ">
            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Description</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-12 grid_box1">
                            <textarea class="form-control" name="residencialaddress" [(ngModel)]="description"> </textarea>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>

            <div>&nbsp;</div>
            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Valuation</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Sanction Collateral Value</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="scvalue">
                        </div>
                        <div class="col-md-2">

                            <select (change)="onSelectCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == sccurrency">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Valuation Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="scvaldat">
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Valuation Frequency</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="valFreq">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Frequency Unit</b></span>
                        </div>
                        <div class="col-md-3">
                            <select (change)="onSelectFrequencyUnit($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" value="Days">Days</option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Margining Frequency</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="MargiFreq">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Margining Frequency Unit</b></span>
                        </div>
                        <div class="col-md-3">

                            <select (change)="onSelectFrequencyUnit($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" value="Days">Days</option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-2 grid_box1">
                            <span><b>Valuation after haircut</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="vahcut">
                        </div>
                        <div class="col-md-2">
                            <select (change)="onSelectVAHCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == vhccurrance">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Valuation Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="valudate">
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Maturity Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="matudate">
                        </div>
                        <div class=" col-md-3 grid_box1">
                            <br />   <span><b>Change Type</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="chaType">
                        </div>

                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Change Value</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="ChangValue">
                        </div>
                        <div class="col-md-2">

                            <select (change)="onSelectCurrChangeValue($event)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == cvcurrance">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>
            <div>&nbsp;</div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                <div class="row" align="center">
                    <br />
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label for="middlename">Enter Comment</label>
                        <textarea type="text" class="form-control" name="middlename" placeholder="Leave Your Comments Here" style="height:150px" [(ngModel)]="comment" autofocus></textarea>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class=" col-md-4 grid_box1">
                </div>
                <div class="col-md-4" style="text-align:center">
                    <br /><br /> <button type="submit" class="btn btn-success" style="text-align:center;font-size:medium;" (click)="SaveCollateralCashMagin()">Save</button>
                </div>
                <div class=" col-md-4 grid_box1">
                </div>
                <div class="clearfix"> </div>
            </div>
        </div>

        <div>&nbsp;</div>
        <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;" *ngIf="selectedcategory== 9 ">
            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Description</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-12 grid_box1">
                            <textarea class="form-control" name="residencialaddress" [(ngModel)]="description"> </textarea>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>


            <div>&nbsp;</div>
            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Lien Information</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Account with Bank</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="checkbox" name="spouse" [(ngModel)]="accnum">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Account Classification</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="accclass">
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>FD Maturity Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="FDMdate">
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>

            <div>&nbsp;</div>
            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Valuation</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Sanction Collateral Value</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="scvalue">
                        </div>
                        <div class="col-md-2">
                            <select (change)="onSelectCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == sccurrency">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Valuation Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="scvaldat">
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Valuation Frequency</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="valFreq">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Frequency Unit</b></span>
                        </div>
                        <div class="col-md-3">
                            <select (change)="onSelectFrequencyUnit($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" value="Days">Days</option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Margining Frequency</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="MargiFreq">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Margining Frequency Unit</b></span>
                        </div>
                        <div class="col-md-3">

                            <select (change)="onSelectFrequencyUnit($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" value="Days">Days</option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-2 grid_box1">
                            <span><b>Valuation after haircut</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="vahcut">
                        </div>
                        <div class="col-md-2">

                            <select (change)="onSelectVAHCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == vhccurrance">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Valuation Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="vahcdate">
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Maturity Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="matudate">
                        </div>
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Guarantee Amount</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="Guaranteeammount">
                        </div>
                        <div class="col-md-2">

                            <select (change)="onSelectCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == sccurrency">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />   <span><b>Change Type</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="chaType">
                        </div>
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Change Value</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="ChangValue">
                        </div>
                        <div class="col-md-2">
                            <select (change)="onSelectCurrChangeValue($event)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == cvcurrance">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>
            <div>&nbsp;</div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                <div class="row" align="center">
                    <br />
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label for="middlename">Enter Comment</label>
                        <textarea type="text" class="form-control" name="middlename" placeholder="Leave Your Comments Here" style="height:150px" [(ngModel)]="comment" autofocus></textarea>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class=" col-md-4 grid_box1">
                </div>
                <div class="col-md-4" style="text-align:center">
                    <br /><br /> <button type="submit" class="btn btn-success" style="text-align:center;font-size:medium;" (click)="SaveCollateralLien()">Save</button>
                </div>
                <div class=" col-md-4 grid_box1">
                </div>
                <div class="clearfix"> </div>
            </div>
        </div>

        <div>&nbsp;</div>

        <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;" *ngIf="selectedcategory== 10 || selectedcategory== 11">

            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Description</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-12 grid_box1">
                            <textarea class="form-control" name="residencialaddress" [(ngModel)]="description"> </textarea>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>

            <div>&nbsp;</div>

            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Mortgage Information</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Borrower Is the owner of the Asset</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="ownornot">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Charge Level Indicator</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="chageindicato">
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Registered or Notarised</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="regornot">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Mortgage Property Type</b></span>
                        </div>
                        <div class="col-md-3">
                            <select class="form-control" name="membertype" (change)="onSelectMortgage($event.target.value)">
                                <option selected="selected" value="">Select</option>
                                <option selected="selected" value="House">House</option>
                            </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>

            <div>&nbsp;</div>

            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Valuation</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Sanction Collateral Value</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="scvalue">
                        </div>
                        <div class="col-md-2">
                            <select (change)="onSelectCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == sccurrency">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Valuation Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="scvaldat">
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Valuation Frequency</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="valFreq">
                        </div>
                        <div class=" col-md-3 grid_box1">
                            <br />  <span><b>Frequency Unit</b></span>
                        </div>
                        <div class="col-md-3">

                            <select (change)="onSelectFrequencyUnit($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" value="Days">Days</option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Margining Frequency</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="MargiFreq">
                        </div>
                        <div class=" col-md-3 grid_box1">
                            <br />  <span><b>Margining Frequency Unit</b></span>
                        </div>
                        <div class="col-md-3">
                            <select (change)="onSelectFrequencyUnit($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" value="Days">Days</option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-2 grid_box1">
                            <br />   <span><b>Valuation after haircut</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="vahcut">
                        </div>
                        <div class="col-md-2">
                            <select (change)="onSelectVAHCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == vhccurrance">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Valuation Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="vahcdate">
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Maturity Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="matudate">
                        </div>
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Revaluation Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="revadate">
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Valued By</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="valueby">
                        </div>
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Market Value</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="marketvalue">
                        </div>
                        <div class="col-md-2">
                            <select (change)="onSelectMVCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == mvccurr">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Revaluation Percent</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="reavaper">
                        </div>
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Change Value</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="ChangValue">
                        </div>
                        <div class="col-md-2">

                            <select (change)="onSelectCurrChangeValue($event)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == cvcurrance">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Forced Sale Value</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="forcedsalval">
                        </div>
                        <div class="col-md-2">

                            <select (change)="onSelectFSVCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == fsvcurr">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class=" col-md-3 grid_box1">
                            <br />   <span><b>Change Type</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="chaType">
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>
            <div>&nbsp;</div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                <div class="row" align="center">
                    <br />
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label for="middlename">Enter Comment</label>
                        <textarea type="text" class="form-control" name="middlename" placeholder="Leave Your Comments Here" style="height:150px" [(ngModel)]="comment" autofocus></textarea>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class=" col-md-4 grid_box1">
                </div>
                <div class="col-md-4" style="text-align:center">
                    <br /><br /> <button type="submit" class="btn btn-success" style="text-align:center;font-size:medium;" (click)="SaveCollateralMorgage()">Save</button>
                </div>
                <div class=" col-md-4 grid_box1">
                </div>
                <div class="clearfix"> </div>
            </div>
        </div>

        <div>&nbsp;</div>

        <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;" *ngIf="selectedcategory== 6 ">
            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Description</b></h3>

            </div>
            <div>&nbsp;</div>
            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #020202;">
                <h3 style="color:rgb(204, 29, 29);"><b>Financial Trade Accounts</b></h3>
            </div>
            <div>&nbsp;</div>
            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Financial Instrument Securities</b></h3>
            </div>
            <div>&nbsp;</div>
            <div id="printsection" class="col-md-12 table-bordered" style="padding-top:15px;border-width:2px;border-color: #000000;">
                <h3 style="color:rgb(204, 29, 29);"><b>Valuation</b></h3>
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Sanction Collateral Value</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="scvalue">
                        </div>
                        <div class="col-md-2">
                            <select (change)="onSelectCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == sccurrency">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Valuation Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="scvaldat">
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Valuation Frequency</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="valuationFreq">
                        </div>
                        <div class=" col-md-3 grid_box1">

                            <br />  <span><b>Frequency Unit</b></span>
                        </div>
                        <div class="col-md-3">

                            <select (change)="onSelectFrequencyUnit($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" value="Days">Days</option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Margining Frequency</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="MargiFreq">
                        </div>
                        <div class=" col-md-3 grid_box1">
                            <br />  <span><b>Margining Frequency Unit</b></span>
                        </div>
                        <div class="col-md-3">

                            <select (change)="onSelectFrequencyUnit($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" value="Days">Days</option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-2 grid_box1">
                            <br />   <span><b>Valuation after haircut</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="vahcut">
                        </div>
                        <div class="col-md-2">

                            <select (change)="onSelectVAHCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == vhccurrance">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Valuation Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="date" class="form-control" name="spouse" [(ngModel)]="vahcdate">
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />    <span><b>Maturity Date</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="spouse">
                        </div>
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Guarantee Amount</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="spouse">
                        </div>
                        <div class="col-md-2">

                            <select (change)="onSelectCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == sccurrency">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>

                    <div class="row">
                        <div class=" col-md-3 grid_box1">
                            <br />   <span><b>Change Type</b></span>
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="spouse">
                        </div>
                        <div class=" col-md-2 grid_box1">
                            <br />    <span><b>Change Value</b></span>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" name="spouse" [(ngModel)]="spouse">
                        </div>
                        <div class="col-md-2">

                            <select (change)="onSelectCurrency($event.target.value)" class="form-control">
                                <option class="option" value="">Select</option>
                                <option class="option" *ngFor="let do of curr" value="{{do.currency}}" [selected]="do.currency == sccurrency">
                                  {{do.currency}}
                                </option>
                              </select>
                        </div>
                        <div class="clearfix"> </div>
                    </div>
                </div>
            </div>
            <div>&nbsp;</div>
        </div> 

        <div>&nbsp;</div>
    </div>
</div>
