import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

declare var MediaRecorder: any;
declare var Peer: any;
import * as RecordRTC from 'recordrtc';
@Component({
  selector: 'app-videokyc',
  templateUrl: './videokyc.component.html',
  styleUrls: ['./videokyc.component.css']
})
export class VideokycComponent implements OnInit {
  @ViewChild('webcamVideo') webcamVideo: ElementRef;

  private stream: MediaStream;
  private recordRTC: any;
  downloadUrl: string;
  convert1: any;
  videoBuffer: Blob;
  makevideokyc:Boolean=true;
  makerecord:Boolean=false;
  recording: boolean = false;
  countdown: number = 60;
  ngOnInit(): void {}

  async startWebcam(): Promise<void> {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      const videoElement = this.webcamVideo.nativeElement as HTMLVideoElement;

      if (videoElement) {
        videoElement.srcObject = this.stream;
        this.recordRTC = RecordRTC(this.stream, { type: 'video' });
        this.makevideokyc = false;
        this.makerecord = true;
      } else {
        console.error('Video element not found.');
      }
    } catch (error) {
      console.error('Error accessing webcam:', error);
    }
  }

  startRecording(): void {
    if (this.recordRTC) {
      this.recordRTC.startRecording();

      
  
      // Set an interval to update the countdown timer every second
      const countdownInterval = setInterval(() => {
        this.recording=true;
        this.countdown--;
  
        if (this.countdown <= 0) {
          clearInterval(countdownInterval);
          this.stopRecording();
          this.stopWebcam();
        }
      }, 1000);
  
      // Set a timeout to stop the interval after the desired duration (e.g., 60 seconds)
      setTimeout(() => {
        clearInterval(countdownInterval);
      }, 60000); // Adjust the duration in milliseconds as needed
    }
  }

  stopRecording(): void {
    if (this.recordRTC) {
      this.recordRTC.stopRecording(() => {
        const blob = this.recordRTC.getBlob();


        this.downloadUrl = window.URL.createObjectURL(blob); // you can download with <a> tag
       
      this.convert1=blob;
        this.saveVideo(blob);
      });
    }
  }

  stopWebcam(): void {
    if (this.stream) {
      const tracks = this.stream.getTracks();
      tracks.forEach(track => track.stop());
    }
  }

  saveVideo(blob: Blob): void {
    // Implement the logic to save the recorded video
    // For example, you can send the blob to a server using HttpClient
  }
}