<br />
<br />
<br />
<br />
<br />
<div style=" justify-content: center;font-family: DIN Next Rounded LT Pro;">
    <div style="width: 100%;height: 80%;">
        <div id="signin" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%"
            (window:resize)="onResize($event)">
            <form class="register-form" [formGroup]="signupForm">
                <fieldset id="container">
                    <legend align="center" id="capageid">USER REGISTRATION</legend>
                    <br />
                    <label for="Email" id="emaillabel">Email Address</label>
                    <input formControlName="email" class="form-control" type="text" id="inputbutton"
                        [(ngModel)]="email">
                    <br />
                    <label for="FirstName" id="emaillabel">First Name</label>
                    <input formControlName="firstname" class="form-control" type="text" id="inputbutton">
                    <br />
                    <label for="LastName" id="emaillabel">Last Name</label>
                    <input formControlName="lastname" class="form-control" type="text" id="inputbutton">
                    <br />
                    <br />
                    <br />
                    <br />
                </fieldset>
            </form>
            <br>
            <p style="width: 234px;height: 21px; font-size: 20px;font-weight: 500;
                font-stretch: normal;font-style: normal;text-align: center;
                cursor: pointer;  font-family: DIN Next Rounded LT Pro; line-height: 18px;
                letter-spacing: 0.15em;color: #c60c0c;outline-color: #FFFFFF;outline:none;">NEXT>
            </p>
        </div>
        <br />
        <br />
        <br />
    </div>
</div>