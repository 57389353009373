import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CollateralformService } from 'src/app/Shared/CollateralForm/collateralform.service';

@Component({
  selector: 'app-collateraldetails',
  templateUrl: './collateraldetails.component.html',
  styleUrls: ['./collateraldetails.component.css']
})
export class CollateraldetailsComponent implements OnInit {
    CollAssignList: any;
    roleid: string;
    uid: string;
    username: string;
    CollAssignverify: any;
    cid: any;
    CollBankList: any;
    customerID: any;
    CustomerName: any;
    CAPSCollateralReference: any;
    OwnerName: any;
    GLCMSCollateralID: any;
    ALGOCollateralID: any;
    CollateralCategory: any;
    CollateralType: any;
    AssetLocation: any;
    CollateralNature: any;
    EligibilityFlag: any;
    Description: any;
    IsRevocable: any;
    IsPairPassu: any;
    IsConfirmed: any;
    ProceedsReceivingAccounts: any;
    SanctionCollateralValue: any;
    ValuationDate: any;
    ValuationFrequency: any;
    FrequencyUnit: any;
    MarginingFrequency: any;
    MarginingFrequencyUnit: any;
    WorkflowState: any;
    EnterComment: any;
    collAssignIDD:any;
    workflwid: string;
    workflowidfrommail: string;
    baid: string;
    detailss: string;
    customerid: any;
    transid: any;
    mailid: any;
    mailticketid: any;
    workflowid: any;
    userdetailsfor: string;
    ticketId: string;
    RevocabilityFlag: any;
    IsLimited: any;
    ChangeValue: any;
    ProjectEmployerName: any;
    GuarantorType: any;
    FABORR: any;
    RatingAgency: any;
    RatingType: any;
    RatingValue: any;
    DateofEstimation: any;
    RelationshipWithBorrower: any;
    Valuationafterhaircut: any;
    MaturityDate: any;
    GuaranteeAmount: any;
    ChangeType: any;
    AccountwithBank: any;
    AccountClassification: any;
    BorrowerIstheowneroftheAsset: any;
    ChargeLevelIndicator: any;
    RegisteredorNotarised: any;
    MortgagePropertyType: any;
    RevaluationDate: any;
    ValuedBy: any;
    MarketValue: any;
    RevaluationPercent: any;
    ForcedSaleValue: any;
  
    constructor(private collateralService: CollateralformService,private router:ActivatedRoute) {
  
      this.router.params.subscribe((data:any)=>{
        if(data){
          this.cid = data['id']
        }
      })
      if (localStorage.getItem("IsLoggedIn") == "true") {
        this.detailss = localStorage.getItem("custidwithmailid");
        this.roleid = localStorage.getItem("role");
        this.uid = localStorage.getItem("userId");
        this.username = localStorage.getItem("fullname");
        this.ticketId=localStorage.getItem("ticketidfrommail");
      }
     }
  
    ngOnInit() {
      this.GetCollateralAssignment();
      this.workflwid = localStorage.getItem("workflowid");
      this.workflowidfrommail=this.workflwid
      this.baid = localStorage.getItem("baid");
      this.detailss = localStorage.getItem("custidwithmailid");
      var aa = this.detailss.split('-');
    
      this.customerid = aa[0];
    
      this.transid = aa[1];
      this.mailid = aa[2];
      this.mailticketid = aa[3];
      this.workflowid = aa[4];
      // if(this.workflowidfrommail=="1008"){
      //   this.mailticketid = localStorage.getItem("ProspectIdtoDisplydata");
      // }
     // this.GetCollateralBankGuarantee()
    }
  
  
    GetCollateralAssignment(){
      this.userdetailsfor = this.cid + ',' + this.ticketId;
      this.collateralService.getcollateralassignment(this.userdetailsfor).subscribe((data: any) => {
        this.CollAssignList = data;
        console.log(data);
        this.collAssignIDD=this.CollAssignList.collAssignIDD
        if(this.collAssignIDD == 1){
          this.customerID = this.CollAssignList.customerId;
          this.CustomerName = this.CollAssignList.customerName;
          this.CAPSCollateralReference = this.CollAssignList.capsCollateralReferance;
          this.OwnerName = this.CollAssignList.guarantorName;
          this.GLCMSCollateralID = this.CollAssignList.glcmsId;
          this.ALGOCollateralID = this.CollAssignList.algoId;
          this.CollateralCategory = this.CollAssignList.collateralCategoryId;
          this.CollateralType = this.CollAssignList.collateralTypeId;
          this.AssetLocation = this.CollAssignList.assectLocation;
          this.CollateralNature = this.CollAssignList.collateralNature;
          this.EligibilityFlag = this.CollAssignList.eligibilty;
          this.Description = this.CollAssignList.description;
          this.IsRevocable  = this.CollAssignList.isRevocable;
          this.IsPairPassu  = this.CollAssignList.isPairPassu;
          this.IsConfirmed = this.CollAssignList.isConfirmed;
          this.ProceedsReceivingAccounts = this.CollAssignList.proceedsReceivingAccount;
          this.SanctionCollateralValue = this.CollAssignList.sanctionCollateralValue;
          this.ValuationDate = this.CollAssignList.valuationDate;
          this.ValuationFrequency = this.CollAssignList.valuationFrequency;
          this.FrequencyUnit = this.CollAssignList.frequencyUnit;
          this.MarginingFrequency = this.CollAssignList.marginingFrequency;
          this.MarginingFrequencyUnit = this.CollAssignList.margininigFrequencyUnit;
          this.WorkflowState = this.CollAssignList.workflowState;
          this.EnterComment = this.CollAssignList.comment;
  
        } 
        else if(this.collAssignIDD == 2){
          this.customerID = this.CollAssignList.customerId;
          this.CustomerName = this.CollAssignList.customerName;
          this.CAPSCollateralReference = this.CollAssignList.capsCollateralReferance;
          this.OwnerName = this.CollAssignList.guarantorName;
          this.GLCMSCollateralID = this.CollAssignList.glcmsId;
          this.ALGOCollateralID = this.CollAssignList.algoId;
          this.CollateralCategory = this.CollAssignList.collateralCategoryId;
          this.CollateralType = this.CollAssignList.collateralTypeId;
          this.AssetLocation = this.CollAssignList.assectLocation;
          this.CollateralNature = this.CollAssignList.collateralNature;
          this.EligibilityFlag = this.CollAssignList.eligibilty;
          this.Description = this.CollAssignList.description;
          this.RevocabilityFlag = this.CollAssignList.revocabilityFlag;
          this.IsLimited = this.CollAssignList.isLimited;
          this.ProjectEmployerName = this.CollAssignList.projectEmploerName;
          this.GuarantorType = this.CollAssignList.guarantorType;
          this.FABORR = this.CollAssignList.faborr;
          this.RatingAgency = this.CollAssignList.ratingAgency;
          this.RatingType = this.CollAssignList.ratingType;
          this.RatingValue = this.CollAssignList.ratingValue;
          this.DateofEstimation = this.CollAssignList.dateofEsitimation;
         // this.NetWorth = this.CollAssignList.
          this.RelationshipWithBorrower = this.CollAssignList.relationshipwithBorrower;
          this.SanctionCollateralValue = this.CollAssignList.sanctionCollateralValue;
          this.ValuationDate = this.CollAssignList.valuationDate;
          this.ValuationFrequency = this.CollAssignList.valuationFrequency;
          this.FrequencyUnit = this.CollAssignList.frequencyUnit;
          this.MarginingFrequency = this.CollAssignList.marginingFrequency;
          this.MarginingFrequencyUnit = this.CollAssignList.margininigFrequencyUnit;
          this.Valuationafterhaircut = this.CollAssignList.valueAfterHaircut;
          this.ValuationDate = this.CollAssignList.valuationDate;
          this.MaturityDate = this.CollAssignList.maturitesDate;
          this.GuaranteeAmount = this.CollAssignList.guaranteedAmount;
          this.ChangeType = this.CollAssignList.chargeType;
          this.ChangeValue = this.CollAssignList.chargeValue;
          this.EnterComment = this.CollAssignList.comment;
        }
  
        else if(this.collAssignIDD == 3){
          this.customerID = this.CollAssignList.customerId;
          this.CustomerName = this.CollAssignList.customerName;
          this.CAPSCollateralReference = this.CollAssignList.capsCollateralReferance;
          this.OwnerName = this.CollAssignList.guarantorName;
          this.GLCMSCollateralID = this.CollAssignList.glcmsId;
          this.ALGOCollateralID = this.CollAssignList.algoId;
          this.CollateralCategory = this.CollAssignList.collateralCategoryId;
          this.CollateralType = this.CollAssignList.collateralTypeId;
          this.AssetLocation = this.CollAssignList.assectLocation;
          this.CollateralNature = this.CollAssignList.collateralNature;
          this.EligibilityFlag = this.CollAssignList.eligibilty;
          this.Description = this.CollAssignList.description;
          this.SanctionCollateralValue = this.CollAssignList.sanctionCollateralValue;
          this.ValuationDate = this.CollAssignList.valuationDate;
          this.ValuationFrequency = this.CollAssignList.valuationFrequency;
          this.FrequencyUnit = this.CollAssignList.frequencyUnit;
          this.MarginingFrequency = this.CollAssignList.marginingFrequency;
          this.MarginingFrequencyUnit = this.CollAssignList.margininigFrequencyUnit;
          this.Valuationafterhaircut = this.CollAssignList.valueAfterHaircut;
          this.ValuationDate = this.CollAssignList.valuationDate;
          this.MaturityDate = this.CollAssignList.maturitesDate;
          this.ChangeType = this.CollAssignList.chargeType;
          this.ChangeValue = this.CollAssignList.chargeValue;
          this.EnterComment = this.CollAssignList.comment;
        }
  
        else if(this.collAssignIDD == 4){
          this.customerID = this.CollAssignList.customerId;
          this.CustomerName = this.CollAssignList.customerName;
          this.CAPSCollateralReference = this.CollAssignList.capsCollateralReferance;
          this.OwnerName = this.CollAssignList.guarantorName;
          this.GLCMSCollateralID = this.CollAssignList.glcmsId;
          this.ALGOCollateralID = this.CollAssignList.algoId;
          this.CollateralCategory = this.CollAssignList.collateralCategoryId;
          this.CollateralType = this.CollAssignList.collateralTypeId;
          this.AssetLocation = this.CollAssignList.assectLocation;
          this.CollateralNature = this.CollAssignList.collateralNature;
          this.EligibilityFlag = this.CollAssignList.eligibilty;
          this.Description = this.CollAssignList.description;
          this.AccountwithBank = this.CollAssignList.accountwithBank;
          this.AccountClassification = this.CollAssignList.accountClasification;
          this.MaturityDate = this.CollAssignList.fdMaturityDate;
          this.SanctionCollateralValue = this.CollAssignList.sanctionCollateralValue;
          this.ValuationDate = this.CollAssignList.valuationDate;
          this.ValuationFrequency = this.CollAssignList.valuationFrequency;
          this.FrequencyUnit = this.CollAssignList.frequencyUnit;
          this.MarginingFrequency = this.CollAssignList.marginingFrequency;
          this.MarginingFrequencyUnit = this.CollAssignList.margininigFrequencyUnit;
          this.Valuationafterhaircut = this.CollAssignList.valueAfterHaircut;
          this.ValuationDate = this.CollAssignList.valuationDate;
          this.MaturityDate = this.CollAssignList.maturitesDate;
          this.GuaranteeAmount = this.CollAssignList.guaranteedAmount;
          this.ChangeType = this.CollAssignList.chargeType;
          this.ChangeValue = this.CollAssignList.chargeValue;
          this.EnterComment = this.CollAssignList.comment;
        }
  
        else if(this.collAssignIDD == 5){
          this.customerID = this.CollAssignList.customerId;
          this.CustomerName = this.CollAssignList.customerName;
          this.CAPSCollateralReference = this.CollAssignList.capsCollateralReferance;
          this.OwnerName = this.CollAssignList.guarantorName;
          this.GLCMSCollateralID = this.CollAssignList.glcmsId;
          this.ALGOCollateralID = this.CollAssignList.algoId;
          this.CollateralCategory = this.CollAssignList.collateralCategoryId;
          this.CollateralType = this.CollAssignList.collateralTypeId;
          this.AssetLocation = this.CollAssignList.assectLocation;
          this.CollateralNature = this.CollAssignList.collateralNature;
          this.EligibilityFlag = this.CollAssignList.eligibilty;
          this.Description = this.CollAssignList.description;
          this.BorrowerIstheowneroftheAsset = this.CollAssignList.borrowerisOwnerofassect;
          this.ChargeLevelIndicator = this.CollAssignList.chargeLevelIndicater;
          this.RegisteredorNotarised = this.CollAssignList.registerdornotarised;
          this.MortgagePropertyType = this.CollAssignList.mortagePropertyType;
          this.SanctionCollateralValue = this.CollAssignList.sanctionCollateralValue;
          this.ValuationDate = this.CollAssignList.valuationDate;
          this.ValuationFrequency = this.CollAssignList.valuationFrequency;
          this.FrequencyUnit = this.CollAssignList.frequencyUnit;
          this.MarginingFrequency = this.CollAssignList.marginingFrequency;
          this.MarginingFrequencyUnit = this.CollAssignList.margininigFrequencyUnit;
          this.Valuationafterhaircut = this.CollAssignList.valueAfterHaircut;
          this.ValuationDate = this.CollAssignList.valuationDate;
          this.MaturityDate = this.CollAssignList.maturitesDate;
          this.RevaluationDate = this.CollAssignList.revalutionInitiationDate;
          this.ValuedBy = this.CollAssignList.valueBy;
          this.MarketValue = this.CollAssignList.marketValue;
          this.RevaluationPercent = this.CollAssignList.revalutionPercentage;
          this.ChangeValue = this.CollAssignList.chargeValue;
          this.ForcedSaleValue = this.CollAssignList.forcedSaleValue;
          this.ChangeType = this.CollAssignList.chargeType; 
          this.EnterComment = this.CollAssignList.comment;
        }
    });
    }
  
    
  
  
  
  }