<app-header></app-header>
<div>&nbsp;</div>
<div>&nbsp;</div>
<div>&nbsp;</div>
<br /><br />
<h2 style="text-align:center">Menu Master</h2>
<div>&nbsp;</div>
<div class="row col-sm-12">
  <div class="col-sm-9"></div>
  <div class="col-sm-2">
    <button style="background-color: green" class="btn btn-success" data-toggle="modal" data-target="#basicExample"
      mdbWavesEffect (click)="addMenu()">
      Add
    </button>
  </div><br /><br /><br />
  <div class="col-md-12 row">
    <div class="col-md-2"></div>
    <div class="col-md-10">
      <div class="table-responsive">
        <div style="overflow-x:visible;height: 600px;width: 700px;overflow-y: visible">
          <table class="table table-bordered" style="border-collapse: collapse;">
            <tr style="font-size:17px">
              <th>Sl No</th>
              <th>Menu Id</th>
              <th>Menu Name </th>
              <th>Parent Menu Id</th>
              <th>User Role</th>
              <th>Menu File Name</th>
              <th>Menu URL</th>
              <th>Menu Visible</th>
              <th>Created By</th>
              <th>Created On</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
            <tr *ngFor="let aa of MenusList| paginate: { itemsPerPage: 5, currentPage: p };index as i"
              style="font-size:16px;font-family:DIN Next Rounded LT Pro ">
              <td> {{ 5 * (p - 1) + (i+1) }}</td>
              <td>{{aa.menuID}}</td>
              <td>{{aa.menuFileName}}</td>
              <td>{{aa.parent_MenuID}}</td>
              <td>{{aa.user_Role}}</td>
              <td>{{aa.menuName}}</td>
              <td>{{aa.menuURL}}</td>
              <td>{{aa.usE_YN}}</td>
              <td>{{aa.createdBy}}</td>
              <td>{{aa.createdOn|date}}</td>
              <td>
                <button style="background-color:#ff8000 " class="btn btn-success" data-toggle="modal"
                  data-target="#basicExample" mdbWavesEffect (click)="editMenu(aa)">
                  Edit
                </button>
              </td>
              <td>
                <button class="btn btn-danger" data-toggle="modal" data-target="#deleteExample" mdbWavesEffect
                  (click)="deleteMenu(aa)">Delete</button>
              </td>
            </tr>
          </table>
          <div class="col-md-12">&nbsp;</div>
        </div>
      </div>
      <div class="col-md-12">&nbsp;</div>
      <div class="col-md-12">&nbsp;</div>

      <pagination-controls (pageChange)="p = $event" style="float: right" ></pagination-controls>

    </div>
  </div>
  <div class="modal modal-angular fade" id="basicExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center"> Menu</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body text-center">
          <form #menuForm="ngForm">
            <div class="col-md-12 row">
              <div class="form-horizontal container">
                <div class="col-md-12 row">
                  <label class="col-md-4" style="padding-top: 13px;color: black">User Role :</label>
                  <div class="col-md-8">
                    <select (change)="onSelectRole($event.target.value)" class="form-control">
                      <option value="">Select Role</option>
                      <option *ngFor="let do of RolesList" value={{do.id}} [selected]="do.id == ccid">
                        {{do.roleName}}
                      </option>
                    </select>
                  </div>
                </div> <br />
                <div class="col-md-12 row">
                  <label class="col-md-4" style="padding-top: 13px;color: black">Menu Id:</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" id="MenuId" name="MenuId" placeholder="Enter State Menu Id"
                      [(ngModel)]="MenuId" />
                  </div>
                </div><br />
                <div class="col-md-12 row">
                  <label class="col-md-4" style="padding-top: 13px;color: black">Menu File Name:</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" id="MenuFileName" name="MenuFileName"
                      placeholder="Menu File Name" [(ngModel)]="MenuFileName" />
                  </div>
                </div><br />

                <div class="col-md-12 row">
                  <label class="col-md-4" style="padding-top: 13px;color: black">Menu Name:</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" id="MenuName" name="MenuName" placeholder="Enter Menu Name"
                      [(ngModel)]="MenuName" />
                  </div>
                </div><br />

                <div class="col-md-12 row">
                  <label class="col-md-4" style="padding-top: 13px;color: black">Menu URL:</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" id="MenuURL" name="MenuURL" placeholder="Menu URL"
                      [(ngModel)]="MenuURL" />
                  </div>
                </div><br />

                <div class="col-md-12 row">
                  <label class="col-md-4" style="padding-top: 13px;color: black">Parent Menu Id:</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" id="ParentMenuId" name="ParentMenuId"
                      placeholder="Parent Menu Id" [(ngModel)]="ParentMenuId" />
                  </div>
                </div><br />
              </div>
            </div>
          </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning btn-sm waves-light" data-dismiss="modal"
           mdbWavesEffect>Close</button>
          <button  data-dismiss="modal" *ngIf="svemenu" type="submit" class="btn btn-success btn-sm waves-light" mdbWavesEffect
            (click)="SaveMenuK(menuForm)">Save</button>
          <button data-dismiss="modal" *ngIf="updmenu" type="submit" class="btn btn-success btn-sm waves-light"
            (click)="UpdateMenu(menuForm)">Update </button>
        </div>
      </div>

    </div>

  </div>
  <div class="modal modal-angular fade" id="deleteExample" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title w-100" id="myModalLabel" style="text-align: center">Delete Menu</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <h3 style="text-align: center">Are you sure to delete..??</h3>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" data-dismiss="modal" 
            mdbWavesEffect>Close</button>
          <button data-dismiss="modal" type="submit" style="background-color: red" class="btn btn-danger" (click)="deletemenudata()"
            mdbWavesEffect>Delete</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">&nbsp;</div>
  <div class="col-md-12">&nbsp;</div>
  <div class="col-md-12">&nbsp;</div>
  <div class="col-md-12">&nbsp;</div>
  <!-- notification alert -->
  <div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
      <div class="alert alert-info"
        style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 550px;width: 350px;">
        <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <i class="material-icons" style="color: white">close</i>
        </button>
        <span style="color: white;margin-top: 6px;">
          <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
      </div>
    </div>
  </div>
