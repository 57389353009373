<div class="container">
    <div class="video-container">
      <video #webcamVideo autoplay></video>
    </div>
  
    <div class="button-container">
      <button *ngIf="makevideokyc" (click)="startWebcam()" class="btn btn-primary">Complete Video KYC of One minute</button>
      <button *ngIf="makerecord" (click)="startRecording()" class="btn btn-primary" [disabled]="recording">Start Recording Now</button>
      <div *ngIf="recording" class="stopwatch">{{ countdown }}s</div>
    </div>
  </div>