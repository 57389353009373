<app-header></app-header>
<div>&nbsp;</div>
<div>&nbsp;</div>
<mat-horizontal-stepper #stepper>
    <!-- <mat-step>
        <div>
            <button mat-button matStepperPrevious></button>
            <button mat-button (click)="stepper.reset()"></button>
        </div>
    </mat-step> -->
</mat-horizontal-stepper>

<!-- #docregion states -->
<mat-horizontal-stepper style="background-color: #c2b6b6;
background-image: linear-gradient(315deg, #c2b6b6 0%, #576574 74%);" #stepper1>


    <mat-step label="Step 1" state="details" style="text-align: center;    height: 96%;width: 136%;">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
            <div class="col-md-1"></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10">

                <div class="homecard" id="signin" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%">
                    <h1 style="font-family: DINNextRoundedLTPro;color: #2b156f;font-size: 34px;">Motor Insurance Form</h1>

                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Personal Details</h2>

                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12">&nbsp;</div>
                    <form id="msform" [formGroup]="RegistrationForm">
                        <div class="col-md-12 row">
                            <div class="col-md-4">&nbsp;</div>
                            <div class="col-md-4">
                                <div class="form-card">
                                    <div class="container fileUploadWrapper">
                                        <div class="col-md-12">
                                            <img src="{{userImage}}" class="img-circle" style="height: 150px;width: 150px;border: solid 2px;
                                border-radius: 7px;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12">&nbsp;</div>

                        <div class="col-md-12">&nbsp;</div>
                        <div class="col-md-12 row">
                            <div class="col-md-2 row">&nbsp;</div>
                            <div class="col-md-3 row">
                                <label id="emaillabel">Customer Id</label>
                                <input (keyup.enter)="signIn(stepper1)" (change)="getCustomerImage(customerid)" formControlName="customerid" class="form-control" type="text" id="inputbutton" [(ngModel)]="customerid">
                            </div>
                        </div>
                        <br>
                        <div class="col-md-12 row">
                            <div class="col-md-2 row">&nbsp;</div>
                            <div class="col-md-3 row">

                                <label id="emaillabel">First Name</label>

                                <input (keyup.enter)="signIn(stepper1)" formControlName="firstname" class="form-control" type="text" id="inputbutton" [(ngModel)]="firstname" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.firstname.errors.required ">First Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Middle Name</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="middlename" class="form-control " type="text " id="inputbutton " [(ngModel)]="middlename ">
                                <!-- <div *ngIf="submitted && f.middlename.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="f.middlename.errors.required ">Middle Name is required
                            </div>
                        </div> -->
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Last Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" (keyup.enter)="signIn(stepper1) " formControlName="lastname" class="form-control " type="text " id="inputbutton " [(ngModel)]="lastname ">
                                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.lastname.errors.required ">Last Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Place of Birth</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.placeofbirth.errors }" (keyup.enter)="signIn(stepper1) " formControlName="placeofbirth" class="form-control " type="text " id="inputbutton " [(ngModel)]="placeofbirth ">
                                <div *ngIf="submitted && f.placeofbirth.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.placeofbirth.errors.required ">Place is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Gender</label>
                                <select (change)="onSelectgender($event.target.value)" class="form-control">
                        <option value="">Select Gender</option>
                        <option *ngFor="let do of genders" value={{do.genderId}}
                            [selected]="do.genderId==genderid">
                            {{do.genderName}}
                        </option>
                    </select>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Caste</label>
                                <select (change)="onSelectCaste($event.target.value)" class="form-control">
                        <option value="">Select Caste</option>
                        <option *ngFor="let do of castes" value={{do.raceId}}
                            [selected]="do.raceId==casteid">
                            {{do.raceName}}
                        </option>
                    </select>
                            </div>
                            <div class="col-md-2 row " *ngIf="othercastedetails">&nbsp;</div>

                            <div class="col-md-3 row " *ngIf="othercastedetails">
                                <label for="Email " id="emaillabel ">Caste</label>
                                <input formControlName="castedetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="castedetail">
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Mobile Number</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.Number.errors }" (keyup.enter)="signIn(stepper1) " formControlName="Number" class="form-control" type="text" id="inputbutton " [(ngModel)]="Number ">
                                <div *ngIf="submitted && f.Number.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.Number.errors.required ">Mobile Number is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Date of Birth</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.dateofbirth.errors }" (keyup.enter)="signIn(stepper1) " formControlName="dateofbirth" class="form-control" type="date" id="inputbutton " [(ngModel)]="dateofbirth ">
                                <div *ngIf="submitted && f.dateofbirth.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.dateofbirth.errors.required ">Date of Birth is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Religion</label>
                                <select (change)="onSelectrelogion($event.target.value)" class="form-control">
                        <option value="">Select Religion</option>
                        <option *ngFor="let do of religions" value={{do.religionId}}
                            [selected]="do.religionId==rid">
                            {{do.religionName}}
                        </option>
                    </select> </div>
                            <div class="col-md-2 row " *ngIf="othereligiondetails">&nbsp;</div>
                            <div class="col-md-3 row " *ngIf="othereligiondetails">
                                <label for="Email " id="emaillabel ">Religion</label>
                                <input formControlName="religiondetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="religiondetail">
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Marital Status</label>
                                <select (change)="onSelectmarital($event.target.value)" class="form-control">
                        <option value="">Select Marital Status</option>
                        <option *ngFor="let do of status" value={{do.maritalStatusId}}
                            [selected]="do.maritalStatusId==martid">
                            {{do.maritalStatus}}
                        </option>
                    </select> </div>



                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Qualification</label>
                                <select (change)="onSelectqualification($event.target.value)" class="form-control">
                        <option value="">Select Qualification</option>
                        <option *ngFor="let do of qualifications" value={{do.qualificationId}}
                            [selected]="do.qualificationId==quaid">
                            {{do.qualificationName}}
                        </option>
                    </select>

                            </div>
                            <div class="col-md-1 row " *ngIf="otherequalification ">&nbsp;</div>
                            <!-- <div class="col-md-1 row " *ngIf="otherequalification">&nbsp;</div> -->

                            <div class="col-md-3 row " *ngIf="otherequalification">
                                <label for="Email " id="emaillabel ">Qualification</label>
                                <input formControlName="qualificationdetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="qualificationdetail">
                            </div>
                            <div class="col-md-1 row " *ngIf="otherequalification" style="  margin-left: 1%;">&nbsp;</div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Occupation</label>
                                <select (change)="onSelectoccupation($event.target.value)" class="form-control">
                        <option value="">Select Occupation</option>
                        <option *ngFor="let do of occupations" value={{do.occupationId}}
                            [selected]="do.occupationId==occuid">
                            {{do.occupationName}}
                        </option>
                    </select>

                            </div>

                            <div class="col-md-2 row " *ngIf="othersoccupation">&nbsp;</div>

                            <div class="col-md-3 row " *ngIf="othersoccupation">
                                <label for="Email " id="emaillabel ">Occupation</label>
                                <input formControlName="occupatoiondetail" class="form-control " type="text " id="inputbutton " [(ngModel)]="occupatoiondetail">
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel "> Email</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (keyup.enter)="signIn(stepper1) " formControlName="email" class="form-control " type="text " id="inputbutton " [(ngModel)]="email ">
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.email.errors.required ">Email is required</div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Designation</label>
                                <select (change)="onSelectdesignation($event.target.value)" class="form-control">
                        <option value="">Select Designations</option>
                        <option *ngFor="let do of designations" value={{do.id}} [selected]="do.id==desid">
                            {{do.designationName}}
                        </option>
                    </select>
                            </div>
                            <div class="col-md-3 row " *ngIf="otherdesignation" style="margin-left: 5%;">

                                <label for="Email " id="emaillabel ">Designation</label>
                                <input formControlName="designationd" class="form-control " type="text " id="inputbutton " [(ngModel)]="designationd">
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h4>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Postal Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }" (keyup.enter)="signIn(stepper1) " formControlName="pincode" class="form-control " type="number " id="inputbutton " [(ngModel)]="pincode" (change)="getalldata()">
                                <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.pincode.errors.required ">Postal is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Country</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="country" class="form-control " type="text " id="inputbutton " [(ngModel)]="country" readonly>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> State</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="state" class="form-control " type="text " id="inputbutton " [(ngModel)]="state" readonly>
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">District</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="district" class="form-control " type="text " id="inputbutton " [(ngModel)]="district" readonly>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Address one</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.city.errors }" (keyup.enter)="signIn(stepper1) " formControlName="city" class="form-control " type="text " id="inputbutton " [(ngModel)]="city">
                                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.city.errors.required ">City Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address two</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.street.errors }" (keyup.enter)="signIn(stepper1) " formControlName="street" class="form-control " type="text " id="inputbutton " [(ngModel)]="street">
                                <div *ngIf="submitted && f.street.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.street.errors.required ">Street Name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-7 row ">
                                <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Permanent Address is Same as Residential Address</h4>
                            </div>
                            <div class="col-md-1 row ">
                                <input class="form-control" formControlName="PermanentSameasResidential" [checked]="PermanentSameasResidential" [(ngModel)]="PermanentSameasResidential" (change)="onSelectaddress($event)" type="checkbox" id="inputbutton ">
                            </div>
                            <div class="col-md-2 row ">&nbsp;</div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Postal Code</label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" (keyup.enter)="signIn(stepper1) " formControlName="postalcode" class="form-control " type="number " id="inputbutton " (change)="getpincoddata()" [(ngModel)]="postalcode">
                                    <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="f.postalcode.errors.required ">Postal is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel ">Country</label>
                                    <input (keyup.enter)="signIn(stepper1) " formControlName="countryone" class="form-control " type="text " id="inputbutton " [(ngModel)]="countryone" readonly>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label for="Email " id="emaillabel "> State</label>
                                    <input (keyup.enter)="signIn(stepper1) " formControlName="stateone" class="form-control " type="text " id="inputbutton " [(ngModel)]="stateone" readonly>
                                </div>

                            </div>
                            <div class="col-md-12 row ">&nbsp;</div>

                            <div class="col-md-12 row ">
                                <div class="col-md-2 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">District</label>
                                    <input (keyup.enter)="signIn(stepper1) " formControlName="districtone" class="form-control " type="text " id="inputbutton " [(ngModel)]="districtone" readonly>
                                </div>

                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Address one</label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.adressone.errors }" (keyup.enter)="signIn(stepper1) " formControlName="adressone" class="form-control " type="text " id="inputbutton " [(ngModel)]="adressone">
                                    <div *ngIf="submitted && f.adressone.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="f.adressone.errors.required ">Address one is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 row ">&nbsp;</div>
                                <div class="col-md-3 row ">
                                    <label id="emaillabel ">Address two</label>
                                    <input [ngClass]="{ 'is-invalid': submitted && f.adresstwo.errors }" (keyup.enter)="signIn(stepper1) " formControlName="adresstwo" class="form-control " type="text " id="inputbutton " [(ngModel)]="adresstwo">
                                    <div *ngIf="submitted && f.adresstwo.errors" class="invalid-feedback">
                                        <div style="color: red; " *ngIf="f.adresstwo.errors.required ">Address two is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <BR /> &nbsp;
                    <div class="col-md-12 row">
                        <div class="col-md-4 row">&nbsp;</div>
                        <div class="col-md-3 row">
                            <button (click)="SaveBasicDraft(stepper1)"  id="signinbutton">Draft</button>

                        </div>
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-3 row">
                            <button (click)="signIn(stepper1)" id="signinbutton">Next</button>
                        </div>

                    </div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                </div>
            </div>
        </div>
    </mat-step>

    <mat-step label="Step 2" state="account" style="text-align: center; height: 96%; width: 136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center " style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Bank Details</h2>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>

                    <div class="container" style="margin-top: 5%">

                        <table class="table table-striped  table-responsive table-bordered">
                            <thead>
                                <tr>
                                    <th>Bank Account Type</th>
                                    <th>Bank Name</th>
                                    <th>Branch</th>
                                    <th>IFSC Code</th>
                                    <th>Cheque Facility</th>
                                    <!-- <th>Loan Facility</th> -->
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let field of fieldArray; let i = index">
                                    <td>
                                        <select (change)="onSelectbank($event.target.value)" class="form-control">
                                        <option value="">Select Bank Account Type</option>
                                        <option *ngFor="let do of banks" value={{do.bankAccTypeId}}
                                            [selected]="do.bankAccTypeId == field.banktypeid">
                                            {{do.accountType}}
                                        </option>
                                    </select>
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.bankname" class="form-control" type="text" name="{{field.bankname}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.branch" class="form-control" type="text" name="{{field.branch}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.ifscecode" class="form-control" type="text" name="{{field.ifscecode}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.selectedchequetypeid" class="form-control" />
                                    </td>
                                    <!-- <td>
                                    <input [(ngModel)]="field.loanfacility" class="form-control" type="checkbox"
                                        name="{{field.loanfacility}}" />
                                </td> -->
                                    <td>
                                        <button class="btn btn-default" type="button" (click)="deleteFieldValue(i)">Delete</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <select (change)="onSelectbank($event.target.value)" class="form-control">
                                        <option value="">Select Bank Account Type</option>
                                        <option *ngFor="let do of banks" value={{do.bankAccTypeId}}>
                                            {{do.accountType}}
                                        </option>
                                    </select>
                                    </td>
                                    <td>
                                        <input class="form-control" type="text" id="bankname" [(ngModel)]="newAttribute.bankname" name="bankname" />

                                    </td>
                                    <td>
                                        <input class="form-control" type="text" id="branch" [(ngModel)]="newAttribute.branch" name="branch" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="text" id="ifscecode" [(ngModel)]="newAttribute.ifscecode" name="ifscecode" />
                                    </td>
                                    <td>
                                        <select (change)="onSelectChequeType($event.target.value)" class="form-control">
                                        <option value="">Select Cheque Facility Type</option>
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </select>
                                        <!-- <input class="form-control" type="checkbox" id="chequefacility"
                                        [(ngModel)]="newAttribute.chequefacility" name="chequefacility" /> -->
                                    </td>
                                    <!-- <td>
                                    <input class="form-control" type="checkbox" id="loanfacility"
                                        [(ngModel)]="newAttribute.loanfacility" name="loanfacility" />
                                </td> -->
                                    <td>
                                        <button class="btn btn-default" type="button" (click)="addFieldValue()">Add</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <form class="register-form " [formGroup]="BankDetailsForm">
                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Identity Details</h4>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Ration Card Number</label>
                                <input formControlName="rationcardnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="rationcardnum">
                                <!-- <div *ngIf="submitted && k.rationcardnum.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.rationcardnum.errors.required "> Ration Card
                                    Number is required</div>
                                <div style="color: red; " *ngIf="k.rationcardnum.errors.pattern ">enter valid Ration
                                    Card Number</div>
                            </div> -->
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Aadhar Number</label>
                                <input  [ngClass]="{ 'is-invalid': submitted && k.aadharnum.errors } " formControlName="aadharnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="aadharnum" >
                                <div *ngIf="submitted && k.aadharnum.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.aadharnum.errors.required ">Aadhar Number is required
                                </div>
                                <div style="color: red; " *ngIf="k.aadharnum.errors.pattern ">enter valid Aadhar Number
                                </div>

                            </div>
                            <!-- <div style="color: red; " *ngIf="aadhaarNumberError" class="error-message">Invalid Aadhaar number</div> -->
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Voter ID Number</label>
                                <input [ngClass]="{ 'is-invalid': submitted && k.voternum.errors } " formControlName="voternum" class="form-control " type="text " id="inputbutton " [(ngModel)]="voternum"  >
                                <div *ngIf="submitted && k.voternum.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.voternum.errors.required "> Voter ID Number is required
                                </div>
                                <div style="color: red; " *ngIf="k.voternum.errors.pattern ">enter valid Voter ID
                                </div>

                            </div>

                            <!-- <div  style="color: red; " *ngIf="voterIdError" class="error-message">Invalid Voter ID</div> -->
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">PAN Number</label>
                                <input [ngClass]="{ 'is-invalid': submitted && k.pannumber.errors }" formControlName="pannumber" class="form-control " type="Date " id="inputbutton " [(ngModel)]="pannumber">
                                <div *ngIf="submitted && k.pannumber.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="k.pannumber.errors.required "> PAN Number is required
                                    </div>
                                    <div style="color: red; " *ngIf="k.pannumber.errors.pattern ">enter valid PAN Number
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">GST No</label>
                                <input [ngClass]="{ 'is-invalid': submitted && k.servicetax.errors }" formControlName="servicetax" class="form-control " type="text " id="inputbutton " [(ngModel)]="servicetax" >
                                <div *ngIf="submitted && k.servicetax.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.servicetax.errors.required ">Service Tax Number
                                    is required</div>
                                <div style="color: red; " *ngIf="k.servicetax.errors.pattern ">enter valid GST No
                                </div>

                            </div>

                            <!-- <div *ngIf="gstNumberError" class="error-message">Invalid GST Number</div> -->
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Passport Number</label>
                                <input [ngClass]="{ 'is-invalid': submitted && k.passportnum.errors }" formControlName="passportnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="passportnum"  >
                                <div *ngIf="submitted && k.passportnum.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.passportnum.errors.required "> Passport Number is
                                    required</div>
                                <div style="color: red; " *ngIf="k.passportnum.errors.pattern ">enter valid Passport
                                    Number </div>

                            </div>
                            <!-- <div *ngIf="passportNumberError" class="error-message">Invalid passport number</div> -->
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Life Insurance Value</label>
                                <input formControlName="lifeinsurance" class="form-control " type="input " id="inputbutton " [(ngModel)]="lifeinsurance">
                                <!-- <div *ngIf="submitted && k.lifeinsurance.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.lifeinsurance.errors.required ">Life Insurance
                                    Value is required</div>
                            </div> -->
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Confirm</label>
                                <input class="form-control" formControlName="IsConfirm" [(ngModel)]="IsConfirm" (change)="onSelectIsConfirm($event)" type="checkbox" id="inputbutton ">

                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <BR />

                        <BR />
                        <div *ngIf="bankdocsexist">
                            <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                         <div class="col-md-12 row">
                            <div class="col-md-1 row">&nbsp;</div>

                             <div class="col-md-2 row" *ngFor="let item of bankdocs">
                                 <img [src]="item.file" height="100" width="100"/>
                                 <br/>
                                 <p style="text-align: center;">{{item.documentName}}</p>
                             </div>
                         </div> 
                        </div>  
                        <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Upload Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                    <div class="container" style="margin-top: 5%">
                        <div class="col-md-12 row">
                            <div class="col-md-3">&nbsp;</div>
                            <div class="col-md-9">
                                <div class="table-responsive" style="overflow-x: visible">
                                    <table class="table table-striped table-responsive table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sl No</th>
                                                <th>Certificate Name</th>
                                                <th>Upload Here</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Aadhar Card</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField (change)="onChangeAadhar($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Voter ID</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField (change)="onChangeVoterId($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Passport</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField (change)="onChangePassport($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>GST Certificate</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField (change)="onChangeGSTCert($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>IT Returns</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField (change)="onChangeITReturns($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Bank Statements</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField (change)="onChangeBankStatements($event)">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div class="col-md-12 row">&nbsp;</div>
                    </form>
                    <BR />
                    <div class="col-md-12 row">
                        <div class="col-md-4 row">&nbsp;</div>
                        <div class="col-md-3 row">
                            <button (click)="SaveBankDraft()" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;

                        </div>
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-3 row">
                            <button (click)="bankIn(stepper1)" id="signinbutton">Next</button>
                        </div>

                    </div>                    <div class="col-md-12 row">&nbsp;</div>
                    <div class="col-md-12 row">&nbsp;</div>
                </div>
            </div>
        </div>
    </mat-step>


    <mat-step label="step 3" state="education" style="text-align: center; height: 96%; width: 136%; ">

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="eignin " align="center " style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Motor Insurance Details</h2>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>


                    <form class="register-form" [formGroup]="motorinsuranceform">

                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Payment Details</h4>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Cheque/Instrument no.</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.chequeno.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="chequeno" class="form-control " type="text " id="inputbutton " [(ngModel)]="chequeno">
                                <div *ngIf="submitted && e.chequeno.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.chequeno.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Date of instrument</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.dateofinst.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="dateofinst" class="form-control " type="Date" id="inputbutton " [(ngModel)]="dateofinst">
                                <div *ngIf="submitted && e.dateofinst.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.dateofinst.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Bank Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.bankname.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="bankname" class="form-control " type="text" id="inputbutton " [(ngModel)]="bankname">
                                <div *ngIf="submitted && e.bankname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.bankname.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Branch Location</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.branchloc.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="branchloc" class="form-control " type="text " id="inputbutton " [(ngModel)]="branchloc">
                                <div *ngIf="submitted && e.branchloc.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.branchloc.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Amount</label>
                                <div class="col-md-12 row ">&nbsp;</div>
                                <span style="display: flex;">Rs.&nbsp;&nbsp;&nbsp;&nbsp;
                                <input [ngClass]="{ 'is-invalid': submitted && e.paymentamount.errors }"
                                    (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="paymentamount"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="paymentamount" style=" margin-top: -2%;"></span>
                                <div *ngIf="submitted && e.paymentamount.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.paymentamount.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Source of fund</h4>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Salary/Business/Other, Specify</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.sourceoffund.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="sourceoffund" class="form-control " type="text " id="inputbutton " [(ngModel)]="sourceoffund">
                                <div *ngIf="submitted && e.sourceoffund.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.sourceoffund.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>

                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Risk Information</h4>
                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Vehicle Manufacturer</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.vehiclemanufacturer.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="vehiclemanufacturer" class="form-control " type="text " id="inputbutton " [(ngModel)]="vehiclemanufacturer">
                                <div *ngIf="submitted && e.vehiclemanufacturer.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.vehiclemanufacturer.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Vehicle model</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.vehiclemodel.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="vehiclemodel" class="form-control " type="text" id="inputbutton " [(ngModel)]="vehiclemodel">
                                <div *ngIf="submitted && e.vehiclemodel.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.vehiclemodel.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Registration location</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.registrationlocation.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="registrationlocation" class="form-control " type="text " id="inputbutton " [(ngModel)]="registrationlocation">
                                <div *ngIf="submitted && e.registrationlocation.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.registrationlocation.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>


                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Year of Manufacturer</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.yearofmanufacture.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="yearofmanufacture" class="form-control " type="number " id="inputbutton " [(ngModel)]="yearofmanufacture">
                                <div *ngIf="submitted && e.yearofmanufacture.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.yearofmanufacture.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Engine no.</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.engineno.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="engineno" class="form-control " type="text " id="inputbutton " [(ngModel)]="engineno">
                                <div *ngIf="submitted && e.engineno.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.engineno.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Chassis no.</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.chassisno.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="chassisno" class="form-control " type="text " id="inputbutton " [(ngModel)]="chassisno">
                                <div *ngIf="submitted && e.chassisno.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.chassisno.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Colour of Vehicle</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.colourofvehicle.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="colourofvehicle" class="form-control " type="text " id="inputbutton " [(ngModel)]="colourofvehicle">
                                <div *ngIf="submitted && e.colourofvehicle.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.colourofvehicle.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Fuel type</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <select (change)="onSelectFueltype($event.target.value)" class="form-control">
                                    <option value="">Select Fuel type</option>
                                    <option *ngFor="let do of FuelTypes" value={{do.id}} [selected]="do.id==selectedfueltype">
                                        {{do.fuelType}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Age of Insured</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.ageofinsured.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="ageofinsured" class="form-control " type="text " id="inputbutton " [(ngModel)]="ageofinsured">
                                <div *ngIf="submitted && e.ageofinsured.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.ageofinsured.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Insured Declared Value of the Vehicle</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.insureddeclaredvalue.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="insureddeclaredvalue" class="form-control " type="text" id="inputbutton " [(ngModel)]="insureddeclaredvalue">
                                <div *ngIf="submitted && e.insureddeclaredvalue.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.insureddeclaredvalue.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label for="Email " id="emaillabel ">Non-Electrical Accessories fitted to vehicle Side
                                    car(Two-Wheeler)</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.accessoriesfitted.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="accessoriesfitted" class="form-control " type="text" id="inputbutton " [(ngModel)]="accessoriesfitted" >
                                <div *ngIf="submitted && e.accessoriesfitted.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.accessoriesfitted.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Value of CNG/LPG kit Bi fuel</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.valueofkit.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="valueofkit" class="form-control " type="text" id="inputbutton " [(ngModel)]="valueofkit">
                                <div *ngIf="submitted && e.valueofkit.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.valueofkit.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label for="Email " id="emaillabel ">Total Value</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.totalvalue.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="totalvalue" class="form-control " type="text" id="inputbutton " [(ngModel)]="totalvalue" >
                                <div *ngIf="submitted && e.totalvalue.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.totalvalue.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>

                            <div class="col-md-4 row ">
                                <label id="emaillabel ">Package policy&nbsp;&nbsp;</label>

                                <mat-slide-toggle formControlName="packagepolicy" [(ngModel)]="packagepolicy">
                                </mat-slide-toggle>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                            <div class="col-md-4 row ">
                                <label id="emaillabel ">PUC&nbsp;&nbsp;</label>

                                <mat-slide-toggle formControlName="puc" [(ngModel)]="puc">
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Additional Info</h4>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Registration no.</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.registrationno.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="registrationno" class="form-control " type="text" id="inputbutton " [(ngModel)]="registrationno">
                                <div *ngIf="submitted && e.registrationno.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.registrationno.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Date of Registration</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.dateofregistration.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="dateofregistration" class="form-control " type="Date" id="inputbutton " [(ngModel)]="dateofregistration" >
                                <div *ngIf="submitted && e.dateofregistration.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.dateofregistration.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Previous Insurer</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.previousinsurer.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="previousinsurer" class="form-control " type="text " id="inputbutton " [(ngModel)]="previousinsurer">
                                <div *ngIf="submitted && e.previousinsurer.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.previousinsurer.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Previous policy number</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.previouspolicynumber.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="previouspolicynumber" class="form-control " type="number" id="inputbutton " [(ngModel)]="previouspolicynumber">
                                <div *ngIf="submitted && e.previouspolicynumber.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.previouspolicynumber.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Previous period of insurance</h4>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel ">From</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.previousperiodfrom.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="previousperiodfrom" class="form-control " type="Date" id="inputbutton " [(ngModel)]="previousperiodfrom">
                                <div *ngIf="submitted && e.previousperiodfrom.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.previousperiodfrom.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label for="Email " id="emaillabel ">To</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.previousperiodto.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="previousperiodto" class="form-control " type="Date" id="inputbutton " [(ngModel)]="previousperiodto">
                                <div *ngIf="submitted && e.previousperiodto.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.previousperiodto.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Current period of insurance</h4>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel ">From</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.currentperiodfrom.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="currentperiodfrom" class="form-control " type="Date" id="inputbutton " [(ngModel)]="currentperiodfrom">
                                <div *ngIf="submitted && e.currentperiodfrom.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.currentperiodfrom.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label for="Email " id="emaillabel ">To</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.currentperiodto.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="currentperiodto" class="form-control " type="Date" id="inputbutton " [(ngModel)]="currentperiodto">
                                <div *ngIf="submitted && e.currentperiodto.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.currentperiodto.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4 row ">
                                <label id="emaillabel "> Claims lodged during preceding year</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.claimslodged.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="claimslodged" class="form-control " type="number" id="inputbutton " [(ngModel)]="claimslodged">
                                <div *ngIf="submitted && e.claimslodged.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.claimslodged.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                            <div class="col-md-5 row ">
                                <label id="emaillabel ">Claims lodged during preceding year Amount </label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.claimslodgedamount.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="claimslodgedamount" class="form-control " type="number" id="inputbutton " [(ngModel)]="claimslodgedamount">
                                <div *ngIf="submitted && e.claimslodgedamount.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.claimslodgedamount.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-5 row ">
                                <label id="emaillabel ">Entitled to NCB(no claim bonus)</label>
                                <div class="col-md-12 row ">
                                    <mat-slide-toggle formControlName="entitledtoncb" [(ngModel)]="entitledtoncb">
                                    </mat-slide-toggle>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row">
                                <label id="emaillabel ">if Yes, please mention % </label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.entitledtoncbyespercent.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="entitledtoncbyespercent" class="form-control " type="number" id="inputbutton " [(ngModel)]="entitledtoncbyespercent">
                            </div>
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-10 row ">
                                <label id="emaillabel ">use of vehicle is limited to own permises?</label>
                                <div class="col-md-12 row ">
                                    <mat-slide-toggle formControlName="limitedtoownpermises" [(ngModel)]="limitedtoownpermises">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-10 row ">
                                <label id="emaillabel ">Vehicle is designed for the Handicapped/Mentally challenged
                                    persons and duly endorsed by RTA?</label>
                                <div class="col-md-12 row ">
                                    <mat-slide-toggle formControlName="vehicledesignedhandicapped" [(ngModel)]="vehicledesignedhandicapped">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-10 row ">
                                <label id="emaillabel ">Is the Vehicle proposed for insurance under:Hire-Purchase/Lease
                                    Agreement</label>
                                <div class="col-md-12 row ">
                                    <mat-slide-toggle formControlName="vehicleproposedfor" [(ngModel)]="vehicleproposedfor">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-4">
                                <label id="emaillabel ">If yes, give name of concerned parties</label>
                                <input (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="nameofconcerned" class="form-control " type="text" id="inputbutton " [(ngModel)]="nameofconcerned">
                            </div>
                        </div>

                        <div class="col-md-12 ">&nbsp;</div>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Coverage Info</h3>
                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Please select the higher deductible if you wish to opt for over and above the compulsory deductible </h4>
                        <div class="col-md-12 ">&nbsp;</div>

                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Do you wish to include the following PA coverages</h4>

                        <div class="col-md-12 row">
                            <div class="col-md-12 ">&nbsp;</div>

                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Pillion Passengers</label>
                                <div class="col-md-12 row ">

                                    <mat-slide-toggle formControlName="pillionpassengers" [(ngModel)]="pillionpassengers">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">No. of Persons</label>
                                <div class="col-md-12 row ">

                                    <mat-slide-toggle formControlName="nofpersons" [(ngModel)]="nofpersons">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">CSI opted for: Rs.</label>
                                <div class="col-md-12 row ">

                                    <mat-slide-toggle formControlName="csiopted" [(ngModel)]="csiopted">
                                    </mat-slide-toggle>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-10 row ">
                                <label id="emaillabel ">Do you wish to opt for statutory TPPD liability coverage of
                                    Rs.6000 only?</label>
                                <div class="col-md-12 row ">
                                    <mat-slide-toggle formControlName="optforstatutory" [(ngModel)]="optforstatutory">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Legal Liability</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.legalliability.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="legalliability" class="form-control " type="text " id="inputbutton " [(ngModel)]="legalliability">
                                <div *ngIf="submitted && e.legalliability.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.legalliability.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">No. of Persons</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.legalliabilitypersons.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="legalliabilitypersons" class="form-control " type="text " id="inputbutton " [(ngModel)]="legalliabilitypersons">
                                <div *ngIf="submitted && e.legalliabilitypersons.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.legalliabilitypersons.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Driver/Conductor/Cleaner</label>
                                <input [ngClass]="{ 'is-invalid': submitted && e.driver.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="driver" class="form-control " type="text " id="inputbutton " [(ngModel)]="driver">
                                <div *ngIf="submitted && e.driver.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.driver.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Other Employee</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input [ngClass]="{ 'is-invalid': submitted && e.otheremployee.errors }" (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="otheremployee" class="form-control " type="text " id="inputbutton " [(ngModel)]="otheremployee">
                                <div *ngIf="submitted && e.otheremployee.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="e.otheremployee.errors.required "> Required Field
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 ">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Details of Hire Purchase,Hypothecation,Lease, if applicable</h4>
                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-8 row ">
                                <label for="Email " id="emaillabel ">Full Name of the concerned party and
                                    address</label>
                                <div class="col-md-12 row ">&nbsp;</div>

                                <input (keyup.enter)="SaveMotorInsurance(stepper1) " formControlName="fullnameofconcerned" class="form-control " type="text " id="inputbutton " [(ngModel)]="fullnameofconcerned">

                            </div>

                        </div>

                        <div class="col-md-12 ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-10 row ">
                                <label id="emaillabel ">Moton Add-on Covers Zero Depriciation</label>
                                <div class="col-md-12 row ">
                                    <mat-slide-toggle formControlName="motonaddon" [(ngModel)]="motonaddon">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row" *ngIf="role!='1008'">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-1 row ">

                                <input  class="form-control" type="checkbox" [checked]="SendCopytoSP" formControlName="SendCopytoSP" [(ngModel)]="SendCopytoSP" style="font-size: 7px;">
                            </div>
                            <div class="col-md-5 row ">
                                <p style="font-size: 20px;color:darkblue">Send one copy to {{serviceprovidername}}</p>

                            </div>
                        </div>
                    </form>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                
                    <div class="col-md-12 row">
                        <div class="col-md-4 row">&nbsp;</div>
                        <div class="col-md-3 row">
                            <button (click)="SaveMotorInsuranceDraft()" id="signinbutton">Draft</button> 
                            &nbsp; &nbsp; &nbsp;

                        </div>
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-3 row">
                            <button (click)="SaveMotorInsurance(stepper1)" id="signinbutton">Next</button>
                        </div>

                    </div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                 
                    <div class="col-md-12 row ">&nbsp;</div>
              
              
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                </div>
            </div>
        </div>
    </mat-step>



    <mat-step label="Step 4" state="upload" style="text-align: center; height: 96%; width: 136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center "
                    style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                    <div *ngIf="certificatesexist">
                        <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="col-md-12 row">
                            <!-- <div class="col-md-1 row">&nbsp;</div> -->

                            <div class="col-md-4 row" *ngFor="let item of CertificateDisplay">
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'image/jpeg' ||
                             item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                    <img [src]="item.certificate" height="100" width="100" />
                                    <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/pdf' ||
                             item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                    <img src="assets/img/pdficon.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                        <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                             item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                    <img src="assets/img/xls.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                        <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                             item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                    <img src="assets/img/docx.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                        <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                </div>


                            </div>
                        </div>
                        <div *ngIf="documentArray.length!=0">
                            <div class="col-md-12 row">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Uploaded Documents</h4>

                            <div class="col-md-12 row">
                                <!-- <div class="col-md-1 row">&nbsp;</div> -->

                                <div class="col-md-4 row" *ngFor="let item of certificates">
                                    <div *ngIf="item.actualCertificateName!=null  && (item.documentType == 'image/jpeg' ||
                             item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                        <img [src]="item.certificate" height="100" width="100" />
                                        <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                        <!-- <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/pdf' ||
                             item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                        <img src="assets/img/pdficon.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                            <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                        <!-- <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                             item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/xls.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                            <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                        <!-- <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                             item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/docx.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                            <p style="text-align: center;"><br/>{{item.fileName}}</p>
                                        <!-- <div class="col-md-12 row">
                                            <div class="col-md-2 row">&nbsp;</div>
                                            <div class="col-md-10 row">
                                                <p style="text-align: center;">{{item.fileName}}</p>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Upload Certificates</h2>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row">
                        <div class="col-md-3">&nbsp;</div>
                        <div class="col-md-9">
                            <div class="table-responsive" style="overflow-x: visible">

                                <table class="table table-striped table-responsive table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Certificate Name</th>
                                            <th>Upload Here</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let field of CertificateList; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{field.certificateName}}</td>
                                            <td>
                                                <input type="file" name="avatars" #fileField
                                                    (change)="onChangeCertificates($event,field)">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Documents</h2>
                                <table class="table table-striped table-responsive table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Document Name</th>
                                            <th>Document</th>

                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let field of documentArray; let i = index">

                                            <td>
                                                <input [(ngModel)]="field.otherdocumentname" class="form-control"
                                                    type="text" name="{{field.otherdocumentname}}" />
                                            </td>
                                            <td>
                                                <input [(ngModel)]="field.otherdocactualname" class="form-control"
                                                    type="text" name="{{field.otherdocactualname}}" />
                                            </td>

                                            <td>
                                                <button class="btn btn-default" type="button"
                                                    (click)="deleteFieldValueDocument(i)">Delete</button>
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>
                                                <input class="form-control" type="text" id="otherdocumentname"
                                                    [(ngModel)]="newDocAttribute.otherdocumentname"
                                                    name="otherdocumentname" />

                                            </td>
                                            <td>
                                                <input class="form-control" type="file" id="otherdocument"
                                                    (change)="OnChangeOtherDocs($event)" name="otherdocument" />
                                            </td>


                                            <td>
                                                <button class="btn btn-default" type="button"
                                                    (click)="addDocFieldValue()">Add</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    
               
               
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row" *ngIf="role!='1008' && ticketid!=null">
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-1 row ">

                        <input  class="form-control" type="checkbox" [checked]="SendCopytoSP"
                            [(ngModel)]="SendCopytoSP" style="font-size: 7px;">
                    </div>
                    <div class="col-md-5 row ">
                        <p style="font-size: 20px;color:darkblue">Send one copy to {{serviceprovidername}}</p>

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <!-- <div class="col-md-12 row">
                    <div class="col-md-3 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <button (click)="uploadCertificatesDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button (click)="uploadCertificates(stepper1)" id="signinbutton">Upload</button>
                    </div>
                    <div class="col-md-1 row">
                    </div>
                 
                </div> -->
                <!-- <div class="col-md-12 row" *ngIf="!enablepreview && ProspectIdtoDisplydata==null" >
                    <div class="col-md-3 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        
                        <button  id="signinbuttonpreview" >Preview</button>

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button id="signinbuttonpreview" >Submit</button>
                    </div>
                    <div class="col-md-1 row">
                    </div>
                  
                </div>
                <div class="col-md-12 row" *ngIf="enablepreview && ProspectIdtoDisplydata==null">
                    <div class="col-md-3 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <button (click)="previewinsurancedetails()"  id="signinbutton">Preview</button>

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button  (click)="submitloanform()" id="signinbutton">Submit</button>
                    </div>
                    <div class="col-md-1 row">
                    </div>
                    
                </div> -->
                
                
                
                <div class="col-md-12 row">
                    <div class="col-md-3 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <button (click)="uploadCertificatesDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button (click)="uploadCertificates(stepper1)" id="signinbutton">Upload</button>
                    </div>
                    <div class="col-md-1 row">
                    </div>
                 
                </div>
            
                <div class="col-md-12 row" *ngIf="ProspectIdtoDisplydata==null">
                    <div class="col-md-3 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <button (click)="previewinsurancedetails()"  id="signinbutton">Preview</button>

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button  (click)="submitInsuranceform()" id="signinbutton">Submit</button>
                    </div>
                    <div class="col-md-1 row">
                    </div>
                    
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>

                </div>
            </div>
        </div>
    </mat-step>




    <!-- Icon overrides. -->
    <!-- #docregion override-icons -->
    <ng-template matStepperIcon="details">
        <mat-icon>account_circle</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="family">
        <mat-icon>family_restroom</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="account">
        <mat-icon>account_balance</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="homei">
        <mat-icon>home</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="business">
        <mat-icon>business</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="upload">
        <mat-icon>cloud_upload</mat-icon>
    </ng-template>
</mat-horizontal-stepper>
<br /><br />
<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info" style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 500px;width: 350px;">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <i class="material-icons" style="color: white">close</i>
            </button>
            <span style="color: white;margin-top: 6px;">
                <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
        </div>
    </div>
</div>