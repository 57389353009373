import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppGlobals } from 'src/app.Global';

@Injectable({
  providedIn: 'root'
})
export class CollateralformService {

  constructor(private http:HttpClient,private _urls:AppGlobals) { }

 getCollateral () {
    return this.http.get(this._urls.weburl + '/api/CollateralForm/GetCollateral');
}
getcategory() {
  return this.http.get(this._urls.weburl + '/api/CollateralForm/GetCollateralCategory');
}

getcategorytype(id) {
  return this.http.get(this._urls.weburl + '/api/CollateralForm/GetCollateralcategoryType/'+ id);
}

getCollateralNature() {
  return this.http.get(this._urls.weburl + '/api/CollateralForm/GetCollateralNature');
}
getcurrency () {
  return this.http.get(this._urls.weburl + '/api/CollateralForm/GetCurrency');
}
getWorkflowstatesforwf(id) {
  return this.http.get(this._urls.weburl + '/api/CollateralForm/GetWorkflowstatesforWorkflow/' + id);
}

getcollateralassignment(id){
  return this.http.get(this._urls.weburl + '/api/CollateralAssignment/GetCollateralAssignment/' + id);
}

getcollateralverify(id){
  return this.http.get(this._urls.weburl + '/api/CollateralAssignment/GetCollateralAssignVerify/' + id);

}

getcollateralbankguarantee(id){
  return this.http.get(this._urls.weburl + '/api/CollateralBankGuarantee/GetCollateralBankGuarantee/' + id);
}

getcollateralcashmargin(id){
  return this.http.get(this._urls.weburl + '/api/CollateralCashMargin/GetCollateralCashMargin/' + id);
}

getcollaterallien(id){
  return this.http.get(this._urls.weburl + '/api/CollateralLien/GetCollateralLien/' + id);
}

getcollateralmortgage(id){
  return this.http.get(this._urls.weburl + '/api/CollateralMortgage/GetCollateralMortgage/' + id);
}

gettouser(id){
  return this.http.get(this._urls.weburl + '/api/CollateralForm/GetToUser/' + id);
}

getcollaterallist(id){
  return this.http.get(this._urls.weburl + '/api/CollateralForm/GetCollList/' + id);
}

saveCollateralAssignment(ct) {
  return this.http.post(this._urls.weburl + '/api/CollateralAssignment/PostsaveCollateralAssignment', ct);
}


SaveCollateralBankGuarantee(ct) {
  return this.http.post(this._urls.weburl + '/api/CollateralBankGuarantee/PostsaveCollateralBankGuarantee', ct);
}

SaveCollateralCashMagin(ct) {
  return this.http.post(this._urls.weburl + '/api/CollateralCashMargin/SaveCollateralchequeCshMargin', ct);
}

saveCollateralLien(ct) {
  return this.http.post(this._urls.weburl + '/api/CollateralLien/PostsaveCollateralLien', ct);
}

saveCollateralMorgage(ct) {
  return this.http.post(this._urls.weburl + '/api/CollateralMortgage/PostsaveCollateralMortgageProperties', ct);
}

}
