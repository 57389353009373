<div>&nbsp;</div>
<app-header></app-header>
<div>&nbsp;</div>
<div>&nbsp;</div>
<mat-horizontal-stepper #stepper>
    <!-- <mat-step>
        <div>
            <button mat-button matStepperPrevious></button>
            <button mat-button (click)="stepper.reset()"></button>
        </div>
    </mat-step> -->
</mat-horizontal-stepper>
<mat-horizontal-stepper style="background-color: #c2b6b6;
background-image: linear-gradient(315deg, #c2b6b6 0%, #576574 74%);" #stepper1>


<mat-step label="Step 1" state="details" style="text-align: center;    height: 96%;width: 136%;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
        <div class="col-md-1"></div>
        <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10">

            <div class="homecard" id="signin" align="center"
                style=" margin-left: 0px;margin-right: 0px;margin-top: 1%">
                <h1 style="font-family: DINNextRoundedLTPro;color: #2b156f;font-size: 34px;">Fixed Deposit Form</h1>

                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Personal Details</h2>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <form id="msform" [formGroup]="RegistrationForm">
                    <div class="col-md-12 row">
                        <div class="col-md-4">&nbsp;</div>
                        <div class="col-md-4">
                            <div class="form-card">
                                <div class="container fileUploadWrapper">
                                    <div class="col-md-12">
                                        <img src="{{userImage}}" class="img-circle" style="height: 150px;width: 150px;border: solid 2px;
                            border-radius: 7px;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12">&nbsp;</div>

                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12 row">
                        <div class="col-md-2 row">&nbsp;</div>
                        <div class="col-md-3 row">
                            <label id="emaillabel">Customer Id</label>
                            <input (keyup.enter)="signIn(stepper1)" (change)="getCustomerImage(customerid)"
                                formControlName="customerid" class="form-control" type="text" id="inputbutton"
                                [(ngModel)]="customerid">
                        </div>
                    </div>
                    <br>
                    <div class="col-md-12 row">
                        <div class="col-md-2 row">&nbsp;</div>
                        <div class="col-md-3 row">

                            <label id="emaillabel">First Name</label>

                            <input (keyup.enter)="signIn(stepper1)" formControlName="firstname" class="form-control"
                                type="text" id="inputbutton" [(ngModel)]="firstname"
                                [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                            <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.firstname.errors.required ">First Name is
                                    required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Middle Name</label>
                            <input (keyup.enter)="signIn(stepper1) " formControlName="middlename"
                                class="form-control " type="text " id="inputbutton " [(ngModel)]="middlename ">
                           
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Last Name</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"
                                (keyup.enter)="signIn(stepper1) " formControlName="lastname" class="form-control "
                                type="text " id="inputbutton " [(ngModel)]="lastname ">
                            <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.lastname.errors.required ">Last Name is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Place of Birth</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.placeofbirth.errors }"
                                (keyup.enter)="signIn(stepper1) " formControlName="placeofbirth"
                                class="form-control " type="text " id="inputbutton " [(ngModel)]="placeofbirth ">
                            <div *ngIf="submitted && f.placeofbirth.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.placeofbirth.errors.required ">Place is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Gender</label>
                            <select (change)="onSelectgender($event.target.value)" class="form-control">
                                <option value="">Select Gender</option>
                                <option *ngFor="let do of genders" value={{do.genderId}}
                                    [selected]="do.genderId==genderid">
                                    {{do.genderName}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>

                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Caste</label>
                            <select (change)="onSelectCaste($event.target.value)" class="form-control">
                                <option value="">Select Caste</option>
                                <option *ngFor="let do of castes" value={{do.raceId}}
                                    [selected]="do.raceId==casteid">
                                    {{do.raceName}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-2 row " *ngIf="othercastedetails">&nbsp;</div>

                        <div class="col-md-3 row " *ngIf="othercastedetails">
                            <label for="Email " id="emaillabel ">Caste</label>
                            <input (keyup.enter)="details(stepper1) " formControlName="castedetail"
                                class="form-control " type="text " id="inputbutton " [(ngModel)]="castedetail">
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel "> Mobile Number</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.Number.errors }"
                                (keyup.enter)="signIn(stepper1) " formControlName="Number" class="form-control"
                                type="text" id="inputbutton " [(ngModel)]="Number ">
                            <div *ngIf="submitted && f.Number.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.Number.errors.required ">Mobile Number is
                                    required
                                </div>
                            </div>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Date of Birth</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.dateofbirth.errors }"
                                (keyup.enter)="signIn(stepper1) " formControlName="dateofbirth" class="form-control"
                                type="date" id="inputbutton " [(ngModel)]="dateofbirth ">
                            <div *ngIf="submitted && f.dateofbirth.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.dateofbirth.errors.required ">Date of Birth is
                                    required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Religion</label>
                            <select (change)="onSelectrelogion($event.target.value)" class="form-control">
                                <option value="">Select Religion</option>
                                <option *ngFor="let do of religions" value={{do.religionId}}
                                    [selected]="do.religionId==rid">
                                    {{do.religionName}}
                                </option>
                            </select> </div>
                        <div class="col-md-2 row " *ngIf="othereligiondetails">&nbsp;</div>
                        <div class="col-md-3 row " *ngIf="othereligiondetails">
                            <label for="Email " id="emaillabel ">Religion</label>
                            <input (keyup.enter)="details(stepper1) " formControlName="religiondetail"
                                class="form-control " type="text " id="inputbutton " [(ngModel)]="religiondetail">
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Marital Status</label>
                            <select (change)="onSelectmarital($event.target.value)" class="form-control">
                                <option value="">Select Marital Status</option>
                                <option *ngFor="let do of status" value={{do.maritalStatusId}}
                                    [selected]="do.maritalStatusId==martid">
                                    {{do.maritalStatus}}
                                </option>
                            </select> </div>



                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Qualification</label>
                            <select (change)="onSelectqualification($event.target.value)" class="form-control">
                                <option value="">Select Qualification</option>
                                <option *ngFor="let do of qualifications" value={{do.qualificationId}}
                                    [selected]="do.qualificationId==quaid">
                                    {{do.qualificationName}}
                                </option>
                            </select>

                        </div>
                        <div class="col-md-1 row " *ngIf="otherequalification ">&nbsp;</div>

                        <div class="col-md-3 row " *ngIf="otherequalification">
                            <label for="Email " id="emaillabel ">Qualification</label>
                            <input (keyup.enter)="details(stepper1) " formControlName="qualificationdetail"
                                class="form-control " type="text " id="inputbutton "
                                [(ngModel)]="qualificationdetail">
                        </div>
                        <div class="col-md-1 row " *ngIf="otherequalification" style="  margin-left: 1%;">&nbsp;
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Occupation</label>
                            <select (change)="onSelectoccupation($event.target.value)" class="form-control">
                                <option value="">Select Occupation</option>
                                <option *ngFor="let do of occupations" value={{do.occupationId}}
                                    [selected]="do.occupationId==occuid">
                                    {{do.occupationName}}
                                </option>
                            </select>

                        </div>

                        <div class="col-md-2 row " *ngIf="othersoccupation">&nbsp;</div>

                        <div class="col-md-3 row " *ngIf="othersoccupation">
                            <label for="Email " id="emaillabel ">Occupation</label>
                            <input (keyup.enter)="details(stepper1) " formControlName="occupatoiondetail"
                                class="form-control " type="text " id="inputbutton "
                                [(ngModel)]="occupatoiondetail">
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel "> Email</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                (keyup.enter)="signIn(stepper1) " formControlName="email" class="form-control "
                                type="text " id="inputbutton " [(ngModel)]="email ">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.email.errors.required ">Email is required</div>
                            </div>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Designation</label>
                            <select (change)="onSelectdesignation($event.target.value)" class="form-control">
                                <option value="">Select Designations</option>
                                <option *ngFor="let do of designations" value={{do.id}} [selected]="do.id==desid">
                                    {{do.designationName}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3 row " *ngIf="otherdesignation" style="margin-left: 5%;">

                            <label for="Email " id="emaillabel ">Designation</label>
                            <input (keyup.enter)="details(stepper1) " formControlName="designationd"
                                class="form-control " type="text " id="inputbutton " [(ngModel)]="designationd">
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h4>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Postal Code</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }"
                                (keyup.enter)="signIn(stepper1) " formControlName="pincode" class="form-control "
                                type="number " id="inputbutton " [(ngModel)]="pincode" (change)="getalldata()">
                            <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.pincode.errors.required ">Postal is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Country</label>
                            <input (keyup.enter)="signIn(stepper1) " formControlName="country" class="form-control "
                                type="text " id="inputbutton " [(ngModel)]="country" readonly>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> State</label>
                            <input (keyup.enter)="signIn(stepper1) " formControlName="state" class="form-control "
                                type="text " id="inputbutton " [(ngModel)]="state" readonly>
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">District</label>
                            <input (keyup.enter)="signIn(stepper1) " formControlName="district"
                                class="form-control " type="text " id="inputbutton " [(ngModel)]="district"
                                readonly>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Address one</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                                (keyup.enter)="signIn(stepper1) " formControlName="city" class="form-control "
                                type="text " id="inputbutton " [(ngModel)]="city">
                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.city.errors.required ">City Name is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address two</label>
                            <input [ngClass]="{ 'is-invalid': submitted && f.street.errors }"
                                (keyup.enter)="signIn(stepper1) " formControlName="street" class="form-control "
                                type="text " id="inputbutton " [(ngModel)]="street">
                            <div *ngIf="submitted && f.street.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="f.street.errors.required ">Street Name is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-7 row ">
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Permanent Address is Same
                                as Residential Address</h4>
                        </div>
                        <div class="col-md-1 row ">
                            <input class="form-control" formControlName="PermanentSameasResidential"
                                [checked]="PermanentSameasResidential" [(ngModel)]="PermanentSameasResidential"
                                (change)="onSelectaddress($event)" type="checkbox" id="inputbutton ">
                        </div>
                        <div class="col-md-2 row ">&nbsp;</div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Postal Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }"
                                    (keyup.enter)="signIn(stepper1) " formControlName="postalcode"
                                    class="form-control " type="number " id="inputbutton "
                                    (change)="getpincoddata()" [(ngModel)]="postalcode">
                                <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.postalcode.errors.required ">Postal is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Country</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="countryone"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="countryone"
                                    readonly>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> State</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="stateone"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="stateone"
                                    readonly>
                            </div>

                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-2 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">District</label>
                                <input (keyup.enter)="signIn(stepper1) " formControlName="districtone"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="districtone"
                                    readonly>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address one</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.adressone.errors }"
                                    (keyup.enter)="signIn(stepper1) " formControlName="adressone"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="adressone">
                                <div *ngIf="submitted && f.adressone.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.adressone.errors.required ">Address one is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address two</label>
                                <input [ngClass]="{ 'is-invalid': submitted && f.adresstwo.errors }"
                                    (keyup.enter)="signIn(stepper1) " formControlName="adresstwo"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="adresstwo">
                                <div *ngIf="submitted && f.adresstwo.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="f.adresstwo.errors.required ">Address two is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <BR /> &nbsp;
                <div class="col-md-12 row">
                    <div class="col-md-3 row">&nbsp;</div>
                    <div class="col-md-4 row">
                        <button (click)="SaveBasicDraft(stepper1)" id="signinbutton1">Draft</button>

                    </div>
                    <br />
                    <br />
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-4 row">
                        <button (click)="signIn(stepper1)" id="signinbutton1">Next</button>
                    </div>

                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
            </div>
        </div>
    </div>
</mat-step>


<mat-step label="Step 2 " state="family" style="text-align: center; height: 96%; width: 136%; ">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
        <div class="col-md-1"></div>
        <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
            <div class="homecard " id="signin " align="center "
                style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Family Details</h2>

                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>

                <form class="register-form " [formGroup]="DetailsForm">
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Family Type</label>
                            <select (change)="onSelectfamily($event.target.value)" class="form-control">
                                <option value="">Select Family Type</option>
                                <option *ngFor="let do of families" value={{do.familyTypeId}}
                                [selected]="do.familyTypeId == fid">
                                    {{do.familyType}}
                                </option>
                            </select> </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Number of Dependents</label>
                            <input [ngClass]="{ 'is-invalid': submitted && d.numberofdep.errors }"
                                (keyup.enter)="details(stepper)" formControlName="numberofdep" class="form-control "
                                type="number" id="inputbutton " [(ngModel)]="numberofdep">
                            <div *ngIf="submitted && d.numberofdep.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.numberofdep.errors.required "> Number of
                                    Dependents is required</div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Number of Children</label>
                            <input [ngClass]="{ 'is-invalid': submitted && d.numofchild.errors }"
                                (keyup.enter)="details(stepper)" formControlName="numofchild" class="form-control "
                                type="number" id="inputbutton " [(ngModel)]="numofchild">
                            <div *ngIf="submitted && d.numofchild.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.numofchild.errors.required ">Number of Children
                                    is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Annual Income </label>
                            <span style="display: flex;">Rs.&nbsp;&nbsp;&nbsp;&nbsp;
                                <input [ngClass]="{ 'is-invalid': submitted && d.Anualamt.errors }"
                                    (keyup.enter)="details(stepper)" formControlName="Anualamt"
                                    class="form-control " type="number" id="inputbutton " [(ngModel)]="Anualamt"
                                    style=" margin-top: -2%;"></span>
                            <div *ngIf="submitted && d.Anualamt.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.Anualamt.errors.required ">Anual Income is
                                    required
                                </div>
                            </div>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Income from Other sources </label>
                            <span style="display: flex;">Rs.&nbsp;&nbsp;&nbsp;&nbsp;
                                <input [ngClass]="{ 'is-invalid': submitted && d.otheramt.errors }"
                                    (keyup.enter)="details(stepper)" formControlName="otheramt"
                                    class="form-control " type="number" id="inputbutton " [(ngModel)]="otheramt"
                                    style=" margin-top: -2%;" (change)="GetTotalAmout()"></span>
                            <div *ngIf="submitted && d.otheramt.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.otheramt.errors.required "> Source Amount is
                                    required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel "> Total Annual Income</label>
                            <span style="display: flex;">Rs.&nbsp;&nbsp;&nbsp;&nbsp;
                                <input [ngClass]="{ 'is-invalid': submitted && d.totalamt.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="totalamt"
                                    class="form-control " type="number" id="inputbutton " style=" margin-top: -2%;"
                                    [(ngModel)]="totalamt" readonly></span>
                            <div *ngIf="submitted && d.totalamt.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.totalamt.errors.required "> Total Annual Income
                                    is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Nominee Date of Birth</label>
                            <input [ngClass]="{ 'is-invalid': submitted && d.Nagedob.errors }"
                                (change)="ageCalculator()" (keyup.enter)="details(stepper) "
                                formControlName="Nagedob" class="form-control " type="Date" id="inputbutton "
                                [(ngModel)]="Nagedob">
                            <div *ngIf="submitted && d.Nagedob.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.Nagedob.errors.required "> Date of Birth is
                                    required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="Nomineetype == 'major'">
                        <br/>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Major Nominee</h3>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.nominnename.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="nominnename"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="nominnename">
                                <div *ngIf="submitted && d.nominnename.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.nominnename.errors.required "> Nominee Name
                                        is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Middle Name</label>
                                <input (keyup.enter)="details(stepper1) " formControlName="nominnemiddlename"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="nominnemiddlename">
                                <!-- <div *ngIf="submitted && d.nominnemiddlename.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.nominnemiddlename.errors.required "> Nominee Name is
                                    required
                                </div>
                            </div> -->
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Last Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.nominnelastname.errors }"
                                    (keyup.enter)="details(stepper1) " formControlName="nominnelastname"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="nominnelastname">
                                <div *ngIf="submitted && d.nominnelastname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.nominnelastname.errors.required "> Nominee
                                        Last Name is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <!-- <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Relationship</label>
                            <input [ngClass]="{ 'is-invalid': submitted && d.relationship.errors }"
                                (keyup.enter)="details(stepper) " formControlName="relationship"
                                class="form-control " type="text " id="inputbutton "
                                
                                [(ngModel)]="relationship">
                            <div *ngIf="submitted && d.relationship.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.relationship.errors.required "> Relationship Name
                                    is required</div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Name on Passbook</label>
                            <input [ngClass]="{ 'is-invalid': submitted && d.passbook.errors }"
                                (keyup.enter)="details(stepper) " formControlName="passbook" class="form-control "
                                type="text " id="inputbutton "
                                
                                [(ngModel)]="passbook ">
                            <div *ngIf="submitted && d.passbook.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.passbook.errors.required ">Name on Passbook is required
                                </div>
                            </div>
                        </div> -->
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Name on Passbook</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.passbook.errors }"
                                    (keyup.enter)="details(stepper1) " formControlName="passbook"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="passbook ">
                                <div *ngIf="submitted && d.passbook.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.passbook.errors.required ">Name on Passbook
                                        is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Relationship</label>
                                <select (change)="onSelectRelationType($event.target.value)" class="form-control">
                                    <option value="">Select Relation Ship Type</option>
                                    <option *ngFor="let do of RelationShipTyes" value={{do.id}}
                                        [selected]="do.id==relationshiptypeid">
                                        {{do.relationShip}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row " *ngIf="othersinrelationshiptypes">
                                <label for="Email " id="emaillabel ">Others</label>
                                <input (keyup.enter)="details(stepper1) " formControlName="relationship"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="relationship">

                                <!-- <div *ngIf="submitted && d.relationship.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.relationship.errors.required "> Field is
                                        required</div>
                                </div> -->
                            </div>
                        </div>

                        <div class="col-md-12 row ">
                            <!-- <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Date of Birth</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.Ndob.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="Ndob" class="form-control "
                                    type="Date" id="inputbutton " [(ngModel)]="Ndob">
                                <div *ngIf="submitted && d.Ndob.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.Ndob.errors.required "> Date of Birth is
                                        required
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Postal Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.Npincode.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="Npincode"
                                    class="form-control " type="number " id="inputbutton " [(ngModel)]="Npincode"
                                    (change)="getallpincoddata(Npincode)">
                                <div *ngIf="submitted && d.Npincode.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.Npincode.errors.required ">Postal Code is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Country</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.ncountry.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="ncountry"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="ncountry"
                                    readonly>
                                <div *ngIf="submitted && d.ncountry.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.ncountry.errors.required "> Country is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>

                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">State</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.Nstate.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="Nstate" class="form-control "
                                    type="input " id="inputbutton " [(ngModel)]="Nstate" readonly>
                              
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">District</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.Ndistrict.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="Ndistrict"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Ndistrict"
                                    readonly>
                                <div *ngIf="submitted && d.Ndistrict.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.Ndistrict.errors.required "> Address is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Address Line One</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.Naddress.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="Naddress"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="Naddress">
                                <div *ngIf="submitted && d.Naddress.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.Naddress.errors.required "> Address Line One
                                        is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line Two</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.Naddress2.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="Naddress2"
                                    class="form-control " type="input " id="inputbutton " [(ngModel)]="Naddress2">
                                <div *ngIf="submitted && d.Naddress2.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.Naddress2.errors.required "> state is
                                        required
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>


                    <div class="col-md-12 row ">&nbsp;</div>

                    <div *ngIf="Nomineetype == 'minor'">
                        <br/>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Minor Nominee</h3>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">First Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minorname.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="minorname"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="minorname">
                                <div *ngIf="submitted && d.minorname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.minorname.errors.required "> Minor Nominee
                                        name is required</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Middle Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minormiddname.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="minormiddname"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="minormiddname">
                                <div *ngIf="submitted && d.minormiddname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.minormiddname.errors.required "> Minor
                                        Nominee name is required</div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Last Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minorlastname.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="minorlastname"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="minorlastname">
                                <div *ngIf="submitted && d.minorlastname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.minorlastname.errors.required "> Minor
                                        Nominee name is required</div>
                                </div>
                            </div>
                            <!-- <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Date of Birth</label>
                            <input [ngClass]="{ 'is-invalid': submitted && d.minordob.errors }"
                                (keyup.enter)="details(stepper) " formControlName="minordob" class="form-control "
                                type="Date" id="inputbutton "
                                
                                [(ngModel)]="minordob">
                            <div *ngIf="submitted && d.minordob.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="d.minordob.errors.required "> Date of Birth is
                                    required</div>
                            </div>
                        </div> -->


                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> Address Line One</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minoraddress.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="minoraddress"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="minoraddress">
                                <div *ngIf="submitted && d.minoraddress.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.minoraddress.errors.required ">Address Line
                                        One is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Address Line Two</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minoraddress1.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="minoraddress1"
                                    class="form-control " type="text " id="inputbutton "
                                    [(ngModel)]="minoraddress1">
                                <div *ngIf="submitted && d.minoraddress1.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.minoraddress1.errors.required "> Address Line
                                        Two is required</div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Postal Code</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minorpincode.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="minorpincode"
                                    class="form-control " type="number " id="inputbutton "
                                    [(ngModel)]="minorpincode" (change)="getallpincoddataforminor(minorpincode)">
                                <div *ngIf="submitted && d.minorpincode.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.minorpincode.errors.required ">Postal Code is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 row ">&nbsp;</div>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label id="emaillabel ">Country</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minorcountry.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="minorcountry"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="minorcountry"
                                    readonly>

                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel "> State</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minorstate.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="minorstate"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="minorstate"
                                    readonly>

                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">District</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.minordistrict.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="minordistrict"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="minordistrict"
                                    readonly>

                            </div>
                        </div>
                        <br><br>
                        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Guardian Details</h3><br>
                        <div class="col-md-12 row ">
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">

                                <label id="emaillabel ">Guardian Name</label>
                                <input [ngClass]="{ 'is-invalid': submitted && d.guardianname.errors }"
                                    (keyup.enter)="details(stepper) " formControlName="guardianname"
                                    class="form-control " type="text " id="inputbutton " [(ngModel)]="guardianname">
                                <div *ngIf="submitted && d.guardianname.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.guardianname.errors.required ">Field is
                                        required</div>
                                </div>
                            </div>

                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row ">
                                <label for="Email " id="emaillabel ">Relationship</label>
                                <select (change)="onSelectMinorRelationType($event.target.value)"
                                    class="form-control">
                                    <option value="">Select Relation Ship Type</option>
                                    <option *ngFor="let do of RelationShipTyes" value={{do.id}}
                                        [selected]="do.id==guardioanrelationshipwithminorid">
                                        {{do.relationShip}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-1 row ">&nbsp;</div>
                            <div class="col-md-3 row " *ngIf="minorrelationshipforguardian">
                                <label for="Email " id="emaillabel ">Others</label>
                                <input (keyup.enter)="details(stepper1) "
                                    formControlName="guardioanrelationshipwithminor" class="form-control "
                                    type="text " id="inputbutton " [(ngModel)]="guardioanrelationshipwithminor">

                                <!-- <div *ngIf="submitted && d.guardioanrelationshipwithminor.errors" class="invalid-feedback">
                                    <div style="color: red; " *ngIf="d.guardioanrelationshipwithminor.errors.required "> Field is
                                        required</div>
                                </div> -->
                            </div>
                        </div>
                        <br>
                        <BR />
                        <div *ngIf="familydocsexist">
                            <div class="col-md-12 row">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                            <div class="col-md-12 row">&nbsp;</div>
                            <div class="col-md-12 row" style="margin-left: auto;">
                                <!-- <div class="col-md-1 row">&nbsp;</div> -->

                                <div class="col-md-4 row" *ngFor="let item of familydocs">
                                    <div *ngIf="item.documentType == 'image/jpeg' ||
                                    item.documentType == 'image/png'" (click)="ViewBankFamDoc(item)">
                                            <img [src]="item.file" height="100" width="100" />
                                            <p style="text-align: center;"><br/>{{item.documentName}}</p>
                                            <!-- <div class="col-md-12 row">
                                                <div class="col-md-2 row">&nbsp;</div>
                                                <div class="col-md-10 row">
                                                    <p style="text-align: center;">{{item.documentName}}</p>            
                                                </div>            
                                            </div> -->
                                        </div>
                                        <div *ngIf=" (item.documentType == 'application/pdf' ||
                                    item.documentType == 'pdf')" (click)="ViewBankFamDoc(item)">
                                            <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                            <p style="text-align: center;"><br/>{{item.documentName}}</p>
                                            <!-- <div class="col-md-12 row">
                                                <div class="col-md-2 row">&nbsp;</div>
                                                <div class="col-md-10 row">
                                                    <p style="text-align: center;">{{item.documentName}}</p>            
                                                </div>            
                                            </div> -->
                                        </div>
                                        <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                    item.documentType == 'xlsx')" (click)="ViewBankFamDoc(item)">
                                            <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                            <p style="text-align: center;"><br/>{{item.documentName}}</p>
                                            <!-- <div class="col-md-12 row">
                                                <div class="col-md-2 row">&nbsp;</div>
                                                <div class="col-md-10 row">
                                                    <p style="text-align: center;">{{item.documentName}}</p>            
                                                </div>            
                                            </div> -->
                                        </div>
                                        <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                                    item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewBankFamDoc(item)">
                                            <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                            <p style="text-align: center;"><br/>{{item.documentName}}</p>
                                            <!-- <div class="col-md-12 row">
                                                <div class="col-md-2 row">&nbsp;</div>
                                                <div class="col-md-10 row">
                                                    <p style="text-align: center;">{{item.documentName}}</p>            
                                                </div>            
                                            </div> -->
                                        </div>
        
                                </div>
                            </div>
                        </div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Upload Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="container" style="margin-top: 5%">
                            <div class="col-md-12 row">
                                <div class="col-md-3">&nbsp;</div>
                                <div class="col-md-9">
                                    <div class="table-responsive" style="overflow-x: visible">
                                        <table class="table table-striped table-responsive table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Sl No</th>
                                                    <th>Certificate Name</th>
                                                    <th>Upload Here</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Proof of Address</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField
                                                            (change)="onChangeguardianproofofaddress($event)">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Upload Pan Card</td>
                                                    <td>
                                                        <input type="file" name="avatars" #fileField
                                                            (change)="onChangeGuardianpancard($event)">
                                                    </td>
                                                </tr>
                                      
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-md-12 row ">&nbsp;</div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                </form>
                <br>
                <div class="col-md-12 row">
                    <div class="col-md-4 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <button (click)="SaveFamilyDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp;
                        &nbsp;

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-3 row">
                        <button (click)="details(stepper1)" id="signinbutton">Next</button>
                    </div>

                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>

            </div>
        </div>
    </div>
</mat-step>


<mat-step label="Step 3" state="account" style="text-align: center; height: 96%; width: 136%; ">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
        <div class="col-md-1 "></div>
        <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
            <div class="homecard " id="signin " align="center "
                style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Bank Details</h2>

                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>

                <div class="container" style="margin-top: 5%">

                    <table class="table table-striped table-responsive table-bordered">
                        <thead>
                            <tr>
                                <th>Bank Account Type</th>
                                <th>Bank Name</th>
                                <th>Branch</th>
                                <th>IFSC Code</th>
                                <th>Cheque Facility</th>
                                <!-- <th>Loan Facility</th> -->
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let field of fieldArray; let i = index">
                                <td>
                                    <select (change)="onSelectbank($event.target.value)" class="form-control">
                                        <option value="">Select Bank Account Type</option>
                                        <option *ngFor="let do of banks" value={{do.bankAccTypeId}}
                                            [selected]="do.bankAccTypeId == field.banktypeid">
                                            {{do.accountType}}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <input [(ngModel)]="field.bankname" class="form-control" type="text"
                                        name="{{field.bankname}}" />
                                </td>
                                <td>
                                    <input [(ngModel)]="field.branch" class="form-control" type="text"
                                        name="{{field.branch}}" />
                                </td>
                                <td>
                                    <input [(ngModel)]="field.ifscecode" class="form-control" type="text"
                                        name="{{field.ifscecode}}" />
                                </td>
                                <td>
                                    <input [(ngModel)]="field.selectedchequetypeid" class="form-control" />
                                </td>
                                <!-- <td>
                                <input [(ngModel)]="field.loanfacility" class="form-control" type="checkbox"
                                    name="{{field.loanfacility}}" />
                            </td> -->
                                <td>
                                    <button class="btn btn-default" type="button"
                                        (click)="deleteFieldValue(i)">Delete</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <select (change)="onSelectbank($event.target.value)" class="form-control">
                                        <option value="">Select Bank Account Type</option>
                                        <option *ngFor="let do of banks" value={{do.bankAccTypeId}}>
                                            {{do.accountType}}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <input class="form-control" type="text" id="bankname"
                                        [(ngModel)]="newAttribute.bankname" name="bankname" />

                                </td>
                                <td>
                                    <input class="form-control" type="text" id="branch"
                                        [(ngModel)]="newAttribute.branch" name="branch" />
                                </td>
                                <td>
                                    <input class="form-control" type="text" id="ifscecode"
                                        [(ngModel)]="newAttribute.ifscecode" name="ifscecode" />
                                </td>
                                <td>
                                    <select (change)="onSelectChequeType($event.target.value)" class="form-control">
                                        <option value="">Select Cheque Facility Type</option>
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </select>
                                    <!-- <input class="form-control" type="checkbox" id="chequefacility"
                                    [(ngModel)]="newAttribute.chequefacility" name="chequefacility" /> -->
                                </td>
                                <!-- <td>
                                <input class="form-control" type="checkbox" id="loanfacility"
                                    [(ngModel)]="newAttribute.loanfacility" name="loanfacility" />
                            </td> -->
                                <td>
                                    <button class="btn btn-default" type="button"
                                        (click)="addFieldValue()">Add</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <form class="register-form " [formGroup]="BankDetailsForm">
                    <div class="col-md-12 ">&nbsp;</div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Identity Details</h4>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Ration Card Number</label>
                            <input formControlName="rationcardnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="rationcardnum">
                            <!-- <div *ngIf="submitted && k.rationcardnum.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.rationcardnum.errors.required "> Ration Card
                                Number is required</div>
                            <div style="color: red; " *ngIf="k.rationcardnum.errors.pattern ">enter valid Ration
                                Card Number</div>
                        </div> -->
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Aadhar Number</label>
                            <input  [ngClass]="{ 'is-invalid': submitted && k.aadharnum.errors } " formControlName="aadharnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="aadharnum" >
                            <div *ngIf="submitted && k.aadharnum.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.aadharnum.errors.required ">Aadhar Number is required
                            </div>
                            <div style="color: red; " *ngIf="k.aadharnum.errors.pattern ">enter valid Aadhar Number
                            </div>

                        </div>
                        <!-- <div style="color: red; " *ngIf="aadhaarNumberError" class="error-message">Invalid Aadhaar number</div> -->
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Voter ID Number</label>
                            <input [ngClass]="{ 'is-invalid': submitted && k.voternum.errors } " formControlName="voternum" class="form-control " type="text " id="inputbutton " [(ngModel)]="voternum"  >
                            <div *ngIf="submitted && k.voternum.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.voternum.errors.required "> Voter ID Number is required
                            </div>
                            <div style="color: red; " *ngIf="k.voternum.errors.pattern ">enter valid Voter ID
                            </div>

                        </div>

                        <!-- <div  style="color: red; " *ngIf="voterIdError" class="error-message">Invalid Voter ID</div> -->
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">PAN Number</label>
                            <input [ngClass]="{ 'is-invalid': submitted && k.pannumber.errors }" formControlName="pannumber" class="form-control " type="Date " id="inputbutton " [(ngModel)]="pannumber">
                            <div *ngIf="submitted && k.pannumber.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="k.pannumber.errors.required "> PAN Number is required
                                </div>
                                <div style="color: red; " *ngIf="k.pannumber.errors.pattern ">enter valid PAN Number
                                </div>

                            </div>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">GST No</label>
                            <input [ngClass]="{ 'is-invalid': submitted && k.servicetax.errors }" formControlName="servicetax" class="form-control " type="text " id="inputbutton " [(ngModel)]="servicetax" >
                            <div *ngIf="submitted && k.servicetax.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.servicetax.errors.required ">Service Tax Number
                                is required</div>
                            <div style="color: red; " *ngIf="k.servicetax.errors.pattern ">enter valid GST No
                            </div>

                        </div>

                        <!-- <div *ngIf="gstNumberError" class="error-message">Invalid GST Number</div> -->
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Passport Number</label>
                            <input [ngClass]="{ 'is-invalid': submitted && k.passportnum.errors }" formControlName="passportnum" class="form-control " type="text " id="inputbutton " [(ngModel)]="passportnum"  >
                            <div *ngIf="submitted && k.passportnum.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.passportnum.errors.required "> Passport Number is
                                required</div>
                            <div style="color: red; " *ngIf="k.passportnum.errors.pattern ">enter valid Passport
                                Number </div>

                        </div>
                        <!-- <div *ngIf="passportNumberError" class="error-message">Invalid passport number</div> -->
                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>


                    <div class="col-md-12 row ">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Life Insurance Value</label>
                            <input formControlName="lifeinsurance" class="form-control " type="input "
                                id="inputbutton " [(ngModel)]="lifeinsurance">
                            <!-- <div *ngIf="submitted && k.lifeinsurance.errors" class="invalid-feedback">
                            <div style="color: red; " *ngIf="k.lifeinsurance.errors.required ">Life Insurance
                                Value is required</div>
                        </div> -->
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Confirm</label>
                            <input class="form-control" formControlName="IsConfirm" [(ngModel)]="IsConfirm"
                                (change)="onSelectIsConfirm($event)" type="checkbox" id="inputbutton ">

                        </div>
                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <BR />

                    <BR />
                    <div *ngIf="bankdocsexist">
                        <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="col-md-12 row" style="margin-left: auto;">
                            <!-- <div class="col-md-1 row">&nbsp;</div> -->

                            <div class="col-md-4 row" *ngFor="let item of bankdocs">
                                <div *ngIf="item.documentType == 'image/jpeg' ||
                                item.documentType == 'image/png'" (click)="ViewBankFamDoc(item)">
                                    <img [src]="item.file" height="100" width="100" />
                                    <p style="text-align: center;"><br/>{{item.documentName}}</p>    
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>            
                                        </div>                                
                                    </div> -->
                                </div>
                                <div *ngIf=" (item.documentType == 'application/pdf' ||
                                item.documentType == 'pdf')" (click)="ViewBankFamDoc(item)">
                                    <img src="assets/img/pdficon.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <p style="text-align: center;"><br/>{{item.documentName}}</p>    
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>            
                                        </div>            
                                    </div> -->
                                </div>
                                <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                item.documentType == 'xlsx')" (click)="ViewBankFamDoc(item)">
                                    <img src="assets/img/xls.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <p style="text-align: center;"><br/>{{item.documentName}}</p>    
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>            
                                        </div>            
                                    </div> -->
                                </div>
                                <div *ngIf=" (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                                item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewBankFamDoc(item)">
                                    <img src="assets/img/docx.png" style="width: 100px; height: 100px; cursor: pointer;" />
                                    <p style="text-align: center;"><br/>{{item.documentName}}</p>    
                                    <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.documentName}}</p>            
                                        </div>            
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 row">&nbsp;</div>
                    <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Upload Documents</h4>

                    <div class="col-md-12 row">&nbsp;</div>
                    <div class="container" style="margin-top: 5%">
                        <div class="col-md-12 row">
                            <div class="col-md-3">&nbsp;</div>
                            <div class="col-md-9">
                                <div class="table-responsive" style="overflow-x: visible">
                                    <table class="table table-striped table-responsive table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sl No</th>
                                                <th>Certificate Name</th>
                                                <th>Upload Here</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Aadhar Card</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField
                                                        (change)="onChangeAadhar($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Voter ID</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField
                                                        (change)="onChangeVoterId($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Passport</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField
                                                        (change)="onChangePassport($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>GST Certificate</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField
                                                        (change)="onChangeGSTCert($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>IT Returns</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField
                                                        (change)="onChangeITReturns($event)">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Bank Statements</td>
                                                <td>
                                                    <input type="file" name="avatars" #fileField
                                                        (change)="onChangeBankStatements($event)">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 row">&nbsp;</div>
                </form>
                <BR />
                <div class="col-md-12 row">
                    <div class="col-md-4 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <button (click)="SaveBankDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp;
                        &nbsp;

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-3 row">
                        <button (click)="bankIn(stepper1)" id="signinbutton">Next</button>
                    </div>

                </div>
                <div class="col-md-12 row">&nbsp;</div>
                <div class="col-md-12 row">&nbsp;</div>
            </div>
        </div>
    </div>
</mat-step>

<mat-step label="Step 4 " state="homei" style="text-align: center; height: 96%; width: 136%; ">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
        <div class="col-md-1 "></div>
        <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
            <div class="homecard " id="signin " align="center "
                style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                <div class="col-md-12 ">&nbsp;</div>

                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Asset Details</h2>

                <div class="col-md-12 ">&nbsp;</div>

                <div class="container" style="margin-top: 5%">

                    <div class="table-responsive" style="overflow-x: visible">
                        <table class="table table-striped table-responsive table-bordered">
                            <thead>
                                <tr>
                                    <th>Fixed Asset</th>
                                    <th>Location with Address</th>
                                    <th>Measurement</th>
                                    <th>Guidance Value</th>
                                    <th>Present Value</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let field of fieldArrayFA; let i = index">
                                    <td>
                                        <select (change)="onSelecthome($event.target.value)" class="form-control">
                                            <option value="">Select Asset</option>
                                            <option *ngFor="let do of assets" value={{do.fixedAssetId}}
                                                [selected]="do.fixedAssetId == field.fixedAssetId">
                                                {{do.fixedAssetName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.placeallocation" class="form-control" type="text"
                                            name="{{field.placeallocation}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.measurement" class="form-control" type="text"
                                            name="{{field.measurement}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.giidancevalue" class="form-control" type="text"
                                            name="{{field.giidancevalue}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.presentvalue" class="form-control" type="text"
                                            name="{{field.presentvalue}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.total" class="form-control" type="text"
                                            name="{{field.total}}" />
                                    </td>
                                    <td>
                                        <button class="btn btn-default" type="button"
                                            (click)="deleteFieldValue(i)">Delete</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <select (change)="onSelecthome($event.target.value)" class="form-control">
                                            <option value="">Select Asset</option>
                                            <option *ngFor="let do of assets" value={{do.fixedAssetId}}
                                                [selected]="do.fixedAssetId == cid">
                                                {{do.fixedAssetName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input class="form-control" type="text" id="placeallocation"
                                            [(ngModel)]="newAttribute.placeallocation" name="placeallocation" />

                                    </td>
                                    <td>
                                        <input class="form-control" type="number" id="measurement"
                                            [(ngModel)]="newAttribute.measurement" name="measurement" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="number" id="giidancevalue"
                                            [(ngModel)]="newAttribute.giidancevalue" name="giidancevalue"
                                            (change)="GetFixedAssetTotal(newAttribute.measurement,newAttribute.giidancevalue)" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="number" id="presentvalue"
                                            [(ngModel)]="newAttribute.presentvalue" name="presentvalue" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="number" id="total"
                                            [(ngModel)]="newAttribute.total" name="total" readonly />
                                    </td>
                                    <td>
                                        <button class="btn btn-default" type="button"
                                            (click)="addFieldValueFA()">Add</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="container" style="margin-top: 5%">

                    <div class="table-responsive" style="overflow-x: visible">
                        <table class="table table-striped table-responsive table-bordered">
                            <thead>
                                <tr>
                                    <th>Movable Asset</th>
                                    <th>Model</th>
                                    <th>Date Of Purchase</th>
                                    <th>Quantity</th>
                                    <th>Present Value</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let field of fieldArrayMove; let i = index">
                                    <td>
                                        <select (change)="onSelectcar($event.target.value)" class="form-control">
                                            <option value="">Select Movable Asset</option>
                                            <option *ngFor="let do of moveable" value={{do.movableAssetId}}
                                                [selected]="do.movableAssetId == field.movableAssetId">
                                                {{do.movableAssetName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.model" class="form-control" type="text"
                                            name="{{field.model}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.dateofpurchase" class="form-control" type="date"
                                            name="{{field.dateofpurchase}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.quantity" class="form-control" type="text"
                                            name="{{field.quantity}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.prevalues" class="form-control" type="text"
                                            name="{{field.prevalues}}" />
                                    </td>
                                    <td>
                                        <input [(ngModel)]="field.cartotal" class="form-control" type="text"
                                            name="{{field.cartotal}}" />
                                    </td>
                                    <td>
                                        <button class="btn btn-default" type="button"
                                            (click)="deleteFieldcarValue(i)">Delete</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <select (change)="onSelectcar($event.target.value)" class="form-control">
                                            <option value="">Select Movable Asset</option>
                                            <option *ngFor="let do of moveable" value={{do.movableAssetId}}
                                                [selected]="do.movableAssetId == cid">
                                                {{do.movableAssetName}}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input class="form-control" type="text" id="model"
                                            [(ngModel)]="carAttribute.model" name="model" />

                                    </td>
                                    <td>
                                        <input class="form-control" type="date" id="dateofpurchase"
                                            [(ngModel)]="carAttribute.dateofpurchase" name="dateofpurchase" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="number" id="quantity"
                                            [(ngModel)]="carAttribute.quantity" name="quantity" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="number" id="prevalues"
                                            [(ngModel)]="carAttribute.prevalues" name="prevalues"
                                            (change)="GetMovableAssetTotal(carAttribute.quantity,carAttribute.prevalues)" />
                                    </td>
                                    <td>
                                        <input class="form-control" type="text" id="cartotal"
                                            [(ngModel)]="carAttribute.cartotal" name="cartotal" readonly />
                                    </td>
                                    <td>
                                        <button class="btn btn-default" type="button"
                                            (click)="addFieldcarValue()">Add</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <form class="register-form " [formGroup]="homeDetailsForm">

                </form>

                <BR />
                <BR />
                <div class="col-md-12 row">
                    <div class="col-md-4 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <button (click)="SaveHomeVehicleDraft(stepper1)" id="signinbutton">Draft</button> &nbsp;
                        &nbsp; &nbsp;

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-3 row">
                        <button (click)="homeIn(stepper1)" id="signinbutton">Next</button>
                    </div>

                </div>
                <div class="col-md-12 ">&nbsp;</div>
                <div class="col-md-12 ">&nbsp;</div>
            </div>
        </div>
    </div>
</mat-step>


<mat-step label="Step 5" state="gold" style="text-align: center;    height: 96%;width: 136%;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
        <div class="col-md-1"></div>
        <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10">

            <div class="homecard" id="signin" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%">
                <div class="col-md-12">&nbsp;</div>
                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Fixed Deposit</h2>

                <div class="col-md-12">&nbsp;</div>
                <div class="col-md-12">&nbsp;</div>
                <form id="msform" [formGroup]="fixeddeposit">
                    <div class="col-md-12 row">
                        <div class="col-md-1 row">&nbsp;</div>
                        <div class="col-md-3 row">
                            <label id="emaillabel">Applicant name</label>
                            <input formControlName="Applicantname" class="form-control" type="text" id="inputbutton" [(ngModel)]="Applicantname" [ngClass]="{ 'is-invalid': submitted && g.Applicantname.errors }">
                            <div *ngIf="submitted && g.Applicantname.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="g.Applicantname.errors.required ">Applicant Name is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row">&nbsp;</div>
                        <div class="col-md-3 row">
                            <label id="emaillabel">Joint Applicant 1</label>
                            <input formControlName="JointApplicantone" class="form-control" type="text" id="inputbutton" [(ngModel)]="JointApplicantone" [ngClass]="{ 'is-invalid': submitted && g.JointApplicantone.errors }">
                            <!-- <div *ngIf="submitted && g.JointApplicantone.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="g.JointApplicantone.errors.required ">Joint Applicant Name is required
                                </div>
                            </div> -->
                        </div>
                        <div class="col-md-1 row">&nbsp;</div>
                        <div class="col-md-3 row">
                            <label id="emaillabel">Joint Applicant 2</label>
                            <input formControlName="JointApplicanttwo" class="form-control" type="text" id="inputbutton" [(ngModel)]="JointApplicanttwo" [ngClass]="{ 'is-invalid': submitted && g.JointApplicanttwo.errors }">
                            <!-- <div *ngIf="submitted && g.JointApplicanttwo.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="g.JointApplicanttwo.errors.required ">First Name is required
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12 row">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel "> Mobile Number</label>
                            <input [ngClass]="{ 'is-invalid': submitted && g.jointNumber.errors }" formControlName="jointNumber" class="form-control" type="text" id="inputbutton " [(ngModel)]="jointNumber ">
                            <div *ngIf="submitted && g.jointNumber.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="g.jointNumber.errors.required ">Mobile Number is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>

                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Type of Deposit</label>
                            <select (change)="onSelectdeposittype($event.target.value)" class="form-control">
                            <option value="">Select Deposit Type</option>
                            <option *ngFor="let do of deposittypes" value={{do.id}} [selected]="do.id == selcetedfdtype">
                                {{do.depositetype}}
                            </option>
                        </select>
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Interest payout</label>
                            <select (change)="onSelectInterestpayout($event.target.value)" class="form-control">
                            <option value="">Select Interest payout</option>
                            <option *ngFor="let do of Interestpayout" value={{do.id}}
                            [selected]="do.id == selectedInterestpayout">
                                {{do.interestpayout}}
                            </option>
                        </select>
                        </div>

                    </div>
                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12 row">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel "> Deposit amount</label>
                            <input [ngClass]="{ 'is-invalid': submitted && g.Depositamount.errors }" formControlName="Depositamount" class="form-control" type="number" id="inputbutton " [(ngModel)]="Depositamount ">
                            <div *ngIf="submitted && g.Depositamount.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="g.Depositamount.errors.required ">Deposit amount is required
                                </div>
                            </div>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel "> Period of Deposit</label>
                            <select (change)="onSelectperiodofdeposite($event.target.value)" class="form-control">
                                <option value="">Select Period of Deposit</option>
                                <option *ngFor="let do of periods" value={{do.id}}
                                 [selected]="do.id == selectedMaturityInstruction">
                                    {{do.months}}
                                </option>
                            </select>
                            <!-- <input [ngClass]="{ 'is-invalid': submitted && g.PeriodofDeposit.errors }" formControlName="PeriodofDeposit" class="form-control" type="text" id="inputbutton " [(ngModel)]="PeriodofDeposit ">
                            <div *ngIf="submitted && g.PeriodofDeposit.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="g.PeriodofDeposit.errors.required ">Period of Deposit is required
                                </div>
                            </div> -->
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <!-- <div class="col-md-3 row ">
                            <label id="emaillabel ">Return on investment (ROI)</label>
                            <input [ngClass]="{ 'is-invalid': submitted && g.roi.errors }" formControlName="roi" class="form-control" type="text" id="inputbutton " [(ngModel)]="roi ">
                            
                        </div> -->
                    </div>
                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12 row">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">PAN</label>
                            <input [ngClass]="{ 'is-invalid': submitted && g.depositpan.errors }" formControlName="depositpan" class="form-control" type="text" id="inputbutton " [(ngModel)]="depositpan ">
                            <div *ngIf="submitted && g.depositpan.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="g.depositpan.errors.required "> PAN is required
                                </div>
                            </div>
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Payment Details</label>
                            <input [ngClass]="{ 'is-invalid': submitted && g.PaymentDetails.errors }" formControlName="PaymentDetails" class="form-control" type="text" id="inputbutton " [(ngModel)]="PaymentDetails ">
                            <div *ngIf="submitted && g.PaymentDetails.errors" class="invalid-feedback">
                                <div style="color: red; " *ngIf="g.PaymentDetails.errors.required "> Payment Details is required
                                </div>
                            </div>
                        </div>


                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Maturity Instruction</label>
                            <select (change)="onSelectMaturityInstruction($event.target.value)" class="form-control">
                            <option value="">Select Maturity Instruction</option>
                            <option *ngFor="let do of MaturityInstruction" value={{do.id}}
                             [selected]="do.id == selectedMaturityInstruction">
                                {{do.maturityInstruction}}
                            </option>
                        </select>
                        </div>
                    </div>
                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12">&nbsp;</div>
                    <div class="col-md-12 row" *ngIf="role!='1008'">
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-1 row ">

                            <input class="form-control" type="checkbox" [checked]="SendCopytoSP" formControlName="SendCopytoSP" [(ngModel)]="SendCopytoSP" style="font-size: 7px;">
                        </div>
                        <div class="col-md-5 row ">
                            <p style="font-size: 20px;color:darkblue">Send one copy to {{serviceprovidername}}</p>

                        </div>
                    </div>
                </form>
                <BR />
                <div class="col-md-12 row">
                    <div class="col-md-4 row">&nbsp;</div>
                    <div class="col-md-3 row">
                        <button (click)="SaveFixedDepositDraft(stepper1)" id="signinbutton">Draft</button> &nbsp; &nbsp; &nbsp;

                    </div>
                    <br />
                    <div class="col-md-1 row">
                    </div>
                    <div class="col-md-3 row">
                        <button (click)="FixedDepositIN(stepper)" id="signinbutton">Next</button>
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">&nbsp;</div>
            </div>
        </div>
    </div>
</mat-step>

    <mat-step label="Step 6" state="upload" style="text-align: center; height: 96%; width: 136%; ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ">
            <div class="col-md-1 "></div>
            <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 ">
                <div class="homecard " id="signin " align="center "
                    style=" margin-left: 0px;margin-right: 0px;margin-top: 1% ">
                    <div *ngIf="certificatesexist">
                        <div class="col-md-12 row">&nbsp;</div>
                        <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Uploaded Documents</h4>

                        <div class="col-md-12 row">&nbsp;</div>
                        <div class="col-md-12 row">
                            <!-- <div class="col-md-1 row">&nbsp;</div> -->

                            <div class="col-md-4 row" *ngFor="let item of CertificateDisplay">
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'image/jpeg' ||
                         item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                    <img [src]="item.certificate" height="100" width="100" />
                                    <p style="text-align: center;"><br />{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                    <div class="col-md-2 row">&nbsp;</div>
                                    <div class="col-md-10 row">
                                        <p style="text-align: center;">{{item.fileName}}</p>
                                    </div>
                                </div> -->
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/pdf' ||
                         item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                    <img src="assets/img/pdficon.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <p style="text-align: center;"><br />{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                    <div class="col-md-2 row">&nbsp;</div>
                                    <div class="col-md-10 row">
                                        <p style="text-align: center;">{{item.fileName}}</p>
                                    </div>
                                </div> -->
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                         item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                    <img src="assets/img/xls.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <p style="text-align: center;"><br />{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                    <div class="col-md-2 row">&nbsp;</div>
                                    <div class="col-md-10 row">
                                        <p style="text-align: center;">{{item.fileName}}</p>
                                    </div>
                                </div> -->
                                </div>
                                <div *ngIf="(item.actualCertificateName==null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                         item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                    <img src="assets/img/docx.png"
                                        style="width: 100px; height: 100px; cursor: pointer;" />
                                    <p style="text-align: center;"><br />{{item.fileName}}</p>
                                    <!-- <div class="col-md-12 row">
                                    <div class="col-md-2 row">&nbsp;</div>
                                    <div class="col-md-10 row">
                                        <p style="text-align: center;">{{item.fileName}}</p>
                                    </div>
                                </div> -->
                                </div>


                            </div>
                        </div>
                        <div *ngIf="documentArray.length!=0">
                            <div class="col-md-12 row">&nbsp;</div>
                            <h4 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Uploaded Documents</h4>

                            <div class="col-md-12 row">
                                <!-- <div class="col-md-1 row">&nbsp;</div> -->

                                <div class="col-md-4 row" *ngFor="let item of certificates">
                                    <div *ngIf="item.actualCertificateName!=null  && (item.documentType == 'image/jpeg' ||
                         item.documentType == 'image/png')" (click)="ViewDoc(item)">
                                        <img [src]="item.certificate" height="100" width="100" />
                                        <p style="text-align: center;"><br />{{item.fileName}}</p>
                                        <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/pdf' ||
                         item.documentType == 'pdf')" (click)="ViewDoc(item)">
                                        <img src="assets/img/pdficon.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                        <p style="text-align: center;"><br />{{item.fileName}}</p>
                                        <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                         item.documentType == 'xlsx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/xls.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                        <p style="text-align: center;"><br />{{item.fileName}}</p>
                                        <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                    </div>
                                    <div *ngIf="(item.actualCertificateName!=null) && (item.documentType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                         item.documentType == 'doc' || item.documentType == 'docx')" (click)="ViewDoc(item)">
                                        <img src="assets/img/docx.png"
                                            style="width: 100px; height: 100px; cursor: pointer;" />
                                        <p style="text-align: center;"><br />{{item.fileName}}</p>
                                        <!-- <div class="col-md-12 row">
                                        <div class="col-md-2 row">&nbsp;</div>
                                        <div class="col-md-10 row">
                                            <p style="text-align: center;">{{item.fileName}}</p>
                                        </div>
                                    </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Upload Certificates</h2>

                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 ">&nbsp;</div>
                    <div class="col-md-12 row">
                        <div class="col-md-3">&nbsp;</div>
                        <div class="col-md-9">
                            <div class="table-responsive" style="overflow-x: visible">

                                <table class="table table-striped table-responsive table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Certificate Name</th>
                                            <th>Upload Here</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let field of CertificateList; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{field.certificateName}}</td>
                                            <td>
                                                <input type="file" name="avatars" #fileField
                                                    (change)="onChangeCertificates($event,field)">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f; ">Other Documents</h2>
                                <table class="table table-striped table-responsive table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Document Name</th>
                                            <th>Document</th>

                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let field of documentArray; let i = index">

                                            <td>
                                                <input [(ngModel)]="field.otherdocumentname" class="form-control"
                                                    type="text" name="{{field.otherdocumentname}}" />
                                            </td>
                                            <td>
                                                <input [(ngModel)]="field.otherdocactualname" class="form-control"
                                                    type="text" name="{{field.otherdocactualname}}" />
                                            </td>

                                            <td>
                                                <button class="btn btn-default" type="button"
                                                    (click)="deleteFieldValueDocument(i)">Delete</button>
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>
                                                <input class="form-control" type="text" id="otherdocumentname"
                                                    [(ngModel)]="newDocAttribute.otherdocumentname"
                                                    name="otherdocumentname" />

                                            </td>
                                            <td>
                                                <input class="form-control" type="file" id="otherdocument"
                                                    (change)="OnChangeOtherDocs($event)" name="otherdocument" />
                                            </td>


                                            <td>
                                                <button class="btn btn-default" type="button"
                                                    (click)="addDocFieldValue()">Add</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 row ">&nbsp;</div>
                    <div class="col-md-12 row ">&nbsp;</div>
                    <!-- <div class="col-md-12 row">
                        <div class="col-md-3 row">&nbsp;</div>
                        <div class="col-md-2 row">
                            <button (click)="uploadCertificatesDraft(stepper1)" id="signinbutton">Draft</button> &nbsp;
                            &nbsp; &nbsp;

                        </div>
                        <br />
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-2 row">
                            <button (click)="uploadCertificates(stepper1)" id="signinbutton">Upload</button>
                        </div>
                        <div class="col-md-1 row">
                        </div>
                        <div class="col-md-2 row">
                            <button (click)="previewloandetails()" id="signinbutton">Preview</button>
                        </div>
                    </div> -->
               
               
                    <div class="col-md-12 row">
                        <div class="col-md-3 row">&nbsp;</div>

                        <div class="col-md-4">
                            <button (click)="uploadCertificatesDraft(stepper1)" id="signinbutton">Draft</button> &nbsp;
                        </div>
                        <div class="col-md-4">
                            <button (click)="uploadCertificates(stepper1)" id="signinbutton">Upload</button>
                        </div>

                        <div class="col-md-1 row">
                        </div>
                    </div><br/><br/>

                    <div class="col-md-12 row" *ngIf="ProspectIdtoDisplydata==null">
                        <div class="col-md-3 row">&nbsp;</div>
                       
                        <div class="col-md-4">
                            <button (click)="previewloandetails()" id="signinbutton">Preview</button>
                        </div>
                        <div class="col-md-4" *ngIf = "showsubmitbtn">
                            <button (click)="submitloanform()" id="signinbutton">Submit</button>
                        </div>

                        <div class="col-md-1 row">
                        </div>
                    </div><br/><br/>
                </div>
            </div>
        </div>
    </mat-step>


    <!-- Icon overrides. -->
    <!-- #docregion override-icons -->
    <ng-template matStepperIcon="details">
        <mat-icon>account_circle</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="family">
        <mat-icon>family_restroom</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="account">
        <mat-icon>account_balance</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="homei">
        <mat-icon>home</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="business">
        <mat-icon>business</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="upload">
        <mat-icon>cloud_upload</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="gold">
        <mat-icon>how_to_vote</mat-icon>
    </ng-template>
</mat-horizontal-stepper>
<br /><br />
<!-- notification alert -->
<div class="col-md-12 row">
    <div class="col-md-2">&nbsp;</div>
    <div class="col-md-4" *ngIf="dismissalert">
        <div class="alert alert-info"
            style="background-color: #545353;box-shadow: none;left: 245px; position: fixed;top: 500px;width: 350px;">
            <button mat-button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <i class="material-icons" style="color: white">close</i>
            </button>
            <span style="color: white;margin-top: 6px;">
                <b style="text-transform: capitalize;font-size: 14px;"> {{notify}}</b></span>
        </div>
    </div>
</div>
