<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
    <div class="col-md-1"></div>
    <div class="col-xs-10 col-sm-9 col-md-10 col-lg-10 col-xl-10">

        <div class="homecard" id="signin" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%"
            *ngIf="collAssignIDD==1">
            <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Collateral Assignment</h2>

            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-12">
                <div class="col-md-12 row">
                    <div class="col-md-2 row">&nbsp;</div>
                    <div class="col-md-3 row" style="display: flex;">
                        <label id="emaillabel">Customer ID:</label>&nbsp;{{customerID}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Customer Name:</label>&nbsp;{{CustomerName}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">CAPS Collateral
                            Reference:</label>&nbsp;{{CAPSCollateralReference}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Owner/ Guarantor Name:</label>&nbsp;{{OwnerName}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">GLCMS Collateral ID:</label>&nbsp;{{GLCMSCollateralID}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> ALGO Collateral ID:</label>&nbsp;{{ALGOCollateralID}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Collateral Category:</label>&nbsp;{{CollateralCategory}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Collateral Type:</label>&nbsp;{{CollateralType}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Asset Location:</label>&nbsp;{{AssetLocation}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Collateral Nature:</label>&nbsp;{{CollateralNature}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Eligibility Flag:</label>&nbsp;{{EligibilityFlag}}


                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Description:</label>&nbsp;{{Description}}


                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> Is Revocable:</label>&nbsp;{{IsRevocable}}

                    </div>

                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Is Pair Passu:</label>&nbsp;{{IsPairPassu}}


                    </div>
                </div>
                <!-- <div class="col-md-12 row ">&nbsp;</div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h3> -->
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Is Confirmed:</label>&nbsp;{{IsConfirmed}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Proceeds Receiving
                            Accounts:</label>&nbsp;{{ProceedsReceivingAccounts}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Sanction Collateral
                            Value:</label>&nbsp;{{SanctionCollateralValue}}
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Valuation Date:</label>&nbsp;{{ValuationDate}}
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Valuation Frequency:</label>&nbsp;{{ValuationFrequency}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Frequency Unit:</label>&nbsp;{{FrequencyUnit}}

                    </div>
                </div>
                <!-- <div class="col-md-12 row ">&nbsp;</div> -->

                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Margining
                                Frequency:</label>&nbsp;{{MarginingFrequency}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Margining Frequency
                                Unit:</label>&nbsp;{{MarginingFrequencyUnit}}
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Enter Comment:</label>&nbsp;{{EnterComment}}
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <!-- <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Enter Comment:</label>&nbsp;{{}}
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address one:</label>&nbsp;

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Address two:</label>&nbsp;

                        </div>
                    </div> -->
                </div>

            </div>
            <br>
            <!-- <div class="col-md-12 row">
                <div class="col-md-3 row" *ngIf="roleid=='1'">&nbsp;</div>
                <div class="col-md-4 row" *ngIf="roleid!='1'">&nbsp;</div>
                <div class="col-md-4 row" *ngIf="roleid=='1'">
                    <button (click)="EditPersonalDetails()" id="signinbutton">Edit</button>
                </div>
                <br />
                <div class="col-md-1 row">
                </div>
                <div class="col-md-4 row">
                    <button (click)="signIn(stepper1)" id="signinbutton">NEXT</button> 
                </div>

            </div> -->
            <br>
            <br>
            <div class="col-md-12 row ">&nbsp;</div>
        </div>


        <div class="homecard" id="signin" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%"
            *ngIf="collAssignIDD==2">
            <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Collateral Bank Guarantee</h2>

            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-12">
                <div class="col-md-12 row">
                    <div class="col-md-2 row">&nbsp;</div>
                    <div class="col-md-3 row" style="display: flex;">
                        <label id="emaillabel">Customer ID:</label>&nbsp;{{customerID}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Customer Name:</label>&nbsp;{{CustomerName}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">CAPS Collateral
                            Reference:</label>&nbsp;{{CAPSCollateralReference}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Owner/ Guarantor Name:</label>&nbsp;{{OwnerName}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">GLCMS Collateral ID:</label>&nbsp;{{GLCMSCollateralID}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> ALGO Collateral ID:</label>&nbsp;{{ALGOCollateralID}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Collateral Category:</label>&nbsp;{{CollateralCategory}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Collateral Type:</label>&nbsp;{{CollateralType}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Asset Location:</label>&nbsp;{{AssetLocation}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Collateral Nature:</label>&nbsp;{{CollateralNature}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Eligibility Flag:</label>&nbsp;{{EligibilityFlag}}


                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Description:</label>&nbsp;{{Description}}


                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> Revocability Flag:</label>&nbsp;{{RevocabilityFlag}}

                    </div>

                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Is Limited:</label>&nbsp;{{IsLimited}}


                    </div>
                </div>
                <!-- <div class="col-md-12 row ">&nbsp;</div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h3> -->
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Project Employer Name:</label>&nbsp;{{ProjectEmployerName}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Guarantor Type:</label>&nbsp;{{GuarantorType}}
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">FAB ORR:</label>&nbsp;{{FABORR}}
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Rating Agency:</label>&nbsp;{{RatingAgency}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Rating Type:</label>&nbsp;{{RatingType}}

                    </div>
                </div>
                <!-- <div class="col-md-12 row ">&nbsp;</div> -->

                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Rating Value:</label>&nbsp;{{RatingValue}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Date of Estimation:</label>&nbsp;{{DateofEstimation}}
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Net Worth:</label>&nbsp;
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Relationship With
                                Borrower:</label>&nbsp;{{RelationshipWithBorrower}}
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Sanction Collateral Value:</label>&nbsp;{{SanctionCollateralValue}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Valuation Date:</label>&nbsp;{{ValuationDate}}

                        </div>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Valuation
                                Frequency:</label>&nbsp;{{ValuationFrequency}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Frequency Unit:</label>&nbsp;{{FrequencyUnit}}
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Margining
                                Frequency:</label>&nbsp;{{MarginingFrequency}}
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Margining Frequency Unit:</label>&nbsp;{{MarginingFrequencyUnit}}
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Valuation after haircut:</label>&nbsp;{{Valuationafterhaircut}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Valuation Date:</label>&nbsp;{{ValuationDate}}

                        </div>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Maturity Date:</label>&nbsp;{{MaturityDate}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Guarantee Amount:</label>&nbsp;{{GuaranteeAmount}}
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Change Type:</label>&nbsp;{{ChangeType}}
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Change Value:</label>&nbsp;{{ChangeValue}}
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Enter Comment:</label>&nbsp;{{EnterComment}}

                        </div>
                        <!-- <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Valuation Date:</label>&nbsp;

                        </div> -->
                    </div>
                </div>

            </div>
            <br>
            <!-- <div class="col-md-12 row">
                <div class="col-md-3 row" *ngIf="roleid=='1'">&nbsp;</div>
                <div class="col-md-4 row" *ngIf="roleid!='1'">&nbsp;</div>
                <div class="col-md-4 row" *ngIf="roleid=='1'">
                    <button (click)="EditPersonalDetails()" id="signinbutton">Edit</button>
                </div>
                <br />
                <div class="col-md-1 row">
                </div>
                <div class="col-md-4 row">
                    <button (click)="signIn(stepper1)" id="signinbutton">NEXT</button> 
                </div>

            </div> -->
            <br>
            <br>
            <div class="col-md-12 row ">&nbsp;</div>
        </div>


        <div class="homecard" id="signin" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%"
            *ngIf="collAssignIDD==3">
            <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Collateral Cash Margin</h2>

            <div class="col-md-12">&nbsp;</div>
            <div class="col-md-12">&nbsp;</div>

            <div class="col-md-12">
                <div class="col-md-12 row">
                    <div class="col-md-2 row">&nbsp;</div>
                    <div class="col-md-3 row" style="display: flex;">
                        <label id="emaillabel">Customer ID:</label>&nbsp;{{customerID}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Customer Name:</label>&nbsp;{{CustomerName}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">CAPS Collateral
                            Reference:</label>&nbsp;{{CAPSCollateralReference}}

                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Owner/ Guarantor Name:</label>&nbsp;{{OwnerName}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">GLCMS Collateral ID:</label>&nbsp;{{GLCMSCollateralID}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> ALGO Collateral ID:</label>&nbsp;{{ALGOCollateralID}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel "> Collateral Category:</label>&nbsp;{{CollateralCategory}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Collateral Type:</label>&nbsp;{{CollateralType}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Asset Location:</label>&nbsp;{{AssetLocation}}
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Collateral Nature:</label>&nbsp;{{CollateralNature}}

                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Eligibility Flag:</label>&nbsp;{{EligibilityFlag}}


                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Description:</label>&nbsp;{{Description}}


                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-4 row ">
                        <label id="emaillabel "> Sanction Collateral Value:</label>&nbsp;{{SanctionCollateralValue}}

                    </div>

                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Valuation Date:</label>&nbsp;{{ValuationDate}}


                    </div>
                </div>
                <!-- <div class="col-md-12 row ">&nbsp;</div>

                <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h3> -->
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Valuation Frequency:</label>&nbsp;{{ValuationFrequency}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel "> Frequency Unit:</label>&nbsp;{{FrequencyUnit}}
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Margining Frequency:</label>&nbsp;{{MarginingFrequency}}
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Margining Frequency
                            Unit:</label>&nbsp;{{MarginingFrequencyUnit}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Valuation after haircut:</label>&nbsp;{{Valuationafterhaircut}}

                    </div>
                </div>
                <!-- <div class="col-md-12 row ">&nbsp;</div> -->

                <div class="col-md-12 row ">&nbsp;</div>


                <div class="col-md-12 row ">
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Valuation Date:</label>&nbsp;{{ValuationDate}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Maturity Date:</label>&nbsp;{{MaturityDate}}
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Change Type:</label>&nbsp;{{ChangeType}}
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Change Value:</label>&nbsp;{{ChangeValue}}
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Enter Comment:</label>&nbsp;{{EnterComment}}

                        </div>
                        <!-- <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Enter Comment:</label>&nbsp;{{ValuationDate}}

                        </div> -->
                    </div>
                </div>
                <!-- <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Valuation Frequency:</label>&nbsp;{{ValuationFrequency}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Frequency Unit:</label>&nbsp;{{FrequencyUnit}}
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Margining Frequency:</label>&nbsp;{{MarginingFrequency}}
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Margining Frequency Unit:</label>&nbsp;{{MarginingFrequencyUnit}}
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Valuation after haircut:</label>&nbsp;{{Valuationafterhaircut}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Valuation Date:</label>&nbsp;{{ValuationDate}}

                        </div>
                    </div>
                </div>
                <div class="col-md-12 row ">&nbsp;</div>
                <div class="col-md-12 row ">
                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Maturity Date:</label>&nbsp;{{MaturityDate}}

                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Guarantee Amount:</label>&nbsp;{{GuaranteeAmount}}
                        </div>
                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label for="Email " id="emaillabel ">Change Type:</label>&nbsp;{{ChangeType}}
                        </div>

                    </div>
                    <div class="col-md-12 row ">&nbsp;</div>

                    <div class="col-md-12 row ">
                        <div class="col-md-2 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Change Value:</label>&nbsp;{{ChangeValue}}
                        </div>

                        <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Enter Comment:</label>&nbsp;{{EnterComment}}

                        </div>
                        <!-- <div class="col-md-1 row ">&nbsp;</div>
                        <div class="col-md-3 row ">
                            <label id="emaillabel ">Valuation Date:</label>&nbsp;

                        </div> -->
            </div>
        </div>

        <div class="homecard" id="signin" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%"
        *ngIf="collAssignIDD==4">
        <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Collateral Lien</h2>

        <div class="col-md-12">&nbsp;</div>
        <div class="col-md-12">&nbsp;</div>

        <div class="col-md-12">
            <div class="col-md-12 row">
                <div class="col-md-2 row">&nbsp;</div>
                <div class="col-md-3 row" style="display: flex;">
                    <label id="emaillabel">Customer ID:</label>&nbsp;{{customerID}}
                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Customer Name:</label>&nbsp;{{CustomerName}}

                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">CAPS Collateral
                        Reference:</label>&nbsp;{{CAPSCollateralReference}}

                </div>
            </div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel ">Owner/ Guarantor Name:</label>&nbsp;{{OwnerName}}
                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">GLCMS Collateral ID:</label>&nbsp;{{GLCMSCollateralID}}

                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel "> ALGO Collateral ID:</label>&nbsp;{{ALGOCollateralID}}
                </div>
            </div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel "> Collateral Category:</label>&nbsp;{{CollateralCategory}}

                </div>

                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel "> Collateral Type:</label>&nbsp;{{CollateralType}}

                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Asset Location:</label>&nbsp;{{AssetLocation}}
                </div>
            </div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel ">Collateral Nature:</label>&nbsp;{{CollateralNature}}

                </div>

                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel "> Eligibility Flag:</label>&nbsp;{{EligibilityFlag}}


                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Description:</label>&nbsp;{{Description}}


                </div>
            </div>
            <div class="col-md-12 row ">&nbsp;</div>

            <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-4 row ">
                    <label id="emaillabel "> Account with Bank:</label>&nbsp;{{AccountwithBank}}

                </div>

                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Account Classification:</label>&nbsp;{{AccountClassification}}


                </div>
            </div>
            <div class="col-md-2 row ">&nbsp;</div>
            <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-4 row ">
                    <label id="emaillabel "> FD Maturity Date:</label>&nbsp;{{MaturityDate}}

                </div>

            </div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-4 row ">
                    <label id="emaillabel "> Sanction Collateral Value:</label>&nbsp;{{SanctionCollateralValue}}

                </div>

                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Valuation Date:</label>&nbsp;{{ValuationDate}}


                </div>
            </div>
            <!-- <div class="col-md-12 row ">&nbsp;</div>

            <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h3> -->
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Valuation Frequency:</label>&nbsp;{{ValuationFrequency}}

                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel "> Frequency Unit:</label>&nbsp;{{FrequencyUnit}}
                </div>

            </div>
            <div class="col-md-12 row ">&nbsp;</div>


            <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel ">Margining Frequency:</label>&nbsp;{{MarginingFrequency}}
                </div>

                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Margining Frequency
                        Unit:</label>&nbsp;{{MarginingFrequencyUnit}}

                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel ">Valuation after haircut:</label>&nbsp;{{Valuationafterhaircut}}

                </div>
            </div>
            <!-- <div class="col-md-12 row ">&nbsp;</div> -->

            <div class="col-md-12 row ">&nbsp;</div>


            <div class="col-md-12 row ">
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Valuation Date:</label>&nbsp;{{ValuationDate}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Maturity Date:</label>&nbsp;{{MaturityDate}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Guarantee Amount:</label>&nbsp;{{ChangeType}}
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Change Type:</label>&nbsp;{{ChangeType}}
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Change Value:</label>&nbsp;{{ChangeValue}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Enter Comment:</label>&nbsp;{{EnterComment}}

                    </div>
                </div>
            </div>
            <!-- <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Valuation Frequency:</label>&nbsp;{{ValuationFrequency}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Frequency Unit:</label>&nbsp;{{FrequencyUnit}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Margining Frequency:</label>&nbsp;{{MarginingFrequency}}
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Margining Frequency Unit:</label>&nbsp;{{MarginingFrequencyUnit}}
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Valuation after haircut:</label>&nbsp;{{Valuationafterhaircut}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Valuation Date:</label>&nbsp;{{ValuationDate}}

                    </div>
                </div>
            </div>
            <div class="col-md-12 row ">&nbsp;</div>
            <div class="col-md-12 row ">
                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Maturity Date:</label>&nbsp;{{MaturityDate}}

                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Guarantee Amount:</label>&nbsp;{{GuaranteeAmount}}
                    </div>
                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label for="Email " id="emaillabel ">Change Type:</label>&nbsp;{{ChangeType}}
                    </div>

                </div>
                <div class="col-md-12 row ">&nbsp;</div>

                <div class="col-md-12 row ">
                    <div class="col-md-2 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Change Value:</label>&nbsp;{{ChangeValue}}
                    </div>

                    <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Enter Comment:</label>&nbsp;{{EnterComment}}

                    </div>
                    <!-- <div class="col-md-1 row ">&nbsp;</div>
                    <div class="col-md-3 row ">
                        <label id="emaillabel ">Valuation Date:</label>&nbsp;

                    </div> -->
        </div>
    </div>

    <div class="homecard" id="signin" align="center" style=" margin-left: 0px;margin-right: 0px;margin-top: 1%"
    *ngIf="collAssignIDD==5">
    <h2 style="font-family: DINNextRoundedLTPro;color: #2b156f;">Collateral Mortgage Information</h2>

    <div class="col-md-12">&nbsp;</div>
    <div class="col-md-12">&nbsp;</div>

    <div class="col-md-12">
        <div class="col-md-12 row">
            <div class="col-md-2 row">&nbsp;</div>
            <div class="col-md-3 row" style="display: flex;">
                <label id="emaillabel">Customer ID:</label>&nbsp;{{customerID}}
            </div>
            <div class="col-md-1 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label for="Email " id="emaillabel ">Customer Name:</label>&nbsp;{{CustomerName}}

            </div>
            <div class="col-md-1 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label for="Email " id="emaillabel ">CAPS Collateral
                    Reference:</label>&nbsp;{{CAPSCollateralReference}}

            </div>
        </div>
        <div class="col-md-12 row ">&nbsp;</div>
        <div class="col-md-12 row ">
            <div class="col-md-2 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label id="emaillabel ">Owner/ Guarantor Name:</label>&nbsp;{{OwnerName}}
            </div>
            <div class="col-md-1 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label for="Email " id="emaillabel ">GLCMS Collateral ID:</label>&nbsp;{{GLCMSCollateralID}}

            </div>
            <div class="col-md-1 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label for="Email " id="emaillabel "> ALGO Collateral ID:</label>&nbsp;{{ALGOCollateralID}}
            </div>
        </div>
        <div class="col-md-12 row ">&nbsp;</div>
        <div class="col-md-12 row ">
            <div class="col-md-2 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label id="emaillabel "> Collateral Category:</label>&nbsp;{{CollateralCategory}}

            </div>

            <div class="col-md-1 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label for="Email " id="emaillabel "> Collateral Type:</label>&nbsp;{{CollateralType}}

            </div>
            <div class="col-md-1 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label for="Email " id="emaillabel ">Asset Location:</label>&nbsp;{{AssetLocation}}
            </div>
        </div>
        <div class="col-md-12 row ">&nbsp;</div>
        <div class="col-md-12 row ">
            <div class="col-md-2 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label id="emaillabel ">Collateral Nature:</label>&nbsp;{{CollateralNature}}

            </div>

            <div class="col-md-1 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label for="Email " id="emaillabel "> Eligibility Flag:</label>&nbsp;{{EligibilityFlag}}


            </div>
            <div class="col-md-1 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label for="Email " id="emaillabel ">Borrower Is the owner of the Asset:</label>&nbsp;{{BorrowerIstheowneroftheAsset}}


            </div>
        </div>
        <div class="col-md-12 row ">&nbsp;</div>

        <div class="col-md-12 row ">
            <div class="col-md-2 row ">&nbsp;</div>
            <div class="col-md-4 row ">
                <label id="emaillabel "> Charge Level Indicator:</label>&nbsp;{{ChargeLevelIndicator}}

            </div>

            <div class="col-md-3 row ">
                <label for="Email " id="emaillabel ">Registered or Notarised:</label>&nbsp;{{RegisteredorNotarised}}


            </div>
        </div>
        <!-- <div class="col-md-12 row ">&nbsp;</div>

        <h3 style="font-family: DINNextRoundedLTPro;color: #2b156f; "> Residential Address</h3> -->
        <div class="col-md-12 row ">&nbsp;</div>
        <div class="col-md-12 row ">
            <div class="col-md-2 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label for="Email " id="emaillabel ">Mortgage Property Type:</label>&nbsp;{{MortgagePropertyType}}

            </div>
            <div class="col-md-1 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label for="Email " id="emaillabel "> Sanction Collateral Value:</label>&nbsp;{{SanctionCollateralValue}}
            </div>

        </div>
        <div class="col-md-12 row ">&nbsp;</div>


        <div class="col-md-12 row ">
            <div class="col-md-2 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label id="emaillabel ">Valuation Date:</label>&nbsp;{{ValuationDate}}
            </div>

            <div class="col-md-1 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label for="Email " id="emaillabel ">Valuation Frequency:</label>&nbsp;{{ValuationFrequency}}

            </div>
            <div class="col-md-1 row ">&nbsp;</div>
            <div class="col-md-3 row ">
                <label id="emaillabel ">Frequency Unit:</label>&nbsp;{{FrequencyUnit}}

            </div>
        </div>
        <!-- <div class="col-md-12 row ">&nbsp;</div> -->

        <div class="col-md-12 row ">&nbsp;</div>


        <div class="col-md-12 row ">
            <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Margining Frequency:</label>&nbsp;{{MarginingFrequency}}

                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Margining Frequency Unit:</label>&nbsp;{{MarginingFrequencyUnit}}
                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Valuation after haircut:</label>&nbsp;{{Valuationafterhaircut}}
                </div>

            </div>
            <div class="col-md-12 row ">&nbsp;</div>

            <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel ">Valuation Date:</label>&nbsp;{{ValuationDate}}
                </div>

                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel ">Maturity Date:</label>&nbsp;{{MaturityDate}}

                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel ">Revaluation Date:</label>&nbsp;{{RevaluationDate}}

                </div>
            </div>
        </div>
        <div class="col-md-12 row ">&nbsp;</div>
        <div class="col-md-12 row ">
            <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Valued By:</label>&nbsp;{{ValuedBy}}

                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Market Value:</label>&nbsp;{{MarketValue}}
                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Revaluation Percent:</label>&nbsp;{{RevaluationPercent}}
                </div>

            </div>
            <div class="col-md-12 row ">&nbsp;</div>

            <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel ">Change Value:</label>&nbsp;{{ChangeValue}}
                </div>

                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel ">Forced Sale Value:</label>&nbsp;{{ForcedSaleValue}}

                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel ">Change Type:</label>&nbsp;{{ChangeType}}

                </div>
            </div>
        </div>
        <div class="col-md-12 row ">&nbsp;</div>
        <div class="col-md-12 row ">
            <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Enter Comment:</label>&nbsp;{{EnterComment}}

                </div>
                <!-- <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Guarantee Amount:</label>&nbsp;{{GuaranteeAmount}}
                </div> -->
                <!-- <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label for="Email " id="emaillabel ">Change Type:</label>&nbsp;{{ChangeType}}
                </div> -->

            </div>
            <div class="col-md-12 row ">&nbsp;</div>

            <!-- <div class="col-md-12 row ">
                <div class="col-md-2 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel ">Change Value:</label>&nbsp;{{ChangeValue}}
                </div>

                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel ">Enter Comment:</label>&nbsp;{{EnterComment}}

                </div>
                <div class="col-md-1 row ">&nbsp;</div>
                <div class="col-md-3 row ">
                    <label id="emaillabel ">Valuation Date:</label>&nbsp;

                </div>
            </div> -->
        </div>

    </div>
    <br>
    <!-- <div class="col-md-12 row">
        <div class="col-md-3 row" *ngIf="roleid=='1'">&nbsp;</div>
        <div class="col-md-4 row" *ngIf="roleid!='1'">&nbsp;</div>
        <div class="col-md-4 row" *ngIf="roleid=='1'">
            <button (click)="EditPersonalDetails()" id="signinbutton">Edit</button>
        </div>
        <br />
        <div class="col-md-1 row">
        </div>
        <div class="col-md-4 row">
            <button (click)="signIn(stepper1)" id="signinbutton">NEXT</button> 
        </div>

    </div> -->
    <br>
    <br>
    <div class="col-md-12 row ">&nbsp;</div>
</div>


    </div>
    <br>
    <!-- <div class="col-md-12 row">
                <div class="col-md-3 row" *ngIf="roleid=='1'">&nbsp;</div>
                <div class="col-md-4 row" *ngIf="roleid!='1'">&nbsp;</div>
                <div class="col-md-4 row" *ngIf="roleid=='1'">
                    <button (click)="EditPersonalDetails()" id="signinbutton">Edit</button>
                </div>
                <br />
                <div class="col-md-1 row">
                </div>
                <div class="col-md-4 row">
                    <button (click)="signIn(stepper1)" id="signinbutton">NEXT</button> 
                </div>

            </div> -->
    <br>
    <br>
    <div class="col-md-12 row ">&nbsp;</div>
</div>

